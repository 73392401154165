import api from "../../services/api";
import Qs from "qs";

export function getLoadOptionsCategorieMail() {
    return async (inputValue) => {
        return api.get(`categorieEmail?` + new URLSearchParams(Qs.stringify({
            "start": 0,
            "length": 10,
            search: {
                value: inputValue
            },
            columns: [
                {
                    data: "libelle",
                    searchable: true,
                    orderable: true,
                    search: {
                        value: '',
                        regex: false,
                    }
                },
                {
                    data: "id",
                    searchable: true,
                    orderable: true,
                    search: {
                        value: '',
                        regex: false,
                    }
                }
            ]
        })),
            {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            }).then(res => {
            return res.json().then((json) => {
                return json.data.map(exp => ({
                    id: exp.id,
                    title: `${exp.libelle} `,
                    image: exp?.upload?.urlOriginale
                }));
            })
        });
    };
}