import { useController } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import { FormControl, InputGroup } from "react-bootstrap";
import { French } from "flatpickr/dist/l10n/fr";
import { ReactComponent as Gen013 } from "@assets/icons/gen013.svg";
import moment from "moment";
import {count} from "react-table/src/aggregations";
import {BtSwal} from "../../../utils/alerts/sweetAlert";

const $ = require('jquery');

function FormTimePicker({ control, name, solid, idDate, rules, index, getValues, setValue, remove }) {
    const { field } = useController({ control, name, rules });
    //todo fix error : lors de la modification erreur de l'heure de fin d'évènement
    let defaultValue = field.value;
    let addClass = "";
    if (defaultValue != ""){
        if(count(field.value.split(" ")) === 1 ){
            defaultValue = field.value;
        }else {
            defaultValue = field.value.split(" ")[2].split(":")[0]+":"+field.value.split(" ")[2].split(":")[1];
        }
    }
    if (idDate){
        if ($("#"+idDate).val() !== ""){
            // console.log($(".groupTimeForDate"+index))
            $(".groupTimeForDate"+index).removeAttr('hidden');
        }
        addClass = "timeForDate"+idDate;
        // alert($("#"+idDate).val())
    }
    return <Flatpickr
        // defaultValue={field.value.split(" ")[2].split(":")[0]+":"+field.value.split(" ")[2].split(":")[1]}
        // defaultValue={field.value}
        defaultValue={defaultValue}
        onChange={date => {
            field.onChange(date[0]?.toLocaleTimeString('fr'));
            if (idDate){
                field.onChange($("#"+idDate).val().split(" ")[0]+" à "+date[0]?.toLocaleTimeString('fr').split(":")[0]+":"+date[0]?.toLocaleTimeString('fr').split(":")[1])
                let numberId = idDate.replace("idDate", "");
                if (numberId != 0){
                    let dateIndexPrev = getValues(name.replace(numberId, numberId-1)).split(" ")[0]
                    let dateIndex = $("#"+idDate).val().split(" ")[0]
                    let heureIndexPrev = getValues(name.replace(numberId, numberId-1)).split(" ")[2]
                    let heureIndex = date[0].getHours()+":"+date[0].getMinutes()

                    if (! (moment(new Date(dateIndexPrev.split('/')[2]+"-"+dateIndexPrev.split('/')[1]+"-"+dateIndexPrev.split('/')[0]+" "+heureIndexPrev)) < moment(new Date(dateIndex.split('/')[2]+"-"+dateIndex.split('/')[1]+"-"+dateIndex.split('/')[0]+" "+heureIndex)))){
                        BtSwal.fire({
                            title: 'La période choisie est incorrecte. Merci de rentrer les dates par ordre chronologique.',
                            showDenyButton: false,
                            icon: 'error',
                            confirmButtonText: `J'ai compris`});
                        remove(index)
                    }

                }
            }
        }}
        render={
            (props, ref) => {
                return <InputGroup className={solid ? 'input-group-solid' : ''}>
                    <InputGroup.Text>
                        <span className='svg-icon svg-icon-fluid  w-25px'>
                            <Gen013 />
                        </span>
                    </InputGroup.Text>
                    <FormControl {...props} ref={ref} className={solid ? 'form-control-solid '+addClass : addClass} />
                </InputGroup>
            }
        }
        options={{
            locale: French,
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
        }}
    />;
}

export default FormTimePicker;