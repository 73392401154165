import { useMutation } from "react-query";
import api from "@services/api";
import auth from "@services/auth";

export default function useChangeCategoriesMail(props) {
    return useMutation(changeCategoriesMail, props);
}

function changeCategoriesMail(categoriesMail) {
    const id = categoriesMail?.id;
    const idAgence = auth.agence?.id;

    const params = { ...categoriesMail, idAgence };

    if (id != null)
        return api.put(`categorieEmail/${id}`, { json: params }).json();
    return api.post('categorieEmail', { json: params }).json();
}