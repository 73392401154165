import { useMutation } from "react-query";
import api from "@services/api";
import auth from "@services/auth";

export default function useChangeDestinataireReponseInvitation(props) {
    return useMutation(changeDestinataire, props);
}

function changeDestinataire(expediteur) {
    const id = expediteur?.id;

    if (id != null)
        return api.put(`destinataireReponseInvitation/${id}`, { json: expediteur }).json();
    return api.post('destinataireReponseInvitation', {
        json: {
            ...expediteur,
            idAgence: auth?.agence?.id,
        }
    }).json();
}