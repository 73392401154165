import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import FooterSelectionDropDown from "./FooterSelection/FooterSelectionDropdown";

function FooterEditor({ footer, onChange }) {
    return <table width="600" cellSpacing="0" cellPadding="0" border="0">
        <tbody>
            <tr>
                <td>
                    <div className='overlay min-h-100px w-600px'>
                        <div
                            className='overlay-wrapper' style={{overflowX : "hidden", overflowY: "visible"}}
                            dangerouslySetInnerHTML={{ __html: footer }}
                        />
                        <div className='overlay-layer bg-dark bg-opacity-25 align-items-start justify-content-end'>
                            <div className='m-5 w-100 d-flex justify-content-center'>
                                <Button variant='danger' onClick={() => onChange(null)}>
                                    <FontAwesomeIcon icon={faTrash} className='me-3' />
                                    Enlever
                                </Button>
                                <FooterSelectionDropDown
                                    className='btn-secondary ms-2'
                                    onChange={onChange}
                                >
                                    <FontAwesomeIcon icon={faPen} className='me-3' />
                                    Changer
                                </FooterSelectionDropDown>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>;
}

export default FooterEditor;