import {useSnapshot} from "valtio";
import auth from "../../../services/auth";
import LoadingView from "../../LoadingView/LoadingView";
import useGetGestionNotificationPublic from "../../../requests/communication/useGetGestionNotificationPublic";
import {Button, Card, Container, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useEffect} from "react";
import useAbonnerMail from "../../../requests/communication/useAbonnerMail";
import useDesabonnerMail from "../../../requests/communication/useDesabonnerMail";
import useChangeNotificationsPublic from "../../../requests/communication/useChangeNotificationsPublic";

function GestionNotificationPublicView() {

    const snapAuth = useSnapshot(auth);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get('ref');
    const getGestionNotificationPublic = useGetGestionNotificationPublic(ref);
    const abonneMail = useAbonnerMail();
    const desabonneMail = useDesabonnerMail();
    const changeNotification = useChangeNotificationsPublic();

    async function saveChanges(data) {
        data.ref = ref;
        changeNotification.mutate(data);
    }

    const {
        register, handleSubmit, reset
    } = useForm({
        defaultValues: getGestionNotificationPublic?.data?.parametreNotification
    });

    useEffect(() => {
        reset(getGestionNotificationPublic?.data?.parametreNotification);
    }, [reset, getGestionNotificationPublic.isLoading]);


    if (getGestionNotificationPublic.isError || ref === ""){
        return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
            <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
                <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                    <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                        <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Gestion des notifications</h1>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column flex-lg-row-fluid py-10 card'>
                <div className='d-flex flex-center flex-column flex-column-fluid card-body'>
                    <div className='p-10 p-lg-15 mx-auto w-lg-75 w-md-100'>
                        <p>{getGestionNotificationPublic.error?.message} </p>
                    </div>
                </div>
            </div>
        </div>;
    }

    if (!getGestionNotificationPublic.isSuccess){
        return <LoadingView/>
    }



    return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
            <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo'/>
                        </div>
                    </span>
                    <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Gestion des notifications</h1>
                </div>
            </div>
        </div>
        <div className='d-flex flex-column flex-lg-row-fluid py-10'>
            <div className=''>
                <div className=''>
                    <Container>
                        <Card className={`bg-light-info card-flush`}>
                            <Card.Body>
                                <h2 className="text-center">Gestion des notifications</h2>
                                <h3 className='text-center'>Paramètres forum</h3>
                                <div className='mt-7 d-flex flex-wrap justify-content-between'>
                                    <Form.Check
                                        className="mb-2 w-300px"
                                        inline
                                        type='switch'
                                        label=" nouveau sujet forum"
                                        id='mailNouveauSujetForum'
                                        {...register('mailNouveauSujetForum')}
                                    />
                                    <Form.Check
                                        inline
                                        type='hidden'
                                        id='pushNouveauSujetForum'
                                        {...register('pushNouveauSujetForum')}
                                    />
                                    <Form.Check
                                        className="w-300px mb-2"
                                        inline
                                        type='switch'
                                        label=" réponse au sujet du forum"
                                        id='mailReponseSujetForum'
                                        {...register('mailReponseParticipantSujetForum')}
                                    />
                                    <Form.Check
                                        type='hidden'
                                        id='pushReponseSujetForum'
                                        {...register('pushReponseParticipantSujetForum')}
                                    />
                                    <Form.Check
                                        className="w-300px mb-2"
                                        inline
                                        type='switch'
                                        label=" réponse de l'auteur au sujet du forum"
                                        id='mailReponseSujetForum'
                                        {...register('mailReponseAuteurSujetForum')}
                                    />
                                    <Form.Check
                                        type='hidden'
                                        id='pushReponseSujetForum'
                                        {...register('pushReponseAuteurSujetForum')}
                                    />
                                </div>
                                <h3 className='text-center mt-7'>Paramètres mail</h3>
                                <div className='mt-7 d-flex flex-wrap justify-content-between'>
                                    <Form.Check
                                        className="w-300px mb-2"
                                        inline
                                        type='switch'
                                        label=" nouvel email"
                                        id='mailNouvelEmail'
                                        {...register('mailNouvelEmail')}
                                    />
                                    <Form.Check
                                        type='hidden'
                                        id='pushNouvelEmail'
                                        {...register('pushNouvelEmail')}
                                    />
                                </div>
                                <h3 className='text-center mt-7'>Paramètres invitation</h3>
                                <div className='mt-7 d-flex flex-wrap justify-content-between'>
                                    <Form.Check
                                        className="w-300px mb-2"
                                        inline
                                        type='switch'
                                        label=" nouvelle invitation"
                                        id='mailInvitation'
                                        {...register('mailInvitation')}
                                    />
                                    <Form.Check
                                        type='hidden'
                                        id='pushInvitation'
                                        {...register('pushInvitation')}
                                    />
                                    <Form.Check
                                        className="w-300px mb-2"
                                        inline
                                        type='switch'
                                        label=" rappel invitation"
                                        id='mailRappelInvitation'
                                        {...register('mailRappelInvitation')}
                                    />
                                    <Form.Check
                                        type='hidden'
                                        id='pushRappelInvitation'
                                        {...register('pushRappelInvitation')}
                                    />
                                </div>
                                <div className="d-flex justify-content-center">
                                    <Button variant="secondary" onClick={handleSubmit((data) => saveChanges(data))}>Enregistrer les paramètres</Button>
                                </div>
                                <div className='w-lg-500px p-10 p-lg-15 mx-auto'>
                                    <div className='text-center mb-10'>
                                        {getGestionNotificationPublic?.data?.personne?.mailIsDesinscrit ? <h2 className='text-dark mb-3'>Se réabonner aux mails</h2> : <h2 className='text-dark mb-3'>Se désabonner des mails</h2>}
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        {getGestionNotificationPublic?.data?.personne?.mailIsDesinscrit ?
                                        <Button
                                            onClick={() => abonneMail.mutate(ref)}
                                            className="btn btn-danger">
                                            Se réabonner
                                        </Button> :
                                        <Button
                                            onClick={() => desabonneMail.mutate(ref)}
                                            className="btn btn-secondary me-2">
                                            Se désabonner
                                        </Button>
                                        }
                                    </div>

                                </div>
                            </Card.Body>
                        </Card>
                    </Container>

                </div>
            </div>
        </div>
    </div>
}

export default GestionNotificationPublicView;