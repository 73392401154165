import { useMutation } from "react-query";
import api from "@services/api";

export default function useSendMail({ id, date, ...props }) {
    return useMutation(() => api.post(
        `email/${id}/envoyer`, {
        json: {
            dateProgrammationEnvoi: date,
        }
    }).json(), props);
}