import $ from "jquery";
import fileManagerProxy from "../proxies/fileManager";

export function imageTinyMce(isValidHttpUrl, footer, uploadFile) {
    $(".tox-dialog__title").text("Test")
    if (!isValidHttpUrl($(".tox-textfield").first().val())) {
        $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled", true);
    }
    $(".tox-dialog").css('z-index', 5000)
    $(".tox-tinymce-aux").css('z-index', 5000)
    $(".tox-form").first().append(`
                                                    <small id="errUrl" style="color: lightcoral; font-size: small; display: none" >Url invalid merci de renseigner une url du type https://xxx.xx ou renseigner un mot clé du type {motCle} </small>
                                                `)
    $(".tox-dialog__body-content").prepend(`<span id="contentBloc"><p class="mb-2">Pour ajouter une image, cliquer sur l'un des deux boutons ci-dessous : </p>
                                            <div id="custom_button" class="d-flex align-center justify-content-center w-100 btn mb-2" style="background-color: #207ab7; color: white; font-weight: bold; cursor: pointer">
                                             <label
                                             style="display: flex;align-items: center;margin-right: 10px;"
                                              ><p class="mb-2">Rechercher sur le serveur</p>
                                                </label>
                                              <img width="50px" src="/icon/serverDownload.svg" style="width: 40px;padding: 0;margin: 0;" alt="Rechercher sur le serveur">
                                            </div>

                                             <label
                                              class="btn"
                                              style="background-color: #207ab7; color: white; font-weight: bold; cursor: pointer;display: flex;justify-content: center;align-items: center;"
                                              for="file1" id="custom_button_bis"><p class="mb-2 me-2">Rechercher sur votre pc</p>
                                                <input
                                                    style="display: none;"
                                                    type='file'
                                                    id='file1'
                                                />
                                                <img width="50px" src="/icon/computerUpload.svg" style="width: 40px;padding: 0;margin: 0;" alt="Rechercher sur votre pc">
                                              </label>
                                                <hr style="border: 1px solid;margin-top: 15px;margin-bottom: 10px;"/>
                                              <p class="mb-2">Si vous souhaitez ajouter manuellement une image depuis son lien internet, cliquez sur le bouton ci-dessous :</p>
                                              <div id="custom_button_manuel" class="d-flex align-center justify-content-center w-100 btn mb-2" style="background-color: #207ab7; color: white; font-weight: bold; cursor: pointer">
                                                 <label
                                                 style="display: flex;align-items: center;margin-right: 10px;"
                                                  ><p class="mb-2">Ajouter une image depuis un lien internet</p>
                                                    </label>
                                                  <img width="50px" src="/icon/link.svg" style="width: 40px;padding: 0;margin: 0;" alt="Ajouter manuellement une url d'une image">
                                                </div></span>

                                    `)

    if ($(".tox-form__controls-h-stack .tox-textfield").val() == "") {
        $(".tox-form__group").hide();
        $(".tox-dialog__title").text("Insérer une image")
    } else {
        $(".tox-dialog__title").text("Modifier une image")
        $("#contentBloc").hide()
    }

    $("#custom_button_manuel").on("click", function (e) {
        $(".tox-form__group").first().show();
        $(".tox-form__group").eq(1).show();
        $(".tox-form__group").eq(2).show();
        $(".tox-form__group .tox-form__group").show();
    });

    $('#custom_button').on('click', function (e) {
        fileManagerProxy.fileManagerProxy(footer?.id ? footer?.id : "", false);
    });


    $(".tox-textfield").first().on('keyup', function (e) {
        if (isValidHttpUrl($(".tox-textfield").first().val())) {
            $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled", false);
            $("#errUrl").hide()
        } else {
            $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled", true);
            $("#errUrl").show()
        }
    })
    $(".tox-textfield").first().on('paste', function (e) {
        if (isValidHttpUrl($(".tox-textfield").first().val())) {
            $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled", false);
            $("#errUrl").hide()
        } else {
            $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled", true);
            $("#errUrl").show()
        }
    })
    $(".tox-textfield").first().on('change', function (e) {
        if (isValidHttpUrl($(".tox-textfield").first().val())) {
            $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled", false);
            $("#errUrl").hide()
        } else {
            $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled", true);
            $("#errUrl").show()
        }
    })

    $('#custom_button_bis #file1').on('change', function (e) {
        //userfile
        const file = e.target.files[0];
        uploadFile.mutate(file);
    });
}