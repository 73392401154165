import useGetProfil from '@requests/profil/useGetProfil';
import { Alert, Button, Spinner } from 'react-bootstrap';
import {useEffect, useState} from "react";
import Select from "react-select";

function ProfilFilter({ selected, onChange, className }) {
    const profils = useGetProfil();

    const [selectedValue, setSelectedValue] = useState(selected != null ? {id:selected.id,title:selected.libelle} : null);
    const handleChange = value => {
        setSelectedValue(value);
    }


    useEffect(() => {
        setSelectedValue(selected != null ? {id:selected.id,title:selected.title} : null)
    }, [selected])


    if (profils.isLoading){
        return <div className='text-center'><Spinner animation='border' /></div>;
    }

    const debouncedLoadOptions = profils?.data.data.map(exp => ({
        id: exp.id,
        title: exp.libelle
    }));


    return <div>
            <h3 className="text-center">Quel profil afficher ?</h3>
            <Select
                className={className}
                placeholder={"Choisir un profil"}
                noOptionsMessage={() => "Aucun résultat"}
                loadingMessage={() => "Chargement..."}
                minLength={0}
                styles={{
                    option: (provided, state) => ({
                        ...provided,
                        color: state.isSelected ? 'white' : '#716d66',
                        backgroundColor: state.isSelected ? '#4fc9da' : 'white',
                        "&:hover":{
                            backgroundColor: !state.isSelected ? 'rgba(79,201,218,0.3)' : "#4fc9da",
                        }
                    }),
                    input: (base) => ({
                        ...base,
                        width: "250px"
                    }),
                    control: (base) => ({
                        ...base,
                        backgroundColor : "#FFF",
                        borderRadius : ".95rem",
                        color: '#716d66',
                    }),
                }}
                isClearable={true}
                cacheOptions
                defaultOptions={true}
                value={selectedValue}
                getOptionLabel={e => e.title}
                getOptionValue={e => e.id}
                formatOptionLabel={categorie => (
                    <div className="categorie-option">
                        <span>{categorie?.title}</span>
                    </div>
                )}
                options={debouncedLoadOptions}
                onChange={ (selected) => {
                    handleChange(selected)
                    onChange(selected)
                }}
            />
        </div>
}

export default ProfilFilter;