import {Col, Form, Modal, Row} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSnapshot } from "valtio";
import mailsProxy from "@proxies/mails";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import useChangeMail from "@requests/communication/mails/useChangeMail";
import ExpediteurSelection from "../../../ExpediteurSelection/ExpediteurSelection";
import CategorieSelection from "../CategorieSelection/CategorieSelection";
import { ReactComponent as Cod001 } from "@assets/icons/cod001.svg";
import CategorieMailSettings from "../../../../ParametresView/CategorieMailSettings/CategorieMailSettings";
import ExpediteurSettings from "../../../../ParametresView/ExpediteursSettings/ExpediteurSettings";

const ReglagesStep = forwardRef((_, ref) => {
    const [showModalSettings, setShowModalSettings] = useState(false);
    const [currentComponentSettings, setCurrentComponentSettings] = useState(null);
    const changeMail = useChangeMail();
    const mailsSnapshot = useSnapshot(mailsProxy);
    const {
        register, handleSubmit, reset, control,
        formState: { errors },
    } = useForm({
        defaultValues: mailsProxy.mail,
    });

    useEffect(() => {
        reset(mailsSnapshot.mail ?? {
            sujet: null,
        });
    }, [reset, mailsSnapshot.mail]);

    useImperativeHandle(ref, () => ({
        save: async () => {
            let done = false;
            await handleSubmit(async (data) => {
                try {
                    const result = await changeMail.mutateAsync(data);
                    mailsProxy.mail = result;
                    done = true;
                } catch {}
            })();
            return done;
        },
    }));

    return <div className='mw-800px m-auto'>
        <Row className='mt-5'>
            <Col lg={6} md={6} sm={12}>
                <Form.Group>
                    <Form.Label className='required'>Sujet du mail</Form.Label>
                    <Form.Control maxLength={200} {...register('sujet', { required: true })} className='form-control-solid' placeholder='Sujet du mail' />
                    {errors.sujet && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label className='required'>Pré-header</Form.Label>
                    <Form.Control maxLength={60} {...register('preHeader', { required: true })} className='form-control-solid' placeholder='Sujet du mail' />
                    {errors.preHeader && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Form.Group>
            </Col>
        </Row>
        <Row className='mt-5'>
            <Col lg={6} md={6} sm={12}>
                <Form.Group>
                    <Form.Label className='required'>Catégorie</Form.Label>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="w-100">
                            <CategorieSelection control={control} name='categorie' rules={{ required: true }} />
                        </div>
                        <span
                            title={"Ajouter une catégorie"}
                            style={{fill: "black"}}
                            onClick={() => {
                                setCurrentComponentSettings(<CategorieMailSettings/>)
                                setShowModalSettings(true)
                            }} className='ms-2 svg-icon svg-icon-1 cursor-pointer'><Cod001/></span>
                    </div>
                    {errors.categorie && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label className='required'>Expéditeur</Form.Label>
                    <div className="d-flex align-items-center">
                        <div className="w-100">
                            <ExpediteurSelection control={control} name='expediteur' rules={{ required: true }} />
                        </div>
                        <span
                            title={"Ajouter un expéditeur"}
                            style={{fill: "black"}}
                            onClick={() => {
                                setCurrentComponentSettings(<ExpediteurSettings/>)
                                setShowModalSettings(true)
                            }} className='ms-2 svg-icon svg-icon-1 cursor-pointer'><Cod001/></span>
                    </div>
                    {errors.expediteur && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Form.Group>
            </Col>
        </Row>
        <Modal
            show={showModalSettings}
            onHide={() => setShowModalSettings(false)}
            size='lg'
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Paramètres mail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {currentComponentSettings}
            </Modal.Body>
        </Modal>
    </div>;
});

export default ReglagesStep;