import {useEffect, useState} from "react";
import {URLWebsocket, URLWebSocketSpeed} from "../../../config";
import {v4 as uuidv4} from "uuid";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";
import {useQueryClient} from "react-query";

export default function useWebSocketPresence(){
    const [reloadWebSocket, setReloadWebSocket] = useState(0);
    const [websocketIsConnecte, setWebsocketIsConnecte] = useState(false);
    const snapAuth = useSnapshot(auth);
    const queryClientb = useQueryClient();

    useEffect(() => {
        if (snapAuth?.utilisateur?.id){
            let websocket = new WebSocket(URLWebsocket)
            let idConnexion = null;
            websocket.addEventListener("close", (event) => {
                setWebsocketIsConnecte(false)
                setTimeout(function (){
                    setReloadWebSocket(uuidv4());
                    auth.websocket.send = (cache) => {
                        console.log("ws présence hs")
                    };
                },3000)
            });
            websocket.onopen = (ev) => {
                setWebsocketIsConnecte(true)
                console.log('connected ws présence')
                auth.websocket.send = (cache) => {
                    websocket.send(btoa(JSON.stringify({
                        action : "clearCache",
                        idConnexion : idConnexion,
                        idAgence : snapAuth.agence.id,
                        cache : cache
                    })))
                }
            }
            websocket.onmessage = (event) => {
                let data  = (JSON.parse(atob(event.data)));
                if (data?.action == "connexion" && data?.response?.idConnexion){
                    idConnexion = data?.response?.idConnexion
                    websocket.send(btoa(JSON.stringify({
                        action : "identification",
                        idConnexion : data?.response?.idConnexion,
                        idUtilisateur : snapAuth.utilisateur.id,
                        idAgence : snapAuth.agence.id
                    })))
                }
                else if(data?.action == "identification"){
                    websocket.send(btoa(JSON.stringify({
                        action : "clearCache",
                        idConnexion : idConnexion,
                        idAgence : snapAuth.agence.id,
                        cache : "getProxies"
                    })))
                }
                else if(data?.action == "clearCache"){
                    queryClientb.invalidateQueries(data?.response?.cache);
                }
            }
            return () => {websocket.close()
            }
        }


    }, [reloadWebSocket, snapAuth?.utilisateur?.id, queryClientb])

    return websocketIsConnecte;
}