import useGetFiles from "../../requests/filemanager/useGetFiles";
import LineFileView from "./LineFileView";
import {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import fileManagerProxie from "@proxies/fileManager";
import {useSnapshot} from "valtio";

function FileManagerView() {

    const [dossierBase, setDossierBase] = useState("/images")
    const [stateArbo, setSateArbo] = useState(dossierBase);
    const files = useGetFiles(stateArbo);
    const [cheminParent, setCheminParent] = useState(null);
    const fileManagerSnap = useSnapshot(fileManagerProxie);

    useEffect(() => {
        files.refetch()
    }, [stateArbo])

    if (files.data) {

        const fileLines = files.data.data.map((file, index) =>
            <LineFileView
                key={index}
                nom={file.nom}
                stateArbo={stateArbo}
                changeArbo={setSateArbo}
                setCheminParent={setCheminParent}
                cheminParent={cheminParent}
                file={file}
            />);

        return <Modal
            style={{zIndex:10000}}
            show={fileManagerSnap.showFileManager.show}
            onHide={() => fileManagerProxie.showFileManager.show = false}
            size='lg'
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Button
                    onClick={() => {
                        setDossierBase("/files")
                        setSateArbo("/files")
                    }}
                    className="me-2">
                    Dossier fichiers
                </Button>
                <Button
                    onClick={() => {
                        setDossierBase("/images")
                        setSateArbo("/images")
                    }}
                >
                    Dossier images
                </Button>
            </Modal.Header>
            <Modal.Body>
        <table id="kt_file_manager_list" data-kt-filemanager-table="files"
                      className="table align-middle table-row-dashed fs-6 gy-5">

            <thead>

            <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                <th className="min-w-250px ps-10">Nom</th>
                <th className="min-w-10px">Taille</th>
                <th className="min-w-125px">Déposé le</th>
                <th className="w-125px"><button
                    onClick={() => {
                        if (cheminParent == "/"){
                            setSateArbo(dossierBase)
                        }else {
                            setSateArbo(cheminParent)
                        }
                    }}
                    className="btn btn-primary">Retour</button></th>
            </tr>

            </thead>

            <tbody className="fw-bold text-gray-600">
            {fileLines}
            </tbody>
        </table>
            </Modal.Body></Modal>;
    } else {
        return ""
    }

}

export default FileManagerView;
