function LoadingView() {
    return <div className=''>
        <div className='h-10px w-100'>
            <div className='progress-bar progress-bar-striped progress-bar-animated h-100 bg-primary' />
        </div>
        <div className='d-flex justify-content-center'>
            <div className=''>
                <svg id={"loadingSvg"} xmlns="http://www.w3.org/2000/svg" width="550px" height="550px" viewBox="0 0 100 100">
                    <g transform="rotate(0 50 50)">
                        <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.7666666666666667s" repeatCount="indefinite"></animate>
                        </rect>
                    </g><g transform="rotate(15 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.7333333333333333s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(30 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.7s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(45 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(60 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.6333333333333333s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(75 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.6s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(90 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.5666666666666667s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(105 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.5333333333333333s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(120 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.5s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(135 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.4666666666666667s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(150 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.43333333333333335s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(165 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.4s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(180 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.36666666666666664s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(195 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(210 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.3s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(225 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.26666666666666666s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(240 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.23333333333333334s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(255 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.2s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(270 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(285 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.13333333333333333s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(300 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.1s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(315 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.06666666666666667s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(330 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="-0.03333333333333333s" repeatCount="indefinite"></animate>
                    </rect>
                </g><g transform="rotate(345 50 50)">
                    <rect x="47.5" y="21.5" rx="2.5" ry="4.5" width="5" height="9" >
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.8s" begin="0s" repeatCount="indefinite"></animate>
                    </rect>
                </g>
                </svg>
                <h1 style={{bottom:"100px"}} className='fw-bolder text-center fs-2qx text-gray-800 mb-7 position-relative'>Chargement</h1>
            </div>
        </div>
    </div>;
}

export default LoadingView;