import {useSnapshot} from "valtio";
import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {useQueryClient} from "react-query";
import auth from "../../../services/auth";
import SearchBar from "../../../components/SearchBar/SearchBar";
import MetaTable from "../../../components/MetaTable/MetaTable";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import invitationsProxy from "../../../proxies/invitations";
import InfoPersonneForSuiviModal from "../../../views/communication/InvitationsView/SuiviModal/InfoPersonneForSuiviModal";
import toast from "react-hot-toast";
import {Button, Modal} from "react-bootstrap";
import useAddNewReceptionniste from "../../../requests/communication/speedBusiness/useAddNewReceptionniste";
import useDeleteReceptionniste from "../../../requests/communication/speedBusiness/useDeleteReceptionniste";
import moment from "moment";
import {French} from "flatpickr/dist/l10n/fr";
import Flatpickr from "react-flatpickr";
import useChangeLienReceptionniste from "../../../requests/communication/speedBusiness/useChangeLienReceptionniste";
import useSendEmailReceptionniste from "../../../requests/communication/speedBusiness/useSendEmailReceptionniste";

export default function GestionReceptionnisteModal(){
    const speedSnap = useSnapshot(speedBusinessProxy);
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const search2 = watch('search2');
    const [debouncedSearch] = useDebounce(search, 300);
    const [debouncedSearch2] = useDebounce(search2, 300);
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const [prenom, setPrenom] = useState("");
    const [prenom2, setPrenom2] = useState("");
    const [prenomNom, setPrenomNom] = useState("");
    const [prenomNom2, setPrenomNom2] = useState("");
    const [nom, setNom] = useState("");
    const [nom2, setNom2] = useState("");
    const [dateTimeExpirationLien, setDateTimeExpirationLien] = useState("");
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const regenererLien = useChangeLienReceptionniste();
    const envoyerEmail = useSendEmailReceptionniste();
    const addNewReceptionniste = useAddNewReceptionniste({
        onSuccess: (res) => {
            queryClient.invalidateQueries('receptionniste');
            queryClient.invalidateQueries('receptionniste', speedSnap.speedBusiness?.id);
            queryClient.invalidateQueries(['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'receptionnistes']);
            snapAuth.websocket.send("receptionniste")
            snapAuth.websocket.send(['receptionniste', speedSnap.speedBusiness?.id])
            snapAuth.websocket.send(['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'receptionnistes'])
            setUpdate(update+1)
            setShowModalSelectExpirationLien(false)
        },
        onError: (err) => {
            toast.error(err.message);
        },
    });
    const deleteReceptionniste = useDeleteReceptionniste();
    const [update, setUpdate] = useState(0);
    const [totalRow, setTotalRow] = useState(0);
    const [showModalSelectExpirationLien, setShowModalSelectExpirationLien] = useState(false);
    const [personneAddSelected, setPersonneAddSelected] = useState(null);
    const [currentIdReceptionniste, setCurrentIdReceptionniste] = useState(null);

    useEffect(() => {
        setPrenom("")
        setPrenom2("")
        setPrenomNom("")
        setPrenomNom2("")
        setNom("")
        setNom2("")
    }, [speedSnap.showJourJModal]);

    const colSearch = useMemo(() => {
        return {
            'personne.prenom': prenom,
            'personne.nom': nom,
            'personne.prenomNom': prenomNom,
        };
    }, [prenom, nom, prenomNom]);


    const colSearch2 = useMemo(() => {
        return {
            'personne.prenom': prenom2,
            'personne.nom': nom2,
            'personne.prenomNom': prenomNom2,
        };
    }, [prenom2, nom2, prenomNom2]);

    const tableRef = useRef();
    const tableRef2 = useRef();
    const columns = useMemo(() => {

        let tmp =  [
            {
                Header: 'Id',
                accessor: 'personne.id',
                minWidth: 80,
                maxWidth: 80
            },
            {
                Header: 'prenomNom',
                accessor: 'personne.prenomNom',
                hidden: true
            },
            {
                Header: 'nomPrenom',
                accessor: 'personne.nomPrenom',
                hidden: true
            },
            {
                Header: 'prenom',
                accessor: 'personne.prenom',
                hidden: true,
            },
            {
                Header: 'Participant',
                accessor: 'personne.nom',
                minWidth: 200,
                Cell: ({row}) => {
                    let result = [];
                    result.push(<i className="me-3 cursor-pointer fa fa-plus-circle fa-2x" onClick={() => {
                        setPersonneAddSelected(row?.original?.personne)
                        setShowModalSelectExpirationLien(true)
                        setCurrentIdReceptionniste(null);
                    }} style={{
                        color: "green"
                    }}>

                    </i>)
                    result.push(row.original.personne.prenom + " " + row.original.personne.nom);
                    result.push(<span> <i onClick={() => {
                        invitationsSnapshot.showInfoPersonneForSuiviInvitation(row.original);
                    }} className="cursor-pointer fa fa-info-circle"></i></span>)

                    return result;
                }
            },
        ]
        return tmp;
    }, [update, totalRow, addNewReceptionniste?.data, speedSnap?.speedBusiness])
    const columns2 = useMemo(() => {

        let tmp = [
            {
                Header: 'Id',
                accessor: 'personne.id',
                minWidth: 80,
                maxWidth: 80
            },
            {
                Header: 'prenomNom',
                accessor: 'personne.prenomNom',
                hidden: true
            },
            {
                Header: 'nomPrenom',
                accessor: 'personne.nomPrenom',
                hidden: true
            },
            {
                Header: 'prenom',
                accessor: 'personne.prenom',
                hidden: true,
            },
            {
                Header: 'Participant',
                accessor: 'personne.nom',
                minWidth: 200,
                Cell: ({row}) => {
                    let result = [];
                    result.push(<i className="me-3 cursor-pointer fa fa-trash fa-2x" onClick={() => {
                        deleteReceptionniste.mutate(row.original.id)
                        queryClient.invalidateQueries(['receptionniste', speedSnap.speedBusiness?.id])
                        queryClient.invalidateQueries(['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'receptionnistes'])
                        snapAuth.websocket.send("receptionniste")
                        snapAuth.websocket.send(['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'receptionnistes'])
                        snapAuth.websocket.send(speedSnap.speedBusiness?.id)
                    }} style={{
                        color: "indianred"
                    }}>

                    </i>)
                    result.push(row.original.personne.prenom + " " + row.original.personne.nom);
                    result.push(<span> <i onClick={() => {
                        invitationsSnapshot.showInfoPersonneForSuiviInvitation(row.original);
                    }} className="cursor-pointer fa fa-info-circle"></i></span>)
                    result.push(<span> <i onClick={() => {
                        setCurrentIdReceptionniste(row?.original?.id)
                        setDateTimeExpirationLien(row?.original?.dateExiprationAcces)
                        setPersonneAddSelected(row?.original?.personne)
                        setShowModalSelectExpirationLien(true)

                    }} title="Modifier la date de validité du lien" className={`${moment(row?.original.dateExiprationAcces, "DD/MM/YYYY à HH:mm") > moment() ? 'badge badge-success' : 'badge badge-danger'}  cursor-pointer`}>{moment(row?.original.dateExiprationAcces, "DD/MM/YYYY à HH:mm") > moment() ? 'Date de validité du lien valide' : 'Date de validité du lien expiré'}</i></span>)
                    result.push(<span title="Générer un nouveau lien"> <i onClick={() => {
                        regenererLien.mutate(row?.original?.id)
                    }}  className="btn btn-primary cursor-pointer fa fa-sync"></i></span>)
                    result.push(<span title="Voir le tableau des précences"> <i onClick={() => {
                        window.open(row?.original?.lienAcces, "_blank")
                    }}  className="btn btn-primary cursor-pointer fa fa-eye"></i></span>)
                    result.push(<span title="Envoyer email lien réceptionniste"> <i onClick={() => {
                        envoyerEmail.mutate(row?.original?.id)
                    }}  className="btn btn-primary cursor-pointer fa fa-paper-plane"></i></span>)
                    return result;
                }
            },
        ]
        return tmp;
    }, [update, totalRow, deleteReceptionniste?.data, addNewReceptionniste?.data, speedSnap?.speedBusiness])

    return <Modal
        fullscreen
        show={speedSnap.showGestionReceptionnisteModal}
        onHide={() => {
            speedBusinessProxy.showGestionReceptionnisteModal = false
        }}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <h2>
                Gestion des réceptionnistes
            </h2>
        </Modal.Header>
        <Modal.Body>
            <div>
        <div style={{
            width: 800,
            margin: "auto",
            marginBottom: 10,
            height: "fit-content",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            borderRadius: 10,
            border: "solid 1px var(--primary-color)"
        }}>
            <p className="w-100 fs-6 fw-bold pt-5 ps-5 pe-5">Pour le bon fonctionnement des rencontres, il est important de valider les présences de tous les participants. Cette tâche peut être réalisée par des réceptionnistes, nous vous conseillons de choisir un réceptionniste pour 50 personnes afin de fluidifier l'arrivée des participants.</p>
        </div>
        <div className="d-flex justify-content-around">
            <div>
                <h3>Annuaire</h3>
                <SearchBar
                    solid
                    {...register('search')}
                    onClear={search?.length > 0 ? () => {
                        setValue('search', '');
                    } : null}
                />
                <MetaTable
                    ref={tableRef}
                    className='mt-5 w-800px'
                    height={500}
                    url={`annuaire`}
                    keys={['annuaire']}
                    columns={columns}
                    search={debouncedSearch}
                    colSearch={colSearch}
                    flex={true}
                />
            </div>
            <div>
                <h3>Liste des réceptionnistes</h3>
                <SearchBar
                    solid
                    {...register('search2')}
                    onClear={search?.length > 0 ? () => {
                        setValue('search2', '');
                    } : null}
                />
                <MetaTable
                    ref={tableRef2}
                    className='mt-5 w-800px'
                    height={500}
                    url={`rencontreSpeedBusiness/${speedSnap.speedBusiness?.id}/receptionnistes`}
                    keys={['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'receptionnistes']}
                    columns={columns2}
                    search={debouncedSearch2}
                    colSearch={colSearch2}
                    flex={true}
                    setTotalRow={setTotalRow}
                />
            </div>
        </div>
                <Modal
                    show={showModalSelectExpirationLien}
                    onHide={() => {
                        setShowModalSelectExpirationLien(false)
                    }}
                    dialogClassName='p-9'
                    contentClassName='modal-rounded'
                    enforceFocus={false}
                    keyboard={false}
                    backdrop={"static"}
                >
                    <Modal.Body>
                        <p style={{
                            fontWeight: "bold",
                            fontSize: 14
                        }}>
                            Afin de permettre aux réceptionnistes de valider les présences. Un lien leur sera envoyé par mail. Merci de choisir la date de validité de ce lien.
                        </p>
                        <Flatpickr
                            className={"form-control mt-2"}
                            display={"hidden"}
                            defaultValue={dateTimeExpirationLien}
                            onChange={date => {
                                setDateTimeExpirationLien(moment(date[0]).format("DD/MM/YYYY à HH:mm"))
                            }}
                            options={{
                                locale: French,
                                dateFormat: 'd/m/Y à H:i',
                                enableTime: true,
                            }}
                        />
                        <div style={{
                            width: "fit-content"
                        }} className="m-auto">
                            <Button className="mt-2" onClick={() => {
                                addNewReceptionniste.mutate({
                                    id: currentIdReceptionniste,
                                    idAgence: snapAuth?.agence?.id,
                                    idRencontreSpeedBusiness:speedSnap?.speedBusiness?.id,
                                    personne: personneAddSelected,
                                    dateExiprationAcces:dateTimeExpirationLien,
                                    tabDatesAcces:null
                                })
                                queryClient.invalidateQueries("receptionniste")
                                queryClient.invalidateQueries(['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'receptionnistes'])
                                snapAuth.websocket.send("receptionniste")
                                snapAuth.websocket.send(['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'receptionnistes'])
                            }}>Confirmer</Button>
                            <Button className="btn mt-2 ms-2 btn-danger" onClick={() => {
                                setShowModalSelectExpirationLien(false)
                            }}>Annuler</Button>
                        </div>
                    </Modal.Body>
                </Modal>
        <InfoPersonneForSuiviModal/>

    </div>
        </Modal.Body>
    </Modal>
};
