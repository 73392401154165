export function prepareEntrepriseList(entreprises, entreprise, isPartenaire) {
    let added = false;
    if (entreprises === undefined){
        entreprises = []
    }
    let newEntreprises = entreprises.map((e) => {
        if (e.id === entreprise?.id) {
            added = true;
            return updateProfils(entreprise, isPartenaire);
        }
        return updateProfils(e, isPartenaire);
    });
    if (entreprise != null && !added)
        newEntreprises.push(updateProfils(entreprise, isPartenaire));
    return newEntreprises;
}

function updateProfils(entreprise, isPartenaire) {
    let arrProfils = Object.values(entreprise.tabProfils);

    if (!isPartenaire)
        arrProfils = arrProfils.filter(profil => !profil.isPartenaire);

    let tabProfils = {};
    arrProfils.forEach(profil => tabProfils[profil.id] = profil);

    return {
        ...entreprise,
        tabProfils: tabProfils,
    };
}