import ky, {HTTPError} from "ky";
import { baseURL } from '@/config'
import { getAccessToken, getRefreshToken, setTokens } from "./token";

let isRefresh = false;
const currentUrl = window.location.href;
const parts = currentUrl.split('/');
const agence = parts[3];

const baseApi = ky.create({
    prefixUrl: baseURL,
    hooks: {
        // TODO: fix error message
        afterResponse: [
            async (request, options, response) => {
                const body = await response.json();
                if (body?.success == false && body.message != null) {
                    // console.error(body.message);
                    // throw new HTTPError(body.message);
                }
            },
        ],
    }
});

const api = baseApi.extend({
    retry: {
        limit: 2,
        methods: ['get', 'post'],
        statusCodes: [498],
        backoffLimit: 3000
    },
    timeout : 50000,
    hooks: {
        beforeRetry: [
            // Send access token in header
            async ({request, options, error, retryCount}) => {
                const accessToken = getAccessToken();
                request.headers.set('Agence', agence);
                request.headers.set('Version', "1.101");
                if (accessToken) {
                    request.headers.set('Authorization', `Bearer ${accessToken}`);
                }
            },
        ],
        beforeRequest: [
            // Send access token in header
            request => {
                const accessToken = getAccessToken();

                request.headers.set('Agence', agence);
                request.headers.set('Version', "1.101");

                if (accessToken && request.headers.get('Authorization') == null) {
                    request.headers.set('Authorization', `Bearer ${accessToken}`);
                }
            },
        ],
        beforeError: [
            async error => {
                const response = await error.response.json();
                if (response && response.message) {
                    error.name = 'Erreur serveur';
                    error.message = `${response.message}`;
                    error.code = error?.response?.status;
                    if (response?.code === 190){
                        error.data = response;
                    }
                }
                return error;
            }
        ],
        afterResponse: [
            async (request, options, response) => {
                if (response?.status === 498 && !isRefresh) {
                    isRefresh = true;
                    const refreshToken = getRefreshToken();
                    try {
                        const refreshed = await baseApi.post('utilisateur/refreshToken', {
                            headers: {
                                'Authorization': `Bearer ${refreshToken}`,
                                'Agence' : agence,
                                'Version' : "1.101"
                            }
                        }).json();
                        setTokens(refreshed.accessToken?.jwt, refreshed.refreshToken?.jwt);
                        request.headers.set('Authorization', 'Bearer ' + refreshed.accessToken?.jwt);
                    }catch (e) {

                    }
                    isRefresh = false;
                    return ky(request);
                }
            }
        ],
    }
});

export default api;