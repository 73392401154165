import {useMutation} from "react-query";
import toast from "react-hot-toast";
import api from "@services/api";

export default function useResendMailForOneCorrespondant() {

    return useMutation(resend, {
        onSuccess: () => {
            toast.success('Le mail a été envoyé', {
                duration:5000
            });
        },
    });
}

function resend(id) {

    if (id != null)
        return api.post(`correspondantEmail/${id}/envoyerEmail`).json();

}