import { proxy } from "valtio";

const speedBusinessProxy = proxy({
    addAutoParticipant:false,
    showEditSpeedBusinessModal: false,
    showFiltresParticipants:false,
    showJourJModal: false,
    showMaitreDuTempsModal: false,
    showLancementSpeedBusinessModal: false,
    showLastCheckSuppleantModal:false,
    showGestionReceptionnisteModal:false,
    showMaitreDuTempsTutoAndSendMailModal: false,
    showEmailAndSmsPassageModal:false,
    showPresencesModal:false,
    speedBusiness:null,
    destinataireIsChecked:false,
    destinataireAlertIsAdd: false,
    step: 0,
    loading: false,
    idInvitation:null,
    websocket: {
        send : (cache) => {
            console.log("ws hs")
        },
        sendParticipant : (cache) => {
            console.log("ws hs")
        },
    },
    objectCurrentPresentationSpeed: null,
    tabParticipantArrive: [],
    tabParticipantArriveTable: [],
    updateParticipantsArrive:0,
    diffServeurTimestamp: null,
    showEditSpeedBusiness:(speedBusiness=null, idInvitation = null) => {
        speedBusinessProxy.showEditSpeedBusinessModal = true;
        speedBusinessProxy.showFiltresParticipants = false;
        speedBusinessProxy.showJourJModal = false;
        speedBusinessProxy.speedBusiness = speedBusiness;
        speedBusinessProxy.idInvitation = idInvitation;
        speedBusinessProxy.addAutoParticipant = false;
        speedBusinessProxy.step = 0;
    },
    showJourJ:(speedBusiness) => {
        speedBusinessProxy.showJourJModal = true;
        speedBusinessProxy.showFiltresParticipants = false;
        speedBusinessProxy.showEditSpeedBusinessModal = false;
        speedBusinessProxy.speedBusiness = speedBusiness;
        speedBusinessProxy.addAutoParticipant = false;
        speedBusinessProxy.step = 0;
    },
    showMaitreDuTemps:(speedBusiness) => {
        speedBusinessProxy.showMaitreDuTempsModal = true;
        speedBusinessProxy.showJourJModal = false;
        speedBusinessProxy.showFiltresParticipants = false;
        speedBusinessProxy.showEditSpeedBusinessModal = false;
        speedBusinessProxy.speedBusiness = speedBusiness;
        speedBusinessProxy.step = 0;
        speedBusinessProxy.addAutoParticipant = false;
    },
    showLancementSpeedBusiness:(speedBusiness) => {
        speedBusinessProxy.showLancementSpeedBusinessModal = true;
        speedBusinessProxy.showMaitreDuTempsModal = false;
        speedBusinessProxy.showJourJModal = false;
        speedBusinessProxy.showFiltresParticipants = false;
        speedBusinessProxy.showEditSpeedBusinessModal = false;
        speedBusinessProxy.speedBusiness = speedBusiness;
        speedBusinessProxy.addAutoParticipant = false;
        speedBusinessProxy.step = 0;
    },
    showMaitreDuTempsTutoAndSendMail : (speedBusiness) => {
        speedBusinessProxy.showMaitreDuTempsTutoAndSendMailModal = true;
        speedBusinessProxy.showLancementSpeedBusinessModal = false;
        speedBusinessProxy.showMaitreDuTempsModal = false;
        speedBusinessProxy.showJourJModal = false;
        speedBusinessProxy.showFiltresParticipants = false;
        speedBusinessProxy.showEditSpeedBusinessModal = false;
        speedBusinessProxy.speedBusiness = speedBusiness;
        speedBusinessProxy.addAutoParticipant = false;
        speedBusinessProxy.step = 0;
    },
    showPresences : (speedBusiness) => {
        speedBusinessProxy.showPresencesModal = true;
        speedBusinessProxy.addAutoParticipant = true;
        speedBusinessProxy.showMaitreDuTempsTutoAndSendMailModal = false;
        speedBusinessProxy.showLancementSpeedBusinessModal = false;
        speedBusinessProxy.showMaitreDuTempsModal = false;
        speedBusinessProxy.showJourJModal = false;
        speedBusinessProxy.showFiltresParticipants = false;
        speedBusinessProxy.showEditSpeedBusinessModal = false;
        speedBusinessProxy.speedBusiness = speedBusiness;
        speedBusinessProxy.step = 0;
    },
    showEmailAndSmsPassage : (speedBusiness) => {
        speedBusinessProxy.showEmailAndSmsPassageModal = true;
        speedBusinessProxy.showPresencesModal = false;
        speedBusinessProxy.showMaitreDuTempsTutoAndSendMailModal = false;
        speedBusinessProxy.showLancementSpeedBusinessModal = false;
        speedBusinessProxy.showMaitreDuTempsModal = false;
        speedBusinessProxy.showJourJModal = false;
        speedBusinessProxy.showFiltresParticipants = false;
        speedBusinessProxy.showEditSpeedBusinessModal = false;
        speedBusinessProxy.speedBusiness = speedBusiness;
        speedBusinessProxy.addAutoParticipant = false;
        speedBusinessProxy.step = 0;
    },
    showLastCheckSuppleant : (speedBusiness) => {
        speedBusinessProxy.showLastCheckSuppleantModal = true;
        speedBusinessProxy.showEmailAndSmsPassageModal = false;
        speedBusinessProxy.showPresencesModal = false;
        speedBusinessProxy.showMaitreDuTempsTutoAndSendMailModal = false;
        speedBusinessProxy.showLancementSpeedBusinessModal = false;
        speedBusinessProxy.showMaitreDuTempsModal = false;
        speedBusinessProxy.showJourJModal = false;
        speedBusinessProxy.showFiltresParticipants = false;
        speedBusinessProxy.showEditSpeedBusinessModal = false;
        speedBusinessProxy.speedBusiness = speedBusiness;
        speedBusinessProxy.addAutoParticipant = false;
        speedBusinessProxy.step = 0;
    },
    showGestionReceptionniste: (speedBusiness) => {
        speedBusinessProxy.showGestionReceptionnisteModal = true;
        speedBusinessProxy.showLastCheckSuppleantModal = false;
        speedBusinessProxy.showEmailAndSmsPassageModal = false;
        speedBusinessProxy.showPresencesModal = false;
        speedBusinessProxy.showMaitreDuTempsTutoAndSendMailModal = false;
        speedBusinessProxy.showLancementSpeedBusinessModal = false;
        speedBusinessProxy.showMaitreDuTempsModal = false;
        speedBusinessProxy.showJourJModal = false;
        speedBusinessProxy.showFiltresParticipants = false;
        speedBusinessProxy.showEditSpeedBusinessModal = false;
        speedBusinessProxy.speedBusiness = speedBusiness;
        speedBusinessProxy.addAutoParticipant = false;
        speedBusinessProxy.step = 0;
    }
});

export default speedBusinessProxy;