import {useEffect, useState} from "react";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import {getLoadOptionsPersonnePartenaire} from "../../../requests/selectionRequests/personnePartenaire";
import useGetPersonneUtilisateur from "../../../requests/utilisateur/useGetPersonneUtilisateur";

function PersonnePartenaireSelection({ stateAutheurSujet, logChange, utilisateur }) {
    const field  = stateAutheurSujet;
    const utilisateurAgence = useGetPersonneUtilisateur();

    const [selectedValue, setSelectedValue] = useState(field ? {id:field.id,title:field.prenom+" "+field.nom} : null);
    const handleChange = value => {
        if (value.utilisateur){
            setSelectedValue({id : value.id, title: value.title});
            logChange(value.utilisateur)
        }else {
            setSelectedValue({id : value.id.id, title: value.id.prenom+" "+value.id.nom});
            logChange(value.id)
        }
    }
    const loadOptions = getLoadOptionsPersonnePartenaire();
    const debouncedLoadOptions = debounce(loadOptions, 500);
    const defaultOptions = utilisateurAgence?.data?.data?.map(x => {
        return {
            id: x.id,
            title: x.prenom + " " + x.nom + " (Utilisateur)",
            utilisateur : x
        }
    })

    useEffect(() => {
        const awaitLoadOptions = async () => {
            await loadOptions
        }
        awaitLoadOptions()
        // loadOptions?.data?.data?.unshift({
        //     id: utilisateur?.utilisateur?.id,
        //     text: utilisateur?.utilisateur?.prenom + " " + utilisateur?.utilisateur?.nom + " (Utilisateur)"
        // })
    }, [loadOptions])

    useEffect(() => {
        setSelectedValue(field != null ? {id:field.id,title:field.prenom+" "+field.nom} : null)
    }, [field])

    return <AsyncSelect
        placeholder={"Rechercher une personne"}
        noOptionsMessage={() => "Aucun résultat"}
        loadingMessage={() => "Chargement..."}
        minLength={0}
        styles={{
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? 'white' : '#716d66',
                backgroundColor: state.isSelected ? '#4fc9da' : 'white',
                "&:hover":{
                    backgroundColor: !state.isSelected ? 'rgba(79,201,218,0.3)' : "#4fc9da",
                }
            }),
            control: (base) => ({
                ...base,
                backgroundColor : "#FFF",
                borderRadius : ".95rem",
                color: '#716d66'
            }),
            menu: (provided, state) => {
                return {
                    ...provided,
                    zIndex: 9999
                }
            }
        }}
        cacheOptions
        defaultOptions={defaultOptions}
        value={selectedValue}
        getOptionLabel={e => e.title}
        getOptionValue={e => e.id}
        loadOptions={debouncedLoadOptions}
        onChange={ (selected) => {
            handleChange(selected)
            // field.onChange(selected.id)
        }}
    />
}

export default PersonnePartenaireSelection;