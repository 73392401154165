import illustration from "@assets/illustrations/18.png";
import { Button } from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";

function NotFoundView() {
    const navigate = useNavigate();
    const {agence} = useParams();

    return <div className='d-flex flex-column flex-root'>
        <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
            <img className='mw-100 mb-15 h-lg-450px' src={illustration} alt='' />
            <h1 className='fw-bolder mb-10 text-muted'>Cette page est introuvable</h1>
            <Button onClick={() => navigate('/'+(agence ? agence : "plink"), { replace: true })}>Retourner à l'accueil</Button>
        </div>
    </div>;
}

export default NotFoundView;