import { Alert, Dropdown, Spinner } from "react-bootstrap";
import useGetFooters from "@requests/communication/footer/useGetFooters";


function FooterSelectionDropDown({ children, className, onChange }) {
    const footers = useGetFooters();

    return <Dropdown>
        <Dropdown.Toggle className={`${className ?? ''} hide-after`}>
            {children}
        </Dropdown.Toggle>
        <Dropdown.Menu className='fs-6 w-400px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
            {footers.isLoading && <div className='text-center my-5'>
                <Spinner animation='border' />
            </div>}
            {footers.isError &&
                <Alert variant='danger' className='m-2'>
                    {footers.error?.message ?? 'Erreur'}
                </Alert>
            }
            {footers.isSuccess &&
                <div className='mh-300px scroll-y'>
                    {footers.data?.data?.map(footer => <div className='menu-item px-3' key={footer.id}>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => onChange(footer)}
                        >
                            {footer.nom}
                        </Dropdown.Item>
                    </div>)}
                </div>
            }
        </Dropdown.Menu>
    </Dropdown>;
}

export default FooterSelectionDropDown;