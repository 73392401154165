import './SideMenu.css';
import { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Arr001 } from '@assets/icons/arr001.svg';
import {useQueryClient} from "react-query";

function SideMenuItem({ children, title, to, childrenTo }) {
    const [showChildren, setShowChildren] = useState(false);
    const location = useLocation().pathname;
    const active = location.startsWith(to);
    const queryClient = useQueryClient();

    // Force show children when children is active
    const childrenActive = location.startsWith(childrenTo);
    useEffect(() => {
        if (childrenActive)
            setShowChildren(true);
    }, [childrenActive]);

    return <div className={`menu-item menu-accordion ${showChildren ? 'show hover' : ''}`}>
        <Link
            className={`menu-link ${active ? 'active' : ''}`}
            onClick={(children != null && !childrenActive) ?
                () => {
                    setShowChildren(!showChildren)
                } :
                () => queryClient.invalidateQueries()}
            to={to ?? '#'}
        >
            <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
            </span>
            <span className='menu-icon'>
                <span className='svg-icon svg-icon-5'><Arr001 /></span>
            </span>
            <span className='menu-title'>{title}</span>
            {children != null && <span className=''></span>}
        </Link>
        {(children != null && showChildren) && <div className='menu-sub menu-sub-accordion menu-active-bg show'>
            {children}
        </div>}
    </div>;
}

function SideMenu() {
    const {agence} = useParams();

    return <div className='menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold'>
        <SideMenuItem title='Accueil' to={'/' + agence + '/accueil'} />
        <SideMenuItem title='Annuaire' to={'/' + agence + '/annuaire'} />
        <SideMenuItem title='Communication' childrenTo={'/' + agence + '/communication'}>
            <SideMenuItem title='Mails' to={'/' + agence + '/communication/mails'} />
            <SideMenuItem title='Invitations' to={'/' + agence + '/communication/invitations'} />
            <SideMenuItem title='Accès partenaire' to={'/' + agence + '/communication/acces'} />
            <SideMenuItem title='Publications mobile' to={'/' + agence + '/communication/publicationsMobile'} />
            {/*<SideMenuItem title='Publications réseaux sociaux' to={'/' + agence + '/communication/publicationsReseauxSociaux'} />*/}
            <SideMenuItem title='SMS' to={'/' + agence + '/communication/sms'} />
        </SideMenuItem>
        <SideMenuItem title='Forum' to={'/' + agence + '/forum'} />
        <SideMenuItem title='Générateur de rencontre' to={'/' + agence + '/speedBusiness'} />
        <SideMenuItem title='Facturation' childrenTo={'/'+agence+'/facturation'}>
            <SideMenuItem title='Facturation' to={'/' + agence + '/facturation/facture'} />
            <SideMenuItem title='Réversion' to={'/' + agence + '/facturation/reversion'} />
        </SideMenuItem>
    </div>;
}

export default SideMenu;