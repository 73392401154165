import { ReactComponent as Logo } from "@assets/logo.svg";
import {Button} from "react-bootstrap";
import useValidInvitationByMail from "../../../requests/communication/invitations/useValidInvitationByMail";
import useInvalidInvitationByMail from "../../../requests/communication/invitations/useInvalidInvitationByMail";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

function ValidInvalidInvitationView() {

    const validInvitation = useValidInvitationByMail();
    const invalidInvitation = useInvalidInvitationByMail();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get('ref') ? urlParams.get('ref').replace(/ /g, "+") : "";
    const snapAuth = useSnapshot(auth);


    return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
            <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                    <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Bienvenue sur Partenaire Link</h1>
                    <p className='fw-bold fs-2 text-white'>
                        Gérez les partenaires de votre
                        <br />club sportif, et plus encore !
                    </p>
                </div>
            </div>
        </div>
        <div className='d-flex flex-column flex-lg-row-fluid py-10 card'>
            <div className='d-flex flex-center flex-column flex-column-fluid card-body'>
                <div className='w-lg-500px p-10 p-lg-15 mx-auto'>
                    <div className='text-center mb-10'>
                        <h1 className='text-dark mb-3'>Valider l'invitation</h1>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <Button
                            onClick={() => validInvitation.mutate(ref)}
                            className="btn btn-secondary me-2">
                            Valider
                        </Button>
                        <Button
                            onClick={() => invalidInvitation.mutate(ref)}
                            className="btn btn-danger">
                            Invalider
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    </div>;

}

export default ValidInvalidInvitationView;
