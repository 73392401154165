import { proxy } from "valtio";
import { clearTokens, setTokens } from "./token";

//todo ajouter les tabId pour invitation et autre...

const auth = proxy({
    srcLogo : "/logo.svg",
    utilisateur: null,
    agence: null,
    parametreFacturation: null,
    tabIdMailVerrou:[],
    tabIdInvitationVerrou:[],
    tabIdSmsVerrou:[],
    tabIdPublicationVerrou:[],
    idConnexion : null,
    websocket: {
        send : (cache) => {
            console.log("ws hs")
        }
    },
    login: ({ utilisateur, agence, parametreFacturation, accessToken, refreshToken}) => {
        setTokens(accessToken, refreshToken);
        auth.utilisateur = utilisateur;
        auth.agence = agence;
        auth.parametreFacturation = parametreFacturation;
        auth.websocket = {
            send : (cache) => {
                console.log("ws hs")
            }
        };
    },
    logout: () => {
        clearTokens();
        auth.utilisateur = null;
        auth.agence = null;
        auth.parametreFacturation = null;
        auth.websocket = {
            send : (cache) => {
                console.log("ws hs")
            }
        };
    },
});

export default auth;