import BlockUI from "../../components/BlockUI/BlockUI";
import useGetUrl from "../../requests/stripe/useGetUrl";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";
import useGetEtatCompte from "../../requests/stripe/useGetEtatCompte";
import {Modal} from "react-bootstrap";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEye, faTimes} from "@fortawesome/free-solid-svg-icons";
import LoadingView from "../LoadingView/LoadingView";

function ParametresStripeView() {
    const getUrl = useGetUrl();
    const snap = useSnapshot(auth);
    const etatCompte = useGetEtatCompte();
    const [showModalSettings, setShowModalSettings] = useState(false);

    if (getUrl.isLoading || etatCompte.isLoading){
        return <LoadingView/>
    }

    if (snap?.parametreFacturation?.compteStripeIsConfigure){
        return <BlockUI loading={getUrl.isLoading || etatCompte.isLoading}>
            <div className='alert alert-info'>
                <div className="mt-2 text-center">
                    <p>
                        Afin de modifier les paramétres votre compte stripe <a className='badge badge-info' href={getUrl.data ? getUrl.data.url : ""}>cliquez ici</a>
                    </p>
                    <p
                        onClick={() => {
                            setShowModalSettings(true)
                        }}
                        className="badge badge-info cursor-pointer">Voir l'état du compte stripe <FontAwesomeIcon icon={faEye}/></p>
                </div>
                {modalEtatCompte()}
            </div>
        </BlockUI>;
    }else{
        return <BlockUI loading={getUrl.isLoading || etatCompte.isLoading}>
            <h1>Paramètres stripe</h1>
            <div className='alert alert-info'>
                <div className="mt-2 text-center">
                    <p>
                        Afin de paramétrer votre compte stripe et le lier a votre espace responsable <a className='badge badge-info' href={getUrl.data ? getUrl.data.url : ""}>cliquez ici</a>
                    </p>
                    <p
                        onClick={() => {
                            setShowModalSettings(true)
                        }}
                        className="badge badge-info cursor-pointer">Voir l'état du compte stripe <FontAwesomeIcon icon={faEye}/></p>
                </div>
                {modalEtatCompte()}
            </div>
        </BlockUI>;
    }


    function paramsIsOk(){
        let result = [];
        if (etatCompte.isSuccess && !etatCompte.data.autorisations.paiementParCarte){
            result.push(paramPasOkLine("Autorisation paiement par carte bancaire"))
        }else {
            result.push(paramOkLine("Autorisation paiement par carte bancaire"))
        }

        if (etatCompte.isSuccess && !etatCompte.data.autorisations.paiementParVirement){
            result.push(paramPasOkLine("Autorisation paiement par virement"))
        }else {
            result.push(paramOkLine("Autorisation paiement par virement"))
        }

        if (etatCompte.isSuccess && !etatCompte.data.autorisations.chargeSurPaiement){
            result.push(paramPasOkLine("Autorisation charge sur paiement"))
        }else {
            result.push(paramOkLine("Autorisation charge sur paiement"))
        }

        if (etatCompte.isSuccess && !etatCompte.data.autorisations.transfertDesFonds){
            result.push(paramPasOkLine("Autorisation transfert des fonds"))
        }else {
            result.push(paramOkLine("Autorisation transfert des fonds"))
        }

        if (etatCompte.isSuccess && !etatCompte.data.autorisations.paiements){
            result.push(paramPasOkLine("Autorisation paiements"))
        }else {
            result.push(paramOkLine("Autorisation paiements"))
        }

        if (etatCompte.isSuccess && !etatCompte.data.profilBusinessIdentifie){
            result.push(paramPasOkLine("Profil business indentifié"))
        }else {
            result.push(paramOkLine("Profil business indentifié"))
        }

        if (etatCompte.isSuccess && !etatCompte.data.detailDuCompteAjoute){
            result.push(paramPasOkLine("Détails du compte renseignés"))
        }else {
            result.push(paramOkLine("Détails du compte renseignés"))
        }

        if (etatCompte.isSuccess && !etatCompte.data.compteBancaireConfigure){
            result.push(paramPasOkLine("Compte bancaire configuré"))
        }else {
            result.push(paramOkLine("Compte bancaire configuré"))
        }

        if (etatCompte.isSuccess && !etatCompte.data.acceptationContratUtilisationStripe){
            result.push(paramPasOkLine("Contrat d'utilisation stripe accepté"))
        }else {
            result.push(paramOkLine("Contrat d'utilisation stripe accepté"))
        }

        if (etatCompte.isSuccess && etatCompte.data.elementRequisProchainement.etat){
            result.push(paramPasOkLine("Aucuns éléments n'est requis prochainement"))
        }else {
            result.push(paramOkLine("Aucuns éléments n'est requis prochainement"))
        }

        if (etatCompte.isSuccess && etatCompte.data.elementRequisActuellement.etat){
            result.push(paramPasOkLine("Aucuns éléments n'est requis actuellement"))
        }else {
            result.push(paramOkLine("Aucuns éléments n'est requis actuellement"))
        }

        return result;
    }

    function paramOkLine(title){
        return <tr className="text-center">
            <td className="text-start ps-6">
                <div className="fw-bold fs-4 text-gray-800">{title}</div>
            </td>
            <td>
                <FontAwesomeIcon icon={faCheck} color={"green"}/> Ok
            </td>
        </tr>
    }

    function paramPasOkLine(title){
        return <tr className="text-center">
            <td className="text-start ps-6">
                <div className="fw-bold fs-4 text-gray-800">{title}</div>
            </td>
            <td>
                <FontAwesomeIcon icon={faTimes} color={"red"}/> Action à réaliser
            </td>
        </tr>
    }

    function modalEtatCompte(){
        return <Modal
            show={showModalSettings}
            onHide={() => setShowModalSettings(false)}
            size='lg'
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Etat du compte stripe</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="m-auto table mt-4 table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                    <tr className="fw-bolder fs-6 text-gray-800 text-center border-0 bg-light">
                        <th className="w-400px rounded-start"></th>
                        <th>Aucune action ne doit être réalisée.</th>
                    </tr>
                    </thead>
                    <tbody className="border-bottom border-dashed">
                    {paramsIsOk()}
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    }


}

export default ParametresStripeView;