import {Card, Button, Stack, Modal} from "react-bootstrap";
import { BtSwal } from "@utils/alerts/sweetAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck, faFileDownload, faTimes} from "@fortawesome/free-solid-svg-icons";
import { useMemo, useRef, useState } from "react";
import MetaTable from "@components/MetaTable/MetaTable";
import SearchBar from "@components/SearchBar/SearchBar";
import { useForm } from "react-hook-form";
import { useDebounce } from 'use-debounce';
import ProfilFilter from "./ProfilFilter/ProfilFilter";
import {ReactComponent as Gen016} from "@assets/icons/gen016.svg";
import EditModal from "./EditModal/EditModal";
import accesProxy from "../../../proxies/acces";
import useSendAccesPartenaire from "../../../requests/communication/accesPartenaire/useSendAccesPartenaire";
import useGetMailAccesPartenaire from "../../../requests/parametres/accesPartenaires/useGetMailAccesPartenaire";
import LoadingView from "../../LoadingView/LoadingView";
import { ReactComponent as Cod001 } from "@assets/icons/cod001.svg";
import AccesMailSettings from "../../ParametresView/AccesMailSettings/AccesMailSettings";


function AccesPartenaireView() {
    const tableRef = useRef();
    const { register, watch, setValue } = useForm();
    const search = watch('search')
    const [searchFilter] = useDebounce(search, 300);
    const [idFilter] = useDebounce(watch('id'), 300);
    const [entrepriseFilter] = useDebounce(watch('entreprise'), 300);
    const [selectedProfil, setSelectedProfil] = useState();
    const sendAcces = useSendAccesPartenaire();
    const mailAccesPartenaire = useGetMailAccesPartenaire();
    const [showModalSettings, setShowModalSettings] = useState(false);
    const [currentComponentSettings, setCurrentComponentSettings] = useState(null);

    const colSearch = useMemo(() => {
        return {
            'personne.id': idFilter,
            'entreprise.nom': entrepriseFilter,
            'personne.tabProfils.id': selectedProfil,
            'personne.tabProfils.isPartenaire' : 1
        };
    }, [idFilter, entrepriseFilter, selectedProfil]);

    const columns = useMemo(() => [
        {
            Header: () => null,
            id: 'wrench',
            Cell: ({ row }) =>
                <div className="d-flex justify-content-center">
                    <a
                        onClick={() => {
                            if (!(row.original.personne?.tabProfils != null && !!Object.values(row.original.personne?.tabProfils).find(item => item.isPartenaire))){
                                BtSwal.fire({
                                    title: `<b>${row.original.personne.prenom} ${row.original.personne.nom} n'est pas partenaire !</b> <br> <br> <small>Envoi des accès impossible.</small>`,
                                    showDenyButton: false,
                                    confirmButtonText: `Compris`,
                                    icon: 'error',
                                })
                            }else {
                                BtSwal.fire({
                                    title: `<b>Êtes-vous sûr de vouloir envoyer les accès à ${row.original.personne.prenom} ${row.original.personne.nom} ?</b> <br> <br> <small>Les accès seront envoyés par mail.</small>`,
                                    showDenyButton: true,
                                    confirmButtonText: `Oui`,
                                    denyButtonText: `Annuler`,
                                    icon: 'info',
                                }).then((result) => {
                                    if (result.isConfirmed){
                                        sendAcces.mutate(row.original.personne.id)
                                        BtSwal.fire('Les accès ont été envoyés.', '', 'success')
                                    } else if (result.isDenied) {
                                        BtSwal.fire('Les accès n\'ont pas été envoyés.', '', 'info')
                                    }
                                })
                            }
                        }}
                        className='svg-icon svg-icon-1 cursor-pointer'><img width={20} src="/icon/key.svg" alt="memoCicleSvg"/></a></div>,
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: 'Id',
            accessor: 'personne.id',
            minWidth: 70,
            maxWidth: 70,
        },
        {
            Header: 'Prenom',
            accessor: 'personne.prenom',
            minWidth: 100,
        },
        {
            Header: 'Nom',
            accessor: 'personne.nom',
            minWidth: 100,
        },
        {
            Header: 'prenomNom',
            accessor: 'personne.prenomNom',
            hidden: true
        },
        {
            Header: 'nomPrenom',
            accessor: 'personne.nomPrenom',
            hidden: true
        },
        {
            Header: 'Entreprise',
            accessor: 'entreprise.nom',
            minWidth: 200,
        },
        {
            Header: 'Fonction',
            accessor: 'personne.fonction',
            minWidth: 100,
        },
        {
            Header: 'Email',
            accessor: 'personne.email',
            minWidth: 350,
        },
        {
            Header: 'Tel1',
            accessor: 'personne.tel1',
            minWidth: 130,
            maxWidth: 130
        },
        {
            Header: 'Tel2',
            accessor: 'personne.tel2',
            minWidth: 130,
            maxWidth: 130
        },
        {
            accessor: 'personne.tabProfils.id',
            hidden: true,
            searchOperator: "="
        },
        {
            accessor: 'personne.tabProfils.isPartenaire',
            hidden: true,
            searchOperator: "="
        },
    ], []);

    if (mailAccesPartenaire.isLoading){
        return <LoadingView/>
    }

    if (paramsIsOk() !== true){
        return  <Card style={{height: "80vh"}}><div className="table-responsive">
            <h1 className="text-center mt-3
            ">Paramètres obligatoire avant l'envoi des accès aux partenaires</h1>
            <table className="m-auto table mt-4 table-row-dashed w-800px table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                <tr className="fw-bolder fs-6 text-gray-800 text-center border-0 bg-light">
                    <th className="w-400px rounded-start"></th>
                    <th className="w-140px">Paramétré</th>
                </tr>
                </thead>
                <tbody className="border-bottom border-dashed">
                {paramsIsOk()}
                </tbody>
            </table>
            <Modal
                show={showModalSettings}
                onHide={() => setShowModalSettings(false)}
                size='xl'
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Paramètres accès partenaire</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentComponentSettings}
                </Modal.Body>
            </Modal>
        </div></Card>
    }else {
        return <>
            <Card>
                <Card.Body>
                    <ProfilFilter className='mb-8 text-center' selected={selectedProfil} onChange={(value) => setSelectedProfil(value)} />
                    <div className='d-flex flex-stack flex-wrap'>
                        <SearchBar
                            solid
                            {...register('search')}
                            onClear={search?.length > 0 ? () => {
                                setValue('search', '');
                            } : null}
                        />
                        <Stack className='ms-auto' direction='horizontal' gap={2}>
                            <Button
                                onClick={() => accesProxy.showEditAcces(null)}
                                variant='secondary'>
                                <span className='svg-icon svg-icon-1 me-2'><Gen016 /></span>
                                Envoyer aux destinataires
                            </Button>
                        </Stack>
                    </div>
                    <MetaTable
                        ref={tableRef}
                        className='mt-5'
                        height={500}
                        url='annuaire'
                        keys={['annuaire']}
                        columns={columns}
                        search={searchFilter}
                        colSearch={colSearch}
                    />
                    <div className='d-flex mt-3'>
                        <Button
                            variant='secondary'
                            className='ms-auto'
                            onClick={() => {
                                tableRef.current?.export();
                            }}
                        >
                            <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                            Exporter
                        </Button>
                    </div>
                </Card.Body>
            </Card>
            <EditModal />
        </>;
    }

    function paramsIsOk(){
        let result = [];
        let allIsOk = true;
        //check header
        if (mailAccesPartenaire.isSuccess && mailAccesPartenaire.data.corpsHtml == null){
            result.push(paramPasOkLine("Mail accès partenaire", <AccesMailSettings />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Mail accès partenaire", <AccesMailSettings />))
        }

        return allIsOk ? allIsOk : result;
    }

    function paramOkLine(title, settingsConponent){
        return <tr className="text-center">
            <td className="text-start ps-6">
                <div className="fw-bold fs-4 text-gray-800">{title}</div>
            </td>
            <td>
                <FontAwesomeIcon icon={faCheck} color={"green"}/>
                <span
                    style={{fill: "black"}}
                    onClick={() => {
                        setCurrentComponentSettings(settingsConponent)
                        setShowModalSettings(true)
                    }} className='ms-2 svg-icon svg-icon-1 cursor-pointer'><Cod001/></span>
            </td>
        </tr>
    }

    function paramPasOkLine(title, settingsConponent){
        return <tr className="text-center">
            <td className="text-start ps-6">
                <div className="fw-bold fs-4 text-gray-800">{title}</div>
            </td>
            <td>
                <FontAwesomeIcon icon={faTimes} color={"red"}/>
                <span
                    style={{fill: "black"}}
                    onClick={() => {
                        setCurrentComponentSettings(settingsConponent)
                        setShowModalSettings(true)
                    }} className='ms-2 svg-icon svg-icon-1 cursor-pointer'><Cod001/></span>

            </td>
        </tr>
    }
}

export default AccesPartenaireView;