import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetResponseByIdSujet(id) {

    let filters = {
        columns: {},
        order: {}
        // search: { value: search ?? '', regex: false }
    };

    filters.columns[0] = {
        data: "idSujet",
        searchable: true,
        orderable: true,
        search: {
            value: id,
            regex: false,
        }
    }

    filters.columns[1] = {
        data: "id",
        searchable: true,
        orderable: true,
        search: {
            value: "",
            regex: false,
        }
    }

    filters.order[0] = {
        column:1,
        dir: "desc"
    }

    return useQuery(
        ["messageSujetForum"+id],
        async () => {
            // await new Promise(resolve => setTimeout(resolve, 3000))
            return api.get("messageSujetForum", {
                searchParams: Qs.stringify({
                    start: 0,
                    length: 5000,
                    ...filters,
                }, { arrayFormat: 'brackets' }),
            }).json();
        }
    );

}