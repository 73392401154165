import {useMutation, useQueryClient} from "react-query";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import baseApi from "../../../services/apiPublic";

export default function useInvalidMailByMail() {

    const queryClient = useQueryClient();
    return useMutation(invalidMail, {
        onSuccess: () => {
            queryClient.invalidateQueries('emails');
            // snapAuth.websocket.send("emails")
            toast.success('Le mail a été invalidé', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function invalidMail(ref) {

    if (ref != null)
        return baseApi.post(`email/invalider`, {json : {ref : ref}}).json();

}