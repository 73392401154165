import React, {useEffect, useState} from "react";
import Cropper from "react-cropper";
import {v4 as uuidv4} from "uuid";
import useUploadFile from "../../requests/upload/useUploadFile";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import invitationsProxy from "../../proxies/invitations";



function CropperComponent({stateModal, setStateModal}) {
    const [image, setImage] = useState(invitationsProxy?.invitation?.uploadInscriptionLibre?.urlOriginale ? invitationsProxy?.invitation?.uploadInscriptionLibre?.urlOriginale : "");
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();

    const uploadFile = useUploadFile({
        type: 'invitationInscriptionLibre',
        onSuccess: (data) => {
            invitationsProxy.invitation.uploadInscriptionLibre = data
            setStateModal(false)
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'upload de l'image", err.message);
        },
    });

    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        if (image){
            if (typeof cropper !== "undefined") {
                cropper.getCroppedCanvas().toBlob((blob) => {
                    let file = new File([blob], uuidv4()+".png")
                    uploadFile.mutate(file)
                    setCropData(cropper.getCroppedCanvas().toDataURL());
                }, 'image/png')
            }
        }else {
            showErrorAlert("Image manquante", "Merci d'importer une image avant de recadrer");
        }
    };
    useEffect(() => {
        if (cropper){
            document.getElementById("cropperId").children[0].children[0].addEventListener('zoom', (event) => {
                if (event.detail.ratio > event.detail.oldRatio && !((cropper.getCroppedCanvas().width > cropper.getCropBoxData().width) && (cropper.getCroppedCanvas().height > cropper.getCropBoxData().height))){
                    event.preventDefault()
                }
            });
        }
    })


    return (
        <div className="row">
            <div id={'cropperId'} className="col-lg-8">
                <Cropper
                    style={{ height: 500, width: 500 }}
                    zoomTo={1}
                    // aspectRatio={16/8}
                    preview=".img-preview"
                    src={image}
                    viewMode={1}
                    dragMode={"move"}
                    toggleDragModeOnDblclick={false}
                    cropBoxResizable={true}
                    background={false}
                    responsive={false}
                    zoomOnWheel={true}
                    // scalable={true}
                    // autoCropArea={1}
                    checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                    guides={true}
                />
            </div>
            <div className="col-lg-4">
                <h2>Aperçu</h2>
                <div className="cropper-preview clearfix d-flex flex-wrap mb-3">
                    <div id="cropper-preview-lg" className="img-preview preview-lg img-fluid mb-3"
                         style={{height: 281, width: 281, overflow: "hidden", backgroundColor: "#f7f7f7"}}></div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <label className="btn btn-primary btn-upload me-1" title="Upload image file">
                        <input type="file" onChange={onChange} style={{display: "none"}} id="inputImage" name="file" accept="image/*"/>
                        <span className="kt-tooltip" data-toggle="tooltip" title="Import image">
                                                                        <span className="fa fa-upload"></span>
                                                                    </span>
                    </label>
                    <button onClick={()=> {
                        if ((cropper.getCroppedCanvas().width > cropper.getCropBoxData().width) && (cropper.getCroppedCanvas().height > cropper.getCropBoxData().height)){
                            cropper.zoom(0.1)
                        }
                    }
                    } type="button" className="btn btn-primary me-1" data-method="zoom" data-option="0.1"
                            title="Zoom In">
                                                                    <span data-toggle="tooltip" title="cropper.zoom(0.1)">
                                                                        <span className="fa fa-search-plus"></span>
                                                                    </span>
                    </button>
                    <button onClick={()=> {
                        cropper.zoom(-0.1)
                    }
                    } type="button" className="btn btn-primary me-1" data-method="zoom" data-option="0.1"
                            title="Zoom Out">
                                                                    <span data-toggle="tooltip" title="cropper.zoom(0.1)">
                                                                        <span className="fa fa-search-minus"></span>
                                                                    </span>
                    </button>
                </div>
                <div className="btn-group d-flex">
                    <button
                        onClick={()=> {
                            cropper.rotate(-45)
                        }}
                        type="button" className="btn btn-primary mb-3" data-method="rotate" data-option="-45"
                        title="Rotate Left">
																<span data-toggle="tooltip" title="cropper.rotate(-45)">
																	<span className="fa fa-undo-alt"></span>
																</span>
                    </button>
                    <button
                        onClick={()=> {
                            cropper.rotate(45)
                        }}
                        type="button" className="btn btn-primary mb-3" data-method="rotate" data-option="45"
                        title="Rotate Right">
																<span data-toggle="tooltip" title="cropper.rotate(45)">
																	<span className="fa fa-redo-alt"></span>
																</span>
                    </button>
                </div>
                <button className="d-block btn btn-primary m-auto" onClick={getCropData}>Recadrer</button>
            </div>
            <br style={{ clear: "both" }} />
        </div>
    );
}

export default CropperComponent;
