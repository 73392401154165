import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

export default function useDeletePublication() {

    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    

    return useMutation(deletePublication, {
        onSuccess: () => {
            queryClient.invalidateQueries('publicationMobile');
            snapAuth.websocket.send("publicationMobile")
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

}

function deletePublication(id) {

    if (id != null)
        return api.delete(`publicationMobile/${id}`).json();

}