import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";
import moment from "moment";

export default function useGetLastInvitationsSend() {

    let nowTimestanp = moment().format("X");

    console.log(nowTimestanp)
    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = {
        data: "dateEnvoi",
        searchable: true,
        orderable: true,
        search: {
            value: "",
            regex: false,
        }
    }

    filters.columns[1] = {
        data: "tabPeriodes.debut",
        searchable: true,
        orderable: true,
        searchOperator: ">",
        search: {
            value: nowTimestanp,
            regex: false,
        }
    }

    filters.order[0] = {
        column:0,
        dir: "desc"
    }

    return useQuery(
        ["invitation"],
        async () => {
            return api.get("invitation", {
                searchParams: Qs.stringify({
                    start: 0,
                    length: 3,
                    ...filters,
                }, { arrayFormat: 'brackets' }),
            }).json();
        }
    );

}