import { useEffect, useRef } from 'react';

const $ = require('jquery');
$.select2 = require('select2');

function Select2({ data, selected, onChange, large, solid, closeOnSelect = true, ...props }) {
    const ref = useRef();

    useEffect(() => {
        const $el = $(ref.current);
        $el.select2({
            theme: 'bootstrap5',
            width: '100%',
            selectionCssClass: ':all:',
            data: data,
            closeOnSelect: closeOnSelect,
            language: {
                noResults: function () {
                    return 'Aucun résultat trouvé';
                },
            },
            ...props,
        });

        $el.on(
            'change',
            () => {
                let newData = $el.select2('data');
                newData = newData.map(item => item.id);
                if (onChange != null && newData !== selected)
                    onChange(newData)
            }
        );

        return () => {
            $el.off('select2:select');
            $el.select2('destroy');
        }
    }, []);

    useEffect(() => {
        const $el = $(ref.current);
        if (selected)
            $el.val(selected);
        else
            $el.val('');
        $el.trigger('change.select2');
    }, [selected]);

    return <div>
        <select
            ref={ref}
            className={`form-select ${solid ? 'form-select-solid' : ''} ${large ? 'form-select-lg' : ''}`}
        />
    </div>;
}

export default Select2;