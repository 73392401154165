import {useSnapshot} from "valtio";
import auth from "../../services/auth";

function HomeLoginView(){
    const snapAuth = useSnapshot(auth);

    return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
            <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                    <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Bienvenue sur Partenaire Link</h1>
                    <p className='fw-bold fs-2 text-white'>
                        Gérez les partenaires de votre
                        <br />club sportif, et plus encore !
                    </p>
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-center align-items-center m-auto">
            <h2>Pour vous connecter merci d'utiliser le lien communiqué lors de l'inscription</h2>
        </div>
    </div>

}
export default HomeLoginView;