import BlockUI from "../../../../../components/BlockUI/BlockUI";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import useGetPublications from "../../../../../requests/communication/publications/useGetPublications";
import LoadingView from "../../../../LoadingView/LoadingView";
import useChangePublication from "../../../../../requests/communication/publications/useChangePublication";
import {useSnapshot} from "valtio";
import publicationMobileProxy from "../../../../../proxies/publicationMobile";

const FinalisationStep = forwardRef((_, ref) => {
    const getPublication = useGetPublications();
    const changePublication = useChangePublication();
    const publicationSnap = useSnapshot(publicationMobileProxy);
    const [publications, setPublications] = useState([]);

    useImperativeHandle(ref, () => ({
        save: async () => {
            save()
            return true;
        },
    }));

    useEffect(() => {
        if (getPublication.isSuccess && !publicationSnap.initFinalisation){
            getPublication.refetch().then((data) => {
                setPublications(data.data.data)
                }
            )
            publicationMobileProxy.initFinalisation = true;
        }
    }, [getPublication, publicationSnap.initFinalisation])

    if (getPublication.isLoading){
        return <LoadingView/>
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = Array.from(publications);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setPublications(items);
    }

    function save(){
        let timestamp = Date.now();
        publications.forEach((data) => {
            timestamp = timestamp + 1;
            let tmpData = {
                ...data,
                classement : timestamp
            }
            changePublication.mutate(tmpData);
        } )
    }



    return <BlockUI>
        <div style={{width : "fit-content"}} className="m-auto">
            <div className="d-flex align-items-center">
                <h1 className="me-5">Ordre des publications</h1>
            </div>
            <div className="d-flex">
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                        {(provided) => (
                            <ul
                                className="characters"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {publications.map(({ classement, titre }, index) => {
                                    return (
                                        <Draggable key={classement} draggableId={classement.toString()} index={index}>
                                            {(provided) => (
                                                <li
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <p>{titre}</p>
                                                </li>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    </BlockUI>
})

export default FinalisationStep;