import { useSnapshot } from "valtio";
import invitationsProxy from "@proxies/invitations";
import MetaTable from "../../../components/MetaTable/MetaTable";
import React, { useMemo, useRef } from "react";
import { useQueryClient } from "react-query";
import useChangeCorrespondant from "../../../requests/communication/correspondant/useChangeCorrespondant";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import auth from "../../../services/auth";
import InfoPersonneForSuiviModal from "../../communication/InvitationsView/SuiviModal/InfoPersonneForSuiviModal";
import LoadingView from "../../../views/LoadingView/LoadingView";
import FiltresModal from "./FiltresModal";



function MetaTableDestinataireByInvitation({alertInfo = false, debouncedSearch, columns, stateIdEtatPaiement,stateIdTabInvitationFilter,stateIdOptionParticipation, colSearch}) {
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const tabEtatsInvitationSelect = [
        {id: 0, text: "En attente"},
        {id: 3, text: "Paiement en cours"},
        {id: 1, text: "Accepte"},
        {id: 2, text: "Décline"},
    ]
    const tabPaiementSelect = [
        {id: "NULL", text: "Sans paiement"},
        {id: 0, text: "Carte bancaire Confirmé"},
        {id: 1, text: "Carte bancaire en attente de confirmation"},
        {id: 2, text: "Sur place"},
        {id: 3, text: "Gratuit"},
    ]
    const correspondant = useChangeCorrespondant({
        onSuccess: () => {
            queryClient.invalidateQueries(['invitation', invitationsSnapshot.invitation?.id, 'correspondants']);
            snapAuth.websocket.send("invitation")
            snapAuth.websocket.send(invitationsSnapshot.invitation?.id)
            snapAuth.websocket.send("correspondants")

            toast.success('Réponse modifié', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    const tableRef = useRef();

    const convertTabOptionsParticipation = invitationsSnapshot.invitation?.tabOptionsParticipation ? Object.entries(invitationsSnapshot.invitation?.tabOptionsParticipation).map(exp => exp[1]) : [];
    const data = useMemo(() => {
        return convertTabOptionsParticipation.map(exp => ({
            id: exp.id,
            text: `${exp.designation} -- ${exp.montant} €TTC`,
        }));
    }, [invitationsSnapshot.invitation?.id, invitationsSnapshot.invitation?.tabOptionsParticipation]);


    if (invitationsSnapshot.invitation?.id){
        return <><div>
            <MetaTable
                ref={tableRef}
                className='mt-5'
                height={450}
                url={`invitation/${invitationsSnapshot.invitation?.id}/correspondants`}
                keys={['invitation', invitationsSnapshot.invitation?.id, 'correspondants']}
                columns={columns}
                search={debouncedSearch}
                colSearch={colSearch}
                showFiltres={false}
            />
            {alertInfo && alertInfo}
        </div>
            <InfoPersonneForSuiviModal/>
            <FiltresModal
                stateIdEtatPaiement={stateIdEtatPaiement}
                tabPaiementSelect={tabPaiementSelect}
                stateIdTabInvitationFilter={stateIdTabInvitationFilter}
                tabEtatsInvitationSelect={tabEtatsInvitationSelect}
                data={data}
                stateIdOptionParticipation={stateIdOptionParticipation}
            />
        </>;
    }else {
        return <LoadingView/>
    }
}

export default MetaTableDestinataireByInvitation;