import {Modal} from "react-bootstrap";
import {useSnapshot} from "valtio";
import mailsProxy from "../../../../proxies/mails";


function ExportStatMailModal() {

    const mailSnap = useSnapshot(mailsProxy);
    return <Modal
        show={mailSnap.exportStatModal.show}
        onHide={() => mailsProxy.exportStatModal.show = false}
        fullscreen
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Export statistiques mail</Modal.Title>
            <p style={{width : "fit-content"}} className="alert alert-info m-auto mt-0 mb-0 p-2">Aperçu des 2000 premières lignes sur "Détail statistique". Afin de visionner toutes les lignes, en allant sur les points de suspension en haut à droite du tableau "Détail statistique".</p>
        </Modal.Header>
        <Modal.Body className="p-0">
            <iframe width={"100%"} height={"100%"} src={mailSnap?.currentFilterUrl} ></iframe>
        </Modal.Body>
    </Modal>;
}

export default ExportStatMailModal;