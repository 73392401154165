import { useMutation } from "react-query";
import api from "@services/api";

export default function useRelancePaiement({ id, date,...props  }) {

    return useMutation(() => api.post(
        `invitation/${id}/envoyer`, {
            json: {
                dateProgrammationEnvoi: date,
                type:"relancePaiement",
                renvoyerAToutLeMonde:true
            }
        }).json(), props);

}