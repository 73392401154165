import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetExportFacturation() {


    return useQuery(
        ["urlExportFacture"],
        async () => {
            return api.get("facture/urlExport").json();
        }
    );
}