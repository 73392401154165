import TransfereForm from "./TransfereForm";
import {useSnapshot} from "valtio";
import LoadingView from "../../../LoadingView/LoadingView";
import auth from "../../../../services/auth";
import useTransfererMailByMail from "../../../../requests/communication/mails/useTransfererMailByMail";

function TransfereMailView() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get('ref') ? urlParams.get('ref').replace(/ /g, "+") : "";
    const transfererByMail = useTransfererMailByMail(ref);
    const snapAuth = useSnapshot(auth);

    if (transfererByMail.isError || ref === ""){
        return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
            <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
                <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                    <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                        <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Transfert de mail</h1>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column flex-lg-row-fluid py-10 card'>
                <div className='d-flex flex-center flex-column flex-column-fluid card-body'>
                    <div className='p-10 p-lg-15 mx-auto w-lg-75 w-md-100'>
                        <p>{transfererByMail.error?.message} </p>
                    </div>
                </div>
            </div>
        </div>;
    }

    if (!transfererByMail.isSuccess){
        return <LoadingView/>
    }


    return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
            <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo'/>
                        </div>
                    </span>
                    <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Transfert de mail</h1>
                </div>
            </div>
        </div>
        <div className='d-flex flex-column flex-lg-row-fluid py-10 card'>
            <div className='d-flex flex-center flex-column flex-column-fluid card-body'>
                <div className=''>
                    <TransfereForm
                        dataCorrespondant={transfererByMail?.data?.correspondantEmail}
                    />
                </div>
            </div>
        </div>
    </div>;



}

export default TransfereMailView;
