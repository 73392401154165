import { useInfiniteQuery } from "react-query";
import { sleep } from "@utils/sleep";
import api from "@services/api";
var Qs = require('qs');

export default function useGetInfinite(keys, url, filters) {
    const pageLength = 10;

    return useInfiniteQuery([...keys, filters], async ({ pageParam = 0 }) => {
        // await sleep(5000);
        return api.get(url, {
            searchParams: Qs.stringify({
                start: pageParam,
                length: pageLength,
                ...filters,
            }, { arrayFormat: 'brackets' }),
        }).json();
    }, {
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.nbLignesTotales > pages.length * pageLength)
                return pages.length * pageLength;
        },
    });
}