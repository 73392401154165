export function cellStyleParParticipants(workbook, arrayData) {
    workbook.SheetNames.forEach((page, indexPage) => {
            for (let i = 0; i < workbook.Sheets[page]['!cols'].length; i++) {
                if (page != "globale") {
                    //colorisation des cellues
                    workbook.Sheets[page][mapNumLetter(i) + "1"].s = {
                        fill: {
                            patternType: "solid",
                            fgColor: {rgb: i % 2 == 0 ? "c5d9f1" : "f2f2f2"},
                        },
                        font: {
                            bold: true,
                            color: {rgb: "000000"},
                        },
                        border: {
                            left: {style: "medium", color: "000000"},
                            right: {style: "medium", color: "000000"},
                            bottom: {style: "thin", color: "000000"},
                        },
                        alignment: {
                            horizontal: "center"
                        }
                    };
                    workbook.Sheets[page][mapNumLetter(i) + "2"].s = {
                        fill: {
                            patternType: "solid",
                            fgColor: {rgb: i % 2 == 0 ? "c5d9f1" : "f2f2f2"},
                        },
                        font: {
                            color: {rgb: "000000"},
                        },
                        border: {
                            left: {style: "medium", color: "000000"},
                            right: {style: "medium", color: "000000"},
                            bottom: {style: "medium", color: "000000"},
                        },
                        alignment: {
                            horizontal: "center"
                        }
                    };

                    //style par ligne a partir de la troisième ligne
                    for (let i2 = 0; i2 < (arrayData.find(data => data.pageName == page).data.length - 1); i2++) {
                        if (workbook.Sheets[page][mapNumLetter(i) + (3 + i2)]){
                            workbook.Sheets[page][mapNumLetter(i) + (3 + i2)].s = {
                                font: {
                                    color: {rgb: "000000"},
                                },
                                border: {
                                    left: {style: "medium", color: "000000"},
                                    right: {style: "medium", color: "000000"},
                                    bottom: {
                                        style: i2 == (arrayData.find(data => data.pageName == page).data.length - 2) ? "medium" : "thin",
                                        color: "000000"
                                    },
                                },
                                alignment: {
                                    horizontal: "center"
                                }
                            };
                        }else {
                            workbook.Sheets[page][mapNumLetter(i) + (3 + i2)]= {
                                t: "s",
                                v: "",
                                s: {
                                    font: {
                                        color: {rgb: "000000"},
                                    },
                                    border: {
                                        left: {style: "medium", color: "000000"},
                                        right: {style: "medium", color: "000000"},
                                        bottom: {
                                            style: i2 == (arrayData.find(data => data.pageName == page).data.length - 2) ? "medium" : "thin",
                                            color: "000000"
                                        },
                                    },
                                    alignment: {
                                        horizontal: "center"
                                    }
                                }
                            }
                        }
                    }
                }else {
                    //style par ligne a partir de la troisième ligne
                    for (let i2 = 0; i2 < (arrayData.find(data => data.pageName == page).data.length); i2++) {
                        if (workbook.Sheets[page][mapNumLetter(i) + (2 + i2)]){
                            workbook.Sheets[page][mapNumLetter(i) + (2 + i2)].s = {
                                font: {
                                    color: {rgb: "000000"},
                                },
                                border: {
                                    left: {style: "medium", color: "000000"},
                                    right: {style: "medium", color: "000000"},
                                    bottom: {
                                        style: i2 == (arrayData.find(data => data.pageName == page).data.length - 1) ? "medium" : "thin",
                                        color: "000000"
                                    },
                                },
                                alignment: {
                                    horizontal: "center"
                                }
                            };
                        }else {
                            workbook.Sheets[page][mapNumLetter(i) + (2 + i2)]= {
                                t: "s",
                                v: "",
                                s: {
                                    font: {
                                        color: {rgb: "000000"},
                                    },
                                    border: {
                                        left: {style: "medium", color: "000000"},
                                        right: {style: "medium", color: "000000"},
                                        bottom: {
                                            style: i2 == (arrayData.find(data => data.pageName == page).data.length - 1) ? "medium" : "thin",
                                            color: "000000"
                                        },
                                    },
                                    alignment: {
                                        horizontal: "center"
                                    }
                                }
                            }
                        }
                    }
                }
            }
    })

    for (let i = 0; i < workbook.Sheets["globale"]['!cols'].length; i++) {
        workbook.Sheets["globale"][mapNumLetter(i) + "1"].s = {
            fill: {
                patternType: "solid",
                fgColor: {rgb: "f2f2f2"},
            },
            font: {
                bold: true,
            },
            border: {
                left: {style: "medium", color: "000000"},
                right: {style: "medium", color: "000000"},
                bottom: {style: "thin", color: "000000"},
            },
            alignment: {
                horizontal: "center"
            }
        };
    }
}

export function cellStyleParTable(workbook, arrayData){
    //maxCols permet de palier au fait que le plugin calcul le nb col en fonction seulement de la première ligne de données
    let maxCols = 0;
    arrayData.forEach((item, index) => {
        item.data.forEach((itemTabTourParticipant, index) => {
            if (Object.keys(item.data[index]).length > maxCols){
                maxCols = Object.keys(item.data[index]).length
            }
        })

    })
    workbook.SheetNames.forEach((page, indexPage) => {
        // for (let i = 0; i < workbook.Sheets[page]['!cols'].length; i++) {
        for (let i = 0; i < maxCols; i++) {
            if (typeof workbook.Sheets[page][mapNumLetter(i) + "1"] != "undefined"){
                workbook.Sheets[page][mapNumLetter(i) + "1"].s = {
                    fill: {
                        patternType: "solid",
                        fgColor: {rgb: "f2f2f2"},
                    },
                    font: {
                        bold: true,
                    },
                    border: {
                        left: {style: "medium", color: "000000"},
                        right: {style: "medium", color: "000000"},
                        bottom: {style: "thin", color: "000000"},
                    },
                    alignment: {
                        horizontal: "center"
                    }
                }
            }
            for (let i2 = 0; i2 < (arrayData.find(data => data.pageName == page).data.length); i2++) {
                if (workbook.Sheets[page][mapNumLetter(i) + (2 + i2)]) {
                    workbook.Sheets[page][mapNumLetter(i) + (2 + i2)].s = {
                        font: {
                            color: {rgb: "000000"},
                        },
                        border: {
                            left: {style: "medium", color: "000000"},
                            right: {style: "medium", color: "000000"},
                            bottom: {
                                style: i2 == (arrayData.find(data => data.pageName == page).data.length - 1) ? "medium" : "thin",
                                color: "000000"
                            },
                        },
                        alignment: {
                            horizontal: "center"
                        }
                    };
                }else {
                    workbook.Sheets[page][mapNumLetter(i) + (2 + i2)]= {
                        t: "s",
                        v: "",
                        s: {
                            font: {
                                color: {rgb: "000000"},
                            },
                            border: {
                                left: {style: "medium", color: "000000"},
                                right: {style: "medium", color: "000000"},
                                bottom: {
                                    style: i2 == (arrayData.find(data => data.pageName == page).data.length - 1) ? "medium" : "thin",
                                    color: "000000"
                                },
                            },
                            alignment: {
                                horizontal: "center"
                            }
                        }
                    }
                }
            }
        }
    })
}


function mapNumLetter(i){
    let tabLetters = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
    for (let i = 0; i < 26; i++){
        for (let i2 = 0; i2<26; i2++){
            tabLetters.push(tabLetters[i]+tabLetters[i2])
        }
    }
    return tabLetters[i]
}