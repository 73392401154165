import { proxy } from "valtio";

const accesProxy = proxy({
    mail:null,
    editModal: {
        show: false,
        step: 0,
        loading: false,
    },
    showEditAcces: (mail) => {
        accesProxy.mail = mail
        accesProxy.editModal = {
            show: true,
            step: 0,
            loading: false,
        };
    }
})

export default accesProxy;