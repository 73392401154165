import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";

export default function useChangeValueChampPersoAnnuaire() {
    return useMutation(changeValue, {
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function changeValue(value) {
    const id = value.id;

    if (id != null)
        return api.put(`champPersonnaliseAnnuaire/${id}/valeur`, { json: {
                ancienneValeur: value.ancienneValeur,
                nouvelleValeur: value.nouvelleValeur
            } }).json();
}