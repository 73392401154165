import { useMutation } from "react-query";
import api from "@services/api";

export default function useSendForValidationDataTable(props) {

    return useMutation(sendForValidationDataTable, props);

}

function sendForValidationDataTable(id) {

    return api.post(`email/${id}/envoyerPourValidation`).json()
}