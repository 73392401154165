import React, {useEffect, useState} from "react";
import {Button, Col, Form, FormGroup, InputGroup, Modal, Row, Stack} from "react-bootstrap";
import {useFieldArray} from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import useChangeValueChampPersoAnnuaire from "../../../requests/annuaire/useChangeValueChampPersoAnnuaire";
function SelectChampsPersoTab({ control, name, register, errors, setUpdate, usedValues, setValue, getValues, setDeleteValuesChampPerso, deleteValuesChampPerso, onSaveTab, valuesChampPersoAnnuaire, id }) {
    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
        keyName: '',
    });
    const changeValue = useChangeValueChampPersoAnnuaire();
    const [showModal, setShowModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [nameLibelleEdited, setNameLibelleEdited] = useState(null);
    const [valueLibelleEdited, setValueLibelleEdited] = useState(null);

    useEffect(() => {
        if (fields.length < 1)
            append({
                id:uuidv4(),
                text:null
            });
    }, [fields, append]);
    return <div>
        {fields.map((item, index) => (
            <Stack key={item.id} className={index === 0 ? 'mt-5' : ''}>
                <Row>
                    <Col sm={12}>
                        <FormGroup as={Col}>
                            <Form.Label>Libelle</Form.Label>
                            <InputGroup className='input-group'>
                                <Form.Control disabled={usedValues.find((e) => e == item.text)} onKeyUp={() => {
                                    setUpdate(uuidv4());
                                }} className='form-control' {...register(`${name}.${index}.text`, {required : true})} />
                                {usedValues.find((e) => e == item.text) &&
                                <InputGroup.Text
                                    onClick={() => {
                                        BtSwal.fire({
                                            title: `Cette valeur est déjà attribuée. Voulez-vous modifier cette valeur pour toutes les personnes ?`,
                                            showDenyButton: true,
                                            confirmButtonText: `Oui`,
                                            denyButtonText: `Non`,
                                            icon: 'warning',
                                            confirmButtonColor: "red"
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                setShowModal(true)
                                                setNameLibelleEdited(`${name}.${index}.text`)
                                            } else if (result.isDenied) {
                                                append({
                                                    id:uuidv4(),
                                                    text:null
                                                })
                                                setTimeout( () =>  document.getElementsByName(`${name}.${fields.length}.text`)[0].focus(), 500)
                                            }
                                        })
                                    }}
                                    className='text-muted fw-bold fs-5 cursor-pointer'>

                                        <span>
                                            <FontAwesomeIcon icon={faPen}/>
                                        </span>
                                </InputGroup.Text>}
                            </InputGroup>
                            {errors?.text && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                        </FormGroup>
                    </Col>
                </Row>
                <div className='d-flex justify-content-end'>
                    {index === fields.length - 1 && <>
                        <Button
                        className='btn-sm btn btn-quaternaire p-1 me-2 ps-2 pe-2 w-25px h-25px mt-3'
                        onClick={() => append({
                            id:uuidv4(),
                            text : null
                        })}
                    ><FontAwesomeIcon color={"black"} icon={faPlus}/></Button>
                       </>}
                    {fields.length > 1 &&
                        <>
                            <Button
                                className='btn-sm btn btn-quaternaire p-1 ps-2 pe-2 w-25px h-25px mt-3'
                                onClick={() => {
                                    if (usedValues.find((e) => e == item.text)){
                                        BtSwal.fire({
                                            title: `Cette valeur est déjà attribuée. La valeur sera supprimé pour tous contacts.`,
                                            showDenyButton: true,
                                            confirmButtonText: `Continuer`,
                                            denyButtonText: `Annuler`,
                                            icon: 'warning',
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                let tmp = [...deleteValuesChampPerso];
                                                tmp.push(item.text)
                                                setDeleteValuesChampPerso(tmp)
                                                remove(index)
                                            }
                                        })
                                    }else {
                                        remove(index)
                                    }
                                }}
                            >
                                <FontAwesomeIcon color={"rgba(248, 96, 96, 0.953)"} icon={faTrash}/>
                            </Button>
                        </>

                    }
                </div>
            </Stack>
        ))}
        <Modal onHide={() => setShowModal(false)} show={showModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <FormGroup as={Col}>
                    <Form.Label>Nouvelle valeur</Form.Label>
                    <Form.Control value={valueLibelleEdited} onChange={(e) => {
                        setValueLibelleEdited(e.target.value)
                    }} className='form-control' />
                    {errorModal && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </FormGroup>
                <Button className="m-auto mt-2 d-flex" onClick={() => {
                    if (valueLibelleEdited) {
                        changeValue.mutate({
                            id: getValues('id'),
                            ancienneValeur:getValues(nameLibelleEdited),
                            nouvelleValeur:valueLibelleEdited
                        })
                        setValue(nameLibelleEdited, valueLibelleEdited)
                        setErrorModal(false)
                        setShowModal(false)
                        setValueLibelleEdited(null)
                        onSaveTab.mutateAsync(getValues()).then(() => {
                            valuesChampPersoAnnuaire.mutate(id);
                        });
                    }else {
                        setErrorModal(true)
                    }
                }}>
                    Valider et enregistrer
                </Button>
            </Modal.Body>
        </Modal>
    </div>;
}

export default SelectChampsPersoTab;