import BlockUI from "@components/BlockUI/BlockUI";
import {Alert, Button, Form} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import useGetParametresForum from "../../requests/parametresForum/useGetParametresForum";
import useSetParametresForum from "../../requests/parametresForum/useSetParametresForum";

function ParametresForumView() {

    const parametreForum = useGetParametresForum();
    const setParametresForum = useSetParametresForum();
    const { register, handleSubmit, reset } = useForm();

    useEffect(() => {
        reset(parametreForum.data);
    }, [parametreForum.data]);

    if (parametreForum.isError)
        return <Alert variant='danger'>{parametreForum.error?.message}</Alert>;

    return <BlockUI loading={parametreForum.isLoading || parametreForum.isLoading}>
        <Form.Group className='mt-5'>
            <Form.Label>Forum, publication automatique des sujets</Form.Label>
            <Form.Check
                type='switch'
                label="À la création d'un sujet dans le forum, je souhaite qu'il se publie automatiquement"
                id='publierAutomatiquementSujetForumCheck'
                {...register('publierAutomatiquementSujetForum')}
            />
        </Form.Group>

        <div className='d-flex justify-content-center mt-10'>
            <Button
                variant='secondary'
                className='px-20'
                onClick={handleSubmit(setParametresForum.mutate)}
            >
                Enregistrer les modifications
            </Button>
        </div>
    </BlockUI>;
}

export default ParametresForumView;