import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetPricesSmsFormCoutries(prefixe) {

    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = {
        data: "prefixe",
        searchable: true,
        orderable: true,
        search: {
            value: prefixe,
            regex: false,
        },
        searchOperator:"="
    }

    filters.order[0] = {
        column:0,
        dir: "desc"
    }

    return useQuery(
        ["tarifSms"],
        async () => {
            // await new Promise(resolve => setTimeout(resolve, 3000))
            return api.get("tarifSms", {
                searchParams: Qs.stringify({
                    start: 0,
                    length: 100,
                    ...filters,
                }, { arrayFormat: 'brackets' }),
            }).json();
        }
    );

}