import { useQuery } from "react-query";
import baseApi from "../../../services/apiPublic";

export default function useAfficherMailbyMail(ref) {
    return useQuery(
        ['afficherMail'],
        () => baseApi.post('email/afficher', {
            json: {
                ref : ref
            }
        }).json(),
    );
}