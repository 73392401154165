import prewiewCheckoutPayment from './imgPreviewCheckoutAndPayment.png'
import svgResponsiveOrdi from './svgResponsibleOrdi.svg'
import svgResponsiveTel from './svgResponsiveTel.svg'
import {useEffect, useState} from "react";
import tinycolor from "tinycolor2";


export default function PreviewAdaptationMarqueCheckoutAndPayment({color = '#000', logoOrIcon, colorSecondary}){
    const [mobileDisplay, setMobileDisplay] = useState(false);
    const [colorIsDark, setColorIsDark] = useState(true);
    const [logo, setLogo] = useState(logoOrIcon);

    useEffect(() => {
        setLogo(logoOrIcon)
    }, [logoOrIcon]);

    useEffect(() => {
        setColorIsDark(tinycolor(color).getBrightness()<145)
    }, [color]);

    return <>
        <div className='mb-5' style={{
            margin: "auto",
            borderRadius: 5,
            width: 'fit-content',
            boxShadow: "0 0 0 1px rgba(48,49,61,.1),0 2px 5px 0 rgba(48,49,61,.08),0 1px 1.5px 0 #00000012,0 1px 2px 0 #00000014,0 0 0 0 transparent"
        }}>
            <img onClick={() => {
                setMobileDisplay(false);
            }} className="me-3 p-3 cursor-pointer"
                 style={{filter: !mobileDisplay && "invert(20%) sepia(243%) saturate(1576%) hue-rotate(-21deg) brightness(137%) contrast(73%)"}}
                 src={svgResponsiveOrdi} alt="svgOrdi"/>
            <img onClick={() => {
                setMobileDisplay(true);
            }} src={svgResponsiveTel}
                 style={{filter: mobileDisplay && "invert(20%) sepia(243%) saturate(1576%) hue-rotate(-21deg) brightness(137%) contrast(73%)"}}
                 className="p-3 cursor-pointer" alt="svgTel"/>
        </div>
        <div className={`d-flex m-auto mb-2 ${mobileDisplay && 'flex-column'}`} style={{
            transition: "width 2s ease",
            width: mobileDisplay ? 300 : 690,
            height: 540,
            border: "solid 1px black",
        }}>
            <div style={{
                backgroundColor: color,
                transition: "height 0.5s ease",
                height: mobileDisplay ? 300 : "100%"
            }}
                 className="w-100 d-flex justify-content-center align-items-center position-relative">
                {mobileDisplay ?
                    <div>
                        <img className="position-absolute" style={{top: 10, left: 10}} width={30} src={logo}
                             alt=""/>
                        <div className="text-center">
                            <p style={{color: colorIsDark ? '#bbbbbb' : "#000", fontSize: 11, marginBottom: 5}}>Product name</p>
                            <p  style={{color: colorIsDark ? '#fff' : "#000", fontSize: 18, fontWeight: "bold"}}>0,00 €</p>
                        </div>
                    </div>
                    : <>
                        <div className="ms-10" style={{width: 300, height: 370}}>
                            <div className="mb-5"><img width={30} src={logo} alt=""/></div>
                            <p style={{color: colorIsDark ? '#bbbbbb' : "#000", fontSize: 11, marginBottom: 5}}>Product name</p>
                            <p style={{color: colorIsDark ? '#fff' : "#000", fontSize: 22, fontWeight: "bold"}}>0,00 €</p>
                        </div>
                    </>
                }

            </div>
            <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100 "
                 >
                <img style={{
                    transition: "width 2s ease",
                }} width={mobileDisplay ? 260 : 300} src={prewiewCheckoutPayment} alt=""/>
                <button style={{backgroundColor: colorSecondary, width:mobileDisplay ? 260 : 300, borderRadius: 5, fontSize: 12, color: colorIsDark ? '#bbbbbb' : "#000"}} className={`btn ${mobileDisplay ? 'p-2' : 'p-3'}`}>Payer</button>
            </div>
        </div>
    </>

}