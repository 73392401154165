import { proxy } from "valtio";

const annuaireProxy = proxy({
    showModal: false,
    showExportAnnuaireModal: false,
    notificationModal:false,
    personne: null,
    entreprise:null,
    isUtilisateur: false,
    initTab:"personne",
    init:true,
    currentFilterUrl:null,
    partenaireIsSelectedAndNoEntreprisePartenaire:false,
    addAutoDestinataire: false,
    showPersonne: (personne, isUtilisateur, tab, init) => {
        annuaireProxy.personne = personne;
        annuaireProxy.showModal = true;
        annuaireProxy.notificationModal = false;
        annuaireProxy.isUtilisateur = isUtilisateur;
        annuaireProxy.initTab = tab;
        annuaireProxy.init = init;
        annuaireProxy.entreprise = null;
        annuaireProxy.partenaireIsSelectedAndNoEntreprisePartenaire = false;
    },
    showNotificationPersonne: (personne) => {
        annuaireProxy.personne = personne;
        annuaireProxy.showModal = false;
        annuaireProxy.notificationModal = true;
    },
    showExportAnnuaire:(currentFilterUrl) => {
        annuaireProxy.showModal = false;
        annuaireProxy.notificationModal = false;
        annuaireProxy.showExportAnnuaireModal = true;
        annuaireProxy.currentFilterUrl = currentFilterUrl;
    }
});

export default annuaireProxy;