import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useDeleteMail() {

    const queryClient = useQueryClient();

    return useMutation(deleteEmail, {
        onSuccess: () => {
            queryClient.invalidateQueries('emails');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

}

function deleteEmail(id) {

    if (id != null)
        return api.delete(`email/${id}`).json();

}