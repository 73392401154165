import {dureeMinuteToTimeMysqlWithoutHours, dureeTimeMysqlToMinute} from "../../../../views/SpeedBusinessView/ReglageStep/data";
import CountDownCircleTimer from "../../../../components/CountDownCircleTimer/CountDownCircleTimer";

export default function Entracte(props){
    return <span hidden={props.hidden}>
                <div style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "var(--primary-color)"
                }}>
                <div style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <div style={{
                        flex: 1,
                        height: "100%",
                        backgroundColor: "var(--secondary-color)",
                        position: "relative"
                    }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                            <span style={{
                                fontSize: 52,
                                fontWeight: "bold",
                                color: "white"
                            }}>ENTRACTE</span>
                        </div>
                        <div style={{
                            position: "absolute",
                            right: -150,
                            top: "calc(50% - 150px)",
                            height: 300,
                            width: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 200,
                            border: "5px solid var(--primary-color)"
                        }} className="bg-white">
                            <div style={{
                                height: 280,
                                width: 280,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 200,
                                border: "5px solid var(--primary-color)"
                            }} className="bg-white">
                                <div>
                                    <div style={{
                                        marginTop: 30
                                    }}>
                                        <span
                                            style={{
                                                borderColor: "var(--primary-color)"
                                            }}
                                            className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(props.currentTime / 60)[0]}</span>
                                    <span style={{
                                        borderColor: "var(--primary-color)"
                                    }}
                                          className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(props.currentTime / 60)[1]}</span>
                                    <span style={{
                                        fontSize: 30,
                                        fontWeight: "bold",
                                        marginRight: 10
                                    }}>{dureeMinuteToTimeMysqlWithoutHours(props.currentTime / 60)[2]}</span>
                                    <span style={{
                                        borderColor: "var(--primary-color)"
                                    }}
                                          className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(props.currentTime / 60)[3]}</span>
                                    <span style={{
                                        borderColor: "var(--primary-color)",
                                        marginRight: 0
                                    }}
                                          className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(props.currentTime / 60)[4]}</span>
                                    </div>
                                    {props.playing ?
                                        <i
                                            onClick={props.onClick}
                                            style={{
                                                color: props.changementTour ? "white" : "black",
                                                cursor: "pointer",
                                                display: "flex",
                                                justifyContent: "center"
                                            }} className="fa fa-4x fa-pause-circle mt-2"></i>
                                        :
                                        <i
                                            onClick={props.onClick1}
                                            style={{
                                                color: props.changementTour ? "white" : "black",
                                                cursor: "pointer",
                                                display: "flex",
                                                justifyContent: "center"
                                            }} className="fa fa-4x fa-play-circle mt-2"></i>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                      <div style={{
                          flex: 1,
                          height: "100%"
                      }}>

                    </div>

                </div>
                </div>
                <CountDownCircleTimer
                    setInitCurrentPerson={props.initCurrentPerson}
                    isPlaying={props.playing}
                    speedBusiness={props.speedBusiness}
                    valueChangeRemainingTime={props.valueChangeRemainingTime}
                    setValueChangeRemainingTime={props.valueChangeRemainingTime1}
                    currentPerson={props.currentPerson}
                    setCurrentPerson={props.currentPerson1}
                    personneParTable={Math.ceil(props.participantsPresentEtEnRetard?.data?.nbLignesTotales / props.speedBusiness?.data?.nombreTablesChoisies)}
                    tour={props.tour}
                    setTour={props.tour1}
                    setIsEntracte={props.isEntracte}
                    setIsChangementTour={props.isChangementTour}
                    isChangementTour={props.changementTour}
                    isTimePersonne={props.timePersonne}
                    isEntracte={props.entracte}
                    setIsTimePersonne={props.isTimePersonne}
                    currentTime={props.currentTime}
                    setCurrentTime={props.currentTime1}
                    numberOfStep={props.numberOfStep}
                    zigZagBar={props.zigZagBar}
                    numIsPair={props.numIsPair}
                    duration={dureeTimeMysqlToMinute(props.speedBusiness?.data?.dureeEntracte) * 60}
                    title="Entracte"
                />
            </span>;
}