import { useMutation } from "react-query";
import api from "@services/api";
import auth from "@services/auth";

export default function useChangeExpediteur(props) {
    return useMutation(changeExpediteur, props);
}

function changeExpediteur(expediteur) {
    const id = expediteur?.id;

    if (id != null)
        return api.put(`expediteur/${id}`, { json: expediteur }).json();
    return api.post('expediteur', {
        json: {
            ...expediteur,
            idAgence: auth?.agence?.id,
        }
    }).json();
}