import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetPagesEnregistrer(id) {

    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = {
        data: "compteFacebook.id",
        searchable: true,
        orderable: true,
        search: {
            value: id,
            regex: false,
        }
    }

    filters.order[0] = {
        column:0,
        dir: "asc"
    }

    return useQuery(
        ['pageFacebook'+id],
        async () => {
            return api.get(`pageFacebook`, {
                searchParams: Qs.stringify({
                    start: 0,
                    length: 100,
                    ...filters,
                },{ arrayFormat: 'brackets' })})
                .json();
        },
        {
            enabled: id != null,
        },
    );
}