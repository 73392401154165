import { Alert, Dropdown, Spinner } from "react-bootstrap";
import useGetBlocsChoix from "../../../../requests/communication/invitations/blocsInvitation/useGetBlocsChoix";


function BlocChoixSelection({ children, className, onChange }) {

    const blocsChoix = useGetBlocsChoix();

    return <Dropdown>
        <Dropdown.Toggle variant='white' className={`${className ?? ''} hide-after`}>
            {children}
        </Dropdown.Toggle>
        <Dropdown.Menu className='fs-6 w-400px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
            {blocsChoix.isLoading && <div className='text-center my-5'>
                <Spinner animation='border' />
            </div>}
            {blocsChoix.isError &&
                <Alert variant='danger' className='m-2'>
                    {blocsChoix.error?.message ?? 'Erreur'}
                </Alert>
            }
            {blocsChoix.isSuccess &&
                <div className='mh-300px scroll-y'>
                    {blocsChoix.data?.data?.map(header => <div className='menu-item px-3' key={header.id}>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => onChange(header)}
                        >
                            {header.nom}
                        </Dropdown.Item>
                    </div>)}
                </div>
            }
        </Dropdown.Menu>
    </Dropdown>;

}

export default BlocChoixSelection;