import { useQuery } from "react-query";
import api from "@services/api";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";
import Qs from "qs";

export default function useGetPersonneUtilisateur() {
    const snapAuth = useSnapshot(auth);
    let entreprises = [];

    if (snapAuth?.utilisateur?.tabEntreprises){
        Object.values(snapAuth?.utilisateur?.tabEntreprises).forEach(item => {
            entreprises.push(item.id);
        })
    }else {
        entreprises = "";
    }

    let filters = {
        columns: {},
        order: {}
    };


    filters.columns[0] = {
        data: "entreprise.id",
        searchable: true,
        orderable: true,
        searchOperator : "IN",
        search: {
            value: entreprises.toString(),
            regex: false,
        }
    }

    filters.order[0] = {
        column:0,
        dir: "desc"
    }


    return useQuery(
        ['utilisateur', 'personne'],
        async () => {
            return api.get('personne/utilisateur', {
                searchParams: Qs.stringify({
                    start: 0,
                    length: 100,
                    ...filters,
                }, { arrayFormat: 'brackets' }),
            }).json();
        }
    );
}