import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import auth from "../../../services/auth";
import {BtSwal} from "../../../utils/alerts/sweetAlert";

export default function useSetParametresFacturation() {
    const queryClient = useQueryClient();
    return useMutation(parametres => {
        return api.post('parametreFacturation', {
            json: parametres,
        }).json();
    }, {
        onSuccess: (res) => {
            queryClient.invalidateQueries('parametreFacturation');
            BtSwal.fire('Les paramètres ont été modifié.', '', 'success')
            auth.parametreFacturation = res;
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}