import invitationsProxy from "../../../../proxies/invitations";
import {Modal} from "react-bootstrap";
import React from "react";
import {useSnapshot} from "valtio";

function ModalPreviewInscriptionLibre(){
    const invitationsSnapshot = useSnapshot(invitationsProxy);


    return <Modal
        show={invitationsSnapshot.inscriptionLibre.showPreview}
        onHide={() => invitationsProxy.inscriptionLibre.showPreview = false}
        size='xl'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Aperçu page d'inscription libre</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h1 className="mx-10 text-center alert-info-color">{invitationsSnapshot.invitation.sujet}</h1>
            <h2 className="mx-10 text-center ">{invitationsSnapshot.invitation.dateEvenementFormatee}</h2>
            <img style={{
                maxWidth: "100%",
                margin: "10px auto",
                display: "block",
                border: "solid 1px lightgrey"
            }}
                 src={invitationsSnapshot?.invitation?.uploadInscriptionLibre?.urlOriginale} alt=""/>

            <div className="p-3 mb-5 mx-10 bg-white rounded pb-4">
                <span
                    dangerouslySetInnerHTML={{__html: invitationsSnapshot?.invitation?.descriptionInscriptionLibre}}></span>
            </div>

            <div className="p-3 mt-5 mx-10 bg-white rounded pb-4">
                <h3>Adresse</h3>
                <p className=""><i
                    className=""></i> {invitationsSnapshot.invitation.preludeAdresse} <br/>
                    {invitationsSnapshot.invitation.adresse} <br/>
                    {invitationsSnapshot.invitation.codePostal}, {invitationsSnapshot.invitation.ville}

                </p>
            </div>


        </Modal.Body>
    </Modal>
}

export default ModalPreviewInscriptionLibre