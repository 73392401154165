import { Button } from "react-bootstrap";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";
import useValidSmsByMail from "../../../requests/communication/sms/useValidSmsByMail";
import useInvalidSmsByMail from "../../../requests/communication/sms/useInvalidSmsByMail";


function ValidInvalidSmsView() {

    const validSms = useValidSmsByMail();
    const invalidSms = useInvalidSmsByMail();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get('ref') ? urlParams.get('ref').replace(/ /g, "+") : "";
    const snapAuth = useSnapshot(auth);


    return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
            <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                    <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Validation SMS</h1>
                </div>
            </div>
        </div>
        <div className='d-flex flex-column flex-lg-row-fluid py-10 card'>
            <div className='d-flex flex-center flex-column flex-column-fluid card-body'>
                <div className='w-lg-500px p-10 p-lg-15 mx-auto'>
                    <div className='text-center mb-10'>
                        <h1 className='text-dark mb-3'>Valider le sms</h1>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <Button
                            onClick={() => validSms.mutate(ref)}
                            className="btn btn-secondary me-2">
                            Valider
                        </Button>
                        <Button
                            onClick={() => invalidSms.mutate(ref)}
                            className="btn btn-danger">
                            Invalider
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    </div>;

}

export default ValidInvalidSmsView;
