import { Alert, Card, Spinner } from "react-bootstrap";
import useGetProfilPartenaire from "../../../../requests/profil/useGetProfilPartenaire";

function ProfilFilter({ selected, onChange }) {
    const profils = useGetProfilPartenaire();

    return <Card className='card-flush'>
        <Card.Body className='px-2'>
            <h4 className='text-center'>Groupes</h4>
            <div className='menu menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-6 py-4'>
                {profils.isLoading && <Spinner animation='border' className='mx-auto' />}
                {profils.isError && <Alert className='mx-auto w-300px' variant='danger'>{profils.error?.message}</Alert>}
                {profils.isSuccess && <>
                    <div className='menu-item px-3'>
                        <span
                            className={`menu-link px-3 ${selected == null ? 'bg-secondary text-white' : ''}`}
                            onClick={() => onChange(null)}
                        >Tout les partenaires</span>
                    </div>
                    {profils.data?.data?.map(
                        profil => <div
                            key={profil.id}
                            className='menu-item px-3'
                        >
                            <span
                                className={`menu-link px-3 ${profil.id === selected?.id ? 'bg-secondary text-white' : ''}`}
                                onClick={() => onChange(profil)}
                            >
                                {profil.libelle}
                            </span>
                        </div>
                    )}
                </>}
            </div>
        </Card.Body>
    </Card>;
}

export default ProfilFilter;