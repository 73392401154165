import ReactDOMServer from 'react-dom/server';
import juice from 'juice';

import { mailTemplate } from './mailTemplate/mailTemplate';
import { mailInvitationTemplate } from './mailTemplate/mailInvitationTemplate';
import { renderSectionExport } from './utils/renderSectionExport';


export function exportMail(sections, isInvitation, hiddenVisualisezLeEnLigne) {
    var sectionExports = [];

    function exportProcessing(template, id) {
        if (template != null) {
            let replacesStyle = {
                'id="colorPicker"': 'style="background-color: ' + sections[id].backgroundColor + '"',
                'id="paddingTemplate"': 'style="padding: ' + sections[id].padding + '"'
            }
            if (sections[id]?.type == "singleImage"){
                replacesStyle['id="image"'] = sections[id]?.padding && sections[id]?.content?.images?.image && (sections[id]?.type == "singleImage") ? 'src="'+sections[id]?.content?.images?.image+'" id="image" style="max-width: '+ (600 - (parseInt(sections[id].padding.split(' ')[1])+parseInt(sections[id].padding.split(' ')[3]))) + 'px" width="'+(600 - (parseInt(sections[id].padding.split(' ')[1])+parseInt(sections[id].padding.split(' ')[3])))+'"' : 'id="image" width="560" src="https://placehold.co/1120x800/EEE/31343C" style="max-width: 560px"'
            }
            let regex = new RegExp(Object.keys(replacesStyle).join("|"), "gi");
            let replaceTemplate;
            if (!isInvitation){
                replaceTemplate = mailTemplate[sections[id].type].template.replace(regex, function (matched) {
                    return replacesStyle[matched];
                });
            }else {
                replaceTemplate = mailInvitationTemplate[sections[id].type].template.replace(regex, function (matched) {
                    return replacesStyle[matched];
                });
            }
            sectionExports.push(renderSectionExport(
                replaceTemplate,
                sections[id].content,
            ));
        } else
            console.error('Cannot export mail section with undefined template.');
    }

    if (isInvitation){
        sections.order.forEach(function (id, index) {
            const template = mailInvitationTemplate[sections[id].type]?.template;
            exportProcessing(template, id)
        });
    }else {
        sections.order.forEach(function (id, index) {
            const template = mailTemplate[sections[id].type]?.template;
            exportProcessing(template, id);
        });
    }


    const result = ReactDOMServer.renderToStaticMarkup(<html>
        <head>
            <meta httpEquiv='Content-Type' content='text/html; charset=UTF-8' />
            <meta name='viewport' content='width=device-width' />
            <title>Template de mail</title>
        </head>
        <body>
            <css dangerouslySetInnerHTML={{ __html : "{style.css}"}}/>
            <center>
                <table
                    style={{minHeight:950, fontFamily: "Helvetica, Arial", lineHeight: "normal"}}
                    border='0'
                    cellPadding='0'
                    cellSpacing='0'
                    height='100%'
                    width='100%'
                    id='bodyTable'
                >
                    <tbody>
                        <tr>
                            <td  id='bodyCell' valign='top' align='center'>
                                <table width="600"><tbody><tr><td style={{fontSize: 10, color:"#F5F5F5"}} align="center" dangerouslySetInnerHTML={{ __html: "{preHeader}" }}></td></tr></tbody></table>
                                {!hiddenVisualisezLeEnLigne && <p style={{fontSize: 13}}>Si ce message ne s'affiche pas correctement, <a href="{urlVisualisezEnLigne}">visualisez-le en ligne</a></p> }
                                <table id='emailBody' width='600' cellSpacing='0' cellPadding='0' border='0'>
                                    <tbody className='testo'>
                                        {sections?.header != null &&
                                            <tr>
                                                <td>
                                                    <img alt="Télécharger les images" style={{ width: 600, display: "block" }} src="{header}" />
                                                </td>
                                            </tr>
                                        }
                                        {/*{isInvitation && <Invitation />}*/}
                                        {sectionExports}
                                    </tbody>
                                </table>
                                {sections?.footer != null &&
                                    <table width="600" style={{overflowX : "hidden", width :"600px"}} cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div style={{overflowX : "hidden", width :"600px"}} dangerouslySetInnerHTML={{ __html: "{signature}" }} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }
                                <p dangerouslySetInnerHTML={{ __html: "{mentionsLegales}" }}></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </center>
        </body>
    </html>);

    return juice(result);
}