import {faCheck, faPen, faPlus, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Editor} from "@tinymce/tinymce-react";
import React, {useEffect, useRef, useState} from "react";
import {Accordion, Alert, Button, Form, Modal, Spinner} from "react-bootstrap";
import {useQueryClient} from "react-query";
import {useForm} from "react-hook-form";
import {iconLienPj} from "../../../assets/icons/svgTinymce";
import $ from "jquery";
import useUploadFile from "../../../requests/upload/useUploadFile";
import 'tinymce/plugins/code';
import 'tinymce/plugins/table';
import {BtSwal} from "../../../utils/alerts/sweetAlert";
// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css'
import {linkUrlTinyMce} from "../../../functions/linkUrlTinyMce";
import {imageTinyMce} from "../../../functions/ImageTinyMce";
import useGetBlocsChoix from "../../../requests/communication/invitations/blocsInvitation/useGetBlocsChoix";
import useDeleteBlocResponse from "../../../requests/parametres/blocResponse/useDeleteBlocResponse";
import useChangeBlocResponse from "../../../requests/parametres/blocResponse/useChangeBlocResponse";
import fileManagerProxy from "../../../proxies/fileManager";
import {useSnapshot} from "valtio";
import motsClesProxy from "../../../proxies/motsClesProxy";
import {useDebounce} from "use-debounce";
import SearchBar from "../../../components/SearchBar/SearchBar";
import auth from "../../../services/auth";

function BlocResponseSettings() {
    const queryClient = useQueryClient();
    const { register, watch, setValue } = useForm();
    const search = watch('search')
    const [debouncedFilter] = useDebounce(search, 500);
    const blocs = useGetBlocsChoix(debouncedFilter);
    const [activeKey, setActiveKey] = useState();
    const [hiddenEditLines, setHiddenEditLines] = useState(false);
    const [hiddenAdd, setHiddenAdd] = useState(false);
    const [update, setUpdate] = useState(0);
    const snapAuth = useSnapshot(auth);
    const deleteBloc = useDeleteBlocResponse({
        onSuccess: (res) => {
            queryClient.invalidateQueries('blocReponseInvitation')
            snapAuth.websocket.send("blocReponseInvitation")
            setHiddenAdd(false)
            setActiveKey(null);
        },
    });

    const changeBloc = useChangeBlocResponse({
        onSuccess: (res) => {
            queryClient.invalidateQueries('blocReponseInvitation')
            snapAuth.websocket.send("blocReponseInvitation")

            setActiveKey(null);
        },
        onError: (err) => {
            BtSwal.fire(err.message, '', 'error')
        }
    });


    function onSave(data) {
        changeBloc.mutate(data);
    }

    function toggleAccordionKey(eventKey) {
        if (activeKey == eventKey) {
            setActiveKey(null);
            setHiddenAdd(false)
        }
        else {
            setActiveKey(eventKey);
            setHiddenAdd(true)
        }
    }

    useEffect(() => {
        document?.getElementById("searchBarId")?.focus();
    }, [blocs.isSuccess])

    if (blocs.isError)
        return <Alert variant='danger'>{blocs.error?.message}</Alert>;

    if (blocs.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    return <Accordion activeKey={activeKey} className='mt-7'>
        <div className='d-flex flex-stack flex-wrap'>
            <SearchBar
                solid
                {...register('search')}
                onClear={search?.length > 0 ? () => {
                    setValue('search', '');
                } : null}
            />
            <div className='d-flex'>
                <div hidden={hiddenAdd ? hiddenAdd : hiddenEditLines} className='mx-auto fw-bold'>
                    <Button
                        variant='secondary'
                        className='px-20'
                        onClick={() => {
                            toggleAccordionKey('new')
                            setHiddenEditLines(true);
                            setUpdate(update+1)
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} className='me-2' />
                        Ajouter un bloc
                    </Button>
                </div>
            </div>
        </div>
        {blocs.data?.data?.map(bloc => {
            return <div hidden={hiddenEditLines} className='bg-light my-2 p-3 rounded' key={bloc.id}>
                <div className='d-flex align-content-center align-items-center'>
                    <div className='me-auto fw-bold'>
                        {bloc.nom}
                    </div>
                    <button
                        onClick={() => toggleAccordionKey(bloc.id)}
                        className='btn-sm btn btn-quaternaire p-1 ps-2 pe-2'
                    >
                        <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button
                        onClick={() => BtSwal.fire({
                            title: 'Êtes vous sur de vouloir supprimer le bloc de réponse '+bloc.nom+' ?' ,
                            showDenyButton: true,
                            confirmButtonText: `Oui`,
                            denyButtonText: `Annuler`,
                        }).then((result) => {
                            if (result.isConfirmed){
                                deleteBloc.mutate(bloc.id)
                                BtSwal.fire('Le bloc a été supprimé!', '', 'success')
                                setHiddenAdd(false)
                            } else if (result.isDenied) {
                                BtSwal.fire('Le bloc n\'a pas été supprimé', '', 'info')
                            }
                        })}
                        className='btn-sm btn btn-secondary p-1 ps-2 pe-2'
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
                <Accordion.Collapse eventKey={bloc.id}>
                    <div className='mt-3 d-flex justify-content-center'>
                        <div className='separator' />
                        <BlocResponseForm update={update} bloc={bloc} setHiddenAdd={setHiddenAdd} onSave={onSave} />
                    </div>

                </Accordion.Collapse>
            </div>;
        })}
        <div className='mt-10'>
            <Accordion.Collapse eventKey={'new'}>
                <div className='bg-light rounded mt-3 pt-3 mx-10 d-flex justify-content-center'>
                    <BlocResponseForm onSave={onSave} update={update} setHiddenEditLines={setHiddenEditLines} toggleAccordionKey={toggleAccordionKey} />
                </div>
            </Accordion.Collapse>
        </div>
    </Accordion>;
}

function BlocResponseForm({ bloc, onSave, setHiddenEditLines, setHiddenAdd, toggleAccordionKey, update }) {
    const editorRef = useRef();
    const motsClesSnap = useSnapshot(motsClesProxy);
    const [arrayMotCle, setArrayMotCle] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const uploadFile = useUploadFile({ type: "userfile" });
    if (uploadFile.data != null) {
        $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled",false);
        $(".tox-textfield").first().val(uploadFile.data.urlOriginale)

        if ($(".tox-dialog__title").text() == "Insérer une image"  || $(".tox-dialog__title").text() == "Modifier une image"){
            $(".tox-textfield").eq(1).val("L'image n'a pas pu être chargée")
        }
        else if ($(".tox-textfield").eq(1).val() == ""){
            $(".tox-textfield").eq(1).val("Cliquer ici pour ouvrir la pièce jointe.")
        }
        $(".tox-dialog__footer-end button").eq(1).trigger('click')
    }

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            let checkUrlMotCle = string.slice(0,4)
            return string && checkUrlMotCle == "{url";
        }

        return url.protocol === "https:";
    }

    const {register, reset, getValues, handleSubmit, formState: { errors }} = useForm({
        defaultValues: bloc ?? {
            nom : "",
            corps : null,
        },
    });

    useEffect(() => {
        reset(bloc)
        if (!bloc?.corps){
            editorRef?.current?.setContent("");
        }
    }, [bloc, reset, update])

    function handleSubmitSave() {
        if (setHiddenEditLines) {
            setHiddenEditLines(false)
        }
        if (setHiddenAdd){
            setHiddenAdd(false)
        }
        const corps = editorRef.current?.getContent();
        const nom = getValues('nom');

        if (checkMotCleObligatoire(corps).isOk){
            onSave({
                ...bloc,
                corps,
                nom,
            });
        }else {
            setShowModal(true)
        }

    }

    return <div className='mx-3 mt-5 w-600px'>
        <Form.Group className='mt-5'>
            <Form.Label>Nom</Form.Label>
            <Form.Control maxLength={100} {...register('nom', {required: true})} />
            {errors.nom && <Form.Text className='text-danger'>Nom du bloc requis</Form.Text>}
        </Form.Group>

        <div className='w-600px'>
            <Alert variant='info' className='m-3'>
                La largeur du bloc doit être de 600px.
            </Alert>
            <div className='m-0'>
                <Editor
                    id={"tinyMCEARecup#3#"+(bloc?.id ? bloc?.id : "" )}
                    initialValue={bloc?.corps}
                    onInit={(evt, editor) => editorRef.current = editor}
                    init={{
                        content_style: [contentUiCss, contentCss, '#tinymce {margin:0; overflow-x: hidden;}'].join('\n'),
                        skin: false,
                        language : 'fr_FR',
                        language_url : '/lang/tinymce/fr_FR/langs/fr_FR.js',
                        menubar: false,
                        height: 500,
                        table_responsive_width: true,
                        table_sizing_mode: 'relative',
                        plugins: ["link autolink image code table textcolor"],
                        toolbar: 'fontsizeselect | ' +
                            'bold italic forecolor backcolor | mybutton | alignleft aligncenter alignright alignjustify' +
                            '| bullist numlist | image | link unlink code table',
                        setup: function (editor) {

                            editor.ui.registry.addIcon('link', iconLienPj);
                            editor.on('init',function(e) {
                                editor.windowManager.oldOpen = editor.windowManager.open;
                                editor.windowManager.open = function (t, r) {
                                    var modal = editor.windowManager.oldOpen.apply(this, [t, r]);  // call original
                                    if (this && t.title === "Insert/Edit Link"){
                                        linkUrlTinyMce(t, isValidHttpUrl, bloc, uploadFile);
                                    }
                                    else if (t.title === "Insert/Edit Image"){
                                        fileManagerProxy.baseId = "tinyMCEARecup#3#";
                                        imageTinyMce(isValidHttpUrl, bloc, uploadFile);
                                    }
                                    return modal;

                                }
                            });
                            editor.ui.registry.addMenuButton('mybutton', {
                                text: 'Mots clés',
                                fetch: function (callback) {
                                    let data = [];
                                    motsClesSnap.motsCles.forEach((item) => {
                                        data.push({
                                            type: item.type,
                                            text: item.text,
                                            onAction: function () {
                                                editor.insertContent(item.insert);
                                            }
                                        })
                                    })
                                    callback(data);
                                }
                            });
                        }
                    }}
                />
            </div>
        </div>

        <div className='mt-2 d-flex justify-content-center'>
            <Button
                variant='secondary'
                className='btn-md mb-2'
                onClick={handleSubmit(handleSubmitSave)}
            >{bloc?.id != null ? 'Enregistrer' : 'Ajouter'}</Button>
            <span className="m-2"></span>
            {bloc?.id == null && <Button
                variant='danger'
                className='btn-md mb-2'
                onClick={() => {
                    setHiddenEditLines(false)
                    toggleAccordionKey('new')
                }}
            >Annuler</Button>
            }

        </div>
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size='md'
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Mots clés obligatoires</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="table-responsive">
                    <h1 className="text-center mt-3
                    ">Mots clés obligatoires manquants</h1>
                    <table className="m-auto table mt-4 table-row-dashed  table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                        <tr className="fw-bolder fs-6 text-gray-800 text-center border-0 bg-light">
                            <th className="w-300px rounded-start"></th>
                            <th className="w-100px">Présent</th>
                        </tr>
                        </thead>
                        <tbody className="border-bottom border-dashed">
                            {arrayMotCle}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    </div>;

    function checkMotCleObligatoire(corps){
        let arrAsso = {
            '{urlReponseAccepte}' : {
                text : "Adresse accepter",
                isCheck : false
            },
            '{urlReponseDecline}' : {
                text : "Adresse refuser",
                isCheck : false
            },
            '{urlTransfertInvitation}' : {
                text : "Adresse tranférer",
                isCheck : false
            }
        }
        const arr = [
            '{urlReponseAccepte}',
            '{urlReponseDecline}',
            '{urlTransfertInvitation}'
        ]

        let tabResult =  []
        let allIsOk = true;

        arr.forEach(function(word) {
            if (corps.indexOf(word) > -1){
                arrAsso[word].isCheck = true
            }else {
                allIsOk = false;
            }
            return  corps.indexOf(word) > -1
        })

        Object.values(arrAsso).forEach(e => {
            tabResult.push(<tr className="text-center">
                                <td className="text-start ps-6">
                                    <div className="fw-bold fs-4 text-gray-800">{e.text}</div>
                                </td>
                                <td>
                                    {e.isCheck ? <FontAwesomeIcon icon={faCheck} color={"green"}/> : <FontAwesomeIcon icon={faTimes} color={"red"}/>}
                                </td>
                            </tr>)
        })

        setArrayMotCle(tabResult)

        return {
            isOk : allIsOk,
            data : arrAsso
        }
    }
}

export default BlocResponseSettings;