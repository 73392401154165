import { proxy } from "valtio";

const sujetProxy = proxy({
    idSujet: null,
    editModal: {
        show: false,
        initialiser:false,
    },
    createModal: {
        show: false,
        initialiser:false,
    },
    showModal: {
        show: false,
    },

    showCreateSujet: (sujet) => {
        sujetProxy.sujet = sujet;
        sujetProxy.editModal.show = false;
        sujetProxy.showModal.show = false;
        sujetProxy.createModal = {
            show: true,
            initialiser:true,
        };
    },

    showEditSujet: (idSujet, sujet) => {
        sujetProxy.idSujet = idSujet;
        sujetProxy.sujet = sujet;
        sujetProxy.createModal.show = false;
        sujetProxy.showModal.show = false;
        sujetProxy.editModal = {
            show: true,
            initialiser:true,
        };
    },
    showSujet: (idSujet, sujet) => {
        sujetProxy.idSujet = idSujet;
        sujetProxy.sujet = sujet;
        sujetProxy.createModal.show = false;
        sujetProxy.editModal.show = false;
        sujetProxy.showModal = {
            show: true,
        };
    },
})

export default sujetProxy;