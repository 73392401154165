import {Button, Dropdown, Form} from "react-bootstrap";
import { ReactComponent as FillDrip } from "@assets/icons/fillDrip.svg";
import {useForm} from "react-hook-form";
import {BlockPicker} from "react-color";
import {useSnapshot} from "valtio";
import mailEditorProxy from "../../proxies/mailEditorProxy";

function ButtonCreator() {
    const mailEditorSnap = useSnapshot(mailEditorProxy);

    const { register, handleSubmit, formState: { errors }, reset, watch, setValue, getValues } = useForm({
            defaultValues : {
                btnText : mailEditorSnap?.block?.content?.blocButtonTransferes?.txtbtn ? mailEditorSnap.block.content.blocButtonTransferes.txtbtn : "Transférer",
                primary_color : mailEditorSnap?.block?.content?.blocButtonTransferes?.backgroundColorBtn ? mailEditorSnap.block.content.blocButtonTransferes.backgroundColorBtn :"#0060a5",
                border_color : mailEditorSnap?.block?.content?.blocButtonTransferes?.borderColor ? mailEditorSnap.block.content.blocButtonTransferes.borderColor :"#0060a5",
                borderWeight : mailEditorSnap?.block?.content?.blocButtonTransferes?.borderWeight ? mailEditorSnap.block.content.blocButtonTransferes.borderWeight :2,
                secondary_color : mailEditorSnap?.block?.content?.blocButtonTransferes?.textColor ? mailEditorSnap.block.content.blocButtonTransferes.textColor :"#fff",
                percentWidthBtn : mailEditorSnap?.block?.content?.blocButtonTransferes?.percentWidthBtn ? mailEditorSnap.block.content.blocButtonTransferes.percentWidthBtn :"auto"
            }
        }
    );
    const txtbtn = watch("btnText");
    const backgroundColorBtn = watch("primary_color");
    const textColor = watch("secondary_color");
    const borderColor = watch("border_color");
    const borderWeight = watch("borderWeight");
    const percentWidthBtn = watch("percentWidthBtn");

    return <div className="row">
        <div className="col-lg-6 col-md-6">
            <div className="row">
                <Form.Group className="col-lg-6">
                    <Form.Label className="required">Texte</Form.Label>
                    <Form.Control className='form-control' {...register('btnText', { required:true} )} />
                    {errors.btnText && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Form.Group>
                <div className="col-4">
                    <Form.Group>
                        <Form.Label className="required">Largeur</Form.Label>
                        <Form.Select aria-label="Default select example"
                                     {...register('percentWidthBtn', { required:true} )}
                        >
                            <option defaultChecked={true} value="auto">Ajusté</option>
                            <option value="100%">Toute la largeur du bloc du mail</option>
                        </Form.Select>
                    </Form.Group>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center mt-5">
                <div className="d-flex align-items-center w-100">
                    <Form.Label className="required me-2">Couleur de fond</Form.Label>
                    <div className="h-25px w-50px border-2 border-dark" style={{border: "solid", backgroundColor: getValues("primary_color") }}>

                    </div>
                    <Dropdown title={'Choix de la couleur primaire'}>
                        <Dropdown.Toggle className='hide-after' variant='white'>
                            <span className='svg-icon svg-icon-1 me-2'><FillDrip/></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='p-0'>
                            <BlockPicker
                                onChange={(color) => {
                                    setValue("primary_color", color?.hex)
                                }}
                                color={getValues("primary_color")}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center mt-5">
                <div className="d-flex align-items-center w-100">
                    <div className="row">
                        <div className="col-8">
                            <Form.Label className="required me-2">Couleur de la bordure</Form.Label>
                            <div className="d-flex">
                                <div className="h-25px w-50px border-2 border-dark" style={{border: "solid", backgroundColor: getValues("border_color") }}>

                                </div>
                                <Dropdown title={'Choix de la couleur primaire'}>
                                    <Dropdown.Toggle className='hide-after' variant='white'>
                                        <span className='svg-icon svg-icon-1 me-2'><FillDrip/></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='p-0'>
                                        <BlockPicker
                                            onChange={(color) => {
                                                setValue("border_color", color?.hex)
                                            }}
                                            color={getValues("border_color")}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="col-4">
                            <Form.Group>
                                <Form.Label className="required">Epaisseur</Form.Label>
                                <Form.Control
                                    defaultValue={borderWeight} type="number" className='form-control' {...register('borderWeight', { required:true} )} />
                                {errors.borderWeight && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center mt-5">
                <div className="d-flex align-items-center w-100">
                    <Form.Label className="required me-2">Couleur du texte</Form.Label>
                    <div className="h-25px w-50px border-2 border-dark" style={{border: "solid", backgroundColor: getValues("secondary_color") }}>

                    </div>
                    <Dropdown title={'Choix de la couleur primaire'}>
                        <Dropdown.Toggle className='hide-after' variant='white'>
                            <span className='svg-icon svg-icon-1 me-2'><FillDrip/></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='p-0'>
                            <BlockPicker
                                onChange={(color) => {
                                    setValue("secondary_color", color?.hex)
                                }}
                                color={getValues("secondary_color")}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-md-6">
            <p style={{textAlign:"center"}} className="mb-10">
                <a
                    style={{
                        width:percentWidthBtn,
                        textAlign:"center",
                        height:"fit-content",
                        padding: "8px 12px",
                        border: borderWeight+"px solid "+borderColor,
                        backgroundColor: backgroundColorBtn,
                        cursor: "pointer",
                        fontFamily:" Helvetica, Arial, sans-serif",
                        fontSize: "14px",
                        color: textColor,
                        textDecoration: "none",
                        fontWeight: "bold",
                        display: "inline-block"}}
                    href="{urlTransfertEmail}" target="_blank" rel="noopener">{txtbtn}</a>
            </p>
            <div className="text-center">
                <Button
                    onClick={() => {
                        let result = {
                            ...mailEditorSnap.block,
                            content: {
                                ...mailEditorSnap.block.content,
                                blocButtonTransferes: {
                                    blocButtonTransfere: '<p style="text-align: center"><a class="customBtnTransfert" style="box-sizing: border-box; width:'+percentWidthBtn+';padding: 8px 12px; border: '+borderWeight+'px solid '+borderColor+'; background-color: '+backgroundColorBtn+'; cursor: pointer; border-radius: 2px; font-family: Helvetica, Arial, sans-serif; font-size: 14px; color: '+textColor+'; text-decoration: none; font-weight: bold; display: inline-block;" href="{urlTransfertEmail}" target="_blank" rel="noopener"> '+txtbtn+'</a></p>',
                                    percentWidthBtn: percentWidthBtn,
                                    borderWeight: borderWeight,
                                    borderColor: borderColor,
                                    backgroundColorBtn: backgroundColorBtn,
                                    textColor: textColor,
                                    txtbtn: txtbtn
                                }
                            }
                        };
                        mailEditorSnap.onChange(result)
                        mailEditorProxy.modalCustomButtonTranfert = false;
                    }}
                    variant={"secondary"}
                    >Valider</Button>
            </div>
        </div>
    </div>
}

export default ButtonCreator;
