import { useMutation } from "react-query";
import api from "@services/api";

export default function useChangeUpload(props) {
    return useMutation(changeUpload, props);
}

export async function changeUpload(upload) {
    const id = upload.id;

    if (id != null)
        return api.put(`upload/${id}`, { json: upload }).json();
}
