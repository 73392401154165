import { useMutation } from "react-query";
import api from "@services/api";
import auth from "@services/auth";

export default function useChangeFooter(props) {
    return useMutation(changeFooter, props);
}

function changeFooter(footer) {
    const id = footer?.id;
    const idAgence = auth.agence?.id;

    const params = { ...footer, idAgence };

    if (id != null)
        return api.put(`signature/${id}`, { json: params }).json();
    return api.post('signature', { json: params }).json();
}