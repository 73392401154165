import { Button, Spinner } from "react-bootstrap";

function LoadingButton({ onClick, disabled, loading, children, ...props }) {
    return <Button
        variant="secondary"
        disabled={disabled || loading}
        onClick={onClick}
        {...props}
    >
        {loading
            ? <>Chargement<Spinner animation='border' className='align-middle ms-2'/></>
            : children
        }
    </Button>
}

export default LoadingButton;