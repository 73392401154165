import {useEffect, useMemo, useState} from "react";
import {useController} from "react-hook-form";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise"
import useGetResponsablePlaceLimite
    from "../../../requests/parametres/responsablePlaceLimite/useGetResponsablePlaceLimite";
import {getLoadOptionsResponsable} from "../../../requests/selectionRequests/responsablePlaceLimite";

function ResponsableSelection({ control, name, rules, init }) {
    const responsable = useGetResponsablePlaceLimite("", 15);
    const { field } = useController({ control, name, rules });
    const data = useMemo(() => {
        return responsable.data?.data?.map(exp => ({
            id: exp.id,
            title: `${exp.prenomNom} (${exp.email})`,
        }));
    }, [responsable.data]);
    const [selectedValue, setSelectedValue] = useState(field?.value?.id != null ? {id:field.value.id,title:field.value.prenomNom+" ("+field.value.email+") "} : null);
    useEffect(() => {
        if (field.value && field?.value?.id && !selectedValue){
            setSelectedValue( {id:field.value.id,title:field.value.prenomNom+" ("+field.value.email+") "} )
        }
    }, [init])
    const handleChange = value => {
        setSelectedValue(value);
    }
    const loadOptions = getLoadOptionsResponsable();
    const debouncedLoadOptions = debounce(loadOptions, 500)

    return <AsyncSelect
        placeholder={"Choisir un responsable"}
        noOptionsMessage={() => "Aucun résultat"}
        loadingMessage={() => "Chargement..."}
        styles={{
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? 'white' : '#716d66',
                backgroundColor: state.isSelected ? 'var(--primary-color)' : 'white',
                "&:hover":{
                    backgroundColor: !state.isSelected ? 'var(--primary-color-light)' : "var(--primary-color)",
                }
            }),
            control: (base) => ({
                ...base,
                backgroundColor : "#f8f6f2",
                borderRadius : ".95rem",
                color: '#716d66',
            }),
        }}
        cacheOptions
        defaultOptions={data}
        value={selectedValue}
        getOptionLabel={e => e.title}
        getOptionValue={e => e.id}
        loadOptions={debouncedLoadOptions}
        onChange={ (selected) => {
            handleChange(selected)
            field.onChange(selected)
        }}
    />
}

export default ResponsableSelection;