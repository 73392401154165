import useGetHeaders from "@requests/communication/header/useGetHeaders";
import { useRef } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import useChangeHeader from "@requests/communication/header/useChangeHeader";
import useUploadFile from "@requests/upload/useUploadFile";
import { showErrorAlert } from "@utils/alerts/showErrorAlert";
import { useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus, faQuestionCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import useDeleteHeader from "../../../requests/communication/header/useDeleteHeader";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import toast from "react-hot-toast";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

function HeaderSettings() {
    const queryClient = useQueryClient();

    const headers = useGetHeaders();
    const deleteHeader = useDeleteHeader();
    const snapAuth = useSnapshot(auth);
    const changeHeader = useChangeHeader({
        onError: (err) => {
            showErrorAlert("Erreur lors de la création de l'entête", err.message);
        },
        onSuccess: () => {
            queryClient.invalidateQueries('headers');
            snapAuth.websocket.send("headers")

            toast.success("Entête ajouté");
        },
    });
    const uploadFile = useUploadFile({
        type: 'header',
        onSuccess: (data) => {
            changeHeader.mutate({
                upload: data,
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'upload de l'image", err.message);
        },
    });

    const inputFile = useRef();
    function handleUploadInput(e) {
        const file = e.target.files[0];
        uploadFile.mutate(file);
    }

    return <div className='mt-7'>
        <div className='d-flex justify-content-center mt-5'>
            <OverlayTrigger
                key='mailTestInfo'
                placement='right'
                overlay={<Tooltip id='mailTestInfo'>
                    Largeur de <span className='text-info'>600px</span> conseillée
                </Tooltip>}
            >
                <Button
                    variant='secondary'
                    className='px-20'
                    onClick={() => inputFile.current?.click()}
                >
                    <FontAwesomeIcon icon={faPlus} className='me-2' />
                    Ajouter une entête
                    <FontAwesomeIcon icon={faQuestionCircle} size='sm' className='ms-2' />
                </Button>
            </OverlayTrigger>
        </div>
        {headers.data?.data?.map(
            h => <div key={h.id} className='py-3 text-center'>
                <img
                    className='w-100 mw-600px text-danger'
                    src={h.upload?.urlOriginale}
                    alt="Impossible de charger l'entête"
                />
                <button
                    onClick={() => BtSwal.fire({
                        title: 'Êtes vous sur de vouloir supprimer l\'entête ?',
                        showDenyButton: true,
                        confirmButtonText: `Oui`,
                        denyButtonText: `Annuler`,
                    }).then((result) => {
                        if (result.isConfirmed){
                            deleteHeader.mutate(h.id)
                            BtSwal.fire('L\'entête a été supprimé!', '', 'success')
                        } else if (result.isDenied) {
                            BtSwal.fire('L\'entête n\'a pas été supprimé', '', 'info')
                        }
                    })}
                    className='ms-2 btn-sm btn btn-secondary p-2 ps-3 pe-3'
                >
                    <FontAwesomeIcon icon={faTrash} size={"2x"} />
                </button>
            </div>
        )}
        <input
            type='file'
            id='file'
            ref={inputFile}
            style={{ display: 'none' }}
            accept='.png,.jpg,.jpeg'
            onChange={handleUploadInput}
        />
    </div>;
}

export default HeaderSettings;