import { Alert, Button, Container, Form, Modal, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PersonneForm from './PersonneForm/PersonneForm';
import EntrepriseSelection from './EntrepriseSelection/EntrepriseSelection';
import { useEffect, useState } from 'react';
import ProfilSelection from './ProfilSelection/ProfilSelection';
import { BtSwal } from '@utils/alerts/sweetAlert';
import BlockUI from '@components/BlockUI/BlockUI';
import useGetEntreprisePersonne from '@requests/personne/useGetEntreprisesPersonne';
import useDelierAnnuaire from '@requests/annuaire/useDelierAnnuaire';
import useChangeAllAnnuaire from '@requests/annuaire/useChangeAllAnnuaire';
import { useSnapshot } from 'valtio';
import annuaireProxy from '@proxies/annuaire';
import { prepareEntrepriseList } from './prepareEntrepriseList';
import useChangeAllUtilisateur from "../../../requests/annuaire/useChangeAllUtilisateur";
import toast from "react-hot-toast";
import invitationsProxy from "../../../proxies/invitations";
import mailsProxy from "../../../proxies/mails";
import smsProxy from "../../../proxies/sms";
import speedBusinessProxy from "../../../proxies/speedBusiness";

function ContactModal() {
    const annuaireSnapshot = useSnapshot(annuaireProxy);
    const entreprises = useGetEntreprisePersonne(annuaireSnapshot.personne?.id, annuaireSnapshot.isUtilisateur);
    const [stateEntreprise, setStateEntreprise] = useState([]);
    const [updateEntreprise, setUpdateEntreprise] = useState(0);
    const delier = useDelierAnnuaire();
    const changeAllAnnuaire = useChangeAllAnnuaire();
    const changeAllUtilisateur = useChangeAllUtilisateur();
    const [entrepriseInEdit, setEntrepriseInEdit] = useState(false);
    const invitationSnap = useSnapshot(invitationsProxy);
    const emailSnap = useSnapshot(mailsProxy);
    const smsSnap = useSnapshot(smsProxy);
    const speedSnap = useSnapshot(speedBusinessProxy);
    const {
        register, handleSubmit, control, watch, getValues, reset, setValue, setError, clearErrors,
        formState: { errors }
    } = useForm({
        defaultValues: annuaireSnapshot.personne
    });
    const tabProfils = watch('tabProfils');
    const [beforeEdit, setBeforeEdit] = useState(null);

    useEffect(() => {
        if (annuaireSnapshot.personne?.id){
            entreprises.refetch().then(data => {
                setStateEntreprise(data?.data?.data);
            });
        }else {
            setStateEntreprise([]);
        }
    }, [annuaireSnapshot.personne?.id, annuaireSnapshot.isUtilisateur, updateEntreprise])
    const [dataInit, setDataInit] = useState({})
    // TODO Change "setBeforeEdit" mechanism
    useEffect(() => {
        setBeforeEdit(annuaireSnapshot.personne);
        setDataInit(annuaireSnapshot.personne ? annuaireSnapshot.personne : {})
        reset(annuaireSnapshot.personne ?? {
            civilite: "Monsieur",
            nom: null,
            prenom: null,
            fonction: null,
            tel1: null,
            tel2: null,
            indicatifTel1:"33",
            indicatifTel2:"33",
            adresse: null,
            codePostal: null,
            ville: null,
            email: null,
            dateAnniversaire: null,
            datePremiereLicence: null,
            urlFacebook: null,
            urlTwitter: null,
            urlInstagram: null,
            urlYoutube: null,
            urlLinkedin: null,
            urlTiktok: null,
            identifiant: null,
            motDePasse: null,
            isVouvoye: true,
            tel1IsVisible: false,
            tel2IsVisible: false,
            emailIsVisible: true,
            isVisibleInTrombinoscope: true,
            tabProfils: null,
            tabChampsPersonnalisesAnnuaire:null,
            upload: {}
        });
    }, [setBeforeEdit, annuaireSnapshot.personne, reset]);


    useEffect(() => {
        let entreprisePartenaire = false;
        stateEntreprise.forEach((entreprise) => {
            if (!!Object.values(entreprise.tabProfils).find(item => item.isPartenaire)){
                entreprisePartenaire = true;
            }
        })
        if (entreprisePartenaire){
            annuaireProxy.partenaireIsSelectedAndNoEntreprisePartenaire = false;
        }
    }, [stateEntreprise])

    const isPartenaire = tabProfils != null
        ? !!Object.values(tabProfils).find(item => item.isPartenaire)
        : null;

    async function saveChanges(personne, entreprise, isBtnEnregistrer) {
        if (annuaireProxy.partenaireIsSelectedAndNoEntreprisePartenaire && isBtnEnregistrer){
            BtSwal.fire("Erreur profil partenaire", "Merci de rajouter une entreprise aillant le profil partenaire.", "warning")
            return false;
        }else {
            const mutatedEntreprises = prepareEntrepriseList(stateEntreprise, entreprise, isPartenaire);
            try {
                if (annuaireSnapshot.isUtilisateur){
                    if (dataInit?.tabChampsPersonnalisesAnnuaire != null && personne?.tabChampsPersonnalisesAnnuaire != null){
                        Object.values(dataInit?.tabChampsPersonnalisesAnnuaire).forEach((item) => {
                            let currentValeur = Object.values(personne?.tabChampsPersonnalisesAnnuaire)?.find(e => e?.champPersonnaliseAnnuaire?.id == item?.champPersonnaliseAnnuaire?.id);
                            if (item?.valeur && !currentValeur.valeur){
                                currentValeur.valeur = "#$#"
                            }
                        })
                    }
                    await changeAllUtilisateur.mutateAsync({ personne, entreprises: mutatedEntreprises }).then(function (e){
                        annuaireProxy.personne = e.personne
                        annuaireProxy.entreprise = mutatedEntreprises
                        if (entreprise){
                            toast.success("Entreprise enregistré")
                        }
                    });
                }else {
                    if (dataInit?.tabChampsPersonnalisesAnnuaire != null && personne?.tabChampsPersonnalisesAnnuaire != null){
                        Object.values(dataInit?.tabChampsPersonnalisesAnnuaire).forEach((item) => {
                            let currentValeur = Object.values(personne?.tabChampsPersonnalisesAnnuaire)?.find(e => e?.champPersonnaliseAnnuaire?.id == item?.champPersonnaliseAnnuaire?.id);
                            if (item?.valeur && !currentValeur.valeur){
                                currentValeur.valeur = "#$#"
                            }
                        })
                    }
                    await changeAllAnnuaire.mutateAsync({ personne, entreprises: mutatedEntreprises }).then(function (e){
                        annuaireProxy.personne = e.personne
                        annuaireProxy.entreprise = mutatedEntreprises
                        if (invitationSnap.editModal.show || emailSnap.editModal.show || smsSnap.editModal.show){
                            annuaireProxy.addAutoDestinataire = true;
                        }else if (speedSnap.addAutoParticipant){
                            annuaireProxy.addAutoDestinataire = true;
                        }
                    });
                }

            } catch {
                return false;
            }
            if (isBtnEnregistrer){
                annuaireProxy.showModal = false;
            }
            return true;
        }
    }

    const hasChanged = () => JSON.stringify(getValues()) !== JSON.stringify(beforeEdit);

    const loading = changeAllAnnuaire.isLoading || entreprises.isLoading || delier.isLoading;

    return <Modal
        show={annuaireSnapshot.showModal}
        onHide={async () => {
            if (hasChanged()) {
                const result = await BtSwal.fire({
                    title: 'Êtes vous sûr de vouloir quitter ?',
                    text: 'Les modifications non enregistrées seront perdues',
                    icon: 'question',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Oui',
                    denyButtonText: 'Non',
                    cancelButtonText: 'Annuler',
                });
                if (result.isConfirmed) annuaireProxy.showModal = false;
            }
            else if (entrepriseInEdit){
                const result = await BtSwal.fire({
                    title: 'Êtes vous sûr de vouloir quitter ?',
                    text: 'Une entreprise est en édition les modifications non enregistrées seront perdues',
                    icon: 'question',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Oui',
                    denyButtonText: 'Non',
                    cancelButtonText: 'Annuler',
                });
                if (result.isConfirmed) {
                    annuaireProxy.showModal = false;
                    setEntrepriseInEdit(false);
                }
            }
            else
                annuaireProxy.showModal = false;
        }}
        dialogClassName="modal-dialog modal-fullscreen-lg-down modal-lg"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Modifier le contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <BlockUI loading={loading} className='rounded p-3'>
                <Container>
                    <div>
                        {!annuaireSnapshot.isUtilisateur &&
                        <Form.Group>
                            <Form.Label>Profils de la personne</Form.Label>
                            <ProfilSelection
                                solid
                                control={control}
                                name='tabProfils'
                                isPersonne={true}
                            />
                            <div className='separator my-10' />
                        </Form.Group> }
                        <Tabs
                            activeKey={annuaireSnapshot.initTab}
                            variant='pills'
                            className='fs-4 text-muted mb-10 justify-content-center'
                            onSelect={async (value) => {
                                if (value !== annuaireSnapshot.initTab) {
                                    // Save user before editing entreprise
                                    if (value === 'entreprise')
                                        handleSubmit(async (data) => {
                                            if (await saveChanges(data))
                                                annuaireProxy.initTab = value;
                                        })();
                                    else
                                        annuaireProxy.initTab = value;
                                }
                            }}
                        >
                            <Tab eventKey='personne' title='Personne'>
                                <PersonneForm
                                    isUtilisateur={annuaireSnapshot.isUtilisateur}
                                    setValue={setValue}
                                    getValues={getValues}
                                    setError={setError}
                                    clearErrors={clearErrors}
                                    control={control}
                                    register={register}
                                    isPartenaire={isPartenaire}
                                    watch={watch}
                                    errors={errors}
                                    onAutocomplete={(newPersonne) => annuaireProxy.personne = newPersonne}
                                />
                            </Tab>
                            <Tab eventKey='entreprise' title='Entreprise' tabClassName={`ms-10 ${isPartenaire ? 'required' : ''}`}>
                                {entreprises.isError && <Alert variant='danger'>{entreprises.error.message}</Alert>}
                                {entreprises.isLoading && <div className='text-center'><Spinner animation='border' /></div>}
                                {entreprises.isSuccess && <EntrepriseSelection
                                    setEntrepriseInEdit={setEntrepriseInEdit}
                                    isPartenaire={isPartenaire}
                                    personne={annuaireSnapshot.personne}
                                    entreprises={stateEntreprise}
                                    onSave={async (newEntreprise) => {
                                        const newPersonne = getValues();
                                        await saveChanges(newPersonne, newEntreprise);
                                        setUpdateEntreprise(updateEntreprise+1)
                                    }}
                                    onRemove={async entreprise => {
                                        setEntrepriseInEdit(false);
                                        delier.mutate({ personne: annuaireSnapshot.personne, entreprise });
                                        try {
                                            await changeAllAnnuaire.mutateAsync({ personne:annuaireSnapshot.personne, entreprises:[]}).then(function (e){
                                                annuaireProxy.personne = e.personne
                                                setUpdateEntreprise(updateEntreprise+1);
                                            });
                                        } catch {
                                            return false;
                                        }
                                    }}
                                />}
                            </Tab>
                        </Tabs>
                    </div>
                </Container>
            </BlockUI>
        </Modal.Body>
        <Modal.Footer>
            {!entrepriseInEdit ? <Button variant={"secondary"} onClick={handleSubmit((data) => saveChanges(data, null, true), (errors) => {
                    if (errors["email"] && annuaireSnapshot.initTab == "entreprise"){
                        BtSwal.fire("Email manquant", "", "info");
                        annuaireProxy.initTab = "personne";
                    }
                })}>Enregistrer le contact</Button>
            : <Button disabled={true} variant={"danger"}>Merci d'enregistrer l'entreprise en édition</Button>
            }
        </Modal.Footer>
    </Modal>;
}
export default ContactModal;