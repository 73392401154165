import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

export default function useValidPresenceInvitation() {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);

    return useMutation(validPresence, {
        onSuccess: (res) => {
            queryClient.invalidateQueries('invitations');
            snapAuth.websocket.send("invitations")

            // queryClient.setQueryData('invitations', res);
        },
        onError: (err) => {
            toast.error(err.message);
        },
    });
}

function validPresence(invitation) {

    const id = invitation.id;

    if (id != null)
        return api.post(`correspondantInvitation/${id}/ValiderPresence`, { json: {
                idEtatPresence: invitation.idEtatPresence
            } }).json();
}