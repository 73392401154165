import { routes } from "@utils/routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { useRoutes } from "react-router";
import './style.css';
window.Buffer = window.Buffer || require("buffer").Buffer;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            retry: false,
        },
    },
});

function App() {

    const element = useRoutes(routes);

    return <QueryClientProvider client={queryClient}>
        {/*<UserFetcher>*/}
            {element}
        {/*</UserFetcher>*/}
    </QueryClientProvider>;
}

export default App;