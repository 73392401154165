import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";
import usePublierSujet from "../../requests/forum/usePublierSujet";
import useDepublierSujet from "../../requests/forum/useDepublierSujet";

export default function useEditSujet(idAgence, corps, objet, personne, categorie, id, vues, tabEtats, datePublication, isReadOnly) {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const publierSujet = usePublierSujet(null, categorie?.libelle);
    const depublierSujet = useDepublierSujet(null, categorie?.libelle);
    let jsonCreationSujet = {
        idAgence: idAgence,
        // isPublie: isPublie,
        tabEtats : tabEtats,
        isLectureSeule : isReadOnly,
        nbVues: vues,
        objet: objet,
        corps: corps,
        datePublication: datePublication,
        auteur: {
            personne : personne
        },
        categorie: categorie,
        id : parseInt(id)
    }


    return useMutation((tabEtats) => {
        return api.put('sujetForum/'+id, {
            json:  {...jsonCreationSujet,
                // tabEtats : tabEtats
            },
        }).json();
    }, {
        onSuccess: (res, tabEtats) => {
            switch (tabEtats.find(item => item.actif).id) {
                case 0 :
                    if (res?.tabEtats.find(item => item.actif).id != tabEtats.find(item => item.actif).id){
                        publierSujet.mutate(res.id)
                    }
                    break;
                case 1 :
                    if (res?.tabEtats.find(item => item.actif).id != tabEtats.find(item => item.actif).id) {
                        depublierSujet.mutate({idEtat: 1, idNew: res.id})
                    }
                    break;
                case 2 :
                    if (res?.tabEtats.find(item => item.actif).id != tabEtats.find(item => item.actif).id) {
                        depublierSujet.mutate({idEtat: 2, idNew: res.id})
                    }
                    break;
                case 3 :
                    if (res?.tabEtats.find(item => item.actif).id != tabEtats.find(item => item.actif).id) {
                        depublierSujet.mutate({idEtat: 3, idNew: res.id})
                    }
                    break;
                case 4 :
                    if (res?.tabEtats.find(item => item.actif).id != tabEtats.find(item => item.actif).id) {
                        depublierSujet.mutate({idEtat: 4, idNew: res.id})
                    }
                    break;
            }
            queryClient.invalidateQueries("sujetForumByCategories"+categorie.libelle);
            queryClient.invalidateQueries("sujetForum");
            queryClient.invalidateQueries(id);
            snapAuth.websocket.send("sujetForum")
            snapAuth.websocket.send("sujetForumByCategories"+categorie.libelle)

        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}