import { useMutation } from "react-query";
import baseApi from "../../../services/apiPublic";

export default function useChangeCorrespondant(props) {
    return useMutation(changeCorrespondant, props);
}

export async function changeCorrespondant(correspondant) {
    const id = correspondant.id;

    if (id != null)
        return baseApi.post(`correspondantInvitation/${id}/repondre`, { json: correspondant }).json();

}
