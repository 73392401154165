import { Controller } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import { FormControl, InputGroup } from "react-bootstrap";
import { French } from "flatpickr/dist/l10n/fr"
import { ReactComponent as Gen014 } from '@assets/icons/gen014.svg'
import moment from "moment";

function FormDatePickerBis({ control, name, solid, enableTime, rules, }) {
    return <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => <Flatpickr
            value={field.value}
            onChange={date => {
                if (enableTime)
                    field.onChange(moment(date[0]).format("DD/MM/YYYY à HH:mm"));
                else
                    field.onChange(moment(date[0]).format("DD/MM/YYYY"));
            }}
            render={
                (props, ref) => {
                    return <InputGroup className={solid ? 'input-group-solid' : ''}>
                        <InputGroup.Text>
                            <span className='svg-icon svg-icon-fluid w-25px'>
                                <Gen014 />
                            </span>
                        </InputGroup.Text>
                        <FormControl {...props} ref={ref} className={solid ? 'form-control-solid' : ''} />
                    </InputGroup>
                }
            }
            options={{
                locale: French,
                dateFormat: enableTime ? 'd/m/Y à H:i' : 'd/m/Y',
                enableTime: enableTime,
            }}
        />}
    />;
}

export default FormDatePickerBis;