import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import useChangeSpeedBusiness from "../../../requests/communication/speedBusiness/useChangeSpeedBusiness";
import {useForm} from "react-hook-form";
import {useSnapshot} from "valtio";
import {Col, Form, Row} from "react-bootstrap";

const PersonnalisationTablesStep = forwardRef((_, ref) => {
    const changeSpeed = useChangeSpeedBusiness();
    const speedSnap = useSnapshot(speedBusinessProxy)
    const {
        register, setValue, getValues, handleSubmit, reset, control,
        formState: { errors },
    } = useForm({
        defaultValues: Object.assign({}, speedBusinessProxy?.speedBusiness?.tabNomsTables ? [...speedBusinessProxy?.speedBusiness?.tabNomsTables] : ""),
    });
    useImperativeHandle(ref, () => ({
        save: async () => {
            let done = false;
            await handleSubmit(async (data) => {
                try {
                    speedBusinessProxy.speedBusiness.tabNomsTables = Object.values(getValues());
                    await changeSpeed.mutateAsync(speedBusinessProxy.speedBusiness);
                    done = true;
                }catch {}
            })();
            return done;
        },
    }));
    useEffect(() => {
        if (speedBusinessProxy.speedBusiness && speedBusinessProxy.speedBusiness.tabNomsTables){
            reset(Object.assign({}, speedBusinessProxy?.speedBusiness?.tabNomsTables ? [...speedBusinessProxy?.speedBusiness?.tabNomsTables] : ""))
        }
    }, [speedSnap.speedBusiness, reset]);

    var tabInputNameTable = [];
    for (var i = 0; i < speedSnap.speedBusiness?.nombreTablesChoisies; i++) {
        tabInputNameTable.push(<Col className="mt-3" lg={4} md={6}>
            <Form.Group>
                <Form.Label className='required'>Nommer table {i+1}</Form.Label>
                <Form.Control defaultValue={"Table "+(i+1)}
                              type="text"
                              className='form-control-solid' width={10} {...register(""+i, {required: true})} placeholder='Nom de la table '/>
            </Form.Group>
            {errors[i] && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
        </Col>);
    }

    return  <Row className="container m-auto">
        {tabInputNameTable}
    </Row>

});

export default PersonnalisationTablesStep;