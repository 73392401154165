import {Collapse, Button, Col, Row, Stack, Form, Card, InputGroup} from "react-bootstrap";
import React, {useMemo, useState} from "react";
import FormImagePicker from "@components/form/FormImagePicker/FormImagePicker";
import PartenaireSettings from "./PartenaireSettings/PartenaireSettings";
import useSearchPersonne from "@requests/personne/useSearchPersonne";
import FormTypeahead from "@components/form/FormTypeahead/FormTypeahead";
import { useDebounce } from 'use-debounce';
import CiviliteSelection from "../../../personne/CiviliteSelection/CiviliteSelection";
import useGetVilleFromCodePostal from "../../../../requests/geoname/useGetVilleFromCodePostal";
import $ from 'jquery';
import {
    codePostalPattern,
    emailPattern,
    telAnnuairePattern,
    telPattern,
    urlPattern
} from "../../../../functions/patterns";
import PrefixeTelSelection from "../../../communication/PrefixeTelSelection/PrefixeTelSelection";
import useGetChampPerso from "../../../../requests/annuaire/useGetChampPerso";
import FormDatePickerBis from "../../../../components/form/FormDatePicker/FormDatePickerBis";
import Select2 from "../../../../components/Select2/Select2";

function PersonneForm({ control, register, watch, isPartenaire, errors, onAutocomplete, setValue, getValues, setError, clearErrors, isUtilisateur }) {
    const [showMore, setShowMore] = useState(false);
    const [showMoreChampsPerso, setShowMoreChampsPerso] = useState(false);
    const champsPerso = useGetChampPerso("");
    const id = watch('id');
    const nom = watch('nom');
    const prenom = watch('prenom');
    const code = watch('codePostal');
    const ville = watch('ville');
    const [searchParams] = useDebounce(useMemo(() => ({ nom, prenom }), [nom, prenom]), 300);
    const [searchVilleParams] = useDebounce(useMemo(() => ({code, ville}), [code, ville]), 300);
    const autocompleteDisabled = id != null;
    const autocompletion = useSearchPersonne({ ...searchParams, disabled: autocompleteDisabled });
    const autocompletionCodePostal = useGetVilleFromCodePostal({...searchVilleParams});
    const [updateChoice, setUpdateChoice] = useState(0);

    const arrayTypesFields = (index, data= null, arrayTabChoice = []) => {
        return [
            <Form.Control
                type="text" className='form-control'
                {...register(`tabChampsPersonnalisesAnnuaire.${index}.valeur`)}
            />,
            <Form.Control
                as='textarea'
                className='form-control'
                rows={5}
                {...register(`tabChampsPersonnalisesAnnuaire.${index}.valeur`)}
            />,
            <Form.Control type="number" className='form-control'
                          {...register(`tabChampsPersonnalisesAnnuaire.${index}.valeur`)}
            />,
            <FormDatePickerBis control={control} name={`tabChampsPersonnalisesAnnuaire.${index}.valeur`} />,
            <FormDatePickerBis control={control} name={`tabChampsPersonnalisesAnnuaire.${index}.valeur`} enableTime={true} />,
            <Select2
                     allowClear={true}
                     data={data}
                     onChange={(data) => {
                         if (data?.at(0)){
                             setValue(`tabChampsPersonnalisesAnnuaire.${index}.valeur`, getValues(`tabChampsPersonnalisesAnnuaire.${index}.champPersonnaliseAnnuaire.parametre.tabSelect`).find(e => e.id == data?.at(0)).text)
                         }else {
                             setValue(`tabChampsPersonnalisesAnnuaire.${index}.valeur`, null)
                         }
                     }}
                     selected={(getValues(`tabChampsPersonnalisesAnnuaire.${index}.valeur`) != null && getValues(`tabChampsPersonnalisesAnnuaire.${index}.champPersonnaliseAnnuaire.parametre.type`) == `select`) ? getValues(`tabChampsPersonnalisesAnnuaire.${index}.champPersonnaliseAnnuaire.parametre.tabSelect`).find(e => e.text == getValues(`tabChampsPersonnalisesAnnuaire.${index}.valeur`))?.id : null}
                     minimumResultsForSearch={3}
                     placeholder='Choisir'
            />,
            [   <div className="btn-group d-flex" data-kt-buttons="true"
                     data-kt-buttons-target="[data-kt-button]">
                {(arrayTabChoice).map((value, index2) =>
                    <label
                        key={index2}
                        className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${value.text == getValues(`tabChampsPersonnalisesAnnuaire.${index}.valeur`) ? `active` : ``}`}
                        data-kt-button="true">
                        <input
                            onClick={(err) => {
                                setValue(`tabChampsPersonnalisesAnnuaire.${index}.valeur`, value.text)
                                setUpdateChoice(updateChoice+1)
                            }}
                            className="btn-check" type="radio" name="method" value={value.text}/>
                        <span style={{fontSize: '10px'}}>{value.text}</span></label>
                )}
                </div>
            ],
            <Form.Check
                className="me-5"
                checked={getValues(`tabChampsPersonnalisesAnnuaire.${index}.valeur`) != null}
                type={"checkbox"}
                id={`default-radio`}
                // label={getValues(`tabChampsPersonnalisesAnnuaire.${index}.valeur`}
                onClick={() => {
                    if (getValues(`tabChampsPersonnalisesAnnuaire.${index}.valeur`)) {
                        setValue(`tabChampsPersonnalisesAnnuaire.${index}.valeur`, null)
                        setUpdateChoice(updateChoice+1)
                    }else {
                        setValue(`tabChampsPersonnalisesAnnuaire.${index}.valeur`, getValues(`tabChampsPersonnalisesAnnuaire.${index}.champPersonnaliseAnnuaire.libelle`))
                        setUpdateChoice(updateChoice+1)
                    }
                }}
            />,
            <Select2
                allowClear={true}
                multiple="multiple"
                data={data}
                onChange={(data) => {
                    if (data?.at(0)){
                        let valeur = "";
                        data.forEach((item,indexFor) => {
                            if (indexFor == 0){
                                valeur = getValues(`tabChampsPersonnalisesAnnuaire.${index}.champPersonnaliseAnnuaire.parametre.tabSelect`).find(e => e.id == item).text
                            }else {
                                valeur = valeur + ";" + getValues(`tabChampsPersonnalisesAnnuaire.${index}.champPersonnaliseAnnuaire.parametre.tabSelect`).find(e => e.id == item).text
                            }
                        })
                        setValue(`tabChampsPersonnalisesAnnuaire.${index}.valeur`, valeur)
                    }else {
                        setValue(`tabChampsPersonnalisesAnnuaire.${index}.valeur`, null)
                    }
                }}
                selected={(getValues(`tabChampsPersonnalisesAnnuaire.${index}.valeur`) != null && getValues(`tabChampsPersonnalisesAnnuaire.${index}.champPersonnaliseAnnuaire.parametre.type`) == `selectMultiple`) ? () => {
                    let result = [];
                    getValues(`tabChampsPersonnalisesAnnuaire.${index}.champPersonnaliseAnnuaire.parametre.tabSelect`).forEach(item => {
                        if(getValues(`tabChampsPersonnalisesAnnuaire.${index}.valeur`).split(";").includes(item.text)){
                            result.push(item.id)
                        }
                    })
                    return result;
                } : null}
                minimumResultsForSearch={3}
                placeholder='Choisir'
            />,

        ]
    }

    function convertTypeOnNbType(type){
        switch (type) {
            case "text":
                return 0
            case "textarea" :
                return 1
            case "number":
                return 2
            case "date":
                return 3
            case "dateTime":
                return 4
            case "select":
                return 5
            case "selectMultiple":
                return 8
            case "choice":
                return 6
            case "checkbox":
                return 7
            default :
                return 0;
        }
    }

    return <>
        {isPartenaire && !isUtilisateur && <PartenaireSettings
            control={control}
            register={register}
            watch={watch}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            className='mb-10'
        />}
        <Stack direction='horizontal' gap={10}>
            <FormImagePicker type={"portrait"} setValue={setValue} getValues={getValues} control={control} name='upload' />
            <Stack>
                <Form.Group className={'mb-3'}>
                    <Button
                        title="Permet de vider le formulaire et de ne pas modifier la personne précédemment sélectionnée."
                        onClick={() => {
                            onAutocomplete(null);
                            $("#errorPersonne").addClass("d-none")
                        }}
                        className="d-none" id="errorPersonne">Erreur de personne ?</Button> <br/>
                    <Form.Label className='required'>Civilité</Form.Label>
                    <CiviliteSelection control={control} name='civilite' rules={{ required: true }} />
                    {errors.civilite && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label className='required'>Prénom</Form.Label>
                    <FormTypeahead solid
                        key={id}
                        maxLength={20}
                        id='autocompletePrenomPersonne'
                        placeholder='Prénom'
                        control={control}
                        name='prenom'
                        rules={{ required: true }}
                        options={autocompletion.data?.data}
                        autocompleteDisabled={autocompleteDisabled}
                        labelKey={option => option.prenom}
                        textKey={option => `${option.id} - ${option.prenom} ${option.nom}`}
                        filterBy={() => true}
                        onSelected={(selected) => {
                            if (onAutocomplete != null)
                                $("#errorPersonne").removeClass("d-none")
                                onAutocomplete(selected);
                        }}
                    />
                    {errors.prenom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Form.Group>
                <Form.Group>
                    <Form.Label className='required'>Nom</Form.Label>
                    <FormTypeahead solid
                        key={id}
                        maxLength={50}
                        id='autocompleteNomPersonne'
                        placeholder='Nom'
                        control={control}
                        name='nom'
                        rules={{ required: true }}
                        options={autocompletion.data?.data}
                        autocompleteDisabled={autocompleteDisabled}
                        labelKey={option => option.nom}
                        textKey={option => `${option.id} - ${option.prenom} ${option.nom}`}
                        filterBy={() => true}
                        onSelected={(selected) => {
                            if (onAutocomplete != null)
                                $("#errorPersonne").removeClass("d-none")
                                onAutocomplete(selected);
                        }}
                    />
                    {errors.nom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Form.Group>
            </Stack>
        </Stack>
        <Row className='mt-5'>
            <Col sm={6}>
                <Form.Group>
                    <Form.Label>Téléphone 1</Form.Label>
                    <InputGroup>
                        <PrefixeTelSelection control={control} name='indicatifTel1' rules={{ required: true }}/>
                        <Form.Control
                            maxLength={14}
                            onKeyUp={(e) => {
                                e.target.value = e.target.value.replace(/[\.,\-\+]/g, '');
                                let countNumber = 0;
                                for (let i=0; i<e.target.value.length; i++ ){
                                    if (!isNaN(parseInt(e.target.value[i]))){
                                        countNumber = countNumber +1;
                                    }
                                }
                                if (countNumber > 10){
                                    setError("tel1")
                                }else if (countNumber != 10 && countNumber != 0){
                                    setError("tel1")
                                }else {
                                    clearErrors("tel1")
                                }
                            }}
                            placeholder='Téléphone 1' className='form-control-solid' {...register('tel1', { pattern : telAnnuairePattern()})} />
                    </InputGroup>
                    {errors.tel1 && <Form.Text className='text-danger'>Merci d'indiquer un numéro valide</Form.Text>}
                    {errors.indicatifTel1 && <Form.Text className='text-danger'>Merci d'indiquer un indicatif</Form.Text>}

                </Form.Group>
            </Col>
            <Col sm={6} className='mt-5 mt-sm-0'>
                <Form.Group>
                    <Form.Label>Téléphone 2</Form.Label>
                    <InputGroup>
                        <PrefixeTelSelection control={control} name='indicatifTel2' rules={{ required: true }}/>
                        <Form.Control
                            maxLength={14}
                            onKeyUp={(e) => {
                                e.target.value = e.target.value.replace(/[\.,\-\+]/g, '');
                                let countNumber = 0;
                                for (let i=0; i<e.target.value.length; i++ ){
                                    if (!isNaN(parseInt(e.target.value[i]))){
                                        countNumber = countNumber +1;
                                    }
                                }
                                if (countNumber > 10){
                                    setError("tel2")
                                }else if (countNumber != 10 && countNumber != 0){
                                    setError("tel2")
                                }else {
                                    clearErrors("tel2")
                                }
                            }}
                            placeholder='Téléphone 2' className='form-control-solid' {...register('tel2', { pattern : telAnnuairePattern()})} />

                    </InputGroup>
                    {errors.tel2 && <Form.Text className='text-danger'>Merci d'indiquer un numéro valide</Form.Text>}
                    {errors.indicatifTel2 && <Form.Text className='text-danger'>Merci d'indiquer un indicatif</Form.Text>}
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col sm={6} className='mt-5 mt-sm-0'>
                <Form.Group>
                    <Form.Label className={`${isPartenaire ? 'required' : ''}`}>Email</Form.Label>
                    <Form.Control maxLength={50} className={`form-control-solid`} {...register('email', { required : isPartenaire, pattern: emailPattern()})} />
                    {errors.email && <Form.Text className='text-danger'>Ce champ est requis (merci d'indiquer un email valide)</Form.Text>}
                </Form.Group>
            </Col>
            <Col sm={6} className='mt-5 mt-sm-0'>
                <Form.Group>
                    <Form.Label>Fonction</Form.Label>
                    <Form.Control maxLength={110} className={`form-control-solid`} {...register('fonction')} />
                </Form.Group>
            </Col>
        </Row>
        <div className='separator separator-content border-dark mt-13 mb-5'>
            <Button
                variant='link'
                className='w-250px fw-bolder btn-color-dark btn-active-color-info'
                onClick={() => setShowMore(!showMore)}
            >
                Voir {showMore ? 'moins' : 'plus'} de champs
            </Button>
        </div>
        <Collapse in={showMore}>
            <div>
                <div className='separator separator-content border-dark mt-13 mb-5'>
                    <Button
                        variant='link'
                        className='w-250px fw-bolder btn-color-dark btn-active-color-info'
                        onClick={() => setShowMoreChampsPerso(!showMoreChampsPerso)}
                    >
                        {!showMoreChampsPerso ? 'Voir les champs personnalisés' : 'Masquer les champs personnalisés'}
                    </Button>
                </div>
                <Collapse in={showMoreChampsPerso}>
                    <Row style={{marginTop:25}} className="bg-gray-300 rounded pb-5">
                            {champsPerso.data?.data?.map(exp => {
                                if (!getValues("tabChampsPersonnalisesAnnuaire."+exp.id)){
                                    setValue("tabChampsPersonnalisesAnnuaire."+exp.id+".champPersonnaliseAnnuaire", exp)
                                }
                                return <Col className='mt-5' md={6}>
                                    <Form.Group className="color-select2-black">
                                        <Form.Label>{exp?.libelle}</Form.Label>
                                        {arrayTypesFields(exp?.id, (exp?.parametre?.type == "select" || exp?.parametre?.type == "selectMultiple") ? exp?.parametre?.tabSelect : [], exp?.parametre?.type == "choice" ?  exp?.parametre?.tabChoice : [])[convertTypeOnNbType(exp?.parametre?.type)]}
                                    </Form.Group>
                                </Col>
                            })}
                    </Row>
                </Collapse>
                <Row>
                    <Col className='mt-5'>
                        <Form.Group>
                            <Form.Label>Adresse</Form.Label>
                            <Form.Control
                                maxLength={200}
                                as='textarea'
                                className='form-control-solid'
                                rows={5}
                                {...register('adresse')}
                            />
                        </Form.Group>
                    </Col>
                    <Col className='mt-5' md={4}>
                        <Stack gap={3}>
                            <Form.Group>
                                <Form.Label>Code postal</Form.Label>
                                <FormTypeahead solid
                                               key={id}
                                               maxLength={5}
                                               id='codePostal'
                                               placeholder='Code postal'
                                               control={control}
                                               name='codePostal'
                                               rules={{ required: false, pattern: codePostalPattern() }}
                                               options={autocompletionCodePostal.data?.postalCodes}
                                               autocompleteDisabled={false}
                                               labelKey={option => option.postalCode}
                                               textKey={option => `${option.postalCode} - ${option.placeName}`}
                                               filterBy={() => true}
                                               onSelected={(selected) => {
                                                   if (selected && selected.postalCode && selected.placeName){
                                                       setValue("codePostal", selected.postalCode.slice(0,5))
                                                       setValue("ville", selected.placeName)
                                                   }

                                               }}
                                />
                                {errors.codePostal && <Form.Text className='text-danger'>Code postal invalide</Form.Text>}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Ville</Form.Label>
                                <Form.Control maxLength={50} placeholder='Ville' className={`form-control-solid`} {...register('ville')} />
                            {/*    <FormTypeahead solid*/}
                            {/*                   key={id}*/}
                            {/*                   id='ville'*/}
                            {/*                   placeholder='Ville'*/}
                            {/*                   control={control}*/}
                            {/*                   name='ville'*/}
                            {/*                   rules={{ required: false }}*/}
                            {/*                   options={autocompletionCodePostal.data?.postalCodes}*/}
                            {/*                   autocompleteDisabled={false}*/}
                            {/*                   labelKey={option => option.placeName}*/}
                            {/*                   textKey={option => `${option.postalCode} - ${option.placeName}`}*/}
                            {/*                   filterBy={() => true}*/}
                            {/*                   onSelected={(selected) => {*/}
                            {/*                       if (selected && selected.postalCode && selected.placeName) {*/}
                            {/*                           setValue("codePostal", selected.postalCode.slice(0,5))*/}
                            {/*                           setValue("ville", selected.placeName)*/}
                            {/*                       }*/}
                            {/*                   }}*/}
                            {/*    />*/}
                            </Form.Group>
                        </Stack>
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Date d'anniversaire</Form.Label>
                            <FormDatePickerBis maxDate={18} solid control={control} name='dateAnniversaire' />
                        </Form.Group>
                    </Col>
                    <Col sm={6} className='mt-5 mt-sm-0'>
                        <Form.Group>
                            <Form.Label>Date de première licence</Form.Label>
                            <FormDatePickerBis solid control={control} name='datePremiereLicence' />
                        </Form.Group>
                    </Col>
                </Row>
                <Card className='card-flush mt-10'>
                    <Card.Body>
                        <h3>Réseaux sociaux</h3>
                        <Row className='mt-5'>
                            <Col sm={6}>
                                <Form.Group>
                                    <i className="fab fa-linkedin me-2"></i>
                                    <Form.Label>Linkedin</Form.Label>
                                    <Form.Control maxLength={300} className='form-control-solid' {...register('urlLinkedin', {pattern: urlPattern()})} />
                                    {errors.urlLinkedin && <Form.Text className='text-danger'>L'url doit commencer par http:// ou https://</Form.Text>}
                                </Form.Group>
                                <Form.Group className='mt-3'>
                                    <i className="fab fa-instagram me-2"></i>
                                    <Form.Label>Instagram</Form.Label>
                                    <Form.Control maxLength={300} className='form-control-solid' type={"url"} {...register('urlInstagram', {pattern: urlPattern()})} />
                                    {errors.urlInstagram && <Form.Text className='text-danger'>L'url doit commencer par http:// ou https://</Form.Text>}
                                </Form.Group>
                                <Form.Group className='mt-3'>
                                    <i className="fab fa-youtube me-2"></i>
                                    <Form.Label>Youtube</Form.Label>
                                    <Form.Control maxLength={300} className='form-control-solid' {...register('urlYoutube', {pattern: urlPattern()})} />
                                    {errors.urlYoutube && <Form.Text className='text-danger'>L'url doit commencer par http:// ou https://</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col sm={6} className='mt-3 mt-sm-0'>
                                <Form.Group>
                                    <i className="fab fa-twitter me-2"></i>
                                    <Form.Label>Twitter</Form.Label>
                                    <Form.Control maxLength={300} className='form-control-solid' {...register('urlTwitter', {pattern: urlPattern()})} />
                                    {errors.urlTwitter && <Form.Text className='text-danger'>L'url doit commencer par http:// ou https://</Form.Text>}
                                </Form.Group>
                                <Form.Group className='mt-3'>
                                    <i className="fab fa-tiktok me-2"></i>
                                    <Form.Label>TikTok</Form.Label>
                                    <Form.Control maxLength={300} className='form-control-solid' {...register('urlTiktok', {pattern: urlPattern()})} />
                                    {errors.urlTiktok && <Form.Text className='text-danger'>L'url doit commencer par http:// ou https://</Form.Text>}
                                </Form.Group>
                                <Form.Group className='mt-3'>
                                    <i className="fab fa-facebook me-2"></i>
                                    <Form.Label>Facebook</Form.Label>
                                    <Form.Control maxLength={300} className='form-control-solid' {...register('urlFacebook', {pattern: urlPattern()})} />
                                    {errors.urlFacebook && <Form.Text className='text-danger'>L'url doit commencer par http:// ou https://</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </Collapse>
    </>;
}

export default PersonneForm;