import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";

export default function useInscrireDestinataireMetaTable(props) {
    return useMutation(inscrireDestinataire, props);
}

export async function inscrireDestinataire(data) {
    api.post(
        `${data.baseRoute}/${data.id}/inscrireDestinataires`,
        {
            json: {
                tabIdsPersonne: data.tabIds,
                isInclusif:true,
                idProfil:1
            },
        },
    ).json();
}
