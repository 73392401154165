import {useMutation, useQueryClient} from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useDeleteTva() {

    const queryClient = useQueryClient();

    return useMutation(deleteTVA, {
        onSuccess: () => {
            queryClient.invalidateQueries('tvaFacturation');
            toast.success('Le taux de TVA a été supprimé', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function deleteTVA(id) {

    if (id != null)
        return api.delete(`tauxTva/${id}`).json();

}