import {Button, Spinner} from "react-bootstrap";
import useGetUrlConnexion from "../../../requests/communication/reseauxSociaux/facebook/useGetUrlConnexion";
import React from "react";
import useGetComptes from "../../../requests/communication/reseauxSociaux/facebook/useGetComptes";
import GestionPagesModal from "./GestionPagesModal";
import {useSnapshot} from "valtio";
import facebookProxy from "../../../proxies/facebook";
import EditPageFacebookModal from "./EditPageFacebookModal";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import {useLocation} from "react-router";

function FacebookSettings() {

    const comptes = useGetComptes();
    const {state} = useLocation();
    const { isError } = state ? state : false;
    const urlConnexion = useGetUrlConnexion({
        refetchOnWindowFocus: false,
        enabled: false, // disable this query from automatically running
        onSuccess: (data) => {
            window.open(data?.url, "_blank")
        }
    });
    const facebookSnap = useSnapshot(facebookProxy);



    if (urlConnexion.isLoading && comptes.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    // if (mailAccesPartenaire.isError)
    //     return <Alert variant='danger'>{mailAccesPartenaire.error.message}</Alert>

    return <>
        <h2>Comptes facebook</h2>
        {isError && <div className="alert alert-danger">Erreur lors de l'association du compte</div>}
        <div className="d-flex flex-wrap align-items-center">
            {comptes?.data?.data.filter(compte => compte.refUtilisateur).map(compte => {
                return <div className="card w-300px mb-5 me-5">
                    <div className="card-body bg-light pt-15 px-0">
                        <div className="d-flex flex-column text-center mb-9 px-9">
                            <div className="symbol symbol-80px symbol-lg-150px mb-4">
                                <i className="fab fa-facebook fa-3x"/>
                            </div>
                            <div className="text-center">
                                <p className="text-gray-800 fw-bolder fs-4">{compte.nomUtilisateur}</p>
                            </div>
                            <div>
                                <Button
                                    className=""
                                    onClick={() => {
                                        facebookSnap.showGestionPages(compte)
                                    }}
                                >
                                    Gestion des pages
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            })  }
            <Button
                className="rounded-circle m-0 p-5 fa fa-plus-circle"
                title="Ajouter un compte facebook"
                variant="primary" style={{height : "fit-content"}}
                onClick={() => {
                    BtSwal.fire({
                        title: 'Êtes vous sur de vouloir ajouter un compte facebook ?',
                        showDenyButton: true,
                        confirmButtonText: `Oui`,
                        denyButtonText: `Annuler`,
                    }).then((result) => {
                        if (result.isConfirmed){
                            let compteVide = comptes?.data?.data.find(e => e.refUtilisateur == null)
                            if (compteVide){
                                window.open(compteVide?.urlOAuth, "_blank")
                            }else {
                                urlConnexion.refetch();
                            }
                        }
                    })
                }}>
            </Button>
            <GestionPagesModal/>
            <EditPageFacebookModal/>
        </div>
    </>;


}

export default FacebookSettings;