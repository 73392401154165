import { Navigate, useLocation } from "react-router";
import auth from "@services/auth";
import { useSnapshot } from "valtio";
import {useParams} from "react-router-dom";

function Guarded({ children }) {
    const snap = useSnapshot(auth);
    let location = useLocation();
    const {agence} = useParams();

    if (snap.utilisateur == null){
        return <Navigate to={'/' + agence + '/login'} replace={true} state={{ from: location }} />
    }
    // inverser la condition snap.utilisateur?.accepteDeclarationConfidentialite en --> !snap.utilisateur?.accepteDeclarationConfidentialite une fois que la route est faite
    else if (snap.utilisateur && !snap.utilisateur?.accepteDeclarationConfidentialite){
        return <Navigate to={'/'+agence+'/cgu'} replace={true} state={{ from: location }} />
    }else {
        return children
    }
    
}

export default Guarded;