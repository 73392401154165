import { useQuery } from "react-query";
import baseApi from "../../../services/apiPublic";


export default function useRepondreInvitationByMail(ref) {
    return useQuery(
        ['invitation'],
        () => baseApi.post('invitation/repondre', {
            json: {
                ref: ref
            }
        }).json(),
    );
}