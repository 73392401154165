import { imageAndText } from "./modules/imageAndText";
import { singleImageModule } from "./modules/singleImage";
import { singleTextModule } from "./modules/singleText";
import { textAndImage } from "./modules/textAndImage";
import { twoImagesModule } from "./modules/twoImages";
import { twoTextsModule } from "./modules/twoTexts";
import { blocButtonTransfere } from "./modules/blocButtonTransfere";

export const mailTemplate = {
    'blocButtonTransfere' : blocButtonTransfere,
    'singleImage': singleImageModule,
    'singleText': singleTextModule,
    'twoImages': twoImagesModule,
    'twoTexts': twoTextsModule,
    'imageAndText': imageAndText,
    'textAndImage': textAndImage
}