import ky from "ky";
import { baseURL } from '@/config'
import {getAccessToken} from "./token";

const currentUrl = window.location.href;
const parts = currentUrl.split('/');
const agence = parts[3];

const baseApi = ky.create({
    prefixUrl: baseURL,
    timeout : 50000,
    hooks: {
        // TODO: fix error message
        beforeRequest: [
            // Send access token in header
            request => {
                request.headers.set('Agence', agence);
                request.headers.set('Version', "1.101");
            },
        ],
        afterResponse: [
            async (request, options, response) => {
                const body = await response.json();
                if (body?.success == false && body.message != null) {
                    console.error(body.message);
                    // throw new Error(body.message);
                }
            },
        ],
        beforeError: [
            async error => {
                const response = await error.response.json();
                if (response && response.message && error.response.status != 410) {
                    error.name = 'Erreur serveur';
                    error.message = `${response.message}`;
                    error.code = error?.response?.status;
                }else if (error.response.status == 402){
                    error.code = error?.response?.status;
                    error.data = response;
                }else if (error.response.status == 410){
                    error.code = error?.response?.status;
                    error.data = response;
                    error.message = `${response.message}`;
                }
                return error;
            }
        ],
    }
});


export default baseApi;