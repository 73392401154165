import useGetUtilisateur from "@requests/utilisateur/useGetUtilisateur";
import LoadingView from "@views/LoadingView/LoadingView";
import {useState} from "react";
import {useParams} from "react-router-dom";

function UserFetcher({ children }) {
    const [fetchUser, setFetchUser] = useState(false);
    const utilisateur = useGetUtilisateur(fetchUser);
    const {agence} = useParams();
    if (window.location.pathname != "/"+agence+"" && window.location.pathname != "/"+agence+"/" && !fetchUser){
        setFetchUser(true)
    }
    if (utilisateur.isLoading)
        return <LoadingView />
    return children;
}

export default UserFetcher;