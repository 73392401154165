import React, {useEffect, useRef} from "react";
import { useSnapshot } from "valtio";
import invitationsProxy from "@proxies/invitations";
import { Button, Modal } from "react-bootstrap";
import ReglagesStep from "./ReglagesStep/ReglagesStep";
import BlockUI from '@components/BlockUI/BlockUI';
import RedactionStep from "./RedactionStep/RedactionStep";
import FinalisationStep from "./FinalisationStep/FinalisationStep";
import DestinatairesStep from "../../DestinatairesStep/DestinatairesStep";
import useInscrireDestinataireMetaTable from "../../../../requests/communication/useInscrireDestinataireMetaTable";
import {BtSwal} from "../../../../utils/alerts/sweetAlert";
import {useQueryClient} from "react-query";
import motsClesProxy from "../../../../proxies/motsClesProxy";
import {showErrorAlert} from "../../../../utils/alerts/showErrorAlert";
import auth from "../../../../services/auth";
import PersonnaliserPageReponseDestinataireStep
    from "../../../../views/communication/InvitationsView/EditModal/PersonnaliserPageReponseDestinataireStep/PersonnaliserPageReponseDestinataireStep";
import toast from "react-hot-toast";

function EditModal() {
    const stepRef = useRef();
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    let queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const inscrireDestinataireMetaTable = useInscrireDestinataireMetaTable({
        onSuccess: () => {
            queryClient.invalidateQueries(["invitation", invitationsSnapshot.invitation.id, 'correspondants']);
            snapAuth.websocket.send("invitation")
            snapAuth.websocket.send(invitationsSnapshot.invitation.id)
            snapAuth.websocket.send("correspondants")

        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    const steps = [
        {
            name: 'Réglages',
            renderComponent: (ref) => <ReglagesStep ref={ref} />,
        },
        {
            name: 'Page de réponse',
            renderComponent: (ref) => <PersonnaliserPageReponseDestinataireStep ref={ref} />,
        },
        {
            name: 'Rédaction',
            renderComponent: (ref) => <RedactionStep ref={ref} />
        },
        {
            name: 'Destinataires',
            renderComponent: (ref) => <DestinatairesStep baseRoute='invitation' id={invitationsSnapshot.invitation?.id} />
        },
        {
            name: 'Finalisation',
            renderComponent: (ref) => <FinalisationStep />,
        },
    ];

    useEffect(() => {
        if (invitationsSnapshot.destinataireIsChecked){
            console.log(invitationsSnapshot.destinataireIsChecked)
        }
    }, [invitationsSnapshot])

    async function goToStep(step) {
        let checkDestinataire = false;
        if (step === 4 && invitationsSnapshot.destinataireIsChecked){
            let message = "Attention des destinataires sélectionnés n'ont pas été ajoutés. Souhaitez-vous tout de même continuer ?"
            if (!invitationsSnapshot.destinataireAlertIsAdd){
                message = "Attention des destinataires sélectionnés n'ont pas été supprimés. Souhaitez-vous tout de même continuer ?"
            }
            await BtSwal.fire({
                title: message,
                showDenyButton: true,
                confirmButtonText: `Oui`,
                denyButtonText: `Non`,
                icon : 'info'
            }).then((result) => {
                if (!result.isConfirmed){
                    checkDestinataire = true;
                }

            })
        }
        if (checkDestinataire){
            return false;
        }
        if (step === 3 && invitationsSnapshot.tabIdsDestinataires !== null){
            BtSwal.fire({
                title: 'Souhaitez-vous reprendre les destinataires de l\'invitation ?',
                showDenyButton: true,
                confirmButtonText: `Oui`,
                denyButtonText: `Non`,
            }).then((result) => {
                if (result.isConfirmed){
                    let data = {
                        baseRoute : "invitation",
                        id : invitationsSnapshot.invitation.id,
                        tabIds : invitationsSnapshot.tabIdsDestinataires
                    }
                    inscrireDestinataireMetaTable.mutate(data);
                }

            })
        }
        // Pour reset les mots clés
        if (step === 2){
            motsClesProxy.motsCles = [
                {
                    type: 'menuitem',
                    text: 'prenom',
                    insert : '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                },
                {
                    type: 'menuitem',
                    text: 'nom',
                    insert : '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                },
                {
                    type: 'menuitem',
                    text: 'email',
                    insert : '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                },
                {
                    type: 'menuitem',
                    text: 'civilite',
                    insert : '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                },
                {
                    type: 'menuitem',
                    text: 'te/vous',
                    insert : '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                },
                {
                    type: 'menuitem',
                    text: 'toi/vous',
                    insert : '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                },
                {
                    type: 'menuitem',
                    text: 'ta/votre',
                    insert : '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                },
                {
                    type: 'menuitem',
                    text: 'ton/votre',
                    insert : '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                },
                {
                    type: 'menuitem',
                    text: 'tes/vos',
                    insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                },
                {
                    type: 'menuitem',
                    text: 't\'/vous',
                    insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                },
                {
                    type: 'menuitem',
                    text: 'Nom agence',
                    insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                },

            ]
        }
        if (stepRef.current?.save != null) {
            invitationsProxy.editModal.loading = true;
            if (await stepRef.current.save()){
                if (step === 1){
                    toast((t) => (
                            <div style={{
                                color: "var(--primary-color)"
                            }} className="text-center">
                                <i style={{
                                    color: "var(--primary-color)"
                                }} className="fa m-auto fa-info-circle fa-3x"></i>
                                <p>Dans cette partie vous pouvez personnaliser la page de réponse en ajoutant des textes qui seront visible par le destinataire qui accepte l'invitation.</p>
                                <p>Cette partie est facultative vous pouvez la passer en cliquant sur suivant.</p>
                                <Button
                                    onClick={() =>toast.dismiss(t.id) }
                                >
                                    ok
                                </Button>
                            </div>
                        ), {
                            id: 'clipboard',
                            style: {
                                position: "relative",
                                top: 100,
                                boxShadow: "0 0 15px #504f4c",
                                fontWeight: "bold"
                            },
                            duration: 15000
                        }
                    );
                }
                invitationsProxy.editModal.step = step;
            }
            invitationsProxy.editModal.loading = false;
        }
        else invitationsProxy.editModal.step = step;
    }

    return <Modal
        fullscreen
        show={invitationsSnapshot.editModal.show}
        onHide={() => {
            queryClient.invalidateQueries('invitations');
            snapAuth.websocket.send("invitations")
            if (invitationsSnapshot?.invitation?.id && snapAuth.tabIdInvitationVerrou.indexOf(invitationsSnapshot?.invitation?.id) !== -1){
                auth.tabIdInvitationVerrou = snapAuth.tabIdInvitationVerrou.filter(i => i !== invitationsSnapshot?.invitation?.id)
            }
            invitationsProxy.editModal.show = false
        }}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <div className='w-100 stepper stepper-links d-flex flex-column between'>
                <div className='stepper-nav'>
                    {steps.map((step, index) => <div
                        className={`stepper-item ${index === invitationsSnapshot.editModal.step ? 'current' : ''} ${index < invitationsSnapshot.editModal.step ? 'completed' : ''} hoverable`}
                        key={step.name}
                        onClick={() => {
                            goToStep(index);
                        }}
                    >
                        <h3 className='stepper-title'>{step.name}</h3>
                    </div>)}
                </div>
            </div>
        </Modal.Header>
        <BlockUI loading={invitationsSnapshot.editModal.loading} className='overflow-scroll modal-body'>
            {steps[invitationsSnapshot.editModal.step]?.renderComponent(stepRef)}
        </BlockUI>
        <Modal.Footer>
            {/* TODO: Make loading indicator buttons */}
            {invitationsSnapshot.editModal.step > 0 && <Button
                variant='danger'
                className='me-auto'
                onClick={() => invitationsProxy.editModal.step--}
            >Précédent</Button>}
            {invitationsSnapshot.editModal.step < steps.length - 1 && <Button
                text='Suivant'
                variant='secondary'
                className='ms-auto'
                onClick={async () => {
                    goToStep(invitationsSnapshot.editModal.step + 1);
                }}
            >Suivant</Button>}
        </Modal.Footer>
    </Modal>;
}

export default EditModal;