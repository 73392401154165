import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import HeaderSelectionDropDown from "./HeaderSelection/HeaderSelectionDropdown";

function HeaderEditor({ header, onChange }) {
    return <tr>
        <td>
            <div className='overlay'>
                <div className='overlay-wrapper text-center'>
                    <img style={{ width: 600 }} src={header} />
                </div>
                <div className='overlay-layer bg-dark bg-opacity-25 align-items-start justify-content-end'>
                    <div className='m-5 w-100 d-flex justify-content-center'>
                        <Button variant='danger' onClick={() => onChange(null)}>
                            <FontAwesomeIcon icon={faTrash} className='me-3' />
                            Enlever
                        </Button>
                        <HeaderSelectionDropDown
                            className='btn-secondary ms-2'
                            onChange={onChange}
                        >
                            <FontAwesomeIcon icon={faPen} className='me-3' />
                            Changer
                        </HeaderSelectionDropDown>
                    </div>
                </div>
            </div>
        </td>
    </tr>;
}

export default HeaderEditor;