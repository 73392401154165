import React, {useMemo, useRef} from "react";
import {Button, Card, Dropdown, Stack} from "react-bootstrap";
import 'react-medium-image-zoom/dist/styles.css'
import useGetPagesEnregistrer from "../../../requests/communication/reseauxSociaux/facebook/useGetPagesEnregistrer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faWrench} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Art005 } from "@assets/icons/art005.svg";
import NotificationModal from "../../AnnuaireView/NotificationModal/NotificationModal";
import ExportAnnuaireModal from "../../AnnuaireView/ExportAnnuaireModal/ExportAnnuaireModal";
import {useForm} from "react-hook-form";
import SearchBar from "../../../components/SearchBar/SearchBar";
import MetaTable from "../../../components/MetaTable/MetaTable";
import {useDebounce} from "use-debounce";
import FacebookPostPreview from "../../../components/ReseauxSociaux/FacebookPostPreview";
import facebookProxy from "../../../proxies/facebook";
import publicationReseauxSociaux from "../../../proxies/publicationReseauxSociaux";
import EditPublicationReseauSocialModal from "./EditPublicationReseauSocialModal";
import CardPostPublication from "../../../components/ReseauxSociaux/CardPostPublication";
import publicationReseauxSociauxProxy from "../../../proxies/publicationReseauxSociaux";
import moment from "moment";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";
import { ReactComponent as Gra012 } from "@assets/icons/gra012.svg";
import useDeletePublicationReseauSocial
    from "../../../requests/communication/reseauxSociaux/useDeletePublicationReseauSocial";
import { ReactComponent as Gen027 } from "@assets/icons/gen027.svg";
import {BtSwal} from "../../../utils/alerts/sweetAlert";


function PublicationReseauSocialView() {
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const [searchFilter] = useDebounce(search, 300);
    const pagesEnregistrer = useGetPagesEnregistrer("");
    const snapAuth = useSnapshot(auth);
    const deletePublication = useDeletePublicationReseauSocial();

    // const pagesEnregistrer = useGetPagesEnregistrer("");
    // const [isPublie, setisPublie] = useState(null);
    // const [selectedMois, setSelectedMois] = useState(null);
    // const publicationRSSnap = useSnapshot(publicationReseauxSociauxProxy);
    // const publications = useListePublicationReseauSocial(isPublie, selectedMois);
    //
    // if (publications.isLoading || pagesEnregistrer.isLoading)
    //     return <div className='text-center'><Spinner animation='border' /></div>;
    //
    // return <div className="flex-lg-row-fluid m-lg-13">
    //     <Button
    //         variant="secondary"
    //         className="fa fa-calendar mb-2"
    //         onClick={() => {
    //             publicationRSSnap.showCalendarReseauSocialModal(publications?.data?.data, pagesEnregistrer?.data?.data);
    //         }}
    //     >
    //     </Button>
    //     <SelectPublicationPublieOrNot className='mb-8 text-center' selected={isPublie} setSelectedMois={(value) => setSelectedMois(value)} selectedMois={selectedMois} onChange={(value) => setisPublie(value)} />
    //     {(publications.data.data).map((value, index) =>
    //         <CardPostPublication value={value}/>
    //     )}
    //     <AddFacebookPagesModal/>
    //     <PreviewFacebookModal/>
    //     <CalendarReseauSocialModal/>
    //
    // </div>

    const colSearch = useMemo(() => {
        return {

        };
    }, []);
    const tableRef = useRef();

    const columns = useMemo(() => [
        {
            Header: () => null,
            id: 'wrench',
            Cell: ({ row }) =>
                moment().format("YYYY-MM-DD HH:mm") >= row.original.dateMiseEnLigne.split(" ")[0].split("/")[2]+"-"+row.original.dateMiseEnLigne.split(" ")[0].split("/")[1]+"-"+row.original.dateMiseEnLigne.split(" ")[0].split("/")[0] + " " + row.original.dateMiseEnLigne.split(" ")[2] ?
                    <span title="Satistiques de la publication"
                          onClick={() => {
                            //todo afficher les stats de la publication
                          }}
                          className="btn btn-quaternaire p-2 pe-0"><span className='svg-icon svg-icon-1 me-2'><Gra012 /></span></span>
                    :

                    <Dropdown onClick={(e)=> {
                    }
                    }>
                    <Dropdown.Toggle className='hide-after btn-icon btn-quaternaire w-30px h-30px'>
                    <FontAwesomeIcon color={'black'} icon={faWrench} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='fs-6 w-350px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            hidden={moment().format("YYYY-MM-DD HH:mm") >= row.original.dateMiseEnLigne.split(" ")[0].split("/")[2]+"-"+row.original.dateMiseEnLigne.split(" ")[0].split("/")[1]+"-"+row.original.dateMiseEnLigne.split(" ")[0].split("/")[0] + " " + row.original.dateMiseEnLigne.split(" ")[2]}
                            className='menu-link px-3'
                            onClick={async () => {
                                publicationReseauxSociauxProxy.currentPostCardPostPublicationModal = row.original
                                let dataForFacebookPreview = row.original;
                                if (row.original?.tabUploads) {
                                    let fetchAndFormatImages = async () => {
                                        let arrayImages = await Promise.all(Object.values(row.original?.tabUploads).map(async (value) => {
                                            return await (async function () {
                                                let blob = await fetch(value?.urlMiniature).then(async r => r.blob());
                                                return (await new Promise(resolve => {
                                                    let reader = new FileReader();
                                                    reader.onload = () => resolve({
                                                        data_url: reader.result,
                                                        id: value.id
                                                    });
                                                    reader.readAsDataURL(blob);
                                                }))
                                            })();
                                        }))
                                        let objectImages = [];
                                        arrayImages.forEach((value) => {
                                            objectImages.push({
                                                data_url: value.data_url,
                                                id: value.id
                                            });
                                        })
                                        dataForFacebookPreview = {
                                            ...dataForFacebookPreview,
                                            images: objectImages
                                        }
                                    }
                                    await fetchAndFormatImages();
                                }
                                facebookProxy.postFacebookInCreaction = dataForFacebookPreview
                                publicationReseauxSociaux.showEditPublicationReseauSocial()
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Art005 /></span>
                            Modifier
                        </Dropdown.Item>
                    </div>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => BtSwal.fire({
                                title: `<b>Êtes-vous sûr de vouloir supprimer la publication ?</b>`,
                                showDenyButton: true,
                                confirmButtonText: `Oui`,
                                denyButtonText: `Annuler`,
                            }).then((result) => {
                                if (result.isConfirmed){
                                    deletePublication.mutate(row.original.id);
                                    //todo a faire pour tout les réseaux sociaux
                                    facebookProxy.postFacebookInCreaction = null;
                                    BtSwal.fire('La publication a été supprimé!', '', 'success')
                                } else if (result.isDenied) {
                                    BtSwal.fire('La publication n\'a pas été supprimé', '', 'info')
                                }
                            })}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Gen027 /></span>
                            Supprimer
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>,
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            minWidth: 70,
            maxWidth: 70,
        },
        {
            Header: 'Titre',
            accessor: 'contenu',
            Cell: ({row}) => {
                if (row.original.tabUploads){
                    return <span>
                    <img className="me-3 rounded img-fluid" src={Object.values(row.original.tabUploads)[0].urlMiniature} height={48} width={48} alt=""/>
                        {(row.original?.contenu && (row.original?.contenu)?.length > 420) ? (row.original?.contenu).substring(0, 419) + "... <b>En voir plus</b>" : (row.original?.contenu)}
                </span>
                }
                return <span>
                    <img className="me-3 rounded img-fluid" src={snapAuth.srcLogo} height={48} width={48} alt=""/>
                   {(row.original?.contenu && (row.original?.contenu)?.length > 420) ? (row.original?.contenu).substring(0, 419) + "... <b>En voir plus</b>" : (row.original?.contenu)}
                </span>
            },
            minWidth: 100,
        },
        {
            Header: "Réseau de diffusion",
            accessor: "tabReseauxDiffusion",
            Cell: ({row}) => {
                return  row.original?.tabReseauxDiffusion && (Object.values(row.original?.tabReseauxDiffusion)).map(reseauDiff => {
                    switch (reseauDiff.libelle) {
                        case 'page_facebook':
                            return <span onClick={() => {
                                if (reseauDiff.url){
                                    window.open(reseauDiff.url, '_blank');
                                }
                            }} className="ms-2 cursor-pointer text-hover-primary fab fa-2x fa-facebook" title={pagesEnregistrer?.data?.data.find(e => e.id == reseauDiff.idSupport).nom}></span>
                        //todo continuer pour les autres réseaux
                        default:
                            return "";
                    }
                })
            }
        },
        {
            Header: 'Date de publication',
            accessor: 'dateMiseEnLigne',
        }
    ], []);

    return <>
        <Card>
            <Card.Body className="d-flex">
                <div style={{width: 700}}>
                {/*<ProfilFilter className='mb-8 text-center' selected={selectedProfil} onChange={(value) => setSelectedProfil(value)} />*/}
                    <div className='d-flex flex-stack flex-wrap'>
                        <SearchBar
                            solid
                            {...register('search')}
                            onClear={search?.length > 0 ? () => {
                                setValue('search', '');
                            } : null}
                        />
                        <Stack className='ms-auto' direction='horizontal' gap={2}>
                            <Button variant='secondary' onClick={() => {
                                publicationReseauxSociaux.showEditPublicationReseauSocial()
                            }}>
                                <FontAwesomeIcon icon={faPlus} className='me-2' />
                                Créer un nouveau post
                            </Button>
                        </Stack>
                    </div>
                    <MetaTable
                        ref={tableRef}
                        className='mt-5'
                        height={500}
                        url='publicationReseauSocial'
                        keys={['publicationReseauSocial']}
                        columns={columns}
                        search={searchFilter}
                        colSearch={colSearch}
                        getTrProps={(row) => {
                            return {
                                onClick: async e => {
                                    // if (facebook)....
                                    //todo penser a faire tout ça pour les autres réseau ou alors refactor du code....
                                    let dataForFacebookPreview = row.original;
                                    if (row.original?.tabUploads) {
                                        let fetchAndFormatImages = async () => {
                                            let arrayImages = await Promise.all(Object.values(row.original?.tabUploads).map(async (value) => {
                                                return await (async function () {
                                                    let blob = await fetch(value?.urlMiniature).then(async r => r.blob());
                                                    return (await new Promise(resolve => {
                                                        let reader = new FileReader();
                                                        reader.onload = () => resolve({
                                                            data_url: reader.result,
                                                            id: value.id
                                                        });
                                                        reader.readAsDataURL(blob);
                                                    }))
                                                })();
                                            }))
                                            let objectImages = [];
                                            arrayImages.forEach((value) => {
                                                objectImages.push({
                                                    data_url: value.data_url,
                                                    id: value.id
                                                });
                                            })
                                            dataForFacebookPreview = {
                                                ...dataForFacebookPreview,
                                                images: objectImages
                                            }
                                        }
                                        await fetchAndFormatImages();
                                    }
                                    facebookProxy.postFacebookInCreaction = dataForFacebookPreview
                                }
                            }
                        }}
                    />
                    {/*<div className='d-flex mt-3'>*/}
                    {/*    <Button*/}
                    {/*        variant='secondary'*/}
                    {/*        className='ms-auto'*/}
                    {/*        onClick={() => {*/}
                    {/*            // tableRef.current?.export();*/}
                    {/*            // annuaireProxy.showExportAnnuaire(urlExportAnnuaire?.data);*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <FontAwesomeIcon icon={faFileDownload} className='me-2' />*/}
                    {/*        Exporter*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </div>
                <div className="d-flex flex-column align-items-center w-700px" style={{marginTop: 5}}>


                    <ul class="nav nav-tabs nav-line-tabs mb-5 fs-6">
                        <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_1">Facebook</a>
                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_2">Link 2</a>*/}
                        {/*</li>*/}
                        {/*<li className="nav-item">*/}
                        {/*    <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_3">Link 3</a>*/}
                        {/*</li>*/}
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
                            <FacebookPostPreview/>
                        </div>
                        <div class="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                            ...
                        </div>
                        <div class="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
                            ...
                        </div>
                    </div>


                </div>
            </Card.Body>
        </Card>
        <NotificationModal/>
        <ExportAnnuaireModal/>
        <EditPublicationReseauSocialModal/>
    </>;




}

export default PublicationReseauSocialView;