import { Modal } from "react-bootstrap";
import { useSnapshot } from "valtio";
import mailsProxy from "@proxies/mails";
import MetaTable from "@components/MetaTable/MetaTable";
import React, { useMemo } from "react";
import SearchBar from "@components/SearchBar/SearchBar";
import { useForm } from "react-hook-form";
import { useDebounce } from "use-debounce";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

function ModalStatistiqueMail() {
    const mailsSnapshot = useSnapshot(mailsProxy);

    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const [debouncedSearch] = useDebounce(search, 300);


    const columns = useMemo(() => [
        {
            Header: 'Id',
            accessor: 'destinataire.personne.id',
            minWidth: 70,
            maxWidth: 70,
        },
        {
            Header: 'Prénom',
            accessor: 'destinataire.personne.prenom',
            minWidth: 100,
        },
        {
            Header: 'Nom',
            accessor: 'destinataire.personne.nom',
            minWidth: 100,
        },
        {
            Header: 'prenomNom',
            accessor: 'destinataire.personne.prenomNom',
            hidden: true,
        },
        {
            Header: 'nomPrenom',
            accessor: 'destinataire.personne.nomPrenom',
            hidden: true
        },
        {
            Header: 'État',
            id: 'etat',
            minWidth: 300,
            Cell: ({ row }) => {
                if (row.original.destinataire?.personne?.email == null)
                    return <span className='text-warning'>Adresse mail manquante</span>;
                if (row.original.destinataire?.personne?.mailIsDesinscrit)
                    return <span className='text-danger'>Ne souhaite plus recevoir de mail</span>;
                if (row.original.destinataire?.personne?.mailIsErrone)
                    return <span className='text-danger'>Adresse mail erronée</span>;
                if (row.original.dateEnvoi !== null ){
                    return [<div onClick={() => {
                        mailsSnapshot.showDetailEmailModal(row.original)
                    }} className="m-auto text-center btn btn-primary p-1 d-block m-0 cursor-pointer" style={{width: "fit-content"}}>Détails de l'envoi</div>,<span className='text-success'>Email envoyé le {row.original.dateEnvoi}<FontAwesomeIcon icon={faCheck} className='ms-2' /></span>];
                }
                return <span className='text-info'>En attente d'envoi</span>
            }
        },
        {
            Header: 'Liké',
            id: 'like',
            minWidth: 70,
            Cell: ({ row }) => {
                return row.original.isLike ? "Oui" : "Non";
            }
        },
        {
            Header: 'Nombre d\'ouvertures',
            accessor: 'nbOuvertures',
            minWidth: 70,
        },
        {
            Header: 'Nombre de clics',
            accessor: 'nbClics',
            minWidth: 70,
        },
    ], []);


    return <Modal
        show={mailsSnapshot.statModal.show}
        onHide={() => mailsProxy.statModal.show = false}
        size='xl'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Statistiques du mail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <div>
                    <h3>Liste des destinataires</h3>
                    <SearchBar
                        solid
                        {...register('search')}
                        onClear={search?.length > 0 ? () => {
                            setValue('search', '');
                        } : null}
                    />
                    <MetaTable
                        className='mt-5'
                        height={500}
                        url={`email/${mailsSnapshot.mail?.id}/correspondants`}
                        keys={['email', mailsSnapshot.mail?.id, 'correspondants']}
                        columns={columns}
                        search={debouncedSearch}
                    />
                </div>
        </Modal.Body>
    </Modal>;
}

export default ModalStatistiqueMail;