import {Modal} from "react-bootstrap";
import { useSnapshot } from "valtio";
import MetaTable from "@components/MetaTable/MetaTable";
import {useEffect, useMemo} from "react";
import SearchBar from "@components/SearchBar/SearchBar";
import { useForm } from "react-hook-form";
import { useDebounce } from "use-debounce";
import smsProxy from "../../../../proxies/sms";
import auth from "../../../../services/auth";
import ForumListSujetByCatergories
    from "../../../forum/ForumView/ForumListSujetByCatergories/ForumListSujetByCatergories";
import useGetReponsesSms from "../../../../requests/communication/sms/useGetReponsesSms";
import LoadingView from "../../../LoadingView/LoadingView";

function ResponseModal() {
    const smsSnapshot = useSnapshot(smsProxy);
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const [debouncedSearch] = useDebounce(search, 300);
    const snapAuth = useSnapshot(auth);
    const getReponses = useGetReponsesSms(smsSnapshot?.sms?.id, debouncedSearch);

    useEffect(() => {
        if (smsSnapshot?.sms?.id){
            getReponses.refetch()
        }
    }, [smsSnapshot.sms, debouncedSearch])

    if (getReponses.data){
        const responses = (getReponses.data.data).map((value, index) =>
            <div className="d-flex justify-content-end mb-10">
                <div className="d-flex flex-column align-items-end">
                    <div className="d-flex align-items-center mb-2">
                        <div className="me-3">
                            <span className="text-muted fs-7 mb-1">{value.date}</span>
                            <a href="#" className="fs-5 fw-bolder text-gray-900 text-hover-primary ms-1">{value?.auteur?.destinataire?.personne?.prenom} {value?.auteur?.destinataire?.personne?.nom}</a>
                        </div>
                        <div className="symbol symbol-35px symbol-circle">
                            <img alt="Pic" src={value?.auteur?.destinataire?.personne?.upload?.urlMiniature}/>
                        </div>
                    </div>
                    <div className="p-5 rounded bg-light-primary text-dark fw-bold mw-lg-400px text-end"
                         data-kt-element="message-text">{value.message}
                    </div>
                </div>
            </div>
        );

        return <Modal
            show={smsSnapshot.responseModal.show}
            onHide={() => smsProxy.responseModal.show = false}
            size='lg'
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Réponses au sms</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SearchBar
                    solid
                    {...register('search')}
                    onClear={search?.length > 0 ? () => {
                        setValue('search', '');
                    } : null}
                />
                <div className="card-body" id="kt_chat_messenger_body">
                    <div className="scroll-y me-n5 pe-5 h-300px h-lg-auto" data-kt-element="messages" data-kt-scroll="true"
                         data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                         data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
                         data-kt-scroll-wrappers="#kt_content, #kt_chat_messenger_body" data-kt-scroll-offset="5px">
                        <div className="d-flex justify-content-start mb-10">
                            <div className="d-flex flex-column align-items-start">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="symbol symbol-35px symbol-circle">
                                        <img alt="Pic" src={snapAuth.utilisateur?.upload?.urlMiniature}/>
                                    </div>
                                    <div className="ms-3">
                                        <a href="#" className="fs-5 fw-bolder text-gray-900 text-hover-primary me-1">{snapAuth.utilisateur?.prenom} {snapAuth.utilisateur?.nom}</a>
                                        <span className="text-muted fs-7 mb-1">{smsSnapshot?.sms?.dateEnvoi}</span>
                                    </div>
                                </div>
                                <div className="p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start"
                                     data-kt-element="message-text">{smsSnapshot?.sms?.message}
                                </div>
                            </div>
                        </div>
                        {responses}
                    </div>
                </div>
            </Modal.Body>
        </Modal>;
    }else {
        return <Modal
            show={smsSnapshot.responseModal.show}
            onHide={() => smsProxy.responseModal.show = false}
            size='lg'
            enforceFocus={false}
        ><LoadingView/></Modal>
    }


}

export default ResponseModal;