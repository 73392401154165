import {Alert, Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import facebookProxy from "../../../proxies/facebook";
import publicationReseauxSociauxProxy from "../../../proxies/publicationReseauxSociaux";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import {French} from "flatpickr/dist/l10n/fr";
import ImageUploading from "react-images-uploading";
import React, {useEffect, useState} from "react";
import {getImageSize} from "react-image-size";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import {dataURLtoFile} from "../../../functions/utils";
import {v4 as uuidv4} from "uuid";
import useChangePublicationReseauSocial
    from "../../../requests/communication/reseauxSociaux/facebook/useChangePublicationReseauSocial";
import {useSnapshot} from "valtio";
import {useQueryClient} from "react-query";
import useGetPage from "../../../requests/communication/reseauxSociaux/facebook/useGetPage";
import {useForm} from "react-hook-form";
import auth from "../../../services/auth";
import useUploadFile from "../../../requests/upload/useUploadFile";
import useDeleteImageReseauSociaux from "../../../requests/communication/reseauxSociaux/useDeleteImageReseauSociaux";
import useGetPagesEnregistrer from "../../../requests/communication/reseauxSociaux/facebook/useGetPagesEnregistrer";
import AddFacebookPagesModal from "./AddFacebookPagesModal";
import FacebookPostPreview from "../../../components/ReseauxSociaux/FacebookPostPreview";
import VideoEditor from "../../../components/VideoEditor/VideoEditor";
import useChangeUpload from "../../../requests/upload/useChangeUpload";

export default function EditPublicationReseauSocialView(){
    const pagesEnregistrer = useGetPagesEnregistrer("");
    const snapAuth = useSnapshot(auth);
    const [dateDiffusion, setDateDiffusion] = useState(moment().format("DD/MM/YYYY à HH:mm"));
    const [hiddenEmoji, setHiddenEmoji] = useState(true);
    const snapFacebook = useSnapshot(facebookProxy);
    const [lien, setLien] = useState("");
    const queryClient = useQueryClient();
    const [images, setImages] = React.useState([]);
    const [showModalLien, setShowModalLien] = useState(false);
    const page = useGetPage();
    const publicationRSSnap = useSnapshot(publicationReseauxSociauxProxy);
    const deleteImage = useDeleteImageReseauSociaux();
    const [showProgrammation, setShowProgrammation] = useState(false);
    const maxNumber = 69;
    const changeUpload = useChangeUpload();
    const uploadFile = useUploadFile({
        type: 'publicationReseauSocial',
        onSuccess: (res) => {
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'upload de l'image", err.message);
        }
    });
    const onChange = async (imageList) => {
        let newImageList = [];
        for (const img of imageList) {
            let sizesTmp = await getImageSize(img.data_url);
            newImageList.push({
                ...newImageList,
                ...img,
                sizes : sizesTmp
            });
        }
        setImages(newImageList);
        facebookProxy.postFacebookInCreaction = {
            ...getValues(),
            images: newImageList
        };
    };


    const {
        register, handleSubmit, control, watch, getValues, reset, setValue, setError, clearErrors,
        formState: { errors }
    } = useForm({
        defaultValues: {
            idAgence: snapAuth.agence.id,
            tabUploads: null,
            contenu: null,
            lien: null,
            typeBoutonLien:null,
            dateMiseEnLigne: dateDiffusion,
            tabReseauxDiffusion: {},
            id : null
        }
    });

    const changePublication = useChangePublicationReseauSocial({
        onSuccess: (res) => {
            //todo faire le bon refresh de caches
            // queryClient.invalidateQueries('publicationReseauSocial'+isPublie+selectedMois);
            queryClient.invalidateQueries("publicationReseauSocial");
            facebookProxy.arrayIdPageFacebookForPublication = [];
            publicationReseauxSociauxProxy.currentPostCardPostPublicationModal = null;
            if (getValues('id')){
                BtSwal.fire("Modifications effectuées", "", "success");
            }else {
                if (dateDiffusion < moment().format("DD/MM/YYYY à HH:mm:ss")){
                    BtSwal.fire("Article publié", "", "success");
                }else {
                    BtSwal.fire("Article programmé pour le "+dateDiffusion, "", "success");
                }
            }
            setDateDiffusion(moment().format("DD/MM/YYYY à HH:mm"));
            reset({
                idAgence: snapAuth.agence.id,
                tabUploads: null,
                contenu: null,
                lien: null,
                typeBoutonLien:null,
                dateMiseEnLigne: dateDiffusion, // set la date de programmation ici
                tabReseauxDiffusion: {},
                id : null
            })
            publicationReseauxSociauxProxy.editPublicationReseauSocial.show = false;
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    async function save(data) {
        let tmpTabReseauDiffusion = {}
        if (snapFacebook.arrayIdPageFacebookForPublication.length == 0) {
            return showErrorAlert("Sélection des pages manquantes", "Merci de sélectionner au moins 1 page pour publier le post.");
        }
        //check si changement dans les images
        if (getValues()?.tabUploads){
            const promises = Object.values(getValues()?.tabUploads).map(async tmpImage => {
                if (tmpImage?.id && !images.map(img => img?.id ? img?.id : "").includes(tmpImage?.id)) {
                    await deleteImage.mutateAsync(tmpImage.id);
                    setValue("tabUploads", Object.keys(getValues("tabUploads"))
                        .filter((key) => key != tmpImage.id)
                        .reduce((cur, key) => {
                            return Object.assign(cur, {[key]: getValues("tabUploads")[key]})
                        }, {}))
                }
            })
            await Promise.all(promises);
        }


        // //check des pages facebook
        snapFacebook.arrayIdPageFacebookForPublication.forEach(pageFacebook => {
            tmpTabReseauDiffusion = {
                ...tmpTabReseauDiffusion,
                [pageFacebook.id]: {
                    idSupport: pageFacebook.id,
                    libelle: "page_facebook",
                    dateDiffuse: "0000-00-00 00:00",
                    isDiffuse: false
                }
            }
        })
        let tmpPublication = {
            ...getValues(),
            tabReseauxDiffusion: tmpTabReseauDiffusion,
        }

        let newTabUpload = null;
        let tabUploadFinal = {};
        if (images.length > 0) {
            newTabUpload = {};
            const promises = images.map(async image => {
                if (!image?.id) {
                    let tmpImgRes = await uploadFile.mutateAsync(dataURLtoFile(image.data_url, uuidv4() + ".png"))
                    newTabUpload = {
                        ...newTabUpload,
                        [tmpImgRes.id]: tmpImgRes
                    }
                    return tmpImgRes
                }
                return false
            })
            let ordonedUpload = await Promise.all(promises);
            ordonedUpload = ordonedUpload.filter(filter => filter?.id != null)
            if (ordonedUpload && ordonedUpload.length > 0){
                let tabIds = ordonedUpload.map(upload => {
                    if (upload?.id){
                        return upload.id;
                    }
                    return null;
                })
                tabIds.sort(function (a,b){
                    return a - b;
                })
                //modifier les uploads mettre les bons liens
                const promisesRelinkUpload = ordonedUpload.map(async (upload, index) => {
                    let uploadVoulu = ordonedUpload.find(e => e.id === tabIds[index])
                    uploadVoulu = {
                        ...uploadVoulu,
                        urlMiniature : upload.urlMiniature,
                        urlOriginale : upload.urlOriginale
                    }
                    return await changeUpload.mutateAsync(uploadVoulu)
                })
                tabUploadFinal = await Promise.all(promisesRelinkUpload);
                tabUploadFinal = Object.assign({},tabUploadFinal)
            }


            setImages([]);
        }
        if (newTabUpload){
            tmpPublication = {
                ...tmpPublication,
                tabUploads : {
                    ...getValues("tabUploads"),
                    ...tabUploadFinal
                }
            }
        }
        changePublication.mutate(tmpPublication); //ok

    }
    function listener(e) {

        if (!pagesEnregistrer.isLoading) {
            var startPos = document.getElementById('kt_social_feeds_post_input').selectionStart;
            var endPos = document.getElementById('kt_social_feeds_post_input').selectionEnd;
            let result = document.getElementById('kt_social_feeds_post_input').value.substring(0, startPos)
                + e.detail.unicode
                + document.getElementById('kt_social_feeds_post_input').value.substring(endPos, document.getElementById('kt_social_feeds_post_input').value.length);
            setValue("contenu", result)
            setHiddenEmoji(true);
        }
    }

    useEffect(() => {
        if (!pagesEnregistrer.isLoading && !uploadFile.isLoading && !deleteImage.isLoading && !changePublication.isLoading){
            document.getElementById("emojiPickerSmsEdit").onclick = (e) => {
                setHiddenEmoji(!hiddenEmoji);
            }
        }
    })

    useEffect(() => {
        if (!pagesEnregistrer.isLoading && !uploadFile.isLoading && !deleteImage.isLoading && !changePublication.isLoading ) {
            document.querySelector('emoji-picker').addEventListener('emoji-click', listener)
            return () => {
                if (document.querySelector('emoji-picker')) {
                    document.querySelector('emoji-picker').removeEventListener('emoji-click', listener)
                }
            }
        }
    })

    useEffect( () => {
        if (publicationRSSnap?.currentPostCardPostPublicationModal) {
            if (publicationRSSnap?.currentPostCardPostPublicationModal?.tabUploads) {
                let fetchAndFormatImages = async () => {
                    let arrayImages = await Promise.all(Object.values(publicationRSSnap?.currentPostCardPostPublicationModal?.tabUploads).map(async (value) => {
                        return await (async function () {
                            let blob = await fetch(value?.urlMiniature).then(async r => r.blob());
                            return (await new Promise(resolve => {
                                let reader = new FileReader();
                                reader.onload = () => resolve({
                                    data_url: reader.result,
                                    id: value.id
                                });
                                reader.readAsDataURL(blob);
                            }))
                        })();
                    }))
                    let objectImages = [];
                    arrayImages.forEach((value) => {
                        objectImages.push({
                            data_url: value.data_url,
                            id: value.id
                        });
                    })
                    setImages(objectImages)
                }
                fetchAndFormatImages();
            }
            //Check des publication todo a faire pour tout les réseaux
            let fetchPages = async () => {
                for (const item of Object.values(publicationRSSnap?.currentPostCardPostPublicationModal?.tabReseauxDiffusion)) {
                    if (item.libelle == "page_facebook") {
                        facebookProxy.arrayIdPageFacebookForPublication.push(await page.mutateAsync(item.idSupport))
                    }
                }
            }
            if (publicationRSSnap?.currentPostCardPostPublicationModal?.tabReseauxDiffusion && Object.values(publicationRSSnap?.currentPostCardPostPublicationModal?.tabReseauxDiffusion).length > 0) {
                fetchPages();
            }
            reset(publicationRSSnap?.currentPostCardPostPublicationModal)
        }
    }, [publicationRSSnap?.currentPostCardPostPublicationModal])


    if (pagesEnregistrer.isLoading || uploadFile.isLoading || deleteImage.isLoading || changePublication.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    return <div className="card card-flush mb-10">
            <div className="d-flex flex-wrap bg-gray-200">
                <div style={{minWidth : 500}} className="w-50 m-auto">
                    {/*<div className="card-header justify-content-start align-items-center pt-4">*/}
                    {/*    <div className="symbol symbol-45px me-5">*/}
                    {/*        <img src={snapAuth.utilisateur?.upload?.urlMiniature} className="" alt=""/>*/}
                    {/*    </div>*/}
                    {/*    <span className="text-gray-400 fw-bold fs-6">Quoi de neuf, {snapAuth.utilisateur?.prenom} ?</span>*/}
                    {/*</div>*/}
                    <div className="card-body pt-2 pb-0">
                        <div className="bg-white p-5 rounded mb-5">
                            <h3>Publier dans</h3>
                            <div onClick={() => {
                                snapFacebook.showAddFacebookPagesModal();
                            }} style={{border : "solid 1px black", padding:7}} className="mt-2 mb-2 fab fa-facebook me-2 fa-2x position-relative btn-active-primary btn">
                        <span
                            className="position-absolute top-0 start-100 translate-middle  badge badge-circle badge-primary">{snapFacebook.arrayIdPageFacebookForPublication.length}</span>
                            </div>
                        </div>
                        <div className="bg-white p-5 rounded mb-5">
                            <h3>Contenu multimédia</h3>
                            <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                            >
                                {({
                                      imageList,
                                      onImageUpload,
                                      onImageRemoveAll,
                                      onImageUpdate,
                                      onImageRemove,
                                      isDragging,
                                      dragProps
                                  }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper mt-2">
                                        <button
                                            className="fa fa-image btn btn-primary me-2"
                                            style={isDragging ? { color: "red", padding : "10px 10px 10px 10px" } : {padding : "10px 10px 10px 10px"}}
                                            onClick={() => {
                                                if (getValues("lien")) {
                                                    BtSwal.fire({
                                                        title: 'Un lien a été ajouté en mise en avant. Souhaitez vous le retirer pour ajouter vos images ?',
                                                        showDenyButton: true,
                                                        icon: "question",
                                                        confirmButtonText: `Oui`,
                                                        denyButtonText: `Annuler`,
                                                    }).then((result) => {
                                                        if (result.isConfirmed){
                                                            setLien("")
                                                            setValue("lien", "");
                                                            setValue("typeBoutonLien", "");
                                                            BtSwal.fire('Le lien a été supprimé.', '', 'info')
                                                            onImageUpload()
                                                        } else if (result.isDenied) {
                                                            BtSwal.fire('Le lien a été conservé.', '', 'info')
                                                        }
                                                    })
                                                }else {
                                                    onImageUpload()
                                                }
                                            }}
                                            {...dragProps}
                                        >
                                            <span className="ps-2">Ajouter une image</span>
                                        </button>
                                        <button
                                            className="fa fa-link btn btn-primary"
                                            style={{padding : "10px 10px 10px 10px"}}
                                            onClick={() => {
                                                if (images.length > 0) {
                                                    BtSwal.fire({
                                                        title: 'Cette action entrainera la suppression des images ajoutées. Souhaitez vous continuer ?',
                                                        showDenyButton: true,
                                                        icon: "question",
                                                        confirmButtonText: `Oui`,
                                                        denyButtonText: `Annuler`,
                                                    }).then((result) => {
                                                        if (result.isConfirmed){
                                                            setImages([]);
                                                            facebookProxy.postFacebookInCreaction = {
                                                                ...getValues(),
                                                                images: []
                                                            };
                                                            BtSwal.fire('Les images ont été supprimées.', '', 'info')
                                                            setShowModalLien(true)
                                                        } else if (result.isDenied) {
                                                            BtSwal.fire('Les images sont convervées.', '', 'info')
                                                        }
                                                    })
                                                }else {
                                                    setShowModalLien(true)
                                                }



                                            }}
                                        >
                                            <span className="ps-2">Ajouter un lien</span>
                                        </button>

                                        {/*
                                        todo l'erreur viens des header a set lors du fetch de la video....
                                        Cross-Origin-Opener-Policy: same-origin
                                        Cross-Origin-Embedder-Policy: require-corp
                                        baseurl+js/ffmpeg-core*
                                        coté serveur
                                        <VideoEditor/>*/}
                                        &nbsp;
                                        <div className="d-flex flex-wrap">
                                            {imageList.map((image, index) => (
                                                <div key={index} style={{display: "flex", margin: "10px 0"}} className="image-item">
                                                    <img src={image.data_url} style={{height: "fit-content"}} alt="" width="100" />
                                                    <div style={{display: "flex", flexDirection: "column", marginLeft: 10}} className="image-item__btn-wrapper">
                                                        <button className="btn btn-secondary me-2" style={{padding : "5px 10px 5px 10px"}} onClick={() => onImageRemove(index)}>x</button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </ImageUploading>
                        </div>
                        <div className="bg-white p-5 rounded mb-5">
                            <h3>Détails de la publication</h3>
                            <textarea
                                onKeyUp={() => {
                                    facebookProxy.postFacebookInCreaction = {
                                        ...getValues(),
                                        images: images
                                    };
                                }}
                                {...register('contenu')}  className="form-control bg-transparent mb-2" id="kt_social_feeds_post_input"
                                name="contenu" data-kt-autosize="true" rows="1" placeholder="Tapez votre message..."
                                style={{overflow: "hidden", overflowY: "scroll", overflowWrap: "break-word", resize: "none", height: 150, border: "solid 1px black", padding:"10px !important"}}></textarea>
                            <div className="d-flex justify-content-end pt-0">
                                <Button
                                    className="me-2 w-35px h-35px"
                                    id="emojiPickerSmsEdit"
                                    style={{padding: 4}}
                                >
                                    🙂
                                </Button>
                                <div id="emojiPickerSmsEditContainer" hidden={hiddenEmoji} className="position-absolute">
                                    <br/><br/>
                                    <emoji-picker></emoji-picker>
                                </div>
                                {getValues('id')
                                    ?<>
                                        <button disabled={!watch("contenu")} onClick={handleSubmit((data) => {
                                            BtSwal.fire({
                                                title: 'Êtes vous sur de vouloir modifier le post ?',
                                                showDenyButton: true,
                                                confirmButtonText: `Oui`,
                                                denyButtonText: `Annuler`,
                                            }).then((result) => {
                                                if (result.isConfirmed){
                                                    save(data)
                                                }
                                            })
                                        })} className="btn btn-sm btn-primary" id="kt_social_feeds_post_btn">
                                            <span className="indicator-label">Modifier</span>
                                        </button>
                                        <button onClick={(() => {
                                            BtSwal.fire({
                                                title: 'Souhaitez vous abandonner les modification sur ce post ?',
                                                showDenyButton: true,
                                                confirmButtonText: `Oui`,
                                                denyButtonText: `Annuler`,
                                            }).then((result) => {
                                                if (result.isConfirmed){
                                                    facebookProxy.arrayIdPageFacebookForPublication = [];
                                                    publicationReseauxSociauxProxy.currentPostCardPostPublicationModal = null;
                                                    setDateDiffusion(moment().format("DD/MM/YYYY à HH:mm"));
                                                    setImages([]);
                                                    reset({
                                                        idAgence: snapAuth.agence.id,
                                                        tabUploads: null,
                                                        contenu: null,
                                                        lien: null,
                                                        typeBoutonLien:null,
                                                        dateMiseEnLigne: dateDiffusion, // set la date de programmation ici
                                                        tabReseauxDiffusion: {},
                                                        id : null
                                                    })
                                                }
                                            })
                                        })} className="btn btn-sm btn-secondary ms-2" id="kt_social_feeds_post_btn">
                                            <span className="indicator-label">Annuler l'édition</span>
                                        </button>
                                    </>
                                    :
                                    <button disabled={!watch("contenu")} onClick={handleSubmit((data) => {
                                        BtSwal.fire({
                                            title: 'Êtes vous sur de vouloir publier le post ?',
                                            showDenyButton: true,
                                            confirmButtonText: `Oui`,
                                            denyButtonText: `Annuler`,
                                        }).then((result) => {
                                            if (result.isConfirmed){
                                                save(data)
                                            }
                                        })
                                    })} className="btn btn-sm btn-primary" id="kt_social_feeds_post_btn">
                                        <span className="indicator-label">Publier</span>
                                    </button>
                                }
                            </div>
                        </div>
                        <div className="bg-white p-5 rounded mb-5">
                            <h3>Programmer la publication</h3>
                            <div className="d-flex h-50px">
                                <i onClick={() => {
                                    if (!showProgrammation){
                                        setShowProgrammation(true);
                                    }else {
                                        setShowProgrammation(false);
                                    }
                                }} style={{border : "solid 1px black"}} className="fa fa-clock me-2 fa-2x position-relative btn-active-primary btn">
                                </i>
                                <Flatpickr
                                    className={`mw-400px me-2 form-control ms-2 mt-2 mb-2 ${!showProgrammation ? "d-none" : ""}`}
                                    defaultValue={getValues("dateMiseEnLigne") ? getValues("dateMiseEnLigne") : moment().format("DD/MM/YYYY à HH:mm")}
                                    value={getValues("dateMiseEnLigne") ? getValues("dateMiseEnLigne") : moment().format("DD/MM/YYYY à HH:mm")}
                                    onClose={date => {
                                        setDateDiffusion(moment(date[0]).format("DD/MM/YYYY HH:mm"));
                                        setValue("dateMiseEnLigne", moment(date[0]).format("DD/MM/YYYY HH:mm"));
                                    }}
                                    options={{
                                        locale: French,
                                        dateFormat: 'd/m/Y à H:i',
                                        enableTime: true,
                                    }}
                                />
                                {/*<i onClick={() => {*/}
                                {/*    let tmpPublicationPreview = {*/}
                                {/*        ...getValues(),*/}
                                {/*        images:images*/}
                                {/*    }*/}
                                {/*    snapFacebook.showPreviewPostFacebook(tmpPublicationPreview);*/}
                                {/*}} style={{border : "solid 1px black", color: "gray"}} className="fab fa-facebook me-2 fa-2x position-relative btn-active-primary btn">*/}
                                {/*    <i className="fa fa-eye position-absolute fa-2x" style={{right:10, top:15, color: "black"}}/>*/}
                                {/*</i>*/}
                            </div>
                        </div>
                    </div>
                    <div className="card-footer pt-0">
                        <div className="d-flex flex-wrap justify-content-between w-100"
                             id={"divDateEnvoi"}>
                            <AddFacebookPagesModal/>

                        </div>
                    </div>
                    <Modal
                        show={showModalLien}
                        onHide={() => {
                            setShowModalLien(false)
                        }}
                        size='lg'
                        enforceFocus={false}
                    >
                        <Modal.Header closeButton>
                            <div className='w-100 stepper stepper-links d-flex flex-column between'>
                                <div className='stepper-nav'>
                                    <h3 className='stepper-title'>Ajout lien en prévisualisation</h3>
                                </div>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Alert hidden={false} id="infoMessage"  className='mt-2 mx-auto' key='info' variant='info'>
                                Lors de la publication, le lien ajouté à la publication sera mis en avant par un aperçu de la page en bas du post.
                            </Alert>
                            <Row className='mt-5'>
                                <Col sm={12} className='mt-5 mt-sm-0'>
                                    <Form.Label>Lien</Form.Label>
                                    <Form.Control
                                        {...register('lien')}
                                        />
                                </Col>
                                {errors.lien && <Form.Text className='text-danger'>Url incorrect</Form.Text>}
                                <Col sm={12} className='mt-5 mt-sm-0'>
                                    <Form.Label>Type de lien</Form.Label>
                                    <Form.Select
                                        {...register('typeBoutonLien')}
                                    >
                                        <option value="BOOK_TRAVEL">Réserver</option>
                                        <option value="BUY_NOW">Acheter</option>
                                        <option value="CALL_NOW">Appeler</option>
                                        <option value="DOWNLOAD">Télécharger</option>
                                        <option value="INSTALL_APP">Installer</option>
                                        <option value="INSTALL_MOBILE_APP">Installer mobile</option>
                                        <option value="LEARN_MORE">En savoir plus</option>
                                        <option value="LIKE_PAGE">Aimer la page</option>
                                        <option value="LISTEN_MUSIC">Ecouter de la musique</option>
                                        <option value="MESSAGE_PAGE">Message a la page</option>
                                        <option value="NO_BUTTON">Aucune action</option>
                                        <option value="OPEN_LINK">Ouvrir le lien</option>
                                        <option value="PLAY_GAME">Jouer à un jeu</option>
                                        <option value="SIGN_UP">S'inscrire</option>
                                        <option value="SUBSCRIBE">S'abonner</option>
                                        <option value="USE_APP">Utiliser l'application</option>
                                        <option value="USE_MOBILE_APP">Utiliser l'application mobile</option>
                                        <option value="WATCH_MORE">Regarder plus</option>
                                        <option value="WATCH_VIDEO">Regarder la vidéo</option>
                                    </Form.Select>
                                </Col>
                                {errors.typeBoutonLien && <Form.Text className='text-danger'>Merci de choisir un type de lien</Form.Text>}

                            </Row>

                            <div className="d-flex justify-content-center mt-2">
                                <Button
                                    variant='primary'
                                    onClick={() => {
                                        if (!isValidHttpUrl(getValues("lien"))) {
                                            setError("lien")
                                        }else {
                                            clearErrors("lien")
                                            if (getValues('typeBoutonLien')) {
                                                facebookProxy.postFacebookInCreaction = {
                                                    ...getValues(),
                                                    images: [],
                                                };
                                                setShowModalLien(false)
                                            }else {
                                                setError("typeBoutonLien")
                                            }
                                        }
                                    }}
                                >Valider</Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
                <div style={{minWidth : 500}} className="w-50 m-auto d-flex flex-column align-items-center">
                    <ul class="bg-white nav nav-tabs nav-line-tabs fs-6 w-500px ps-5 pe-5" style={{borderRadius: "15px 15px 0 0", boxShadow: "2px 2px 5px #4443", border: "1px solid #ccc", borderBottom:"0"}}>
                        <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_1">Facebook</a>
                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_2">Link 2</a>*/}
                        {/*</li>*/}
                        {/*<li className="nav-item">*/}
                        {/*    <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_3">Link 3</a>*/}
                        {/*</li>*/}
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
                            <FacebookPostPreview/>
                        </div>
                        <div class="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                            ...
                        </div>
                        <div class="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
                            ...
                        </div>
                    </div>
                </div>
            </div>
        </div>



    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            let checkUrlMotCle = string.slice(0,4)
            return string && checkUrlMotCle == "{url";
        }

        return url.protocol === "https:" || url.protocol === "http:";
    }
    function addText(event) {
        if (document.getElementById("kt_social_feeds_post_input")){
            document.getElementById("kt_social_feeds_post_input").value += event;
        }
    }
}