import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGetExpediteurs from "@requests/communication/expediteur/useGetExpediteurs";
import { Accordion, Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import useChangeExpediteur from "@requests/communication/expediteur/useChangeExpediteur";
import { useState } from "react";
import {showErrorAlert} from "../../../../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../../../../services/auth";

function ExpediteurSettings({ show }) {
    const expediteurs = useGetExpediteurs({ enabled: show });
    const queryClient = useQueryClient();
    const [activeKey, setActiveKey] = useState();
    const snapAuth = useSnapshot(auth);
    const changeExpediteur = useChangeExpediteur({
        onSuccess: () => {
            queryClient.invalidateQueries('expediteur');
            snapAuth.websocket.send("expediteur")

            setActiveKey(null);
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    function onSave(data) {
        changeExpediteur.mutate(data);
    }

    function toggleAccordionKey(eventKey) {
        if (activeKey == eventKey)
            setActiveKey(null);
        else
            setActiveKey(eventKey);
    }

    if (expediteurs.isError)
        return <Alert variant='danger'>{expediteurs.error?.message}</Alert>;

    if (expediteurs.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    return <Accordion activeKey={activeKey}>
        {expediteurs.data?.data?.map(exp => {
            return <div className='bg-light my-2 p-3 rounded' key={exp.id}>
                <div className='d-flex align-content-center align-items-center'>
                    <div className='me-auto fw-bold'>
                        {exp.libelleAffiche}
                        <span className='fw-bolder text-info'> ({exp.email})</span>
                    </div>
                    <Button
                        variant='icon'
                        onClick={() => toggleAccordionKey(exp.id)}
                        className='btn-sm btn-light btn-active-primary w-25px h-25px'
                    >
                        <FontAwesomeIcon icon={faPen} />
                    </Button>
                </div>
                <Accordion.Collapse eventKey={exp.id}>
                    <div className='mt-3'>
                        <div className='separator' />
                        <ExpediteurForm expediteur={exp} onSave={onSave} />
                    </div>

                </Accordion.Collapse>
            </div>;
        })}
        <div className='mt-5'>
            <div className='d-flex'>
                <div className='mx-auto fw-bold'>
                    <Button
                        variant='success'
                        className='btn-sm px-20'
                        onClick={() => toggleAccordionKey('new')}
                    >
                        <FontAwesomeIcon icon={faPlus} className='me-2' />
                        Ajouter un expéditeur
                    </Button>
                </div>
            </div>
            <Accordion.Collapse eventKey={'new'}>
                <div className='bg-light rounded mt-3 pt-3 mx-10'>
                    <ExpediteurForm onSave={onSave} />
                </div>
            </Accordion.Collapse>
        </div>
    </Accordion>;
}

function ExpediteurForm({ expediteur, onSave }) {
    const { register, handleSubmit } = useForm({
        defaultValues: expediteur,
    });

    return <div className='mt-5 mx-10'>
        <Form.Group as={Row}>
            <Form.Label column sm='1'>Libellé</Form.Label>
            <Col sm='11'>
                <Form.Control {...register('libelleAffiche')} />
            </Col>
        </Form.Group>
        <Form.Group as={Row} className='mt-2'>
            <Form.Label column sm='1'>Email</Form.Label>
            <Col sm='11'>
                <Form.Control {...register('email')} />
            </Col>
        </Form.Group>
        <div className='mt-2 d-flex justify-content-center'>
            <Button
                variant='link'
                className='btn-md text-info'
                onClick={handleSubmit(onSave)}
            >{expediteur?.id != null ? 'Enregistrer' : 'Ajouter'}</Button>
        </div>
    </div>;
}

export default ExpediteurSettings;