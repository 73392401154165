import { useQuery } from "react-query";
import api from "@services/api";

export default function useGetEmailInscriptionInvite() {
    return useQuery(
        ['emailInscriptionInvite'],
        async () => {
            return api.get(`emailInscriptionInvite`).json();
        }
    );
}