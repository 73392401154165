import './Canvas.css';

import React from 'react';
import Section from '../Section/Section';
import { Droppable } from 'react-beautiful-dnd';

function Canvas({ sections, onChange, isInvitation }) {
    var sectionEditors = [];
    sections.order.forEach(function (id, index) {
        sectionEditors.push(<Section
            isInvitation={isInvitation}
            block={sections[id]}
            id={id}
            key={id}
            index={index}
            onChange={(value) => {
                const result = {
                    ...sections,
                    [id]: value,
                };
                onChange(result);
            }}
            onDelete={(value) => {
                console.log(`Delete section ${value}`);
                const result = {
                    ...sections,
                    [id]: null,
                    order: sections.order.filter((v) => v !== id),
                };
                onChange(result);
            }}
        />);
    });

    return <Droppable droppableId="canvas">
        {(provided, snapshot) => (
            <tr
                className={`canvas ${sectionEditors.length < 1 ? 'empty-canvas' : ''}`}
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                    pointerEvents: snapshot.isDraggingOver ? 'none' : 'auto'
                }}
            >
                {sectionEditors}
                <td>{provided.placeholder}</td>
            </tr>
        )}
    </Droppable>;
}

export default Canvas;
