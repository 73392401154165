export function getObjetPersonneInscriptionLibre(){
    return {
        civilite: "Monsieur",
        nom: null,
        prenom: null,
        fonction: null,
        indicatifTel1: "33",
        tel1: null,
        indicatifTel2: "33",
        tel2: null,
        adresse: null,
        codePostal: null,
        ville: null,
        email: null,
        dateAnniversaire: null,
        datePremiereLicence: null,
        urlFacebook: null,
        urlTwitter: null,
        urlInstagram: null,
        urlYoutube: null,
        urlLinkedin: null,
        urlTiktok: null,
        identifiant: null,
        motDePasse: null,
        isVouvoye: true,
        tel1IsVisible: false,
        tel2IsVisible: false,
        emailIsVisible: false,
        isVisibleInTrombinoscope: false,
        mailIsDesinscrit: false,
        mailIsErrone: false,
        isPartenaire: false,
        upload: null,
        parametreNotification: {
            mailNouveauSujetForum: true,
            pushNouveauSujetForum: true,
            mailReponseAuteurSujetForum: true,
            pushReponseAuteurSujetForum: true,
            mailReponseParticipantSujetForum: true,
            pushReponseParticipantSujetForum: true,
            mailNouvelEmail: true,
            pushNouvelEmail: true,
            mailInvitation: true,
            pushInvitation: true,
            mailRappelInvitation: true,
            pushRappelInvitation: true,
            id: 12
        },
        tabProfils: {
            50: {
                libelle: "Incription libre",
                isPartenaire: false,
                pourEntreprise: false,
                pourPersonne: true,
                id: 50
            }
        },
        tabChampsPersonnalisesAnnuaire: null,
        tabTokens: null,
        compteIsBloque: false,
        id: null
    }
}

export function getEntreprise(){
    return {
        nom: null,
        activite: null,
        adresse: null,
        codePostal: null,
        ville: null,
        tel1: null,
        tel2: null,
        urlSiteInternet: null,
        datePremierPartenariat: null,
        presentation:null,
        isVisibleInAnnuaire: false,
        tel1IsVisible: false,
        tel2IsVisible: false,
        upload: null,
        tabProfils: [],
        id: null
    }
}