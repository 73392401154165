import { ReactComponent as Logo } from "@assets/logo.svg";
import {useForm} from "react-hook-form";
import {Button, Col, Form, Row} from "react-bootstrap";
import BlockUI from "../../../../../components/BlockUI/BlockUI";
import {useSnapshot} from "valtio";
import auth from "../../../../../services/auth";

function ConfirmInfosFacturationView({infosFacturation, changeFacture}) {

    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // const ref = urlParams.get('ref') ? urlParams.get('ref').replace(/ /g, "+") : "";
    // const refPaiement = urlParams.get('refPaiement') ? urlParams.get('refPaiement').replace(/ /g, "+") : "";
    // const sendMail = true;
    // const verifPaiement = useGetVerifPaiement(ref, refPaiement, sendMail);
    const snapAuth = useSnapshot(auth);
    const { register, handleSubmit,
        formState: { errors } }
        = useForm({defaultValues: infosFacturation});


    function onSave(data) {
        changeFacture.mutate(data);
    }

    return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
            <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                    <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Informations de facturation</h1>
                </div>
            </div>
        </div>
        <div className='d-flex flex-column flex-lg-row-fluid py-10 card'>
            <div>
                <div>
                    <BlockUI loading={changeFacture.isLoading}>
                        <form><div className='bg-light rounded mt-3 pt-3 mx-10 pb-10'>
                            <div className='mt-5 mx-10'>
                                <p>Veuillez confirmer le formulaire ci-dessous.</p>
                                <Row>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>Identité client</Form.Label>
                                            <Form.Control maxLength={60} placeholder='Identité client' className={`form-control`} {...register('facture.identiteClient', { required: true })} />
                                            {errors.facture && errors.facture.identiteClient && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>Ville</Form.Label>
                                            <Form.Control maxLength={30} placeholder='Ville' className={`form-control`} {...register('facture.villeClient', { required: true })} />
                                            {errors.facture && errors.facture.villeClient && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Code postal</Form.Label>
                                            <Form.Control maxLength={5} placeholder='Code postal' className={`form-control`} {...register('facture.codePostalClient', { required: true })} />
                                            {errors.facture && errors.codePostalClient && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Numéro d'identification à la TVA</Form.Label>
                                            <Form.Control maxLength={20} placeholder="Numéro d'identification à la TVA" className={`form-control`} {...register('facture.numeroTvaClient')} />
                                            {errors.facture && errors.facture.numeroTvaClient && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mt-5'>
                                        <Form.Group>
                                            <Form.Label>Adresse ligne 1</Form.Label>
                                            <Form.Control maxLength={100} placeholder='Adresse ligne 1' className={`form-control`} {...register('facture.ligne1AdresseClient', { required: true })} />
                                            {errors.facture && errors.facture.ligne1AdresseClient && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                    <Col className='mt-5'>
                                        <Form.Group>
                                            <Form.Label>Adresse ligne 2</Form.Label>
                                            <Form.Control maxLength={100} placeholder='Identité client' className={`form-control`} {...register('facture.ligne2AdresseClient')} />
                                            {errors.facture && errors.facture.ligne2AdresseClient && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div id="containerValidButton" className='mt-2 d-flex justify-content-center'>
                                    <Button
                                        variant='primary'
                                        className='px-20'
                                        onClick={handleSubmit(onSave)}
                                    >Valider</Button>
                                </div>
                            </div>
                        </div></form>
                    </BlockUI>
                </div>
            </div>
        </div>
    </div>;


}

export default ConfirmInfosFacturationView;