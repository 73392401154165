import './ModulesList.css';

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Card } from "react-bootstrap";
import { mailTemplate } from "../../mailTemplate/mailTemplate";
import { mailInvitationTemplate } from '../../mailTemplate/mailInvitationTemplate';

function AddModulesIndicator() {
    return <div className='addModuleIndicator rounded bg-success p-2 h-40px w-40px text-center'>
        <FontAwesomeIcon icon={faPlus} size='2x' color='white' className='' />
    </div>;
}

function ModulesIcon({ type, Modules, index }) {
    return <Draggable draggableId={type} key={type} index={index}>
        {(provided, snapshot) => (
            <>
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={!snapshot.isDropAnimating ?
                        provided.draggableProps.style :
                        {
                            ...provided.draggableProps.style,
                            transitionDuration: '0.01s',
                        }
                    }
                >
                    <Card className='bg-white shadow-sm m-4'>
                        <img
                            src={Modules.image}
                            width='200px'
                            alt=''
                        />
                        {(snapshot.draggingOver === 'canvas') && <AddModulesIndicator />}
                    </Card>
                </div>
                {snapshot.isDragging && <Card className='bg-secondary m-4'>
                    <img
                        src={Modules.image}
                        width='200px'
                        alt=''
                    />
                </Card>}
            </>
        )}
    </Draggable>;
}

function ModulesList({ order, isInvitation }) {
    let pickers = [];

    order.forEach((type, index) => {
        pickers.push(<ModulesIcon
            key={type}
            type={type}
            Modules={isInvitation ? mailInvitationTemplate[type] : mailTemplate[type]}
            index={index}
        />);
    });

    return <div>
        <Droppable droppableId="modulesList" isDropDisabled={true}>
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    {pickers}
                </div>
            )}
        </Droppable>
    </div>;
}

export default ModulesList;