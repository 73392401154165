import image from './icons/informationsObligatoire.svg';

export const blocInformationsObligatoire = {
        image: image,
        defaultContent:  '',
        template: `
            <tr id="colorPicker">
                <td valign="top" align="center">
                    <table width="100%" cellspacing="0" cellpadding="0" border="0">
                        <tbody><tr>
                            <td valign="top" align="center">
                                <table class="flexibleContainer" width="600" cellspacing="0" cellpadding="0" border="0">
                                    <tbody><tr>
                                        <td class="flexibleContainerCell" id="paddingTemplate" width="600" valign="top" align="center">
                                            <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                                <tbody><tr>
                                                    <td id="blocModaliteReponseInvitation" class="textContent" valign="top">
                                                          {blocModaliteReponseInvitation}
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                        </td>
                                    </tr>
                                </tbody></table>
                            </td>
                        </tr>
                    </tbody></table>
                </td>
            </tr>
        `,
}
