import {Button, Col, Dropdown, Row} from "react-bootstrap";
import {BlockPicker, SketchPicker} from "react-color";
import { ReactComponent as FillDrip } from "@assets/icons/fillDrip.svg";
import {useEffect, useState} from "react";
import useGetColorAgence from "../../../requests/parametres/agence/useGetColorAgence";
import auth from "@services/auth";
import BlockUI from "../../../components/BlockUI/BlockUI";
import useChangeColorAgence from "../../../requests/parametres/agence/useChangeColorAgence";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import {useQueryClient} from "react-query";
import {useSnapshot} from "valtio";


function CouleursAgenceSettings() {
    const [updateData, setUpdateData] = useState(0);
    const colorAgence = useGetColorAgence(auth?.agence?.id);
    const [colorsState, setColorsState] = useState();
    let queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const changeColors = useChangeColorAgence({
        onSuccess: () => {
            queryClient.invalidateQueries(['themeUi']);
            snapAuth.websocket.send("themeUi")

            BtSwal.fire('Les couleurs ont été modifés.', '', 'success')
        },
        onError: (err) => {
            queryClient.invalidateQueries(['themeUi']);
            snapAuth.websocket.send("themeUi")

            BtSwal.fire(err.message, '', 'error')
        }
    });

    useEffect(() => {
        if (!colorsState && colorAgence.isSuccess){
            setColorsState({
                "primaire" :colorAgence?.data?.primaire,
                "secondaire" : colorAgence?.data?.secondaire,
                "tertiaire" : colorAgence?.data?.tertiaire,
                "quaternaire" : colorAgence?.data?.quaternaire,
            })
        }
    }, [colorAgence])

    function rgbaToHex (rgba) {
        if (rgba[0] != "#"){
            //on retitre le rgba
            rgba = rgba.substring(4)
            rgba = rgba.replace("(", "")
            rgba = rgba.replace(")", "")
            let rgbaSplit = rgba.split(",");
            let r = parseInt(rgbaSplit[0])
            let g = parseInt(rgbaSplit[1])
            let b = parseInt(rgbaSplit[2])
            let a = parseInt(rgbaSplit[3])
                var outParts = [
                    r.toString(16),
                    g.toString(16),
                    b.toString(16),
                    Math.round(a * 255).toString(16).substring(0, 2)
                ];

                // Pad single-digit output values
                outParts.forEach(function (part, i) {
                    if (part.length === 1) {
                        outParts[i] = '0' + part;
                    }
                })

                return ('#' + outParts.join(''));
        }else {
            return rgba;
        }
    }

    function lightRgbaStr(rgba){
        return "rgba("+rgba.r+","+rgba.g+","+rgba.b+","+getComputedStyle(document.body).getPropertyValue("--light-percent")+")";
    }

    function rgbaStr(rgba){
        if (rgba){
            return "rgba("+rgba.r+","+rgba.g+","+rgba.b+","+rgba.a+")";
        }
    }
    function hoverRgbaStr(rgba){
        return "rgba("+rgba.r+","+rgba.g+","+rgba.b+","+getComputedStyle(document.body).getPropertyValue("--hover-percent")+")";
    }

        return <BlockUI loading={colorAgence.isLoading}>
            <div style={{width: "fit-content"}} className='alert alert-info m-auto mb-3'>
                <div className="mt-2 text-center">
                    <p>
                        Les paramètres ci-dessous vous permettrons d'adapter votre compte stripe à votre image de marque. <br/>
                        Par exemple, les reçus de paiement seront aux couleurs choisies avec l'icône et/ou le logo renseigné.
                    </p>
                </div>
            </div>
            <Row className="mb-2" style={{minHeight:"250px"}}>
                <Col sm={3} className="justify-content-center d-flex">
                    <div className="d-flex flex-column align-items-center">
                        <h1 className="mb-5">Couleur primaire :</h1>
                        <div className="d-flex align-items-center">
                            <div className="h-25px w-50px border-2 border-dark" style={{border: "solid", backgroundColor:rgbaStr(colorsState?.primaire)}}>

                            </div>
                            <Dropdown title={'Choix de la couleur primaire'}>
                                <Dropdown.Toggle className='hide-after' variant='white'>
                                    <span className='svg-icon svg-icon-1 me-2'><FillDrip/></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='p-0'>
                                    <BlockPicker
                                        // color={getValues("parametresStripe.primary_color") ? getValues("parametresStripe.primary_color") : "#000"}
                                        color={rgbaStr(colorsState?.primaire)}
                                        onChange={(color) => {
                                            document.querySelector(':root').style.setProperty('--primary-color', color.hex);
                                            document.querySelector(':root').style.setProperty('--primary-color-light', lightRgbaStr(color.rgb));
                                            document.querySelector(':root').style.setProperty('--primary-color-hover', hoverRgbaStr(color.rgb));
                                            setColorsState({
                                                ...colorsState,
                                                "primaire" : color.rgb
                                            })
                                            setUpdateData(updateData+1)
                                        }} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
                <Col sm={3} className="justify-content-center d-flex">
                    <div className="d-flex flex-column align-items-center">
                        <h1 className="mb-5">Couleur secondaire :</h1>
                        <div className="d-flex align-items-center">
                            <div className="h-25px w-50px border-2 border-dark" style={{border: "solid", backgroundColor:rgbaStr(colorsState?.secondaire) }}>

                            </div>
                            <Dropdown title={'Choix de la couleur secondaire'}>
                                <Dropdown.Toggle className='hide-after' variant='white'>
                                    <span className='svg-icon svg-icon-1 me-2'><FillDrip/></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='p-0'>
                                    <BlockPicker
                                        // color={getValues("parametresStripe.primary_color") ? getValues("parametresStripe.primary_color") : "#000"}
                                        color={rgbaStr(colorsState?.secondaire)}
                                        onChange={(color) => {
                                            document.querySelector(':root').style.setProperty('--secondary-color', color.hex);
                                            document.querySelector(':root').style.setProperty('--secondary-color-light', lightRgbaStr(color.rgb));
                                            document.querySelector(':root').style.setProperty('--secondary-color-hover', hoverRgbaStr(color.rgb));
                                            setUpdateData(updateData+1)
                                            setColorsState({
                                                ...colorsState,
                                                "secondaire" : color.rgb
                                            })
                                        }} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
                <Col sm={3} className="justify-content-center d-flex">
                    <div className="d-flex flex-column align-items-center">
                        <h1 className="mb-5">Couleur tertiaire :</h1>
                        <div className="d-flex align-items-center">
                            <div className="h-25px w-50px border-2 border-dark" style={{border: "solid", backgroundColor:rgbaStr(colorsState?.tertiaire) }}>

                            </div>
                            <Dropdown title={'Choix de la couleur succès'}>
                                <Dropdown.Toggle className='hide-after' variant='white'>
                                    <span className='svg-icon svg-icon-1 me-2'><FillDrip/></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='p-0'>
                                    <BlockPicker
                                        // color={getValues("parametresStripe.primary_color") ? getValues("parametresStripe.primary_color") : "#000"}
                                        color={rgbaStr(colorsState?.tertiaire)}
                                        onChange={(color) => {
                                            document.querySelector(':root').style.setProperty('--tertiaire-color', color.hex);
                                            document.querySelector(':root').style.setProperty('--tertiaire-color-light', lightRgbaStr(color.rgb));
                                            document.querySelector(':root').style.setProperty('--tertiaire-color-hover', hoverRgbaStr(color.rgb));
                                            setUpdateData(updateData+1)
                                            setColorsState({
                                                ...colorsState,
                                                "tertiaire" : color.rgb
                                            })
                                        }} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
                <Col sm={3} className="justify-content-center d-flex">
                    <div className="d-flex flex-column align-items-center">
                        <h1 className="mb-5">Couleur quaternaire :</h1>
                        <div className="d-flex align-items-center">
                            <div className="h-25px w-50px border-2 border-dark" style={{border: "solid", backgroundColor:rgbaStr(colorsState?.quaternaire) }}>

                            </div>
                            <Dropdown title={'Choix de la couleur succès'}>
                                <Dropdown.Toggle className='hide-after' variant='white'>
                                    <span className='svg-icon svg-icon-1 me-2'><FillDrip/></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='p-0'>
                                    <BlockPicker
                                        // color={getValues("parametresStripe.primary_color") ? getValues("parametresStripe.primary_color") : "#000"}
                                        color={rgbaStr(colorsState?.quaternaire)}
                                        onChange={(color) => {
                                            document.querySelector(':root').style.setProperty('--quaternaire-color', color.hex);
                                            document.querySelector(':root').style.setProperty('--quaternaire-color-light', lightRgbaStr(color.rgb));
                                            document.querySelector(':root').style.setProperty('--quaternaire-color-hover', hoverRgbaStr(color.rgb));
                                            setUpdateData(updateData+1)
                                            setColorsState({
                                                ...colorsState,
                                                "quaternaire" : color.rgb
                                            })
                                        }} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
            </Row>
            <Button
                style={{width:"fit-content"}}
                variant='secondary'
                className='px-20 m-auto d-flex'
                onClick={((data) => {
                    if(colorsState?.primaire && colorsState?.secondaire && colorsState?.tertiaire && colorsState?.quaternaire){
                        changeColors.mutate(colorsState)
                    }else{
                        BtSwal.fire("Erreur", "Merci de renseigner les 4 couleurs", "error")
                    }
                })}
            >
                Enregistrer les modifications
            </Button>
        </BlockUI>;



}

export default CouleursAgenceSettings;