import {Modal} from "react-bootstrap";
import {useSnapshot} from "valtio";
import publicationReseauxSociaux from "../../../proxies/publicationReseauxSociaux";
import EditPublicationReseauSocialView from "./EditPublicationReseauSocialView";
import facebookProxy from "../../../proxies/facebook";
import publicationReseauxSociauxProxy from "../../../proxies/publicationReseauxSociaux";


function EditPublicationReseauSocialModal(){
    const publicationRS = useSnapshot(publicationReseauxSociaux);

    return <Modal
        show={publicationRS.editPublicationReseauSocial.show}
        onHide={() => {
            facebookProxy.arrayIdPageFacebookForPublication = [];
            facebookProxy.postFacebookInCreaction = null;
            publicationReseauxSociauxProxy.currentPostCardPostPublicationModal = null;
            publicationReseauxSociaux.editPublicationReseauSocial.show = false;
        }}
        dialogClassName='p-9 modal-fullscreen'
        contentClassName='modal-rounded'
        // dialogClassName="modal-dialog modal-fullscreen-lg-down modal-lg"
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <h2>
                Edition du post
            </h2>
        </Modal.Header>
        <Modal.Body>
            <EditPublicationReseauSocialView/>
        </Modal.Body>
    </Modal>
}
export default EditPublicationReseauSocialModal;