import { proxy } from "valtio";

const linkedinProxy = proxy({
    compteLinkedin:null,
    pageLinkedin: null,
    arrayIdPageLinkedinForPublication: [],
    postLinkedinInCreaction:null,
    previewPostLinkedinModal: {
        show: false
    },
    gestionPagesModal: {
        show: false,
    },
    addLinkedinPagesModal: {
        show: false,
    },
    editPage: {
        show: false,
    },
    showPreviewPostLinkedin(postLinkedinInCreaction){
        linkedinProxy.postLinkedinInCreaction = postLinkedinInCreaction;
        linkedinProxy.previewPostLinkedinModal = {
            show: true,
        }
    },
    showGestionPages: (compte) => {
        linkedinProxy.compteLinkedin = compte
        linkedinProxy.gestionPagesModal = {
            show: true,
        };
    },
    showAddLinkedinPagesModal: () => {
        linkedinProxy.addLinkedinPagesModal = {
            show: true,
        };
    },
    showEditPage: (page) => {
        linkedinProxy.pageLinkedin = page
        linkedinProxy.gestionPagesModal.show = false
        linkedinProxy.editPage = {
            show: true,
        }
    }
})

export default linkedinProxy;