import { useMutation } from "react-query";
import api from "@services/api";
import auth from "@services/auth";

export default function useChangeBlocModalite(props) {
    return useMutation(changeModalite, props);
}

function changeModalite(bloc) {
    const id = bloc?.id;
    const idAgence = auth.agence?.id;

    const params = { ...bloc, idAgence };

    if (id != null)
        return api.put(`blocModaliteReponseInvitation/${id}`, { json: params }).json();
    return api.post('blocModaliteReponseInvitation', { json: params }).json();
}