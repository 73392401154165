import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./CountDownCircleTimer.css";
import {
    dureeMinuteToTimeMysqlWithoutHours
} from "../../views/SpeedBusinessView/ReglageStep/data";
import {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";


const renderTime = ({ remainingTime }) => {
    return (
        <div className="timer-CDCT">
            <div className="value-CDCT">{dureeMinuteToTimeMysqlWithoutHours(remainingTime/60)}</div>
        </div>
    );
};

function CountDownCircleTimer({color = "--primary-color", setCurrentPerson, currentPerson, personneParTable, title, isTimePersonne, setIsTimePersonne, duration, setIsEntracte, isEntracte, tour, setTour, setIsChangementTour, isChangementTour, setCurrentTime, isPlaying, valueChangeRemainingTime = false, setValueChangeRemainingTime, setInitCurrentPerson}) {

    const [initialRemainingTime, setInitialRemainingTime] = useState(valueChangeRemainingTime ? valueChangeRemainingTime : duration);

    //force le re-render
    const [key, setKey] = useState(uuidv4());
    useEffect(() => {
        if (valueChangeRemainingTime){
            setInitialRemainingTime(valueChangeRemainingTime);
            setKey(uuidv4())
        }
    }, [valueChangeRemainingTime]);


    return (
        <div key={key} className="d-none App">
            <h1 className="text-center" style={{
            }}>
                {title} {/* {isTimePersonne && currentPerson}*/}
            </h1>
            <div className="d-flex justify-content-center align-items-center">
                <div className="timer-wrapper-CDCT position-relative">
                    <CountdownCircleTimer
                        size={130}
                        trailStrokeWidth={3}
                        strokeWidth={3}
                        isPlaying={isPlaying}
                        //todo permet de set le temps restant si différent du temps totale -> utile pour le websocket pour initialiser les vues entre elles
                        initialRemainingTime={initialRemainingTime}
                        duration={duration}
                        colors={["var("+color+")"]}
                        onComplete={() => {
                            if (isTimePersonne) {
                                if (currentPerson+1 > personneParTable){
                                    setIsChangementTour(true)
                                    setIsTimePersonne(false)
                                    setCurrentPerson(1)
                                    setTour(tour+1)
                                    setValueChangeRemainingTime(false)
                                    setInitCurrentPerson(true)
                                    return { shouldRepeat: false, delay: 0 };
                                }else {
                                    setCurrentPerson(currentPerson+1)
                                    setValueChangeRemainingTime(false)
                                    return { shouldRepeat: true, delay: 0 };
                                }
                            }else if (isChangementTour) {
                                setValueChangeRemainingTime(false)
                                setIsTimePersonne(true)
                                setInitCurrentPerson(true)
                                setIsChangementTour(false)
                            }else if (isEntracte) {
                                setValueChangeRemainingTime(false)
                                setIsEntracte(false)
                                setIsTimePersonne(false)
                                setIsChangementTour(true)
                            }
                        }}
                        onUpdate={(newTime) => {
                            setCurrentTime(newTime)
                            // if (isChangementTour) {
                            //     setIsPlaying(true)
                            // }
                        }}
                    >
                        {/*{renderTime}*/}
                    </CountdownCircleTimer>
                    {/*<Button variant={"secondary"} style={{*/}
                    {/*    height: "fit-content",*/}
                    {/*    position: "absolute",*/}
                    {/*    top:200,*/}
                    {/*    left:120,*/}
                    {/*    zIndex:60*/}
                    {/*}} className={`fa me-5 ${!isPlaying ? "fa-play" : "fa-pause" }`} onClick={() => setIsPlaying(!isPlaying)}></Button>*/}
                </div>
            </div>
        </div>
    );
}

export default CountDownCircleTimer;
