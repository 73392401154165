import Swal from "sweetalert2/dist/sweetalert2.js";
import withReactContent from "sweetalert2-react-content";

const sweetAlert = withReactContent(Swal);

export const BtSwal = sweetAlert.mixin({
    width: 400,
    heightAuto: false,
    padding: '2.5rem',
    buttonsStyling: false,
    customClass: {
        confirmButton: 'btn btn-secondary',
        cancelButton: 'btn btn-secondary',
        denyButton: 'btn btn-danger',
        input: 'form-control',
    },
});