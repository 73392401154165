import {Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {emailPattern} from "../../../../../functions/patterns";
import CiviliteSelection from "../../../../personne/CiviliteSelection/CiviliteSelection";

function AccompagnantEditForm({ setValueInitial, getValues, setShowEditModal, index, accompagnant }) {

    const { register, control, handleSubmit,
        formState: { errors } } = useForm({defaultValues: accompagnant});

    function onSave(data){
        // let tmp = (getValues("tabAccompagnants."+index).find(obj => obj.destinataire.personne.id == accompagnant.destinataire.personne.id))
        let tmp = (getValues("tabAccompagnants."+index))
        tmp.destinataire.personne.prenom = data.destinataire.personne.prenom;
        tmp.destinataire.personne.nom = data.destinataire.personne.nom;
        tmp.destinataire.personne.email = data.destinataire.personne.email;
        tmp.destinataire.personne.civilite = data.destinataire.personne.civilite;
        setValueInitial("tabAccompagnants."+index, tmp)
        setShowEditModal(false)
    }

    return <div>
        <Row>
            <Form.Group className={'mb-3'}>
                <Form.Label className='required'>Civilité</Form.Label>
                <CiviliteSelection isSolid={false} control={control} name={`destinataire.personne.civilite`} rules={{ required: true }} />
                {errors.tabAccompagnants && errors.destinataire?.personne?.civilite && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
            </Form.Group>
            <Col sm={6}>
                <FormGroup as={Col}>
                    <Form.Label>Prenom</Form.Label>
                    <Form.Control maxLength={20} className='form-control' {...register(`destinataire.personne.prenom`, {required : true})} />
                    {errors.tabAccompagnants && errors.destinataire?.personne?.prenom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup as={Col}>
                    <Form.Label>Nom</Form.Label>
                    <Form.Control maxLength={50} className='form-control' {...register(`destinataire.personne.nom`, {required : true})} />
                    {errors.tabAccompagnants && errors.destinataire?.personne?.nom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup as={Col}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control maxLength={50} className='form-control' {...register(`destinataire.personne.email`, {required : true, pattern: emailPattern()})} />
                    {errors.tabAccompagnants && errors.destinataire?.personne?.email && <Form.Text className='text-danger'>Ce champ est requis (merci d'indiquer un email valide)</Form.Text>}
                </FormGroup>
            </Col>
        </Row>
        <div id="containerValidButton" className='mt-2 d-flex justify-content-center'>
            <Button
                variant='secondary'
                className='px-20'
                onClick={handleSubmit(onSave)}
            >Modifier l'accompagnant</Button>
        </div>
    </div>;
}

export default AccompagnantEditForm;