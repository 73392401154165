import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

export default function useChangeIsMaitreDuTempsSuppleant(props) {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);

    return useMutation(isMaitreDuTempsSuppleant, props);
}

function isMaitreDuTempsSuppleant(rencontreSpeedBusiness) {
    const id = rencontreSpeedBusiness.id;

    if (id != null)
        return api.post(`participantRencontreSpeedBusiness/${id}/isMaitreDuTempsSuppleant`, { json: {
                isMaitreDuTempsSuppleant:rencontreSpeedBusiness.isMaitreDuTempsSuppleant
            } }).json();
}