import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useListeCategorie(libelle) {

    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = {
        data: "libelle",
        searchable: true,
        orderable: true,
        search: {
            value: libelle,
            regex: false,
        }
    }

    filters.columns[1] = {
        data: "id",
        searchable: true,
        orderable: true,
        search: {
            value: "",
            regex: false,
        }
    }

    filters.order[0] = {
        column:1,
        dir: "asc"
    }

    return useQuery(
        ['categorieSujet'+libelle],
        async () => {
            return api.get(`categorieSujetForum`, {
                searchParams: Qs.stringify({
                    start: 0,
                    length: 100,
                    ...filters,
                }, { arrayFormat: 'brackets' }),
            }).json();
        }
    );
}