import {useMutation, useQuery} from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useFilterIntoTwoDates(props) {

    return useMutation(exportExort, props);

    function exportExort(tabDates) {
        let filters = {}

        if (tabDates!=null){
            filters = {
                dateDebut : tabDates[0],
                dateFin : tabDates[1]
            };
        }

        console.log(filters)

        return api.post('facture/exporter', {
            json: {
                ...filters
            },
            enabled: tabDates!=null,
        }).json();
    }
}