import image from './icons/singleText.svg';

export const singleTextModule = {
    image: image,
    defaultContent: {
        richTexts: {
            richText: '<h1>Titre</h1><p>Purus sit amet luctus venenatis lectus magna. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Ultricies mi quis hendrerit dolor magna eget est lorem. Orci sagittis eu volutpat odio. Justo donec enim diam vulputate ut pharetra sit amet.</p>',
        },
    },
    template:
        `<tr id="colorPicker">
            <td valign="top" align="center">
                <table width="100%" cellspacing="0" cellpadding="0" border="0">
                    <tbody><tr>
                        <td valign="top" align="center">
                            <table class="flexibleContainer" width="600" cellspacing="0" cellpadding="0" border="0">
                                <tbody><tr>
                                    <td class="flexibleContainerCell" id="paddingTemplate" width="600" valign="top" align="center">
                                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                            <tbody><tr>
                                                <td id="richText" class="textContent" valign="top">
                                                    <h3>Title</h3>
                                                    <br>
                                                    A kitten or kitty is a juvenile domesticated cat. A feline litter usually consists of two to five kittens. To survive, kittens need the care of their mother for the first several weeks of their life. Kittens are highly social animals and spend most of their waking hours playing and interacting with available companions.
                                                </td>
                                            </tr>
                                        </tbody></table>
                                    </td>
                                </tr>
                            </tbody></table>
                        </td>
                    </tr>
                </tbody></table>
            </td>
        </tr>`,
}