import {Button, Col, Form, InputGroup, Modal, Row} from "react-bootstrap";
import React, {useRef, useState} from "react";
import invitationsProxy from "../../../../proxies/invitations";
import {useSnapshot} from "valtio";
import {useForm} from "react-hook-form";
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";
import contentCss from "!!raw-loader!tinymce/skins/content/default/content.min.css";
import {iconLienPj} from "../../../../assets/icons/svgTinymce";
import {linkUrlTinyMce} from "../../../../functions/linkUrlTinyMce";
import fileManagerProxy from "../../../../proxies/fileManager";
import {imageTinyMce} from "../../../../functions/ImageTinyMce";
import {Editor} from "@tinymce/tinymce-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import ModalAddllustrationInscriptionLibre
    from "./ModalAddllustrationInscriptionLibre";
import useChangeInvitation from "../../../../requests/communication/invitations/useChangeInvitation";
import ModalPreviewInscriptionLibre
    from "./ModalPreviewInscriptionLibre";

function InscriptionLibreModal(){
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const editorRef = useRef();
    const [stateModal, setStateModal]= useState(false);
    const changeInvitation = useChangeInvitation();
    const { register, control, handleSubmit
        ,
        formState:
            { errors } }
        = useForm(
            // {defaultValues: invitationsProxy.invitation,}
    );

    return <Modal
        show={invitationsSnapshot.inscriptionLibre.show}
        onHide={() => invitationsProxy.inscriptionLibre.show = false}
        size='lg'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Page d'inscription libre</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <p>L'inscription libre vous permet de partager l'invitation sur vos réseaux sociaux en copiant le lien ci dessous. Cela offre aux personnes qui vous suivent la possibilité de s'inscrire à votre évènement. </p>
                <p>Cette page reprend le sujet, la date et l'adresse. Nous vous conseillons d'ajouter une illustration et une description pour mettre en avant votre évènement.</p>
                <div className="d-flex">
                    <InputGroup className="mb-3">
                        <Form.Control
                            disabled={true}
                            value={invitationsProxy.invitation?.lienInscriptionLibreReduit}
                        />
                        <Button onClick={(event) => {
                            navigator.clipboard.writeText(invitationsProxy.invitation?.lienInscriptionLibreReduit).then(() => {
                                event.target.innerHTML = "";
                                event.target.classList.add('fa', 'fa-check');
                            })
                        }}  variant="secondary" id="button-addon2">
                            Copier
                        </Button>
                    </InputGroup>
                </div>
                {!invitationsSnapshot?.invitation?.uploadInscriptionLibre?.urlOriginale && <Form.Group>
                    <Button
                        variant='primary'
                        className='px-20 m-auto d-block'
                        onClick={() => setStateModal(true)}
                    >
                        <FontAwesomeIcon icon={faPlus} className='me-2'/>
                        Ajouter une illustration
                    </Button>
                </Form.Group>}
            </div>
            <Row className="position-relative">
                <Col sm={12} className='m-auto'>
                    <img style={{
                        boxShadow: "2px 1px 10px 3px",
                        margin: "10px auto",
                        maxWidth: "100%",
                        display: "block",
                        border: "solid 1px lightgrey"
                    }}
                         src={invitationsSnapshot?.invitation?.uploadInscriptionLibre?.urlOriginale} alt=""/>
                </Col>
                {invitationsSnapshot?.invitation?.uploadInscriptionLibre?.urlOriginale && <>
                    <Button
                        variant='primary'
                        style={{
                            top:10,
                            right:10,
                            width: "fit-content"
                        }}
                        className='ms-2 position-absolute'
                        onClick={() => {
                            setStateModal(true)
                        }}
                    >
                        <FontAwesomeIcon icon={faPen} className=''/>

                    </Button>
                    <Button
                    variant='secondary'
                    style={{
                        top:10,
                        right:80,
                        width: "fit-content"
                    }}
                    className='ms-2 position-absolute'
                    onClick={() => {
                        invitationsProxy.invitation.uploadInscriptionLibre = null;
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} className=''/>

                </Button></>}
                <Col sm={12} className='mt-5 mt-sm-0'>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Editor
                            id={"tinyMCEARecup#descriptionInscriptionLibre#"}
                            onInit={(evt, editor) => editorRef.current = editor}
                            // inline={true}
                            initialValue={invitationsProxy?.invitation?.descriptionInscriptionLibre}
                            init={{
                                content_style: [contentUiCss, contentCss, '.mce-content-body { height: 200px; }'].join('\n'),
                                skin: false,
                                language: 'fr_FR',
                                language_url: '/lang/tinymce/fr_FR/langs/fr_FR.js',
                                menubar: false,
                                plugins: ["link autolink image code table textcolor lists"],
                                toolbar: 'undo redo | ' +
                                    'bold italic underline | bullist ' +
                                    '| link unlink',
                                setup: function (editor) {
                                    editor.ui.registry.addIcon('link', iconLienPj);
                                    editor.on('init', function (e) {
                                        editor.windowManager.oldOpen = editor.windowManager.open;
                                        editor.windowManager.open = function (t, r) {
                                            var modal = editor.windowManager.oldOpen.apply(this, [t, r]);  // call original
                                            if (this && t.title === "Insert/Edit Link") {
                                                linkUrlTinyMce(t, isValidHttpUrl, null, uploadFile);
                                            } else if (t.title === "Insert/Edit Image") {
                                                fileManagerProxy.baseId = "tinyMCEARecup#descriptionInscriptionLibre#";
                                                imageTinyMce(isValidHttpUrl, null, uploadFile);
                                            }
                                            return modal;

                                        }
                                    });
                                }
                            }}
                        />
                    </Form.Group>
                </Col>
                <button
                    style={{width: "fit-content"}}
                    onClick={() => {
                        invitationsProxy.invitation.descriptionInscriptionLibre = (editorRef.current.getContent())
                        changeInvitation.mutate(invitationsProxy.invitation)
                        window.open(invitationsProxy.invitation?.lienInscriptionLibreReduit, '_blank');
                        //Aperçu dans une modale ancienne version
                        // invitationsProxy.inscriptionLibre.showPreview = true
                }} className="d-block mt-4 btn btn-secondary fa fa-eye">
                </button>
                <Button
                    style={{width: "fit-content"}}
                    variant='secondary'
                    className='px-20 ms-auto d-block mt-4'
                    onClick={() => {
                        invitationsProxy.invitation.descriptionInscriptionLibre = (editorRef.current.getContent())
                        changeInvitation.mutate(invitationsProxy.invitation)
                        invitationsProxy.inscriptionLibre.show = false;
                    }}
                >
                    Enregistrer
                </Button>
            </Row>
        </Modal.Body>
        <ModalAddllustrationInscriptionLibre
            stateModal={stateModal}
            setStateModal={setStateModal}
        />
        <ModalPreviewInscriptionLibre/>
    </Modal>
}

export default InscriptionLibreModal