import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";

export default function useIncrementeNbVues(id,libelle) {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);


    return useMutation((tabEtats) => {
        return api.post('sujetForum/'+id+'/incrementerNbVues').json();
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries("sujetForumByCategories"+libelle);
            queryClient.invalidateQueries("sujetForum");
            queryClient.invalidateQueries(id);
            snapAuth.websocket.send("sujetForum")
            snapAuth.websocket.send("sujetForumByCategories"+libelle)

        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}