import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Accordion, Alert, Button, Col, Form, Row, Spinner} from "react-bootstrap";
import { useForm } from "react-hook-form";
import {useQueryClient} from "react-query";
import React, {useEffect, useMemo, useState} from "react";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import {emailPattern, telPattern} from "../../../functions/patterns";
import {useDebounce} from "use-debounce";
import SearchBar from "../../../components/SearchBar/SearchBar";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";
import useGetResponsablePlaceLimite
    from "../../../requests/parametres/responsablePlaceLimite/useGetResponsablePlaceLimite";
import useDeleteResponsablePlaceLimite
    from "../../../requests/parametres/responsablePlaceLimite/useDeleteResponsablePlaceLimite";
import useChangeResponsablePlaceLimite
    from "../../../requests/parametres/responsablePlaceLimite/useChangeResponsablePlaceLimite";

function ResponsablePlaceLimiteSettings() {
    const { register, watch, setValue } = useForm();
    const search = watch('search')
    const [debouncedFilter] = useDebounce(search, 500);
    const responsable = useGetResponsablePlaceLimite(debouncedFilter);
    const queryClient = useQueryClient();
    const [activeKey, setActiveKey] = useState();
    const [hiddenEditLines, setHiddenEditLines] = useState(false);
    const [hiddenAdd, setHiddenAdd] = useState(false);
    const deleteResponsable = useDeleteResponsablePlaceLimite();
    const [update, setUpdate] = useState(0);
    const snapAuth = useSnapshot(auth);
    const changeResponsable = useChangeResponsablePlaceLimite({
        onSuccess: () => {
            queryClient.invalidateQueries('responsablePlaceLimite');
            snapAuth.websocket.send("responsablePlaceLimite")
            setActiveKey(null);
            setHiddenAdd(false)

        },
        onError: (err) => {
            BtSwal.fire(err.message, '', 'error')
        }
    });

    function onSave(data) {
        changeResponsable.mutate(data);
    }

    function toggleAccordionKey(eventKey) {
        if (activeKey == eventKey) {
            setActiveKey(null);
            setHiddenAdd(false)
        }
        else {
            setActiveKey(eventKey);
            setHiddenAdd(true)
        }
    }

    useEffect(() => {
        document?.getElementById("searchBarId")?.focus();
    }, [responsable.isSuccess])

    if (responsable.isError)
        return <Alert variant='danger'>{responsable.error?.message}</Alert>;

    if (responsable.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    return <Accordion activeKey={activeKey} className='mt-7'>
        <div className='d-flex flex-stack flex-wrap'>
            <SearchBar
                solid
                {...register('search')}
                onClear={search?.length > 0 ? () => {
                    setValue('search', '');
                } : null}
            />
            <div className='d-flex'>
                <div hidden={hiddenAdd ? hiddenAdd : hiddenEditLines} className='mx-auto fw-bold'>
                    <Button
                        variant={"secondary"}
                        className='px-20'
                        onClick={() => {
                            toggleAccordionKey('new')
                            setHiddenEditLines(true);
                            setUpdate(update+1)
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} className='me-2' />
                        Ajouter un responsable
                    </Button>
                </div>
            </div>
        </div>
        {responsable.data?.data?.map(resp => {
            return <div hidden={hiddenEditLines} className='bg-light my-2 p-3 rounded' key={resp.id}>
                <div className='d-flex align-content-center align-items-center'>
                    <div className='me-auto fw-bold'>
                        {resp.prenomNom}
                        <span className='fw-bolder text-info'> ({resp.email})</span>
                    </div>
                    <button
                        onClick={() => toggleAccordionKey(resp.id)}
                        className='btn-sm btn btn-quaternaire p-1 ps-2 pe-2'
                    >
                        <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button
                        onClick={() => BtSwal.fire({
                            title: 'Êtes vous sur de vouloir supprimer le responsable '+resp.prenomNom+" ?",
                            showDenyButton: true,
                            confirmButtonText: `Oui`,
                            denyButtonText: `Annuler`,
                        }).then((result) => {
                            if (result.isConfirmed){
                                deleteResponsable.mutate(resp.id);
                                BtSwal.fire('Le responsable a été supprimé!', '', 'success')
                                setHiddenAdd(false)
                            } else if (result.isDenied) {
                                BtSwal.fire('Le responsable n\'a pas été supprimé', '', 'info')
                            }
                        })}
                        className='btn-sm btn btn-secondary p-1 ps-2 pe-2'
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
                <Accordion.Collapse eventKey={resp.id}>
                    <div className='mt-3'>
                        <div className='separator' />
                        <ResponsableForm responsable={resp} update={update} setHiddenAdd={setHiddenAdd} onSave={onSave} />
                    </div>

                </Accordion.Collapse>
            </div>;
        })}
        <div className='mt-10'>
            <Accordion.Collapse eventKey={'new'}>
                <div className='bg-light rounded mt-3 pt-3 mx-10'>
                    <ResponsableForm onSave={onSave} update={update} setHiddenEditLines={setHiddenEditLines} toggleAccordionKey={toggleAccordionKey}  />
                </div>
            </Accordion.Collapse>
        </div>
    </Accordion>;
}

function ResponsableForm({ responsable, onSave, setHiddenEditLines, update, setHiddenAdd, toggleAccordionKey }) {

    const { register, reset, handleSubmit, clearErrors, setError, control, setValue, getValues,
        formState: { errors } } = useForm({
        defaultValues: responsable ?? {
            prenomNom : null,
            email : null,
            tel : null
        },
    });

    useEffect(() => {
        reset(responsable)
    }, [responsable, reset, update])

    function handleSubmitSave(data) {
        if (setHiddenEditLines) {
            setHiddenEditLines(false)
        }
        if (setHiddenAdd){
            setHiddenAdd(false)
        }


        onSave(data)
    }

    return <div className='mt-5 mx-10'>
        <Form.Group>
            <Form.Label>Prénom nom</Form.Label>
            <Form.Control maxLength={50} {...register('prenomNom', {required : true})} />
            {errors.prenomNom && <Form.Text className='text-danger'>Prénom nom requis</Form.Text>}
        </Form.Group>
        <Row>
            <Col>
                <Form.Group className='mt-5'>
                    <Form.Label>Email</Form.Label>
                    <div className="input-group input-group mb-5">
                        <Form.Control maxLength={150} {...register('email', {required : true, pattern : emailPattern()})} />
                    </div>
                    {errors.email && <Form.Text className='text-danger'>Email incorrect</Form.Text>}
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className='mt-5'>
                    <Form.Label>Téléphone</Form.Label>
                    <Form.Control
                        maxLength={14}
                        onKeyUp={(e) => {
                            e.target.value = e.target.value.replace(/[\.,\-\+]/g, '');
                            let countNumber = 0;
                            for (let i=0; i<e.target.value.length; i++ ){
                                if (!isNaN(parseInt(e.target.value[i]))){
                                    countNumber = countNumber +1;
                                }
                            }
                            if (countNumber > 10){
                                setError("tel")
                            }else if (countNumber != 10 && countNumber != 0){
                                setError("tel")
                            }else {
                                clearErrors("tel")
                            }
                        }}
                        className='form-control' {...register('tel', { pattern : telPattern(), required : true})} />
                    {errors.tel && <Form.Text className='text-danger'>Merci d'indiquer un numéro valide</Form.Text>}
                </Form.Group>
            </Col>
        </Row>
        <div className='mt-2 d-flex justify-content-center'>
            <Button
                variant='secondary'
                className='btn-md mb-2'
                onClick={handleSubmit(handleSubmitSave)}
            >{responsable?.id != null ? 'Enregistrer' : 'Ajouter'}</Button>
            <span className="m-2"></span>
            {responsable?.id == null && <Button
                variant='danger'
                className='btn-md mb-2'
                onClick={() => {
                    setHiddenEditLines(false)
                    toggleAccordionKey('new')
                }}
            >Annuler</Button>
            }
        </div>
    </div>;
}

export default ResponsablePlaceLimiteSettings;