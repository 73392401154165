import {useEffect, useMemo, useState} from "react";
import {useController} from "react-hook-form";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise"
import useGetPrefixe from "../../../requests/communication/useGetPrefixe";
import {getLoadOptionsPrefixeTel} from "../../../requests/selectionRequests/prefixeTel";
import {useSnapshot} from "valtio";
import annuaireProxy from "../../../proxies/annuaire";

function PrefixeTelSelection({ control, name, rules }) {
    const getPrefixe = useGetPrefixe();
    const snapPersonne = useSnapshot(annuaireProxy);
    const { field } = useController({ control, name, rules });
    const data = useMemo(() => {
        return getPrefixe.data?.data?.map(exp => ({
            id: exp.prefixe,
            title: `${exp.pays} (+${exp.prefixe})`,
        }));
    }, [getPrefixe.data]);
    const [selectedValue, setSelectedValue] = useState(field.value != null ? {id:field.value,title:field.value} : null);
    useEffect(() => {
        if (field.value && field.value != selectedValue?.id && snapPersonne.init){
            setSelectedValue( {id:field.value,title:field.value} )
            annuaireProxy.init = false;
        }
    }, [field.value, snapPersonne.init])
    const handleChange = value => {
        setSelectedValue(value);
    }
    const loadOptions = getLoadOptionsPrefixeTel();
    const debouncedLoadOptions = debounce(loadOptions, 500)

    return <AsyncSelect
        placeholder={"Choisir un prefixe"}
        noOptionsMessage={() => "Aucun résultat"}
        loadingMessage={() => "Chargement..."}
        styles={{
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? 'white' : '#716d66',
                backgroundColor: state.isSelected ? 'var(--primary-color)' : 'white',
                "&:hover":{
                    backgroundColor: !state.isSelected ? 'var(--primary-color-light)' : "var(--primary-color)",
                }
            }),
            control: (base) => ({
                ...base,
                backgroundColor : "#f8f6f2",
                // borderRadius : ".95rem",
                color: '#716d66',
                height: "100%",
                borderColor: "#f8f6f2"
            }),
        }}
        formatOptionLabel={categorie => (
            <div className="categorie-option">
                <span>{categorie?.title}</span>
            </div>
        )}
        cacheOptions
        defaultOptions={data}
        value={selectedValue}
        getOptionLabel={e => e.title}
        getOptionValue={e => e.id}
        loadOptions={debouncedLoadOptions}
        onChange={ (selected) => {
            handleChange(selected)
            field.onChange(selected.id)
        }}
    />
}

export default PrefixeTelSelection;