import { useQuery } from "react-query";
import apiPublic from "../../../services/apiPublic";

export default function useGetColorFromAgence(libelleAgence) {
    return useQuery(
        ['themeUi'],
        async () => {
            return apiPublic.post(`themeUi/FromAgence`, {
                json: {
                    "libelleAgence":libelleAgence
                },
            }).json();
        }
    );
}