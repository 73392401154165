import { useMutation } from "react-query";
import api from "@services/api";

export default function useGetCostSms(props) {
    return useMutation(getCostSms, props);
}

function getCostSms(data) {
    return api.post(`sms/${data.id}/cout`, { json: {
            seulementEnAttenteEnvoi : data.isSeulementEnAttente
        } }).json();
}
