import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

export default function useChangeSms() {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);

    return useMutation(changeSms, {
        onSuccess: () => {
            queryClient.invalidateQueries('sms');
            snapAuth.websocket.send("sms")
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function changeSms(sms) {
    const id = sms.id;

    if (id != null)
        return api.put(`sms/${id}`, { json: sms }).json();
    return api.post(`sms`, { json: sms }).json();
}