import { useMutation } from "react-query";
import api from "@services/api";
import auth from "@services/auth";

export default function useChangeColorAgence(props) {
    return useMutation(changeColors, props);
}

function changeColors(colors) {
    const id = colors?.id;

    if (id != null)
        return api.put(`themeUi/${id}`, { json: colors }).json();
    return api.post('themeUi', {
        json: {
            ...colors,
            idAgence: auth?.agence?.id,
        }
    }).json();
}