import { Alert, Dropdown, Spinner } from "react-bootstrap";
import useGetBlocsModalite from "../../../../requests/communication/invitations/blocsInvitation/useGetBlocsModalite";


function BlocModaliteSelection({ children, className, onChange }) {

    const blocsModalite = useGetBlocsModalite();

    return <Dropdown>
        <Dropdown.Toggle variant='white' className={`${className ?? ''} hide-after`}>
            {children}
        </Dropdown.Toggle>
        <Dropdown.Menu className='fs-6 w-400px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
            {blocsModalite.isLoading && <div className='text-center my-5'>
                <Spinner animation='border' />
            </div>}
            {blocsModalite.isError &&
                <Alert variant='danger' className='m-2'>
                    {blocsModalite.error?.message ?? 'Erreur'}
                </Alert>
            }
            {blocsModalite.isSuccess &&
                <div className='mh-300px scroll-y'>
                    {blocsModalite.data?.data?.map(header => <div className='menu-item px-3' key={header.id}>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => onChange(header)}
                        >
                            {header.nom}
                        </Dropdown.Item>
                    </div>)}
                </div>
            }
        </Dropdown.Menu>
    </Dropdown>;

}

export default BlocModaliteSelection;