import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";

export default function useDeleteSujet(libelleCat) {

    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    return useMutation(deleteSujet, {
        onSuccess: () => {
            queryClient.invalidateQueries('sujetForumByCategories'+libelleCat);
            snapAuth.websocket.send('sujetForumByCategories'+libelleCat)

        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

}

function deleteSujet(id) {

    if (id != null)
        return api.delete(`sujetForum/${id}`).json();

}