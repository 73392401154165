import {Button, Card, Dropdown, Modal} from "react-bootstrap";
import {useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faWrench} from "@fortawesome/free-solid-svg-icons";
import MetaTable from "@components/MetaTable/MetaTable";
import {ReactComponent as Art005} from "@assets/icons/art005.svg";
import {ReactComponent as Gen027} from "@assets/icons/gen027.svg";
import {useQueryClient} from "react-query";
import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";
import SearchBar from "../../../components/SearchBar/SearchBar";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";
import publicationMobileProxy from "../../../proxies/publicationMobile";
import EditModal from "./EditModal/EditModal";
import useDeletePublication from "../../../requests/communication/publications/useDeletePublication";

function PublicationsMobileView() {
    const queryClient = useQueryClient();
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const [searchFilter] = useDebounce(search, 300);
    const snapAuth = useSnapshot(auth);
    const publicationMobileSnap = useSnapshot(publicationMobileProxy);
    const deletePublication = useDeletePublication();

    const columns = useMemo(() => [
        {
            Header: () => null,
            id: 'wrench',
            Cell: ({ row }) => <Dropdown>
                <Dropdown.Toggle className='hide-after btn-icon btn-quaternaire w-30px h-30px'>
                    <FontAwesomeIcon color={"black"} icon={faWrench} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='fs-6 w-350px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                if (snapAuth.tabIdPublicationVerrou.indexOf((row.original.id)) !== -1){
                                    BtSwal.fire("Attention", "La publication est verrouillé en édition par un autre utilisateur", "warning")
                                }else {
                                    publicationMobileProxy.showEditPublication(row.original)
                                }
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Art005 /></span>
                            Modifier
                        </Dropdown.Item>
                    </div>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                BtSwal.fire({
                                    title: 'Êtes vous sur de vouloir supprimer la publication ?',
                                    showDenyButton: true,
                                    confirmButtonText: `Oui`,
                                    denyButtonText: `Annuler`,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        deletePublication.mutate(row.original.id);
                                        BtSwal.fire('La publication a été supprimé!', '', 'success')
                                    } else if (result.isDenied) {
                                        BtSwal.fire('La publication n\'a pas été supprimé', '', 'info')
                                    }
                                })
                            }
                        }
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Gen027 /></span>
                            Supprimer
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>,
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            minWidth: 70,
            maxWidth: 70,
            Cell:({value, row}) => {
                if (snapAuth.tabIdPublicationVerrou.indexOf((row.original.id)) !== -1){
                    return <span className="d-flex align-items-center">{value} <span className="ms-4 badge badge-circle badge-secondary"></span></span>
                }
                return value;
            }
        },
        {
            Header: 'Titre',
            accessor: 'titre',
            minWidth: 300,
        },
        {
            Header: 'Date de début',
            accessor: 'dateDebut',
            minWidth: 150,
            maxWidth: 150,
        },
        {
            Header: 'Date de fin',
            accessor: 'dateFin',
            minWidth: 150,
            maxWidth: 150,
        }

    ], [snapAuth.tabIdPublicationVerrou]);

    // if (headers.isLoading || footers.isLoading || categories.isLoading || expediteurs.isLoading){
    //     return <LoadingView/>
    // }


        return <>
            <Card>
                <Card.Body>
                    <div className='d-flex flex-stack flex-wrap'>
                        <SearchBar
                            solid
                            {...register('search')}
                            onClear={search?.length > 0 ? () => {
                                setValue('search', '');
                            } : null}
                        />
                        <div className='mb-5'>
                            <Button variant='secondary' onClick={() => {
                                publicationMobileProxy.showEditPublication(null)
                            }}>
                                <FontAwesomeIcon icon={faPlus} className='me-2' />
                                Créer une publication mobile
                            </Button>
                        </div>
                    </div>
                    <div>
                        <MetaTable
                            className='mt-5'
                            height={500}
                            url='publicationMobile'
                            keys={['publicationMobile']}
                            columns={columns}
                            search={searchFilter}
                        />
                    </div>
                </Card.Body>
            </Card>
            <EditModal/>
        </>
}


export default PublicationsMobileView;