import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

export default function useChangePublication() {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    return useMutation(changePublication, {
        onSuccess: () => {
            queryClient.invalidateQueries('publicationMobile');
            snapAuth.websocket.send("publicationMobile")
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function changePublication(publication) {
    const id = publication.id;

    if (id != null)
        return api.put(`publicationMobile/${id}`, { json: publication }).json();
    return api.post(`publicationMobile`, { json: publication }).json();
}