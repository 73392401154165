import { useMutation } from "react-query";
import api from "@services/api";
import auth from "@services/auth";
import { showErrorAlert } from '@utils/alerts/showErrorAlert';
import {useParams} from "react-router-dom";

export default function useLogin() {
    const currentUrl = window.location.href;
    const parts = currentUrl.split('/');
    const agence = parts[3];
    return useMutation(({ identifiant, motDePasse }) => {
        const credentials = Buffer.from(`${identifiant}:${motDePasse}`, 'utf8').toString('base64');
        return api.post('utilisateur/connexion', {
            headers: { 'Authorization': `Basic ${credentials}`,
            'Agence' : agence,
            'Version' : "1.101"},
        }).json();
    }, {
        onSuccess: data => {
            auth.login({
                accessToken: data?.accessToken?.jwt,
                refreshToken: data?.refreshToken?.jwt,
                utilisateur: data?.utilisateur,
                parametreFacturation: data?.parametreFacturation,
                agence: data?.agence,
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur de connexion", err.message);
        },
    });
}