import {useEffect, useState} from "react";
import webpage from 'grapesjs-preset-webpage';
import plugin from 'grapesjs-blocks-basic';
import 'grapesjs/dist/css/grapes.min.css';
import fr from 'grapesjs/locale/fr';
import grapesjs from 'grapesjs';
import useUploadFile from "../../requests/upload/useUploadFile";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {Button} from "react-bootstrap";
import {useSnapshot} from "valtio";
import publicationMobileProxy from "../../proxies/publicationMobile";
import {BtSwal} from "../../utils/alerts/sweetAlert";

function GrapesJS({getValues, onChangeForPublication}){
    const [editorGjs, setEditorGjs] = useState(null);
    const publicationMobileSnap = useSnapshot(publicationMobileProxy);
    const uploadFile = useUploadFile({
        type: 'userfile',
        onSuccess: (data) => {

        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'upload de l'image", err.message);
        },
    });

    useEffect(() => {
        var editor = grapesjs.init({
            container: '#gjs',
            height: "100%",
            plugins: [plugin, webpage],
            pluginsOpts: {
                column1: {
                    addBasicStyle: false,
                },
            },
            assetManager: {
                storageType  	: '',
                storeOnChange  : true,
                storeAfterUpload  : true,
                upload: 'images',        //for temporary storage
                assets    	: [ ],
                uploadFile: function (e) {
                    var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
                    Array.from(files).forEach((data) => {
                        uploadFile.mutateAsync(data).then(tmp => {
                            editor.AssetManager.add({
                                type:"image",
                                src:tmp?.urlOriginale,
                            }); //adding images to asset
                        })
                    })
                },
            },
            styleManager: {
                // sectors: [{
                //     name: 'General',
                //     open: false,
                //     buildProps: ['float', 'display', 'position', 'top', 'right', 'left', 'bottom']
                // },{
                //     name: 'Dimension',
                //     open: false,
                //     buildProps: ['width', 'flex-width', 'height', 'max-width', 'min-height', 'margin', 'padding'],
                //     properties: [{
                //         id: 'flex-width',
                //         type: 'integer',
                //         name: 'Width',
                //         units: ['px', '%'],
                //         property: 'flex-basis',
                //         toRequire: 1,
                //     }]
                // },{
                //     name: 'Decorations',
                //     open: false,
                //     buildProps: ['border-radius-c', 'background-color', 'border-radius', 'border', 'box-shadow', 'background'],
                // }]
            },
            /*styleManager: {
              sectors: [
                {
                  name: 'My sector',
                  properties: [
                    {
                      type: 'my-custom-prop',
                      property: 'font-size',
                      default: '15',
                      min: 10,
                      max: 70,
                    },
                  ],
                },
              ],
            },*/
            blockManager: {},
            i18n: {
                locale: 'fr', // default locale
                detectLocale: true, // by default, the editor will detect the language
                localeFallback: '', // default fallback
                messages: { fr },
            },
        });
        editor.StyleManager.removeSector('general');
        editor.StyleManager.addSector( 'positionnement',
            {
                name: 'Positionnement',
                open: false,
                buildProps: ['float', 'display', 'position', 'top', 'right', 'left', 'bottom']
            })
        // editor.StyleManager.removeProperty('general', 'float');
        editor.Panels.removeButton('devices-c', "set-device-desktop");
        editor.Panels.getButton('devices-c', "set-device-mobile").active = true;
        editor.Panels.getButton('options', "sw-visibility").active = true;
        // editor.Devices.select('mobilePortrait')
        // document.getElementsByClassName()
        // editor.Panels.getButton('devices-c', "set-device-desktop")
        // console.log(editor.Panels.getButton("devices-c").buttons)

        setEditorGjs(editor)

    }, [])

    useEffect(() => {
        if (publicationMobileSnap.templateSelected && editorGjs){
            setTimeout(() => editorGjs.setComponents(publicationMobileSnap.templateSelected), 500)
        }
    }, [publicationMobileSnap.templateSelected, editorGjs])

    useEffect(() => {
        if (publicationMobileSnap.publicationMobile?.contenu && editorGjs){
            setTimeout(() => editorGjs.setComponents(publicationMobileSnap.publicationMobile.contenu), 500)
        }
    }, [publicationMobileSnap.publicationMobile, editorGjs])

    return <>
        <Button
            title={"enregistrer"}
            className="fa fa-2x fa-save mb-2 ms-2 position-absolute"
            style={{top:70, left:25, zIndex:2}}
            variant={"secondary"}
            onClick={() => {
            let style = '<head><style>'+editorGjs.getCss()+'</style></head>'
            onChangeForPublication("contenu", style+editorGjs.getHtml())
            publicationMobileProxy.editGrapesModal.show = false;
            publicationMobileProxy.templateSelected = null;
            // onChangeForPublication("contenu", {
            //     html : editorGjs.getHtml(),
            //     css : editorGjs.getCss()
            // })
        }}>

        </Button>
        <Button
            title={"retour en arrière"}
            className="fa fa-2x fa-reply mb-2 ms-2 position-absolute"
            style={{top:70, left:100, zIndex:2}}
            variant={"secondary"}
            onClick={() => {
                BtSwal.fire({
                    title: `Êtes-vous sûr de vouloir retourner en arrière ? Toutes les modifications non sauvegardées seront perdues.`,
                    showDenyButton: true,
                    confirmButtonText: `Oui`,
                    denyButtonText: `Annuler`,
                    icon: 'warning',
                }).then((result) => {
                    if (result.isConfirmed){
                        publicationMobileProxy.editGrapesModal.show = false;
                        publicationMobileProxy.templateSelected = null;
                    }
                })

            }}>

        </Button>
        <div id="gjs" style={{height:"100vh"}}></div>
    </>
}

export default GrapesJS;