import { useQuery } from "react-query";
import apiPublic from "../../../services/apiPublic";

export default function useAuthReceptionniste(ref) {
    if (ref){
        return useQuery(
            ['receptionniste', ref],
            async () => {
                return apiPublic.get(`receptionniste/authentification?ref=`+encodeURIComponent(ref)).json();
            }
        );
    }
}