import {Button, Col, Form, InputGroup, Row, Stack} from "react-bootstrap";
import PartenaireSettings from "./PartenaireSettings/PartenaireSettings";
import {useForm} from "react-hook-form";
import {useEffect, useMemo, useRef, useState} from "react";
import {useDebounce} from "use-debounce";
import useSearchEntreprise from "../../../../../requests/entreprise/useSearchEntreprise";
import FormTypeahead from "../../../../../components/form/FormTypeahead/FormTypeahead";
import FormImagePicker from "../../../../../components/form/FormImagePicker/FormImagePicker";
import FormDatePicker from "../../../../../components/form/FormDatePicker/FormDatePicker";
import $ from "jquery";
import {Editor} from "@tinymce/tinymce-react";
import {iconLienPj} from "../../../../../assets/icons/svgTinymce";
import useUploadFile from "../../../../../requests/upload/useUploadFile";
import {linkUrlTinyMce} from "../../../../../functions/linkUrlTinyMce";
import {codePostalPattern, telPattern, urlPattern} from "../../../../../functions/patterns";
import useGetVilleFromCodePostal from "../../../../../requests/geoname/useGetVilleFromCodePostal";
import {useSnapshot} from "valtio";
import annuaireProxy from "../../../../../proxies/annuaire";

function EntrepriseForm({ isPartenaire, entreprise, onSave, onRemove, onDeny }) {
    const annuaireSnapshot = useSnapshot(annuaireProxy);

    const editorRef = useRef(null);
    const uploadFilePresentation = useUploadFile({ type: "userfile"});
    const ref = useRef();
    const refCodePostal = useRef();
    if (uploadFilePresentation.data != null) {
        $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled",false);
        $(".tox-textfield").first().val(uploadFilePresentation.data.urlOriginale)
        if ($(".tox-textfield").eq(1).val() == ""){
            $(".tox-textfield").eq(1).val("Cliquer ici pour ouvrir la pièce jointe.")
        }
        $(".tox-dialog__footer-end button").eq(1).trigger('click')
    }

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            let checkUrlMotCle = string.slice(0,4)
            return string && checkUrlMotCle == "{url";
        }

        return url.protocol === "https:";
    }

    const { control, register, handleSubmit, setValue, getValues, formState: { errors }, reset, watch, setError, clearErrors } = useForm({
        defaultValues: entreprise ?? {
            nom: null,
            activite: null,
            adresse: null,
            codePostal: null,
            ville: null,
            tel1: null,
            tel2: null,
            tel1IsVisible: false,
            tel2IsVisible: false,
            urlSiteInternet: null,
            datePremierPartenariat: null,
            presentation: null,
            isVisibleInAnnuaire: true,
            upload: {
                idEntreprise: null,
                libelle: null,
                type: null,
                urlMiniature: null,
                urlOriginale: null,
                id: null,
            },
            tabProfils: {},
            id: null,
            site: null,
            entrepriseTrombi: false,
        },
    });
    const code = watch('codePostal');
    const ville = watch('ville');
    const [searchVilleParams] = useDebounce(useMemo(() => ({code, ville}), [code, ville]), 300);
    const autocompletionCodePostal = useGetVilleFromCodePostal({...searchVilleParams});

    useEffect(() => {
        reset(entreprise);
    }, [entreprise, reset]);

    const [nom] = useDebounce(watch('nom'), 300);
    const id = watch('id');

    const autocompleteDisabled = id != null;
    const autocompletion = useSearchEntreprise({ nom, disabled: autocompleteDisabled });

    const isCreating = entreprise?.id == null;

    return <div>
        {(!isCreating && !annuaireSnapshot.isUtilisateur) && <div className='d-flex mb-5'>
            <Button
                variant='light-danger mx-auto px-10'
                onClick={() => {
                    if (onRemove)
                        onRemove(entreprise);
                }}
            >
                Retirer l'entreprise
            </Button>
        </div>}
        {isPartenaire && <PartenaireSettings
            setValue={setValue}
            watch={watch}
            className='mb-10'
            control={control}
            register={register}
        />}
        <div className='d-flex justify-content-center'>
            <FormImagePicker imageClassName='w-150px h-150px rounded-circle' type={"logo"} setValue={setValue} getValues={getValues} control={control} name='upload' />
            {/*<ImagePicker*/}
            {/*    url='https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true'*/}
            {/*    imageClassName='w-300px'*/}
            {/*/>*/}
        </div>
        <Form.Group className='mt-8'>
            <Form.Label className='required'>Nom</Form.Label>
            {/* <Form.Control placeholder="Nom de l'entreprise" className='form-control-solid' {...register('nom', { required: true })} /> */}
            <FormTypeahead solid
                key={id}
                maxLength={200}
                id='autocompleteNomEntreprise'
                placeholder="Nom de l'entreprise"
                control={control}
                name='nom'
                ref={ref}
                rules={{ required: true }}
                options={autocompletion.data?.data}
                autocompleteDisabled={autocompleteDisabled}
                labelKey={option => option.nom}
                textKey={option => `${option.id} - ${option.nom}`}
                filterBy={() => true}
                onSelected={(selected) => {
                    if (onSave != null){
                        onSave(selected);
                        ref.current.clear();
                        refCodePostal.current.clear();
                    }
                }}
            />
            {errors.nom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
        </Form.Group>
        <Row className='mt-5'>
            <Col sm={6}>
                <Form.Group>
                    <Form.Label>Téléphone 1</Form.Label>
                    <InputGroup>
                        <InputGroup.Text className='text-muted fw-bold fs-5'>
                            +33
                        </InputGroup.Text>
                        <Form.Control
                            maxLength={14}
                            onKeyUp={(e) => {
                                e.target.value = e.target.value.replace(/[\.,\-\+]/g, '');
                                let countNumber = 0;
                                for (let i=0; i<e.target.value.length; i++ ){
                                    if (!isNaN(parseInt(e.target.value[i]))){
                                        countNumber = countNumber +1;
                                    }
                                }
                                if (countNumber > 10){
                                    setError("tel")
                                }else if (countNumber != 10 && countNumber != 0){
                                    setError("tel")
                                }else {
                                    clearErrors("tel")
                                }
                            }}
                            className='form-control-solid' {...register('tel1', { pattern : telPattern()})} />
                    </InputGroup>
                    {errors.tel1 && <Form.Text className='text-danger'>Merci d'indiquer un numéro valide</Form.Text>}
                </Form.Group>
            </Col>
            <Col className='mt-5 mt-sm-0' sm={6}>
                <Form.Group>
                    <Form.Label>Téléphone 2</Form.Label>
                    <InputGroup>
                        <InputGroup.Text className='text-muted fw-bold fs-5'>
                            +33
                        </InputGroup.Text>
                        <Form.Control
                            maxLength={14}
                            onKeyUp={(e) => {
                                e.target.value = e.target.value.replace(/[\.,\-\+]/g, '');
                                let countNumber = 0;
                                for (let i=0; i<e.target.value.length; i++ ){
                                    if (!isNaN(parseInt(e.target.value[i]))){
                                        countNumber = countNumber +1;
                                    }
                                }
                                if (countNumber > 10){
                                    setError("tel")
                                }else if (countNumber != 10 && countNumber != 0){
                                    setError("tel")
                                }else {
                                    clearErrors("tel")
                                }
                            }}
                            className='form-control-solid' {...register('tel2', { pattern : telPattern()})} />
                    </InputGroup>
                    {errors.tel2 && <Form.Text className='text-danger'>Merci d'indiquer un numéro valide</Form.Text>}
                </Form.Group>
            </Col>
        </Row>
        <Form.Group className='mt-5'>
            <Form.Label>Site internet</Form.Label>
            <Form.Control maxLength={100} className='form-control-solid' {...register('urlSiteInternet', {pattern: urlPattern()})} />
            {errors.urlSiteInternet && <Form.Text className='text-danger'>L'url doit commencer par http:// ou https://</Form.Text>}
        </Form.Group>
        <Row className='mt-5'>
            <Col sm={6}>
                <Form.Group>
                    <Form.Label>Activité</Form.Label>
                    <Form.Control maxLength={200} className='form-control-solid' {...register('activite')} />
                </Form.Group>
            </Col>
            <Col className='mt-5 mt-sm-0' sm={6}>
                <Form.Group>
                    <Form.Label>Date premier partenariat</Form.Label>
                    <FormDatePicker control={control} name='datePremierPartenariat' solid />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col className='mt-5'>
                <Form.Group>
                    <Form.Label>Adresse</Form.Label>
                    <Form.Control
                        maxLength={100}
                        as='textarea'
                        className='form-control-solid'
                        rows={5}
                        {...register('adresse')}
                    />
                </Form.Group>
            </Col>
            <Col className='mt-5' md={3}>
                <Stack gap={3}>
                    <Form.Group>
                        <Form.Label>Code postal</Form.Label>
                        <FormTypeahead solid
                                       key={id}
                                       maxLength={5}
                                       id='codePostal'
                                       control={control}
                                       ref={refCodePostal}
                                       name='codePostal'
                                       rules={{ required: false, pattern: codePostalPattern() }}
                                       options={autocompletionCodePostal.data?.postalCodes}
                                       autocompleteDisabled={false}
                                       labelKey={option => option.postalCode}
                                       textKey={option => `${option.postalCode} - ${option.placeName}`}
                                       filterBy={() => true}
                                       onSelected={(selected) => {
                                           if (selected && selected.postalCode && selected.placeName){
                                               setValue("codePostal", selected.postalCode.slice(0,5))
                                               setValue("ville", selected.placeName)
                                           }

                                       }}
                        />
                        {errors.codePostal && <Form.Text className='text-danger'>Code postal invalide</Form.Text>}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Ville</Form.Label>
                        <Form.Control className='form-control-solid' {...register('ville')} />
                    </Form.Group>
                </Stack>
            </Col>
        </Row>
        <Form.Group className='mt-5'>
            <Form.Label>Présentation</Form.Label>
            {/*<Form.Control*/}
            {/*    as='textarea'*/}
            {/*    className='form-control-solid'*/}
            {/*    rows={5}*/}
            {/*    {...register('presentation')}*/}
            {/*/>*/}
            <div className={"bg-gray-100"} style={{color:"#716d66", fontWeight:"500", borderRadius:"0.95rem", minHeight:"100px", padding:"10px"}}>
                <Editor
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={getValues("presentation")}
                    inline={true}
                    onChange={() => {
                        setValue("presentation", editorRef.current.getContent())
                    }}
                    init={{
                        skin: false,
                        // height: 500,
                        content_style: '.mce-content-body { min-height: 100px; }',
                        selector: '#editableDiv',
                        language : 'fr_FR',
                        language_url : '/lang/tinymce/fr_FR/langs/fr_FR.js',
                        menubar: false,
                        plugins: ["link autolink noneditable"],
                        default_link_target: '_blank',
                        toolbar: 'undo redo | formatselect | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help | link unlink ',
                        setup: function (editor) {
                            /* Menu items are recreated when the menu is closed and opened, so we need
                               a variable to store the toggle menu item state. */
                            var toggleState = false;

                            editor.ui.registry.addIcon('link', iconLienPj);

                            editor.on('init',function(e) {
                                editor.windowManager.oldOpen = editor.windowManager.open;
                                editor.windowManager.open = function (t, r) {

                                    var modal = this.oldOpen.apply(this, [t, r]);  // call original

                                    linkUrlTinyMce(t, isValidHttpUrl, id, uploadFilePresentation)

                                    return modal;
                                }
                            });



                        },

                    }}
                />
            </div>
        </Form.Group>
        <div className='mt-10 d-flex'>
            <Button
                variant={isCreating ? 'secondary' : 'secondary'}
                onClick={handleSubmit((data) => {
                    onSave(data)
                    if (!data.id){
                        ref.current.clear();
                        refCodePostal.current.clear();
                        reset(null);
                    }
                })}
                className='mx-auto px-10'
            >
                {isCreating ? 'Ajouter' : 'Enregistrer' + (annuaireSnapshot.isUtilisateur ? " l'entreprise" : "")}
            </Button>
            {isCreating &&
                <Button
                    variant={"danger"}
                    onClick={() => {
                        onDeny();
                    }}
                    className='mx-auto px-10'
                >
                    Annuler
                </Button>
            }
        </div>
    </div>;
}

export default EntrepriseForm;