import {useMutation, useQueryClient} from "react-query";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import baseApi from "../../services/apiPublic";

export default function useAbonnerMail() {

    const queryClient = useQueryClient();
    return useMutation(abonneMail, {
        onSuccess: () => {
            queryClient.invalidateQueries('parametreNotification');
            // snapAuth.websocket.send("emails")
            toast.success('Vous êtes abonné aux mails', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function abonneMail(ref) {

    if (ref != null)
        return baseApi.post(`parametreNotification/seReabonner`, {json : {ref : ref}}).json();

}