import { proxy } from "valtio";

const invitationsProxy = proxy({
    invitation: null,
    destinataire:null,
    tabIdsDestinataires:null,
    destinataireIsChecked:false,
    destinataireAlertIsAdd: false,
    editModal: {
        show: false,
        step: 0,
        loading: false,
    },
    addModal: {
        show: false,
        step: 0,
        loading: false,
    },
    exportSuiviModal:{
        show: false,
    },
    sendModal: {
        show: false,
    },
    rappelModal: {
        show: false,
    },
    personnalisationInvitation: {
        show: false,
    },
    inscriptionLibre: {
        show: false,
        showPreview: true
    },
    relanceModal: {
        show: false,
    },
    relancePaiementModal: {
        show: false,
    },
    suiviModal: {
        show: false,
    },
    detailInvitationModal: {
        show : false,
    },
    infoPersonneModal: {
        show: false,
    },
    showEditInvitation: (invitation) => {
        invitationsProxy.invitation = invitation;
        invitationsProxy.sendModal.show = false;
        invitationsProxy.rappelModal.show = false;
        invitationsProxy.relanceModal.show = false;
        invitationsProxy.suiviModal.show = false;
        invitationsProxy.tabIdsDestinataires = null;
        invitationsProxy.destinataireIsChecked = false;
        invitationsProxy.editModal = {
            show: true,
            step: 0,
            loading: false,
        };
    },
    showAddInvitation: (invitation) => {
        invitationsProxy.invitation = invitation;
        invitationsProxy.sendModal.show = false;
        invitationsProxy.addModal = {
            show: true,
            step: 0,
            loading: false,
        };
    },
    showSendInvitation: (invitation) => {
        invitationsProxy.invitation = invitation;
        invitationsProxy.editModal.show = false;
        invitationsProxy.rappelModal.show = false;
        invitationsProxy.relanceModal.show = false;
        invitationsProxy.suiviModal.show = false;
        invitationsProxy.sendModal = {
            show: true,
        };
    },
    showRappelInvitation: (invitation) => {
        invitationsProxy.invitation = invitation;
        invitationsProxy.editModal.show = false;
        invitationsProxy.sendModal.show = false;
        invitationsProxy.relanceModal.show = false;
        invitationsProxy.suiviModal.show = false;
        invitationsProxy.rappelModal = {
            show: true,
        };
    },
    showRelanceInvitation: (invitation) => {
        invitationsProxy.invitation = invitation;
        invitationsProxy.editModal.show = false;
        invitationsProxy.sendModal.show = false;
        invitationsProxy.rappelModal.show = false;
        invitationsProxy.suiviModal.show = false;
        invitationsProxy.relanceModal = {
            show: true,
        };
    },
    showRelancePaiementInvitation: (invitation) => {
        invitationsProxy.invitation = invitation;
        invitationsProxy.editModal.show = false;
        invitationsProxy.sendModal.show = false;
        invitationsProxy.rappelModal.show = false;
        invitationsProxy.suiviModal.show = false;
        invitationsProxy.relanceModal.show = false;
        invitationsProxy.relancePaiementModal = {
            show: true,
        };
    },
    showSuiviInvitation: (invitation) => {
        invitationsProxy.invitation = invitation;
        invitationsProxy.editModal.show = false;
        invitationsProxy.sendModal.show = false;
        invitationsProxy.rappelModal.show = false;
        invitationsProxy.relanceModal.show = false;
        invitationsProxy.suiviModal = {
            show: true,
        };
    },
    showInfoPersonneForSuiviInvitation: (destinaraire) => {
        invitationsProxy.destinataire = destinaraire;
        invitationsProxy.editModal.show = false;
        invitationsProxy.sendModal.show = false;
        invitationsProxy.rappelModal.show = false;
        invitationsProxy.relanceModal.show = false;
        invitationsProxy.infoPersonneModal = {
            show: true,
        };
    },
    showExportSuiviModal: () => {
        invitationsProxy.editModal.show = false;
        invitationsProxy.sendModal.show = false;
        invitationsProxy.rappelModal.show = false;
        invitationsProxy.relanceModal.show = false;
        invitationsProxy.infoPersonneModal.show = false;
        invitationsProxy.exportSuiviModal = {
            show: true,
        };
    },
    showDetailInvitationModal: (destinaraire) => {
        invitationsProxy.destinataire = destinaraire;
        invitationsProxy.editModal.show = false;
        invitationsProxy.sendModal.show = false;
        invitationsProxy.rappelModal.show = false;
        invitationsProxy.relanceModal.show = false;
        invitationsProxy.infoPersonneModal.show = false;
        invitationsProxy.exportSuiviModal.show = false;
        invitationsProxy.detailInvitationModal = {
            show: true,
        };
    },
    showInscriptionLibre: (invitation) => {
        invitationsProxy.invitation = invitation;
        invitationsProxy.editModal.show = false;
        invitationsProxy.sendModal.show = false;
        invitationsProxy.rappelModal.show = false;
        invitationsProxy.relanceModal.show = false;
        invitationsProxy.infoPersonneModal.show = false;
        invitationsProxy.exportSuiviModal.show = false;
        invitationsProxy.detailInvitationModal = false;
        invitationsProxy.inscriptionLibre = {
            show: true,
        };
    },
    showPersonnalisationInvitation: () => {
        invitationsProxy.personnalisationInvitation = {
            show: true,
        };
    },


})

export default invitationsProxy;