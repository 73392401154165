import {Card, Dropdown, Button, Stack, Alert, Spinner} from "react-bootstrap";
import { BtSwal } from "@utils/alerts/sweetAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faPlus, faWrench } from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useMemo, useRef, useState} from "react";
import MetaTable from "@components/MetaTable/MetaTable";
import SearchBar from "@components/SearchBar/SearchBar";
import { useForm } from "react-hook-form";
import { useDebounce } from 'use-debounce';
import ProfilFilter from "./ProfilFilter/ProfilFilter";
import annuaireProxy from "@proxies/annuaire";
import { ReactComponent as Art005 } from "@assets/icons/art005.svg";
import { ReactComponent as Gen027 } from "@assets/icons/gen027.svg";
import { ReactComponent as Bell } from "@assets/icons/bell.svg";
import { ReactComponent as Key } from "@assets/icons/key.svg";
import useDeletePersonne from "../../requests/personne/useDeletePersonne";
import useSendAccesPartenaire from "../../requests/communication/accesPartenaire/useSendAccesPartenaire";
import NotificationModal from "./NotificationModal/NotificationModal";
import useReabonnementMail from "../../requests/annuaire/useReabonnementMail";
import ExportAnnuaireModal from "./ExportAnnuaireModal/ExportAnnuaireModal";
import useGetExportAnnuaire from "../../requests/annuaire/useGetExportAnnuaire";
import useGetChampPerso from "../../requests/annuaire/useGetChampPerso";

function AnnuaireView() {
    const tableRef = useRef();
    const { register, watch, setValue } = useForm();
    const search = watch('search')
    const [searchFilter] = useDebounce(search, 300);
    const [idFilter] = useDebounce(watch('id'), 300);
    const [entrepriseFilter] = useDebounce(watch('entreprise'), 300);
    const [selectedProfil, setSelectedProfil] = useState();
    const deletePersonne = useDeletePersonne();
    const champsPerso = useGetChampPerso("");
    const urlExportAnnuaire = useGetExportAnnuaire(selectedProfil?.id);
    const sendAcces = useSendAccesPartenaire();
    const reabonnement = useReabonnementMail();

    useEffect(() => {
        urlExportAnnuaire.refetch();
    }, [selectedProfil])

    const colSearch = useMemo(() => {
        return {
            'personne.id': idFilter,
            'entreprise.nom': entrepriseFilter,
            'personne.tabProfils.id': selectedProfil?.id,
        };
    }, [idFilter, entrepriseFilter, selectedProfil]);

    const columns = useMemo(() => {


        let tmp = [
                {
                    Header: () => null,
                    id: 'wrench',
                    Cell: ({row}) => <Dropdown onClick={(e) => {
                        console.log(e)
                    }
                    }>
                        <Dropdown.Toggle className='hide-after btn-icon btn-quaternaire w-30px h-30px'>
                            <FontAwesomeIcon color={'black'} icon={faWrench}/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            className='fs-6 w-350px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
                            <div className='menu-item px-3'>
                                <Dropdown.Item
                                    className='menu-link px-3'
                                    onClick={() => annuaireProxy.showPersonne(row.original.personne, false, "personne", true)}
                                >
                                    <span className='svg-icon svg-icon-1 me-2'><Art005/></span>
                                    Modifier
                                </Dropdown.Item>
                            </div>
                            <div className='menu-item px-3'>
                                <Dropdown.Item
                                    className='menu-link px-3'
                                    onClick={() => {
                                        if (!(row.original.personne?.tabProfils != null && !!Object.values(row.original.personne?.tabProfils).find(item => item.isPartenaire))) {
                                            BtSwal.fire({
                                                title: `<b>${row.original.personne.prenom} ${row.original.personne.nom} n'est pas partenaire !</b> <br> <br> <small>Envoi des accès impossible.</small>`,
                                                showDenyButton: false,
                                                confirmButtonText: `Compris`,
                                                icon: 'error',
                                            })
                                        } else {
                                            BtSwal.fire({
                                                title: `<b>Êtes-vous sûr de vouloir envoyer les accès à ${row.original.personne.prenom} ${row.original.personne.nom} ?</b> <br> <br> <small>Les accès seront envoyés par mail.</small>`,
                                                showDenyButton: true,
                                                confirmButtonText: `Oui`,
                                                denyButtonText: `Annuler`,
                                                icon: 'info',
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    sendAcces.mutate(row.original.personne.id)
                                                    BtSwal.fire('Les accès ont été envoyés.', '', 'success')
                                                } else if (result.isDenied) {
                                                    BtSwal.fire('Les accès n\'ont pas été envoyés.', '', 'info')
                                                }
                                            })
                                        }
                                    }}
                                >
                                    <span className='svg-icon svg-icon-1 me-2'><Key/></span>
                                    {row.original.personne.compteIsBloque ? "Envoi des accès et débloquer le compte" : "Envoi des accès"}
                                </Dropdown.Item>
                            </div>
                            <div className='menu-item px-3'>
                                <Dropdown.Item
                                    className='menu-link px-3'
                                    onClick={() => {
                                        if (!(row.original.personne?.tabProfils != null && !!Object.values(row.original.personne?.tabProfils).find(item => item.isPartenaire))) {
                                            BtSwal.fire({
                                                title: `<b>${row.original.personne.prenom} ${row.original.personne.nom} n'est pas partenaire !</b> <br> <br> <small>Paramétrage des notifications impossible.</small>`,
                                                showDenyButton: false,
                                                confirmButtonText: `Compris`,
                                                icon: 'error',
                                            })
                                        } else {
                                            annuaireProxy.showNotificationPersonne(row.original.personne)
                                        }
                                    }}
                                >
                                    <span className='svg-icon svg-icon-1 me-2'><Bell/></span>
                                    Préférences notification
                                </Dropdown.Item>
                            </div>
                            <div className='menu-item px-3'>
                                <Dropdown.Item
                                    className='menu-link px-3'
                                    onClick={() => {
                                        if (!row.original.personne?.mailIsDesinscrit) {
                                            BtSwal.fire({
                                                title: `<b>${row.original.personne.prenom} ${row.original.personne.nom} n'est pas désinscrit des mails</b> `,
                                                showDenyButton: false,
                                                confirmButtonText: `Compris`,
                                                icon: 'error',
                                            })
                                        } else {
                                            reabonnement.mutate(row.original.personne.id)
                                        }
                                    }}
                                >
                            <span className="fa-stack">
                                <i style={{fontSize: "20px"}} className="fa fa-envelope-open fa-stack-1x me-2"></i>
                                <i style={{color: "rgba(0,0,0,0.69)", top: "-10px", right: "-10px", fontSize: "20px"}}
                                   className="fa fa-bell fa-stack-1x"></i>
                            </span>
                                    Réabonner aux mails
                                </Dropdown.Item>
                            </div>
                            <div className='menu-item px-3'>
                                <Dropdown.Item
                                    className='menu-link px-3'
                                    onClick={() => BtSwal.fire({
                                        title: `<b>Êtes-vous sûr de vouloir supprimer ${row.original.personne.prenom} ${row.original.personne.nom} ?</b> <br> <br> <small>Attention, toutes les lignes correspondantes à cette personne seront supprimées.</small>`,
                                        showDenyButton: true,
                                        confirmButtonText: `Oui`,
                                        denyButtonText: `Annuler`,
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            deletePersonne.mutate(row.original.personne.id);
                                            BtSwal.fire('La personne a été supprimé!', '', 'success')
                                        } else if (result.isDenied) {
                                            BtSwal.fire('La personne n\'a pas été supprimé', '', 'info')
                                        }
                                    })}
                                >
                                    <span className='svg-icon svg-icon-1 me-2'><Gen027/></span>
                                    Supprimer
                                </Dropdown.Item>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>,
                    width: 60,
                    disableResizing: true,
                    disableSearch: true,
                    noWrap: true,
                },
                {
                    Header: 'Id',
                    accessor: 'personne.id',
                    minWidth: 70,
                    maxWidth: 70,
                },
                {
                    Header: 'Prenom',
                    accessor: 'personne.prenom',
                    minWidth: 100,
                },
                {
                    Header: 'Nom',
                    accessor: 'personne.nom',
                    minWidth: 100,
                },
                {
                    Header: 'prenomNom',
                    accessor: 'personne.prenomNom',
                    hidden: true
                },
                {
                    Header: 'nomPrenom',
                    accessor: 'personne.nomPrenom',
                    hidden: true
                },
                {
                    Header: 'Entreprise',
                    accessor: 'entreprise.nom',
                    minWidth: 200,
                },
                {
                    Header: 'Fonction',
                    accessor: 'personne.fonction',
                    minWidth: 100,
                },
                {
                    Header: 'Email',
                    accessor: 'personne.email',
                    minWidth: 350,
                },
                {
                    Header: 'Tel1',
                    accessor: 'personne.tel1',
                    minWidth: 130,
                    maxWidth: 130
                },
                {
                    Header: 'Tel2',
                    accessor: 'personne.tel2',
                    minWidth: 130,
                    maxWidth: 130
                },
                {
                    accessor: 'personne.tabProfils.id',
                    hidden: true,
                    searchOperator: "="
                }
            ]

            let tmpChampsPerso = champsPerso.data?.data.map(exp => {
                return  {
                    Header: exp?.libelle,
                    id: exp?.libelle+exp.id,
                    minWidth: 100,
                    Cell:({value, row}) => {
                        if(row?.original?.personne?.tabChampsPersonnalisesAnnuaire && row?.original?.personne?.tabChampsPersonnalisesAnnuaire[exp.id]){
                            return row?.original?.personne?.tabChampsPersonnalisesAnnuaire[exp.id].valeur;
                        }else {
                            return "";
                        }
                    },
                }
            });

            if (tmpChampsPerso){
                return [
                    ...tmp.slice(0, 11),
                    ...tmpChampsPerso,
                    ...tmp.slice(11),
                ]
            }else {
                return tmp;
            }

        }, [champsPerso]);
    return <>
        <Card>
            <Card.Body>
                <div className="d-flex justify-content-center">
                    <ProfilFilter className='mb-8 text-center' selected={selectedProfil} onChange={(value) => setSelectedProfil(value)} />
                </div>
                <div className='d-flex flex-stack flex-wrap'>
                    <SearchBar
                        solid
                        {...register('search')}
                        onClear={search?.length > 0 ? () => {
                            setValue('search', '');
                        } : null}
                    />
                    <Stack className='ms-auto' direction='horizontal' gap={2}>
                        <Button variant='secondary' onClick={() => annuaireProxy.showPersonne(null, false, "personne", true)}>
                            <FontAwesomeIcon icon={faPlus} className='me-2' />
                            Ajouter une personne
                        </Button>
                    </Stack>
                </div>
                <MetaTable
                    ref={tableRef}
                    className='mt-5'
                    height={500}
                    url='annuaire'
                    keys={['annuaire']}
                    columns={columns}
                    search={searchFilter}
                    colSearch={colSearch}
                />
                <div className='d-flex mt-3'>
                    <Button
                        variant='secondary'
                        className='ms-auto'
                        onClick={() => {
                            // tableRef.current?.export();
                            annuaireProxy.showExportAnnuaire(urlExportAnnuaire?.data);
                        }}
                    >
                        <FontAwesomeIcon icon={faFileDownload} className='me-2' />
                        Exporter
                    </Button>
                </div>
            </Card.Body>
        </Card>
        <NotificationModal/>
        <ExportAnnuaireModal/>
    </>;
}

export default AnnuaireView;