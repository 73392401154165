import {useMutation, useQueryClient} from "react-query";
import toast from "react-hot-toast";
import api from "@services/api";
import {useSnapshot} from "valtio";
import speedBusinessProxy from "../../../proxies/speedBusiness";

export default function useChangeLienReceptionniste() {
    const queryClient = useQueryClient();

    const speedSnap = useSnapshot(speedBusinessProxy);

    return useMutation(regenerer, {
        onSuccess: () => {
            queryClient.invalidateQueries(['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'receptionnistes'])
            toast.success('Le lien a été renouvelé.', {
                duration:5000
            });
        },
        onError: (err) => {
            toast.error(err.message, {
                duration:5000
            });
        },
    });
}

function regenerer(id) {

    if (id != null)
        return api.post(`receptionniste/${id}/regenererLien`).json();

}