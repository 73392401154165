import {Button, Modal} from "react-bootstrap";
import {useSnapshot} from "valtio";
import BlockUI from "@components/BlockUI/BlockUI";
import SearchBar from "@components/SearchBar/SearchBar";
import {useForm} from "react-hook-form";
import smsProxy from "../../../../proxies/sms";
import {useMemo, useState} from "react";
import MetaTable from "../../../../components/MetaTable/MetaTable";
import {useDebounce} from "use-debounce";

function TarifsModal() {
    const smsSnapshot = useSnapshot(smsProxy);
    // const isLoading = sendAsTest.isLoading || send.isLoading;
    const {register, watch, setValue} = useForm();
    const search = watch('search');
    const [debouncedSearch] = useDebounce(search, 300);
    const [initSearch, setInitSearch] = useState("france");


    const colSearch = useMemo(() => {
        return {
            'pays': debouncedSearch ? debouncedSearch : initSearch
        };
    }, [debouncedSearch]);

    const columns = useMemo(() => [
        {
            Header: 'Initiale pays',
            accessor: 'initialePays',
            width: 80,
            minWidth: 60,
        },
        {
            Header: 'Pays',
            accessor: 'pays',
            width: 100,
            minWidth: 60,
        },
        {
            Header: 'Prix',
            accessor: 'prix',
            width: 100,
            minWidth: 60,
            Cell: ({row}) => {
                return row.original.prix + "€"
            }
        },
        {
            Header: 'Préfixe',
            accessor: 'prefixe',
            width: 100,
            minWidth: 60,
        },
    ], []);

    return <Modal
        show={smsSnapshot.tarifsModal.show}
        onHide={() => smsProxy.tarifsModal.show = false}
        size='lg'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Grille tarifaire</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <BlockUI loading={false}>
                <div className='mt-5'>
                    <h3>Liste des prix</h3>
                    {initSearch !== "" ? <Button onClick={() => {
                        setValue("search", "")
                        setInitSearch("")
                    }}>
                        Lister tous les pays
                    </Button> : <Button onClick={() => {
                        setValue("search", "france")
                        setInitSearch("france")
                    }}>
                        Afficher seulement la france
                    </Button>}
                    <SearchBar
                        solid
                        // defaultValue="Fra"
                        {...register('search')}
                        onClear={search?.length > 0 ? () => {
                            setValue('search', '');
                        } : null}
                    />
                    <MetaTable
                        className='mt-3'
                        height={400}
                        url={`tarifSms`}
                        keys={["tarifSms"]}
                        columns={columns}
                        search={debouncedSearch}
                        colSearch={colSearch}
                        desc={false}
                        idSort={"pays"}
                    />
                </div>
            </BlockUI>
        </Modal.Body>
    </Modal>;
}

export default TarifsModal;