import { useMutation } from "react-query";
import api from "@services/api";
import auth from "@services/auth";

export default function useChangeExpediteurDefault(props) {
    return useMutation(changeExpediteurDefault, props);
}

function changeExpediteurDefault(expediteur) {
    const id = expediteur?.id;

    if (id != null)
        return api.put(`parametreCommunication/${id}`, { json: expediteur }).json();
    return api.post('parametreCommunication', {
        json: {
            ...expediteur
        }
    }).json();
}