import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetPrefixe() {

    return useQuery(
        ["tarifSms"],
        async () => {
            // await new Promise(resolve => setTimeout(resolve, 3000))
            return api.get("tarifSms", {
                searchParams: Qs.stringify({
                    start: 0,
                    length: 10
                }, { arrayFormat: 'brackets' }),
            }).json();
        }
    );

}