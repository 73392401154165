import {Modal} from "react-bootstrap";
import {useSnapshot} from "valtio";
import facturationProxy from "../../../proxies/facturation";


function ExportFacturationModal() {
    const snapFacturation = useSnapshot(facturationProxy)

    return <Modal
        show={snapFacturation.showExportFacturationModal}
        onHide={() => facturationProxy.showExportFacturationModal = false}
        fullscreen
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Export annuaire</Modal.Title>
            <p style={{width : "fit-content"}} className="alert alert-info m-auto mt-0 mb-0 p-2">Aperçu des 2000 premières lignes. Afin de visionner toutes les lignes, télécharger l'export via le nuage en bas à droite.</p>
        </Modal.Header>
        <Modal.Body className="p-0">
            <iframe width={"100%"} height={"100%"} src={snapFacturation?.currentFilterUrl} ></iframe>
        </Modal.Body>
    </Modal>;
}

export default ExportFacturationModal;