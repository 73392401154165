import {Button, Card, Container, Form, Modal} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSnapshot } from 'valtio';
import annuaireProxy from '@proxies/annuaire';
import {useEffect} from "react";
import useChangeParametreNotification from "../../../requests/notification/useChangeParametreNotification";
import toast from "react-hot-toast";
import {useQueryClient} from "react-query";
import auth from "../../../services/auth";

function NotificationModal() {
    const queryClient = useQueryClient();
    const annuaireSnapshot = useSnapshot(annuaireProxy);
    const snapAuth = useSnapshot(auth);
    const changeParametreNotification = useChangeParametreNotification({
        onSuccess: () => {
            queryClient.invalidateQueries('annuaire');
            snapAuth.websocket.send("annuaire")

            annuaireProxy.notificationModal = false;
            toast.success('Paramètres notification partenaire modifiés.', {
                duration:5000
            });
        },
        onError: (err) => {
            toast.error(err.message, {
                duration:5000
            });
        },
    });

    const {
        register, handleSubmit, reset
    } = useForm({
        defaultValues: annuaireSnapshot.personne?.parametreNotification
    });

    async function saveChanges(data) {
        data.id = annuaireSnapshot.personne?.id;
        changeParametreNotification.mutate(data);
    }

    useEffect(() => {
        reset(annuaireSnapshot.personne?.parametreNotification ?? {});
    }, [reset, annuaireSnapshot.personne]);

    return <Modal
        show={annuaireSnapshot.notificationModal}
        onHide={() => {
                annuaireProxy.notificationModal = false;
        }}
        dialogClassName="modal-dialog modal-fullscreen-lg-down modal-lg"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Modifier les paramètres de notification du partenaire</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Container>
                    <Card className={`bg-light-info card-flush`}>
                        <Card.Body>
                            <h3 className='text-center'>Paramètres forum</h3>
                            <div className='mt-7 d-flex flex-wrap justify-content-between'>
                                <Form.Check
                                    className="mb-2 w-300px"
                                    inline
                                    type='switch'
                                    label="Mail - nouveau sujet forum"
                                    id='mailNouveauSujetForum'
                                    {...register('mailNouveauSujetForum')}
                                />
                                <Form.Check
                                    className="w-300px mb-2"
                                    inline
                                    type='switch'
                                    label="Push mobile - nouveau sujet forum"
                                    id='pushNouveauSujetForum'
                                    {...register('pushNouveauSujetForum')}
                                />
                                <Form.Check
                                    className="w-300px mb-2"
                                    inline
                                    type='switch'
                                    label="Mail - réponse au sujet du forum"
                                    id='mailReponseSujetForum'
                                    {...register('mailReponseParticipantSujetForum')}
                                />
                                <Form.Check
                                    className="w-300px mb-2"
                                    inline
                                    type='switch'
                                    label="Push mobile - réponse au sujet du forum"
                                    id='pushReponseSujetForum'
                                    {...register('pushReponseParticipantSujetForum')}
                                />
                                <Form.Check
                                    className="w-300px mb-2"
                                    inline
                                    type='switch'
                                    label="Mail - réponse de l'auteur au sujet du forum"
                                    id='mailReponseSujetForum'
                                    {...register('mailReponseAuteurSujetForum')}
                                />
                                <Form.Check
                                    className="w-300px mb-2"
                                    inline
                                    type='switch'
                                    label="Push mobile - réponse de l'auteur au sujet du forum"
                                    id='pushReponseSujetForum'
                                    {...register('pushReponseAuteurSujetForum')}
                                />
                            </div>
                            <h3 className='text-center mt-7'>Paramètres mail</h3>
                            <div className='mt-7 d-flex flex-wrap justify-content-between'>
                                <Form.Check
                                    className="w-300px mb-2"
                                    inline
                                    type='switch'
                                    label="Mail - nouvel email"
                                    id='mailNouvelEmail'
                                    {...register('mailNouvelEmail')}
                                />
                                <Form.Check
                                    className="w-300px mb-2"
                                    inline
                                    type='switch'
                                    label="Push mobile - nouvel email"
                                    id='pushNouvelEmail'
                                    {...register('pushNouvelEmail')}
                                />
                            </div>
                            <h3 className='text-center mt-7'>Paramètres invitation</h3>
                            <div className='mt-7 d-flex flex-wrap justify-content-between'>
                                <Form.Check
                                    className="w-300px mb-2"
                                    inline
                                    type='switch'
                                    label="Mail - nouvelle invitation"
                                    id='mailInvitation'
                                    {...register('mailInvitation')}
                                />
                                <Form.Check
                                    className="w-300px mb-2"
                                    inline
                                    type='switch'
                                    label="Push mobile - nouvelle invitation"
                                    id='pushInvitation'
                                    {...register('pushInvitation')}
                                />
                                <Form.Check
                                    className="w-300px mb-2"
                                    inline
                                    type='switch'
                                    label="Mail - rappel invitation"
                                    id='mailRappelInvitation'
                                    {...register('mailRappelInvitation')}
                                />
                                <Form.Check
                                    className="w-300px mb-2"
                                    inline
                                    type='switch'
                                    label="Push mobile - rappel invitation"
                                    id='pushRappelInvitation'
                                    {...register('pushRappelInvitation')}
                                />
                            </div>
                    </Card.Body></Card>
                </Container>
        </Modal.Body>
        <Modal.Footer>
           <Button variant="secondary" onClick={handleSubmit((data) => saveChanges(data))}>Enregistrer les paramètres</Button>
        </Modal.Footer>
    </Modal>;
}
export default NotificationModal;