import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Button, Dropdown, Form, Modal} from "react-bootstrap";
import { useSnapshot } from "valtio";
import invitationsProxy from "@proxies/invitations";
import MetaTable from "../../../../components/MetaTable/MetaTable";
import React, {useEffect, useMemo, useState} from "react";
import { useDebounce } from "use-debounce";
import { useForm } from "react-hook-form";
import useSendInvitationAsTest from "@requests/communication/invitations/useSendInvitationAsTest";
import useRelanceInvitation from "@requests/communication/invitations/useRelanceInvitation";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import moment from "moment";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import {showErrorAlert} from "../../../../utils/alerts/showErrorAlert";
import auth from "../../../../services/auth";
import useReabonnementMail from "../../../../requests/annuaire/useReabonnementMail";
import {BtSwal} from "../../../../utils/alerts/sweetAlert";
import useResendRelanceForOneCorrespondant
    from "../../../../requests/communication/invitations/useResendRelanceForOneCorrespondant";

function RelanceModal({ onHide, invitation }) {
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const resendForOneCorrepondant = useResendRelanceForOneCorrespondant();
    const { register, watch, setValue, reset } = useForm();
    const search = watch('search');
    const [debouncedSearch] = useDebounce(search, 300);
    const queryClient = useQueryClient();
    const [dateEnvoi, setDateEnvoi] = useState(moment().format("DD/MM/YYYY HH:mm"));
    const [filterDateEnvoi, setFilterDateEnvoi] = useState("");
    const snapAuth = useSnapshot(auth);
    const colSearch =  {
        'etatInvitation': "0,3",
        'dateEnvoiRelance': filterDateEnvoi,
    };
    const reabonnement = useReabonnementMail();

    useEffect(() => {
        reset({search: ""});
    }, [invitationsSnapshot.relanceModal.show]);

    const sendRelance = useRelanceInvitation({
        id: invitationsSnapshot.invitation?.id,
        date: dateEnvoi,
        onSuccess: () => {
            queryClient.invalidateQueries('invitations');
            snapAuth.websocket.send("invitations")

            toast.success('L\'envoi du mail de relance a été pris en compte.', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    const sendAsTest = useSendInvitationAsTest({
        id: invitationsSnapshot.invitation?.id,
        type: "invitation",
        onSuccess: () => {
            queryClient.invalidateQueries('invitations');
            snapAuth.websocket.send("invitations")

            toast.success('L\'envoi du mail a été pris en compte.', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    const columns = useMemo(() => [
        {
            Header: 'Id',
            accessor: 'destinataire.personne.id',
            minWidth: 70,
            maxWidth: 70,
        },
        {
            Header: 'etatInvitation',
            accessor: 'etatInvitation',
            hidden:true,
            searchOperator: "IN",
        },
        {
            Header: 'Prénom',
            accessor: 'destinataire.personne.prenom',
            minWidth: 100,
        },
        {
            Header: 'Nom',
            accessor: 'destinataire.personne.nom',
            minWidth: 100,
        },
        {
            Header: 'prenomNom',
            accessor: 'destinataire.personne.prenomNom',
            hidden: true,
        },
        {
            Header: 'nomPrenom',
            accessor: 'destinataire.personne.nomPrenom',
            hidden: true
        },
        {
            Header: 'dateEnvoi',
            accessor: 'dateEnvoiRelance',
            hidden: true,
        },
        {
            Header: 'État',
            id: 'etat',
            minWidth: 300,
            Cell: ({ row }) => {
                if (row.original.destinataire?.personne?.email == null)
                    return <span className='text-warning'>Adresse mail manquante</span>;
                if (row.original.destinataire?.personne?.mailIsDesinscrit)
                    return <span className='text-danger'>Ne souhaite plus recevoir de mail
                <Dropdown.Item
                    className='menu-link px-3'
                    onClick={() => {
                        if (!row.original.destinataire?.personne?.mailIsDesinscrit){
                            BtSwal.fire({
                                title: `<b>${row.original.destinataire?.personne.prenom} ${row.original.destinataire?.personne.nom} n'est pas désinscrit des mails</b> `,
                                showDenyButton: false,
                                confirmButtonText: `Compris`,
                                icon: 'error',
                            })
                        }else {
                            reabonnement.mutate(row.original.destinataire?.personne.id)
                        }
                    }}
                >
                            <span className="fa-stack">
                                <i style={{fontSize:"20px"}} className="fa fa-envelope-open fa-stack-1x me-2"></i>
                                <i style={{color:"rgba(0,0,0,0.69)", top:"-10px", right:"-10px", fontSize:"20px"}} className="fa fa-bell fa-stack-1x"></i>
                            </span>
                            Réabonner aux mails
                        </Dropdown.Item>
                </span>;
                if (row.original.destinataire?.personne?.mailIsErrone)
                    return <span className='text-danger'>Adresse mail erronée</span>;
                if (row.original.dateEnvoiRelance !== null ){
                    if (row.original.envoiRelanceIsFail){
                        return <span className='text-danger'>L'envoi de la relance à échoué le {row.original.dateEnvoiRelance}<FontAwesomeIcon icon={faTimes} className='ms-2' />
                        <span
                            onClick={() => {
                                resendForOneCorrepondant.mutate(row.original.id)
                            }}
                            title="Renvoyer l'invitation"
                            className='svg-icon svg-icon-1 me-2 d-block text-center cursor-pointer mt-2'><img width={20} src="/icon/repeat.svg" alt="bell"/></span></span>
                    }
                    return <span className='text-success'>Dernière relance envoyé le {row.original.dateEnvoiRelance} <FontAwesomeIcon icon={faCheck} className='ms-2' />
                    <span
                            onClick={() => {
                                resendForOneCorrepondant.mutate(row.original.id)
                            }}
                            title="Renvoyer l'invitation"
                            className='svg-icon svg-icon-1 me-2 d-block text-center cursor-pointer mt-2'><img width={20} src="/icon/repeat.svg" alt="bell"/></span></span>;
                }
                return <span className='text-info'>En attente d'envoi <span
                    onClick={() => {
                        resendForOneCorrepondant.mutate(row.original.id)
                    }}
                    title="Renvoyer l'invitation"
                    className='svg-icon svg-icon-1 me-2 d-block text-center cursor-pointer mt-2'><img width={20} src="/icon/repeat.svg" alt="bell"/></span></span>
            }
        },
    ], []);



    return <Modal
        show={invitationsSnapshot.relanceModal.show}
        onHide={() => invitationsProxy.relanceModal.show = false}
        size='lg'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Envoyer une relance pour l'invitation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='d-flex mt-5 justify-content-center'>
                <div>
                    <Button variant={"secondary"} onClick={sendRelance.mutate} >
                        Envoyer la relance
                    </Button>
                </div>
            </div>
            <div className="justify-content-center mt-5">
                <h3>Liste des destinataires</h3>
                <SearchBar
                    solid
                    {...register('search')}
                    onClear={search?.length > 0 ? () => {
                        setValue('search', '');
                    } : null}
                />
                <MetaTable
                    className='mt-3'
                    height={400}
                    url={`invitation/${invitationsSnapshot.invitation?.id}/correspondants`}
                    keys={['invitation', invitationsSnapshot.invitation?.id, 'correspondants']}
                    columns={columns}
                    search={debouncedSearch}
                    colSearch={colSearch}
                />
            </div>
        </Modal.Body>
    </Modal>;
}

export default RelanceModal;