import { useQuery } from "react-query";
import baseApi from "../../../services/apiPublic";

export default function useTransfererMailByMail(ref) {
    return useQuery(
        ['email'],
        () => baseApi.post('email/transferer', {
            json: {
                ref: ref
            }
        }).json(),
    );
}