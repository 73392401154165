import {Button, Form, Alert, Modal} from "react-bootstrap";
import Select2 from "../../../../components/Select2/Select2";
import useGetAnnuairePartenaire from "../../../../requests/annuaire/useGetAnnuairePartenaire";
import {useEffect, useRef, useState} from "react";
import useListeCategorie from "../../../../requests/forum/useListeCategorie";
import useGetSujet from "../../../../requests/forum/useGetSujet";
import {Editor} from "@tinymce/tinymce-react";
import './style.css';
import useEditSujet from "../../../../requests/forum/useEditSujet";
import sujetProxie from "@proxies/sujet";
import {useSnapshot} from "valtio";
import auth from "../../../../services/auth";
import useUploadFile from "../../../../requests/upload/useUploadFile";
// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';
import {iconLienPj} from "../../../../assets/icons/svgTinymce";
import {linkUrlTinyMce} from "../../../../functions/linkUrlTinyMce";
import fileManagerProxy from "../../../../proxies/fileManager";
import {imageTinyMce} from "../../../../functions/ImageTinyMce";
import PersonnePartenaireSelection from "../../../personne/PersonneSelection/PersonnePartenaireSelection";
import 'tinymce/tinymce';
import 'tinymce/plugins/lists';
import LoadingView from "../../../LoadingView/LoadingView";

function ForumEditView() {
    const partenaires = useGetAnnuairePartenaire();
    const types = useListeCategorie("");
    const [stateAutheurSujet, setAutheurSujet] = useState("");
    const [stateTypeSujet, setTypeSujet] = useState("");
    const [stateObjetSujet, setObjetSujet] = useState("");
    const [stateContentEditor, setContentEdior] = useState("");
    const [stateShowListAuth, setStateShowListAuth] = useState("");
    const [stateVue, setStateVue] = useState("");
    const [stateIsPublie, setStateIsPublie] = useState("");
    const [stateDatePublication, setStateDatePublication] = useState("");
    const sujetSnapshot = useSnapshot(sujetProxie);
    const id = sujetSnapshot.idSujet;
    const utilisateur = useSnapshot(auth);
    const [stateIsReadOnlySujet, setIsReadOnlySujet] = useState(false);
    const [stateTabEtats, setStateTabEtats] = useState([
        {
            id: 0,
            libelle: "Publié",
            actif: false
        },
        {
            id: 1,
            libelle: "En attente de publication",
            actif: true
        },
        {
            id: 2,
            libelle: "Brouillon",
            actif: false
        },
        {
            id: 3,
            libelle: "Archivé",
            actif: false
        },
        {
            id: 4,
            libelle: "Censuré",
            actif: false
        }
    ])
    const agenceId = utilisateur.agence?.id;
    const editSujet = useEditSujet(agenceId, stateContentEditor, stateObjetSujet, stateAutheurSujet, stateTypeSujet, id, stateVue, stateTabEtats, stateDatePublication, stateIsReadOnlySujet);
    // const currentSujet = useGetSujet(id);
    const editorRef = useRef();
    const $ = require('jquery');
    const uploadFile = useUploadFile({ type: "userfile" });
    if (uploadFile.data != null) {
        $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled",false);
        $(".tox-textfield").first().val(uploadFile.data.urlOriginale)

        if ($(".tox-dialog__title").text() == "Insérer une image"  || $(".tox-dialog__title").text() == "Modifier une image"){
            $(".tox-textfield").eq(1).val("L'image n'a pas pu être chargée")
        }
        else if ($(".tox-textfield").eq(1).val() == ""){
            $(".tox-textfield").eq(1).val("Cliquer ici pour ouvrir la pièce jointe.")
        }
        $(".tox-dialog__footer-end button").eq(1).trigger('click')
    }

    function changeAuteur(val) {
        setAutheurSujet(val)
    }

    function changeAutheurIsUtilisateur(){
        setAutheurSujet(utilisateur.utilisateur);
    }

    function changeType(val) {
        types.data.data.forEach(function (element){
            if (element.id == val){
                setTypeSujet(element);
            }
        });
    }


    function updateObjet(event){
        setObjetSujet(event.target.value);
    }

    function createSubmitSujet(tabEtats){
        if (stateObjetSujet === "" || stateContentEditor === ""){
            $("#errorMessage").attr("hidden",false);
            $("#errorObjet").attr("hidden",true);
        }else if (stateObjetSujet.length > 80){
            $("#errorObjet").attr("hidden",false);
            $("#errorMessage").attr("hidden",true);
        }
        else {
            editSujet.mutate(tabEtats);
            let btn = document.getElementById('createSujet');
            let btnEnAttente = document.getElementById('createSujetEnAttente');
            let btnBrouillon = document.getElementById('createSujetBrouillon');
            btn.disabled = true;
            btnEnAttente.disabled = true;
            btnBrouillon.disabled = true;
            $("#errorMessage").attr("hidden",true);
            $("#successMessage").attr("hidden",false);
            setTimeout(function (){
                sujetProxie.editModal.show = false;
            }, 800)
        }

    }

    useEffect(() => {
        if (partenaires.data && types.data && sujetSnapshot.sujet && utilisateur){
            setContentEdior(sujetSnapshot.sujet.corps)
            setObjetSujet(sujetSnapshot.sujet.objet)
            setAutheurSujet(sujetSnapshot.sujet.auteur.personne)
            setTypeSujet(sujetSnapshot.sujet.categorie)
            setStateVue(sujetSnapshot.sujet.nbVues)
            setStateIsPublie(sujetSnapshot.sujet.isPublie)
            setStateDatePublication(sujetSnapshot.sujet.datePublication)
            setStateTabEtats(sujetSnapshot.sujet.tabEtats)
            setIsReadOnlySujet(sujetSnapshot.sujet.isLectureSeule)
            if (sujetSnapshot.sujet.auteur.personne.id === utilisateur.utilisateur.id){
                setStateShowListAuth(false)
            }else {
                setStateShowListAuth(true)
            }
        }
    }, [partenaires.data, sujetSnapshot.sujet, types.data, utilisateur])

    if (partenaires.data && types.data && sujetSnapshot.sujet && utilisateur){
        // if (sujetSnapshot.editModal.initialiser){
        //     currentSujet.refetch().then(() => {
        //         setContentEdior(currentSujet.data.corps)
        //         setObjetSujet(currentSujet.data.objet)
        //         setAutheurSujet(currentSujet.data.auteur.personne)
        //         setTypeSujet(currentSujet.data.categorie)
        //         setStateVue(currentSujet.data.nbVues)
        //         setStateIsPublie(currentSujet.data.isPublie)
        //         setStateDatePublication(currentSujet.data.datePublication)
        //         setStateTabEtats(currentSujet.data.tabEtats)
        //         setIsReadOnlySujet(currentSujet.data.isLectureSeule)
        //         if (currentSujet.data.auteur.personne.id === utilisateur.utilisateur.id){
        //             setStateShowListAuth(false)
        //         }else {
        //             setStateShowListAuth(true)
        //         }
        //         sujetProxie.editModal.initialiser = false;
        //     })
        // }

        let dataTypes = types.data.data.map((response, index) => (
            {
                id : response.id,
                text : response.libelle
            }));


        return <Modal
            show={sujetSnapshot.editModal.show}
            onHide={() => sujetProxie.editModal.show = false}
            size='xl'
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Modifier un sujet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
            <Form.Group className="mb-3">
                <Form.Label className={"required"}>Objet :</Form.Label>
                <Form.Control maxLength={90} value={stateObjetSujet} onChange={updateObjet} type="text" placeholder="" />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label className={"required"}>Choisir la catégorie :</Form.Label>
                <Select2
                    data={dataTypes}
                    minimumResultsForSearch={3}
                    onChange={changeType}
                    selected={sujetSnapshot.sujet.categorie.id}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className={"required"}>Êtes vous l'auteur ?</Form.Label><br/>
                <Form.Check
                    inline
                    type='switch'
                    label=""
                    id='utilisateurIsAuteur'
                    checked={!stateShowListAuth}
                    onClick={(val)  => {
                        if (val.target.checked){
                            changeAutheurIsUtilisateur()
                            setStateShowListAuth(false);
                        }else {
                              setStateShowListAuth(true);
                        }
                    }}
                />
            </Form.Group>

            <Form.Group hidden={!stateShowListAuth}  className="mb-3">
                <Form.Label className={"required"}>Choisir l'auteur de la réponse :</Form.Label>
                <PersonnePartenaireSelection
                    logChange={changeAuteur}
                    stateAutheurSujet={stateAutheurSujet}
                    utilisateur={utilisateur.utilisateur}
                />
                {/*<Select2*/}
                {/*    solid*/}
                {/*    data={dataPartenaires}*/}
                {/*    minimumResultsForSearch={3}*/}
                {/*    onChange={changeAuteur}*/}
                {/*    selected={currentSujet.data.auteur.personne.id}*/}
                {/*/>*/}
            </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className={"required"}>Le sujet est en lecture seul :</Form.Label><br/>
                        <Form.Check
                            inline
                            type='switch'
                            label=""
                            id='isReadOnly'
                            defaultChecked={false}
                            checked={stateIsReadOnlySujet}
                            onClick={(val)  => {
                                if (val.target.checked){
                                    setIsReadOnlySujet(true);
                                }else {
                                    setIsReadOnlySujet(false);
                                }
                            }}
                        />
                    </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label className={"required"}>Corps du sujet :</Form.Label>
                <div className={"bg-gray-100 p-2"}>
                    <Editor className={"required"}
                            id={"tinyMCEARecup#forumEdit#"}
                            onInit={(evt, editor) => editorRef.current = editor}
                            // inline={true}
                            initialValue={sujetSnapshot.sujet.corps}
                            init={{
                                content_style: [contentUiCss, contentCss, '.mce-content-body { min-height: 100px; }'].join('\n'),
                                skin: false,
                                language : 'fr_FR',
                                language_url : '/lang/tinymce/fr_FR/langs/fr_FR.js',
                                valid_children : "+body[style]",
                                menubar: false,
                                formats : {
                                    underline : {inline : 'u', exact : true}
                                },
                                plugins: ["link autolink image code table textcolor lists"],
                                toolbar: 'undo redo | ' +
                                    'bold italic underline | forecolor | alignleft aligncenter alignright alignjustify | bullist numlist ' +
                                    '| link unlink | image',
                                setup: function (editor) {
                                    editor.ui.registry.addIcon('link', iconLienPj);
                                    editor.on('ObjectResized', function(e) {
                                        if (e.target.nodeName == 'IMG') {
                                            var selectedImage = editorRef.current.selection.getNode();
                                            editorRef.current.dom.setStyles(selectedImage, {"width": e.width, "height":+e.height})
                                        }
                                    });
                                    editor.on('init',function(e) {
                                        editor.windowManager.oldOpen = editor.windowManager.open;
                                        editor.windowManager.open = function (t, r) {
                                            var modal = editor.windowManager.oldOpen.apply(this, [t, r]);  // call original
                                            if (this && t.title === "Insert/Edit Link"){
                                                linkUrlTinyMce(t, isValidHttpUrl, null, uploadFile);
                                            }
                                            else if (t.title === "Insert/Edit Image"){
                                                fileManagerProxy.baseId = "tinyMCEARecup#forumEdit#";
                                                imageTinyMce(isValidHttpUrl, null, uploadFile);
                                            }
                                            return modal;

                                        }
                                    });
                                }
                            }}
                            onChange={() => setContentEdior(editorRef.current.getContent())}
                    />
                </div>
            </Form.Group>

                    { stateTabEtats.find(e => e.id == 0 && e.actif) ?
                        <div>
                            <Button id={"createSujet"}  onClick={() => createSubmitSujet(stateTabEtats)} variant={"secondary"}>
                                Modifier
                            </Button>
                            <Button id={"createSujetBrouillon"} hidden={true} />
                            <Button id={"createSujetEnAttente"} hidden={true}/>
                        </div>
                        :
                    <div className="d-flex justify-content-around">
                        <Button id={"createSujet"} onClick={() => createSubmitSujet([
                            {
                                id: 0,
                                libelle: "Publié",
                                actif: true
                            },
                            {
                                id: 1,
                                libelle: "En attente de publication",
                                actif: false
                            },
                            {
                                id: 2,
                                libelle: "Brouillon",
                                actif: false
                            },
                            {
                                id: 3,
                                libelle: "Archivé",
                                actif: false
                            },
                            {
                                id: 4,
                                libelle: "Censuré",
                                actif: false
                            }
                        ])} variant={"secondary"}>
                            Publier
                        </Button>
                        <Button id={"createSujetEnAttente"} onClick={() => createSubmitSujet([
                            {
                                id: 0,
                                libelle: "Publié",
                                actif: false
                            },
                            {
                                id: 1,
                                libelle: "En attente de publication",
                                actif: true
                            },
                            {
                                id: 2,
                                libelle: "Brouillon",
                                actif: false
                            },
                            {
                                id: 3,
                                libelle: "Archivé",
                                actif: false
                            },
                            {
                                id: 4,
                                libelle: "Censuré",
                                actif: false
                            }
                        ])} variant={"primary"} >
                            En attente de publication
                        </Button>
                        <Button id={"createSujetBrouillon"} onClick={() => createSubmitSujet([
                            {
                                id: 0,
                                libelle: "Publié",
                                actif: false
                            },
                            {
                                id: 1,
                                libelle: "En attente de publication",
                                actif: false
                            },
                            {
                                id: 2,
                                libelle: "Brouillon",
                                actif: true
                            },
                            {
                                id: 3,
                                libelle: "Archivé",
                                actif: false
                            },
                            {
                                id: 4,
                                libelle: "Censuré",
                                actif: false
                            }
                        ])} variant={"light"} >
                            Brouillon
                        </Button>
                    </div>}

            <Alert hidden={true} id="successMessage"  className='mt-2 mx-auto' key='success' variant='success'>
                Le sujet à été modifié. Vous allez être automatiquement redirigé vers la liste des sujets.
            </Alert>
            <Alert hidden={true} id="errorMessage"  className='mt-2 mx-auto' key='danger' variant='danger'>
                Erreur d'enregistrement, un des champs est manquant.
            </Alert>
            <Alert hidden={true} id="errorObjet" className='mt-2 mx-auto' key='danger' variant='danger'>
                Erreur, l'objet est trop long, il ne doit pas dépasser 80 caractères.
            </Alert>
        </Form>
            </Modal.Body>
        </Modal>;
    }else {
        return <Modal
            show={sujetSnapshot.editModal.show}
            onHide={() => sujetProxie.editModal.show = false}
            size='lg'
            enforceFocus={false}
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <LoadingView/>
            </Modal.Body>
        </Modal>
    }

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            let checkUrlMotCle = string.slice(0,4)
            return string && checkUrlMotCle == "{url";
        }

        return url.protocol === "https:";
    }

}

export default ForumEditView;