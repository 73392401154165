import {Button} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useQueryClient} from "react-query";
import TransfereTabForm from "./TransfereTabForm";
import toast from "react-hot-toast";
import $ from 'jquery';
import {BtSwal} from "../../../../utils/alerts/sweetAlert";
import {showErrorAlert} from "../../../../utils/alerts/showErrorAlert";
import useChangeCorrespondantTransfereMail
    from "../../../../requests/communication/correspondant/useChangeCorrespondantTransfereMail";

function TransfereForm({dataCorrespondant}) {

    let convertedDataCorrespondant = {...dataCorrespondant};
    let queryClient = useQueryClient();
    const changeCorrespondantTransfere = useChangeCorrespondantTransfereMail({
        onSuccess: (res) => {
            queryClient.invalidateQueries(['email']);
            // snapAuth.websocket.send("email")

            toast.success('Emails transférés', {
                duration:5000
            });
            if (res?.message){
                BtSwal.fire(res.message, '', 'success')
            }
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    convertedDataCorrespondant.tabDestinatairesTransfert = Object.values({})

    const { register, control, handleSubmit,
        formState: { errors } }
        = useForm({defaultValues: convertedDataCorrespondant,});

    function onSave(data) {
        changeCorrespondantTransfere.mutate(data);
        $("#containerValidButton").html("<p class=\"mx-10 alert alert-success text-center\">Le transfert a bien été pris en compte.</p>")
    }

    return <>
        <form><div className='bg-light rounded mt-3 pt-3 mx-10 pb-10'>
            <div className='mt-5 mx-10'>
                <p>Veuillez compléter le formulaire ci-dessous.</p>
                <h2 className='mt-2 fw-bolder d-flex align-items-center text-dark my-10'>
                    Ajouter des destinataires au transfert
                </h2>
                <TransfereTabForm
                    errors={errors}
                    control={control}
                    register={register}
                    name='tabDestinatairesTransfert'
                />


                <div id="containerValidButton" className='mt-2 d-flex justify-content-center'>
                    <Button
                        variant='primary'
                        className='px-20'
                        onClick={handleSubmit(onSave)}
                    >Valider le transfert</Button>
                </div>
            </div>
        </div></form>
    </>;
}

export default TransfereForm;
