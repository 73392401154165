import { useQuery } from "react-query";
import apiPublic from "../../../services/apiPublic";

export default function useGetAgenceAfterAuth(ref) {
    return useQuery(
        [ref],
        async () => {
            return apiPublic.get(`agence/fromRef?ref=${ref}`).json();
        },
        {
            enabled: ref != null,
        }
    );
}