import { useQuery } from "react-query";
import api from "@services/api";
var Qs = require('qs');

export default function useSearchPersonne({ nom, prenom, disabled }) {
    return useQuery(
        ['personne', nom, prenom],
        () => {
            return api.get('personne', {
                searchParams: Qs.stringify({
                    start: 0,
                    length: 5,
                    columns: {
                        0: {
                            data: 'nom',
                            searchable: true,
                            search: {
                                value: nom,
                                regex: false,
                            },
                        },
                        1: {
                            data: 'prenom',
                            searchable: true,
                            search: {
                                value: prenom,
                                regex: false,
                            },
                        },
                    },
                }, { arrayFormat: 'brackets' }),
            }).json();
        },
        {
            enabled: !disabled && (nom?.length > 2 || prenom?.length > 2),
        },
    );
}