import { useQuery } from "react-query";
import baseApi from "../../../services/apiPublic";

export default function useAfficherInvitationByMail(ref) {
    return useQuery(
        ['afficherInvitation'],
        () => baseApi.post('invitation/afficher', {
            json: {
                ref : ref
            }
        }).json(),
    );
}