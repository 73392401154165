import { ReactComponent as Gen021 } from "@assets/icons/gen021.svg";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef } from "react";
import { Button, Form } from "react-bootstrap";

const SearchBar = forwardRef(({ solid, large, onClear, ...props }, ref) => {
    return <div className='position-relative'>
        <span className='svg-icon svg-icon-1 svg-icon-primary position-absolute top-50 translate-middle ms-9'>
            <Gen021 />
        </span>
        <Form.Control
            id="searchBarId"
            ref={ref}
            className={`ps-14 ${large ? 'form-control-lg' : ''} ${solid ? 'form-control-solid' : ''}`}
            placeholder='Rechercher'
            {...props}
        />
        {onClear != null && <Button
            variant='flush'
            className='position-absolute top-50 end-0 me-2 translate-middle-y lh-0'
            onClick={onClear}
        >
            <div className='text-gray-400 p-3'>
                <FontAwesomeIcon icon={faTimes} />
            </div>
        </Button>}
    </div>
});

export default SearchBar;