import ImagePicker from "@components/ImagePicker/ImagePicker";
import { useController } from "react-hook-form";

function FormImagePicker({ type, control, name, setValue, getValues, ...props }) {
    const { field } = useController({ control, name });
    let url = field.value?.urlMiniature;
    let id = field.value?.id;
    if (url == null){
        url = field.value?.urlOriginale;
    }
    return <ImagePicker type={type} setValue={setValue} getValues={getValues} id={id} url={url} {...props} />;
}

export default FormImagePicker;