import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import illustration from "@assets/illustrations/16.png";
import {useSnapshot} from "valtio";
import invitationsProxy from "@proxies/invitations";
import useSendInvitationAsTest from "@requests/communication/invitations/useSendInvitationAsTest";
import useSendForValidation from "@requests/communication/invitations/useSendForValidation";
import toast from "react-hot-toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {useQueryClient} from "react-query";
import auth from "@services/auth";
import {showErrorAlert} from "../../../../../utils/alerts/showErrorAlert";


function FinalisationStep({ onSend, onClose }) {
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const sendAsTest = useSendInvitationAsTest({
        id: invitationsSnapshot.invitation?.id,
        type:"invitation",
        onSuccess: () => {
            queryClient.invalidateQueries('invitations');
            snapAuth.websocket.send("invitations")

            toast.success('L\'envoi du mail a été pris en compte.', {
                duration:5000
            });
        }
    });

    const sendForValidation = useSendForValidation({
        id: invitationsSnapshot.invitation?.id,
        onSuccess: () => {
            queryClient.invalidateQueries('invitations');
            snapAuth.websocket.send("invitations")

            toast.success('L\'envoi du mail a été pris envoyé pour validation.', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    return <div className='w-100 mw-800px m-auto'>
        <div className='pt-15 pb-12 text-center'>
            <h1 className='fw-bolder text-dark'>Invitation créée !</h1>
            <div className='text-muted fw-bold fs-4'>
                Il ne reste plus qu'à la valider
            </div>
        </div>
        <div className='d-flex flex-center pb-3'>
            <OverlayTrigger
                key='mailTestInfo'
                placement='right'
                overlay={<Tooltip id='mailTestInfo'>
                    à l'adresse <span className='text-info'>{auth.utilisateur?.email}</span>
                </Tooltip>}
            >
                <Button className={"me-2"} onClick={sendAsTest.mutate} variant='danger' style={{height: "fit-content"}}>
                    M'envoyer l'invitation en test
                    <FontAwesomeIcon icon={faExclamationCircle} className='ms-2' />
                </Button>

            </OverlayTrigger>
            <Button onClick={sendForValidation.mutate}>Envoyer pour validation</Button>
        </div>
        <div className={`d-flex flex-center pb-15 ${!invitationsSnapshot.invitation?.isValidePourEnvoi ? 'd-none' : ''}`}>
            <Button
                onClick={() => invitationsProxy.showSendInvitation(invitationsSnapshot.invitation)}
            >Envoyer</Button>
        </div>
        <div className='text-center'>
            <img src={illustration} alt='' className='mww-100 mh-350px' />
        </div>
    </div>;
}

export default FinalisationStep;