import { useQuery } from "react-query";
import apiPublic from "../../../services/apiPublic";

export default function useGetTabParticipantsParTourFromRef(ref) {
    if (ref){
        return useQuery(
            ['rencontreSpeedBusiness', ref],
            async () => {
                return apiPublic.get(`participantRencontreSpeedBusiness/tabParticipantsParTour?ref=`+encodeURIComponent(ref)).json();
            }
        );
    }
}