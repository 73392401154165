import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {useSnapshot} from "valtio";
import {showErrorAlert} from "../../../../utils/alerts/showErrorAlert";
import facebookProxy from "../../../../proxies/facebook";

export default function useChangePublicationReseauSocial(props) {
    const queryClient = useQueryClient();
    // const snapAuth = useSnapshot(auth);

    return useMutation(changeReseauSocial, props);
}

function changeReseauSocial(publication) {
    const id = publication.id;

    if (id != null)
        return api.put(`publicationReseauSocial/${id}`, { json: publication }).json();
    return api.post(`publicationReseauSocial`, { json: publication }).json();
}