import {useMutation, useQueryClient} from "react-query";
import toast from "react-hot-toast";
import baseApi from "../../../services/apiPublic";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useResendInvitationForOneCorrespondant() {

    const queryClient = useQueryClient();

    return useMutation(resend, {
        onSuccess: () => {
            toast.success('L\'invitation a été envoyé', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'envoi", err.message);
        }
    });
}

function resend(id) {

    if (id != null)
        return baseApi.post(`correspondantInvitation/${id}/envoyerInvitation`).json();

}