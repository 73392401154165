import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

export default function useAddNewReceptionniste(props) {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);

    return useMutation(addNewReceptionniste, props);
}

function addNewReceptionniste(receptionniste) {
    if (receptionniste?.id){
        return api.put(`receptionniste/`+receptionniste?.id, { json: receptionniste }).json();
    }else {
        return api.post(`receptionniste`, { json: receptionniste }).json();
    }
}