import {Button, Form, InputGroup, Modal} from "react-bootstrap";
import smsProxy from "../../../../proxies/sms";
import {useSnapshot} from "valtio";
import React from "react";
import toast from "react-hot-toast";
import useGetCompteSms from "../../../../requests/communication/sms/useGetCompteSms";
import LoadingView from "../../../../views/LoadingView/LoadingView";

function IdentifiantModal() {
    const smsSnapshot = useSnapshot(smsProxy);
    const compteAllMySms = useGetCompteSms();


    if (compteAllMySms.isLoading){
        return <LoadingView/>
    }

    return <Modal
        show={smsSnapshot.identifiantModal.show}
        onHide={() => smsProxy.identifiantModal.show = false}
        size='lg'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Vos identifiants</Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-auto">

            <InputGroup className="mb-3 w-600px d-flex justify-content-center align-items-center">
                <Form.Label className="me-2 w-200px">
                    Lien de connexion allmysms
                </Form.Label>
                <Form.Control
                    style={{
                        borderRadius: "15px 0 0 15px",
                    }}
                    disabled={true}
                    value={"https://manager.allmysms.com/auth/login/?r=Lw=="}
                />
                <Button style={{
                    height: 43
                }} onClick={(event) => {
                    window.open("https://manager.allmysms.com/auth/login/?r=Lw==", '_blank')
                }}  variant="secondary" className="ps-2" id="button-addon2">
                     <span className="p-0"><i style={{
                         color: "white"
                     }} className="fa fa-link"></i>Ouvrir</span>
                </Button>
            </InputGroup>

            <InputGroup className="mb-3 w-600px d-flex justify-content-center align-items-center">
                <Form.Label className="me-2 w-200px">
                    Identifiant
                </Form.Label>
                <Form.Control
                    style={{
                        borderRadius: "15px 0 0 15px",
                    }}
                    disabled={true}
                    value={compteAllMySms?.data?.identifiant}
                />
                <Button style={{
                    height: 43
                }} onClick={(event) => {
                    navigator.clipboard.writeText(compteAllMySms?.data?.identifiant).then(() => {
                        event.target.innerHTML = "";
                        event.target.classList.add('fa', 'fa-check');
                        setTimeout(() => {
                            event.target.innerHTML = "Copier";
                            event.target.classList.remove('fa', 'fa-check');
                        }, 2000)
                        toast.success("Identifiant copié")
                    })
                }}  variant="secondary" id="button-addon2">
                    Copier
                </Button>
            </InputGroup>

            <InputGroup className="mb-3  w-600px d-flex justify-content-center align-items-center">
                <Form.Label className="me-2 w-200px">
                    Mot de passe
                </Form.Label>
                <Form.Control
                    style={{
                        borderRadius: "15px 0 0 15px",
                    }}
                    disabled={true}
                    value={compteAllMySms?.data?.motDePasse}
                    type={"password"}
                />
                <Button style={{
                    height: 43
                }} onClick={(event) => {
                    navigator.clipboard.writeText(compteAllMySms?.data?.motDePasse).then(() => {
                        event.target.innerHTML = "";
                        event.target.classList.add('fa', 'fa-check');
                        setTimeout(() => {
                            event.target.innerHTML = "Copier";
                            event.target.classList.remove('fa', 'fa-check');
                        }, 2000)
                        toast.success("Mot de passe copié")
                    })
                }}  variant="secondary" id="button-addon2">
                    Copier
                </Button>
            </InputGroup>
        </Modal.Body>
    </Modal>;
}

export default IdentifiantModal;