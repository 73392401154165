import api from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { changePersonne } from "../personne/useChangePersonne";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";
import invitationsProxy from "../../proxies/invitations";
import speedBusiness from "../../proxies/speedBusiness";

export default function useChangeAllAnnuaire() {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const speedSnap = useSnapshot(speedBusiness);
    return useMutation(async ({ personne, entreprises }) => {
        const promises = entreprises?.map(entreprise => changeAnnuaire({ personne, entreprise }));
        await Promise.all(promises);

        // Appliquer les modifs à la personne pour voir le résultat final
        // return changePersonne(personne);
        return await changeAnnuaire({personne});
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['personne', 'entreprises']);
            queryClient.invalidateQueries(['invitation', invitationsSnapshot.invitation?.id, 'correspondants']);
            queryClient.invalidateQueries(['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'participants']);
            queryClient.invalidateQueries(['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'receptionnistes']);
            queryClient.invalidateQueries('receptionniste');
            queryClient.invalidateQueries('annuaire');
            queryClient.invalidateQueries('utilisateur');
            queryClient.invalidateQueries('destinataire');
            queryClient.invalidateQueries('participants');
            snapAuth.websocket.send("personne")
            snapAuth.websocket.send("entreprises")
            snapAuth.websocket.send("annuaire")
            snapAuth.websocket.send("destinataire")
            snapAuth.websocket.send("participants")
            snapAuth.websocket.send("receptionniste")
            snapAuth.websocket.send(['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'receptionnistes'])
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function changeAnnuaire({ personne, entreprise = null }) {
    return api.post('annuaire', {
        json: {
            personne: personne,
            entreprise: entreprise,
        },
    }).json();
}