import { useMutation } from "react-query";
import api from "@services/api";

export default function useDeleteImageReseauSociaux() {

    return useMutation(deleteImage, {
        onSuccess: () => {
        },
        onError: (err) => {
        },
    });

}

function deleteImage(id) {
    if (id != null)
        return api.delete(`uploadPublicationReseauSocial/${id}`).json();

}