import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";

export default function useGetChampPersonnaliseAnnuaire(props) {
    return useMutation(getValeurChampsPerso, props);
}

function getValeurChampsPerso(id) {
    if (id != null){
        return api.get(`champPersonnaliseAnnuaire/${id}/valeurs`).json();
    }
}
