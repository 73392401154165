import {useSnapshot} from "valtio";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import auth from "../../../services/auth";
import React, {useMemo, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import useResendMailOrdreForOneCorrespondant
    from "../../../requests/communication/speedBusiness/useResendMailOrdreForOneCorrespondant";
import useResendSmsOdreForOneCorrespondant
    from "../../../requests/communication/speedBusiness/useResendSmsOdreForOneCorrespondant";
import {useDebounce} from "use-debounce";
import useSendMailsRencontre from "../../../requests/communication/speedBusiness/useSendMailsRencontre";
import moment from "moment/moment";
import annuaireProxy from "../../../proxies/annuaire";
import { ReactComponent as Art005 } from "@assets/icons/art005.svg";
import {Button, Dropdown, Form, Modal} from "react-bootstrap";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import SearchBar from "../../../components/SearchBar/SearchBar";
import MetaTable from "../../../components/MetaTable/MetaTable";
import {ReactComponent as Gen016} from "@assets/icons/gen016.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWrench} from "@fortawesome/free-solid-svg-icons";

export default function EmailAndSmsPassageModal(){
    const speedSnap = useSnapshot(speedBusinessProxy);
    const tableRef = useRef();
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const sendMailOrdreOneCorrespondant = useResendMailOrdreForOneCorrespondant();
    const sendSmsOrdreOneCorrespondant = useResendSmsOdreForOneCorrespondant();
    const [debouncedSearch] = useDebounce(search, 300);
    const [prenom, setPrenom] = useState("");
    const [prenomNom, setPrenomNom] = useState("");
    const [nom, setNom] = useState("");
    const colSearch = useMemo(() => {
        return {
            'etatPresence': '0,2',
            'participant.personne.prenom': prenom,
            'participant.personne.nom': nom,
            'participant.personne.prenomNom': prenomNom,
        };
    }, [prenom, nom, prenomNom]);
    const sendMailRappel = useSendMailsRencontre();
    const [dateEnvoi, setDateEnvoi] = useState(moment().format("DD/MM/YYYY à HH:mm"));

    const columns = useMemo(() => {

        let tmp =  [
            {
                Header: () => "",
                id: 'wrench',
                Cell: ({ row }) =>
                    <Dropdown>
                        <Dropdown.Toggle className='hide-after btn-icon btn-quaternaire w-30px h-30px'>
                            <FontAwesomeIcon color={"black"} icon={faWrench} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='fs-6 w-350px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
                            <div className='menu-item px-3'>
                                <Dropdown.Item
                                    onClick={() => annuaireProxy.showPersonne(row.original.participant.personne, false, "personne", true)}
                                >
                                    <span className='svg-icon svg-icon-1 me-2'><Art005 /></span>
                                    Modifier
                                </Dropdown.Item>
                                <Dropdown.Item
                                    title={"envoyer mail de passage"}
                                    onClick={() => {
                                        BtSwal.fire({
                                            title: `<b>Êtes-vous sûr de vouloir envoyer le mail de passage à ${row.original.participant.personne.prenom} ${row.original.participant.personne.nom} ?</b>`,
                                            showDenyButton: true,
                                            confirmButtonText: `Oui`,
                                            denyButtonText: `Annuler`,
                                            icon: 'info',
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                sendMailOrdreOneCorrespondant.mutate(row.original.id)
                                            } else if (result.isDenied) {
                                                BtSwal.fire('Le mail n\'a pas été envoyé.', '', 'info')
                                            }
                                        })
                                    }}
                                >
                               <span style={{
                               }} className='svg-icon svg-icon-1 me-2'><Gen016/> Envoyer email de passage</span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    title={"envoyer sms de passage"}
                                    onClick={() => {
                                        BtSwal.fire({
                                            title: `<b>Êtes-vous sûr de vouloir envoyer le sms de passage à ${row.original.participant.personne.prenom} ${row.original.participant.personne.nom} ?</b>`,
                                            showDenyButton: true,
                                            confirmButtonText: `Oui`,
                                            denyButtonText: `Annuler`,
                                            icon: 'info',
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                sendSmsOrdreOneCorrespondant.mutate(row.original.id)
                                            } else if (result.isDenied) {
                                                BtSwal.fire('Le sms n\'a pas été envoyé.', '', 'info')
                                            }
                                        })
                                    }}
                                >
                               <span style={{
                               }} className='svg-icon svg-icon-1 me-2'><Gen016/> Envoyer sms de passage</span>
                                </Dropdown.Item>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>,
                width: 60,
                disableResizing: true,
                disableSearch: true,
                noWrap: true,
            },
            {
                Header: 'Id',
                accessor: 'participant.personne.id',
                minWidth: 80,
                maxWidth: 80
            },
            {
                Header: 'etatInvitation',
                accessor: 'etatInvitation',
                hidden: true
            },
            {
                Header: 'etatPresence',
                accessor: 'etatPresence',
                searchOperator:"IN",
                hidden: true
            },
            {
                Header: 'prenomNom',
                accessor: 'participant.personne.prenomNom',
                hidden: true
            },
            {
                Header: 'nomPrenom',
                accessor: 'participant.personne.nomPrenom',
                hidden: true
            },
            {
                Header: 'prenom',
                accessor: 'participant.personne.prenom',
                hidden: true,
            },
            {
                Header: 'Participant',
                accessor: 'participant.personne.nom',
                minWidth: 300,
                Cell: ({row}) => {
                    let result = [];
                    result.push(<div class="me-7">
                        <div class="symbol symbol-fixed d-flex align-items-center position-relative">
                            <img src={row.original.participant.personne?.upload?.urlMiniature} alt="image personne"/>
                            <div className="ms-3 d-flex">
                                <p className="me-2">{row.original.participant.personne.prenom + " " + row.original.participant.personne.nom}</p>
                                <a
                                    onClick={() => annuaireProxy.showPersonne(row.original.participant.personne, false, "personne", true)}
                                    className='svg-icon svg-icon-1 cursor-pointer'><Art005/></a></div>
                        </div>
                    </div>);
                    result.push()
                    // result.push((row.original.destinataire.tel1 ? row.original.destinataire.tel1 + " " : "") + row.original.destinataire.tel2 ? row.original.destinataire.tel2 : "")
                    return result;
                },
                Footer: info => {
                    return <Form.Control
                        onKeyUp={(e) => {
                            setPrenomNom(e.target.value)
                        }}
                        className={`form-control`}
                        placeholder='Rechercher prénom nom'
                    />
                },
            },
            {
                Header: 'Téléphones',
                accessor: 'participant.personne.tel1',
                minWidth: 200,
                Cell: ({row}) => {
                    let result = [];
                    result.push(row.original.participant.personne.tel1 ? row.original.participant.personne.tel1 + " " : "")
                    result.push(<br/>)
                    result.push(row.original.participant.personne.tel2 ? row.original.participant.personne.tel2 : "")
                    return result;
                },
            },
            {
                Header: 'Email',
                accessor: 'participant.personne.email',
                minWidth: 250,
            },
        ]
        return tmp;
    }, [])
    return <Modal
        fullscreen
        show={speedSnap.showEmailAndSmsPassageModal}
        onHide={() => {
            speedBusinessProxy.showEmailAndSmsPassageModal = false;
            speedBusinessProxy.showLancementSpeedBusinessModal = true;
        }}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <h2>
                Envoyer l'ordre de passage aux participants
            </h2>
        </Modal.Header>
        <Modal.Body>
            <div>
                <div style={{
                    width: 800,
                    margin: "auto",
                    marginBottom: 10,
                    height: "fit-content",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    borderRadius: 10,
                    border: "solid 1px var(--primary-color)"
                }}>
                    <p className="w-100 fs-6 fw-bold pt-5 ps-5 pe-5">Pour faciliter les rencontres, vous pouvez envoyer à chaque participant les tables qui lui sont affectées à chaque tour.</p>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <div className="m-auto">
                        <div style={{
                            padding: 5,
                            width: "fit-content",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }} className="">
                    <span className="d-flex justify-content-center align-items-center ">Envoyer l'ordre de passage aux participants <i
                        className="btn btn-primary me-2 ms-2 fa fa-2x fa-sms" onClick={() => {
                        BtSwal.fire({
                            title: `<b>Êtes-vous sûr de vouloir envoyer le sms d'ordre de passage à tous les participants ?</b>`,
                            showDenyButton: true,
                            confirmButtonText: `Oui`,
                            denyButtonText: `Annuler`,
                            icon: 'info',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                sendMailRappel.mutate({
                                    id: speedSnap?.speedBusiness?.id,
                                    type: 'smsPassage',
                                    dateProgrammationEnvoi: dateEnvoi,
                                })
                            } else if (result.isDenied) {
                                BtSwal.fire('Le sms n\'a pas été envoyé.', '', 'info')
                            }
                        })
                    }}></i> <i
                        className="fa fa-2x fa-envelope btn btn-primary "
                        onClick={() => {
                            BtSwal.fire({
                                title: `<b>Êtes-vous sûr de vouloir envoyer le mail d'ordre de passage à tous les participants ?</b>`,
                                showDenyButton: true,
                                confirmButtonText: `Oui`,
                                denyButtonText: `Annuler`,
                                icon: 'info',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    sendMailRappel.mutate({
                                        id: speedSnap?.speedBusiness?.id,
                                        type: 'emailPassage',
                                        dateProgrammationEnvoi: dateEnvoi,
                                    })
                                } else if (result.isDenied) {
                                    BtSwal.fire('Le mail n\'a pas été envoyé.', '', 'info')
                                }
                            })
                        }}
                    ></i></span>
                        </div>
                    </div>
                    {/*<div>*/}
                    {/*    <div style={{}}>*/}
                    {/*        <span>Heure programmation mails et sms</span><Flatpickr*/}
                    {/*        className={"form-control mt-2"}*/}
                    {/*        display={"hidden"}*/}
                    {/*        defaultValue={dateEnvoi}*/}
                    {/*        onChange={date => {*/}
                    {/*            setDateEnvoi(moment(date[0]).format("DD/MM/YYYY à HH:mm"));*/}
                    {/*        }}*/}
                    {/*        options={{*/}
                    {/*            locale: French,*/}
                    {/*            dateFormat: 'd/m/Y à H:i',*/}
                    {/*            enableTime: true,*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div style={{
                    maxWidth: 1200
                }} className="m-auto">
                    <h3>Liste des participants</h3>
                    <SearchBar
                        solid
                        {...register('search')}
                        onClear={search?.length > 0 ? () => {
                            setValue('search', '');
                        } : null}
                    />
                    <MetaTable
                        ref={tableRef}
                        className='mt-5'
                        height={500}
                        url={`rencontreSpeedBusiness/${speedSnap.speedBusiness?.id}/participants`}
                        keys={['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'participants']}
                        columns={columns}
                        search={debouncedSearch}
                        colSearch={colSearch}
                        showFiltres={true}/>
                </div>
            </div>
        </Modal.Body>
    </Modal>
}