import {useMutation, useQueryClient} from "react-query";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import api from "../../services/api";
import {BtSwal} from "../../utils/alerts/sweetAlert";
import {useSnapshot} from "valtio";
import mailsProxy from "../../proxies/mails";
import invitationsProxy from "../../proxies/invitations";

export default function useReabonnementMail() {

    const queryClient = useQueryClient();
    const mailsSnapshot = useSnapshot(mailsProxy);
    const invitationsSnapshot = useSnapshot(invitationsProxy);

    return useMutation(reabonnement, {
        onSuccess: () => {
            queryClient.invalidateQueries('parametreNotification');
            queryClient.invalidateQueries(['email', mailsSnapshot.mail?.id, 'correspondants']);
            queryClient.invalidateQueries(['invitation', invitationsSnapshot.invitation?.id, 'correspondants']);
            BtSwal.fire("Réabonnement effectué", "", "info")
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function reabonnement(id) {

    return api.post(`parametreNotification/${id}/seReabonner`).json();

}

