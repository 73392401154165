import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";
import usePublierSujet from "../../requests/forum/usePublierSujet";
import useDepublierSujet from "../../requests/forum/useDepublierSujet";

export default function useCreateSujet(idAgence, corps, objet, personne, categorie, isReadOnly, tabEtats) {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const publierSujet = usePublierSujet(null, categorie?.libelle);
    const depublierSujet = useDepublierSujet(null, categorie?.libelle);
    let jsonCreationSujet = {
        idAgence: idAgence,
        // isPublie: false,
        tabEtats : tabEtats,
        isLectureSeule : isReadOnly,
        nbVues: 0,
        objet: objet,
        corps: corps,
        auteur: {
            personne : personne
        },
        categorie: categorie
    }

    return useMutation((tabEtats) => {
        return api.post('sujetForum', {
            json: {...jsonCreationSujet,
            }
        }).json();
    }, {
        onSuccess: (res, tabEtats) => {
            switch (tabEtats.find(item => item.actif).id) {
                case 0 :
                    if (res?.tabEtats.find(item => item.actif).id != tabEtats.find(item => item.actif).id){
                        publierSujet.mutate(res.id)
                    }
                    break;
                case 1 :
                    if (res?.tabEtats.find(item => item.actif).id != tabEtats.find(item => item.actif).id) {
                        depublierSujet.mutate({idEtat: 1, idNew: res.id})
                    }
                    break;
                case 2 :
                    if (res?.tabEtats.find(item => item.actif).id != tabEtats.find(item => item.actif).id) {
                        depublierSujet.mutate({idEtat: 2, idNew: res.id})
                    }
                    break;
                case 3 :
                    if (res?.tabEtats.find(item => item.actif).id != tabEtats.find(item => item.actif).id) {
                        depublierSujet.mutate({idEtat: 3, idNew: res.id})
                    }
                    break;
                case 4 :
                    if (res?.tabEtats.find(item => item.actif).id != tabEtats.find(item => item.actif).id) {
                        depublierSujet.mutate({idEtat: 4, idNew: res.id})
                    }
                    break;
            }
            queryClient.invalidateQueries("sujetForumByCategories"+categorie.libelle);
            queryClient.invalidateQueries("categorieSujet");
            snapAuth.websocket.send("sujetForumByCategories"+categorie.libelle)
            snapAuth.websocket.send("categorieSujet")

        },
        onError: (err) => {
            showErrorAlert("Erreur lors de la création de sujet ", err.message);
        },
    });
}