import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetReponsesSms(id, search) {

    if (!search){
        search = "";
    }

    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = {
        data: "auteur.destinataire.personne.prenomNom",
        searchable: true,
        orderable: true,
        search: {
            value: '',
            regex: false,
        },
    }

    filters.columns[1] = {
        data: "auteur.destinataire.personne.nomPrenom",
        searchable: true,
        orderable: true,
        search: {
            value: '',
            regex: false,
        },
    }

    filters.columns[2] = {
        data: "message",
        searchable: true,
        orderable: true,
        search: {
            value: '',
            regex: false,
        },
    }

    filters.columns[3] = {
        data: "date",
        searchable: true,
        orderable: true,
        search: {
            value: '',
            regex: false,
        },
    }

    filters.search = {
        value: search
    }

    filters.order[0] = {
        column:3,
        dir: "desc"
    }

    return useQuery(
        ["reponsesSms"],
        async () => {
            return api.get(`sms/${id}/reponses`, {
                searchParams: Qs.stringify({
                    start: 0,
                    length: 100,
                    ...filters,
                }, { arrayFormat: 'brackets' }),
            }).json();
        }, {
            enabled : id != null
        }
    );

}