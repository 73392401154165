import { useMutation } from "react-query";
import api from "@services/api";

export default function useChangeParamsStripe(props) {
    return useMutation((file) => changeParamsStripe(file), props);
}

export function changeParamsStripe(file) {
    const formData = new FormData();
    formData.append('icon', file.icon);
    formData.append('logo', file.logo);
    formData.append('primary_color', file.primary);
    formData.append('secondary_color', file.secondary);
    return api.post('stripe/parametresCompte', { body: formData }).json();
}