import {useParams} from "react-router-dom";
import {useEffect} from "react";
import useGetColorFromAgence from "../../requests/parametres/agence/useGetColorFromAgence";
import {useSnapshot} from "valtio";
import auth from "@services/auth";
import LoadingView from "../../views/LoadingView/LoadingView";

function SetVarCssAgence({ children }) {
    const snap = useSnapshot(auth);
    const {agence} = useParams();
    const couleursAgence = useGetColorFromAgence(agence);
    //todo get css wait back route
    useEffect(() => {
        if (snap.utilisateur == null && couleursAgence.isSuccess){
            import('../../customThemeUi.css');
            document.querySelector(':root').style.setProperty('--light-percent', 0.33);
            document.querySelector(':root').style.setProperty('--hover-percent', 0.66);
            document.querySelector(':root').style.setProperty('--hover-light-percent', 0.44);
            document.querySelector(':root').style.setProperty('--primary-color', rgbaStr(couleursAgence?.data?.primaire));
            document.querySelector(':root').style.setProperty('--primary-color-light', lightRgbaStr(couleursAgence?.data?.primaire));
            document.querySelector(':root').style.setProperty('--primary-color-hover', hoverRgbaStr(couleursAgence?.data?.primaire));
            document.querySelector(':root').style.setProperty('--secondary-color', rgbaStr(couleursAgence?.data?.secondaire));
            document.querySelector(':root').style.setProperty('--secondary-color-light', lightRgbaStr(couleursAgence?.data?.secondaire));
            document.querySelector(':root').style.setProperty('--secondary-color-hover', hoverRgbaStr(couleursAgence?.data?.secondaire));
            document.querySelector(':root').style.setProperty('--tertiaire-color', rgbaStr(couleursAgence?.data?.tertiaire));
            document.querySelector(':root').style.setProperty('--tertiaire-color-light', lightRgbaStr(couleursAgence?.data?.tertiaire));
            document.querySelector(':root').style.setProperty('--tertiaire-color-hover', hoverRgbaStr(couleursAgence?.data?.tertiaire));
            document.querySelector(':root').style.setProperty('--quaternaire-color', rgbaStr(couleursAgence?.data?.quaternaire));
            document.querySelector(':root').style.setProperty('--quaternaire-color-light', lightRgbaStr(couleursAgence?.data?.quaternaire));
            document.querySelector(':root').style.setProperty('--quaternaire-color-hover', hoverRgbaStr(couleursAgence?.data?.quaternaire));
            auth.srcLogo = couleursAgence?.data?.logo?.urlOriginale;
        }else if (couleursAgence.isSuccess){
            import('../../customThemeUi.css');
            document.querySelector(':root').style.setProperty('--light-percent', 0.33);
            document.querySelector(':root').style.setProperty('--hover-percent', 0.66);
            document.querySelector(':root').style.setProperty('--hover-light-percent', 0.44);
            document.querySelector(':root').style.setProperty('--primary-color', rgbaStr(couleursAgence?.data?.primaire));
            document.querySelector(':root').style.setProperty('--primary-color-light', lightRgbaStr(couleursAgence?.data?.primaire));
            document.querySelector(':root').style.setProperty('--primary-color-hover', hoverRgbaStr(couleursAgence?.data?.primaire));
            document.querySelector(':root').style.setProperty('--secondary-color', rgbaStr(couleursAgence?.data?.secondaire));
            document.querySelector(':root').style.setProperty('--secondary-color-light', lightRgbaStr(couleursAgence?.data?.secondaire));
            document.querySelector(':root').style.setProperty('--secondary-color-hover', hoverRgbaStr(couleursAgence?.data?.secondaire));
            document.querySelector(':root').style.setProperty('--tertiaire-color', rgbaStr(couleursAgence?.data?.tertiaire));
            document.querySelector(':root').style.setProperty('--tertiaire-color-light', lightRgbaStr(couleursAgence?.data?.tertiaire));
            document.querySelector(':root').style.setProperty('--tertiaire-color-hover', hoverRgbaStr(couleursAgence?.data?.tertiaire));
            document.querySelector(':root').style.setProperty('--quaternaire-color', rgbaStr(couleursAgence?.data?.quaternaire));
            document.querySelector(':root').style.setProperty('--quaternaire-color-light', lightRgbaStr(couleursAgence?.data?.quaternaire));
            document.querySelector(':root').style.setProperty('--quaternaire-color-hover', hoverRgbaStr(couleursAgence?.data?.quaternaire));
            auth.srcLogo = couleursAgence?.data?.logo?.urlOriginale;
        }
    }, [agence, couleursAgence]);
    if (!couleursAgence.isLoading){
        return children
    }else {
        return <LoadingView/>
    }
}

function rgbaStr(rgba){
    if (rgba){
        return "rgba("+rgba.r+","+rgba.g+","+rgba.b+","+rgba.a+")";
    }
}
function hoverRgbaStr(rgba){
    return "rgba("+rgba.r+","+rgba.g+","+rgba.b+","+getComputedStyle(document.body).getPropertyValue("--hover-percent")+")";
}
function lightRgbaStr(rgba){
    return "rgba("+rgba.r+","+rgba.g+","+rgba.b+","+getComputedStyle(document.body).getPropertyValue("--light-percent")+")";
}
export default SetVarCssAgence;