import {faPencilAlt, faUndo} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useEffect, useRef, useState} from "react";
import BlockUI from "../BlockUI/BlockUI";

import './style.css';
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";

function ImagePickerStripe({ setValue, getValues, url, circular, imageClassName, name }) {
    const inputFile = useRef();

    return <BlockUI className='overflow-visible'>
        <div className={`image-input image-input-outline ${circular ? 'image-input-circle' : ''}`}>
            <div
                className={`image-input-wrapper ${imageClassName ?? ''}`}
                style={{
                    backgroundImage: `url("${url}")`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                }}
            />
            <label
                className='btn btn-icon btn-circle btn-active-color-primary w-35px h-35px bg-white shadow image-picker-edit'
                onClick={() => inputFile.current?.click()}
            >
                <FontAwesomeIcon icon={faPencilAlt} size='lg' />
                <input
                    type='file'
                    id='file'
                    ref={inputFile}
                    onChange={() => {
                        if (inputFile.current?.files && inputFile.current?.files[0]) {
                            var reader = new FileReader();

                            if (name == "apercu.urlIcone"){
                                if(inputFile.current?.files[0].size < 512000){
                                    reader.onload = function (e) {
                                        let image = new Image();
                                        image.src = e.target.result;
                                        image.onload = function() {
                                            if ((image.width/image.height) == 1){
                                                setValue('icon', inputFile.current?.files[0])
                                                setValue("apercu.urlIcone", e.target.result);
                                            }else {
                                                showErrorAlert("Erreur de dimension de l'icône", "L'image doit avoir un ratio de 1.")
                                            }
                                        }
                                    };
                                }else {
                                    showErrorAlert("Taille de l'icône trop importante", "La taille de l'image ne doit pas dépasser 512kbit")
                                }
                            }else if (name == "apercu.urlLogo"){
                                if(inputFile.current?.files[0].size < 512000) {
                                    setValue('logo', inputFile.current?.files[0])
                                    reader.onload = function (e) {
                                        setValue("apercu.urlLogo", e.target.result);
                                    };
                                }
                                else {
                                    showErrorAlert("Taille du logo trop important", "La taille de l'image ne doit pas dépasser 512kbit")
                                }
                            }
                            reader.readAsDataURL(inputFile.current?.files[0]);
                        }
                    }}
                    style={{ display: 'none' }}
                    accept='.png,.jpg,.jpeg'
                />
            </label>
            <label
                className={`btn btn-icon btn-circle btn-active-color-primary w-35px h-35px bg-white shadow image-picker-undo disabled`}
            >
                <FontAwesomeIcon icon={faUndo} size='lg' />
            </label>
        </div>
    </BlockUI>;
}

export default ImagePickerStripe;