import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

export default function useChangeMail() {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    return useMutation(changeMail, {
        onSuccess: () => {
            queryClient.invalidateQueries('emails');
            snapAuth.websocket.send("emails")
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function changeMail(mail) {
    const id = mail.id;

    if (id != null)
        return api.put(`email/${id}`, { json: mail }).json();
    return api.post(`email`, { json: mail }).json();
}