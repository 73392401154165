import { proxy } from "valtio";

const mailEditorProxy = proxy({
    modalPadding: false,
    modalAddTwoUrlOnImage: false,
    modalCustomButtonTranfert: false,
    padding:"0 0 0 0",
    setPaddingSection:null,
    onChange:null,
    block:null,
    id:null,
    showModalPadding: (setPaddingSection, onChange, block, id, paddingSection) => {
        mailEditorProxy.modalPadding = true;
        mailEditorProxy.setPaddingSection = setPaddingSection;
        mailEditorProxy.onChange = onChange;
        mailEditorProxy.block = block;
        mailEditorProxy.id = id;
        mailEditorProxy.padding = paddingSection;
    },
    showModalAddOneUrlOnImage: (onChange, block, id) => {
        mailEditorProxy.modalAddOneUrlOnImage = true;
        mailEditorProxy.onChange = onChange;
        mailEditorProxy.block = block;
        mailEditorProxy.id = id;
    },
    showModalCustomButtonTranfert: (onChange, block, id) => {
        mailEditorProxy.modalCustomButtonTranfert = true;
        mailEditorProxy.onChange = onChange;
        mailEditorProxy.block = block;
        mailEditorProxy.id = id;
    },
    showModalAddTwoUrlOnImage: (onChange, block, id) => {
        mailEditorProxy.modalAddTwoUrlOnImage = true;
        mailEditorProxy.onChange = onChange;
        mailEditorProxy.block = block;
        mailEditorProxy.id = id;
    }
});

export default mailEditorProxy;