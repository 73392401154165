import { useMutation } from "react-query";
import api from "@services/api";

export default function useUploadMultiLogo({ type, ...props }) {
    return useMutation((file) => uploadFile(file, type), props);
}

export function uploadFile(file, type) {
    const formData = new FormData();
    formData.append('type', type);
    formData.append('fichier', file);
    return api.post('upload/multiLogo', { body: formData }).json();
}