import { useMutation } from "react-query";
import api from "@services/api";

export default function useChangeCUSms(props) {
    return useMutation(changeCUSms, props);
}

export async function changeCUSms(boolCU) {
    return api.post('utilisateur/accepteConditionUtilisationSms', { json: {
            accepteConditionUtilisationSms : boolCU
        } }).json();
}
