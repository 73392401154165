import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetDestinataireValidationMessage(search) {

    if (!search){
        search = "";
    }
    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = {
        data: "personne.prenomNom",
        searchable: true,
        orderable: true,
        search: {
            value: "",
            regex: false,
        }
    }

    filters.columns[1] = {
        data: "personne.nomPrenom",
        searchable: true,
        orderable: true,
        search: {
            value: "",
            regex: false,
        }
    }

    filters.columns[2] = {
        data: "personne.email",
        searchable: true,
        orderable: true,
        search: {
            value: "",
            regex: false,
        }
    }

    filters.columns[3] = {
        data: "personne.id",
        searchable: true,
        orderable: true,
        search: {
            value: "",
            regex: false,
        }
    }


    filters.search = {
        value:search
    }


    filters.order[0] = {
        column:3,
        dir: "asc"
    }

    return useQuery(
        ['destinataireValidationMessage', search],
        () => api.get('destinataireValidationMessage', {
            searchParams: Qs.stringify({
                start: 0,
                length: 100,
                ...filters,
            },{ arrayFormat: 'brackets' })}).json(),
    );
}