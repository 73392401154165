import ExcelExport from "../../../components/ExcelExport/ExcelExport";

//pour prendre en compte les présents, il faut mettre utiliserParticipant a true
export default function ExportParTable({id, participants, genererParTable, nomTables, utiliserParticipant=false, participantsMaitre, participantsSupplant, nbTables, nbTours}){
    // const data = [
    //     { id: 1, name: 'John Doe', age: 30, profession: 'Developer' },
    //     { id: 2, name: 'Jane Smith', age: 25, profession: 'Designer' }
    // ];

    async function exportParTable() {

        let dataGenererParTable = await genererParTable.mutateAsync({
            id:id,
            utiliserParticipant:utiliserParticipant
        })
        let dataParticipants = await participants.mutateAsync(id)
        let dataParticipantsMaitre = await participantsMaitre.mutateAsync(id)
        let dataParticipantsSuppleant = await participantsSupplant.mutateAsync(id)
        let dataParTable = [];
        let dataParTableParTour = [];
        Object.values(dataGenererParTable).forEach((table, indexTable) => {
            Object.values(table).forEach((tour, indexTour) => {
                let tmpParticipants = [];
                tour.forEach((item, index) => {
                    if (dataParticipants?.data[item] && item >= 0){
                        tmpParticipants["Participant "+(index+1)] = dataParticipants?.data[item].participant.personne.prenom + " " + dataParticipants?.data[item].participant.personne.nom
                    }else if (item >= (-nbTables)){
                        tmpParticipants["Participant "+(index+1)] = dataParticipantsMaitre?.data[item+nbTables].participant.personne.prenom + " " + dataParticipantsMaitre?.data[item+nbTables].participant.personne.nom
                    }else {
                        tmpParticipants["Participant "+(index+1)] = dataParticipantsSuppleant?.data[Math.abs(item+nbTours)].participant.personne.prenom + " " + dataParticipantsSuppleant?.data[Math.abs(item+nbTours)].participant.personne.nom
                    }
                })
                dataParTable.push({
                    ["nom table"]: nomTables[indexTable],
                    ["Tour"]: indexTour + 1,
                    ...tmpParticipants
                })
                if (!dataParTableParTour[indexTour]){
                    dataParTableParTour[indexTour]= {
                        data: [
                            {
                                ["nom table"]: nomTables[indexTable],
                                ["numéro tour"]: indexTour + 1,
                                ...tmpParticipants
                            }
                        ],
                        pageName: "tour " + (indexTour+1)
                    }
                }else {
                    dataParTableParTour[indexTour].data.push(
                        {
                            ["nom table"]: nomTables[indexTable],
                            ["numéro tour"]: indexTour + 1,
                            ...tmpParticipants
                        }
                    );
                }

            })
        })
        ExcelExport({
            arrayData:[{
                data : dataParTable,
                pageName: "globale"
            },
                ...dataParTableParTour
            ],
            filename: "ordre_par_table",
            styleParTable:true
        })
    }


    return exportParTable();
}