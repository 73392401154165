import { useMutation } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";

export default function useGetGenererParParticipant() {

    return useMutation(genererParParticipant, {
        onError: (err) => {
            toast.error(err.message);
        },
    });
}

function genererParParticipant(obj) {
    if (obj.id != null)
        return api.get(`rencontreSpeedBusiness/${obj.id}/GenererParParticipant?utiliserParticipant=`+obj.utiliserParticipant).json();
}