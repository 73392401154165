import { proxy } from "valtio";

const fileManagerProxy = proxy({
    id:"",
    isUrl:false,
    baseId:"tinyMCEARecup",
    showFileManager: {
        show: false,
    },
    urlForPublicationMobile : null,
    initUrlForPublicationMobile : false,
    fileManagerProxy: (id, isUrl) => {
        fileManagerProxy.id = id;
        fileManagerProxy.isUrl=isUrl;
        fileManagerProxy.showFileManager = {
            show: true,
        };
    },
})

export default fileManagerProxy;