import ExcelExport from "../../../components/ExcelExport/ExcelExport";

export default function ExportParParticipant({id,genererParParticipant, participants, utiliserParticipant=false, participantsMaitre, participantsSupplant, nbTables, nbTours, nomTables }){


    async function exportParParticipant() {
        let dataGenererParParticipant = await genererParParticipant.mutateAsync({
            id:id,
            utiliserParticipant:utiliserParticipant
        })
        let dataParticipants = await participants.mutateAsync(id)
        let dataParticipantsMaitre = await participantsMaitre.mutateAsync(id)
        let dataParticipantsSuppleant = await participantsSupplant.mutateAsync(id)
        let dataParPersonne = [];
        let data = [];
        let tabToursTabPersonnes = [];
        //-consctruction excel generation par participants
        Object.values(dataGenererParParticipant).forEach((premierPart, index) => {
            let tmpTableParTour = []
            premierPart.forEach((indexTable, indexTour) => {
                tmpTableParTour["Tour " + (indexTour+1) ] = nomTables[indexTable];
            })
            let item = parseInt(Object.keys(dataGenererParParticipant)[index])
            let personne = "";
            if (item >= 0 ){
                personne = (dataParticipants?.data[item]?.participant?.personne?.prenom ? dataParticipants?.data[item]?.participant?.personne?.prenom + " " + dataParticipants?.data[item]?.participant?.personne?.nom : "null");
                data.push({
                    ["Personne"]: personne,
                    ...tmpTableParTour
                })
                constructTabToursTabPersonnes(premierPart, tabToursTabPersonnes, personne);
            }else if (item >= (-nbTables)){
                personne = (dataParticipantsMaitre?.data[item+nbTables]?.participant?.personne?.prenom ? dataParticipantsMaitre?.data[item+nbTables]?.participant?.personne?.prenom + " " + dataParticipantsMaitre?.data[item+nbTables]?.participant?.personne?.nom : "null");
                data.push({
                    ["Personne"]: personne,
                    ...tmpTableParTour
                })
                constructTabToursTabPersonnes(premierPart, tabToursTabPersonnes, personne);
            }else {
                personne = (dataParticipantsSuppleant?.data[Math.abs(item+nbTours)]?.participant?.personne?.prenom ? dataParticipantsSuppleant?.data[Math.abs(item+nbTours)]?.participant?.personne?.prenom + " " + dataParticipantsSuppleant?.data[Math.abs(item+nbTours)]?.participant?.personne?.nom : "null");
                data.push({
                    ["Personne"]: personne,
                    ...tmpTableParTour
                })
                constructTabToursTabPersonnes(premierPart, tabToursTabPersonnes, personne);
            }

            //génération par personne à travailler
            dataParPersonne[index] = {
                data: [{
                    ...tmpTableParTour
                }],
                pageName: personne
            }

        });
        addPersonnesRencontreeParPersonne(dataParPersonne, tabToursTabPersonnes);

        ExcelExport({
            arrayData:[{
                data: data,
                pageName: "globale"
            },
                ...dataParPersonne
            ],
            filename: "ordre_tables_par_personne",
            styleParParticipant: true
        })
    }

    return exportParParticipant();

    function constructTabToursTabPersonnes(premierPart, tabToursTabPersonnes, personne) {
        premierPart.forEach((indexTable, indexTour) => {
            if (typeof tabToursTabPersonnes[indexTour] == "undefined") {
                tabToursTabPersonnes[indexTour] = [];
                tabToursTabPersonnes[indexTour][indexTable] = [personne]
            } else {
                if (typeof tabToursTabPersonnes[indexTour][indexTable] != "undefined") {
                    tabToursTabPersonnes[indexTour][indexTable].push(personne)
                } else {
                    tabToursTabPersonnes[indexTour][indexTable] = [personne]
                }
            }
        })
    }

    function addPersonnesRencontreeParPersonne(dataParPersonne, tabToursTabPersonnes) {
        //Attributions des personnes rencontrées par personne
        [...dataParPersonne].forEach((pagePersonne, indexPagePersonne) => {
            tabToursTabPersonnes.forEach((currentTour, indexCurrentTour) => {
                for (let i = 0; i < currentTour.length; i++) {
                    if (currentTour[i].find(item => item == pagePersonne.pageName)) {
                        currentTour[i].forEach((currentPersonne, indexCurP) => {
                            let tmpEdit = dataParPersonne[indexPagePersonne].data.find(item => item["Ordre des tables"] == "personne " + (indexCurP + 1));
                            if (tmpEdit) {
                                Object.assign(dataParPersonne[indexPagePersonne].data.find(item => item["Ordre des tables"] == "personne " + (indexCurP + 1)),
                                    {
                                        ["Tour " + (indexCurrentTour + 1)]: currentPersonne
                                    })
                            } else {
                                dataParPersonne[indexPagePersonne].data.push({
                                    ["Ordre des tables"]: "personne " + (indexCurP + 1),
                                    ["Tour " + (indexCurrentTour + 1)]: currentPersonne
                                })
                            }
                        })
                    }
                }
            })
        });
        [...dataParPersonne].forEach((pagePersonne, indexPagePersonne) => {
                pagePersonne.data.forEach((personneParTour, indexPersonne) => {
                    if (typeof dataParPersonne[indexPagePersonne] != "undefined" && typeof dataParPersonne[indexPagePersonne].data[indexPersonne] != "undefined" && typeof dataParPersonne[indexPagePersonne].data[indexPersonne]["Ordre des tables"] != "undefined"){
                        delete dataParPersonne[indexPagePersonne].data[indexPersonne]["Ordre des tables"]
                    }
                })
        })
    }


}