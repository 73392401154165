import {useMutation, useQueryClient} from "react-query";
import api from "@services/api";

export default function useRotateUploadImage(props) {

    return useMutation(rotateImage, props);
}

export function rotateImage(data) {
    return api.post(`upload/${data.id}/rotation`, { json: data.rotate }).json();
}