import {faPen, faPlus, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGetExpediteurs from "@requests/communication/expediteur/useGetExpediteurs";
import { Accordion, Alert, Button, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {useQueryClient} from "react-query";
import useChangeExpediteur from "@requests/communication/expediteur/useChangeExpediteur";
import React, {useEffect, useMemo, useState} from "react";
import useDeleteExpediteur from "../../../requests/communication/expediteur/useDeleteExpediteur";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import {emailPattern} from "../../../functions/patterns";
import {useDebounce} from "use-debounce";
import SearchBar from "../../../components/SearchBar/SearchBar";
import DomaineExpediteurSelection from "../DomaineExpediteurSelection/DomaineExpediteurSelection";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";
import ExpediteurSelection from "../../communication/ExpediteurSelection/ExpediteurSelection";
import useGetExpediteurDefault from "../../../requests/communication/expediteur/useGetExpediteurDefault";
import useChangeExpediteurDefault from "../../../requests/communication/expediteur/useChangeExpediteurDefault";
import toast from "react-hot-toast";

function ExpediteurSettings() {
    const queryClient = useQueryClient();
    const [activeKey, setActiveKey] = useState();
    const [hiddenEditLines, setHiddenEditLines] = useState(false);
    const [hiddenAdd, setHiddenAdd] = useState(false);
    const deleteExpediteur = useDeleteExpediteur();
    const [update, setUpdate] = useState(0);
    const snapAuth = useSnapshot(auth);
    const paramCom = useGetExpediteurDefault();
    const changeParamCom = useChangeExpediteurDefault({
        onSuccess: () => {
            queryClient.invalidateQueries('parametreCommunication');
            toast.success('Expéditeur par défault sauvegardé', {
                duration:2500
            });
        },
        onError: (err) => {
            BtSwal.fire(err.message, '', 'error')
        }
    });
    const { register, handleSubmit, watch, setValue, control, reset } = useForm({
            defaultValues : paramCom?.data
        }
    );
    const search = watch('search')
    const [debouncedFilter] = useDebounce(search, 500);
    const expediteurs = useGetExpediteurs(debouncedFilter);
    const changeExpediteur = useChangeExpediteur({
        onSuccess: () => {
            queryClient.invalidateQueries('expediteur');
            queryClient.invalidateQueries('parametreCommunication');
            snapAuth.websocket.send("expediteur")
            setHiddenAdd(false)
            // BtSwal.fire('L\'expédieur a été ajouté!', '', 'success')
            setActiveKey(null);
        },
        onError: (err) => {
            BtSwal.fire(err.message, '', 'error')
        }
    });

    const [init, setInit] = useState(false);
    useEffect(() => {
        reset(paramCom?.data ?? {});
        if (paramCom.data){
            setInit(true)
        }
    }, [reset, paramCom?.data]);

    function onSave(data) {
        changeExpediteur.mutate(data);
    }

    function toggleAccordionKey(eventKey) {
        if (activeKey == eventKey) {
            setActiveKey(null);
            setHiddenAdd(false)
        }
        else {
            setActiveKey(eventKey);
            setHiddenAdd(true)
        }
    }

    useEffect(() => {
        document?.getElementById("searchBarId")?.focus();
    }, [expediteurs.isSuccess])

    if (!expediteurs.isError && paramCom.isError){
       return <Accordion activeKey={activeKey} className='mt-7'>
           <Alert variant='danger'>{paramCom?.error?.message} Merci d'enregistrer un expéditeur par défaut.</Alert>
           <Form.Group className="mt-5 mb-3 m-auto">
               <div className="d-flex align-items-center justify-content-center">
                   <Form.Label className='required me-2'>Expéditeur par défaut</Form.Label>
                   <ExpediteurSelection control={control} name='expediteurGlobalMail' rules={{ required: true }} init={init} />
                   <Button
                       className="ms-2"
                       variant={"secondary"}
                       onClick={handleSubmit(save)}
                   >
                       <FontAwesomeIcon icon={faSave} className='' />

                   </Button>
               </div>
           </Form.Group>
           <div className='d-flex flex-stack flex-wrap'>
               <SearchBar
                   solid
                   {...register('search')}
                   onClear={search?.length > 0 ? () => {
                       setValue('search', '');
                   } : null}
               />
               <div className='d-flex'>
                   <div hidden={hiddenAdd ? hiddenAdd : hiddenEditLines} className='mx-auto fw-bold'>
                       <Button
                           variant={"secondary"}
                           className='px-20'
                           onClick={() => {
                               toggleAccordionKey('new')
                               setHiddenEditLines(true);
                               setUpdate(update+1);
                           }}
                       >
                           <FontAwesomeIcon icon={faPlus} className='me-2' />
                           Ajouter un expéditeur
                       </Button>
                   </div>
               </div>
           </div>
           <div className='mt-10'>
               <Accordion.Collapse eventKey={'new'}>
                   <div className='bg-light rounded mt-3 pt-3 mx-10'>
                       <ExpediteurForm onSave={onSave} update={update} setHiddenEditLines={setHiddenEditLines} toggleAccordionKey={toggleAccordionKey}  />
                   </div>
               </Accordion.Collapse>
           </div>
       </Accordion>;

    }

    if (expediteurs.isError || (paramCom.isError && expediteurs.isError))
        return <Alert variant='danger'>{expediteurs.error?.message} {paramCom?.error?.message}</Alert>;

    if (expediteurs.isLoading || paramCom.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    function save(data){
        let defaultExpediteur = {
            expediteurGlobalMail : {
                idAgence : snapAuth?.agence?.id,
                id : data?.expediteurGlobalMail.id
            }
        }
        changeParamCom.mutate(defaultExpediteur);
    }

    return <Accordion activeKey={activeKey} className='mt-7'>
        <Form.Group className="mt-5 mb-3 m-auto">
            <div className="d-flex align-items-center justify-content-center">
                <Form.Label className='required me-2'>Expéditeur par défaut</Form.Label>
                <ExpediteurSelection control={control} name='expediteurGlobalMail' rules={{ required: true }} init={init} />
                <Button
                    className="ms-2"
                    variant={"secondary"}
                    onClick={handleSubmit(save)}
                >
                    <FontAwesomeIcon icon={faSave} className='' />

                </Button>
            </div>
        </Form.Group>
        <div className='d-flex flex-stack flex-wrap'>
            <SearchBar
                solid
                {...register('search')}
                onClear={search?.length > 0 ? () => {
                    setValue('search', '');
                } : null}
            />
            <div className='d-flex'>
                <div hidden={hiddenAdd ? hiddenAdd : hiddenEditLines} className='mx-auto fw-bold'>
                    <Button
                        variant={"secondary"}
                        className='px-20'
                        onClick={() => {
                            toggleAccordionKey('new')
                            setHiddenEditLines(true);
                            setUpdate(update+1);
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} className='me-2' />
                        Ajouter un expéditeur
                    </Button>
                </div>
            </div>
        </div>
        {expediteurs.data?.data?.map(exp => {
            return <div hidden={hiddenEditLines} className='bg-light my-2 p-3 rounded' key={exp.id}>
                <div className='d-flex align-content-center align-items-center'>
                    <div className='me-auto fw-bold'>
                        {exp.libelleAffiche}
                        <span className='fw-bolder text-info'> ({exp.email})</span>
                    </div>
                    <button
                        onClick={() => toggleAccordionKey(exp.id)}
                        className='btn-sm btn btn-quaternaire p-1 ps-2 pe-2'
                    >
                        <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button
                        onClick={() => BtSwal.fire({
                            title: 'Êtes vous sur de vouloir supprimer l\'expédieur '+exp.libelleAffiche+" ?",
                            showDenyButton: true,
                            confirmButtonText: `Oui`,
                            denyButtonText: `Annuler`,
                        }).then((result) => {
                            if (result.isConfirmed){
                                deleteExpediteur.mutate(exp.id);
                                BtSwal.fire('L\'expédieur a été supprimé!', '', 'success')
                                setHiddenAdd(false)
                            } else if (result.isDenied) {
                                BtSwal.fire('L\'expédieur n\'a pas été supprimé', '', 'info')
                            }
                        })}
                        className='btn-sm btn btn-secondary p-1 ps-2 pe-2'
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
                <Accordion.Collapse eventKey={exp.id}>
                    <div className='mt-3'>
                        <div className='separator' />
                        <ExpediteurForm expediteur={exp} update={update} setHiddenAdd={setHiddenAdd} onSave={onSave} />
                    </div>

                </Accordion.Collapse>
            </div>;
        })}
        <div className='mt-10'>
            <Accordion.Collapse eventKey={'new'}>
                <div className='bg-light rounded mt-3 pt-3 mx-10'>
                    <ExpediteurForm onSave={onSave} update={update} setHiddenEditLines={setHiddenEditLines} toggleAccordionKey={toggleAccordionKey}  />
                </div>
            </Accordion.Collapse>
        </div>
    </Accordion>;
}

function ExpediteurForm({ expediteur, onSave, setHiddenEditLines, setHiddenAdd, toggleAccordionKey, update }) {
    const convertedExediteur = useMemo(
        () => ({
            ...expediteur,
            libelleAffiche: expediteur?.libelleAffiche ? expediteur.libelleAffiche : null,
            email : expediteur?.id ? expediteur.email.split("@")[0] : "",
            domaine : expediteur?.id ? expediteur.email.split("@")[1] : null,
        }),
        [expediteur, update],
    );

    const { register, handleSubmit, control, setValue, getValues, reset,
        formState: { errors } } = useForm({
        defaultValues: convertedExediteur,
    });

    useEffect(() => {
        reset(convertedExediteur)
    }, [convertedExediteur, reset, update])

    function handleSubmitSave(data) {
        if (setHiddenEditLines) {
            setHiddenEditLines(false)
        }
        if (setHiddenAdd){
            setHiddenAdd(false)
        }
        data = {
            ...data,
            email : data.email+"@"+data.domaine
        }
        delete data.domaine;
        if (emailPattern().test(data.email)){
            onSave(data)
        }else {
            BtSwal.fire('Erreur d\'adresse email', "", "error")
        }
    }

    return <div className='mt-5 mx-10'>
        <Form.Group>
            <Form.Label>Libellé</Form.Label>
            <Form.Control maxLength={50} {...register('libelleAffiche', {required : true})} />
            {errors.libelleAffiche && <Form.Text className='text-danger'>Libelle requis</Form.Text>}
        </Form.Group>
        <Form.Group className='mt-5'>
            <Form.Label>Email</Form.Label>
            <div className="input-group input-group mb-5">
                <Form.Control maxLength={100} onKeyUp={(str) => {
                    if (str.target.value.indexOf("@") != -1){
                        setValue("email", str.target.value.replace("@", ""))
                        BtSwal.fire("Merci de renseigner le nom de domaine dans la liste déroulante à droite", "", "info")
                    }
                }} {...register('email', {required : true})} />
                <label className="input-group-text">@</label>
                <DomaineExpediteurSelection control={control} name='domaine' rules={{ required: true }}/>
            </div>
            {errors.email && <Form.Text className='text-danger'>Email incorrect</Form.Text>}
            {errors.domaine && <Form.Text className='text-danger'>Merci de selectionner un domaine</Form.Text>}
        </Form.Group>
        <div className='mt-2 d-flex justify-content-center'>
            <Button
                variant='secondary'
                className='btn-md mb-2'
                onClick={handleSubmit(handleSubmitSave)}
            >{expediteur?.id != null ? 'Enregistrer' : 'Ajouter'}</Button>
            <span className="m-2"></span>
            {expediteur?.id == null && <Button
                variant='danger'
                className='btn-md mb-2'
                onClick={() => {
                    setHiddenEditLines(false)
                    toggleAccordionKey('new')
                }}
            >Annuler</Button>
            }
        </div>
    </div>;
}

export default ExpediteurSettings;