import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";
//
export default function useAddResponseAboutSujet(idSujet, idAgence, corps, objet, personne) {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    let jsonData = {
        idAgence: idAgence,
        idSujet: idSujet,
        isPublie: true,
        objet: objet,
        corps: corps,
        auteur: {
            personne : personne
        }
    }


    return useMutation(() => {
        return api.post('messageSujetForum', {
            json: jsonData,
            retry: {
                limit : 0
            }
        }).json();
    }, {
        onSuccess: (res) => {
            queryClient.invalidateQueries("messageSujetForum"+idSujet);
            snapAuth.websocket.send("messageSujetForum"+idSujet)
        },
        onError: () => {
            queryClient.invalidateQueries("messageSujetForum"+idSujet);
            snapAuth.websocket.send("messageSujetForum"+idSujet)

        }
    });
}