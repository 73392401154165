import { useMutation } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import Qs from "qs";

export default function useGetParticipantsSuppleant() {

    return useMutation(participants, {
        onError: (err) => {
            toast.error(err.message);
        },
    });
}

function participants(id) {
    if (id != null){
        //todo peut être rajouter un filtre sur l'état présence. A réfléchir
        let filters = {
            columns: {},
            order: {}
        };

        filters.columns[0] = {
            data: "isMaitreDuTemps",
            searchable: true,
            orderable: true,
            searchOperator: "=",
            search: {
                //1==false 0==true
                value: 1,
                regex: false,
            },
        };
        filters.columns[1] = {
            data: "isMaitreDuTempsSuppleant",
            searchable: true,
            orderable: true,
            searchOperator: "=",
            search: {
                //1==false 0==true
                value: 0,
                regex: false,
            },
        };

        filters.columns[2] = {
            data: "id",
            searchable: true,
            orderable: true,
            search: {
                value: "",
                regex: false,
            },
        }
        filters.order[0] = {
            column:2,
            dir: "asc"
        }
        return api.get(`rencontreSpeedBusiness/${id}/participants`, {
            searchParams: Qs.stringify({
                start: 0,
                length: 1000,
                ...filters
            }, { arrayFormat: 'brackets' }),
        }).json();
    }
}