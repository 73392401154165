import useGetDestinataireResponseInvitation from "@requests/communication/invitations/useGetDestinataireResponseInvitation";
import { useMemo } from "react";
import { useController } from "react-hook-form";
import Select2 from "@components/Select2/Select2";
import { Spinner, Alert } from "react-bootstrap";

function DestinataireResponseInvitationSelection({ control, name, rules }) {
    const destinataire = useGetDestinataireResponseInvitation();
    const { field } = useController({ control, name, rules });

    const data = useMemo(() => {
        return destinataire.data?.data?.map(exp => ({
            id: exp.id,
            text: `${exp.email}`,
        }));
    }, [destinataire.data]);

    if (destinataire.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    if (destinataire.isError)
        return <Alert variant='danger'>{destinataire.error.message}</Alert>

    return <Select2 solid
                    multiple={true}
                    data={data}
                    selected={field.value != null ?  Object.keys(field.value) : null}
                    minimumResultsForSearch={3}
                    placeholder='Sélectionner une ou plusieurs adresses'
                    onChange={(selected) => {
                        let res = {};
                        destinataire.data?.data.forEach(item => {
                            if (selected.includes(item.id.toString()))
                                res[item.id] = item;
                        });
                        field.onChange(res);
                    }}
    />
}

export default DestinataireResponseInvitationSelection;