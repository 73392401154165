import {useSnapshot} from "valtio";
import speedBusinessProxy from "../../../../proxies/speedBusiness";
import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";
import {useQueryClient} from "react-query";
import auth from "../../../../services/auth";
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import invitationsProxy from "../../../../proxies/invitations";
import useValidPresence from "../../../../requests/communication/speedBusiness/useValidPresence";
import useGetQueryParticipants from "../../../../requests/communication/speedBusiness/useGetQueryParticipants";
import {BtSwal} from "../../../../utils/alerts/sweetAlert";
import annuaireProxy from "../../../../proxies/annuaire";
import { ReactComponent as Art005 } from "@assets/icons/art005.svg";
import {Alert, Button, Form, InputGroup} from "react-bootstrap";
import Select2 from "../../../../components/Select2/Select2";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import MetaTable from "../../../../components/MetaTable/MetaTable";
import {baseURL} from "../../../../config";

const TutorielEtPresencesMaitreDuTemps = forwardRef((_, ref) => {
    const speedSnap = useSnapshot(speedBusinessProxy);
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const [debouncedSearch] = useDebounce(search, 300);
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const [idEtatPresence, stateIdEtatPresence] = useState("");
    const [prenom, setPrenom] = useState("");
    const [prenomNom, setPrenomNom] = useState("");
    const [nom, setNom] = useState("");
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const validerPresence= useValidPresence();
    const [totalRows, setTotalRows] = useState(0);
    useEffect(() => {
        stateIdEtatPresence("")
        setPrenom("")
        setPrenomNom("")
        setNom("")
    }, [speedSnap.showJourJModal]);

    const participantsMaitreDuTemps = useGetQueryParticipants(
        speedSnap.speedBusiness?.id,
        {
            data: "isMaitreDuTemps",
            searchable: true,
            orderable: true,
            searchOperator: "=",
            logic:"OR",
            search: {
                //1==false 0==true
                value: 0,
                regex: false,
            },
        },
        {
            data: "etatPresence",
            searchable: true,
            orderable: true,
            searchOperator: "IN",
            search: {
                value: "0,2",
                regex: false,
            },
        },
        "maitreDuTempsEtSuppleant",
        {
            data: "isMaitreDuTempsSuppleant",
            searchable: true,
            orderable: true,
            searchOperator: "=",
            logic:"OR",
            search: {
                //1==false 0==true
                value: 0,
                regex: false,
            },
        },
    );

    const colSearch = useMemo(() => {
        return {
            // 'etatPresence': idEtatPresence,
            'participant.personne.prenom': prenom,
            'participant.personne.nom': nom,
            'participant.personne.prenomNom': prenomNom,
            'isMaitreDuTemps': 0,
            'isMaitreDuTempsSuppleant': 0,
        };
    }, [idEtatPresence, prenom, nom, prenomNom]);

    useImperativeHandle(ref, () => ({
        save: async () => {
            let done = true;
            //todo -> si les maitres du temps et maitres suppléant ne sont pas tous présent -> déclanche erreur.
            let nbMaitreDuTempsEtSuppleantRequis = speedSnap?.speedBusiness?.nombreTablesChoisies;
            let hasMaitreDuTempsSuppleant = false;
            if ((speedSnap?.speedBusiness?.nombreTablesChoisies + 1) == speedSnap?.speedBusiness?.nombreTours){
                nbMaitreDuTempsEtSuppleantRequis = nbMaitreDuTempsEtSuppleantRequis + (speedSnap?.speedBusiness?.nombreTablesChoisies-1);
                hasMaitreDuTempsSuppleant = true;
            }
            if (nbMaitreDuTempsEtSuppleantRequis != participantsMaitreDuTemps?.data?.nbLignesTotales){
                    let result = await BtSwal.fire(
                        {
                            title: "Attention il y a seulement "+participantsMaitreDuTemps?.data?.nbLignesTotales+ " maitres du temps" + (hasMaitreDuTempsSuppleant ? " et suppléants" : "")+ " présent sur " + nbMaitreDuTempsEtSuppleantRequis + " requis. Si les présences sont terminée, il faudra retourner dans la gestion des maitres du temps pour rajouter les maitres du temps manquants.",
                            showDenyButton: true,
                            confirmButtonText: `Retourner aux présences`,
                            denyButtonText:"Retourner a la gestion des maitres du temps",
                            icon: 'warning',
                        })
                    if (!result.value){
                        speedBusinessProxy.showMaitreDuTempsModal = true;
                    }
                    done = false;
            }
            return done;
        },
    }));


    const tableRef = useRef();

    const columns = useMemo(() => {

        let tmp =  [
            {
                Header: 'Id',
                accessor: 'participant.personne.id',
                minWidth: 80,
                maxWidth: 80,
                Cell: ({row}) => {
                    return <span>{row?.original?.participant?.personne?.id} <i style={{
                        color: row?.original?.isMaitreDuTemps ? "gold" : "silver"
                    }} className="fa fa-crown"></i></span>;
                }
            },
            {
                Header: 'etatInvitation',
                accessor: 'etatInvitation',
                hidden: true
            },
            {
                Header: 'Passer en maitre du temps',
                accessor: 'isMaitreDuTemps',
                searchOperator: "=",
                logic: "OR",
                hidden: true
            },
            {
                Header: 'isMaitreDuTempsSuppleant',
                accessor: 'isMaitreDuTempsSuppleant',
                searchOperator: "=",
                logic: "OR",
                hidden: true
            },
            {
                Header: 'etatPresence',
                accessor: 'etatPresence',
                hidden: true
            },
            {
                Header: 'prenomNom',
                accessor: 'participant.personne.prenomNom',
                hidden: true
            },
            {
                Header: 'nomPrenom',
                accessor: 'participant.personne.nomPrenom',
                hidden: true
            },
            {
                Header: 'prenom',
                accessor: 'participant.personne.prenom',
                hidden: true,
            },
            {
                Header: 'Participant',
                accessor: 'participant.personne.nom',
                minWidth: 300,
                Cell: ({row}) => {
                    let result = [];
                    result.push(<div class="me-7">
                        <div class="symbol symbol-fixed d-flex align-items-center position-relative">
                            <img src={row.original.participant.personne?.upload?.urlMiniature} alt="image personne"/>
                            <div className="ms-3 d-flex">
                                <p className="me-2">{row.original.participant.personne.prenom + " " + row.original.participant.personne.nom}</p>
                                <a
                                    onClick={() => annuaireProxy.showPersonne(row.original.participant.personne, false, "personne", true)}
                                    className='svg-icon svg-icon-1 cursor-pointer'><Art005/></a></div>
                        </div>
                    </div>);
                    result.push()
                    // result.push((row.original.destinataire.tel1 ? row.original.destinataire.tel1 + " " : "") + row.original.destinataire.tel2 ? row.original.destinataire.tel2 : "")
                    return result;
                },
                Footer: info => {
                    return <Form.Control
                        onKeyUp={(e) => {
                            setPrenomNom(e.target.value)
                        }}
                        className={`form-control`}
                        placeholder='Rechercher prénom nom'
                    />
                },
            },
            {
                Header: 'Téléphones',
                accessor: 'participant.personne.tel1',
                minWidth: 200,
                Cell: ({row}) => {
                    let result = [];
                    result.push(row.original.participant.personne.tel1 ? row.original.participant.personne.tel1 + " " : "")
                    result.push(<br/>)
                    result.push(row.original.participant.personne.tel2 ? row.original.participant.personne.tel2 : "")
                    return result;
                },
            },
            {
                Header: 'Email',
                accessor: 'participant.personne.email',
                minWidth: 250,
            },
            {
                Header: 'Présence rencontre',
                id: 'presenceEvenement',
                accessor: 'tabEtatsPresence',
                minWidth: 240,
                maxWidth: 240,
                Cell: ({row}) => {
                    const etatsPresence = (row.original.tabEtatsPresence).map((value, index) =>
                        <label
                            key={index}
                            className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${value.actif ? `active` : ``}`}
                            data-kt-button="true">
                            <input
                                onClick={(err) => {
                                    validerPresence.mutate({
                                        id: row.original.id,
                                        idEtatPresence: value.id
                                    })
                                }}
                                className="btn-check" type="radio" name="method" value={value.id}/>
                            <span style={{fontSize: '14px'}}>{value.libelle}</span></label>
                    );

                    const etatsPresenceDisabled = (row.original.tabEtatsPresence).map((value, index) =>
                        <label
                            key={index}
                            className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success disabled ${value.actif ? `active` : ``}`}
                            data-kt-button="true">
                            <input
                                className="btn-check" type="radio" name="method" value={value.id}/>
                            <span style={{fontSize: '10px'}}>{value.libelle}</span></label>
                    );

                    return <div className="btn-group" data-kt-buttons="true"
                                data-kt-buttons-target="[data-kt-button]">
                        {true ? etatsPresence : etatsPresenceDisabled}
                    </div>
                },
                Footer: info => {
                    let tabEtatsPresenceSelect = [
                        {id: 0, text: "Présent"},
                        {id: 1, text: "Absent"},
                        {id: 2, text: "En retard"},
                    ]
                    return <Select2
                        data={tabEtatsPresenceSelect}
                        closeOnSelect={true}
                        allowClear={true}
                        placeholder='Filtre présence'
                        onChange={(selected) => {
                            selected = selected?.at(0);
                            stateIdEtatPresence(selected)
                        }}/>
                },
            },
        ]
        return tmp;
    }, [])


    return <div style={{
        maxWidth: 1100
    }} className="m-auto">
        <div style={{
            width: 800,
            margin: "auto",
            marginBottom: 10,
            height: "fit-content",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            borderRadius: 10,
            border: "solid 1px var(--primary-color)"
        }}>
            <p className="w-100 fs-6 fw-bold pt-5 ps-5 pe-5">Le maître du temps est affecté à une table pour gérer le temps de parole de chaque participant ainsi que l'ordre de passage. C'est le premier à parler.</p>
            <p className="w-100 fs-6 fw-bold ps-5 pe-5">Pour l'aider dans sa mission, nous vous invitons à lui envoyer le lien de suivi de sa table.</p>
        </div>
        <div className="d-flex">
            <Button onClick={(event) => {
                navigator.clipboard.writeText(baseURL + "/uploads/cap/userfiles/files/tutoMaitreDuTemps.pdf").then(() => {
                    window.open(baseURL + "/uploads/cap/userfiles/files/tutoMaitreDuTemps.pdf", "_blank")
                    event.target.innerHTML = "";
                    event.target.classList.add('fa', 'fa-check');
                })
            }} variant="secondary" id="button-addon2">
                <span style={{color: "white"}}>Tutoriel maitre du temps</span>
            </Button>
        </div>
        <h3>Liste des maitres du temps</h3>
        <div className="d-flex justify-content-between">
            <div className="w-400px">
                <SearchBar
                    solid
                    {...register('search')}
                    onClear={search?.length > 0 ? () => {
                        setValue('search', '');
                    } : null}
                />
            </div>
            <div>
                <div>
                    <i style={{
                        color: "gold"
                    }} className="fa fa-crown me-2"></i>Maitre du temps
                </div>
                <div>
                    <i
                        style={{
                            color: "silver"
                        }}
                        className="fa fa-crown me-2"></i>Maitre du temps suppléant
                </div>
            </div>
        </div>
        <MetaTable
            ref={tableRef}
            className='mt-5'
            height={500}
            url={`rencontreSpeedBusiness/${speedSnap.speedBusiness?.id}/participants`}
            keys={['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'participants']}
            columns={columns}
            search={debouncedSearch}
            setTotalRow={setTotalRows}
            colSearch={colSearch}
            showFiltres={true}/>
    </div>
});

export default TutorielEtPresencesMaitreDuTemps;