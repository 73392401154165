import { ReactComponent as Logo } from "@assets/logo.svg";
import useAfficherInvitationByMail from "../../../requests/communication/invitations/useAfficherInvitationByMail";
import LoadingView from "../../LoadingView/LoadingView";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

function ValidInvalidInvitationView() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get('ref') ? urlParams.get('ref').replace(/ /g, "+") : "";
    const afficherInvitation = useAfficherInvitationByMail(ref);
    const snapAuth = useSnapshot(auth);

    document.head.querySelector("#meta").setAttribute("content", "width=device-width")
    // alert(window.devicePixelRatio)
    if (afficherInvitation.isError){
        return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
            <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
                <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                    <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                        <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Afficher l'invitation</h1>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column flex-lg-row-fluid py-10 card'>
                <div className='d-flex flex-center flex-column flex-column-fluid card-body'>
                    <div className='w-lg-500px p-10 p-lg-15 mx-auto'>
                        <p>{afficherInvitation.error?.message} </p>
                    </div>
                </div>
            </div>
        </div>;
    }

    if (!afficherInvitation.isSuccess){
        return <LoadingView/>
    }

    return <div dangerouslySetInnerHTML={{ __html: afficherInvitation.data.corpsHtml }} />

}

export default ValidInvalidInvitationView;
