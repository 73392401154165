import { useController } from "react-hook-form";
import Select2 from "@components/Select2/Select2";
import {useEffect, useMemo, useState} from "react";
import useGetProfil from '@requests/profil/useGetProfil';
import { Alert, Spinner } from "react-bootstrap";
import {useSnapshot} from "valtio";
import annuaireProxy from "../../../../proxies/annuaire";
import useGetEntreprisePersonne from "../../../../requests/personne/useGetEntreprisesPersonne";

function ProfilSelection({ control, name, isEntreprise, isPersonne, solid }) {
    const profils = useGetProfil();
    const annuaire = useSnapshot(annuaireProxy);
    const entreprises = useGetEntreprisePersonne(annuaire.personne?.id, annuaire.isUtilisateur)
    const [stateEntreprises, setStateEntreprises] = useState([]);
    const { field } = useController({ control, name });
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        setUpdate(true);
        if (annuaire.personne?.id){
            entreprises.refetch().then((data) => {
                setStateEntreprises(data?.data?.data)
            })
        }else {
            setStateEntreprises([])
        }
        setTimeout(() => setUpdate(false), 500)
    }, [annuaire.personne?.id, annuaire.isUtilisateur, annuaire.initTab])

    const data = useMemo(() => {
        const filtered = profils.data?.data?.filter(value => {
            return (isEntreprise && value.pourEntreprise) || (isPersonne && value.pourPersonne);
        }) ?? [];

        return filtered.map(obj => ({
            id: obj.id,
            text: obj.libelle,
        }));
    }, [profils.data, isEntreprise, isPersonne, annuaire?.personne?.id]);

    useEffect(() => {
        if (profils?.data?.data && annuaire.initTab == "entreprise" && annuaire.partenaireIsSelectedAndNoEntreprisePartenaire && isPersonne){
            let addPartenaire = profils.data.data.find(value => value.pourPersonne && value.isPartenaire)
            field.onChange({...field.value,
                [addPartenaire.id]: addPartenaire
            })
        }
    }, [annuaire.initTab, annuaire.partenaireIsSelectedAndNoEntreprisePartenaire, isEntreprise, isPersonne])

    // useEffect(() => {
    //     if (entreprises.isSuccess && profils.isSuccess){
    //         let entreprisePartenaire = false;
    //         annuaireProxy.partenaireIsSelectedAndNoEntreprisePartenaire = false;
    //         entreprises.data?.data.forEach((entreprise) => {
    //             if (!!Object.values(entreprise.tabProfils).find(item => item.isPartenaire)){
    //                 entreprisePartenaire = true;
    //             }
    //         })
    //         profils.data?.data.forEach(item => {
    //             if (field.value && Object.keys(field.value).includes(item.id.toString()) && (item.isPartenaire && !entreprisePartenaire)){
    //                 annuaireProxy.partenaireIsSelectedAndNoEntreprisePartenaire = true;
    //             }
    //         })
    //     }
    // }, [annuaire.entreprise])


    if (profils.isLoading || entreprises.isLoading || update)
        return <div className='text-center'><Spinner animation='border' /></div>;

    if (profils.isError || entreprises.isError)
        return <Alert variant='danger'>{profils.error.message}</Alert>



    return <Select2
        multiple={true}
        data={data}
        large
        solid={solid}
        minimumResultsForSearch={3}
        selected={field.value != null ? Object.keys(field.value) : null}
        onChange={(selected) => {
            let res = {};
            let entreprisePartenaire = false;
            annuaireProxy.partenaireIsSelectedAndNoEntreprisePartenaire = false;
            stateEntreprises.forEach((entreprise) => {
                if (!!Object.values(entreprise.tabProfils).find(item => item.isPartenaire)){
                    entreprisePartenaire = true;
                }
            })
            profils.data?.data.forEach(item => {
                if (isPersonne){
                    if (selected.includes(item.id.toString()) && (!item.isPartenaire || entreprisePartenaire)) {
                        res[item.id] = item;
                    }else if (selected.includes(item.id.toString()) && (item.isPartenaire && !entreprisePartenaire)){
                        annuaireProxy.partenaireIsSelectedAndNoEntreprisePartenaire = true;
                        res[item.id] = item;
                        // BtSwal.fire("Entreprise partenaire manquante", "Afin d'ajouter le profil partenaire à une personne vous devez auparavant lui ajouter une entreprise aillant le profil partenaire.", "warning")
                    }
                }else {
                    if (selected.includes(item.id.toString())){
                        res[item.id] = item;
                    }
                }
            });
            field.onChange(res);
        }}
    />;
}

export default ProfilSelection;