import MailEditor from "@components/MailEditor/MailEditor";
import React, {useEffect, useRef, useState} from "react";
import mailsProxy from "@proxies/mails";
import { BtSwal } from "@utils/alerts/sweetAlert";
import {Alert, Button, Form, Modal, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useQueryClient} from "react-query";
import ExpediteurSelection from "../../communication/ExpediteurSelection/ExpediteurSelection";
import {useForm} from "react-hook-form";
import useChangeEmailInscriptionInvite from "../../../requests/communication/mails/useChangeEmailInscriptionInvite";
import useGetEmailInscriptionInvite from "../../../requests/communication/mails/useGetEmailInscriptionInvite";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

function EmailInscriptionInviteSettings() {
    const queryClient = useQueryClient();
    const editorRef = useRef();
    const snapAuth = useSnapshot(auth);
    const changeMail = useChangeEmailInscriptionInvite({
        onSuccess: (res) => {
            queryClient.invalidateQueries('emailInscriptionInvite')
            snapAuth.websocket.send("emailInscriptionInvite")

            BtSwal.fire('Mail d\'inscription invité modifié', '', 'success')
        },
    });
    const mailInscriptionInvite = useGetEmailInscriptionInvite();
    const [arrayMotCle, setArrayMotCle] = useState(null);
    const [showModal, setShowModal] = useState(false);


    function save(){

        const mailBody = editorRef.current?.save();

        if (!(mailBody.json?.order?.length > 0)) {
            BtSwal.fire({
                title: 'Mail incomplet !',
                text: 'Vous devez ajouter au minimum une section au mail',
                icon: 'error',
                confirmButtonText: 'Ok',
            });
            return false;
        }

        const newMail = {
            corpsHtml: mailBody.html,
            corpsTemplate: mailBody.json,
            header: "",
            signature: "",
        };


        if (checkMotCleObligatoire(mailBody.html).isOk){
            try {
                const result = changeMail.mutate(newMail);
                mailsProxy.mail = result;
                return true;
            } catch {
                return false;
            }
        }else {
            setShowModal(true)
        }
    }



    if (mailInscriptionInvite.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    if (mailInscriptionInvite.isError)
        return <Alert variant='danger'>{mailInscriptionInvite.error.message}</Alert>


    return <div id='emailInscriptionInvite'><div className="d-flex justify-content-end mb-2">
        <Button
            variant='secondary'
            className='text-center'
            onClick={() => {
                save()
            }}
        >
            <FontAwesomeIcon className='fa fa-2x'  icon={faSave}/>

        </Button>
    </div>
        <MailEditor
            hiddenFooterHeader={true}
            ref={editorRef}
            value={mailInscriptionInvite.data.corpsTemplate}
        />
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size='md'
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Mots clés obligatoires</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="table-responsive">
                    <h1 className="text-center mt-3
                    ">Mots clés obligatoires manquants</h1>
                    <table className="m-auto table mt-4 table-row-dashed  table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                        <tr className="fw-bolder fs-6 text-gray-800 text-center border-0 bg-light">
                            <th className="w-300px rounded-start"></th>
                            <th className="w-100px">Présent</th>
                        </tr>
                        </thead>
                        <tbody className="border-bottom border-dashed">
                        {arrayMotCle}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    </div>;

    function checkMotCleObligatoire(corps){
        let arrAsso = {
            '{dateEvenement}' : {
                text : "Date événement",
                isCheck : false
            },
            '{prenom}' : {
                text : "Prenom",
                isCheck : false
            },
            '{typeEvenement}' : {
                text : "Type événement",
                isCheck : false
            },
            '{adresseEvenement}' : {
                text : "Adresse événement",
                isCheck : false
            },
            '{codePostalEvenement}' : {
                text : "Code postal événement",
                isCheck : false
            },
            '{villeEvenement}' : {
                text : "Ville événement",
                isCheck : false
            },
            '{prenomNomHote}' : {
                text : "Prénom nom hôte",
                isCheck : false
            },
            '{coordonneesContactHote}' : {
                text : "Coordonnées hôte",
                isCheck : false
            },
        }
        const arr = [
            '{dateEvenement}',
            '{prenom}',
            '{typeEvenement}',
            '{adresseEvenement}',
            '{codePostalEvenement}',
            '{villeEvenement}',
            '{prenomNomHote}',
            '{coordonneesContactHote}',
        ]

        let tabResult =  []
        let allIsOk = true;

        arr.forEach(function(word) {
            if (corps.indexOf(word) > -1){
                arrAsso[word].isCheck = true
            }else {
                allIsOk = false;
            }
            return  corps.indexOf(word) > -1
        })

        Object.values(arrAsso).forEach(e => {
            tabResult.push(<tr className="text-center">
                <td className="text-start ps-6">
                    <div className="fw-bold fs-4 text-gray-800">{e.text}</div>
                </td>
                <td>
                    {e.isCheck ? <FontAwesomeIcon icon={faCheck} color={"green"}/> : <FontAwesomeIcon icon={faTimes} color={"red"}/>}
                </td>
            </tr>)
        })

        setArrayMotCle(tabResult)

        return {
            isOk : allIsOk,
            data : arrAsso
        }
    }

}

export default EmailInscriptionInviteSettings;