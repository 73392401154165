import { useQuery } from "react-query";
import baseApi from "../../../services/apiPublic";

export default function useAfficherPublication(ref) {
    return useQuery(
        ['publicationMobile'],
        () => baseApi.post('publicationMobile/afficher', {
            json: {
                ref : ref
            }
        }).json(),
    );
}