import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Button, Dropdown, Form, Modal} from "react-bootstrap";
import { useSnapshot } from "valtio";
import invitationsProxy from "@proxies/invitations";
import MetaTable from "../../../../components/MetaTable/MetaTable";
import React, {useEffect, useMemo, useState} from "react";
import { useDebounce } from "use-debounce";
import { useForm } from "react-hook-form";
import useSendInvitation from "@requests/communication/invitations/useSendInvitation";
import useResendInvitation from "@requests/communication/invitations/useResendInvitation";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import { French } from "flatpickr/dist/l10n/fr"
import SearchBar from "../../../../components/SearchBar/SearchBar";
import {showErrorAlert} from "../../../../utils/alerts/showErrorAlert";
import useResendInvitationForOneCorrespondant
    from "../../../../requests/communication/invitations/useResendInvitationForOneCorrespondant";
import auth from "../../../../services/auth";
import useReabonnementMail from "../../../../requests/annuaire/useReabonnementMail";
import {BtSwal} from "../../../../utils/alerts/sweetAlert";
import DestinatairesStep from "../../DestinatairesStep/DestinatairesStep";
import { ReactComponent as Art005 } from "@assets/icons/art005.svg";
import annuaireProxy from "../../../../proxies/annuaire";
const $ = require('jquery');

function SendModal({ onHide, invitation }) {
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const { register, watch, setValue, reset } = useForm();
    const resendForOneCorrepondant = useResendInvitationForOneCorrespondant();
    const search = watch('search');
    const [debouncedSearch] = useDebounce(search, 300);
    const queryClient = useQueryClient();
    const [dateEnvoi, setDateEnvoi] = useState(moment().format("DD/MM/YYYY HH:mm"));
    const [filterDateEnvoi, setFilterDateEnvoi] = useState("");
    const snapAuth = useSnapshot(auth);
    const reabonnement = useReabonnementMail();
    const [showAddDestinataire, setShowAddDestinataire] = useState(false);

    const colSearch = useMemo(() => {
        return {
            'dateEnvoiInvitation': filterDateEnvoi,
        };
    }, [filterDateEnvoi]);

    useEffect(() => {
        reset({search: ""});
    }, [invitationsSnapshot.sendModal.show]);

    const send = useSendInvitation({
        id: invitationsSnapshot.invitation?.id,
        date: dateEnvoi,
        onSuccess: () => {
            queryClient.invalidateQueries('invitations');
            snapAuth.websocket.send("invitations")

            toast.success('L\'envoi du mail a été pris en compte.', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    const resend = useResendInvitation({
        id: invitationsSnapshot.invitation?.id,
        date: dateEnvoi,
        onSuccess: () => {
            queryClient.invalidateQueries('invitations');
            snapAuth.websocket.send("invitations")

            toast.success('L\'envoi du mail a été pris en compte.', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    // const sendAsTest = useSendInvitationAsTest({
    //     id: invitationsSnapshot.invitation?.id,
    //     type: "invitation",
    //     onSuccess: () => {
    //         queryClient.invalidateQueries('invitations');
    //         toast.success('L\'envoi du mail a été pris en compte.', {
    //             duration:5000
    //         });
    //     },
    //     onError: (err) => {
    //         showErrorAlert("Erreur lors de l'enregistrement", err.message);
    //     },
    // });

    const columns = useMemo(() => [
        {
            Header: 'Id',
            accessor: 'destinataire.personne.id',
            minWidth: 70,
            maxWidth: 70,
        },
        {
            Header: () => "",
            id: 'wrench',
            Cell: ({ row }) => <div className="d-flex justify-content-center">
                <a
                    onClick={() => annuaireProxy.showPersonne(row.original.destinataire.personne, false, "personne", true)}
                    className='svg-icon svg-icon-1 cursor-pointer'><Art005 /></a></div>,

            width: 60,
        },
        {
            Header: 'Prénom',
            accessor: 'destinataire.personne.prenom',
            minWidth: 100,
        },
        {
            Header: 'Nom',
            accessor: 'destinataire.personne.nom',
            minWidth: 100,
        },
        {
            Header: 'prenomNom',
            accessor: 'destinataire.personne.prenomNom',
            hidden: true,
        },
        {
            Header: 'nomPrenom',
            accessor: 'destinataire.personne.nomPrenom',
            hidden: true
        },
        {
            Header: 'dateEnvoi',
            accessor: 'dateEnvoiInvitation',
            hidden: true,
        },
        {
            Header: 'État',
            id: 'etat',
            minWidth: 300,
            Cell: ({ row }) => {
                let result = [<span className='text-info'>En attente d'envoi</span>];
                if (row.original.destinataire?.personne?.email == null)
                    return <span className='text-warning'>Adresse mail manquante</span>;
                if (row.original.destinataire?.personne?.mailIsDesinscrit)
                    return <span className='text-danger'>Ne souhaite plus recevoir de mail
                <Dropdown.Item
                    className='menu-link px-3'
                    onClick={() => {
                        if (!row.original.destinataire?.personne?.mailIsDesinscrit){
                            BtSwal.fire({
                                title: `<b>${row.original.destinataire?.personne.prenom} ${row.original.destinataire?.personne.nom} n'est pas désinscrit des mails</b> `,
                                showDenyButton: false,
                                confirmButtonText: `Compris`,
                                icon: 'error',
                            })
                        }else {
                            reabonnement.mutate(row.original.destinataire?.personne.id)
                        }
                    }}
                >
                            <span className="fa-stack">
                                <i style={{fontSize:"20px"}} className="fa fa-envelope-open fa-stack-1x me-2"></i>
                                <i style={{color:"rgba(0,0,0,0.69)", top:"-10px", right:"-10px", fontSize:"20px"}} className="fa fa-bell fa-stack-1x"></i>
                            </span>
                            Réabonner aux mails
                        </Dropdown.Item>
                </span>;
                if (row.original.destinataire?.personne?.mailIsErrone)
                    return <span className='text-danger'>Adresse mail erronée</span>;
                if (row.original.dateEnvoiInvitation !== null ){
                    result = []
                    if (row.original.envoiInvitationIsFail){
                        result.push([<span className='text-danger'>L'envoi à échoué le {row.original.dateEnvoiInvitation}<FontAwesomeIcon icon={faTimes} className='ms-2' /></span>])
                    }else {
                        result.push([<span className='text-success'>Invitation envoyé le {row.original.dateEnvoiInvitation}<FontAwesomeIcon icon={faCheck} className='ms-2' /></span>]);
                    }
                }

                result.push(<br/>);result.push(<span
                    onClick={() => {
                        resendForOneCorrepondant.mutate(row.original.id)
                    }}
                    title="Renvoyer l'invitation"
                    className='svg-icon svg-icon-1 me-2 d-block text-center cursor-pointer mt-2'><img width={20} src="/icon/repeat.svg" alt="bell"/></span>)

                return result;
            }
        },
    ], []);



    return <Modal
        show={invitationsSnapshot.sendModal.show}
        onHide={() => invitationsProxy.sendModal.show = false}
        size='lg'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Envoyer l'invitation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='justify-content-center d-flex mt-5'>
                <Button variant='secondary' onClick={send.mutate}>Envoyer aux destinataires en attente</Button>
            </div>
            <div className='d-flex justify-content-center mt-5'>
                <div className="">
                    <Button
                        onClick={
                        () => {
                                if ($("#divDateEnvoi").hasClass('d-none')){
                                    $("#divDateEnvoi").removeClass('d-none')
                                }else {
                                    $("#divDateEnvoi").addClass('d-none')
                                }
                            }
                        }
                        className=''
                        variant='primary'>
                        Programmer l'envoi
                    </Button>
                    <div className={"d-none"}
                    id={"divDateEnvoi"}>
                        <Flatpickr
                            className={"form-control mt-2"}
                            display={"hidden"}
                            defaultValue={dateEnvoi}
                            onChange={date => {
                                setDateEnvoi(moment(date[0]).format("DD/MM/YYYY HH:mm"));
                            }}
                            options={{
                                locale: French,
                                dateFormat: 'd/m/Y à H:i',
                                enableTime: true,
                            }}
                        />
                        <Button className={"mt-2 m-auto"}
                                style={{width: "fit-content"}}
                                onClick={send.mutate}
                        >Valider</Button>
                    </div>
                </div>


            </div>
            {invitationsSnapshot?.invitation?.dateEnvoi && <div
                className="justify-content-center mt-5 d-flex">
                <Button variant='danger' onClick={resend.mutate}>Renvoyer à tous les destinataires</Button>
            </div>}
            <div className="mt-5">
                <h3>Liste des destinataires</h3>
                <SearchBar
                    solid
                    {...register('search')}
                    onClear={search?.length > 0 ? () => {
                        setValue('search', '');
                    } : null}
                />
                <div className="d-flex justify-content-between">
                    {filterDateEnvoi !== "NULL" ?
                        <Form.Check
                            className="mt-5"
                            checked={false}
                            type={"checkbox"}
                            id={`default-radio`}
                            label={`Afficher seulement les destinataires en attente`}
                            onClick={() => setFilterDateEnvoi("NULL")
                            }
                        />
                        :
                        <Form.Check
                            className="mt-5"
                            checked={true}
                            type={"checkbox"}
                            id={`default-radio`}
                            label={`Afficher seulement les destinataires en attente`}
                            onClick={() => setFilterDateEnvoi("")
                            }
                        />
                    }
                    <Button className="mt-2"
                        onClick={() => setShowAddDestinataire(true)}
                    >
                        + Ajouter un destinataire
                    </Button>
                </div>

                <MetaTable
                    className='mt-3'
                    height={400}
                    url={`invitation/${invitationsSnapshot.invitation?.id}/correspondants`}
                    keys={['invitation', invitationsSnapshot.invitation?.id, 'correspondants']}
                    columns={columns}
                    search={debouncedSearch}
                    colSearch={colSearch}

                />
            </div>
        </Modal.Body>
        <Modal
            fullscreen
            show={showAddDestinataire}
            onHide={() => {
                setShowAddDestinataire(false)
            }}
            dialogClassName='p-9'
            contentClassName='modal-rounded'
            enforceFocus={false}
            keyboard={false}
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <DestinatairesStep baseRoute='invitation' id={invitationsSnapshot.invitation?.id} />
            </Modal.Body>
            <Modal.Footer>
                <Button className="mt-2 btn-secondary"
                        onClick={() => setShowAddDestinataire(false)}
                >
                    Revenir à l'envoi
                </Button>
            </Modal.Footer>
        </Modal>
    </Modal>;
}

export default SendModal;