import {faEyeDropper, faFillDrip} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { BlockPicker } from "react-color";
import { ReactComponent as FillDrip } from "@assets/icons/fillDrip.svg";
import React from "react";

function ColorPicker({ color, onChange, show }) {
    return <Dropdown title={'Couleur de fond'} className={`${show ? '' : 'd-none'}`}>
        <Dropdown.Toggle className='hide-after' variant='white'>
            <span className='svg-icon svg-icon-1 me-2'><FillDrip/></span>
        </Dropdown.Toggle>
        <Dropdown.Menu className='p-0'>
            <BlockPicker color={color} onChange={(color) => onChange(color?.hex)} />
        </Dropdown.Menu>
    </Dropdown>;
}

export default ColorPicker;