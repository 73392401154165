import { useMutation } from "react-query";
import api from "@services/api";

export default function useDeleteBlocResponse(props) {

    return useMutation(deleteResponse, props);

}

function deleteResponse(id) {

    if (id != null)
        return api.delete(`blocReponseInvitation/${id}`).json();

}