import {Button, Card, Dropdown, Modal} from "react-bootstrap";
import {useEffect, useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPlus, faTimes, faWrench} from "@fortawesome/free-solid-svg-icons";
import MetaTable from "@components/MetaTable/MetaTable";
import mailsProxy from "@proxies/mails";
import moment from "moment";
import EditModal from "./EditModal/EditModal";
import SendModal from "./SendModal/SendModal";
import {ReactComponent as Art005} from "@assets/icons/art005.svg";
import {ReactComponent as Gen016} from "@assets/icons/gen016.svg";
import {ReactComponent as Gen027} from "@assets/icons/gen027.svg";
import {ReactComponent as Gra012} from "@assets/icons/gra012.svg";
import {ReactComponent as Gen034} from "@assets/icons/gen034.svg";
import {ReactComponent as Gen037} from "@assets/icons/gen037.svg";
import {ReactComponent as Reply} from "@assets/icons/reply.svg";
import {ReactComponent as MemoCicle} from "@assets/icons/memoCicle.svg";
import useDeleteMail from "../../../requests/communication/mails/useDeleteMail";
import useValidMail from "../../../requests/communication/mails/useValidMail";
import useInvalidMail from "../../../requests/communication/mails/useInvalidMail";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import {useQueryClient} from "react-query";
import RelanceModal from "./RelanceModal/RelanceModal";
import toast from "react-hot-toast";
import useSendForValidationDataTable from "../../../requests/communication/mails/useSendForValidationDataTable";
import useGetCorrespondants from "../../../requests/communication/useGetCorrespondants";
import SearchBar from "../../../components/SearchBar/SearchBar";
import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import useGetHeaders from "../../../requests/communication/header/useGetHeaders";
import useGetFooters from "../../../requests/communication/footer/useGetFooters";
import useGetCategoriesMail from "../../../requests/parametres/categoriesMail/useGetCategoriesMail";
import LoadingView from "../../LoadingView/LoadingView";
import { ReactComponent as Cod001 } from "@assets/icons/cod001.svg";
import HeaderSettings from "../../ParametresView/HeaderSettings.js/HeaderSettings";
import FooterSettings from "../../ParametresView/FooterSettings/FooterSettings";
import CategorieMailSettings from "../../ParametresView/CategorieMailSettings/CategorieMailSettings";
import useGetExpediteurs from "../../../requests/communication/expediteur/useGetExpediteurs";
import ExpediteurSettings from "../../ParametresView/ExpediteursSettings/ExpediteurSettings";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";
import DetailEmailModal from "./DetailEmailModal";
import useGetExportStatMail from "../../../requests/communication/mails/useGetExportStatMail";
import ExportStatMailModal from "./ExportStatMail/ExportStatMailModal";

function MailsView() {
    const queryClient = useQueryClient();
    const deleteMail = useDeleteMail();
    const validMail = useValidMail();
    const invalidMail = useInvalidMail();
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const [searchFilter] = useDebounce(search, 300);
    const headers = useGetHeaders();
    const footers = useGetFooters();
    const categories = useGetCategoriesMail();
    const expediteurs = useGetExpediteurs();
    const snapAuth = useSnapshot(auth);
    const [showModalSettings, setShowModalSettings] = useState(false);
    const [currentComponentSettings, setCurrentComponentSettings] = useState(null);
    const [idExportMail, setIdExportMail] = useState(null);
    const exportStatMail = useGetExportStatMail(idExportMail);
    // const idVerrouSnap = useMemo(() => snapAuth.tabIdMailVerrou, [snapAuth.tabIdMailVerrou]);

    // console.log(snapAuth.tabIdMailVerrou)
    useEffect(() => {
        if (idExportMail){
            mailsProxy.showExportStatMail(exportStatMail?.data)
        }
    }, [idExportMail, exportStatMail])

    const getCorrespondants = useGetCorrespondants({
        onSuccess: (res) => {
            mailsProxy.tabIdsDestinataires = res.data.map(data => data.destinataire.personne.id);
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    const colSearch =  {
            'isReadOnly' : 1,
        };

    const sendForValidation = useSendForValidationDataTable({
        onSuccess: () => {
            queryClient.invalidateQueries('emails');
            snapAuth.websocket.send("emails")
            toast.success('Le mail de validation a bien été envoyé.', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    //todo juste bloquer la modif du mail et ne pas faire disparaitre la  clé + vérifier que la dépendance sur les colones ne fou pas en l'air la mise a jour lors des recherches
    const columns = useMemo(() => [
        {
            Header: () => null,
            id: 'wrench',
            Cell: ({ row }) => <Dropdown>
                <Dropdown.Toggle className='hide-after btn-icon btn-quaternaire w-30px h-30px'>
                    <FontAwesomeIcon color={"black"} icon={faWrench} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='fs-6 w-350px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                if (snapAuth.tabIdMailVerrou.indexOf((row.original.id)) !== -1){
                                    BtSwal.fire("Attention", "L'email est verrouillé en édition par un autre utilisateur", "warning")
                                }else {
                                    mailsProxy.showEditMail(row.original)
                                    if (mailsProxy.mail?.corpsTemplate?.header){
                                        mailsProxy.mail.corpsTemplate.header = mailsProxy.mail.header?.upload?.urlOriginale
                                    }
                                    if (mailsProxy.mail?.corpsTemplate?.footer){
                                        mailsProxy.mail.corpsTemplate.footer = mailsProxy.mail.signature?.corps
                                    }
                                }
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Art005 /></span>
                            Modifier
                        </Dropdown.Item>
                    </div>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                let data = {
                                    id : row.original.id,
                                    baseRoute : "email"
                                }
                                let newMail = {
                                    ...row.original
                                };
                                newMail.id = null;
                                newMail.dateCreation = null;
                                newMail.dateEnvoi = null;
                                newMail.dateProgrammationEnvoi = null;
                                newMail.isValidePourEnvoi = false;
                                mailsProxy.showEditMail(newMail)
                                getCorrespondants.mutate(data)
                                if (mailsProxy.mail?.corpsTemplate?.header){
                                    mailsProxy.mail.corpsTemplate.header = mailsProxy.mail.header?.upload?.urlOriginale
                                }
                                if (mailsProxy.mail?.corpsTemplate?.footer){
                                    mailsProxy.mail.corpsTemplate.footer = mailsProxy.mail.signature?.corps
                                }
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Reply /></span>
                            Rédiger un email à partir de celui-ci
                        </Dropdown.Item>
                    </div>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            hidden={!row.original?.header?.id || !row.original?.signature?.id}
                            className='menu-link px-3'
                            onClick={() => mailsProxy.showSendMail(row.original)}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Gen016 /></span>
                            Envoyer
                        </Dropdown.Item>
                    </div>
                    <div className='menu-item px-3'>
                        {row.original.isValidePourEnvoi
                            ? <Dropdown.Item className='menu-link px-3' onClick={() => invalidMail.mutate(row.original.id)} > <span className='svg-icon svg-icon-1 me-2'><Gen034 /></span> Invalider le mail </Dropdown.Item>
                            : <Dropdown.Item className='menu-link px-3' onClick={() => validMail.mutate(row.original.id)}><span className='svg-icon svg-icon-1 me-2'><Gen037 /></span>Valider le mail</Dropdown.Item>
                        }

                    </div>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                if (row.original.id == idExportMail) {
                                    mailsProxy.showExportStatMail(exportStatMail?.data)
                                }
                                setIdExportMail(row.original.id)
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Gra012 /></span>
                            Statistiques
                        </Dropdown.Item>
                    </div>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => BtSwal.fire({
                                title: 'Êtes vous sur de vouloir supprimer le mail ?',
                                showDenyButton: true,
                                confirmButtonText: `Oui`,
                                denyButtonText: `Annuler`,
                            }).then((result) => {
                                if (result.isConfirmed){
                                    deleteMail.mutate(row.original.id);
                                    BtSwal.fire('Le mail a été supprimé!', '', 'success')
                                } else if (result.isDenied) {
                                    BtSwal.fire('Le mail n\'a pas été supprimer', '', 'info')
                                }
                            })}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Gen027 /></span>
                            Supprimer
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>,
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            minWidth: 70,
            maxWidth: 70,
            Cell: ({ value, row }) => {
                if (snapAuth.tabIdMailVerrou.indexOf((row.original.id)) !== -1){
                    return <span className="d-flex align-items-center">{value} <span className="ms-4 badge badge-circle badge-secondary"></span></span>
                }
                return value
            },
        },
        {
            Header: 'Sujet',
            accessor: 'sujet',
            minWidth: 300,
            noWrap: true
        },
        {
            Header: "Prêt à l'envoi",
            accessor: 'isValidePourEnvoi',
            minWidth: 180,
            maxWidth: 180,
            Cell: ({ value, row }) => {
                if (value == null)
                    return '';

                return <><div className="btn-group" data-kt-buttons="true"
                              data-kt-buttons-target="[data-kt-button]">
                    <label
                        className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${!value ? `active` : ``}`}
                        data-kt-button="true">
                        <input
                            onClick={(err) => {
                                invalidMail.mutate(row.original.id)
                            }}
                            className="btn-check" type="radio" name="method" value={value.id}/>
                        <span style={{fontSize: '10px'}}>A valider</span></label>
                    <label
                        className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${value ? `active` : ``}`}
                        data-kt-button="true">
                        <input
                            onClick={(err) => {
                                validMail.mutate(row.original.id)
                            }}
                            className="btn-check" type="radio" name="method" value={value.id}/>
                        <span style={{fontSize: '10px'}}>Validé</span></label>
                </div>
                    <span
                        onClick={() => sendForValidation.mutate(row.original.id)}
                        title='Envoyer pour validation' className='ms-2 cursor-pointer'
                    ><span className='svg-icon svg-icon-fluid me-2 w-30px d-inline-block'><MemoCicle /></span>
                        </span>
                </>
            },
        },
        {
            Header: "Envoi programmé le",
            accessor: 'dateProgrammationEnvoi',
            minWidth: 165,
            maxWidth: 165,
        },
        {
            Header: "Date d'envoi",
            accessor: 'dateEnvoi',
            minWidth: 165,
            maxWidth: 165,
        },
        {
            accessor: 'isReadOnly',
            hidden: true,
        },
        {
            Header: 'Date de création',
            accessor: 'dateCreation',
            minWidth: 150,
            maxWidth: 150,
            Cell: ({ value }) => {
                if (value == null) return '';
                return moment(value * 1000).format("DD/MM/YYYY à HH:mm");
            },
        },
    ], [snapAuth.tabIdMailVerrou, idExportMail, exportStatMail]);

    if (headers.isLoading || footers.isLoading || categories.isLoading || expediteurs.isLoading){
        return <LoadingView/>
    }

    if (paramsIsOk() !== true){
        return  <Card style={{height: "80vh"}}><div className="table-responsive">
            <h1 className="text-center mt-3
            ">Paramètres obligatoire avant la rédaction des mails</h1>
            <table className="m-auto table mt-4 table-row-dashed w-800px table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                <tr className="fw-bolder fs-6 text-gray-800 text-center border-0 bg-light">
                    <th className="w-400px rounded-start"></th>
                    <th className="w-140px">Paramétré</th>
                </tr>
                </thead>
                <tbody className="border-bottom border-dashed">
                    {paramsIsOk()}
                </tbody>
            </table>
            <Modal
                show={showModalSettings}
                onHide={() => setShowModalSettings(false)}
                size='lg'
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Paramètres mail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentComponentSettings}
                </Modal.Body>
            </Modal>
        </div></Card>
    }else {
        return <>
            <Card>
                <Card.Body>
                    <div className='d-flex flex-stack flex-wrap'>
                        <SearchBar
                            solid
                            {...register('search')}
                            onClear={search?.length > 0 ? () => {
                                setValue('search', '');
                            } : null}
                        />
                        <div className='mb-5'>
                            <Button variant='secondary' onClick={() => mailsProxy.showEditMail(null)}>
                                <FontAwesomeIcon icon={faPlus} className='me-2' />
                                Créer un mail
                            </Button>
                        </div>
                    </div>
                    <div>
                        <MetaTable
                            className='mt-5'
                            height={500}
                            url='email'
                            keys={['emails']}
                            columns={columns}
                            search={searchFilter}
                            colSearch={colSearch}
                        />
                    </div>
                </Card.Body>
            </Card>
            <EditModal />
            <SendModal />
            <RelanceModal/>
            <DetailEmailModal/>
            <ExportStatMailModal/>
        </>;
    }

    function paramsIsOk(){
        let result = [];
        let allIsOk = true;
        //check header
        if (headers.isSuccess && headers.data.nbLignesTotales == 0){
            result.push(paramPasOkLine("Entête de mail", <HeaderSettings />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Entête de mail", <HeaderSettings />))
        }
        //check footer
        if (footers.isSuccess && footers.data.nbLignesTotales == 0){
            result.push(paramPasOkLine("Signature de mail", <FooterSettings />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Signature de mail", <FooterSettings />))
        }
        // check categorie
        if (categories.isSuccess && categories.data.nbLignesTotales == 0){
            result.push(paramPasOkLine("Catégorie de mail", <CategorieMailSettings />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Catégorie de mail", <CategorieMailSettings />))
        }
        // check expéditeur
        if (expediteurs.isSuccess && expediteurs.data.nbLignesTotales == 0){
            result.push(paramPasOkLine("Exépditeurs de mail", <ExpediteurSettings />))
            allIsOk = false;
        }else {
            result.push(paramOkLine("Expéditeurs de mail", <ExpediteurSettings />))
        }
        return allIsOk ? allIsOk : result;
    }

    function paramOkLine(title, settingsConponent){
        return <tr className="text-center">
                    <td className="text-start ps-6">
                        <div className="fw-bold fs-4 text-gray-800">{title}</div>
                    </td>
                    <td>
                        <FontAwesomeIcon icon={faCheck} color={"green"}/>
                        <span
                            style={{fill: "black"}}
                            onClick={() => {
                            setCurrentComponentSettings(settingsConponent)
                            setShowModalSettings(true)
                        }} className='ms-2 svg-icon svg-icon-1 cursor-pointer'><Cod001/></span>
                    </td>
                </tr>
    }

    function paramPasOkLine(title, settingsConponent){
        return <tr className="text-center">
            <td className="text-start ps-6">
                <div className="fw-bold fs-4 text-gray-800">{title}</div>
            </td>
            <td>
                <FontAwesomeIcon icon={faTimes} color={"red"}/>
                <span
                    style={{fill: "black"}}
                    onClick={() => {
                    setCurrentComponentSettings(settingsConponent)
                    setShowModalSettings(true)
                }} className='ms-2 svg-icon svg-icon-1 cursor-pointer'><Cod001/></span>

            </td>
        </tr>
    }
}


export default MailsView;