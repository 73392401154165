import {Modal} from "react-bootstrap";
import {useSnapshot} from "valtio";
import React, {useMemo, useState} from "react";
import invitationsProxy from "../../../proxies/invitations";
import MetaTable from "../../../components/MetaTable/MetaTable";
import mailsProxy from "../../../proxies/mails";
import Select2 from "../../../components/Select2/Select2";
import {formatDetailsEmail} from "../../../functions/formatDetailsEmail";


function DetailEmailModal() {
    const emailSnap = useSnapshot(mailsProxy);

    const tabFilter = useMemo(() => {
        return {
            'idSupport': emailSnap.mail?.id,
            'idDestinataire': emailSnap.destinataire?.id
        };
    }, [emailSnap.mail?.id, emailSnap.destinataire?.id]);
    const [type, setType] = useState("");
    const colSearch = useMemo(() => {
        return {
            'type': type,
        };
    }, [type]);


    const columns = useMemo(() => [
        // {
        //     Header: 'Id',
        //     accessor: 'id',
        //     width: 80,
        //     minWidth: 60,
        // },
        {
            Header: 'Date',
            accessor: 'date',
            Footer: type => {
                let tabTypes = [
                    {id: "MessageDelayed", text:"Envoi retardé"},
                    {id: "MessageDeliveryFailed", text:"Echec de l'envoi du message"},
                    {id: "MessageHeld", text:"Message en attente d'envoi"},
                    {id: "MessageLinkClicked", text:"Lien cliqué"},
                    {id: "MessageLoaded", text:"Message consulté"},
                    {id: "MessageSent", text:"Message envoyé"},
                ]
                return <Select2
                    data={tabTypes}
                    closeOnSelect={true}
                    allowClear={true}
                    placeholder='Filtre détail'
                    onChange={(selected) => {
                        selected = selected?.at(0);
                        setType(selected)
                    }}/>
            },
        },
        {
            Header: 'Type',
            accessor: 'type',
            hidden:true
        },
        {
            Header: 'Détails',
            Cell: ({ row }) => {
                let detailsFormate = formatDetailsEmail(JSON.parse(row.original.detail), row.original.type);
                return [<div><p style={{fontWeight: "bold"}}><i className={`${detailsFormate.icon} text-black`}/> {row.original.type}</p></div>,<div>{detailsFormate.details}</div>]
            }
        }
    ], []);

    return <Modal
        show={emailSnap.detailEmailModal.show}
        onHide={() => mailsProxy.detailEmailModal.show = false}
        fullscreen
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Detail du suivi du mail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <MetaTable
                className='mt-5'
                height={500}
                url={`activiteEmail`}
                keys={['activiteEmail', emailSnap.mail?.id, 'correspondants']}
                columns={columns}
                tabFilter={tabFilter}
                colSearch={colSearch}
                showFiltres={true}
            />
        </Modal.Body>
    </Modal>;
}

export default DetailEmailModal;