import { Modal, Button } from "react-bootstrap";
import { BtSwal } from "@utils/alerts/sweetAlert";
import {useRef, useState} from "react";
import { useSnapshot } from "valtio";
import ReglagesStep from "./ReglagesStep/ReglagesStep";
import FinalisationStep from "./FinalisationStep/FinalisationStep";
import BlockUI from '@components/BlockUI/BlockUI';
import DestinatairesStep from "../../../communication/DestinatairesStep/DestinatairesStep";
import smsProxy from "../../../../proxies/sms";
import auth from "../../../../services/auth";

function EditModal() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const stepRef = useRef();
    //todo faire le sms snapshot
    const smsSnapshot = useSnapshot(smsProxy);
    const snapAuth = useSnapshot(auth);

    const steps = [
        {
            name: 'Réglages',
            renderComponent: (ref) => <ReglagesStep ref={ref} />,
        },
        {
            name: 'Destinataires',
            renderComponent: (ref) => <DestinatairesStep baseRoute='sms' id={smsSnapshot.sms?.id} />
        },
        {
            name: 'Finalisation',
            renderComponent: (ref) => <FinalisationStep />,
        },
    ];

    async function hideValidation() {
        if (currentIndex === 3){
            smsProxy.editModal.show = false;
        }else {
            const result = await BtSwal.fire({
                title: 'Voulez-vous quitter ?',
                text: "Si vous quittez vous perdrez ce que vous n'avez pas enregistré",
                icon: 'question',
                showDenyButton: true,
                denyButtonText: 'Quitter',
                confirmButtonText: 'Annuler',
                customClass: {
                    confirmButton: 'btn btn-secondary',
                    denyButton: 'btn btn-danger',
                },
            });
            if (result.isDenied)
                smsProxy.editModal.show = false;
        }
        //todo invalidate les SMS

        if (smsSnapshot?.sms?.id && snapAuth.tabIdSmsVerrou.indexOf(smsSnapshot?.sms?.id) !== -1){
            auth.tabIdSmsVerrou = snapAuth.tabIdSmsVerrou.filter(i => i !== smsSnapshot?.sms?.id)
        }
        // queryClient.invalidateQueries('emails');
    }

    async function goToStep(step) {
        setCurrentIndex(step);
        if (stepRef.current?.save != null) {
            smsProxy.editModal.loading = true;
            if (await stepRef.current.save())
                smsProxy.editModal.step = step;
            smsProxy.editModal.loading = false;
        }
        else smsProxy.editModal.step = step;
    }


    return <Modal
        fullscreen
        show={smsSnapshot.editModal.show}
        onHide={hideValidation}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <div className='w-100 stepper stepper-links d-flex flex-column between'>
                <div className='stepper-nav'>
                    {steps.map((step, index) => <div
                        className={`stepper-item ${index === smsSnapshot.editModal.step ? 'current' : ''} ${index < smsSnapshot.editModal.step ? 'completed' : ''} hoverable`}
                        key={step.name}
                        onClick={() => {
                            goToStep(index);
                        }}
                    >
                        <h3 className='stepper-title'>{step.name}</h3>
                    </div>)}
                </div>
            </div>
        </Modal.Header>
        <BlockUI loading={smsSnapshot.editModal.loading} className='overflow-scroll modal-body'>
            {steps[smsSnapshot.editModal.step]?.renderComponent(stepRef)}
        </BlockUI>
        <Modal.Footer>
            {/* TODO: Make loading indicator buttons */}
            {smsSnapshot.editModal.step > 0 && <Button
                variant='danger'
                className='me-auto'
                onClick={() => smsProxy.editModal.step--}
            >Précédent</Button>}
            {smsSnapshot.editModal.step < steps.length - 1 && <Button
                text='Suivant'
                variant='secondary'
                className='ms-auto'
                onClick={async () => {
                    goToStep(smsSnapshot.editModal.step + 1);
                }}
            >Suivant</Button>}
        </Modal.Footer>
    </Modal>;
}

export default EditModal;