import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import illustration from "@assets/illustrations/16.png";
import mailsProxy from "@proxies/mails";
import { useSnapshot } from "valtio";
import toast from "react-hot-toast";
import useSendMailAsTest from "../../../../../requests/communication/mails/useSendMailAsTest";
import useSendForValidation from "../../../../../requests/communication/mails/useSendForValidation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import auth from "@services/auth";
import {useQueryClient} from "react-query";
import {showErrorAlert} from "../../../../../utils/alerts/showErrorAlert";

function FinalisationStep() {
    const mailsSnapshot = useSnapshot(mailsProxy);
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const sendAsTest = useSendMailAsTest({
        id: mailsSnapshot.mail?.id,
        onSuccess: () => {
            queryClient.invalidateQueries('email');
            snapAuth.websocket.send("email")

            toast.success('L\'envoi du mail a été pris en compte.', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    const sendForValidation = useSendForValidation({
        id: mailsSnapshot.mail?.id,
        onSuccess: () => {
            queryClient.invalidateQueries('email');
            snapAuth.websocket.send("email")


            toast.success('L\'envoi du mail a été pris envoyé pour validation.', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    return <div className='w-100 mw-800px m-auto'>
        <div className='pt-15 pb-12 text-center'>
            <h1 className='fw-bolder text-dark'>Mail créé !</h1>
            <div className='text-muted fw-bold fs-4'>
                Il ne reste plus qu'à l'envoyer
            </div>
        </div>
        <div className='d-flex flex-center pb-3'>
            <OverlayTrigger
                key='mailTestInfo'
                placement='right'
                overlay={<Tooltip id='mailTestInfo'>
                    à l'adresse <span className='text-info'>{auth.utilisateur?.email}</span>
                </Tooltip>}
            >
                <Button className={"me-2"} onClick={sendAsTest.mutate} variant='danger' style={{height: "fit-content"}}>
                    M'envoyer le mail en test
                    <FontAwesomeIcon icon={faExclamationCircle} className='ms-2' />
                </Button>

            </OverlayTrigger>
            <Button onClick={sendForValidation.mutate}>Envoyer pour validation</Button>
        </div>
        <div className={`d-flex flex-center pb-15 ${!mailsSnapshot.mail?.isValidePourEnvoi ? 'd-none' : ''}`}>
            <Button
                variant={"secondary"}
                onClick={() => mailsProxy.showSendMail(mailsSnapshot.mail)}
            >Envoyer</Button>
        </div>
        <div className='text-center'>
            <img src={illustration} alt='' className='mww-100 mh-350px' />
        </div>
    </div>;
}

export default FinalisationStep;