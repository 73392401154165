import { Container } from "react-bootstrap";
import { breadcrumbs } from "@utils/breadcrumbs";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

function Header() {
    const location = useLocation().pathname;
    let locationFormat = useLocation().pathname;
    locationFormat = locationFormat.split('/');
    locationFormat[1] = ":agence";
    locationFormat = locationFormat.join('/');

    let crumbs = [];

    if (!['/:agence', '/:agence/accueil'].includes(locationFormat)) {
        let index = 6;
        do {
            let sectionBase = location.split('/');
            sectionBase = "/"+sectionBase[1];
            let section = location.slice(0, index);
            section = section.split('/');
            section[1] = ":agence";
            section = section.join('/');
            if (breadcrumbs[section] != null)
                crumbs.push(<li
                    key={sectionBase}
                    className='breadcrumb-item text-muted'
                >
                    {breadcrumbs[section].abstract
                        ? breadcrumbs[section].name
                        : <Link
                            to={sectionBase}
                            className='text-muted text-hover-primary'
                        >
                            {breadcrumbs[section].name}
                        </Link>}
                </li>);
            index = locationFormat.indexOf('/:agence', index + 1)
        } while (index > -1);
    }

    crumbs.push(<li key={location} className='breadcrumb-item text-dark'>
        {breadcrumbs[locationFormat]?.name}
    </li>);

    return <Container fluid className='py-6 py-lg-0 d-flex flex-column flex-sm-row align-items-lg-stretch justify-content-sm-between'>
        {(breadcrumbs[location]?.back != null) && <div className='d-flex flex-column column-fluid pe-5 flex-center'>
            <Link to={breadcrumbs[location].back}>
                <FontAwesomeIcon icon={faArrowLeft} size='lg' />
            </Link>
        </div>}
        <div className='me-auto'>
            <div className='page-title d-flex flex-column me-auto'>
                <h1 className='d-flex flex-column text-dark fw-bolder fs-3 mb-0'>{breadcrumbs[location]?.name}</h1>
                <ul className='breadcrumb breadcrumb-line fw-bold fs-7 pt-1'>
                    {crumbs}
                </ul>
            </div>
        </div>
    </Container>;
}

export default Header;