import { useMutation } from "react-query";
import api from "@services/api";

export default function useChangeEmailInscriptionInvite(props) {
    return useMutation(changeEmailInscriptionInvite, props);
}

export async function changeEmailInscriptionInvite(email) {
    return api.put(`emailInscriptionInvite`, { json: email }).json();
}
