import {Button, Card, Col, Form, Row} from "react-bootstrap";
import { useEffect, useMemo, useRef, useState } from "react";
import MetaTable from "@components/MetaTable/MetaTable";
import ProfilSelection from "./ProfilFilter/ProfilFilter";
import SearchBar from "@components/SearchBar/SearchBar";
import { useForm } from "react-hook-form";
import { useDebounce } from "use-debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import { useMetaCheckboxes } from "@components/MetaTable/useMetaCheckboxes";
import useInscrireDestinataires from "@requests/communication/useInscrireDestinataires";
import BlockUI from "@components/BlockUI/BlockUI";
import useDesinscrireDestinataires from "@requests/communication/useDesinscrireDestinataire";
import { useDatatableColumns } from "@components/MetaTable/useDatatableColumns";
import { ReactComponent as Art005 } from "@assets/icons/art005.svg";
import annuaireProxy from "@proxies/annuaire";
import ProfilPartenaireFilter from "./ProfilFilter/ProfilPartenaireFilter";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import mailsProxy from "../../../proxies/mails";
import invitationsProxy from "../../../proxies/invitations";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import toast from "react-hot-toast";
import {useSnapshot} from "valtio";

function DestinatairesStep({ baseRoute, id, onlyPartenaireProfil = false }) {
    const { register, watch, setValue } = useForm();
    const searchContact = watch('searchContact');
    const [debouncedSearchContact] = useDebounce(searchContact, 300);
    const searchDestinataire = watch('searchDestinataire');
    const [debouncedSearchDestinataire] = useDebounce(searchDestinataire, 300);
    const annuaireSnap = useSnapshot(annuaireProxy);
    const [selectedProfil, setSelectedProfil] = useState();

    const contactsCheckboxes = useMetaCheckboxes();
    const destinatairesCheckboxes = useMetaCheckboxes();

    const destinataireSectionRef = useRef();
    const contactSectionRef = useRef();

    const scrollToContacts = () => contactSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    const scrollToDestinataires = () => destinataireSectionRef.current?.scrollIntoView({ behavior: 'smooth' });

    useEffect(() => {
        if (baseRoute == "email" && (contactsCheckboxes.getIds().length !== 0 || destinatairesCheckboxes.getIds().length !== 0 || contactsCheckboxes.isSelectingAll || destinatairesCheckboxes.isSelectingAll)){
            mailsProxy.destinataireIsChecked = true;
            mailsProxy.destinataireAlertIsAdd = contactsCheckboxes.getIds().length !== 0 || contactsCheckboxes.isSelectingAll;
        }else if (baseRoute == "email"){
            mailsProxy.destinataireIsChecked = false;
        }

        if (baseRoute == "invitation" && (contactsCheckboxes.getIds().length !== 0 || destinatairesCheckboxes.getIds().length !== 0 || contactsCheckboxes.isSelectingAll || destinatairesCheckboxes.isSelectingAll)){
            invitationsProxy.destinataireIsChecked = true;
            invitationsProxy.destinataireAlertIsAdd = contactsCheckboxes.getIds().length !== 0 || contactsCheckboxes.isSelectingAll;
        }else if (baseRoute == "invitation"){
            invitationsProxy.destinataireIsChecked = false;
        }

    }, [contactsCheckboxes, destinatairesCheckboxes])

    useEffect(() => {
        contactsCheckboxes.reset();
    }, [selectedProfil, debouncedSearchContact]);

    useEffect(() => {
        destinatairesCheckboxes.reset();
    }, [debouncedSearchDestinataire]);

    useEffect(() => {
        if (annuaireSnap.addAutoDestinataire){
            BtSwal.fire({
                heightAuto: false,
                title: `Voulez vous ajouter `+annuaireSnap.personne?.prenom + " "+annuaireSnap.personne?.nom +" aux destinataires ?",
                showDenyButton: true,
                confirmButtonText: `Oui`,
                denyButtonText: `Annuler`,
                didClose: () => scrollToDestinataires()
            }).then((result) => {
                if (result.isConfirmed){
                    inscrireDestinataires.mutate({
                        idPersonnes: [annuaireSnap.personne?.id],
                        idProfil: 1,
                        isInclusif: !contactsCheckboxes.isSelectingAll,
                    });
                    annuaireProxy.addAutoDestinataire = false;
                    toast.success("Le contact a été ajouté")
                } else if (result.isDenied) {
                    annuaireProxy.addAutoDestinataire = false;
                    toast.success("L'ajout a été annulé.")
                } else {
                    annuaireProxy.addAutoDestinataire = false;
                }
            })
        }
    }, [annuaireSnap.addAutoDestinataire]);

    const colSearch = useMemo(() => {
        return {
            'personne.tabProfils.id': selectedProfil?.id,
        };
    }, [selectedProfil]);

    const contactsColumns = useMemo(() => [
        {
            Header: () => <div className='my-2'>
                <Form.Check
                    className='form-check form-check-custom'
                    checked={contactsCheckboxes.isSelectingAll}
                    onChange={() => contactsCheckboxes.toggleSelectAll()}
                />
            </div>,
            id: 'selector',
            Cell: ({ row }) => <Form.Check
                className='form-check form-check-custom'
                checked={contactsCheckboxes.isItemChecked(row.original.personne.id)}
                onChange={() => contactsCheckboxes.toggleItem(row.original.personne.id)}
            />,
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: () => "",
            id: 'wrench',
            Cell: ({ row }) => <div className="d-flex justify-content-center">
                <a
                onClick={() => annuaireProxy.showPersonne(row.original.personne, false, "personne", true)}
                className='svg-icon svg-icon-1 cursor-pointer'><Art005 /></a></div>,

            width: 60,
        },
        {
            Header: 'Id',
            accessor: 'personne.id',
            minWidth: 70,
            maxWidth: 70,
        },
        {
            Header: 'Prenom',
            accessor: 'personne.prenom',
        },
        {
            Header: 'Nom',
            accessor: 'personne.nom',
        },
        {
            Header: 'prenomNom',
            accessor: 'personne.prenomNom',
            hidden: true
        },
        {
            Header: 'nomPrenom',
            accessor: 'personne.nomPrenom',
            hidden: true
        },
        {
            Header: 'Email',
            accessor: 'personne.email',
            width: 250,
        },
        {
            Header: 'Téléphones',
            accessor: 'personne.tel1',
            width: 250,
            Cell: ({row}) => {
                let result = [];
                result.push(row.original.personne.tel1 ? row.original.personne.tel1 + " " : "")
                result.push(<br/>)
                result.push(row.original.personne.tel2 ? row.original.personne.tel2 : "")
                return result;
            },
        },
        {
            Header: 'Entreprises',
            accessor: 'tabEntreprises.nom',
            width: 300,
            Cell: ({ row }) => {
                if (row.original?.tabEntreprises == null) return '';
                return Object.values(row.original.tabEntreprises).map(e => e.nom).join(', ');
            },
        },
        {
            Header: 'Fonction',
            accessor: 'personne.fonction',
        },
        {
            accessor: 'personne.tabProfils.id',
            hidden: true,
            searchOperator: "="
        },
    ], [contactsCheckboxes]);

    const destinatairesColumns = useMemo(() => [
        {
            Header: () => <div className='my-2'>
                <Form.Check
                    className='form-check form-check-custom'
                    checked={destinatairesCheckboxes.isSelectingAll}
                    onChange={() => destinatairesCheckboxes.toggleSelectAll()}
                />
            </div>,
            id: 'selector',
            Cell: ({ row }) => <Form.Check
                className='form-check form-check-custom'
                checked={destinatairesCheckboxes.isItemChecked(row.original.destinataire.personne.id)}
                onChange={() => destinatairesCheckboxes.toggleItem(row.original.destinataire.personne.id)}
            />,
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: () => "",
            id: 'wrench',
            Cell: ({ row }) => <div className="d-flex justify-content-center">
                <a
                    onClick={() => annuaireProxy.showPersonne(row.original.destinataire.personne, false, "personne", true)}
                    className='svg-icon svg-icon-1 cursor-pointer'><Art005 /></a></div>,

            width: 60,
        },
        {
            Header: 'Id',
            accessor: 'destinataire.personne.id',
            width: 80,
        },
        {
            Header: 'Prenom',
            accessor: 'destinataire.personne.prenom',
        },
        {
            Header: 'Nom',
            accessor: 'destinataire.personne.nom',
        },
        {
            Header: 'Email',
            accessor: 'destinataire.personne.email',
            width: 250,
        },
        {
            Header: 'Téléphones',
            accessor: 'destinataire.personne.tel1',
            width: 250,
            Cell: ({row}) => {
                let result = [];
                result.push(row.original.destinataire.personne.tel1 ? row.original.destinataire.personne.tel1 + " " : "")
                result.push(<br/>)
                result.push(row.original.destinataire.personne.tel2 ? row.original.destinataire.personne.tel2 : "")
                return result;
            }
        },
        {
            Header: 'Prix unitaire sms',
            accessor: 'cout',
            width: 250,
            Cell: ({row}) => {
                return row.original.cout+" €";
            },
            hidden: baseRoute == "sms" ? false : true,
        },
    ], [destinatairesCheckboxes]);

    const inscrireDestinatairesFilters = useDatatableColumns({
        columns: contactsColumns,
        colSearch,
        search: searchContact,
    });

    const inscrireDestinataires = useInscrireDestinataires({
        baseRoute: baseRoute,
        id: id,
        filters: inscrireDestinatairesFilters,
        onSuccess: (data) => {
            contactsCheckboxes.reset();
            scrollToDestinataires();
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    const desinscrireDestinatairesFilters = useDatatableColumns({
        columns: destinatairesColumns,
        search: searchDestinataire,
    });

    const desinscrireDestinataires = useDesinscrireDestinataires({
        baseRoute: baseRoute,
        id: id,
        filters: desinscrireDestinatairesFilters,
        onSuccess: (data) => {
            destinatairesCheckboxes.reset();
            setValue('searchDestinataire', '');
            if (baseRoute == "sms"){
                BtSwal.fire("Information destinataires", "Les destinataires aillant déjà reçu le sms ne peuvent être supprimés.", "info")
            }
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    function addToDestinataires() {
        const idPersonnes = contactsCheckboxes.getIds();
        if (idPersonnes.length == 0){
            BtSwal.fire({
                heightAuto: false,
                title: `Êtes-vous sur de vouloirs rajouter tous les contacts de cette catégorie ?`,
                showDenyButton: true,
                confirmButtonText: `Oui`,
                denyButtonText: `Annuler`,
                didClose: () => scrollToDestinataires()
            }).then((result) => {
                if (result.isConfirmed){
                    inscrireDestinataires.mutate({
                        idPersonnes,
                        idProfil: selectedProfil?.id,
                        isInclusif: !contactsCheckboxes.isSelectingAll,
                    });
                    toast.success("Tous les contacts de la catégorie ont été rajoutés.")
                } else if (result.isDenied) {
                    toast.success("L'ajout annulé.")
                }
            })
        }else {
            inscrireDestinataires.mutate({
                idPersonnes,
                idProfil: selectedProfil?.id,
                isInclusif: !contactsCheckboxes.isSelectingAll,
            });
        }
    }

    function removeDestinataires() {
        const idPersonnes = destinatairesCheckboxes.getIds();
        if (idPersonnes.length == 0){
            BtSwal.fire({
                title: `Êtes-vous sur de vouloirs supprimer tous les contacts ?`,
                text: `Seulement ceux qui n'ont pas reçu l'invitation seront supprimés.`,
                showDenyButton: true,
                confirmButtonText: `Oui`,
                denyButtonText: `Annuler`,
                didClose: () => scrollToDestinataires()
            }).then((result) => {
                if (result.isConfirmed){
                    desinscrireDestinataires.mutate({
                        idPersonnes,
                        isInclusif: !destinatairesCheckboxes.isSelectingAll,
                    });
                    toast.success("Tous les contacts ont été supprimés.")
                } else if (result.isDenied) {
                    toast.success("La suppression a été annulé.")
                }
            })
        }else {
            BtSwal.fire({
                title: `Êtes-vous sur de vouloirs supprimer les contacts sélectionnés ?`,
                text: `Seulement ceux qui n'ont pas reçu l'invitation seront supprimés.`,
                showDenyButton: true,
                confirmButtonText: `Oui`,
                denyButtonText: `Annuler`,
                didClose: () => scrollToDestinataires()
            }).then((result) => {
                if (result.isConfirmed){
                    desinscrireDestinataires.mutate({
                        idPersonnes,
                        isInclusif: !destinatairesCheckboxes.isSelectingAll,
                    });
                    toast.success("Les contacts sélectionnés ont été supprimés.")
                } else if (result.isDenied) {
                    toast.success("La suppression a été annulé.")
                }
            })
        }
    }

    const isLoading = inscrireDestinataires.isLoading || desinscrireDestinataires.isLoading;

    return <BlockUI loading={isLoading}>
        <Row ref={contactSectionRef}>
            <Col xs={2} className=''>
                {onlyPartenaireProfil ? <ProfilPartenaireFilter selected={selectedProfil} onChange={setSelectedProfil} /> :<ProfilSelection selected={selectedProfil} onChange={setSelectedProfil}/>}
            </Col>
            <Col xs={10}>
                <Card className='card-flush'>
                    <Card.Body>
                        <h4 className='text-center'>Contacts</h4>
                        {baseRoute == "sms" && <p style={{width: "fit-content"}} className="m-auto alert alert-info mb-2">Lors de l'envoi, le téléphone 1 sera priorisé, s'il ne s'agit pas d'un numéro de téléphone portable, le téléphone 2 sera utilisé.</p>}
                        <div className='d-flex'>
                            <SearchBar
                                solid
                                {...register('searchContact')}
                                onClear={searchContact?.length > 0 ? () => {
                                    setValue('searchContact', '');
                                } : null}
                            />
                            <div className="ms-auto">
                                <Button
                                    variant='primary'
                                    className='me-2'
                                    onClick={() => {
                                        annuaireProxy.showPersonne(null, false, "personne", true)
                                    }}
                                    // onClick={addToDestinataires}
                                >
                                    <FontAwesomeIcon icon={faPlus} className='me-2' />
                                    Ajouter un contact
                                </Button>
                                <Button
                                    variant='secondary'
                                    className='ms-auto'
                                    onClick={addToDestinataires}
                                >
                                    <FontAwesomeIcon icon={faPlus} className='me-2' />
                                    Ajouter la sélection aux destinataires
                                </Button>
                            </div>
                            <Button variant='danger' className='btn-icon ms-2 pulse' onClick={scrollToDestinataires}>
                                <FontAwesomeIcon icon={faArrowDown} />
                                <span className='pulse-ring' />
                            </Button>
                        </div>
                        <MetaTable
                            className='mt-5'
                            height={450}
                            url='destinataire'
                            keys={['destinataire']}
                            columns={contactsColumns}
                            colSearch={colSearch}
                            search={debouncedSearchContact}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <div className='separator my-10' />
        <Card className='card-flush' ref={destinataireSectionRef}>
            <Card.Body>
                <h4 className='text-center'>Destinataires</h4>
                <div className='d-flex'>
                    <SearchBar
                        solid
                        {...register('searchDestinataire')}
                        onClear={searchDestinataire?.length > 0 ? () => {
                            setValue('searchDestinataire', '');
                        } : null}
                    />
                    <Button
                        variant='secondary'
                        className='ms-auto'
                        onClick={removeDestinataires}
                    >
                        <FontAwesomeIcon icon={faTrash} className='me-2' />
                        Enlever les destinataires séléctionnés
                    </Button>
                    <Button variant='danger' className='btn-icon ms-2' onClick={scrollToContacts}>
                        <FontAwesomeIcon icon={faArrowUp} />
                    </Button>
                </div>
                <MetaTable
                    className='mt-5'
                    height={450}
                    url={`${baseRoute}/${id}/correspondants`}
                    keys={[baseRoute, id, 'correspondants']}
                    columns={destinatairesColumns}
                    search={debouncedSearchDestinataire}
                />
            </Card.Body>
        </Card>
    </BlockUI>;
}

export default DestinatairesStep;