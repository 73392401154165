import {useMutation, useQueryClient} from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

export default function useInvalidSms() {

    const snapAuth = useSnapshot(auth);
    const queryClient = useQueryClient();

    return useMutation(invalidSms, {
        onSuccess: () => {
            queryClient.invalidateQueries('sms');
            snapAuth.websocket.send("sms")
            toast.success('Le sms a été invalidé', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function invalidSms(id) {

    if (id != null)
        return api.post(`sms/${id}/invalider`).json();

}