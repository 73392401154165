import {Modal, Button, OverlayTrigger, Tooltip, Badge} from "react-bootstrap";
import {useSnapshot} from "valtio";
import publicationMobileProxy from "../../../../proxies/publicationMobile";
import CropperForPublication from "../../../../components/CropperJs/CropperForPublication";

function CropperForPublicationModal({getValues, onChangeForPublication}) {
    const publicationMobileSnap = useSnapshot(publicationMobileProxy);

    return <Modal
        size={"lg"}
        show={publicationMobileSnap.editCropperModal.show}
        onHide={() => {
            publicationMobileProxy.editCropperModal.show = false;
        }}
        dialogClassName='p-9 modal-fullscreen-lg-down'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Image de la publication</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height:550}}>
            <CropperForPublication
                getValues={getValues}
                onChangeForPublication={onChangeForPublication}
            />
        </Modal.Body>
    </Modal>;
}

export default CropperForPublicationModal;