import { Col, Form, FormGroup, Row, Button, Stack } from "react-bootstrap";
import FormDatePicker from "@components/form/FormDatePicker/FormDatePicker";
import FormTimePicker from "@components/form/FormTimePicker/FormTimePicker";
import { useFieldArray } from "react-hook-form";
import { useEffect } from "react";

function DatesForm({ control, name, rules, getValues, setValue }) {
    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
        keyName: '',
    });

    useEffect(() => {
        if (fields.length < 1)
            append({
                debut: '',
                fin: '',
                id: null,
            });
    }, [fields, append]);

    return <div>
        {fields.map((item, index) => (
            <Stack key={index} className={index === 0 ? 'mt-5' : ''}>
                <Row>
                    <Col md={4}>
                        <FormGroup as={Col}>
                            <Form.Label className='required'>Date de l'évènement</Form.Label>
                            <FormDatePicker solid
                                control={control}
                                name={`${name}.${index}.debut`}
                                enableTime={false}
                                idDate={"idDate"+index}
                                getValues={getValues}
                                setValue={setValue}
                                rules={rules}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup hidden={true} className={"groupTimeForDate"+index} as={Col}>
                            <Form.Label className='required'>Heure de début</Form.Label>
                            <FormTimePicker solid
                                            control={control}
                                            name={`${name}.${index}.debut`}
                                            enableTime={true}
                                            idDate={"idDate"+index}
                                            getValues={getValues}
                                            setValue={setValue}
                                            remove={remove}
                                            index={index}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup hidden={true}  className={"groupTimeForDate"+index} as={Col}>
                            <Form.Label className='required'>Heure de fin</Form.Label>
                            <FormTimePicker solid
                                            control={control}
                                            name={`${name}.${index}.fin`}
                                            enableTime={true}
                                            idDate={"idDate"+index}
                                            getValues={getValues}
                                            setValue={setValue}
                                            remove={remove}
                                            index={index}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <div className='d-flex justify-content-end'>
                    {index === fields.length - 1 && <Button
                        variant='link'
                        className='btn-color-info btn-active-color-primary'
                        onClick={() => append({
                            debut: '',
                            fin: '',
                            id: null,
                        })}
                    >Ajouter une date</Button>}
                    {fields.length > 1 && <Button
                        variant='link'
                        className='btn-color-danger btn-active-color-primary ms-auto'
                        onClick={() => remove(index)}
                    >Enlever la date</Button>}
                </div>
            </Stack>
        ))}
        <div className='d-flex justify-content-center'>

        </div>
    </div>;
}

export default DatesForm;