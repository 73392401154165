export const dataRangeNbTours = {
    1: {
        text:"1",
        valeur: 1,
    },
    2: {
        text:"2",
        valeur: 2
    },
    3: {
        text:"3",
        valeur: 3
    },
    4: {
        text:"4",
        valeur: 4
    },
    5: {
        text:"5",
        valeur: 5
    },
    6: {
        text:"6",
        valeur: 6
    },
    7: {
        text:"7",
        valeur: 7
    },
    8: {
        text:"8",
        valeur: 8
    },
    9: {
        text:"9",
        valeur: 9
    },
    10: {
        text:"10",
        valeur: 10
    },
    11: {
        text:"11",
        valeur: 11
    },
    12: {
        text:"12",
        valeur: 12
    },
};
export const dataRangeTempsParPersonne = {
    1: {
        text: "60",
        valeur: 1,
        overlayTxt: "1 minute"
    },
    2: {
        text: "90",
        valeur: 1.5,
        overlayTxt: "1 minute 30"
    },
    3: {
        text: "120",
        valeur: 2,
        overlayTxt: "2 minutes"
    },
    4: {
        text: "150",
        valeur: 2.5,
        overlayTxt: "2 minutes 30"
    },
    5: {
        text : "180",
        valeur : 3,
        overlayTxt: "3 minutes"
    },
    6: {
        text : "210",
        valeur : 3.5,
        overlayTxt: "3 minutes 30"
    },
    7: {
        text : "240",
        valeur : 4,
        overlayTxt: "4 minutes"
    },
    8: {
        text : "270",
        valeur : 4.5,
        overlayTxt: "4 minutes 30"
    },
    9: {
        text : "300",
        valeur : 5,
        overlayTxt: "5 minutes"
    }
};
export const dataRangeTempsPourChangerDePlace = {
    1: {
        text : "60",
        valeur : 1,
        overlayTxt: "1 minute"
    },
    2: {
        text : "90",
        valeur : 1.5,
        overlayTxt: "1 minutes 30"
    },
    3: {
        text : "120",
        valeur : 2,
        overlayTxt: "2 minutes"
    },
    4: {
        text : "150",
        valeur : 2.5,
        overlayTxt: "2 minutes 30"
    },
    5: {
        text : "180",
        valeur : 3,
        overlayTxt: "3 minutes"
    },
    6: {
        text : "210",
        valeur : 3.5,
        overlayTxt: "3 minutes 30"
    },
    7: {
        text : "240",
        valeur : 4,
        overlayTxt: "4 minutes"
    },
};
export const dureeMinuteToTimeMysql = (minutes) => {
    return (minutes/60 < 10 ? "0" : "") + Math.floor(minutes / 60) + ":" + (minutes % 60 < 10 ? "0" : "")+Math.trunc(minutes % 60) + ":" + ((((minutes % 60) % 1) * 60) < 10 ? "0" : "") + Math.round(((minutes % 60) % 1) * 60)
};
export const dureeMinuteToTimeMysqlWithoutHours = (minutes) => {
    return (minutes % 60 < 10 ? "0" : "")+Math.trunc(minutes % 60) + ":" + ((((minutes % 60) % 1) * 60) < 10 ? "0" : "") + Math.round(((minutes % 60) % 1) * 60)
};
export const dureeTimeMysqlToMinute = (timeMysql) => {
    let tabTime = timeMysql.split(":");
    return parseInt(tabTime[0])*60+parseInt(tabTime[1])+(parseInt(tabTime[2])/60);
}