import {useMutation, useQueryClient} from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useValidMail() {

    const queryClient = useQueryClient();

    return useMutation(validEmail, {
        onSuccess: () => {
            queryClient.invalidateQueries('emails');
            toast.success('L\'email a été validé', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function validEmail(id) {

    if (id != null)
        return api.post(`email/${id}/valider`).json();

}