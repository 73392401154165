import { Modal, Button } from "react-bootstrap";
import { BtSwal } from "@utils/alerts/sweetAlert";
import {useRef, useState} from "react";
import { useSnapshot } from "valtio";
import ReglagesStep from "./ReglagesStep/ReglagesStep";
import FinalisationStep from "./FinalisationStep/FinalisationStep";
import BlockUI from '@components/BlockUI/BlockUI';
import publicationMobileProxy from "../../../../proxies/publicationMobile";
import {useQueryClient} from "react-query";
import auth from "../../../../services/auth";

function EditModal() {
    const queryClient = useQueryClient();
    const [currentIndex, setCurrentIndex] = useState(0);
    const stepRef = useRef();
    const publicationMobileSnap = useSnapshot(publicationMobileProxy);
    const snapAuth = useSnapshot(auth);

    const steps = [
        {
            name: 'Réglages',
            renderComponent: (ref) => <ReglagesStep ref={ref} />,
        },
        {
            name: 'Finalisation',
            renderComponent: (ref) => <FinalisationStep ref={ref} />,
        },
    ];

    async function hideValidation() {

        const result = await BtSwal.fire({
            title: 'Voulez-vous quitter ?',
            text: "Si vous quittez vous perdrez ce que vous n'avez pas enregistré",
            icon: 'question',
            showDenyButton: true,
            denyButtonText: 'Quitter',
            confirmButtonText: 'Annuler',
            customClass: {
                confirmButton: 'btn btn-secondary',
                denyButton: 'btn btn-danger',
            },
        });
        if (result.isDenied)
            publicationMobileProxy.editModal.show = false;

        queryClient.invalidateQueries('publicationMobile');
        if (publicationMobileSnap?.publicationMobile?.id && snapAuth.tabIdInvitationVerrou.indexOf(publicationMobileSnap?.publicationMobile?.id) !== -1){
            auth.tabIdPublicationVerrou = snapAuth.tabIdPublicationVerrou.filter(i => i !== publicationMobileSnap?.publicationMobile?.id)
        }
    }

    async function goToStep(step) {
        setCurrentIndex(step);
        if (stepRef.current?.save != null) {
            publicationMobileProxy.editModal.loading = true;
            if (await stepRef.current.save())
                publicationMobileProxy.editModal.step = step;
            publicationMobileProxy.editModal.loading = false;
        }
        else publicationMobileProxy.editModal.step = step;

        if (step === 1){
            publicationMobileProxy.initFinalisation = false;
        }
    }

    async function closeAndSaveFinalStep(){
        publicationMobileProxy.editModal.loading = true;
        if (await stepRef.current.save()){
            publicationMobileProxy.editModal.show = false;
        }
        publicationMobileProxy.editModal.loading = false;
        if (publicationMobileSnap?.publicationMobile?.id && snapAuth.tabIdInvitationVerrou.indexOf(publicationMobileSnap?.publicationMobile?.id) !== -1){
            auth.tabIdPublicationVerrou = snapAuth.tabIdPublicationVerrou.filter(i => i !== publicationMobileSnap?.publicationMobile?.id)
        }
    }


    return <Modal
        fullscreen
        show={publicationMobileSnap.editModal.show}
        onHide={hideValidation}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <div className='w-100 stepper stepper-links d-flex flex-column between'>
                <div className='stepper-nav'>
                    {steps.map((step, index) => <div
                        className={`stepper-item ${index === publicationMobileSnap.editModal.step ? 'current' : ''} ${index < publicationMobileSnap.editModal.step ? 'completed' : ''} hoverable`}
                        key={step.name}
                        onClick={() => {
                            goToStep(index);
                        }}
                    >
                        <h3 className='stepper-title'>{step.name}</h3>
                    </div>)}
                </div>
            </div>
        </Modal.Header>
        <BlockUI loading={publicationMobileSnap.editModal.loading} className='overflow-scroll modal-body'>
            {steps[publicationMobileSnap.editModal.step]?.renderComponent(stepRef)}
        </BlockUI>
        <Modal.Footer>
            {/* TODO: Make loading indicator buttons */}
            {publicationMobileSnap.editModal.step > 0 && <Button
                variant='danger'
                className='me-auto'
                onClick={() => publicationMobileProxy.editModal.step--}
            >Précédent</Button>}
            {publicationMobileSnap.editModal.step < steps.length - 1 && <Button
                text='Suivant'
                variant='secondary'
                className='ms-auto'
                onClick={async () => {
                    goToStep(publicationMobileSnap.editModal.step + 1);
                }}
            >Suivant</Button>}
            {publicationMobileSnap.editModal.step === steps.length - 1 && <Button
                text='Terminer'
                variant='secondary'
                className='ms-auto'
                onClick={async () => {
                    closeAndSaveFinalStep();
                }}
            >Terminer et enregistrer</Button>}
        </Modal.Footer>
    </Modal>;
}

export default EditModal;