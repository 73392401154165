/**
 * 6 charactères mini + au moins 1 lettre et 1 chiffre
 * @returns {RegExp}
 */
export function passwordPattern() {
    return /^(?=.*[A-z])(?=.*[0-9])\S{6,}$/;
}

export function urlPattern() {
    return /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
}

export function telPattern() {
    return /^\d{2}(?: ?\d+)*$/;
    // return /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s]*\d{2}){4}$/;
}

export function telAnnuairePattern() {
    return /^(0)\s*[1-9](?:[\s]*\d{2}){4}|(?:[\s]*\d{3}){2}$/;
}

export function emailPattern() {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/;
}

export function codePostalPattern(){
    return /^[0-9]{5}$/;
}

export function alphaNumericAndSpaceAndStartWithLetter(){
    return /^[A-Za-z][A-Za-z\d\s\-_]+$/;
}

export function allowAllLetterAndAccentAndSpace(){
    return /^[a-zA-Z\u00C0-\u00FF\s]*$/;
}

//Fonction non fonctionnelle...
// export function wordsBetweenTwoCaracters(){
//     return /\{(.* ?)\}/g;
// }