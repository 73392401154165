import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetFiles(arbo) {

    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = {
        data: "nom",
        searchable: true,
        orderable: true,
        search: {
            value: "",
            regex: true,
        }
    }

    filters.columns[1] = {
        data: "cheminAcces",
        searchable: true,
        orderable: true,
        search: {
            value: arbo,
            regex: true,
        }
    }

    filters.order[0] = {
        column:0,
        dir: "desc"
    }

    return useQuery(
        ["arborescence"],
        async () => {
            return api.get("arborescence", {
                searchParams: Qs.stringify({
                    start: 0,
                    length: 100,
                    ...filters,
                }, { arrayFormat: 'brackets' }),
            }).json();
        }
    );

}