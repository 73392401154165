import {useMutation, useQueryClient} from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

export default function useValidInvitation() {

    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    return useMutation(validInvitation, {
        onSuccess: () => {
            queryClient.invalidateQueries('invitations');
            snapAuth.websocket.send("invitations")
            toast.success('L\'invitation a été validé', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function validInvitation(id) {

    if (id != null)
        return api.post(`invitation/${id}/valider`).json();

}