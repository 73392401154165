import { useMutation, useQueryClient } from "react-query";
import { sleep } from "@utils/sleep";
import api from "@services/api";
import auth from "@services/auth";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";

export default function useChangeAgence() {
    const queryClient = useQueryClient();

    return useMutation(async agence => {
        const id = agence.id;

        await sleep(1000);

        return api.post('utilisateur/changeAgence', {
            json: { idAgence: id }
        }).json();
    }, {
        onSuccess: async data => {
            await auth.login({
                accessToken: data.accessToken?.jwt,
                refreshToken: data.refreshToken?.jwt,
                utilisateur: data.utilisateur,
                parametreFacturation: data.parametreFacturation,
                agence: data.agence,
            });
            queryClient.invalidateQueries();
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}