import {useMutation} from "react-query";
import api from "../../../services/api";
import Qs from "qs";

export default function useGetSpeedBusinessByIdInvitation() {

    return useMutation(speed);

    function speed(id) {

        if (id != null) {
            return api.get('rencontreSpeedBusiness/' + id).json()
        }
    }
}