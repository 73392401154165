import image from './icons/twoTexts.svg';

export const twoTextsModule = {
    image: image,
    defaultContent: {
        richTexts: {
            leftRichText: '<h1>Titre</h1><p>Purus sit amet luctus venenatis lectus magna. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Ultricies mi quis hendrerit dolor magna eget est lorem. Orci sagittis eu volutpat odio. Justo donec enim diam vulputate ut pharetra sit amet.</p>',
            rightRichText: '<h1>Titre</h1><p>Purus sit amet luctus venenatis lectus magna. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Ultricies mi quis hendrerit dolor magna eget est lorem. Orci sagittis eu volutpat odio. Justo donec enim diam vulputate ut pharetra sit amet.</p>',
        },
    },
    template:
        `<tr id="colorPicker">
            <td valign="top" align="center">
                <table width="100%" cellspacing="0" cellpadding="0" border="0">
                    <tbody><tr>
                        <td valign="top" align="center">
                            <table class="flexibleContainer" width="600" cellspacing="0" cellpadding="0" border="0">
                                <tbody><tr>
                                    <td class="flexibleContainerCell" id="paddingTemplate" width="600" valign="top">
                                        <table class="flexibleContainer" width="260" cellspacing="0" cellpadding="0" border="0" align="Left">
                                            <tbody><tr>
                                                <td id="leftRichText" class="textContent" valign="top">
                                                    <h3>Left Column</h3>
                                                    <br>
                                                    A kitten or kitty is a juvenile domesticated cat. A feline litter usually consists of two to five kittens. To survive, kittens need the care of their mother for the first several weeks of their life. Kittens are highly social animals and spend most of their waking hours playing and interacting with available companions.
                                                </td>
                                            </tr>
                                        </tbody></table>
                                        <table class="flexibleContainer" width="260" cellspacing="0" cellpadding="0" border="0" align="Right">
                                            <tbody><tr>
                                                <td id="rightRichText" class="textContentLast" valign="top">
                                                    <h3>Right Column</h3>
                                                    <br>
                                                    A kitten or kitty is a juvenile domesticated cat. A feline litter usually consists of two to five kittens. To survive, kittens need the care of their mother for the first several weeks of their life. Kittens are highly social animals and spend most of their waking hours playing and interacting with available companions.
                                                </td>
                                            </tr>
                                        </tbody></table>
                                    </td>
                                </tr>
                            </tbody></table>
                        </td>
                    </tr>
                </tbody></table>
            </td>
        </tr>`,
}