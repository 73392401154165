import { useQuery } from "react-query";
import baseApi from "../../../services/apiPublic";

export default function useTransfererInvitationByMail(ref) {
    return useQuery(
        ['invitation'],
        () => baseApi.post('invitation/transferer', {
            json: {
                ref: ref
            }
        }).json(),
    );
}