import {useEffect, useState} from "react";
import svgResponsiveOrdi from "./svgResponsibleOrdi.svg";
import svgResponsiveTel from "./svgResponsiveTel.svg";
import prewiewReceiptPaymentMobile from "./imgPreviewLaptopReicept.png";
import prewiewReceiptPaymentDesktop from "./imgPreviewDesktopReicept.png";
import Left from "./Left.png"
import Right from "./Right.png"
import LoadingView from "../LoadingView/LoadingView";

export default function PreviewAdaptationMarqueRecu({color = 'black', logoOrIcon}){
    const [mobileDisplay, setMobileDisplay] = useState(false);
    const [logo, setLogo] = useState(logoOrIcon);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        setLogo(logoOrIcon)
        setTimeout(() => setLoading(false), 500)
    }, [logoOrIcon]);


    if (loading){
        return <LoadingView/>
    }

    return <>
        <div className='mb-5' style={{
            margin: "auto",
            borderRadius: 5,
            width: 'fit-content',
            boxShadow: "0 0 0 1px rgba(48,49,61,.1),0 2px 5px 0 rgba(48,49,61,.08),0 1px 1.5px 0 #00000012,0 1px 2px 0 #00000014,0 0 0 0 transparent"
        }}>
            <img onClick={() => {
                setMobileDisplay(false);
            }} className="me-3 p-3 cursor-pointer"
                 style={{filter: !mobileDisplay && "invert(20%) sepia(243%) saturate(1576%) hue-rotate(-21deg) brightness(137%) contrast(73%)"}}
                 src={svgResponsiveOrdi} alt="svgOrdi"/>
            <img onClick={() => {
                setMobileDisplay(true);
            }} src={svgResponsiveTel}
                 style={{filter: mobileDisplay && "invert(20%) sepia(243%) saturate(1576%) hue-rotate(-21deg) brightness(137%) contrast(73%)"}}
                 className="p-3 cursor-pointer" alt="svgTel"/>
        </div>
        <div className={`d-flex m-auto mb-2 flex-column`} style={{
            transition: "width 2s ease",
            width: mobileDisplay ? 400 : 580,
            height: 540,
            border: "solid 1px black",
            borderRadius: 2
        }}>
            <div style={{
                transition: "height 0.5s ease",
            }}
                 className="w-100 d-flex justify-content-center align-items-center position-relative">
                {mobileDisplay ?
                    <div>
                        <table dir="ltr" className="Section Header" width="100%"
                               style={{
                                   border: 0,
                                   borderCollapse: "collapse",
                                   margin: 0,
                                   padding: 0,
                                   backgroundColor: "#ffffff"
                               }}>
                            <tbody>
                            <tr>
                                <td className="Header-left Target"
                                    style={{
                                        backgroundColor: color,
                                        border: 0,
                                        borderCollapse: "collapse",
                                        margin: 0,
                                        padding: 0,
                                        fontSize: 0,
                                        lineHeight: "0px",
                                        backgroundSize: "100% 100%",
                                        borderTopLeftRadius: "5px"}}
                                    align="right" height="156" valign="bottom" width="252">
                                    <a href="#" target="_blank"
                                       style={{outline: 0, textDecoration: "none"}}>
                                        <img alt="" height="156" width="252"
                                             src={Left}
                                             style={{
                                                 display: "block",
                                                 border: 0,
                                                 lineHeight: "100%",
                                                 width: "100%"
                                             }}/>
                                    </a>
                                </td>
                                <td className="Header-icon Target position-relative"
                                    style={{
                                        backgroundColor: color,
                                        border: 0,
                                        borderCollapse: "collapse",
                                        margin: 0,
                                        padding: 0,
                                        fontSize: 0,
                                        lineHeight: 0,
                                        backgroundSize: "100% 100%",
                                        width: "96px !important"
                                    }}
                                    align="center" height="156" valign="bottom">
                                    <div className="position-absolute"
                                         style={{
                                             top: 60,
                                             left: 20,
                                             zIndex: 9,
                                             borderRadius: "50%",
                                             padding: 5,
                                             backgroundColor: "white"
                                         }}
                                    >
                                        <div
                                            style={{
                                                padding: 5,
                                                backgroundOrigin: "content-box",
                                                display: "block",
                                                width: 50,
                                                height: 50,
                                                border: 0,
                                                lineHeight: "100%",
                                                borderRadius: "50%",
                                                background: `url("`+logo+`") no-repeat white`,
                                                backgroundSize: "cover",
                                                zIndex: 10,
                                                top: 60,
                                                left: 20
                                            }}></div>
                                    </div>
                                    <a href="#" target="_blank"
                                       style={{
                                           outline: 0,
                                           textDecoration: "none"
                                       }}>
                                        <div style={{
                                            clipPath: "polygon(0% 27.27%, 100% 0%, 100% 100%, 0% 100%)",
                                            backgroundColor: "#fff",
                                            width: 74,
                                            height: 76
                                        }}></div>
                                    </a>
                                </td>
                                <td className="Header-right Target"
                                    style={{
                                        backgroundColor: color,
                                        border: 0,
                                        borderCollapse: "collapse",
                                        margin: 0,
                                        padding: 0,
                                        lineHeight: 0,
                                        backgroundSize: "100% 100%",
                                        borderTopRightRadius: 5
                                    }}
                                    align="left" height="156" valign="bottom" width="252">
                                    <a href="#" target="_blank"
                                       style={{
                                           outline: 0,
                                           textDecoration: "none"
                                       }}>
                                        <img alt="" height="156" width="252"
                                             src={Right}
                                             style={{display: "block", border: 0, lineHeight: "100%", width: "100%"}}/>
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    : <>
                        <div>
                            <table dir="ltr" className="Section Header" width="100%"
                                   style={{
                                       border: 0,
                                       borderCollapse: "collapse",
                                       margin: 0,
                                       padding: 0,
                                       backgroundColor: "#ffffff"
                                   }}>
                                <tbody>
                                <tr>
                                    <td className="Header-left Target"
                                        style={{
                                            backgroundColor: color,
                                            border: 0,
                                            borderCollapse: "collapse",
                                            margin: 0,
                                            padding: 0,
                                            fontSize: 0,
                                            lineHeight: "0px",
                                            backgroundSize: "100% 100%",
                                            borderTopLeftRadius: "5px"
                                        }}
                                        align="right" height="156" valign="bottom" width="252">
                                        <a href="#" target="_blank"
                                           style={{outline: 0, textDecoration: "none"}}>
                                            <img alt="" height="156" width="252"
                                                 src={Left}
                                                 style={{
                                                     display: "block",
                                                     border: 0,
                                                     lineHeight: "100%",
                                                     width: "100%"
                                                 }}/>
                                        </a>
                                    </td>
                                    <td className="Header-icon Target position-relative"
                                        style={{
                                            backgroundColor: color,
                                            border: 0,
                                            borderCollapse: "collapse",
                                            margin: 0,
                                            padding: 0,
                                            fontSize: 0,
                                            lineHeight: 0,
                                            backgroundSize: "100% 100%",
                                            width: "96px !important"
                                        }}
                                        align="center" height="156" valign="bottom">
                                        <div className="position-absolute"
                                             style={{
                                                 top: 60,
                                                 left: 20,
                                                 zIndex: 9,
                                                 borderRadius: "50%",
                                                 padding: 5,
                                                 backgroundColor: "white"
                                             }}
                                        >
                                            <div
                                                style={{
                                                    padding: 5,
                                                    backgroundOrigin: "content-box",
                                                    display: "block",
                                                    width: 50,
                                                    height: 50,
                                                    border: 0,
                                                    lineHeight: "100%",
                                                    borderRadius: "50%",
                                                    background: `url("`+logo+`") no-repeat white`,
                                                    backgroundSize: "cover",
                                                    zIndex: 10,
                                                    top: 60,
                                                    left: 20
                                                }}></div>
                                        </div>
                                        <a href="#" target="_blank"
                                           style={{
                                               outline: 0,
                                               textDecoration: "none"
                                           }}>
                                            <div style={{
                                                clipPath: "polygon(0% 27.27%, 100% 0%, 100% 100%, 0% 100%)",
                                                backgroundColor: "#fff",
                                                width: 74,
                                                height: 76
                                            }}></div>
                                        </a>
                                    </td>
                                    <td className="Header-right Target"
                                        style={{
                                            backgroundColor: color,
                                            border: 0,
                                            borderCollapse: "collapse",
                                            margin: 0,
                                            padding: 0,
                                            lineHeight: 0,
                                            backgroundSize: "100% 100%",
                                            borderTopRightRadius: 5
                                        }}
                                        align="left" height="156" valign="bottom" width="252">
                                        <a href="#" target="_blank"
                                           style={{
                                               outline: 0,
                                               textDecoration: "none"
                                           }}>
                                            <img alt="" height="156" width="252"
                                                 src={Right}
                                                 style={{
                                                     display: "block",
                                                     border: 0,
                                                     lineHeight: "100%",
                                                     width: "100%"
                                                 }}/>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                }

            </div>
            <div className="d-flex flex-column align-items-center position-relative">
                <span className="position-absolute" style={{fontSize: 14, top: "-30px", fontWeight: "bold"}}>Reçu de xxx</span>
                <span className="text-gray-400 position-absolute" style={{fontSize:11, top: "-10px"}}>Reçu n° 1234-5678</span>
            </div>
            <div className="d-flex justify-content-center align-items-start w-100 h-100 mt-2 "
                 >
                {mobileDisplay ?
                    <img style={{
                        transition: "width 2s ease",
                    }} width={365} className="mt-2" src={prewiewReceiptPaymentMobile} alt=""/>
                    :
                    <img style={{
                        transition: "width 2s ease",
                    }} className="mt-2" width={570} src={prewiewReceiptPaymentDesktop} alt=""/>
                }
            </div>
        </div>
    </>
}