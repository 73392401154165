import { useMutation } from "react-query";
import api from "@services/api";

export default function useChangeMailJustificatifPaiement(props) {
    return useMutation(changeMailJustificatif, props);
}

export async function changeMailJustificatif(mail) {
    return api.post('emailJustificatifPaiement', { json: mail }).json();
}
