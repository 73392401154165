import BlockUI from "@components/BlockUI/BlockUI";
import {Alert, Button, Col, Form, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap";
import { useForm } from "react-hook-form";
import React, { useEffect } from "react";
import {emailPattern} from "../../functions/patterns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import useGetParametresFacturation from "../../requests/parametresPaiement/facturation/useGetParametresFacturation";
import useSetParametresFacturation from "../../requests/parametresPaiement/facturation/useSetParametresFacturation";
import auth from "../../services/auth";
import {useSnapshot} from "valtio";
import useGetDomaineExpediteur from "../../requests/communication/expediteur/useGetDomaineExpediteur";
import {BtSwal} from "../../utils/alerts/sweetAlert";
import useGetTva from "../../requests/parametresPaiement/tva/useGetTva";

function ParametresFacturationView() {

    const parametreFacturation = useGetParametresFacturation();
    const setParametresFacturation = useSetParametresFacturation();
    const { register, handleSubmit, formState: { errors }, reset, watch, setValue, getValues } = useForm();
    const boolAutoriserPaiementCarteHorsUE = watch('autoriserPaiementCarteHorsUE');
    const snap = useSnapshot(auth);
    const domaineExpediteur = useGetDomaineExpediteur();
    const tva = useGetTva();


    useEffect(() => {
        reset(parametreFacturation.data);
        if(!getValues("id") && parametreFacturation.isSuccess){
            setValue("id", snap.agence.id);
        }
    }, [parametreFacturation.data]);

    if (parametreFacturation.isError)
        return <Alert variant='danger'>{parametreFacturation.error?.message}</Alert>;

    if (tva?.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    if (tva?.isError)
        return <Alert variant='danger'>{tva.error?.message}</Alert>;

    if (!snap?.parametreFacturation?.compteStripeIsConfigure){
        return <div className='alert alert-info'>
            <div className="mt-2 text-center">
                <p>
                    Merci de configurer les paramètres de stripe avant de configurer les paramètres de facturation.
                </p>
            </div>
        </div>
    }


    if (tva?.data?.nbLignesTotales == 0){
        return <div className='alert alert-info'>
            <div className="mt-2 text-center">
                <p>
                    Merci de configurer la tva avant de configurer les paramètres de facturation.
                </p>
            </div>
        </div>
    }

    return <BlockUI loading={parametreFacturation.isLoading || setParametresFacturation.isLoading || domaineExpediteur.isLoading || tva.isLoading}>
        <Row className='mt-5'>
            <Col sm={6} className='mt-5 mt-sm-0'>
                <Form.Group>
                    <Form.Label className="required">Nom de l'expéditeur</Form.Label>
                    <Form.Control maxLength={30} className='form-control-solid' {...register('nomExpediteurFacturation', { required:true})} />
                    {errors.nomExpediteurFacturation && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Form.Group>
            </Col>
            <Col sm={6}>
                <Form.Group>
                    <Form.Label className="required">Adresse email de facturation</Form.Label>
                    <Form.Control maxLength={50} className='form-control-solid' {...register('emailFacturation', { pattern: emailPattern(), required:true} )} />
                    {errors.emailFacturation && <Form.Text className='text-danger'>Ce champ est requis (merci d'indiquer un email valide)</Form.Text>}
                </Form.Group>
            </Col>
            {/*<Col sm={6}>*/}
            {/*    <Form.Group>*/}
            {/*        <Form.Label className="required">Taux de TVA</Form.Label>*/}
            {/*        <Form.Control type="number" className='form-control-solid' {...register('tauxTva', { required:true, valueAsNumber:true})} />*/}
            {/*        {errors.tauxTva && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}*/}
            {/*    </Form.Group>*/}
            {/*</Col>*/}
            <Col sm={6}>
                <Form.Group>
                    <Form.Label>Numéroration de la facture</Form.Label>
                    <p className="alert alert-info">La numérotation de la facture sera au format suivant : AAAAMM-NB. (AAAA = année, MM = mois, NB = numéro incrémentable)</p>
                </Form.Group>
            </Col>
            <Col sm={12}>
                <Form.Group>
                    <Form.Label>Mentions particulières</Form.Label>
                        <OverlayTrigger
                            style={{
                                display:"flex",
                                backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                padding: '2px 10px',
                                color: 'white',
                                borderRadius: 3,
                            }}
                            placement="bottom"
                            delay={{ show: 250, hide: 500 }}
                            overlay={<Tooltip>
                                <p>D'autres mentions doivent être inscrites sur la facture selon les cas particuliers suivants :</p>
                                <strong><li>Le vendeur ou prestataire est membre d'un centre de gestion ou d'une association agréée</li></strong>
                                <p>Ajouter la mention : « Membre d'une association agréée, le règlement par chèque et par carte bancaire est accepté ».</p>
                                <strong><li>Le vendeur a un régime de franchise de TVA</li></strong>
                                <p>Ajouter la mention « TVA non applicable, art. 293 B du Code général des impôts ».</p>
                                <strong><li>Le sous traitant ne déclare plus la TVA, c'est l'entreprise principale qui la déclare (autoliquidation de la TVA)</li></strong>
                                <p>Mention « auto-liquidation de la TVA ». Indiquer qu'il s'agit d'un « montant hors taxe ».</p>
                                <strong><li>Les artisans ou les micro-entrepreneurs exerçant une activité artisanale pour laquelle une assurance professionnelle est obligatoire</li></strong>
                                <p>Mention de l'assurance souscrite au titre de l'activité.</p>
                            </Tooltip>}
                        >
                            <button className="btn btn-icon"> <FontAwesomeIcon icon={faExclamationCircle} size='sm' className='ms-2' /></button>
                        </OverlayTrigger>
                    <Form.Control as='textarea' rows={5} className='form-control-solid' {...register('mentionParticuliere')} />
                </Form.Group>
            </Col>
            <Col sm={12}>
                <Form.Group>
                    <Form.Label>Information importantes <small>(Ce message sera affiché en rouge dans la facture)</small></Form.Label>
                    <Form.Control as='textarea' rows={5} className='form-control-solid' {...register('messageImportantFacturation')} />
                </Form.Group>
            </Col>
            <Form.Group className='mt-5'>
                <Form.Label>Autorisations de paiement stripe</Form.Label>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <Form.Check
                            type='switch'
                            label="J'autorise le paiement CB hors UE. (3,25% + 0,50€ de frais)"
                            id='publierAutomatiquementSujetForumCheck'
                            {...register('autoriserPaiementCarteHorsUE')}
                        />
                    </Col>
                    {boolAutoriserPaiementCarteHorsUE && <Col lg={6} md={6} sm={12}>
                        <Form.Check
                            type='switch'
                            label="J'autorise le paiement CB britanniques. (2,5% + 0,50€ de frais)"
                            id='publierAutomatiquementSujetForumCheck'
                            {...register('autoriserPaiementCarteGB')}
                        />
                    </Col>}
                </Row>
            </Form.Group>
            <Button
                style={{width:"fit-content"}}
                onClick={() => {
                    window.open(parametreFacturation?.data?.urlTemplateFacture, "_blank")
                }}
                variant={"secondary"}
                className="ms-3 mt-3">
                Télécharger le modèle de la facture
            </Button>
        </Row>

        <div className='d-flex justify-content-center mt-10'>
            <Button
                variant='secondary'
                className='px-20'
                onClick={handleSubmit(save)}
            >
                Enregistrer les modifications
            </Button>
        </div>
    </BlockUI>;

    function save(data){
        let nomDeDomaineEmail = data?.emailFacturation.split("@")[1];
        let arrayDomaines = () => domaineExpediteur?.data?.data.map((item) => {
                return item.libelle;
            });

        if (domaineExpediteur?.data?.data.find(item => item.libelle == nomDeDomaineEmail)){
            setParametresFacturation.mutate(data);
        }else {
            BtSwal.fire("Erreur email", "Le nom de domaine de l'email de facturation doit appartenir à la liste suivante : "+arrayDomaines().toString(), "info");
        }
    }
}

export default ParametresFacturationView;