import {useMutation, useQueryClient} from "react-query";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import baseApi from "../../services/apiPublic";

export default function useChangeNotificationsPublic() {

    const queryClient = useQueryClient();
    return useMutation(changeNotification, {
        onSuccess: () => {
            queryClient.invalidateQueries('parametreNotification');
            toast.success('Changements enregistrés', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function changeNotification(data) {

    if (data.ref != null)
        return baseApi.post(`parametreNotification`, {json : data}).json();

}

