import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useDeleteCategorieMail() {

    const queryClient = useQueryClient();

    return useMutation(deleteCategorie, {
        onSuccess: () => {
            queryClient.invalidateQueries('categorieEmail');
            toast.success("Catégorie supprimé");
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

}

function deleteCategorie(id) {

    if (id != null)
        return api.delete(`categorieEmail/${id}`).json();

}