import {useMutation} from "react-query";
import toast from "react-hot-toast";
import api from "../../../services/api";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useSendRelancePaiement() {

    return useMutation(send, {
        onSuccess: () => {
            toast.success('La relance a été envoyé', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'envoi", err.message);
        }
    });
}

function send(id) {

    if (id != null)
        return api.post(`correspondantInvitation/${id}/relancerPaiement`).json();

}