import useGetParametresAgence from "@requests/parametres/agence/useGetParametresAgence";
import BlockUI from "@components/BlockUI/BlockUI";
import { Alert, Button, Card, Col, Form, Row, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import useSetParametresAgence from "../../../../requests/parametres/agence/useSetParametresAgence";

function AgenceSettings({ show }) {
    const parametresAgence = useGetParametresAgence({ enabled: show });
    const setParametresAgence = useSetParametresAgence();
    const { register, handleSubmit, reset } = useForm();

    useEffect(() => {
        reset(parametresAgence.data);
    }, [parametresAgence.data]);

    if (parametresAgence.isError)
        return <Alert variant='danger'>{parametresAgence.error?.message}</Alert>;

    return <BlockUI loading={parametresAgence.isLoading}>
        <Form.Group className='mt-5'>
            <Form.Label>Responsable</Form.Label>
            <Form.Control className='form-control-solid' {...register('prenomNomResponsable')} />
        </Form.Group>
        <Row className='mt-5'>
            <Col sm={6}>
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control className='form-control-solid' {...register('email')} />
                </Form.Group>
            </Col>
            <Col sm={6} className='mt-5 mt-sm-0'>
                <Form.Group>
                    <Form.Label>Téléphone</Form.Label>
                    <Form.Control className='form-control-solid' {...register('tel')} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col className='mt-5'>
                <Form.Group>
                    <Form.Label>Adresse</Form.Label>
                    <Form.Control
                        as='textarea'
                        className='form-control-solid'
                        rows={5}
                        {...register('adresse')}
                    />
                </Form.Group>
            </Col>
            <Col className='mt-5' md={3}>
                <Stack gap={3}>
                    <Form.Group>
                        <Form.Label>Code postal</Form.Label>
                        <Form.Control className='form-control-solid' {...register('codePostal')} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Ville</Form.Label>
                        <Form.Control className='form-control-solid' {...register('ville')} />
                    </Form.Group>
                </Stack>
            </Col>
        </Row>
        <Form.Group className='mt-5'>
            <Form.Label>Site web</Form.Label>
            <Form.Control className='form-control-solid' {...register('urlSiteWeb')} />
        </Form.Group>
        <Card className='bg-light card-flush mt-10'>
            <Card.Body>
                <h3>Réseaux sociaux</h3>
                <Row className='mt-5'>
                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Linkedin</Form.Label>
                            <Form.Control className='form-control-solid' {...register('urlLinkedin')} />
                        </Form.Group>
                        <Form.Group className='mt-3'>
                            <Form.Label>Instagram</Form.Label>
                            <Form.Control className='form-control-solid' {...register('urlInstagram')} />
                        </Form.Group>
                        <Form.Group className='mt-3'>
                            <Form.Label>Youtube</Form.Label>
                            <Form.Control className='form-control-solid' {...register('urlYoutube')} />
                        </Form.Group>
                    </Col>
                    <Col sm={6} className='mt-3 mt-sm-0'>
                        <Form.Group>
                            <Form.Label>Twitter</Form.Label>
                            <Form.Control className='form-control-solid' {...register('urlTwitter')} />
                        </Form.Group>
                        <Form.Group className='mt-3'>
                            <Form.Label>TikTok</Form.Label>
                            <Form.Control className='form-control-solid' {...register('urlTiktok')} />
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        <div className='d-flex justify-content-center mt-5'>
            <Button
                variant='success'
                onClick={handleSubmit(setParametresAgence.mutate)}
            >
                Enregistrer les modifications
            </Button>
        </div>
    </BlockUI>;
}

export default AgenceSettings;