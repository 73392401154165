import { useQuery } from "react-query";
import baseApi from "../../services/apiPublic";

export default function useGetVerifPaiement(ref, refPaiement, sendMail) {
    return useQuery(
        ['useGetVerifPaiement'],
        async () => {
            return baseApi.post(`stripe/verificationPaiement`, {
                json: { referencePaiement: refPaiement,
                        ref: ref
                }
            }).json();
        }
    );
}