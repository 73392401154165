export const iconLienPj = `<svg version="1.1" width="61.4" height="20" id="b5de8fc1-f6f3-4345-b9c5-8459b1b42c8c"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1368.9 535.1"
    style="enable-background:new 0 0 1368.9 535.1;" xml:space="preserve">
        <path d="M1260.6,102.9c-23-23-60.3-23-83.3,0l-173.4,173.4c-39.4,39.9-39,104.2,0.9,143.6c39.6,39.1,103.2,39.1,142.8,0l143.2-143.3
    c10.2-10.4,26.9-10.5,37.3-0.3s10.5,26.9,0.3,37.3c-0.1,0.1-0.2,0.2-0.3,0.3l-143.2,143.3c-60.3,60.3-158,60.3-218.3,0
    c-60.3-60.3-60.3-158,0-218.3l173.4-173.4c43.6-43.6,114.4-43.6,158,0s43.6,114.4,0,158L1132,389.4c-27,27-70.7,27-97.6,0
    c-27-27-27-70.7,0-97.6L1170,156.1c10.3-10.3,27-10.3,37.3,0c10.3,10.3,10.3,27,0,37.3l0,0l-135.7,135.7c-6.4,6.4-6.4,16.6,0,23
    c6.4,6.4,16.6,6.4,23,0l165.9-165.9C1283.5,163.2,1283.5,126,1260.6,102.9z"/>
    <path d="M597.6,279.1c56.5-56.5,56.5-148,0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6,1.1c-14.4,10.3-17.7,30.3-7.4,44.6
    s30.3,17.7,44.6,7.4l1.6-1.1c32.1-22.9,76-19.3,103.8,8.6c31.5,31.5,31.5,82.5,0,114L440.1,346.2c-31.5,31.5-82.5,31.5-114,0
    c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4,6.9-34.4-7.4-44.6s-34.4-6.9-44.6,7.4l-1.1,1.6
    c-41.2,57.4-34.7,136.2,15.3,186.2c56.5,56.5,148,56.5,204.5,0L597.6,279.1z M78,255.7c-56.5,56.5-56.5,148,0,204.5
    c50,50,128.8,56.5,186.3,15.4l1.6-1.1c14.4-10.3,17.7-30.3,7.4-44.6s-30.3-17.7-44.6-7.4l-1.6,1.1c-32.1,22.9-76,19.3-103.8-8.6
    c-31.5-31.6-31.5-82.6,0-114.1l112.2-112.3c31.5-31.5,82.5-31.5,114,0c27.9,27.9,31.5,71.8,8.6,103.9l-1.1,1.6
    c-10.3,14.4-6.9,34.4,7.4,44.6s34.4,6.9,44.6-7.4l1.1-1.6c41.2-57.5,34.7-136.3-15.3-186.3c-56.5-56.5-148-56.5-204.5,0L78,255.7z"
    />
    <g>
    <path d="M835.8,288.8c-20.9,1.5-58.1,2.3-111.5,2.3c-9.4,0-17.6-2.1-24.7-6.4c-8.3-5.1-12.5-12.1-12.5-20.9
    c0-8.8,4.2-15.7,12.5-20.9c7.1-4.4,15.4-6.6,24.7-6.6c12,0,29.9-0.3,53.8-1c23.9-0.7,41.9-1,53.8-1c9.4,0,17.6,1.9,24.7,5.7
    c9.1,4.8,13.6,12,13.6,21.6C870.4,278.1,858.8,287.1,835.8,288.8z"/>
    </g>
    </svg>`;