import CountDownCircleTimerLight from "../../../../components/CountDownCircleTimer/CountDownCircleTimerLight";
import CountDownCircleTimer from "../../../../components/CountDownCircleTimer/CountDownCircleTimer";
import {dureeMinuteToTimeMysqlWithoutHours, dureeTimeMysqlToMinute} from "../../../../views/SpeedBusinessView/ReglageStep/data";
import CustomProgressBar from "../../../../components/CustomProgressBar/CustomProgressBar";
import {useEffect, useMemo, useState} from "react";
import "./style.css";

export default function Groupe({
                             tour,
                             speedBusiness,
                             isTimePersonne,
                             isPlaying,
                             currentPerson,
                             setCurrentPerson,
                             setTour,
                             setIsEntracte,
                             isEntracte,
                             setIsChangementTour,
                             isChangementTour,
                             setCurrentTime,
                             setIsTimePersonne,
                             currentTime,
                             numberOfStep,
                             zigZagBar,
                             numIsPair,
                             duration,
                             personneParTable,
                             color,
                             nomDuGroupe,
                             hasGroup,
                             valueChangeRemainingTime,
                             setValueChangeRemainingTime,
                             setInitCurrentPerson
                         }) {

    const tabInfo = useMemo(() =>{
        return [
            {
                description: "Présentation de soi-même",
                duration : duration*0.2
            },
            {
                description: "Présentation de mon entreprise",
                duration : duration*0.3
            },
            {
                description: "Ce que je recherche précisément",
                duration : duration*0.25
            },
            {
                description: "Questions-réponses",
                duration : duration*0.25
            },
        ]
    },[duration]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTimeUp, setIsTimeUp] = useState(false);
    const [isTimeUpBottom, setIsTimeUpBottom] = useState(false);
    const [isTimeUpBottom2, setIsTimeUpBottom2] = useState(false);
    const [delayStart, setDelayStart] = useState(false);
    const [valueChangeRemainingTimeLight, setValueChangeRemainingTimeLight] = useState(true);

    useEffect(() => {
        setIsTimeUp(true)
        setDelayStart(true)
        setIsTimeUpBottom(true)
        setIsTimeUpBottom2(true)
        setTimeout(() => setIsTimeUp(false), 400)
        setTimeout(() => setDelayStart(false), 400)
        setTimeout(() => setIsTimeUpBottom(false), 400)
        setTimeout(() => setIsTimeUpBottom2(false), 400)
    }, [currentIndex])

    useEffect(() => {
        if (valueChangeRemainingTime && valueChangeRemainingTimeLight){
            let tempsEcoulee = duration - valueChangeRemainingTime;
            let i = 0;
            for (let stepInfo of tabInfo) {
                tempsEcoulee = tempsEcoulee - stepInfo.duration;
                if (tempsEcoulee < 0){
                    setCurrentIndex(i)
                    //temps restant de l'étape
                    setValueChangeRemainingTimeLight(Math.abs(tempsEcoulee))
                    break;
                }
                i++;
            }
        }
    }, [valueChangeRemainingTime])

    return <div className="d-flex justify-content-center flex-wrap"
                style={{
                    width: 500 //largeur groupe + nbtours
                }}
    >
        {/*Bloc nbTours*/}
        <div style={{
            marginTop: 10,
            marginRight: 5
        }}>
                        <span style={{
                            width: 80,
                            fontSize: 15,
                            fontWeight: "bold",
                            display: "inline-block",
                            textAlign: "center"
                        }}>
                            Nombre de tours
                        </span>
            <div style={{
                width: 80,
                height: 80,
                backgroundColor: "white",
                border: "solid 1px grey",
                borderRadius: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                            <span style={{
                                fontSize: 20,
                                fontWeight: "bold"
                            }}>
                                {tour} <span style={{
                                fontSize: 16,
                                color: "var("+color+")"
                            }}>/{speedBusiness?.data?.nombreTours}</span>
                            </span>
            </div>
        </div>
        {/*Bloc infos groupe A*/}
        <div
            style={{
                marginTop: 10,
                width: "400px",
                height: "254px",
            }}
        >
            {hasGroup && <div style={{
                height: 52,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 0,
                border: "4px var("+color+") solid",
                borderRadius: "20px 20px 0  0"
            }}>
                            <span
                                style={{
                                    width: "100%",
                                    fontSize: 28,
                                    color: "var("+color+")",
                                    textAlign: "center",
                                    display: "inline-block",
                                    fontWeight: "bold"
                                }}
                            >GROUPE {nomDuGroupe}</span>

            </div>}
            <div style={{
                height: 52,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: !hasGroup ? "20px 20px 0  0" : "",
                margin: 0,
                backgroundColor: "var("+color+")"
            }}>
                             <span
                                 style={{
                                     width: "100%",
                                     fontSize: 22,
                                     color: "white",
                                     textAlign: "center",
                                     display: "inline-block",
                                     fontWeight: "bold"
                                 }}
                             >{Math.trunc(duration/60)+" minute"+(Math.trunc(duration/60)>1 ? "s" : "")+(duration%60 != 0 ? (duration%60 < 10 ? " 0": " ")+""+Math.round(duration%60) : "")} pour vous présenter</span>

            </div>
            <div style={{
                height: 150,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 0,
                backgroundColor: "#d8d8d8",
                borderRadius: "0 0 20px 20px"
            }}>
                <div className="d-flex justify-content-center align-items-center flex-wrap" style={{
                    height:150,
                    width:"100%"
                }}>
                            <span
                                style={{
                                    width: "100%",
                                    fontSize: 18,
                                    color: "rgba(0,0,0,0.50)",
                                    textAlign: "center",
                                    display: "block",
                                    fontWeight: "bold",
                                    marginBottom: 10,
                                    height:36
                                }}
                            >
                                <div className={`${delayStart ? "d-none" : ""} d-flex justify-content-center align-items-center`}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>
                                                {currentIndex !== 0 && tabInfo[currentIndex-1].description}
                                            </span>
                                            <div style={{
                                                fontSize: 18
                                            }} className="d-block">
                                        {currentIndex !== 0 && <CountDownCircleTimerLight color={color} duration={currentIndex !== 0 && tabInfo[(currentIndex-1)].duration} isPlaying={false}/>}
                                            </div>
                                        </div>
                                </div>
                            </span>
                    <span
                        style={{
                            width: "100%",
                            fontSize: 20,
                            color: "black",
                            textAlign: "center",
                            display: "block",
                            fontWeight: "bold",
                            marginBottom: 10,
                            height:36

                        }}
                    >
                                    <div className={`${isTimeUp ? "time-up" : ""} d-flex justify-content-center align-items-center`}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>
                                                {currentIndex < tabInfo.length && tabInfo[((isTimeUp && currentIndex != 0) ? currentIndex - 1 : currentIndex)].description}
                                            </span>
                                            <div style={{
                                                fontSize: 18
                                            }} className="d-block">
                                       {currentIndex < tabInfo.length && <CountDownCircleTimerLight color={color} currentPerson={currentPerson} setCurrentIndex={setCurrentIndex} setValueChangeRemainingTimeLight={setValueChangeRemainingTimeLight} valueChangeRemainingTimeLight={valueChangeRemainingTimeLight} currentIndex={currentIndex} duration={currentIndex < tabInfo.length && tabInfo[currentIndex].duration} isPlaying={isPlaying}/>}
                                            </div>
                                        </div>
                                    </div>
                                </span>
                    <span
                        style={{
                            width: "100%",
                            fontSize: 18,
                            color: "rgba(0,0,0,0.50)",
                            textAlign: "center",
                            display: "block",
                            fontWeight: "bold",
                            position:"relative",
                            height:36
                        }}
                    >
                                    <div
                                        className={`${isTimeUpBottom ? "time-up-bottom" : ""} d-flex justify-content-center align-items-center`}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>
                                                {(currentIndex < tabInfo.length - 1 || (isTimeUpBottom && currentIndex < tabInfo.length)) && tabInfo[(isTimeUpBottom ? currentIndex : currentIndex + 1)].description}
                                            </span>
                                            <div style={{
                                                fontSize: 18
                                            }} className="d-block">
                                        {(currentIndex < tabInfo.length - 1 || (isTimeUpBottom && currentIndex < tabInfo.length)) && <CountDownCircleTimerLight color={color}
                                                                                                         duration={(currentIndex < tabInfo.length - 1 || isTimeUpBottom) && tabInfo[(isTimeUpBottom ? currentIndex : currentIndex + 1)].duration}
                                                                                                         isPlaying={false}/>}
                                            </div>
                                        </div>
                                    </div>
                        <div
                            style={{
                                width:"100%",
                                textAlign:"center",
                                bottom:7
                            }}
                            className={`${isTimeUpBottom2 ? "position-absolute delay" : "d-none-custom"} d-flex justify-content-center align-items-center`}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>
                                                {currentIndex < tabInfo.length - 1 && tabInfo[currentIndex + 1].description}
                                            </span>
                                            <div style={{
                                                fontSize: 18
                                            }} className="d-block">
                                        {currentIndex < tabInfo.length - 1 && <CountDownCircleTimerLight color={color}
                                                                                                         duration={currentIndex < tabInfo.length - 1 && tabInfo[currentIndex + 1].duration}
                                                                                                         isPlaying={false}/>}
                                            </div>
                                        </div>
                                    </div>

                                </span>
                </div>
            </div>
            <span style={{
                display: "inline-block",
                width: "100%",
                textAlign: "center"
            }}>*Cette liste est indicative.
                            Le décompte de celle-ci est en secondes.</span>
        </div>
        <div style={{
            width: "100%"
        }} className="mt-20 d-flex justify-content-center align-items-center">
            <CountDownCircleTimer
                setInitCurrentPerson={setInitCurrentPerson}
                setValueChangeRemainingTime={setValueChangeRemainingTime}
                valueChangeRemainingTime={valueChangeRemainingTime}
                color={color}
                isPlaying={isPlaying}
                currentPerson={currentPerson}
                setCurrentPerson={setCurrentPerson}
                personneParTable={personneParTable}
                tour={tour}
                setTour={setTour}
                setIsEntracte={setIsEntracte}
                isEntracte={isEntracte}
                setIsChangementTour={setIsChangementTour}
                isChangementTour={isChangementTour}
                isTimePersonne={isTimePersonne}
                setCurrentTime={setCurrentTime}
                setIsTimePersonne={setIsTimePersonne}
                duration={duration}
            />
            <div className="ms-5">
                            <span
                                style={{
                                    borderColor: "var("+color+")"
                                }}
                                className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(currentTime / 60)[0]}</span>
                <span style={{
                    borderColor: "var("+color+")"
                }}
                    className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(currentTime / 60)[1]}</span>
                <span style={{
                    fontSize: 30,
                    fontWeight: "bold",
                    marginRight: 10
                }}>{dureeMinuteToTimeMysqlWithoutHours(currentTime / 60)[2]}</span>
                <span style={{
                    borderColor: "var("+color+")"
                }}
                    className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(currentTime / 60)[3]}</span>
                <span style={{
                    borderColor: "var("+color+")"
                }}
                    className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(currentTime / 60)[4]}</span>

            </div>
        </div>
        <div style={{
            marginTop: 30,

        }}>
                        <span> <i className="fa fa-users fa-2x me-2" style={{
                            color: "var("+color+")"
                        }}></i> <span style={{
                            fontSize: 18,
                            fontWeight: "bold"
                        }}>Ordre de passage</span></span>
        </div>
        <div style={{
            marginTop: 60,
            marginLeft: 50,
            width: "100%"
        }} className="">
            {tour <= speedBusiness?.data.nombreTours && <div className="position-relative ms-5" style={{
                height: 200,

            }}>
                <div style={{
                    backgroundColor: "white",
                    height: 120,
                    width: 500,
                    position: "absolute",
                    top: -52,
                    left: -35,
                    borderRadius: 75,
                    border: "solid 6px #d8d8d8"
                }}>

                </div>
                {numberOfStep.map((number, index) => {
                    if (number == 1) {
                        return <CustomProgressBar
                            color={color}
                            zIndex={100 - number}
                            rotate={-zigZagBar.rotate}
                            nbToursTotal={speedBusiness?.data.nombreTours}
                            numberOfStep={[1, 2]}
                            width={zigZagBar.width}
                            left={zigZagBar.left * 0}
                            progressPercent={currentPerson == 1 ? (((duration) - currentTime) / duration) * 100 : 100}
                        />
                    } else if (index == 1) {
                        return;
                    } else if (index == numberOfStep.length - 1) {
                        return <CustomProgressBar
                            color={color}
                            zIndex={100 - number}
                            rotate={!numIsPair(number) ? zigZagBar.rotate : -zigZagBar.rotate}
                            left={zigZagBar.left * (index - 1)}
                            width={zigZagBar.width}
                            nbToursTotal={speedBusiness?.data.nombreTours}
                            numberOfStep={[0, "fin"]}
                            progressPercent={currentPerson > number - 1 ? 100 : (currentPerson == number - 1 ? (((duration) - currentTime) / duration) * 100 : 0)}
                        />
                    } else {
                        return <CustomProgressBar
                            color={color}
                            zIndex={100 - number}
                            rotate={!numIsPair(number) ? zigZagBar.rotate : -zigZagBar.rotate}
                            left={zigZagBar.left * (index - 1)}
                            width={zigZagBar.width}
                            nbToursTotal={speedBusiness?.data.nombreTours}
                            numberOfStep={[0, number]}
                            progressPercent={currentPerson > number - 1 ? 100 : (currentPerson == number - 1 ? (((duration) - currentTime) / duration) * 100 : 0)}
                        />
                    }
                })
                }
            </div>}
        </div>

    </div>;
}