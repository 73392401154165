import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";
import moment from "moment";

export default function useGetPublications() {
    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = {
        data: "classement",
        searchable: true,
        orderable: true,
        search: {
            value: "",
            regex: false,
        }
    }

    filters.columns[1] = {
        data: "dateFin",
        searchable: true,
        orderable: true,
        search: {
            value: moment(new Date()).format("YYYY-MM-DD"),
            regex: false,
        },
        searchOperator: ">"
    }

    filters.order[0] = {
        column:0,
        dir: "asc"
    }

    return useQuery(
        ['publicationMobile'],
        async () => {
            return api.get("publicationMobile", {
                searchParams: Qs.stringify({
                    start: 0,
                    length: 100,
                    ...filters,
                }, { arrayFormat: 'brackets' }),
            }).json();
        }
    );
}