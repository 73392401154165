import mailEditorProxy from "../../../proxies/mailEditorProxy";
import {Alert, Button, Col, Form, Modal} from "react-bootstrap";
import $ from "jquery";
import {useSnapshot} from "valtio";
import {useState} from "react";

function AddJustOneUrlOnImageModal(){
    const mailEditorSnap = useSnapshot(mailEditorProxy);
    const [justOneUrl, setJustOneUrl] = useState(mailEditorSnap?.block?.content?.url ? mailEditorSnap?.block?.content?.url : "");

    return <Modal
        show={mailEditorSnap.modalAddOneUrlOnImage}
        onHide={() => {
            mailEditorProxy.modalAddOneUrlOnImage = false
        }}
        size='lg'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <div className='w-100 stepper stepper-links d-flex flex-column between'>
                <div className='stepper-nav'>
                    <h3 className='stepper-title'>Ajout de liens sur les images</h3>
                </div>
            </div>
        </Modal.Header>
        <Modal.Body>
            <Col className='mt-5 mt-sm-0'>
                <Form.Label>Lien</Form.Label>
                <Form.Control value={justOneUrl} onChange={(e) => {
                    setJustOneUrl(e.target.value)
                }} />
            </Col>

            <Alert hidden={true} id="errorMessageJustOneUrl"  className='mt-2 mx-auto' key='danger' variant='danger'>
                Lien incorrect format attendu -> https://xxxxx.xx.
            </Alert>

            {/*{errors.txtLien && <Form.Text className='text-danger'>Texte pour l'url requis</Form.Text>}*/}
            <div className="d-flex justify-content-center mt-2">
                <Button
                    variant='primary'
                    onClick={() => {
                        if (isValidHttpUrl(justOneUrl)) {
                            let result = {
                                ...mailEditorSnap.block,
                                content: {
                                    ...mailEditorSnap.block.content,
                                    url : justOneUrl,
                                }
                            };
                            mailEditorSnap.onChange(result)
                            mailEditorProxy.modalAddOneUrlOnImage = false;
                        }else {
                            $("#errorMessageJustOneUrl").attr("hidden",false);
                        }

                    }}
                >Valider</Button>
            </div>
        </Modal.Body>
    </Modal>;

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "https:";
    }
}

export default AddJustOneUrlOnImageModal;