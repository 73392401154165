import React from "react";

export function renderSwitchInscriptionInscriptionLibreEtPreview(champsPerso, key, color){
    if (champsPerso && champsPerso[key] && champsPerso[key].state.active){
        switch(key) {
            case "champs1":
                return <div className="position-relative p-10 pb-5"><div style={{
                    color: color
                }} dangerouslySetInnerHTML={{
                    __html: champsPerso[key].html
                }}></div>
                </div>;
            case "champs2":
                return <div className="position-relative p-10 pb-5"><div style={{
                    color: color
                }} dangerouslySetInnerHTML={{
                    __html: champsPerso[key].html
                }}></div>
                </div>;
            case "champs3":
                return <div className="position-relative pb-5"><div style={{
                    color: color
                }} dangerouslySetInnerHTML={{
                    __html: champsPerso[key].html
                }}></div>
                </div>;
            case "champs4":
                return <div className="position-relative p-10 pb-5"><div style={{
                    color: color
                }} dangerouslySetInnerHTML={{
                    __html: champsPerso[key].html
                }}></div>
                </div>;
            case "default":
                return "";
        }
    }
}