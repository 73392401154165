import {useState} from "react";
import tinymce from "tinymce";
import fileManagerProxy from "../../proxies/fileManager";
import {useSnapshot} from "valtio";
import {Button, Form, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import $ from "jquery";

function LineFileView({nom, file, changeArbo, setCheminParent, stateArbo, cheminParent}) {

    const fileManagerSnap = useSnapshot(fileManagerProxy);

    let tiny = tinymce.get(fileManagerSnap.baseId+fileManagerSnap.id)


    const [modalChoixTxtUrl, setModalChoixTxtUrl] = useState(false)


    if (cheminParent != file.cheminParent){
        setCheminParent(file.cheminParent)
    }

    const {register, handleSubmit, formState : {errors} } = useForm();

    function saveUrl() {
        $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled",false);
        $(".tox-textfield").first().val(file.urlAcces)
        if ($(".tox-textfield").eq(1).val() == ""){
            $(".tox-textfield").eq(1).val("Cliquer ici pour ouvrir la pièce jointe.")
        }
        $(".tox-dialog__footer-end button").eq(1).trigger('click')
        fileManagerProxy.showFileManager.show = false;

    }

    return <><tr>

        <td>
            <div className="d-flex align-items-center">

                        <span className="svg-icon svg-icon-2x svg-icon-primary me-4">
															<img width={24} src={file.urlMiniature} alt="urlMiniature"/>
														</span>

                <a onClick={() => {
                    if(file.typeElement == "dir"){
                        // setCheminParent(file.cheminParent)
                        changeArbo(file.cheminAcces)
                    }else {
                        if ((file.extension == "jpg" || file.extension == "png" || file.extension == "gif") && !fileManagerProxy.isUrl){
                            tiny.insertContent("<img src="+file.urlAcces+" />")
                            $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled",false);
                            $(".tox-dialog__footer-end button").eq(1).trigger('click')
                            $(".tox-textfield").eq(1).val("L'image n'a pas pu être chargée")
                            fileManagerProxy.showFileManager.show = false;
                        }else if (fileManagerProxy.isUrl){
                            fileManagerProxy.urlForPublicationMobile = file.urlAcces;
                            fileManagerProxy.initUrlForPublicationMobile = false;
                            saveUrl()
                        }
                        //ici pour activer la fonctionnalité lien

                        // window.open(file.urlAcces);
                    }
                }}
                   className="text-gray-800 text-hover-primary cursor-pointer" >{nom}</a>
            </div>
        </td>

        <td>{file.taille ? (file.taille / 1048576).toFixed(2) + "Mo" : ""}</td>

        <td>{file.dateCreationElement ? file.dateCreationElement : ""}</td>

        <td className="text-end" data-kt-filemanager-table="action_dropdown">
            <div className="d-flex justify-content-end">

                <div className="ms-2" data-kt-filemanger-table="copy_link">
                    <button
                        onClick={() => {
                            if(file.typeElement == "dir"){
                                // setCheminParent(file.cheminParent)
                                changeArbo(file.cheminAcces)
                            }else {
                                if ((file.extension == "jpg" || file.extension == "png" || file.extension == "gif") && !fileManagerProxy.isUrl){
                                    tiny.insertContent("<img src="+file.urlAcces+" />")
                                    $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled",false);
                                    $(".tox-dialog__footer-end button").eq(1).trigger('click')
                                    $(".tox-textfield").eq(1).val("L'image n'a pas pu être chargée")
                                    fileManagerProxy.showFileManager.show = false;
                                }else if (fileManagerProxy.isUrl){
                                    fileManagerProxy.urlForPublicationMobile = file.urlAcces;
                                    fileManagerProxy.initUrlForPublicationMobile = false;
                                    saveUrl()
                                }
                                //ici pour activer la fonctionnalité lien

                                // window.open(file.urlAcces);
                            }
                        }}
                        type="button" className="btn btn-sm btn-icon btn-light btn-active-light-primary"
                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">

                            <img src="/icon/serverDownload.svg" style={{width: "30px", padding: "2px", margin: "0", backgroundColor :"lightgray", borderRadius :"5px"}} alt="Valider" width="50px"/>

                    </button>

                </div>

            </div>
        </td>

    </tr>
    <Modal
        show={modalChoixTxtUrl}
        size='lg'
        enforceFocus={false}
    >
        <Modal.Body>
            <Form.Label>Texte pour le lien</Form.Label>
            <Form.Control {...register('txtLien', { required: true })} />
            {errors.txtLien && <Form.Text className='text-danger'>Texte pour l'url requis</Form.Text>}
            <div className="d-flex justify-content-center mt-2">
                <Button
                    variant='primary'
                    onClick={handleSubmit(()=>saveUrl())}
                >Valider</Button>
            </div>
        </Modal.Body>
    </Modal></>

}

export default LineFileView;
