import {Modal} from "react-bootstrap";
import React from "react";

function DetailContributionsModal({showModal, setShowModal, data}) {
    return <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size='lg'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Détails des contributions pour le climat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="table-responsive m-auto w-500px ">
                <table className="table table-rounded table-striped border">
                    <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                        <th className="text-center" colSpan={2}>
                            Récapitulatifs des contributions
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data?.map(item => {
                            return <tr><td className="p-3">Contribution</td>
                                <td className="p-3">{Math.abs(item?.montant).toFixed(2).toString().replace(".", ",")}€</td></tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        </Modal.Body>
    </Modal>;
}

export default DetailContributionsModal;