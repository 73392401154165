import React, {useEffect, useState} from "react";
import Cropper from "react-cropper";
import {v4 as uuidv4} from "uuid";
import useUploadFile from "../../requests/upload/useUploadFile";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {BtSwal} from "../../utils/alerts/sweetAlert";
import publicationMobileProxy from "../../proxies/publicationMobile";
import toast from "react-hot-toast";
import BlockUI from "../BlockUI/BlockUI";
import {Button} from "react-bootstrap";

function CropperForPublication({onChangeForPublication, getValues}) {
    const [image, setImage] = useState((typeof getValues === "function" && getValues("uploadOriginal.urlOriginale")) ? getValues("uploadOriginal.urlOriginale") : "");
    const [imageOriginal, setImageOriginal] = useState(null);
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();

    const uploadFile = useUploadFile({
        type: "publication",
        onSuccess: (data) => {
            onChangeForPublication("upload", data)
            BtSwal.fire("L'image à bien été ajouté à la publication.", "", "success");
            publicationMobileProxy.editCropperModal.show = false;
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'upload de l'image", err.message);
        },
    });

    const uploadFileOriginal = useUploadFile({
        type: "publication",
        onSuccess: (data) => {
            onChangeForPublication("uploadOriginal", data)
            toast.success("L'image original à bien été ajouté à la publication.")
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'upload de l'image original", err.message);
        },
    });

    const onChange = (e) => {
        var _URL = window.URL || window.webkitURL;
        var file, img;
        var imgIsOk = true;

        if ((file = e.target.files[0])) {
            img = new Image();
            img.onload = function() {
                if (this.height < 1000 || this.width < 500){
                    showErrorAlert("Dimensions de l'image trop faible.", "Dimensions minimales : 500x1000")
                    imgIsOk = false;

                }else {
                    if (imgIsOk){
                        e.preventDefault();
                        let files;
                        if (e.dataTransfer) {
                            files = e.dataTransfer.files;
                        } else if (e.target) {
                            files = e.target.files;
                        }
                        setImageOriginal(files[0])
                        const reader = new FileReader();
                        reader.onload = () => {
                            setImage(reader.result);
                        };
                        reader.readAsDataURL(files[0]);
                    }
                }
                // alert(this.width + " " + this.height);
            };
            img.onerror = function() {
                alert( "not a valid file: " + file.type);
            };
            img.src = _URL.createObjectURL(file);

        }
    };

    const getCropData = () => {
        if (image){
            if (typeof cropper !== "undefined") {
                cropper.getCroppedCanvas().toBlob((blob) => {
                        let file = new File([blob], uuidv4()+".png")
                        uploadFile.mutate(file);
                        if (imageOriginal){
                            uploadFileOriginal.mutate(imageOriginal);
                        }
                        setCropData(cropper.getCroppedCanvas().toDataURL());
                }, 'image/png')
            }
        }else {
            showErrorAlert("Image manquante", "Merci d'importer une image avant de recadrer");
        }
    };
    useEffect(() => {
        if (cropper){
            document.getElementById("cropperId").children[1].addEventListener('zoom', (event) => {
                if (event.detail.ratio > event.detail.oldRatio && !((cropper.getCroppedCanvas().width > cropper.getCropBoxData().width) && (cropper.getCroppedCanvas().height > cropper.getCropBoxData().height))){
                    event.preventDefault()
                }
            });
        }
    })


    return (
        <BlockUI loading={uploadFile.isLoading || uploadFileOriginal.isLoading}>
            <div className="">
                <div id={'cropperId'} className="position-relative">
                    <div style={{top:0, left:0, zIndex:100}} className="w-250px position-absolute">
                        <div className="d-flex justify-content-between mb-2">
                            <label className="btn btn-primary btn-upload me-1" title="Upload image file">
                                <input type="file" onChange={onChange} style={{display: "none"}} id="inputImage" name="file" accept="image/*"/>
                                <span className="kt-tooltip" data-toggle="tooltip" title="Import image">
                                                                        <span className="fa fa-upload"></span>
                                                                    </span>
                            </label>
                            <button onClick={()=> {
                                if ((cropper.getCroppedCanvas().width > cropper.getCropBoxData().width) && (cropper.getCroppedCanvas().height > cropper.getCropBoxData().height)){
                                    cropper.zoom(0.1)
                                }
                            }
                            } type="button" className="btn btn-primary me-1" data-method="zoom" data-option="0.1"
                                    title="Zoom In">
                                                                    <span data-toggle="tooltip" title="cropper.zoom(0.1)">
                                                                        <span className="fa fa-search-plus"></span>
                                                                    </span>
                            </button>
                            <button onClick={()=> {
                                cropper.zoom(-0.1)
                            }
                            } type="button" className="btn btn-primary me-1" data-method="zoom" data-option="0.1"
                                    title="Zoom Out">
                                                                    <span data-toggle="tooltip" title="cropper.zoom(0.1)">
                                                                        <span className="fa fa-search-minus"></span>
                                                                    </span>
                            </button>
                        </div>
                        <div className="btn-group d-flex">
                            <button
                                onClick={()=> {
                                    cropper.rotate(-45)
                                }}
                                type="button" className="btn btn-primary mb-3" data-method="rotate" data-option="-45"
                                title="Rotate Left">
																<span data-toggle="tooltip" title="cropper.rotate(-45)">
																	<span className="fa fa-undo-alt"></span>
																</span>
                            </button>
                            <button
                                onClick={()=> {
                                    cropper.rotate(45)
                                }}
                                type="button" className="btn btn-primary mb-3" data-method="rotate" data-option="45"
                                title="Rotate Right">
																<span data-toggle="tooltip" title="cropper.rotate(45)">
																	<span className="fa fa-redo-alt"></span>
																</span>
                            </button>
                        </div>
                        <button className="d-block btn btn-primary m-auto" onClick={getCropData}>Recadrer</button>
                    </div>
                    <Cropper
                        className="m-auto"
                        style={{ height: 1200, width: 1200, transform: "scale(0.4)", transformOrigin: "190px 0 0" }}
                        zoomTo={1}
                        aspectRatio={8/16}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        dragMode={"move"}
                        toggleDragModeOnDblclick={false}
                        cropBoxResizable={false}
                        minCropBoxHeight={1000}
                        minCropBoxWidth={500}
                        background={false}
                        responsive={false}
                        zoomOnWheel={true}
                        // scalable={true}
                        // autoCropArea={1}
                        checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={false}
                    />
                </div>
                <br style={{ clear: "both" }} />
            </div>
        </BlockUI>
    );
}

export default CropperForPublication;
