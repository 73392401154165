import { useQuery } from "react-query";
import api from "@services/api";

export default function useGetProfil() {
    return useQuery(
        ['profil'],
        async () => {
            return api.get('profil').json();
        },
    );
}