import {Button} from "react-bootstrap";
import useChangeCGU from "../../requests/utilisateur/useChangeCGU";
import {useNavigate} from "react-router";
import auth from "@services/auth";
import toast from "react-hot-toast";
import { ReactComponent as Logo } from "@assets/logo.svg";
import {useParams} from "react-router-dom";
import {useSnapshot} from "valtio";


function CGUView() {
    const {agence} = useParams();
    const navigate = useNavigate();
    const changeCGU = useChangeCGU({
        onSuccess: (res) => {
            if (res.accepteDeclarationConfidentialite === true){
                auth.utilisateur.accepteDeclarationConfidentialite = true;
                toast.success('La déclaration de confidentialité a été accepté', {
                    duration:5000
                });
                navigate("/"+(agence ? agence : "plink"))
            }else {
                auth.utilisateur.accepteDeclarationConfidentialite = false;
                toast.error('La déclaration de confidentialité a été refusé', {
                    duration:5000
                });
            }
        },
        onError: (err) => {
            toast.error(err.message, {
                duration:5000
            });
        },
    });
    const snapAuth = useSnapshot(auth);

    return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
            <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                    <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Bienvenue sur Partenaire Link</h1>
                    <p className='fw-bold fs-2 text-white'>
                        Gérez les partenaires de votre
                        <br />club sportif, et plus encore !
                    </p>
                </div>
            </div>
        </div>
        <div className="container d-flex  flex-column flex-column-fluid w-500px">
            <div id="kt_app_content" className="app-content flex-column-fluid d-flex align-items-center">
                <div id="kt_app_content_container" className="app-container container-fluid">
                    <div className="d-flex flex-column flex-xl-row">
                        <div className="card bg-body align-self-start mb-9 mb-xl-0 me-lg-9">
                            <div className="card-body">
                                <div className="">
                                    <div className="mb-10">
                                        <h1 className="fs-2x text-dark mb-7 text-center">Déclaration de confidentialité </h1>
                                        <div className="fs-5 text-gray-600 fw-bold">Lisez nos <span>Conditions générales d'utilisation</span> et <a
                                            href="https://rgdev.fr/conditions-generales-dutilisation/">Politique de Confidentialité.</a> Pour utiliser cette application, vous devez accepter les Conditions
                                            d'Utilisation et Politique de Confidentialité.
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="d-flex mt-3 justify-content-center">
                                            <Button
                                                onClick={() => {
                                                    changeCGU.mutate(true);
                                                }}
                                                className="me-2">
                                                J'accepte
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    changeCGU.mutate(false);
                                                }}
                                                variant={"danger"}
                                            >
                                                Je refuse
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default CGUView;
