import { useMutation } from "react-query";
import api from "@services/api";

export default function useSendInvitationAsTest({ id, type, ...props  }) {

    return useMutation(() => api.post(
        `invitation/${id}/envoyerEnTest`, {
            json: {
                type: type,
            },
            retry: {
                limit : 0
            }
        }).json(), props);

}