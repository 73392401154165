import "../PresentationView/style.css"
import {useEffect, useMemo, useState} from "react";
import EntracteMobile from "../../../views/SpeedBusinessView/PresentationMobileView/Steps/EntracteMobile";
import TempsDeParoleMobile from "../../../views/SpeedBusinessView/PresentationMobileView/Steps/TempsDeParoleMobile";
import CheckParticipantsMobile from "../../../views/SpeedBusinessView/PresentationMobileView/Steps/CheckParticipantsMobile";
import ChangementTableMobile from "../../../views/SpeedBusinessView/PresentationMobileView/Steps/ChangementTableMobile";
import useWebSocket from "../../../views/SpeedBusinessView/PresentationView/websocketSpeed";
import useGetTabParticipantsParTourFromRef
    from "../../../requests/communication/speedBusiness/useGetTabParticipantsParTourFromRef";
import LoadingView from "../../../views/LoadingView/LoadingView";
import useGetSpeedFromRef from "../../../requests/communication/speedBusiness/useGetSpeedFromRef";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import {useSnapshot} from "valtio";
import {dureeTimeMysqlToMinute} from "../../../views/SpeedBusinessView/ReglageStep/data";
import moment from "moment/moment";
import CountDownCircleTimer from "../../../components/CountDownCircleTimer/CountDownCircleTimer";
import VousNEtesPlusMaitreDuTemps
    from "../../../views/SpeedBusinessView/PresentationMobileView/Steps/VousNEtesPlusMaitreDuTemps";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function PresentationMobileView(){
    const [currentPerson, setCurrentPerson] = useState(1);
    const [isTimePersonne, setIsTimePersonne] = useState(true);
    const [duration, setDuration] = useState(0);
    const [isEntracte, setIsEntracte] = useState(false);
    const [tour, setTour] = useState(1);
    const [isChangementTour, setIsChangementTour] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [valueChangeRemainingTime, setValueChangeRemainingTime] = useState(false);
    const [initCurrentPerson, setInitCurrentPerson] = useState(false);
    const [numberOfStep, setNumberOfStep] = useState([]);
    const color = "var(--primary-color)"
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const screnn = useFullScreenHandle();
    const refSpeedGlobal = urlParams.get('ref') ? urlParams.get('ref').replace(/ /g, "+") : "";
    const tabParticipantsParTour = useGetTabParticipantsParTourFromRef(refSpeedGlobal);
    const [zigZagBar, setZigZagBar] = useState(null);
    const [entracteIsDone, setEntracteIsDone] = useState(false);
    const speedSnap = useSnapshot(speedBusinessProxy);
    const [dataWebsocketIsInit, setDataWebsocketIsInit] = useState(false);
    const speedBusiness = useGetSpeedFromRef(tabParticipantsParTour?.data?.refRencontreSpeedBusiness);
    const websocket = useWebSocket(tabParticipantsParTour?.data?.refRencontreSpeedBusiness);
    const [isGroupAOrB, setIsGroupAOrB] = useState("A");
    const [stateNbPersonneCurrentTable, setStateNbPersonneCurrentTable] = useState(0);
    const [showCheck, setShowCheck] = useState(false);
    const conditionDernierTour = useMemo(() => {
        if (tabParticipantsParTour?.data){
            return ((speedBusiness?.data?.nombreTours !== tour && (speedBusiness?.data?.nombreTablesChoisies + 1) == speedBusiness?.data?.nombreTours) || ((speedBusiness?.data?.nombreTablesChoisies + 1) > speedBusiness?.data?.nombreTours) || isChangementTour || typeof Object.values(tabParticipantsParTour?.data?.tabParticipantsParTour)[tour-1] !== 'undefined' )
        }else {
            return true
        }
    }, [speedBusiness?.data, tour, isTimePersonne, isChangementTour, isEntracte, speedSnap?.objectCurrentPresentationSpeed,tabParticipantsParTour])
    const conditionDernierTourWithOutAllowChangementTour = useMemo(() => {
        if (tabParticipantsParTour?.data){
            return ((speedBusiness?.data?.nombreTours !== tour && (speedBusiness?.data?.nombreTablesChoisies + 1) == speedBusiness?.data?.nombreTours) || ((speedBusiness?.data?.nombreTablesChoisies + 1) > speedBusiness?.data?.nombreTours) || typeof Object.values(tabParticipantsParTour?.data?.tabParticipantsParTour)[tour-1] !== 'undefined')
        }else {
            return true
        }
    }, [speedBusiness?.data, tour, isTimePersonne, isChangementTour, isEntracte, speedSnap?.objectCurrentPresentationSpeed, tabParticipantsParTour])
    const fullTauxRencontre = useMemo(() => {
        return (speedBusiness?.data?.nombreTablesChoisies + 1) == speedBusiness?.data?.nombreTours
    }, [speedBusiness?.data])
    useEffect(() => {
        //gestion de l'entracte a mis parcours
        if (tour == (Math.floor(speedBusiness?.data?.nombreTours/2) + 1) && !isTimePersonne && !entracteIsDone && dureeTimeMysqlToMinute(speedBusiness?.data?.dureeEntracte) > 0){
            setIsEntracte(true)
            setEntracteIsDone(true)
            setIsChangementTour(false)
        }
        //todo en test a retirer si ça plante
        if (speedBusiness?.data && speedSnap?.objectCurrentPresentationSpeed && tabParticipantsParTour?.data && conditionDernierTour){
            if (speedSnap?.objectCurrentPresentationSpeed.tabEtats.find(item => item.actif == true).id !== null){
                initDataAfterReload()
            }
        }
        setShowCheck(false)
        // -- a supprimer a la fin des tests
        // initialise les personnes a chaque début de tour
        // setCurrentPerson(1)
        // setCurrentPersonGroupeB(1)
    }, [tour]);

    //en test
    const [lastSavedDate, setLastSavedDate] = useState(moment().unix());
    useEffect(() => {
        const interval = setInterval(() => {
            if ((moment().unix() - lastSavedDate) > 5){
                window.location.reload();
            }
            setLastSavedDate(moment().unix())
        }, 1000);
        return () => clearInterval(interval);

    }, [lastSavedDate]);
    //fin en test

    //-- a supprimer a la fin des tests
    // useEffect(() => {
    //     if (initCurrentPerson){
    //         setCurrentPerson(1)
    //         setInitCurrentPerson(false)
    //         if (isTimePersonne){
    //             setValueChangeRemainingTime(duration)
    //         }
    //     }
    // }, [initCurrentPerson, isTimePersonne, isEntracte]);

    useEffect(() => {
        // if (speedBusiness?.data && numberOfStep.length == 0 && participantsPresentEtEnRetard?.data){
        if (speedSnap?.objectCurrentPresentationSpeed && tabParticipantsParTour?.data && speedBusiness?.data && dataWebsocketIsInit) {
            let nbPersonneParTableGroupeA = Math.ceil(tabParticipantsParTour?.data?.nbParticipants / speedBusiness?.data?.nombreTablesChoisies);
            let nbPersonneParTableGroupeB = Math.floor(tabParticipantsParTour?.data?.nbParticipants / speedBusiness?.data?.nombreTablesChoisies);
            if (fullTauxRencontre && speedBusiness?.data?.nombreTours === tour){
                nbPersonneParTableGroupeA = speedBusiness?.data?.nombreTablesChoisies;
                nbPersonneParTableGroupeB = tabParticipantsParTour?.data?.nbParticipants - (Math.floor(tabParticipantsParTour?.data?.nbParticipants/speedBusiness?.data?.nombreTablesChoisies)*speedBusiness?.data?.nombreTablesChoisies);
            }
            let nbPersonInCurrentTable = (typeof Object.values(tabParticipantsParTour?.data?.tabParticipantsParTour)[tour-1] === 'undefined') ? 1 : Object.values(tabParticipantsParTour?.data?.tabParticipantsParTour)[tour-1].length;
            setStateNbPersonneCurrentTable(nbPersonInCurrentTable);
            let currentGroup = nbPersonneParTableGroupeA == nbPersonInCurrentTable ? "A" : "B";
            setIsGroupAOrB(currentGroup);
            let tmp = [];
            for (let i = 0; i <= (currentGroup == "A" ? nbPersonneParTableGroupeA : nbPersonneParTableGroupeB); i++) {
                tmp.push(i + 1)
            }

            setNumberOfStep(tmp)
            let durationGroupB = ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60)*Math.ceil(tabParticipantsParTour?.data?.nbParticipants/ speedBusiness?.data?.nombreTablesChoisies))/Math.floor(tabParticipantsParTour?.data?.nbParticipants / speedBusiness?.data?.nombreTablesChoisies)
            setDuration(currentGroup == "A" ? dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60 : durationGroupB)
            //Initialisation des données de la zigZagBar
            let largeurZigZag = 250;
            let hauteurZigZagBar = 40;
            let a = largeurZigZag / (currentGroup == "A" ? nbPersonneParTableGroupeA : nbPersonneParTableGroupeB)
            let c = Math.sqrt((a * a) + (hauteurZigZagBar * hauteurZigZagBar))
            let alpha = Math.atan(hauteurZigZagBar / a) * (180 / Math.PI)
            setZigZagBar({
                rotate: alpha,
                width: c,
                left: a
            })
        }
    }, [tabParticipantsParTour?.data, speedBusiness?.data, dataWebsocketIsInit, tour])



    useEffect(() => {
        if (speedSnap?.objectCurrentPresentationSpeed && tabParticipantsParTour?.data && !dataWebsocketIsInit && conditionDernierTour){
            if (speedBusiness?.data && speedSnap?.objectCurrentPresentationSpeed.tabEtats.find(item => item.actif == true).id !== null){
                initDataAfterReload();
                setDataWebsocketIsInit(true)
            }else {
                setDataWebsocketIsInit(true)
                //todo init par défaut...
            }
        }
        if (speedBusiness?.data && speedSnap?.objectCurrentPresentationSpeed && conditionDernierTour){
            switch (speedSnap?.objectCurrentPresentationSpeed?.tabEtats?.find(item => item.actif == true).id) {
                case null:
                    setIsPlaying(false);
                    break;
                case 0:
                    setIsPlaying(true);
                    initDataAfterReload()
                    break;
                case 1:
                    setIsPlaying(false);
                    initDataAfterReload()
                    break;
                case 2:
                    setIsPlaying(false);
                    break;
            }
        }
    }, [speedSnap?.objectCurrentPresentationSpeed,speedSnap?.objectCurrentPresentationSpeed?.tabEtats, speedBusiness?.data, tabParticipantsParTour?.data])
    if (tabParticipantsParTour.isLoading || speedBusiness.isLoading || !websocket){
        return <LoadingView />
    }
    return <FullScreen className="bg-white h-100" handle={screnn}><div style={{
        width:"100vw",
        overflow: "hidden"
    }} className="h-100">
        <span onClick={() => {
            try {
                if (screnn.active) {
                    screnn.exit();
                } else {
                    screnn.enter();
                }
            } catch (e) {
                showErrorAlert('Plein écran non pris en charge')
            }

        }} className="fa fa-expand btn btn-secondary" style={{
            marginLeft: 40,
            fontSize: 25,
            color: "white",
            fontWeight: "bold",
            position: "absolute",
            top: 70,
            right: 5,
            zIndex: 100
        }}></span>
            {dataWebsocketIsInit && <>
                {(conditionDernierTour && !(tour > speedBusiness?.data.nombreTours) && typeof tour !== "undefined" && speedSnap?.objectCurrentPresentationSpeed.tabEtats.find(item => item.actif == true).id !== 2) &&
                    <>
                        {(isTimePersonne && speedSnap?.objectCurrentPresentationSpeed.tabEtats.find(item => item.actif == true).id !== null) &&
                            <TempsDeParoleMobile
                                nomCurrentTable={tabParticipantsParTour?.data?.nomTable}
                                heureFinEstime={getHeureDeFin()}
                                dataCurrentTableParticipant={Object.values(tabParticipantsParTour?.data?.tabParticipantsParTour)[tour - 1]}
                                color={isGroupAOrB == "A" ? "--primary-color" : "--secondary-color"}
                                currentTime={currentTime}
                                setCurrentPerson={setCurrentPerson}
                                currentPerson={currentPerson}
                                isTimePersonne={isTimePersonne}
                                setIsTimePersonne={setIsTimePersonne}
                                duration={duration}
                                setIsEntracte={setIsEntracte}
                                isEntracte={isEntracte}
                                tour={tour}
                                setTour={setTour}
                                setIsChangementTour={setIsChangementTour}
                                isChangementTour={isChangementTour}
                                setCurrentTime={setCurrentTime}
                                isPlaying={isPlaying}
                                valueChangeRemainingTime={valueChangeRemainingTime}
                                setValueChangeRemainingTime={setValueChangeRemainingTime}
                                setInitCurrentPerson={setInitCurrentPerson}
                                numberOfStep={numberOfStep}
                                zigZagBar={zigZagBar}
                                numIsPair={numIsPair}
                                personneParTable={stateNbPersonneCurrentTable}
                                speedBusiness={speedBusiness}
                                nbTours={speedBusiness?.data?.nombreTours}
                            />
                        }
                        {(isTimePersonne && speedSnap?.objectCurrentPresentationSpeed.tabEtats.find(item => item.actif == true).id === null)
                            &&
                            <CheckParticipantsMobile
                                color={color}
                                nomCurrentTable={tabParticipantsParTour?.data?.nomTable}
                                currentTime={currentTime}
                                dataCurrentTableParticipantArrivantATable={Object.values(tabParticipantsParTour?.data?.tabParticipantsParTour)[tour - 1]}
                                tabTables={speedBusiness?.data?.tabNomsTables}
                                tour={tour}
                                nbTours={speedBusiness?.data?.nombreTours}
                                numTable={tabParticipantsParTour?.data?.indexTable}
                            />
                        }
                        {isChangementTour &&
                            <>
                                {(showCheck && conditionDernierTourWithOutAllowChangementTour) ?
                                    <CheckParticipantsMobile
                                        color={color}
                                        nomCurrentTable={tabParticipantsParTour?.data?.nomTable}
                                        currentTime={currentTime}
                                        dataCurrentTableParticipantArrivantATable={Object.values(tabParticipantsParTour?.data?.tabParticipantsParTour)[tour - 1]}
                                        tabTables={speedBusiness?.data?.tabNomsTables}
                                        tour={tour}
                                        nbTours={speedBusiness?.data?.nombreTours}
                                        numTable={tabParticipantsParTour?.data?.indexTable}
                                    />
                                    :
                                    <>
                                        {(showCheck && !conditionDernierTourWithOutAllowChangementTour) ?
                                            <VousNEtesPlusMaitreDuTemps/>
                                            : <ChangementTableMobile
                                                nomCurrentTable={tabParticipantsParTour?.data?.nomTable}
                                                setShowCheck={setShowCheck}
                                                currentTime={currentTime}
                                                tour={tour}
                                                nbTours={speedBusiness?.data?.nombreTours}
                                                color={color}
                                                dataCurrentTableParticipantPartantDeTable={Object.values(tabParticipantsParTour?.data?.tabParticipantsParTour)[tour - 2]}
                                                tabTables={speedBusiness?.data?.tabNomsTables}
                                            />
                                        }
                                    </>
                                }
                                {/*Is hidden   */}
                                <CountDownCircleTimer
                                    setInitCurrentPerson={setInitCurrentPerson}
                                    valueChangeRemainingTime={valueChangeRemainingTime}
                                    setValueChangeRemainingTime={setValueChangeRemainingTime}
                                    tour={tour}
                                    speedBusiness={speedBusiness}
                                    isTimePersonne={isTimePersonne}
                                    isPlaying={isPlaying}
                                    currentPerson={currentPerson}
                                    setCurrentPerson={setCurrentPerson}
                                    setTour={setTour}
                                    setIsEntracte={setIsEntracte}
                                    isEntracte={isEntracte}
                                    setIsChangementTour={setIsChangementTour}
                                    isChangementTour={isChangementTour}
                                    setCurrentTime={setCurrentTime}
                                    setIsTimePersonne={setIsTimePersonne}
                                    currentTime={currentTime}
                                    numberOfStep={numberOfStep}
                                    zigZagBar={zigZagBar}
                                    numIsPair={numIsPair}
                                    personneParTable={stateNbPersonneCurrentTable}
                                    duration={dureeTimeMysqlToMinute(speedBusiness?.data?.dureeChangementPlace) * 60}
                                />
                            </>
                        }
                        {isEntracte &&
                            <EntracteMobile
                                currentTime={currentTime}
                                setInitCurrentPerson={setInitCurrentPerson}
                                isPlaying={isPlaying}
                                valueChangeRemainingTime={valueChangeRemainingTime}
                                setValueChangeRemainingTime={setValueChangeRemainingTime}
                                currentPerson={currentPerson}
                                setCurrentPerson={setCurrentPerson}
                                tour={tour}
                                setTour={setTour}
                                setIsEntracte={setIsEntracte}
                                setIsChangementTour={setIsChangementTour}
                                isChangementTour={isChangementTour}
                                isTimePersonne={isTimePersonne}
                                isEntracte={isEntracte}
                                setIsTimePersonne={setIsTimePersonne}
                                setCurrentTime={setCurrentTime}
                                numberOfStep={numberOfStep}
                                zigZagBar={zigZagBar}
                                numIsPair={numIsPair}
                                speedBusiness={speedBusiness}
                                personneParTable={stateNbPersonneCurrentTable}
                            />
                        }
                    </>
                }
                {/*Si speed non terminé mais speed en cours*/}
                {(speedSnap?.objectCurrentPresentationSpeed.tabEtats.find(item => item.actif == true).id !== 2 && !conditionDernierTour) &&
                    <VousNEtesPlusMaitreDuTemps/>
                }
                {/*Si speed terminé*/}
                {speedSnap?.objectCurrentPresentationSpeed.tabEtats.find(item => item.actif == true).id === 2 &&
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%",
                            backgroundColor: "var(--primary-color)",
                        }}>
                    <span style={{
                        color: "white",
                        fontSize: 56,
                        fontWeight: "bold",
                        textAlign: "center"
                    }}>Les rencontres sont terminées</span>
                        </div>
                    </div>
                }
            </>
            }
        </div>
    </FullScreen>;


    function numIsPair(n) {
        return (n & 1) ? false : true;
    }

    function initDataAfterReload() {
        let diffTimestampServeur = speedSnap?.diffServeurTimestamp;

        let nbPersonneParTableGroupeA = Math.ceil(tabParticipantsParTour?.data?.nbParticipants / speedBusiness?.data?.nombreTablesChoisies);
        let nbPersonneParTableGroupeAFullLastTour = 0
        let nbPersonneParTableGroupeB = Math.floor(tabParticipantsParTour?.data?.nbParticipants / speedBusiness?.data?.nombreTablesChoisies);
        let nbPersonneParTableGroupeBFullLastTour = 0
        let currentIsFullLastTour = (speedBusiness?.data?.nombreTablesChoisies +1) == speedBusiness?.data?.nombreTours
        if (currentIsFullLastTour){
            nbPersonneParTableGroupeAFullLastTour = speedBusiness?.data?.nombreTablesChoisies;
            nbPersonneParTableGroupeBFullLastTour = tabParticipantsParTour?.data?.nbParticipants - (Math.floor(tabParticipantsParTour?.data?.nbParticipants/speedBusiness?.data?.nombreTablesChoisies)*speedBusiness?.data?.nombreTablesChoisies);
        }
        //si l'état du speed était en pause et non terminé
        if (speedSnap?.objectCurrentPresentationSpeed.tabEtats.find(item => item.actif == true).id == 1 && speedSnap?.objectCurrentPresentationSpeed.tabEtats.find(item => item.actif == true).id !== 2) {
            setIsPlaying(false);
            let conditionPauseDernierTour = (typeof Object.values(tabParticipantsParTour?.data?.tabParticipantsParTour)[speedSnap?.objectCurrentPresentationSpeed?.numTour-1] === 'undefined')
            let nbPersonInCurrentTable = conditionPauseDernierTour ? 0  : Object.values(tabParticipantsParTour?.data?.tabParticipantsParTour)[speedSnap?.objectCurrentPresentationSpeed?.numTour - 1].length;
            setCurrentPerson(nbPersonneParTableGroupeA == nbPersonInCurrentTable ? speedSnap?.objectCurrentPresentationSpeed.numPersonneGroupAEnCours : speedSnap?.objectCurrentPresentationSpeed.numPersonneGroupBEnCours)
            setTour(speedSnap?.objectCurrentPresentationSpeed?.numTour)
            setCurrentTime(((nbPersonneParTableGroupeA == nbPersonInCurrentTable) || conditionPauseDernierTour || isChangementTour) ? speedSnap?.objectCurrentPresentationSpeed?.tempsEtapeGroupeA : speedSnap?.objectCurrentPresentationSpeed?.tempsEtapeGroupeB)
            setValueChangeRemainingTime((nbPersonneParTableGroupeA == nbPersonInCurrentTable || conditionPauseDernierTour || isChangementTour) ? speedSnap?.objectCurrentPresentationSpeed?.tempsEtapeGroupeA : speedSnap?.objectCurrentPresentationSpeed?.tempsEtapeGroupeB)
            setIsGroupAOrB((nbPersonneParTableGroupeA == nbPersonInCurrentTable) ? "A" : "B")
            switch (speedSnap?.objectCurrentPresentationSpeed?.tabEtapes.find(item => item.actif == true).id) {
                case null :
                    break;
                case 0 :
                    setIsTimePersonne(true)
                    setIsEntracte(false)
                    setIsChangementTour(false)
                    break;
                case 1 :
                    setIsTimePersonne(false)
                    setIsEntracte(true)
                    setIsChangementTour(false)
                    break;
                case 2 :
                    if (speedSnap?.objectCurrentPresentationSpeed?.numTour == (Math.floor(speedBusiness?.data?.nombreTours/2) + 1)){
                        setEntracteIsDone(true)
                    }
                    setIsTimePersonne(false)
                    setIsEntracte(false)
                    setIsChangementTour(true)
                    break;
            }
        } else if (speedSnap?.objectCurrentPresentationSpeed.tabEtats.find(item => item.actif == true).id !== null && speedSnap?.objectCurrentPresentationSpeed.tabEtats.find(item => item.actif == true).id !== 2){
            let durationGroupB = ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60)*Math.ceil(tabParticipantsParTour?.data?.nbParticipants/ speedBusiness?.data?.nombreTablesChoisies))/Math.floor(tabParticipantsParTour?.data?.nbParticipants / speedBusiness?.data?.nombreTablesChoisies)
            let objectDerouleSpeed = []
            for (let i = 0; i < speedBusiness?.data?.nombreTours; i++) {
                if (i >= speedBusiness?.data?.nombreTours - 1) {
                    //dernier tour de parole
                    if (currentIsFullLastTour){
                        objectDerouleSpeed.push({
                            name: "parole",
                            duration: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * nbPersonneParTableGroupeAFullLastTour,
                            durationParPersonne: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60),
                            durationGoupB: durationGroupB * nbPersonneParTableGroupeBFullLastTour,
                            durationGoupBParPersonne: durationGroupB,
                            nbPersonneParTableGroupeA:nbPersonneParTableGroupeAFullLastTour,
                            nbPersonneParTableGroupeB:nbPersonneParTableGroupeBFullLastTour,
                            tour: i + 1,
                        })
                    }else {
                        objectDerouleSpeed.push({
                            name: "parole",
                            duration: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * nbPersonneParTableGroupeA,
                            durationParPersonne: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60),
                            durationGoupB: durationGroupB * nbPersonneParTableGroupeB,
                            durationGoupBParPersonne: durationGroupB,
                            nbPersonneParTableGroupeA,
                            nbPersonneParTableGroupeB,
                            tour: i + 1,
                        })
                    }
                } else {
                    objectDerouleSpeed.push({
                        name: "parole",
                        duration: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * nbPersonneParTableGroupeA,
                        durationParPersonne: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60),
                        durationGoupB: durationGroupB * nbPersonneParTableGroupeB,
                        durationGoupBParPersonne: durationGroupB,
                        nbPersonneParTableGroupeA,
                        nbPersonneParTableGroupeB,
                        tour: i + 1,
                    })
                    //si on est pas au tour de l'entracte, alors il n'y a pas de changement de table -> attention a modi
                    if (!((i + 1) == (Math.floor(speedBusiness?.data?.nombreTours / 2)) && dureeTimeMysqlToMinute(speedBusiness?.data?.dureeEntracte) > 0)) {
                        objectDerouleSpeed.push({
                            name: "table",
                            duration: dureeTimeMysqlToMinute(speedBusiness?.data?.dureeChangementPlace) * 60,
                            nbPersonneParTableGroupeA,
                            nbPersonneParTableGroupeB,
                            tour: i + 2,
                        })
                    }
                }
                if ((i + 1) == (Math.floor(speedBusiness?.data?.nombreTours / 2)) && dureeTimeMysqlToMinute(speedBusiness?.data?.dureeEntracte) > 0) {
                    objectDerouleSpeed.push({
                        name: "entracte",
                        duration: dureeTimeMysqlToMinute(speedBusiness?.data?.dureeEntracte) * 60,
                        tour: i + 2,
                    })
                    objectDerouleSpeed.push({
                        name: "table",
                        duration: dureeTimeMysqlToMinute(speedBusiness?.data?.dureeChangementPlace) * 60,
                        nbPersonneParTableGroupeA,
                        nbPersonneParTableGroupeB,
                        tour: i + 2,
                    })
                }
            }
            let tempsTotalSpeedPlay = moment().unix() + diffTimestampServeur - speedSnap?.objectCurrentPresentationSpeed?.heureDepart - speedSnap?.objectCurrentPresentationSpeed.dureePause;
            let incrementTime = 0;
            let incrementTimeAfter = 0;
            let currentStepAfterFor = null;
            for (let item of objectDerouleSpeed) {
                incrementTime = incrementTime + item.duration;
                if (incrementTime > tempsTotalSpeedPlay) {
                    currentStepAfterFor = item;
                    break;
                }
                incrementTimeAfter = incrementTimeAfter + item.duration;
            }
            let tempsTotalSpeedPlayInEtape = (tempsTotalSpeedPlay - incrementTimeAfter);
            setIsPlaying(true);
            setTour(currentStepAfterFor?.tour)
            let nbPersonInCurrentTable = (typeof Object.values(tabParticipantsParTour?.data?.tabParticipantsParTour)[currentStepAfterFor?.tour - 1] !== 'undefined') ? Object.values(tabParticipantsParTour?.data?.tabParticipantsParTour)[currentStepAfterFor?.tour - 1].length : 0;
            setIsGroupAOrB(nbPersonneParTableGroupeA == nbPersonInCurrentTable ? "A" : "B")
            let whatGroupsIts = nbPersonneParTableGroupeA == nbPersonInCurrentTable ? "A" : "B";
            switch (currentStepAfterFor?.name) {
                case "parole" :
                    if (whatGroupsIts == "A"){
                        let calcCurrentPersonneA = Math.ceil(tempsTotalSpeedPlayInEtape / currentStepAfterFor?.durationParPersonne) === 0 ? 1 : Math.ceil(tempsTotalSpeedPlayInEtape / currentStepAfterFor?.durationParPersonne);
                        setCurrentPerson(calcCurrentPersonneA)
                        setCurrentTime((calcCurrentPersonneA * currentStepAfterFor?.durationParPersonne) - tempsTotalSpeedPlayInEtape)
                        setValueChangeRemainingTime((calcCurrentPersonneA * currentStepAfterFor?.durationParPersonne) - tempsTotalSpeedPlayInEtape)
                    }else {
                        let calcCurrentPersonneB = Math.ceil(tempsTotalSpeedPlayInEtape / currentStepAfterFor?.durationGoupBParPersonne) === 0 ? 1 : Math.ceil(tempsTotalSpeedPlayInEtape / currentStepAfterFor?.durationGoupBParPersonne);
                        setCurrentPerson(calcCurrentPersonneB)
                        setCurrentTime((calcCurrentPersonneB * currentStepAfterFor?.durationGoupBParPersonne) - tempsTotalSpeedPlayInEtape)
                        setValueChangeRemainingTime((calcCurrentPersonneB * currentStepAfterFor?.durationGoupBParPersonne) - tempsTotalSpeedPlayInEtape)

                    }
                    setIsTimePersonne(true)
                    setIsEntracte(false)
                    setIsChangementTour(false)
                   break;
                case "entracte" :
                    setIsTimePersonne(false)
                    setIsEntracte(true)
                    setIsChangementTour(false)
                    setCurrentTime(currentStepAfterFor?.duration - tempsTotalSpeedPlayInEtape)
                    setValueChangeRemainingTime(currentStepAfterFor?.duration - tempsTotalSpeedPlayInEtape)
                    break;
                case "table" :
                    if (currentStepAfterFor?.tour == (Math.floor(speedBusiness?.data?.nombreTours/2) + 1)){
                        setEntracteIsDone(true)
                    }
                    setIsTimePersonne(false)
                    setIsEntracte(false)
                    setIsChangementTour(true)
                    setCurrentTime(currentStepAfterFor?.duration - tempsTotalSpeedPlayInEtape)
                    setValueChangeRemainingTime(currentStepAfterFor?.duration - tempsTotalSpeedPlayInEtape)
                    break;
            }
        }
    }

    function getHeureDeFin(){
        let diffTimestampServeur = speedSnap?.diffServeurTimestamp;
        let objectDerouleSpeed = []
        let durationGroupB = ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60)*Math.ceil(tabParticipantsParTour?.data?.nbParticipants/ speedBusiness?.data?.nombreTablesChoisies))/Math.floor(tabParticipantsParTour?.data?.nbParticipants / speedBusiness?.data?.nombreTablesChoisies)
        let nbPersonneParTableGroupeA = Math.ceil(tabParticipantsParTour?.data?.nbParticipants / speedBusiness?.data?.nombreTablesChoisies);
        let nbPersonneParTableGroupeAFullLastTour = 0
        let nbPersonneParTableGroupeB = Math.floor(tabParticipantsParTour?.data?.nbParticipants / speedBusiness?.data?.nombreTablesChoisies);
        let nbPersonneParTableGroupeBFullLastTour = 0
        let currentIsFullLastTour = (speedBusiness?.data?.nombreTablesChoisies +1) == speedBusiness?.data?.nombreTours
        if (currentIsFullLastTour){
            nbPersonneParTableGroupeAFullLastTour = speedBusiness?.data?.nombreTablesChoisies;
            nbPersonneParTableGroupeBFullLastTour = tabParticipantsParTour?.data?.nbParticipants - (Math.floor(tabParticipantsParTour?.data?.nbParticipants/speedBusiness?.data?.nombreTablesChoisies)*speedBusiness?.data?.nombreTablesChoisies);
        }
        for (let i = 0; i < speedBusiness?.data?.nombreTours; i++) {
            if (i >= speedBusiness?.data?.nombreTours - 1) {
                //dernier tour de parole
                if (currentIsFullLastTour){
                    objectDerouleSpeed.push({
                        name: "parole",
                        duration: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * nbPersonneParTableGroupeAFullLastTour,
                        durationParPersonne: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60),
                        durationGoupB: durationGroupB * nbPersonneParTableGroupeBFullLastTour,
                        durationGoupBParPersonne: durationGroupB,
                        nbPersonneParTableGroupeA:nbPersonneParTableGroupeAFullLastTour,
                        nbPersonneParTableGroupeB:nbPersonneParTableGroupeBFullLastTour,
                        tour: i + 1,
                    })
                }else {
                    objectDerouleSpeed.push({
                        name: "parole",
                        duration: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * nbPersonneParTableGroupeA,
                        durationParPersonne: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60),
                        durationGoupB: durationGroupB * nbPersonneParTableGroupeB,
                        durationGoupBParPersonne: durationGroupB,
                        nbPersonneParTableGroupeA,
                        nbPersonneParTableGroupeB,
                        tour: i + 1,
                    })
                }
            } else {
                objectDerouleSpeed.push({
                    name: "parole",
                    duration: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * nbPersonneParTableGroupeA,
                    durationParPersonne: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60),
                    durationGoupB: ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * Math.ceil(tabParticipantsParTour?.data?.nbParticipants / speedBusiness?.data?.nombreTablesChoisies)) / Math.floor(tabParticipantsParTour?.data?.nbParticipants / speedBusiness?.data?.nombreTablesChoisies) * nbPersonneParTableGroupeB,
                    durationGoupBParPersonne: ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * Math.ceil(tabParticipantsParTour?.data?.nbParticipants / speedBusiness?.data?.nombreTablesChoisies)) / Math.floor(tabParticipantsParTour?.data?.nbParticipants / speedBusiness?.data?.nombreTablesChoisies),
                    nbPersonneParTableGroupeA,
                    nbPersonneParTableGroupeB,
                    tour: i + 1,
                })
                //si on est pas au tour de l'entracte, alors il n'y a pas de changement de table -> attention a modi
                if (!((i + 1) == (Math.floor(speedBusiness?.data?.nombreTours / 2)) && dureeTimeMysqlToMinute(speedBusiness?.data?.dureeEntracte) > 0)) {
                    objectDerouleSpeed.push({
                        name: "table",
                        duration: dureeTimeMysqlToMinute(speedBusiness?.data?.dureeChangementPlace) * 60,
                        nbPersonneParTableGroupeA,
                        nbPersonneParTableGroupeB,
                        tour: i + 2,
                    })
                }
            }
            if ((i + 1) == (Math.floor(speedBusiness?.data?.nombreTours / 2)) && dureeTimeMysqlToMinute(speedBusiness?.data?.dureeEntracte) > 0) {
                objectDerouleSpeed.push({
                    name: "entracte",
                    duration: dureeTimeMysqlToMinute(speedBusiness?.data?.dureeEntracte) * 60,
                    tour: i + 2,
                })
                objectDerouleSpeed.push({
                    name: "table",
                    duration: dureeTimeMysqlToMinute(speedBusiness?.data?.dureeChangementPlace) * 60,
                    nbPersonneParTableGroupeA,
                    nbPersonneParTableGroupeB,
                    tour: i + 2,
                })
            }
        }
        let tempsTotalSpeedPlay = 0;
        if (speedSnap?.objectCurrentPresentationSpeed?.heureDepart){
            tempsTotalSpeedPlay = moment().unix() + diffTimestampServeur - speedSnap?.objectCurrentPresentationSpeed?.heureDepart - speedSnap?.objectCurrentPresentationSpeed.dureePause;
        }

        let tempsTotalSpeed = 0;
        for (let item of objectDerouleSpeed) {
            tempsTotalSpeed = tempsTotalSpeed + item.duration;
        }
        if (!isPlaying && speedSnap?.objectCurrentPresentationSpeed?.heureDepart){
            tempsTotalSpeedPlay = tempsTotalSpeedPlay - (moment().unix() + diffTimestampServeur - speedSnap.objectCurrentPresentationSpeed.heureDebDernierePause)
        }
        let tempsRestant = tempsTotalSpeed - tempsTotalSpeedPlay;
        let heureDeFin = tempsRestant;
        return moment().add(heureDeFin, "seconds").format("HH:mm");
    }
}