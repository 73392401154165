import LoadingView from "../../../../views/LoadingView/LoadingView";
import useGetQueryGenererParParticipant
    from "../../../../requests/communication/speedBusiness/useGetQueryGenererParParticipant";
import TablePremierPlacement from "./TablePremierPlacement";

export default function PremierPlacement({speed, nbTablesTotales, arrayDataParticipantMaitre, arrayDataParticipantMaitreSuppleant, arrayDataParticipantSansMaitre, tour}){

    const placementParPersonne = useGetQueryGenererParParticipant(speed?.id);
    const mapTables = () => {
        if (placementParPersonne?.data){
            let tmpMap = [];
            let objectData = objectParPremiereLettrePrenom(placementParPersonne?.data, nbTablesTotales, arrayDataParticipantSansMaitre, arrayDataParticipantMaitre, arrayDataParticipantMaitreSuppleant, speed, tour);
            let orderedObjectData = Object.keys(objectData).sort().reduce(
                (obj, key) => {
                    obj[key] = objectData[key];
                    return obj;
                },
                {}
            );
            for (let i = 0; i < Object.keys(orderedObjectData).length; i++) {
                tmpMap.push(<span className={`${Object.keys(orderedObjectData).length > 48 ? "pe-1" : "pe-1"} pb-2`}><TablePremierPlacement nomTables={speed?.tabNomsTables} nomTable={Object.keys(orderedObjectData)[i]} objectData={Object.values(orderedObjectData)[i]} nbTablesTotales={Object.keys(orderedObjectData).length}/> </span>)
            }
            return tmpMap;
        }else {
            return [];
        }
    }

    if (placementParPersonne?.isLoading){
        return <LoadingView/>
    }

    return <div className="d-flex flex-wrap justify-content-around w-100 " style={{
        marginLeft: 10
    }}>
        {mapTables()}
    </div>

    function objectParPremiereLettrePrenom(object, nbTablesTotales,arrayDataParticipantSansMaitre, arrayDataParticipantMaitre, arrayDataParticipantMaitreSuppleant, speed, tour){
        let tmpObject = {};
        for (const [key, value] of Object.entries(object)) {
            if (key >= 0 && typeof arrayDataParticipantSansMaitre[key] !== "undefined"){
                if (typeof tmpObject[arrayDataParticipantSansMaitre[key]?.participant?.personne?.nom[0]?.toUpperCase()] === "undefined"){
                    tmpObject[arrayDataParticipantSansMaitre[key].participant.personne.nom[0].toUpperCase()] = [{personne : arrayDataParticipantSansMaitre[key].participant.personne,
                                                                                                                    indexTable : value[tour-1],
                                                                                                                    idParticipant: arrayDataParticipantSansMaitre[key].id}];
                }else {
                    tmpObject[arrayDataParticipantSansMaitre[key].participant.personne.nom[0].toUpperCase()] = [...tmpObject[arrayDataParticipantSansMaitre[key].participant.personne.nom[0].toUpperCase()], {personne : arrayDataParticipantSansMaitre[key].participant.personne,
                                                                                                                                                                                                                    indexTable: value[tour-1],
                                                                                                                                                                                                                    idParticipant:  arrayDataParticipantSansMaitre[key].id},]
                }
            }
            else if (key >= (-nbTablesTotales) && speed?.nombrePersonnesParTableChoisies !== 2){
                if (typeof tmpObject[arrayDataParticipantMaitre[parseInt(key)+nbTablesTotales]?.participant?.personne?.nom[0]?.toUpperCase()] === "undefined"){
                    tmpObject[arrayDataParticipantMaitre[parseInt(key)+nbTablesTotales].participant.personne.nom[0].toUpperCase()] = [{personne : arrayDataParticipantMaitre[parseInt(key) + nbTablesTotales].participant.personne,
                                                                                                                                        indexTable: value[tour-1],
                                                                                                                                        idParticipant : arrayDataParticipantMaitre[parseInt(key) + nbTablesTotales].id}]
                }else {
                    tmpObject[arrayDataParticipantMaitre[parseInt(key)+nbTablesTotales].participant.personne.nom[0].toUpperCase()] = [...tmpObject[arrayDataParticipantMaitre[parseInt(key)+nbTablesTotales].participant.personne.nom[0].toUpperCase()], {personne : arrayDataParticipantMaitre[parseInt(key) + nbTablesTotales].participant.personne,
                                                                                                                                        indexTable: value[tour-1],
                                                                                                                                        idParticipant: arrayDataParticipantMaitre[parseInt(key) + nbTablesTotales].id}]
                }
            }else if (speed?.nombrePersonnesParTableChoisies !== 2){
                if (typeof tmpObject[arrayDataParticipantMaitreSuppleant[Math.abs(parseInt(key)+speed?.nombreTours)]?.participant?.personne?.nom[0]?.toUpperCase()] === "undefined"){
                    tmpObject[arrayDataParticipantMaitreSuppleant[Math.abs(parseInt(key)+speed?.nombreTours)].participant.personne.nom[0].toUpperCase()] = [{personne : arrayDataParticipantMaitreSuppleant[Math.abs(parseInt(key) + speed?.nombreTours)].participant.personne,
                                                                                                                                                                                        indexTable: value[tour-1],
                                                                                                                                                                    idParticipant: arrayDataParticipantMaitreSuppleant[Math.abs(parseInt(key) + speed?.nombreTours)].id}]
                }else {
                    tmpObject[arrayDataParticipantMaitreSuppleant[Math.abs(parseInt(key)+speed?.nombreTours)].participant.personne.nom[0].toUpperCase()] = [...tmpObject[arrayDataParticipantMaitreSuppleant[Math.abs(parseInt(key)+speed?.nombreTours)].participant.personne.nom[0].toUpperCase()], {personne : arrayDataParticipantMaitreSuppleant[Math.abs(parseInt(key) + speed?.nombreTours)].participant.personne,
                                                                                                                                                                                                                                                                                                                                                          indexTable:value[tour-1], idParticipant:arrayDataParticipantMaitreSuppleant[Math.abs(parseInt(key) + speed?.nombreTours)].id}]
                }
            }
        }
        return tmpObject
    }

    return null;

}