import {useSnapshot} from "valtio";
import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {useQueryClient} from "react-query";
import auth from "../../../services/auth";
import SearchBar from "../../../components/SearchBar/SearchBar";
import MetaTable from "../../../components/MetaTable/MetaTable";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import invitationsProxy from "../../../proxies/invitations";
import InfoPersonneForSuiviModal from "../../../views/communication/InvitationsView/SuiviModal/InfoPersonneForSuiviModal";
import toast from "react-hot-toast";
import {Alert, Button, Modal} from "react-bootstrap";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import useChangeIsMaitreDuTempsSuppleant
    from "../../../requests/communication/speedBusiness/useChangeIsMaitreDuTempsSuppleant";
import useGetQueryParticipants from "../../../requests/communication/speedBusiness/useGetQueryParticipants";
import LoadingView from "../../../views/LoadingView/LoadingView";

export default function LastCheckSuppleantAndGoModal(){
    const speedSnap = useSnapshot(speedBusinessProxy);
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const search2 = watch('search2');
    const [debouncedSearch] = useDebounce(search, 300);
    const [debouncedSearch2] = useDebounce(search2, 300);
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const [prenom, setPrenom] = useState("");
    const [prenom2, setPrenom2] = useState("");
    const [prenomNom, setPrenomNom] = useState("");
    const [prenomNom2, setPrenomNom2] = useState("");
    const [nom, setNom] = useState("");
    const [nom2, setNom2] = useState("");
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const changeIsMaitreTempsSuppleant = useChangeIsMaitreDuTempsSuppleant({
        onSuccess: (res) => {
            queryClient.invalidateQueries('presentEtEnRetard');
            queryClient.invalidateQueries('rencontreSpeedBusiness');
            queryClient.invalidateQueries('rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'participants');
            queryClient.invalidateQueries(['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'participants'])
            queryClient.invalidateQueries(['participants', speedSnap.speedBusiness?.id, "presentEtEnRetard"])
            queryClient.invalidateQueries(['participants', speedSnap.speedBusiness?.id, "maitreDuTempsSuppleant"])
            snapAuth.websocket.send("rencontreSpeedBusiness")
            snapAuth.websocket.send("participant")
            setUpdate(update+1)
        },
        onError: (err) => {
            toast.error(err.message);
        },
    });
    const participantsPresentEtEnRetard = useGetQueryParticipants(
        speedSnap.speedBusiness?.id,
        {
            data: "etatPresence",
            searchable: true,
            orderable: true,
            searchOperator: "IN",
            search: {
                value: "0,2",
                regex: false,
            },
        },
        {
            data: "isMaitreDuTemps",
            searchable: true,
            orderable: true,
            search: {
                //1==false 0==true
                value: "",
                regex: false,
            },
        },
        "presentEtEnRetard",
    );
    const participantsMaitreDutempsSuppleant = useGetQueryParticipants(
        speedSnap.speedBusiness?.id,
        {
            data: "isMaitreDuTempsSuppleant",
            searchable: true,
            orderable: true,
            searchOperator: "=",
            search: {
                value: 0,
                regex: false,
            },
        },
        {
            data: "etatPresence",
            searchable: true,
            orderable: true,
            searchOperator: "IN",
            search: {
                value: "0,2",
                regex: false,
            },
        },
        "maitreDuTempsSuppleant"
    )
    const [update, setUpdate] = useState(0);
    const [totalRow, setTotalRow] = useState(0);


    useEffect(() => {
        setPrenom("")
        setPrenom2("")
        setPrenomNom("")
        setPrenomNom2("")
        setNom("")
        setNom2("")
    }, [speedSnap.showJourJModal]);
    const colSearch = useMemo(() => {
        return {
            'etatPresence': "0",
            'isMaitreDuTemps': 1,
            'isMaitreDuTempsSuppleant': 1,
            'participant.personne.prenom': prenom,
            'participant.personne.nom': nom,
            'participant.personne.prenomNom': prenomNom,
        };
    }, [prenom, nom, prenomNom]);

    const colSearch2 = useMemo(() => {
        return {
            'etatPresence': "0,2",
            'isMaitreDuTempsSuppleant': 0,
            'participant.personne.prenom': prenom2,
            'participant.personne.nom': nom2,
            'participant.personne.prenomNom': prenomNom2,
        };
    }, [prenom2, nom2, prenomNom2]);
    const tableRef = useRef();
    const tableRef2 = useRef();
    const columns = useMemo(() => {
        let tmp =  [
            {
                Header: 'Id',
                accessor: 'participant.personne.id',
                minWidth: 80,
                maxWidth: 80
            },
            {
                Header: 'etatInvitation',
                accessor: 'etatInvitation',
                hidden: true
            },
            {
                Header: 'etatPresence',
                accessor: 'etatPresence',
                hidden: true,
                searchOperator:"IN"
            },
            {
                Header: 'prenomNom',
                accessor: 'participant.personne.prenomNom',
                hidden: true
            },
            {
                Header: 'Passer en maitre du temps',
                accessor: 'isMaitreDuTemps',
                hidden: true
            },
            {
                Header: 'nomPrenom',
                accessor: 'participant.personne.nomPrenom',
                hidden: true
            },
            {
                Header: 'isMaitreDuTempsSuppleant',
                accessor: 'isMaitreDuTempsSuppleant',
                hidden: true
            },
            {
                Header: 'prenom',
                accessor: 'participant.personne.prenom',
                hidden: true,
            },
            {
                Header: 'Participant',
                accessor: 'participant.personne.nom',
                minWidth: 200,
                Cell: ({row}) => {
                    let result = [];
                    result.push(<i className="me-3 cursor-pointer fa fa-plus-circle fa-2x" onClick={() => {
                        if (((Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales/speedSnap?.speedBusiness?.nombreTablesChoisies)-1) - (participantsMaitreDutempsSuppleant?.data?.nbLignesTotales)) > 0) {
                            changeIsMaitreTempsSuppleant.mutate({
                                id: row.original.id,
                                isMaitreDuTempsSuppleant: true
                            })
                        } else {
                            BtSwal.fire("Nombre de maîtres du temps suppléant requis atteint.", "", "info")
                        }
                    }} style={{
                        color: "green"
                    }}>
                    </i>)
                    result.push(row.original.participant.personne.prenom + " " + row.original.participant.personne.nom);
                    result.push(<span> <i onClick={() => {
                        invitationsSnapshot.showInfoPersonneForSuiviInvitation(row.original.participant);
                    }} className="cursor-pointer fa fa-info-circle"></i></span>)
                    return result;
                }
            },
        ]
        return tmp;
    }, [update, totalRow, participantsPresentEtEnRetard?.data, participantsMaitreDutempsSuppleant?.data, speedSnap?.speedBusiness])
    const columns2 = useMemo(() => {

        let tmp = [
            {
                Header: 'Id',
                accessor: 'participant.personne.id',
                minWidth: 80,
                maxWidth: 80
            },
            {
                Header: 'etatInvitation',
                accessor: 'etatInvitation',
                hidden: true
            },
            {
                Header: 'etatPresence',
                accessor: 'etatPresence',
                hidden: true,
                searchOperator:"IN"
            },
            {
                Header: 'prenomNom',
                accessor: 'participant.personne.prenomNom',
                hidden: true
            },
            {
                Header: 'nomPrenom',
                accessor: 'participant.personne.nomPrenom',
                hidden: true
            },
            {
                Header: 'isMaitreDuTempsSuppleant',
                accessor: 'isMaitreDuTempsSuppleant',
                hidden: true
            },
            {
                Header: 'prenom',
                accessor: 'participant.personne.prenom',
                hidden: true,
            },
            {
                Header: 'Participant',
                accessor: 'participant.personne.nom',
                minWidth: 200,
                Cell: ({row}) => {
                    let result = [];
                    result.push(<i className="cursor-pointer me-3 fa fa-trash fa-2x" onClick={() => {
                        changeIsMaitreTempsSuppleant.mutate({
                            id: row.original.id,
                            isMaitreDuTempsSuppleant: false
                        })
                    }} style={{
                        color: "indianred"
                    }}>

                    </i>)
                    result.push(row.original.participant.personne.prenom + " " + row.original.participant.personne.nom);
                    result.push(<span> <i onClick={() => {
                        invitationsSnapshot.showInfoPersonneForSuiviInvitation(row.original.participant);
                    }} className="cursor-pointer fa fa-info-circle"></i></span>)
                    return result;
                }
            },
        ]
        return tmp;
    }, [update, totalRow, participantsPresentEtEnRetard?.data, participantsMaitreDutempsSuppleant?.data, speedSnap?.speedBusiness])

    if (participantsPresentEtEnRetard.isLoading){
        return <LoadingView/>
    }
    return <Modal
        fullscreen
        show={speedSnap.showLastCheckSuppleantModal}
        onHide={() => {
            speedBusinessProxy.showLastCheckSuppleantModal = false;
            speedBusinessProxy.showLancementSpeedBusinessModal = true;
        }}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <h2>
                Ajustement des maitres du temps suppléant
            </h2>
        </Modal.Header>
        <Modal.Body>
            <div>
                <div style={{
                    width: 800,
                    margin: "auto",
                    marginBottom: 10,
                    height: 150,
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    borderRadius: 10,
                    border: "solid 1px var(--primary-color)"
                }}>
                    <p className="w-100 fs-6 fw-bold pt-5 ps-5 pe-5">Le role du maitre du temps est de faire en sorte
                        que tous les participants puissent user de leur temps de parole lors du dernier tour de la
                        rencontre.</p>
                    <Alert
                        style={{
                            width: "fit-content",
                            height: "fit-content"
                        }}
                        className="me-3"
                        variant={"info"}>
                        {((Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedSnap?.speedBusiness?.nombreTablesChoisies) - 1) - participantsMaitreDutempsSuppleant?.data?.nbLignesTotales) >= 0
                            ?
                            <span>{(Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedSnap?.speedBusiness?.nombreTablesChoisies) - 1) - participantsMaitreDutempsSuppleant?.data?.nbLignesTotales} maitres
                            du temps suppléant supplémentaire
                                requis</span>
                            :
                            <span>{Math.abs((Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedSnap?.speedBusiness?.nombreTablesChoisies) - 1) - participantsMaitreDutempsSuppleant?.data?.nbLignesTotales)} maitres
                            du temps suppléant en trop</span>
                        }
                    </Alert>
                </div>
                <div className="d-flex justify-content-around">
                    <div>
                        <h3>Liste des présents (participants en retard exclu pour la sélection des maîtres du temps
                            suppléant)</h3>
                        <SearchBar
                            solid
                            {...register('search')}
                            onClear={search?.length > 0 ? () => {
                                setValue('search', '');
                            } : null}
                        />
                        <MetaTable
                            ref={tableRef}
                            className='mt-5 w-800px'
                            height={500}
                            url={`rencontreSpeedBusiness/${speedSnap.speedBusiness?.id}/participants`}
                            keys={['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'participants']}
                            columns={columns}
                            search={debouncedSearch}
                            colSearch={colSearch}
                            flex={true}
                            // showFiltres={true}
                        />
                    </div>
                    <div>
                        <h3>Liste des maitres du temps suppléant</h3>
                        <SearchBar
                            solid
                            {...register('search2')}
                            onClear={search?.length > 0 ? () => {
                                setValue('search2', '');
                            } : null}
                        />
                        <MetaTable
                            ref={tableRef2}
                            className='mt-5 w-800px'
                            height={500}
                            url={`rencontreSpeedBusiness/${speedSnap.speedBusiness?.id}/participants`}
                            keys={['rencontreSpeedBusiness', speedSnap.speedBusiness?.id, 'participants']}
                            columns={columns2}
                            search={debouncedSearch2}
                            colSearch={colSearch2}
                            flex={true}
                            setTotalRow={setTotalRow}
                        />
                    </div>

                </div>
                <InfoPersonneForSuiviModal/>

            </div>

        </Modal.Body>
        <Modal.Footer>
            <span
                style={{
                    height: 50,
                    width: 50,
                    padding: 0,
                    borderRadius: 15,
                    display: "flex",
                    justifyContent:"center",
                    alignItems:"center",
                    border: "solid 1px white",
                    color:"white",
                }}
                className={`${(((Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedSnap?.speedBusiness?.nombreTablesChoisies) - 1) - participantsMaitreDutempsSuppleant?.data?.nbLignesTotales) == 0) ? "bg-primary cursor-pointer" : "bg-gray-400 cursor-not-allowed" } ms-auto`}
                onClick={async () => {
                    if (((Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedSnap?.speedBusiness?.nombreTablesChoisies) - 1) - participantsMaitreDutempsSuppleant?.data?.nbLignesTotales) == 0) {
                        BtSwal.fire({
                            title: `<b>Êtes-vous sûr de vouloir lancer les rencontres ?</b> <br> <br> <small>Attention, tout ajout de participants après cette étape entraînera des erreurs de positionnement.</small>`,
                            showDenyButton: true,
                            confirmButtonText: `Oui`,
                            denyButtonText: `Annuler`,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                let base_url = window.location.origin;
                                window.open(base_url + "/" + snapAuth?.agence?.dossierUpload + "/speedBusiness/presentation?ref=" + speedSnap.speedBusiness.ref, '_blank');
                            }
                        })
                    }
                }}
            >GO</span>
        </Modal.Footer>
    </Modal>
}