export function getEtatPlay(){
    return [
        {
            id: null,
            libelle: "Non démarré",
            actif: false
        },
        {
            id: 0,
            libelle: "En cours",
            actif: true
        },
        {
            id: 1,
            libelle: "En pause",
            actif: false
        },
        {
            id: 2,
            libelle: "Terminé",
            actif: false
        }
    ]

}

export function getEtatTermine(){
    return [
        {
            id: null,
            libelle: "Non démarré",
            actif: false
        },
        {
            id: 0,
            libelle: "En cours",
            actif: false
        },
        {
            id: 1,
            libelle: "En pause",
            actif: false
        },
        {
            id: 2,
            libelle: "Terminé",
            actif: true
        }
    ]

}

export function getEtatPause(){
    return [
        {
            id: null,
            libelle: "Non démarré",
            actif: false
        },
        {
            id: 0,
            libelle: "En cours",
            actif: false
        },
        {
            id: 1,
            libelle: "En pause",
            actif: true
        },
        {
            id: 2,
            libelle: "Terminé",
            actif: false
        }
    ]
}

export function getEtapeTimePersonne(){
    return [
        {
            id: null,
            libelle: "1er placement",
            actif: false
        },
        {
            id: 0,
            libelle: "Temps de parole",
            actif: true
        },
        {
            id: 1,
            libelle: "Entracte",
            actif: false
        },
        {
            id: 2,
            libelle: "Changement de place",
            actif: false
        }
    ]
}

export function getEtapeEntracte(){
    return [
        {
            id: null,
            libelle: "1er placement",
            actif: false
        },
        {
            id: 0,
            libelle: "Temps de parole",
            actif: false
        },
        {
            id: 1,
            libelle: "Entracte",
            actif: true
        },
        {
            id: 2,
            libelle: "Changement de place",
            actif: false
        }
    ]
}

export function getEtapeChangementPlace(){
    return [
        {
            id: null,
            libelle: "1er placement",
            actif: false
        },
        {
            id: 0,
            libelle: "Temps de parole",
            actif: false
        },
        {
            id: 1,
            libelle: "Entracte",
            actif: false
        },
        {
            id: 2,
            libelle: "Changement de place",
            actif: true
        }
    ]
}

export function initDonneeParDefautSpeed(){
    return {
        heureDepart: null,
        tabEtats: [
            {
                id: null,
                libelle: "Non démarré",
                actif: true
            },
            {
                id: 0,
                libelle: "En cours",
                actif: false
            },
            {
                id: 1,
                libelle: "En pause",
                actif: false
            },
            {
                id: 2,
                libelle: "Terminé",
                actif: false
            }
        ],
        dureePause: null,
        numPersonneGroupAEnCours: null,
        numPersonneGroupBEnCours: null,
        numTour: null,
        tabEtapes: [
            {
                id: null,
                libelle: "1er placement",
                actif: true
            },
            {
                id: 0,
                libelle: "Temps de parole",
                actif: false
            },
            {
                id: 1,
                libelle: "Entracte",
                actif: false
            },
            {
                id: 2,
                libelle: "Changement de place",
                actif: false
            }
        ],
        heureDebDernierePause: null,
        heureFinDernierePause: null,
        tempsEtapeGroupeA: null,
        tempsEtapeGroupeB: null
    }
}