import { imageAndText } from "./modules/imageAndText";
import { singleImageModule } from "./modules/singleImage";
import { singleTextModule } from "./modules/singleText";
import { textAndImage } from "./modules/textAndImage";
import { twoImagesModule } from "./modules/twoImages";
import { twoTextsModule } from "./modules/twoTexts";
import { choixObligatoire } from "./modules/choixObligatoire";
import { blocInformationsObligatoire } from "./modules/blocInformationsObligatoire";

export const mailInvitationTemplate = {
    'choixObligatoire': choixObligatoire,
    'blocInformationsObligatoire': blocInformationsObligatoire,
    'singleImage': singleImageModule,
    'singleText': singleTextModule,
    'twoImages': twoImagesModule,
    'twoTexts': twoTextsModule,
    'imageAndText': imageAndText,
    'textAndImage': textAndImage
}