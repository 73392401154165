import {Button, Form, Alert, Modal} from "react-bootstrap";
import Select2 from "../../../../components/Select2/Select2";
import {useEffect, useRef, useState} from "react";
import useListeCategorie from "../../../../requests/forum/useListeCategorie";
import useCreateSujet from "../../../../requests/forum/useCreateSujet";
import $ from "jquery";
import {Editor} from "@tinymce/tinymce-react";
import {useSnapshot} from "valtio";
import sujetProxie from "@proxies/sujet";
import auth from "../../../../services/auth";
import {iconLienPj} from "../../../../assets/icons/svgTinymce";
import {linkUrlTinyMce} from "../../../../functions/linkUrlTinyMce";
import fileManagerProxy from "../../../../proxies/fileManager";
import {imageTinyMce} from "../../../../functions/ImageTinyMce";
import useUploadFile from "../../../../requests/upload/useUploadFile";
// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';
import PersonnePartenaireSelection from "../../../personne/PersonneSelection/PersonnePartenaireSelection";
import 'tinymce/tinymce';
import 'tinymce/plugins/lists';

function ForumCreateViewModal() {
    const types = useListeCategorie("");
    const [stateAutheurSujet, setAutheurSujet] = useState("");
    const [stateTypeSujet, setTypeSujet] = useState("");
    const [stateObjetSujet, setObjetSujet] = useState("");
    const [stateCorpsSujet, setCorpsSujet] = useState("");
    const [stateIsReadOnlySujet, setIsReadOnlySujet] = useState(false);
    const [stateShowListAuth, setStateShowListAuth] = useState(false);
    const [stateTabEtats, setStateTabEtats] = useState([
        {
            id: 0,
            libelle: "Publié",
            actif: false
        },
        {
            id: 1,
            libelle: "En attente de publication",
            actif: true
        },
        {
            id: 2,
            libelle: "Brouillon",
            actif: false
        },
        {
            id: 3,
            libelle: "Archivé",
            actif: false
        },
        {
            id: 4,
            libelle: "Censuré",
            actif: false
        }
    ])
    const utilisateur = useSnapshot(auth);
    const editorRef = useRef();
    const sujetSnapshot = useSnapshot(sujetProxie);
    const agenceId = utilisateur.agence?.id;
    const createSujet = useCreateSujet(agenceId, stateCorpsSujet, stateObjetSujet, stateAutheurSujet, stateTypeSujet, stateIsReadOnlySujet, stateTabEtats);
    const uploadFile = useUploadFile({ type: "userfile" });
    if (uploadFile.data != null) {
        $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled",false);
        $(".tox-textfield").first().val(uploadFile.data.urlOriginale)

        if ($(".tox-dialog__title").text() == "Insérer une image"  || $(".tox-dialog__title").text() == "Modifier une image"){
            $(".tox-textfield").eq(1).val("L'image n'a pas pu être chargée")
        }
        else if ($(".tox-textfield").eq(1).val() == ""){
            $(".tox-textfield").eq(1).val("Cliquer ici pour ouvrir la pièce jointe.")
        }
        $(".tox-dialog__footer-end button").eq(1).trigger('click')
    }

    useEffect(() => {
        if (sujetSnapshot.createModal.initialiser){
            setAutheurSujet(utilisateur.utilisateur);
            setStateShowListAuth(false);
            sujetProxie.createModal.initialiser = false;
        }
    }, [sujetProxie.createModal.initialiser])

    function changeAuteur(val) {
        setAutheurSujet(val)
    }

    function changeAutheurIsUtilisateur(){
        setAutheurSujet(utilisateur.utilisateur);
    }

    function changeType(val) {
        types.data.data.forEach(function (element){
            if (element.id == val){
                setTypeSujet(element);

            }
        });
    }

    function changeCorps(corps) {
        setCorpsSujet(corps)
    }

    function updateObjet(event){
        setObjetSujet(event.target.value);
    }

    function createSubmitSujet(tabEtats){
        if (stateObjetSujet === "" || stateCorpsSujet === "" || stateAutheurSujet === "" || stateTypeSujet === ""){
            $("#errorMessage").attr("hidden",false);
            $("#errorObjet").attr("hidden",true);
        }else if (stateObjetSujet.length > 80){
            $("#errorObjet").attr("hidden",false);
            $("#errorMessage").attr("hidden",true);
        }
        else {
            createSujet.mutate(tabEtats);
            let btn = document.getElementById('createSujet');
            let btnEnAttente = document.getElementById('createSujetEnAttente');
            let btnBrouillon = document.getElementById('createSujetBrouillon');
            btn.disabled = true;
            btnEnAttente.disabled = true;
            btnBrouillon.disabled = true;
            $("#errorMessage").attr("hidden",true);
            $("#successMessage").attr("hidden",false);
            sujetProxie.createModal.show = false;
            setObjetSujet("");
            setCorpsSujet("");
        }

    }

    if (types.data && utilisateur){
        if (stateAutheurSujet === ""){
            setAutheurSujet(utilisateur.utilisateur);
        }

        let dataTypes = types.data.data.map((response, index) => (
            {
                id : response.id,
                text : response.libelle
            }));

        return <Modal
            show={sujetSnapshot.createModal.show}
            onHide={() => sujetProxie.createModal.show = false}
            enforceFocus={false}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>Créer un sujet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label className={"required"}>Objet :</Form.Label>
                        <Form.Control maxLength={90} value={stateObjetSujet} onChange={updateObjet} type="text" placeholder=""/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label  className={"required"}>Choisir la catégorie :</Form.Label>
                        <Select2
                            data={dataTypes}
                            minimumResultsForSearch={3}
                            onChange={changeType}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className={"required"}>Êtes vous l'auteur ?</Form.Label><br/>
                        <Form.Check
                            inline
                            type='switch'
                            label=""
                            id='utilisateurIsAuteur'
                            defaultChecked={true}
                            onClick={(val)  => {
                                if (val.target.checked){
                                    changeAutheurIsUtilisateur()
                                    setStateShowListAuth(false);
                                }else {
                                    setStateShowListAuth(true);
                                }
                            }}
                        />
                    </Form.Group>

                    <Form.Group hidden={!stateShowListAuth} className="mb-3">
                        <Form.Label className={"required"}>Choisir l'auteur de la réponse :</Form.Label>
                        <PersonnePartenaireSelection
                            logChange={changeAuteur}
                            stateAutheurSujet={stateAutheurSujet}
                            utilisateur={utilisateur.utilisateur}
                        />
                        {/*<Select2*/}
                        {/*    solid*/}
                        {/*    data={dataPartenaires}*/}
                        {/*    minimumResultsForSearch={3}*/}
                        {/*    onChange={changeAuteur}*/}
                        {/*/>*/}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className={"required"}>Le sujet est en lecture seul :</Form.Label><br/>
                        <Form.Check
                            inline
                            type='switch'
                            label=""
                            id='isReadOnly'
                            defaultChecked={false}
                            onClick={(val)  => {
                                if (val.target.checked){
                                    setIsReadOnlySujet(true);
                                }else {
                                    setIsReadOnlySujet(false);
                                }
                            }}
                        />
                    </Form.Group>


                    <Form.Group className="mb-3">
                        <Form.Label className={"required"}> Corps : </Form.Label>
                        <div className={"bg-gray-100 p-2"}>
                            <Editor className={"required"}
                                    id={"tinyMCEARecup#forumCreate#"}
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    // inline={true}
                                    init={{
                                        content_style: [contentUiCss, contentCss, '.mce-content-body { min-height: 100px; }'].join('\n'),
                                        skin: false,
                                        language: 'fr_FR',
                                        language_url: '/lang/tinymce/fr_FR/langs/fr_FR.js',
                                        menubar: false,
                                        formats : {
                                            underline : {inline : 'u', exact : true}
                                        },
                                        plugins: ["link autolink image code table textcolor lists"],
                                        toolbar: 'undo redo | ' +
                                            'bold italic underline | forecolor | alignleft aligncenter alignright alignjustify | bullist numlist ' +
                                            '| link unlink | image',
                                        setup: function (editor) {
                                            editor.ui.registry.addIcon('link', iconLienPj);
                                            editor.on('init',function(e) {
                                                editor.windowManager.oldOpen = editor.windowManager.open;
                                                editor.windowManager.open = function (t, r) {
                                                    var modal = editor.windowManager.oldOpen.apply(this, [t, r]);  // call original
                                                    if (this && t.title === "Insert/Edit Link"){
                                                        linkUrlTinyMce(t, isValidHttpUrl, null, uploadFile);
                                                    }
                                                    else if (t.title === "Insert/Edit Image"){
                                                        fileManagerProxy.baseId = "tinyMCEARecup#forumCreate#";
                                                        imageTinyMce(isValidHttpUrl, null, uploadFile);
                                                    }
                                                    return modal;

                                                }
                                            });
                                        }
                                    }}
                                    onChange={() => changeCorps(editorRef.current.getContent())}
                            />
                        </div>
                    </Form.Group>

                    <div className="d-flex justify-content-around">
                        <Button id={"createSujet"} onClick={() => createSubmitSujet([
                            {
                                id: 0,
                                libelle: "Publié",
                                actif: true
                            },
                            {
                                id: 1,
                                libelle: "En attente de publication",
                                actif: false
                            },
                            {
                                id: 2,
                                libelle: "Brouillon",
                                actif: false
                            },
                            {
                                id: 3,
                                libelle: "Archivé",
                                actif: false
                            },
                            {
                                id: 4,
                                libelle: "Censuré",
                                actif: false
                            }
                        ])} variant={"secondary"}>
                            Publier
                        </Button>
                        <Button id={"createSujetEnAttente"} onClick={() => createSubmitSujet([
                            {
                                id: 0,
                                libelle: "Publié",
                                actif: false
                            },
                            {
                                id: 1,
                                libelle: "En attente de publication",
                                actif: true
                            },
                            {
                                id: 2,
                                libelle: "Brouillon",
                                actif: false
                            },
                            {
                                id: 3,
                                libelle: "Archivé",
                                actif: false
                            },
                            {
                                id: 4,
                                libelle: "Censuré",
                                actif: false
                            }
                        ])} variant={"primary"} >
                            En attente de publication
                        </Button>
                        <Button id={"createSujetBrouillon"} onClick={() => createSubmitSujet([
                            {
                                id: 0,
                                libelle: "Publié",
                                actif: false
                            },
                            {
                                id: 1,
                                libelle: "En attente de publication",
                                actif: false
                            },
                            {
                                id: 2,
                                libelle: "Brouillon",
                                actif: true
                            },
                            {
                                id: 3,
                                libelle: "Archivé",
                                actif: false
                            },
                            {
                                id: 4,
                                libelle: "Censuré",
                                actif: false
                            }
                        ])} variant={"light"} >
                            Brouillon
                        </Button>
                    </div>
                    <Alert hidden={true} id="successMessage" className='mt-2 mx-auto' key='success' variant='success'>
                        Le sujet à été ajouté. Vous allez être automatiquement redirigé vers la liste des sujets.
                    </Alert>
                    <Alert hidden={true} id="errorMessage" className='mt-2 mx-auto' key='danger' variant='danger'>
                        Erreur d'enregistrement, un des champs est manquant.
                    </Alert>
                    <Alert hidden={true} id="errorObjet" className='mt-2 mx-auto' key='danger' variant='danger'>
                        Erreur, l'objet est trop long, il ne doit pas dépasser 80 caractères.
                    </Alert>
                </Form>
            </Modal.Body>
        </Modal>;
    } else {
        return ""
    }

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            let checkUrlMotCle = string.slice(0,4)
            return string && checkUrlMotCle == "{url";
        }

        return url.protocol === "https:";
    }

}

export default ForumCreateViewModal;