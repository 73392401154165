import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetQueryGenererParParticipant(id) {

    if (id){
        return useQuery(
            ['GenererParParticpant', id],
            async () => {
                return api.get(`rencontreSpeedBusiness/${id}/GenererParParticipant`, {
                    searchParams: Qs.stringify({
                        start: 0,
                        length: 1000,
                        utiliserParticipant:true
                    }, { arrayFormat: 'brackets' }),
                }).json();
            }
        );
    }
}