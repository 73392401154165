import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetExportAnnuaire(id) {

    let filters = {
        idProfil: id ? id : ""
    };


    return useQuery(
        ["urlExport"],
        async () => {
            return api.get("annuaire/urlExport", {
                searchParams: Qs.stringify({
                    ...filters,
                }, { arrayFormat: 'brackets' }),
            }).json();
        }
    );

}