import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

export default function useDeleteSms() {

    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);


    return useMutation(deleteSms, {
        onSuccess: () => {
            queryClient.invalidateQueries('sms');
            snapAuth.websocket.send("sms")
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

}

function deleteSms(id) {

    if (id != null)
        return api.delete(`sms/${id}`).json();

}