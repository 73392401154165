import {saveAs} from 'file-saver';
import * as XLSX from 'sheetjs-style'
import {cellStyleParParticipants, cellStyleParTable} from "../../components/ExcelExport/CellStyle";

const ExcelExport = ({ arrayData, filename, styleParParticipant = false, styleParTable = false }) => {
        const exportToExcel = () => {
                const workbook = XLSX.utils.book_new();
                arrayData.forEach(((data, index) => {
                        let tmpWorkSheet = XLSX.utils.json_to_sheet(data.data)
                        tmpWorkSheet['!autofilter'] = {ref:"A1:Z1"};
                        tmpWorkSheet = autofitColumns(data.data, tmpWorkSheet)
                        XLSX.utils.book_append_sheet(workbook, tmpWorkSheet, data?.pageName ? data?.pageName : 'page'+index);
                }))
                if (styleParParticipant){
                        cellStyleParParticipants(workbook, arrayData);
                }
                if (styleParTable){
                        cellStyleParTable(workbook, arrayData)
                }
                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
                saveAs(blob, `${filename}.xlsx`);
        };

        return exportToExcel();
}


function autofitColumns(json, worksheet, header) {

        const jsonKeys = header ? header : Object.keys(json[0]);

        let objectMaxLength = [];
        for (let i = 0; i < json.length; i++) {
                let value = json[i];
                for (let j = 0; j < jsonKeys.length; j++) {
                        if (typeof value[jsonKeys[j]] == "number") {
                                objectMaxLength[j] = 10;
                        } else {

                                const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0;

                                objectMaxLength[j] =
                                    objectMaxLength[j] >= l
                                        ? objectMaxLength[j]
                                        : l;
                        }
                }

                let key = jsonKeys;
                for (let j = 0; j < key.length; j++) {
                        objectMaxLength[j] =
                            objectMaxLength[j] >= key[j].length
                                ? objectMaxLength[j]
                                : key[j].length;
                }
        }

        worksheet["!cols"] = objectMaxLength.map(w => {
                return {width: w + 5}
        });

        return worksheet;

}
export default ExcelExport;