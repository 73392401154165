import './Section.css';

import {faArrowsAlt, faExclamationCircle, faEyeDropper, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {renderSectionEditor} from '../../utils/renderSectionEditor';
import {mailTemplate} from '../../mailTemplate/mailTemplate';
import {mailInvitationTemplate} from '../../mailTemplate/mailInvitationTemplate';
import {Alert, Button, Dropdown, Form, Modal} from 'react-bootstrap';
import ColorPicker from "../ColorPicker/ColorPicker";
import $ from "jquery";
import mailEditorProxy from "../../../../proxies/mailEditorProxy";
import {useSnapshot} from "valtio";
import { ReactComponent as Padding } from "@assets/icons/padding.svg";
import { ReactComponent as Gear } from "@assets/icons/gear.svg";
import BlocModaliteSelection from "../BlocsObligatoire/BlocModaliteSelection";
import invitationsProxy from "../../../../proxies/invitations";
import BlocChoixSelection from "../BlocsObligatoire/BlocChoixSelection";
import blocsProxy from "../../../../proxies/blocs";


function MoveHandle({ show }) {
  return <div className={`sectionButton symbol symbol-40px ${show ? 'visible' : 'invisible'}`}>
    <div className='symbol-label fs-2 fw-bold bg-primary text-inverse-primary p-2'>
      <FontAwesomeIcon icon={faArrowsAlt} size='lg' />
    </div>
  </div>
}

function DeleteButton({ show }) {
  return <div className={`sectionButton symbol symbol-40px ${show ? 'visible' : 'invisible'}`}>
    <div
      className='symbol-label fs-2 fw-bold bg-danger hoverable text-inverse-primary p-2'
      style={{ transition: 'none' }}
    >
      <FontAwesomeIcon icon={faTrashAlt} size='lg' />
    </div>
  </div>
}

function BlockNotFound() {
  return <div className='w-600px p-5'>
    <Alert variant='danger' className='m-0'>
      <FontAwesomeIcon icon={faExclamationCircle} size='lg' className='me-3' />
      Le bloc est introuvable
    </Alert>
  </div>;
}

function Section({ isInvitation, block, id, index, onChange, onDelete }) {
  let choixMailTemplate
  if (isInvitation){
    choixMailTemplate = mailInvitationTemplate;
  }else {
    choixMailTemplate = mailTemplate;
  }
  const invitationSnap = useSnapshot(invitationsProxy);
  const [initBloc, setInitBloc] = useState(false);
  const [colorSection, setColorSection] = useState(block.backgroundColor);
  const [paddingSection, setPaddingSection] = useState(block.padding);
  const [hoverBorder, setHoverBorder] = useState(false);
  useEffect(() => {
    if (!initBloc){
      setInitBloc(true);
      if (invitationSnap.invitation?.blocModaliteReponse?.corps && invitationSnap.invitation?.blocReponse?.corps){
        $("[data-rbd-draggable-id='" + id + "'] #blocModaliteReponseInvitation").html(invitationSnap.invitation?.blocModaliteReponse?.corps);
        $("[data-rbd-draggable-id='" + id + "'] #blocReponseInvitation").html(invitationSnap.invitation?.blocReponse?.corps);
      }else {
        if (!invitationSnap.invitation?.blocModaliteReponse?.corps){
          $("[data-rbd-draggable-id='" + id + "'] #blocModaliteReponseInvitation").height(40)
          $("[data-rbd-draggable-id='" + id + "'] #blocModaliteReponseInvitation").html(`<p>Merci de sélectionner un bloc en cliquant sur le bloc et en sélectionnant un bloc via la roue crantée.</p>`);
        }
        if (!invitationSnap.invitation?.blocReponse?.corps){
          $("[data-rbd-draggable-id='" + id + "'] #blocReponseInvitation").height(40)
          $("[data-rbd-draggable-id='" + id + "'] #blocReponseInvitation").html(`<p>Merci de sélectionner un bloc en cliquant sur le bloc et en sélectionnant un bloc via la roue crantée.</p>`);
        }
      }
    }
    let viewPaddingTemplate = paddingSection !== "" ? paddingSection : block.padding;
    $("[data-rbd-draggable-id='"+id+"'] .flexibleContainerCell").css('padding',  viewPaddingTemplate)
    if (paddingSection === undefined){
      setPaddingSection("20px 20px 20px 20px")
    }
  }, [])

  $(".customBtnTransfert").removeAttr('href')

  let replacesStyle = {
    'id="colorPicker"':'style="background-color: '+block.backgroundColor+'"',
    'id="paddingTemplate"':'style="padding: '+block.padding+'"',
  }
  if (block?.type == "singleImage"){
    replacesStyle['id="image"'] = block?.padding && block?.content?.images?.image && (block?.type == "singleImage") ? 'src="'+block?.content?.images?.image+'" style="max-width: '+ (600 - (parseInt(block.padding.split(' ')[1])+parseInt(block.padding.split(' ')[3]))) + 'px" width="'+(600 - (parseInt(block.padding.split(' ')[1])+parseInt(block.padding.split(' ')[3])))+'"' : 'id="image" width="560" src="https://placehold.co/1120x800/EEE/31343C" style="max-width: 560px"'
  }
  let regex = new RegExp(Object.keys(replacesStyle).join("|"),"gi");

  const template = choixMailTemplate[block.type]?.template.replace(regex, function (matched) {
    return replacesStyle[matched];
  });

  const reactElement = (template != null)
    ? <table>
      <tbody>
        {renderSectionEditor(template, block.content, function (type, id, value) {
          const result = {
            ...block,
            content: {
              ...block.content,
              [type]: {
                ...block.content[type],
                [id]: value,
              }
            },
            backgroundColor: block.backgroundColor ? block.backgroundColor : "",
            padding: block.padding ? block.padding : "20px 20px 20px 20px"
          };
          onChange(result);
        }, index)}
      </tbody>
    </table>
    : <BlockNotFound />;

  const [isHover, setIsHover] = useState(false);

  return (
    <Draggable draggableId={id} key={id} index={index}>
      {(provided, snapshot) => (
        <td className='section'
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div
              style={{backgroundColor:colorSection, border: hoverBorder || isHover ? "solid 1px blue" : ""}}
              className={`${(isHover || snapshot.isDragging) ? 'bg-light' : ''} ${snapshot.isDragging ? 'shadow-sm' : ''}`}
              onClick={() => setIsHover(!isHover)}
              onMouseLeave={() => setHoverBorder(false)}
              onMouseEnter={() => setHoverBorder(true)}
          >
            {reactElement}
          </div>
          <div className="position-relative">
            <div className='buttonsWrapper position-absolute ps-1'>
              <div className='d-flex'>
                <div
                    hidden={!isHover}
                  {...provided.dragHandleProps}
                  onMouseDown={(e) => e.currentTarget.focus()}
                >
                  <MoveHandle show={isHover || snapshot.isDragging} />
                </div>
                <div onClick={() => onDelete(id)}
                     hidden={!isHover}
                >
                  <DeleteButton show={isHover || snapshot.isDragging} />
                </div>
                <ColorPicker hidden={!isHover} show={isHover || snapshot.isDragging} color={colorSection} onChange={(color) => {
                  setColorSection(color)
                  let result = {
                    ...block,
                    backgroundColor:color
                  };
                  onChange(result)
                }}/>
                <Dropdown
                    hidden={!isHover}
                    className={`${isHover || snapshot.isDragging  ? '' : 'd-none'}`}
                    title={'Marge du bloc'} onClick={() => {
                  mailEditorProxy.showModalPadding(setPaddingSection, onChange, block, id, paddingSection);
                }}>
                  <Dropdown.Toggle className='hide-after' variant='white'>
                    <span className='svg-icon svg-icon-1 me-2'><Padding/></span>
                  </Dropdown.Toggle>
                </Dropdown>
                {block?.content?.images && Object.keys(block?.content?.images).length == 1 &&
                    <Dropdown
                        hidden={!isHover}
                        className={`${isHover || snapshot.isDragging  ? '' : 'd-none'}`}
                        title={'Ajouter un lien à l\'image'} onClick={() => {
                        mailEditorProxy.showModalAddOneUrlOnImage(onChange, block, id);
                    }}>
                      <Dropdown.Toggle className='hide-after' variant='white'>
                        <i className='fa fa-link'></i>
                      </Dropdown.Toggle>
                    </Dropdown>
                }
                {block?.content?.images && Object.keys(block?.content?.images).length > 1 &&
                    <Dropdown
                        hidden={!isHover}
                        className={`${isHover || snapshot.isDragging  ? '' : 'd-none'}`}
                        title={'Ajouter un lien aux images'} onClick={() => {
                        mailEditorProxy.showModalAddTwoUrlOnImage(onChange, block, id);
                    }}>
                      <Dropdown.Toggle className='hide-after' variant='white'>
                        <i className='fa fa-link'></i>
                      </Dropdown.Toggle>
                    </Dropdown>
                }
                {block?.content?.blocButtonTransferes &&
                    <Dropdown
                        hidden={!isHover}
                        className={`${isHover || snapshot.isDragging  ? '' : 'd-none'}`}
                        title={'Personnaliser le bouton de transfert'} onClick={() => {
                      mailEditorProxy.showModalCustomButtonTranfert(onChange, block, id);
                    }}>
                      <Dropdown.Toggle className='hide-after' variant='white'>
                        <span className='svg-icon svg-icon-1 me-2'><Gear/></span>
                      </Dropdown.Toggle>
                    </Dropdown>
                }

                {block.type == "blocInformationsObligatoire" && <BlocModaliteSelection
                    hidden={!isHover}
                    className={`btn btn-outline-default ms-2  ${isHover || snapshot.isDragging ? '' : 'd-none'}`}
                    onChange={(bloc) => {
                      $("[data-rbd-draggable-id='" + id + "'] #blocModaliteReponseInvitation").html(bloc.corps);
                      blocsProxy.blocModalite = {
                        ...bloc
                      };
                    }}
                >
                  <span className='svg-icon svg-icon-1 me-2'><Gear/></span>
                </BlocModaliteSelection>}
                {block.type == "choixObligatoire" && <BlocChoixSelection
                    hidden={!isHover}
                    className={`btn btn-outline-default ms-2  ${isHover || snapshot.isDragging ? '' : 'd-none'}`}
                    onChange={(bloc) => {
                      $("[data-rbd-draggable-id='" + id + "'] #blocReponseInvitation").html(bloc.corps);
                      blocsProxy.blocChoix = {
                        ...bloc
                      };
                    }}
                >
                  <span className='svg-icon svg-icon-1 me-2'><Gear/></span>
                </BlocChoixSelection>}
              </div>
            </div>
          </div>
        </td>
      )}
    </Draggable>
  );
}


export default Section;