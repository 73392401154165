import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";
import useResendMailRappelForOneCorrespondant
    from "../../../../requests/communication/speedBusiness/useResendMailRappelForOneCorrespondant";
import useSendMailsRencontre from "../../../../requests/communication/speedBusiness/useSendMailsRencontre";
import React, {useMemo, useState} from "react";
import moment from "moment";
import annuaireProxy from "../../../../proxies/annuaire";
import {ReactComponent as Gen016} from "@assets/icons/gen016.svg";
import {BtSwal} from "../../../../utils/alerts/sweetAlert";
import {Button, Card, Dropdown} from "react-bootstrap";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import Flatpickr from "react-flatpickr";
import {French} from "flatpickr/dist/l10n/fr";
import MetaTable from "../../../../components/MetaTable/MetaTable";
import { ReactComponent as Art005 } from "@assets/icons/art005.svg";
import {useSnapshot} from "valtio";
import speedBusinessProxy from "../../../../proxies/speedBusiness";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWrench} from "@fortawesome/free-solid-svg-icons";
import useSendLeinMaitreDuTemps from "../../../../requests/communication/speedBusiness/useSendLeinMaitreDuTemps";
import useResendSmsMaitreDuTempsForOneCorrespondant
    from "../../../../requests/communication/speedBusiness/useResendSmsMaitreDuTempsForOneCorrespondant";
import useResendMailMaitreDuTempsForOneCorrespondant
    from "../../../../requests/communication/speedBusiness/useResendMailMaitreDuTempsForOneCorrespondant";

export default function EmailLienMaitreDuTemps({baseRoute, id}){
    const { register, watch, setValue } = useForm();
    const searchDestinataire = watch('searchDestinataire');
    const [debouncedSearchDestinataire] = useDebounce(searchDestinataire, 300);
    const sendLienMaitreDuTemps = useSendLeinMaitreDuTemps();
    const speedSnap = useSnapshot(speedBusinessProxy);
    const sendSmsLienMaitreDuTempsForOneCorrespondant = useResendSmsMaitreDuTempsForOneCorrespondant();
    const sendMailLienMaitreDuTempsForOneCorrespondant = useResendMailMaitreDuTempsForOneCorrespondant();
    const colSearch = useMemo(() => {
        return {
            'isMaitreDuTemps': 0,
            'isMaitreDuTempsSuppleant': 0,
        };
    }, []);

    const [dateEnvoi, setDateEnvoi] = useState(moment().format("DD/MM/YYYY à HH:mm"));
    const destinatairesColumns = useMemo(() => [
        {
            Header: () => "",
            id: 'wrench',
            Cell: ({ row }) =>
                <Dropdown>
                    <Dropdown.Toggle className='hide-after btn-icon btn-quaternaire w-30px h-30px'>
                        <FontAwesomeIcon color={"black"} icon={faWrench} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='fs-6 w-350px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
                        <div className='menu-item px-3'>
                            <Dropdown.Item
                                onClick={() => annuaireProxy.showPersonne(row.original.participant.personne, false, "personne", true)}
                            >
                                <span className='svg-icon svg-icon-1 me-2'><Art005 /></span>
                                Modifier
                            </Dropdown.Item>
                            <Dropdown.Item
                                title={"envoyer mail lien"}
                                onClick={() => {
                                    BtSwal.fire({
                                        title: `<b>Êtes-vous sûr de vouloir envoyer l'email lien à ${row.original.participant.personne.prenom} ${row.original.participant.personne.nom} ?</b>`,
                                        showDenyButton: true,
                                        confirmButtonText: `Oui`,
                                        denyButtonText: `Annuler`,
                                        icon: 'info',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                           sendMailLienMaitreDuTempsForOneCorrespondant.mutate(row?.original?.id);
                                        } else if (result.isDenied) {
                                            BtSwal.fire('Le mail n\'a pas été envoyé.', '', 'info');
                                        }
                                    })
                                }}
                            >
                               <span style={{
                               }} className='svg-icon svg-icon-1 me-2'><Gen016/> Envoyer email lien maitre du temps</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                title={"envoyer sms lien"}
                                onClick={() => {
                                    BtSwal.fire({
                                        title: `<b>Êtes-vous sûr de vouloir envoyer le sms lien à ${row.original.participant.personne.prenom} ${row.original.participant.personne.nom} ?</b>`,
                                        showDenyButton: true,
                                        confirmButtonText: `Oui`,
                                        denyButtonText: `Annuler`,
                                        icon: 'info',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            sendSmsLienMaitreDuTempsForOneCorrespondant.mutate(row?.original?.id);
                                        } else if (result.isDenied) {
                                            BtSwal.fire('Le mail n\'a pas été envoyé.', '', 'info');
                                        }
                                    })
                                }}
                            >
                               <span style={{
                               }} className='svg-icon svg-icon-1 me-2'><Gen016/> Envoyer sms lien maitre du temps</span>
                            </Dropdown.Item>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>,
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: 'Id',
            accessor: 'participant.personne.id',
            width: 80,
            Cell: ({row}) => {
                return <span>{row?.original?.participant?.personne?.id} <i style={{
                    color: row?.original?.isMaitreDuTemps ? "gold" : "silver"
                }} className="fa fa-crown"></i></span>;
            }
        },
        {
            Header: 'Passer en maitre du temps',
            accessor: 'isMaitreDuTemps',
            searchOperator: "=",
            logic: "OR",
            hidden: true
        },
        {
            Header: 'isMaitreDuTempsSuppleant',
            accessor: 'isMaitreDuTempsSuppleant',
            searchOperator: "=",
            logic: "OR",
            hidden: true
        },
        {
            Header: 'Prenom',
            accessor: 'participant.personne.prenom',
        },
        {
            Header: 'Nom',
            accessor: 'participant.personne.nom',
        },
        {
            Header: 'Email',
            accessor: 'participant.personne.email',
            width: 250,
        },
        {
            Header: 'Téléphones',
            accessor: 'participant.personne.tel1',
            width: 250,
            Cell: ({row}) => {
                let result = [];
                result.push(row.original.participant.personne.tel1 ? row.original.participant.personne.tel1 + " " : "")
                result.push(<br/>)
                result.push(row.original.participant.personne.tel2 ? row.original.participant.personne.tel2 : "")
                return result;
            }
        },
    ], []);
    return <Card className='card-flush'>
        <Card.Body>
            <h4 className='text-center'>Participants</h4>
            <div className='d-flex justify-content-between'>
                <div style={{
                    height: "fit-content"
                }}>
                    <SearchBar
                        solid
                        {...register('searchDestinataire')}
                        onClear={searchDestinataire?.length > 0 ? () => {
                            setValue('searchDestinataire', '');
                        } : null}
                    />
                </div>
                <div style={{
                    padding: 5,
                    border: "solid 1px black",
                    width: "fit-content",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "fit-content"
                }} className="">
                    <span className="d-flex justify-content-center align-items-center ">Envoyer le lien aux maitres du temps <i
                        className="btn btn-primary me-2 ms-2 fa fa-2x fa-sms" onClick={() => {
                        BtSwal.fire({
                            title: `<b>Êtes-vous sûr de vouloir envoyer le sms lien aux maitres du temps ?</b>`,
                            showDenyButton: true,
                            confirmButtonText: `Oui`,
                            denyButtonText: `Annuler`,
                            icon: 'info',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                sendLienMaitreDuTemps.mutate({
                                    id: id,
                                    type: 'smsLienMaitreTemps',
                                    dateProgrammationEnvoi: dateEnvoi,
                                })
                            } else if (result.isDenied) {
                                BtSwal.fire('Le sms n\'a pas été envoyé.', '', 'info')
                            }
                        })
                    }}></i> <i
                        className="fa fa-2x fa-envelope btn btn-primary "
                        onClick={() => {
                            BtSwal.fire({
                                title: `<b>Êtes-vous sûr de vouloir envoyer le mail lien aux maitres du temps ?</b>`,
                                showDenyButton: true,
                                confirmButtonText: `Oui`,
                                denyButtonText: `Annuler`,
                                icon: 'info',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    sendLienMaitreDuTemps.mutate({
                                        id: id,
                                        type: 'emailLienMaitreTemps',
                                        dateProgrammationEnvoi: dateEnvoi,
                                    })
                                } else if (result.isDenied) {
                                    BtSwal.fire('Le mail n\'a pas été envoyé.', '', 'info')
                                }
                            })
                        }}
                    ></i></span>
                </div>
                <div>
                    <div>
                        <i style={{
                            color: "gold"
                        }} className="fa fa-crown me-2"></i>Maitre du temps
                    </div>
                    <div>
                        <i
                            style={{
                                color: "silver"
                            }}
                            className="fa fa-crown me-2"></i>Maitre du temps suppléant
                    </div>
                </div>

            </div>
            <MetaTable
                className='mt-5'
                height={450}
                url={`${baseRoute}/${id}/participants`}
                keys={[baseRoute, id, 'participants', 'mission']}
                columns={destinatairesColumns}
                colSearch={colSearch}
                search={debouncedSearchDestinataire}
            />
        </Card.Body>
    </Card>;
}