import MailEditor from "@components/MailEditor/MailEditor";
import React, {useEffect, useRef, useState} from "react";
import mailsProxy from "@proxies/mails";
import { BtSwal } from "@utils/alerts/sweetAlert";
import {Alert, Button, Form, Modal, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useQueryClient} from "react-query";
import useChangeMailAccesPartenaire from "../../../requests/parametres/accesPartenaires/useChangeMailAccesPartenaire";
import useGetMailAccesPartenaire from "../../../requests/parametres/accesPartenaires/useGetMailAccesPartenaire";
import ExpediteurSelection from "../../communication/ExpediteurSelection/ExpediteurSelection";
import {useForm} from "react-hook-form";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

function AccesMailSettings() {
    const queryClient = useQueryClient();
    const editorRef = useRef();
    const snapAuth = useSnapshot(auth);
    const changeMail = useChangeMailAccesPartenaire({
        onSuccess: (res) => {
            queryClient.invalidateQueries('emailEnvoiIdentifiant')
            snapAuth.websocket.send("emailEnvoiIdentifiant")

            BtSwal.fire('Mail accès partenaire modifié', '', 'success')
        },
    });
    const mailAccesPartenaire = useGetMailAccesPartenaire();
    const [arrayMotCle, setArrayMotCle] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const { register, handleSubmit, reset, control, setError,
        formState: { errors }, watch} =
        useForm({
            defaultValues: mailAccesPartenaire?.data
    });
    const expediteur = watch("expediteur");
    const [init, setInit] = useState(false);
    useEffect(() => {
        reset(mailAccesPartenaire?.data ?? {});
        if (mailAccesPartenaire.data){
            setInit(true)
        }
    }, [reset, mailAccesPartenaire?.data]);


    function save(){

        const mailBody = editorRef.current?.save();

        if (!(mailBody.json?.order?.length > 0)) {
            BtSwal.fire({
                title: 'Mail incomplet !',
                text: 'Vous devez ajouter au minimum une section au mail',
                icon: 'error',
                confirmButtonText: 'Ok',
            });
            return false;
        }

        const newMail = {
            corpsHtml: mailBody.html,
            corpsTemplate: mailBody.json,
            header: "",
            signature: "",
            expediteur: expediteur
        };

        if (!expediteur){
            setError("expediteur")
        }
        else if (checkMotCleObligatoire(mailBody.html).isOk){
            try {
                const result = changeMail.mutate(newMail);
                mailsProxy.mail = result;
                return true;
            } catch {
                return false;
            }
        }else {
            setShowModal(true)
        }
    }



    if (mailAccesPartenaire.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    if (mailAccesPartenaire.isError)
        return <Alert variant='danger'>{mailAccesPartenaire.error.message}</Alert>


    return <div id='rappelMail'><div className="d-flex justify-content-end mb-2">
        <Button
            variant='secondary'
            className='text-center'
            onClick={() => {
                save()
            }}
        >
            <FontAwesomeIcon className='fa fa-2x'  icon={faSave}/>

        </Button>
    </div>
        <Form.Group className="mt-5 mb-3 mw-250px m-auto">
            <Form.Label className='required'>Expéditeur</Form.Label>
            <ExpediteurSelection control={control} name='expediteur' rules={{ required: true }} init={init} />
            {errors.expediteur && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
        </Form.Group>
        <MailEditor
        hiddenFooterHeader={true}
        ref={editorRef}
        value={mailAccesPartenaire.data.corpsTemplate}
    />
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size='md'
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Mots clés obligatoires</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="table-responsive">
                    <h1 className="text-center mt-3
                    ">Mots clés obligatoires manquants</h1>
                    <table className="m-auto table mt-4 table-row-dashed  table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                        <tr className="fw-bolder fs-6 text-gray-800 text-center border-0 bg-light">
                            <th className="w-300px rounded-start"></th>
                            <th className="w-100px">Présent</th>
                        </tr>
                        </thead>
                        <tbody className="border-bottom border-dashed">
                        {arrayMotCle}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    </div>;

    function checkMotCleObligatoire(corps){
        let arrAsso = {
            '{urlDefinirUnMotDePasse}' : {
                text : "Url pour définir mot de passe",
                isCheck : false
            },
            '{login}' : {
                text : "identifiant",
                isCheck : false
            }
        }
        const arr = [
            '{urlDefinirUnMotDePasse}',
            '{login}'
        ]

        let tabResult =  []
        let allIsOk = true;

        arr.forEach(function(word) {
            if (corps.indexOf(word) > -1){
                arrAsso[word].isCheck = true
            }else {
                allIsOk = false;
            }
            return  corps.indexOf(word) > -1
        })

        Object.values(arrAsso).forEach(e => {
            tabResult.push(<tr className="text-center">
                <td className="text-start ps-6">
                    <div className="fw-bold fs-4 text-gray-800">{e.text}</div>
                </td>
                <td>
                    {e.isCheck ? <FontAwesomeIcon icon={faCheck} color={"green"}/> : <FontAwesomeIcon icon={faTimes} color={"red"}/>}
                </td>
            </tr>)
        })

        setArrayMotCle(tabResult)

        return {
            isOk : allIsOk,
            data : arrAsso
        }
    }

}

export default AccesMailSettings;