import { useMutation } from "react-query";
import api from "@services/api";

export default function useGetPage() {

    return useMutation(getPage, {
        onSuccess: () => {
        },
        onError: (err) => {
        },
    });

}

function getPage(id) {
    if (id != null)
        return api.get(`pageFacebook/${id}`).json();

}