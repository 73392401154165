import { useMutation } from "react-query";
import api from "@services/api";

export default function useChangeParametreNotification(props) {
    return useMutation(changeParametreNotification, props);
}

export async function changeParametreNotification(personne) {
    const id = personne.id;

    if (id != null)
        return api.put(`parametreNotification/${id}`, { json: personne }).json();

}
