import {dureeMinuteToTimeMysqlWithoutHours} from "../../../../views/SpeedBusinessView/ReglageStep/data";
import CountDownCircleTimer from "../../../../components/CountDownCircleTimer/CountDownCircleTimer";
import CarousselPersonnes from "../../../../views/SpeedBusinessView/PresentationMobileView/Steps/CarousselPersonnes";
import CustomProgressBar from "../../../../components/CustomProgressBar/CustomProgressBar";
import CountDownCircleTimerLight from "../../../../components/CountDownCircleTimer/CountDownCircleTimerLight";
import {useEffect, useMemo, useState} from "react";

export default function TempsDeParoleMobile({
                                 color = "var(--primary-color)",
                                 currentTime,
                                 setCurrentPerson,
                                 currentPerson,
                                 isTimePersonne,
                                 setIsTimePersonne,
                                 duration,
                                 setIsEntracte,
                                 isEntracte,
                                 tour,
                                 setTour,
                                 setIsChangementTour,
                                 isChangementTour,
                                 setCurrentTime,
                                 isPlaying,
                                 valueChangeRemainingTime,
                                 setValueChangeRemainingTime,
                                 setInitCurrentPerson,
                                 numberOfStep,
                                 zigZagBar,
                                 numIsPair,
                                 personneParTable,
                                 speedBusiness,
                                 dataCurrentTableParticipant,
                                 heureFinEstime,
                                 nbTours,
                                 nomCurrentTable
                             }) {

    const tabInfo = useMemo(() =>{
        return [
            {
                description: "Présentation de soi-même",
                duration : duration*0.2
            },
            {
                description: "Présentation de mon entreprise",
                duration : duration*0.3
            },
            {
                description: "Ce que je recherche précisément",
                duration : duration*0.25
            },
            {
                description: "Questions-réponses",
                duration : duration*0.25
            },
        ]
    },[duration]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [valueChangeRemainingTimeLight, setValueChangeRemainingTimeLight] = useState(true);
    useEffect(() => {
        if (valueChangeRemainingTime && valueChangeRemainingTimeLight){
            let tempsEcoulee = duration - valueChangeRemainingTime;
            let i = 0;
            for (let stepInfo of tabInfo) {
                tempsEcoulee = tempsEcoulee - stepInfo.duration;
                if (tempsEcoulee < 0){
                    setCurrentIndex(i)
                    //temps restant de l'étape
                    setValueChangeRemainingTimeLight(Math.abs(tempsEcoulee))
                    break;
                }
                i++;
            }
        }
    }, [valueChangeRemainingTime, valueChangeRemainingTimeLight])
    return <>
        <div style={{
            height: 120,
            width: "100%",
            marginBottom: 50,
            backgroundColor: "var(--primary-color)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            flexWrap: "wrap",
        }}>
            <div><h2 style={{
                width: "100%",
                fontSize: 22,
                marginBottom: 0,
                color: "white",
                textAlign: "center"
            }}>TEMPS DE PAROLE</h2>
                <span style={{
                    display: "block",
                    fontWeight: "bold",
                    fontSize: 18,
                    color: "white",
                    textAlign: "center"
                }}>Tour {tour}/{nbTours} - Table : {nomCurrentTable}</span></div>
            <span style={{
                position: "absolute",
                height: "40px",
                width: 300,
                bottom: -20,
                backgroundColor: "var(" + color + ")",
                left: "calc(50% - 150px)",
                borderRadius: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <span style={{
                    fontSize: 14,
                    color: "white",
                    fontWeight: "bold"
                }}>Heure de fin estimé : {heureFinEstime}</span>
            </span>
        </div>
        <div className="d-flex justify-content-center">
            <div className="ms-5">
                            <span
                                style={{
                                    borderColor: "var(" + color + ")"
                                }}
                                className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(currentTime / 60)[0]}</span>
                <span style={{
                    borderColor: "var(" + color + ")"
                }}
                      className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(currentTime / 60)[1]}</span>
                <span style={{
                    fontSize: 30,
                    fontWeight: "bold",
                    marginRight: 10
                }}>{dureeMinuteToTimeMysqlWithoutHours(currentTime / 60)[2]}</span>
                <span style={{
                    borderColor: "var(" + color + ")"
                }}
                      className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(currentTime / 60)[3]}</span>
                <span style={{
                    borderColor: "var(" + color + ")"
                }}
                      className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(currentTime / 60)[4]}</span>

            </div>
        </div>

        <CountDownCircleTimer
            setCurrentPerson={setCurrentPerson}
            currentPerson={currentPerson}
            personneParTable={personneParTable}//calcul a faire
            title={"Titre"}
            isTimePersonne={isTimePersonne}
            setIsTimePersonne={setIsTimePersonne}
            duration={duration}
            setIsEntracte={setIsEntracte}
            isEntracte={isEntracte}
            tour={tour}
            setTour={setTour}
            setIsChangementTour={setIsChangementTour}
            isChangementTour={isChangementTour}
            setCurrentTime={setCurrentTime}
            isPlaying={isPlaying}
            valueChangeRemainingTime={valueChangeRemainingTime}
            setValueChangeRemainingTime={setValueChangeRemainingTime}
            setInitCurrentPerson={setInitCurrentPerson}
        />
        <CarousselPersonnes
            currentPerson={currentPerson}
            dataCurrentTableParticipant={dataCurrentTableParticipant}
        />
        <div
            style={{
                width: "100%",
                height: 70,
                marginTop:40,
                paddingLeft:5,
                paddingRight:5,
            }}
        >
            <div style={{
                height: 40,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "20px 20px 0  0",
                margin: 0,
                backgroundColor: "var(" + color + ")"
            }}>
                             <span
                                 style={{
                                     width: "100%",
                                     fontSize: 16,
                                     color: "white",
                                     textAlign: "center",
                                     display: "inline-block",
                                     fontWeight: "bold"
                                 }}
                             >{Math.trunc(duration / 60) + " minute" + (Math.trunc(duration / 60) > 1 ? "s" : "") + (duration % 60 != 0 ? (duration % 60 < 10 ? " 0" : " ") + "" + Math.round(duration % 60) : "")} pour vous présenter</span>

            </div>
            <div style={{
                height: 40,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 0,
                backgroundColor: "#d8d8d8",
                borderRadius: "0 0 20px 20px"
            }}>
                <div className="d-flex justify-content-center align-items-center flex-wrap" style={{
                    height: 150,
                    width: "100%"
                }}>
                    <span
                        style={{
                            width: "100%",
                            fontSize: 16,
                            color: "black",
                            textAlign: "center",
                            display: "block",
                            fontWeight: "bold",
                            // height: 36

                        }}
                    >
                                    <div
                                        className={`d-flex justify-content-center align-items-center`}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>
                                                {currentIndex < tabInfo.length && tabInfo[currentIndex].description}
                                            </span>
                                            <div style={{
                                                fontSize: 16
                                            }} className="d-block">
                                       {currentIndex < tabInfo.length &&
                                           <CountDownCircleTimerLight color={color} currentPerson={currentPerson}
                                                                      setCurrentIndex={setCurrentIndex}
                                                                      setValueChangeRemainingTimeLight={setValueChangeRemainingTimeLight}
                                                                      valueChangeRemainingTimeLight={valueChangeRemainingTimeLight}
                                                                      currentIndex={currentIndex}
                                                                      duration={currentIndex < tabInfo.length && tabInfo[currentIndex].duration}
                                                                      isPlaying={isPlaying}/>}
                                            </div>
                                        </div>
                                    </div>
                                </span>
                </div>
            </div>
        </div>
        <div style={{
            marginTop: 30,
            paddingBottom: 20,
            textAlign: "center"
        }}>
                        <span> <i className="fa fa-users fa-2x me-2" style={{
                            color: color
                        }}></i> <span style={{
                            fontSize: 18,
                            fontWeight: "bold"
                        }}>Ordre de passage</span></span>
        </div>
        <div style={{
            marginTop: 60,
            margin: 56,
            width: "100%"
        }} className="">
            {tour <= speedBusiness?.data.nombreTours && <div className="position-relative m-auto" style={{
                height: 200,
                width: 374
            }}>
                <div style={{
                    backgroundColor: "white",
                    height: 120,
                    width: 330,
                    position: "absolute",
                    top: -52,
                    right: " calc(50% - 109px)",
                    borderRadius: 75,
                    border: "solid 6px #d8d8d8"
                }}>

                </div>
                {numberOfStep.map((number, index) => {
                    if (number == 1) {
                        return <CustomProgressBar
                            color={color}
                            zIndex={100 - number}
                            rotate={-zigZagBar.rotate}
                            nbToursTotal={speedBusiness?.data.nombreTours}
                            numberOfStep={[1, 2]}
                            width={zigZagBar.width}
                            left={zigZagBar.left * 0}
                            progressPercent={currentPerson == 1 ? (((duration) - currentTime) / duration) * 100 : 100}
                        />
                    } else if (index == 1) {
                        return;
                    } else if (index == numberOfStep.length - 1) {
                        return <CustomProgressBar
                            color={color}
                            zIndex={100 - number}
                            rotate={!numIsPair(number) ? zigZagBar.rotate : -zigZagBar.rotate}
                            left={zigZagBar.left * (index - 1)}
                            width={zigZagBar.width}
                            nbToursTotal={speedBusiness?.data.nombreTours}
                            numberOfStep={[0, "fin"]}
                            progressPercent={currentPerson > number - 1 ? 100 : (currentPerson == number - 1 ? (((duration) - currentTime) / duration) * 100 : 0)}
                        />
                    } else {
                        return <CustomProgressBar
                            color={color}
                            zIndex={100 - number}
                            rotate={!numIsPair(number) ? zigZagBar.rotate : -zigZagBar.rotate}
                            left={zigZagBar.left * (index - 1)}
                            width={zigZagBar.width}
                            nbToursTotal={speedBusiness?.data.nombreTours}
                            numberOfStep={[0, number]}
                            progressPercent={currentPerson > number - 1 ? 100 : (currentPerson == number - 1 ? (((duration) - currentTime) / duration) * 100 : 0)}
                        />
                    }
                })
                }
            </div>}
        </div>
    </>;
}
