import {
    faTrash,
    faCommentDots,
    faEdit,
    faEye,
    faBan,
    faCircle,
    faCheckCircle,
    faArchive, faPencilAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Button, Card, Col, Row, Stack} from "react-bootstrap";
import usePublierSujet from "../../../../requests/forum/usePublierSujet";
import useDepublierSujet from "../../../../requests/forum/useDepublierSujet";
import useDeleteSujet from "../../../../requests/forum/useDeleteSujet";
import {BtSwal} from "../../../../utils/alerts/sweetAlert";
import sujetProxie from "@proxies/sujet";
import {useEffect} from "react";
import useIncrementeNbVues from "../../../../requests/forum/useIncrementeNbVues";

function ForumItem({ title, content, idSujet, dateCreation, nbVues, nbMessages, isPublie, libelleCat, auteur, dernierMessage, useGetSujetByCategorie, showMore, datePublication, sujet }) {
    let isPublieTxt  = null;
    let btnsPublication = null;
    const incrementerVue = useIncrementeNbVues(idSujet, libelleCat);
    const deleteSujet = useDeleteSujet(libelleCat);
    if (isPublie == true){
        isPublieTxt = "Publié le "+datePublication;
        btnsPublication = [
                        <Button disabled={true} variant={"secondary"} className='me-2'><FontAwesomeIcon title="Publier" icon={faCheckCircle} /></Button>,
                        <Button className="me-2" variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été dépublié', '', 'success').then(() =>{
                            depublierSujet.mutate({idEtat: 1})
                        })}><FontAwesomeIcon title="Dépublier" icon={faBan} /></Button>,
                        <Button title={"brouillon"} className="me-2" variant={"secondary"} onClick={() => BtSwal.fire('Le sujet est en brouillon', '', 'success').then(() =>{
                            depublierSujet.mutate({idEtat: 2})
                        })}><FontAwesomeIcon title="brouillon" icon={faPencilAlt} /></Button>,
                        <Button title={"archiver"} className="me-2" variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été archivé', '', 'success').then(() =>{
                            depublierSujet.mutate({idEtat: 3})
                        })}><FontAwesomeIcon title="Archiver" icon={faArchive} /></Button>,

                        <Button style={{width: 55, height:43, padding: 5}} title={"censuré"} variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été censuré', '', 'success').then(() =>{
                            depublierSujet.mutate({idEtat: 4})
                        })}><img width={30} src="/icon/censure.svg"  alt={"censure"}/></Button>,

                        <Button variant={"secondary"} className={"ms-2"} onClick={() => BtSwal.fire({
                            title: 'Êtes vous sur de vouloir supprimer le sujet ?',
                            showDenyButton: true,
                            confirmButtonText: `Oui`,
                            denyButtonText: `Annuler`,
                        }).then((result) => {
                            if (result.isConfirmed){
                                deleteSujet.mutate(idSujet);
                                BtSwal.fire('Le sujet a été supprimé!', '', 'success')
                            } else if (result.isDenied) {
                                BtSwal.fire('Le sujet n\'a pas été supprimé', '', 'info')
                            }
                        })}><FontAwesomeIcon title="Supprimer" icon={faTrash} /></Button>
        ];
    }else if (sujet.tabEtats.find(e => e.actif).libelle == "En attente de publication"){
        isPublieTxt = "Non Publié ("+sujet.tabEtats.find(e => e.actif).libelle+")";
        btnsPublication = [
                        <Button variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été publié les partenaires vont être notifé par mail de la publication', '', 'success').then(() => {
                            publierSujet.mutate(null)
                        })} className='me-2'><FontAwesomeIcon title="Publier" icon={faCheckCircle} /></Button>,
                        <Button variant={"secondary"} className="me-2" disabled={true}><FontAwesomeIcon title="Dépublier" icon={faBan} /></Button>,
                        <Button title={"brouillon"} className="me-2" variant={"secondary"} onClick={() => BtSwal.fire('Le sujet est en brouillon', '', 'success').then(() =>{
                            depublierSujet.mutate({idEtat: 2})
                        })}><FontAwesomeIcon title="brouillon" icon={faPencilAlt} /></Button>,
                        <Button title={"archiver"} className="me-2" variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été archivé', '', 'success').then(() =>{
                            depublierSujet.mutate({idEtat: 3})
                        })}><FontAwesomeIcon title="Archiver" icon={faArchive} /></Button>,

                        <Button style={{width: 55, height:43, padding: 5}} title={"censuré"} variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été censuré', '', 'success').then(() =>{
                            depublierSujet.mutate({idEtat: 4})
                        })}><img width={30} src="/icon/censure.svg"  alt={"censure"}/></Button>,
                        <Button variant={"secondary"} className={"ms-2"} onClick={() => BtSwal.fire({
                            title: 'Êtes vous sur de vouloir supprimer le sujet ?',
                            showDenyButton: true,
                            confirmButtonText: `Oui`,
                            denyButtonText: `Annuler`,
                        }).then((result) => {
                            if (result.isConfirmed){
                                deleteSujet.mutate(idSujet);
                                BtSwal.fire('Le sujet a été supprimé!', '', 'success')
                            } else if (result.isDenied) {
                                BtSwal.fire('Le sujet n\'a pas été supprimé', '', 'info')
                            }
                        })}><FontAwesomeIcon title="Supprimer" icon={faTrash} /></Button>
        ];
    }else if (sujet.tabEtats.find(e => e.actif).libelle == "Brouillon"){
        isPublieTxt = "Non Publié ("+sujet.tabEtats.find(e => e.actif).libelle+")";
        btnsPublication = [
            <Button variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été publié les partenaires vont être notifé par mail de la publication', '', 'success').then(() => {
                publierSujet.mutate(null)
            })} className='me-2'><FontAwesomeIcon title="Publier" icon={faCheckCircle} /></Button>,
            <Button className="me-2" variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été dépublié', '', 'success').then(() =>{
                depublierSujet.mutate({idEtat: 1})
            })}><FontAwesomeIcon title="Dépublier" icon={faBan} /></Button>,
            <Button title={"brouillon"} disabled={true} className="me-2" variant={"secondary"} onClick={() => BtSwal.fire('Le sujet est en brouillon', '', 'success').then(() =>{
                depublierSujet.mutate({idEtat: 2})
            })}><FontAwesomeIcon title="brouillon" icon={faPencilAlt} /></Button>,
            <Button title={"archiver"} className="me-2" variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été archivé', '', 'success').then(() =>{
                depublierSujet.mutate({idEtat: 3})
            })}><FontAwesomeIcon title="Archiver" icon={faArchive} /></Button>,

            <Button style={{width: 55, height:43, padding: 5}} title={"censuré"} variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été censuré', '', 'success').then(() =>{
                depublierSujet.mutate({idEtat: 4})
            })}><img width={30} src="/icon/censure.svg"  alt={"censure"}/></Button>,
            <Button variant={"secondary"} className={"ms-2"} onClick={() => BtSwal.fire({
                title: 'Êtes vous sur de vouloir supprimer le sujet ?',
                showDenyButton: true,
                confirmButtonText: `Oui`,
                denyButtonText: `Annuler`,
            }).then((result) => {
                if (result.isConfirmed){
                    deleteSujet.mutate(idSujet);
                    BtSwal.fire('Le sujet a été supprimé!', '', 'success')
                } else if (result.isDenied) {
                    BtSwal.fire('Le sujet n\'a pas été supprimé', '', 'info')
                }
            })}><FontAwesomeIcon title="Supprimer" icon={faTrash} /></Button>
        ];
    }else if (sujet.tabEtats.find(e => e.actif).libelle == "Archivé"){
        isPublieTxt = "Non Publié ("+sujet.tabEtats.find(e => e.actif).libelle+")";
        btnsPublication = [
            <Button variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été publié les partenaires vont être notifé par mail de la publication', '', 'success').then(() => {
                publierSujet.mutate(null)
            })} className='me-2'><FontAwesomeIcon title="Publier" icon={faCheckCircle} /></Button>,
            <Button className="me-2" variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été dépublié', '', 'success').then(() =>{
                depublierSujet.mutate({idEtat: 1})
            })}><FontAwesomeIcon title="Dépublier" icon={faBan} /></Button>,
            <Button title={"brouillon"} className="me-2" variant={"secondary"} onClick={() => BtSwal.fire('Le sujet est en brouillon', '', 'success').then(() =>{
                depublierSujet.mutate({idEtat: 2})
            })}><FontAwesomeIcon title="brouillon" icon={faPencilAlt} /></Button>,
            <Button title={"archiver"} disabled={true} className="me-2" variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été archivé', '', 'success').then(() =>{
                depublierSujet.mutate({idEtat: 3})
            })}><FontAwesomeIcon title="Archiver" icon={faArchive} /></Button>,

            <Button style={{width: 55, height:43, padding: 5}} title={"censuré"} variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été censuré', '', 'success').then(() =>{
                depublierSujet.mutate({idEtat: 4})
            })}><img width={30} src="/icon/censure.svg"  alt={"censure"}/></Button>,
            <Button variant={"secondary"} className={"ms-2"} onClick={() => BtSwal.fire({
                title: 'Êtes vous sur de vouloir supprimer le sujet ?',
                showDenyButton: true,
                confirmButtonText: `Oui`,
                denyButtonText: `Annuler`,
            }).then((result) => {
                if (result.isConfirmed){
                    deleteSujet.mutate(idSujet);
                    BtSwal.fire('Le sujet a été supprimé!', '', 'success')
                } else if (result.isDenied) {
                    BtSwal.fire('Le sujet n\'a pas été supprimé', '', 'info')
                }
            })}><FontAwesomeIcon title="Supprimer" icon={faTrash} /></Button>
        ];
    }else if (sujet.tabEtats.find(e => e.actif).libelle == "Censuré"){
        isPublieTxt = "Non Publié ("+sujet.tabEtats.find(e => e.actif).libelle+")";
        btnsPublication = [
            <Button variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été publié les partenaires vont être notifé par mail de la publication', '', 'success').then(() => {
                publierSujet.mutate(null)
            })} className='me-2'><FontAwesomeIcon title="Publier" icon={faCheckCircle} /></Button>,
            <Button className="me-2" variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été dépublié', '', 'success').then(() =>{
                depublierSujet.mutate({idEtat: 1})
            })}><FontAwesomeIcon title="Dépublier" icon={faBan} /></Button>,
            <Button title={"brouillon"} className="me-2" variant={"secondary"} onClick={() => BtSwal.fire('Le sujet est en brouillon', '', 'success').then(() =>{
                depublierSujet.mutate({idEtat: 2})
            })}><FontAwesomeIcon title="brouillon" icon={faPencilAlt} /></Button>,
            <Button title={"archiver"} className="me-2" variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été archivé', '', 'success').then(() =>{
                depublierSujet.mutate({idEtat: 3})
            })}><FontAwesomeIcon title="Archiver" icon={faArchive} /></Button>,

            <Button style={{width: 55, height:43, padding: 5}} disabled={true} title={"censuré"} variant={"secondary"} onClick={() => BtSwal.fire('Le sujet a été censuré', '', 'success').then(() =>{
                depublierSujet.mutate({idEtat: 4})
            })}><img width={30} src="/icon/censure.svg"  alt={"censure"}/></Button>,
            <Button variant={"secondary"} className={"ms-2"} onClick={() => BtSwal.fire({
                title: 'Êtes vous sur de vouloir supprimer le sujet ?',
                showDenyButton: true,
                confirmButtonText: `Oui`,
                denyButtonText: `Annuler`,
            }).then((result) => {
                if (result.isConfirmed){
                    deleteSujet.mutate(idSujet);
                    BtSwal.fire('Le sujet a été supprimé!', '', 'success')
                } else if (result.isDenied) {
                    BtSwal.fire('Le sujet n\'a pas été supprimé', '', 'info')
                }
            })}><FontAwesomeIcon title="Supprimer" icon={faTrash} /></Button>
        ];
    }
    const publierSujet = usePublierSujet(idSujet, libelleCat);
    const depublierSujet = useDepublierSujet(idSujet, libelleCat);

    return <Card className={`${showMore ? 'collapse multi-collapse '+libelleCat : ""}`}>
        <Card.Header className={`bg-hover-light-dark card-flush ${!isPublie ? "bg-tertiaire" : ""}`}
                     onClick={() => {
                         if (useGetSujetByCategorie){
                             useGetSujetByCategorie.refetch().then(data => {
                                 sujetProxie.sujet = data.data.data.find(e => e.id == sujet.id)
                             })
                         }
                         if (isPublie){
                             incrementerVue.mutate();
                         }

                         sujetProxie.showSujet(idSujet, null)
                     }}
        >
            <h3 className='card-title'>
                <div className='symbol symbol-40px pe-5'>
                    <img src={auteur.personne?.upload?.urlMiniature} alt='' />
                </div>
                <Stack gap={1} className='align-middle my-auto'>
                    <div>{title}</div>
                    <div className='fs-7 text-muted'>
                        De <span className='text-info'>{auteur.personne.prenom} {auteur.personne.nom}</span>
                    </div>
                </Stack>
            </h3>
            <div className='card-toolbar'>
                <div className='badge badge-light-warning fs-7'>le {dateCreation}</div>
                <div className={`badge fs-7 ${!isPublie ? "badge-light-danger" : "badge-light-info"}`}>{isPublieTxt}</div>
                <Row gap={2} className='text-center'>
                    <Col xs={true} md={12}>
                            <span className='fs-6 fw-bold text-muted'>
                                <FontAwesomeIcon icon={faCommentDots} className='me-2' />
                                {nbMessages} réponses
                            </span>
                    </Col>
                    <Col>
                            <span className='fs-6 fw-bold text-muted'>
                                <FontAwesomeIcon icon={faEye} className='me-2' />
                                {nbVues} vues
                            </span>
                    </Col>
                </Row>
            </div>
        </Card.Header>
        <Card.Body>
            <Row>
                <Col>
                    <div>
                        <p>{dernierMessage
                            ? <p>Dernière réponse de <span className='text-info'>{dernierMessage.auteur.personne.prenom} {dernierMessage.auteur.personne.nom}</span> le {dernierMessage.dateCreation}</p>
                            : "Aucunes nouvelles réponses"}</p></div>
                </Col>
                <Col className="text-end" md={8}>
                    <div>
                        <Button variant={"secondary"} title="Modifier sujet"
                                onClick={() => {
                                    if (useGetSujetByCategorie){
                                        useGetSujetByCategorie.refetch().then(data => {
                                            sujetProxie.sujet = data.data.data.find(e => e.id == sujet.id)
                                        })
                                    }
                                    sujetProxie.showEditSujet(idSujet, null)
                                }}
                                className='me-2'><FontAwesomeIcon icon={faEdit}
                        /></Button>
                        {btnsPublication}
                    </div>
                </Col>
            </Row>
        </Card.Body>
    </Card>;
}

export default ForumItem;