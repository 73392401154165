import { useMutation } from "react-query";
import api from "@services/api";

export default function useChangeRappelInvitation(props) {
    return useMutation(changeRappel, props);
}

export async function changeRappel(mail) {
    return api.post('emailRappelInvitation', { json: mail }).json();
}
