import useGetHeaders from "@requests/communication/header/useGetHeaders";
import { useRef } from "react";
import { Button } from "react-bootstrap";
import useChangeHeader from "@requests/communication/header/useChangeHeader";
import useUploadFile from "@requests/upload/useUploadFile";
import {showErrorAlert} from "../../../../../utils/alerts/showErrorAlert";

function HeaderSettings({ show }) {
    const headers = useGetHeaders({ enabled: show });
    const changeHeader = useChangeHeader();
    const uploadFile = useUploadFile({
        type: 'portrait',
        onSuccess: (data) => {
            changeHeader.mutate();
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    const inputFile = useRef();
    function handleUploadInput(e) {
        const file = e.target.files[0];
        uploadFile.mutate(file);
    }

    return <div>
        {headers.data?.data?.map(
            h => <img key={h.id} src={h.upload?.urlOriginale} />
        )}
        <Button
            variant='dark'
            onClick={() => inputFile.current?.click()}
        >Uploader</Button>
        <input
            type='file'
            id='file'
            ref={inputFile}
            style={{ display: 'none' }}
            accept='.png,.jpg,.jpeg'
            onChange={handleUploadInput}
        />
    </div>;
}

export default HeaderSettings;