import { faExclamationCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Button, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import auth from "@services/auth";
import { useSnapshot } from "valtio";
import mailsProxy from "../../../../proxies/mails";
import MetaTable from "../../../../components/MetaTable/MetaTable";
import { useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import moment from "moment";
import useRelanceMail from "../../../../requests/communication/mails/useRelanceMail";
import useSendMailAsTest from "../../../../requests/communication/mails/useSendMailAsTest";
import {showErrorAlert} from "../../../../utils/alerts/showErrorAlert";

function RelanceModal({ onHide, invitation }) {
    const mailsSnapshot = useSnapshot(mailsProxy);
    const { watch } = useForm();
    const search = watch('search');
    const [debouncedSearch] = useDebounce(search, 300);
    const queryClient = useQueryClient();
    const [dateEnvoi, setDateEnvoi] = useState(moment().format("DD/MM/YYYY HH:mm"));
    const snapAuth = useSnapshot(auth);

    const sendRelance = useRelanceMail({
        id: mailsSnapshot.mail?.id,
        date: dateEnvoi,
        onSuccess: () => {
            queryClient.invalidateQueries('email');
            snapAuth.websocket.send("email")

            toast.success('L\'envoi du mail de relance a été pris en compte.', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    const sendAsTest = useSendMailAsTest({
        id: mailsSnapshot.mail?.id,
        onSuccess: () => {
            queryClient.invalidateQueries('email');
            snapAuth.websocket.send("email")

            toast.success('L\'envoi du mail a été pris en compte.', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    const columns = useMemo(() => [
        {
            Header: 'Id',
            accessor: 'destinataire.personne.id',
            width: 80,
            minWidth: 60,
        },
        {
            Header: 'Prénom',
            accessor: 'destinataire.personne.prenom',
        },
        {
            Header: 'Nom',
            accessor: 'destinataire.personne.nom',
        },
        {
            Header: 'État',
            id: 'etat',
            Cell: ({ row }) => {
                if (row.original.destinataire?.personne?.email == null)
                    return <span className='text-warning'>Adresse mail inconnue</span>;
                if (row.original.destinataire?.personne?.mailIsDesinscrit)
                    return <span className='text-danger'>Ne souhaite plus recevoir de mail</span>;
                if (row.original.destinataire?.personne?.mailIsErrone)
                    return <span className='text-danger'>Adresse mail erronée</span>;
                if (row.original.dateEnvoiRelance !== null )
                    return <span className='text-success'>Dernière relance envoyé le {row.original.dateEnvoiRelance} <FontAwesomeIcon icon={faCheck} className='ms-2' /></span>;
                return <span className='text-info'>En attente d'envoi </span>
            }
        },
    ], []);



    return <Modal
        show={mailsSnapshot.relanceModal.show}
        onHide={() => mailsProxy.relanceModal.show = false}
        size='lg'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Envoyer une relance pour le mail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='d-flex mt-5'>
                <OverlayTrigger
                    key='mailTestInfo'
                    placement='right'
                    overlay={<Tooltip id='mailTestInfo'>
                        à l'adresse <span className='text-info'>{auth.utilisateur?.email}</span>
                    </Tooltip>}
                >
                    <Button onClick={sendAsTest.mutate} variant='light-info' style={{height: "fit-content"}}>
                        M'envoyer l'invitation en test
                        <FontAwesomeIcon icon={faExclamationCircle} className='ms-2' />
                    </Button>

                </OverlayTrigger>

                <div className={"d-flex flex-column ms-auto"}>
                    <Button onClick={sendRelance.mutate} >
                        Envoyer la relance
                    </Button>
                </div>


            </div>
            <div className='my-10 separator' />
            <div>
                <h3>Liste des destinataires</h3>
                <MetaTable
                    className='mt-5'
                    height={500}
                    url={`email/${mailsSnapshot.mail?.id}/correspondants`}
                    keys={['email', mailsSnapshot.mail?.id, 'correspondants']}
                    columns={columns}
                    search={debouncedSearch}
                />
            </div>
        </Modal.Body>
    </Modal>;
}

export default RelanceModal;