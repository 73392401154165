import {Button, Form} from "react-bootstrap";
import SearchBar from "@components/SearchBar/SearchBar";
import useListeCategorie from "@/requests/forum/useListeCategorie";
import ForumListSujetByCatergories from "./ForumListSujetByCatergories/ForumListSujetByCatergories";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import {useForm} from "react-hook-form";
import sujetProxie from "@proxies/sujet";
import ForumCreateViewModal from "./ForumCreate/ForumCreateViewModal";
import ForumFilter from "../ForumFilter/ForumFilter";
import ForumEtatFilter from "../ForumFilter/ForumEtatFilter";

function ForumView() {
    const [selectedCategorie, setselectedCategorie] = useState(null);
    const [filterEtat, setFilterEtat] = useState(null);
    const [filterSujetAgence, setFilterSujetAgence] = useState(null);
    const categoriesSujet = useListeCategorie(selectedCategorie ? selectedCategorie.title : "");
    const { register, watch } = useForm({
        defaultValues: {
            search: ""
        }
    });
    const search = watch('search');

    if (categoriesSujet.data){
        const postCategories = (categoriesSujet.data.data).map((value, index) =>
            <ForumListSujetByCatergories
                    key={index}
                    libelle={value.libelle}
                    id={value.id}
                    search={search}
                    nbSujetsPublie={value.nbSujetsPublie}
                    urlImageOrginal={value.upload.urlOriginale}
                    filterEtat={filterEtat ? filterEtat.id : ""}
                    filterSujetAgence={filterSujetAgence}
            >

            </ForumListSujetByCatergories
            >
        );
        return <>
            <div className="mt-5">
                <div className='d-flex flex-stack flex-wrap'>
                    <div className="d-flex flex-wrap align-items-center justify-content-center">
                        <div className="mt-3">
                            <SearchBar  {...register('search')} />
                        </div>
                        <ForumFilter className='ms-2 mt-3' selected={selectedCategorie} onChange={(value) => setselectedCategorie(value)}/>
                        <ForumEtatFilter className='ms-2 mt-3' selected={filterEtat} onChange={(value) => setFilterEtat(value)}/>
                        <div className="mt-3">
                            <Form.Check
                                className="ms-5 me-0"
                                inline
                                type='switch'
                                label=""
                                defaultChecked={false}
                                onClick={(val)  => {
                                    if (val.target.checked){
                                        setFilterSujetAgence(true)
                                    }else {
                                        setFilterSujetAgence(false);
                                    }
                                }}
                            />
                            <Form.Label className="m-0">Afficher seulement les sujets de l'agence</Form.Label>
                        </div>
                    </div>
                    <div className={"d-flex justify-content-end mt-2 mb-2"}>
                        <Button
                            onClick={() => sujetProxie.showCreateSujet(null)}
                            variant='secondary'
                        >
                            <FontAwesomeIcon icon={faPlus} className='me-2' />
                            Ajouter un sujet
                        </Button>
                    </div>
                </div>
                {postCategories}
            </div>
            <ForumCreateViewModal/>
        </>;
    }else {
        return ""
    }
}

export default ForumView;