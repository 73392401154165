import useListeCategorie from "../../../requests/forum/useListeCategorie";
import {useEffect, useState} from "react";
import Select from "react-select";

function ForumFilter({ selected, onChange, className }) {
    const categoriesSujet = useListeCategorie("");


    const [selectedValue, setSelectedValue] = useState(selected != null ? {id:selected.id,title:selected.libelle, image:selected?.upload?.urlOriginale, nbSujetsPublie:selected?.nbSujetsPublie, nbSujetsNonPublie:selected?.nbSujetsNonPublie} : null);
    const handleChange = value => {
        setSelectedValue(value);
    }


    useEffect(() => {
        setSelectedValue(selected != null ? {id:selected.id,title:selected.title, image:selected?.image, nbSujetsPublie:selected?.nbSujetsPublie, nbSujetsNonPublie:selected?.nbSujetsNonPublie} : null)
    }, [selected])

    const debouncedLoadOptions = categoriesSujet.data.data.map(exp => ({
        id: exp.id,
        title: exp.libelle,
        image:exp?.upload?.urlOriginale,
        nbSujetsPublie:exp?.nbSujetsPublie,
        nbSujetsNonPublie:exp?.nbSujetsNonPublie,
    }));

    return <Select
        className={className}
        placeholder={"Choisir une catégorie"}
        noOptionsMessage={() => "Aucun résultat"}
        loadingMessage={() => "Chargement..."}
        minLength={0}
        styles={{
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? 'white' : '#716d66',
                backgroundColor: state.isSelected ? '#4fc9da' : 'white',
                "&:hover":{
                    backgroundColor: !state.isSelected ? 'rgba(79,201,218,0.3)' : "#4fc9da",
                }
            }),
            input: (base) => ({
                ...base,
                width: "250px"
            }),
            control: (base) => ({
                ...base,
                backgroundColor : "#FFF",
                borderRadius : ".95rem",
                color: '#716d66',
            }),
        }}
        isClearable={true}
        cacheOptions
        defaultOptions={true}
        value={selectedValue}
        getOptionLabel={e => e.title}
        getOptionValue={e => e.id}
        formatOptionLabel={categorie => (
            <div className="categorie-option">
                <img width={25} src={categorie?.image} className="me-5" alt="categorie-image" />
                <span>{categorie?.title}<span className={`ms-3 badge badge-circle badge-primary me-2`}>{categorie.nbSujetsPublie}</span>{categorie.nbSujetsNonPublie > 0 &&
                    <span className="badge badge-circle badge-secondary">{categorie.nbSujetsNonPublie}</span>}</span>
            </div>
        )}
        options={debouncedLoadOptions}
        onChange={ (selected) => {
            console.log(selected)
            handleChange(selected)
            onChange(selected)
        }}
    />
}

export default ForumFilter;