import { useMutation } from "react-query";
import api from "@services/api";

export default function useChangeCGU(props) {
    return useMutation(changeCGU, props);
}

export async function changeCGU(boolCGU) {
    return api.post('utilisateur/accepteDeclarationConfidentialite', { json: {
            accepteDeclarationConfidentialite : boolCGU
    } }).json();
}
