import {useMutation, useQueryClient} from "react-query";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import baseApi from "../../../services/apiPublic";

export default function useValidInvitationByMail() {

    const queryClient = useQueryClient();

    return useMutation(validInvitation, {
        onSuccess: () => {
            queryClient.invalidateQueries('invitations');
            toast.success('L\'invitation a été validé', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function validInvitation(ref) {

    if (ref != null)
        return baseApi.post(`invitation/valider`, {json : {ref : ref}}).json();

}