import { useQuery } from "react-query";
import api from "@services/api";

export default function useGetParamsCompte() {
    return useQuery(
        ['parametresCompte'],
        async () => {
            return api.get(`stripe/parametresCompte`).json();
        }
    );
}