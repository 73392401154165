import {useEffect, useMemo, useState} from "react";
import {useSpring} from "@react-spring/web";
import Carousel from "react-spring-3d-carousel";

export default function CarousselPersonnes({dataCurrentTableParticipant, currentPerson}){
    const animateSpring = useSpring({ config:{tension: 120, friction: 14} });
    const [goToSlide, setGoToSlide] = useState(currentPerson-1);
    const [offsetRadius, setOffsetRadius] = useState(2);
    const [showNavigation, setShowNavigation] = useState(false);
    const memoSlide = useMemo(() => {
        let participantEnRetard = [];
        let participantNonEnRetard = [];
        dataCurrentTableParticipant.forEach((item, index) => {
            if (item?.infoParticipant?.tabEtatsPresence?.find(presence => presence.actif === true).id === 0){
                participantNonEnRetard.push({
                    key: index,
                    content:
                        <div
                            style={{
                                width: 200,
                                textAlign: "center",
                                padding: 20,
                                border: "1px solid #d8d8d8",
                                borderRadius: 30,
                                height: "fit-content",
                                backgroundColor: "white",
                                marginTop: 0,
                                boxShadow: "0px 5px 5px",
                            }}>
                            {item?.infoParticipant?.participant.personne.prenom + " " + item?.infoParticipant?.participant.personne.nom}
                        </div>
                })
            }else {
                participantEnRetard.push({
                    key: index,
                    content:
                        <div
                            style={{
                                width: 200,
                                textAlign: "center",
                                padding: 20,
                                border: "1px solid #d8d8d8",
                                borderRadius: 30,
                                height: "fit-content",
                                backgroundColor: "white",
                                marginTop: 0,
                                boxShadow: "0px 5px 5px",
                            }}>
                            {item?.infoParticipant?.participant.personne.prenom + " " + item?.infoParticipant?.participant.personne.nom}
                        </div>
                })
            }
        })
        let participant = [];
        participant.push(...participantNonEnRetard)
        participant.push(...participantEnRetard)
        return participant;
    }, [dataCurrentTableParticipant])
    const [slides, setSlide] = useState(memoSlide);

    useEffect(() => {
        setSlide(memoSlide)
    }, [memoSlide]);
    useEffect(() => {
        setGoToSlide(currentPerson-1)
    }, [currentPerson]);

    return (
        <div
            style={{width: "90%", height: "100px", margin: "0 auto"}}
        >
            <h2 className="text-center mt-2">C'est au tour de :</h2>
            <Carousel
                slides={slides}
                goToSlide={goToSlide}
                offsetRadius={offsetRadius}
                showNavigation={showNavigation}
                animationConfig={animateSpring}
                />
                <div
                    style={{
                        margin: "0 auto",
                        marginTop: "2rem",
                        width: "50%",
                        display: "flex",
                        justifyContent: "space-around",
                    }}
                ></div>
            </div>
        );
}