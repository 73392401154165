import {Button} from "react-bootstrap";
import $ from "jquery";
import useChangeCorrespondant from "../../../../requests/communication/correspondant/useChangeCorrespondant";
import toast from "react-hot-toast";
import {useQueryClient} from "react-query";
import ConfirmView from "./ConfirmView";
import {showErrorAlert} from "../../../../utils/alerts/showErrorAlert";


function DeclineView({dataInvitation, dataCorrespondant}) {

    let queryClient = useQueryClient();
    const changeCorrespondant = useChangeCorrespondant({
        onSuccess: () => {
            queryClient.invalidateQueries(['invitation']);
            // snapAuth.websocket.send("invitations")

            toast.success('Réponse modifié', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    function onSave() {
        changeCorrespondant.mutate(dataCorrespondant)
        $("#containerValidButton").html("<p class=\"mx-10 alert alert-success text-center\">Votre choix a bien été pris en compte.</p>")
    }

    return <>
        {!dataCorrespondant.tabPaiements[1].actif && <div className='bg-light rounded mt-3 pt-3 mx-10 pb-10'>
            <div className='mt-5 mx-10'>
                <p>Merci pour votre réponse.</p>
                <p> <strong>Vous avez décliné l'invitation {dataInvitation.type?.motDeLiaison} {dataInvitation.type?.libelle}{dataInvitation.dateEvenementFormatee.toLowerCase()}</strong></p>
            </div>
            <div id="containerValidButton" className='mt-2 d-flex justify-content-center'>
               <Button
                   variant='danger'
                   className='px-20'
                   onClick={(onSave)}
               >Confirmer votre choix</Button>
           </div>
        </div>}
        {dataCorrespondant.tabPaiements[1].actif && <div className="text-center">
            <p>Le paiement pour l'invitation à déjà été réalisé. <span className='svg-icon svg-icon-1 me-2'><img width={25} src="/icon/shield-check.svg" alt="shield-check"/></span></p>
            <ConfirmView
            isPaye={true}
            data={{
                correspondantInvitation: {...dataCorrespondant}
            }}
            />
            </div>}
    </>;

}

export default DeclineView;
