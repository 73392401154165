import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useSortBy, useTable, useResizeColumns } from "react-table";
import { useMemo } from "react";
import useGetInfinite from "./useGetInfinite";
import {Alert, Button} from "react-bootstrap";
import { useVirtual } from "react-virtual";
import BlockUI from "@components/BlockUI/BlockUI";
import { BtSwal } from '@utils/alerts/sweetAlert';

import "./style.scss";
import FileSaver from "file-saver";
import { useDatatableColumns } from "./useDatatableColumns";

const MetaTable = forwardRef(({ flex = false, setTotalRow, columns, filterForSpeed = false, keys, url, search, colSearch, tabFilter , height = 400, className, showFiltres =  false, desc = true, idSort = "id", getTrProps = () => "getTrProps", logic}, ref) => {
    const parentRef = useRef();
    const [onClickFilter, setOnClickFilter] = useState(false);
    const [sortFilter, setSortFilter] = useState({});

    // Filters
    const filters = useDatatableColumns({
        columns, colSearch, search, sortFilter, tabFilter
    });

    // Queries
    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        isSuccess,
        isError,
    } = useGetInfinite(keys, url, filters);

    const flatData = useMemo(() => {
        let res = [];
        if (data != null)
            for (let page of data.pages)
                res = res.concat(page.data);
        return res;
    }, [data]);

    // Export
    useImperativeHandle(ref, () => ({
        export: async (filename) => {
            BtSwal.fire({
                title: 'Export en cours ...',
                allowOutsideClick: false,
                didOpen: async () => {
                    BtSwal.showLoading();
                }
            });
            // Fetch all pages
            let res;
            do
                res = await fetchNextPage();
            while (res?.hasNextPage);

            let csv = '';
            let cols = columns.filter(column => column.accessor != null && !column.hidden);
            // CSV header
            csv += cols.map(col => col.Header).join(';') + '\n';

            // CSV data
            res.data.pages.forEach((page) => {
                page.data.forEach((row) => {
                    csv += cols.map(col => {
                        let value = col.accessor.split('.').reduce((o, i) => o != null ? o[i] : null, row);
                        value = exportCustomForSuiviInvitation(col, row, value);
                        if (value != null && col.Export != null)
                            return col.Export(value);
                        return value;
                    }).join(';') + '\n';
                });
            });

            // Download file
            const blob = new Blob(["\uFEFF"+csv], { type: "text/csv;charset=utf-8" });
            FileSaver.saveAs(blob, filename ?? `plink-export-${Date.now()}.csv`);

            BtSwal.close();
        }
    }));

    // Table
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        footerGroups,
        state: { sortBy },
    } = useTable(
        {
            columns,
            data: flatData,
            defaultColumn: {
                width: 150,
                minWidth: 50,
            },
            manualSortBy: true,
            initialState: {
                hiddenColumns: columns.filter(col => col.hidden).map(col => col.accessor),
                sortBy: [
                    {
                        id: idSort,
                        desc: desc
                    }
                ]
            }
        },
        useSortBy,
        useResizeColumns,
    );

    // Sort
    useEffect(() => {
        let res = {};
        sortBy.forEach((sort, index) => {
            let offset = 0;
            columns.some((col, i) => {
                if (col.accessor != null) {
                    if (col.accessor === sort.id) {
                        res[index] = {
                            column: i - offset,
                            dir: sort.desc ? 'desc' : 'asc',
                        }
                    }
                } else
                    offset++;
            });
        });

        setSortFilter(res);
    }, [sortBy, columns]);

    // List
    const rowVirtualizer = useVirtual({
        size: hasNextPage ? rows.length + 1 : rows.length,
        parentRef,
        estimateSize: useCallback(() => 77, []),
    });

    // Load more
    useEffect(() => {
        const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

        if (!lastItem) return;

        if (lastItem.index >= rows.length - 1 &&
            hasNextPage && !isFetchingNextPage)
            fetchNextPage();
    }, [
        hasNextPage,
        fetchNextPage,
        rows.length,
        isFetchingNextPage,
        rowVirtualizer.virtualItems
    ]);

    // Scroll to top on reset
    // useEffect(() => {
    //     rowVirtualizer.scrollToOffset(0);
    // }, [filters]);

    const items = rowVirtualizer.virtualItems;
    const paddingTop = items.length > 0 ? items[0].start : 0;
    const paddingBottom =
        items.length > 0
            ? rowVirtualizer.totalSize - items[items.length - 1].end
            : 0;

    const totalRows = data?.pages?.at(0)?.nbLignesTotales;

    useEffect(() => {
        if (setTotalRow){
            setTotalRow(totalRows);
        }
    }, [totalRows])

    const getSortClass = (column) => column.isSorted ? `sorting ${column.isSortedDesc ? 'sorting_desc' : 'sorting_asc'}` : '';

    return <>
        <BlockUI loading={isLoading} className={`rounded-top ${className}`}>
            <div
                className='MetaTable'
                ref={parentRef}
                style={{height: height, overflow: 'auto'}}
            >
                <table
                    {...getTableProps()}
                    className='table table-row-bordered table-hover gy-7 gs-7 dataTable'
                    style={{
                        '--virtualPaddingTop': paddingTop + 'px',
                        '--virtualPaddingBottom': paddingBottom + 'px'
                    }}
                >
                    {headerGroups.map((headerGroup, index) =>
                        <colgroup key={index}>
                            {headerGroup.headers.map((column, index) =>
                                <col
                                    key={index}
                                    style={{width: Math.min(Math.max(column.width, column.minWidth), column.maxWidth)}}
                                />
                            )}
                        </colgroup>
                    )}
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}
                            className='fw-bold fs-6 text-muted border-bottom border-gray-200'>
                            {headerGroup.headers.map(column => (
                                <th
                                    className={`bg-dark-custom position-sticky  user-select-none py-0 h-60px`}
                                    {...column.getHeaderProps()}
                                >
                                    <div className='w-100 h-100 d-relative d-flex'>
                                        <div {...column.getSortByToggleProps({title: 'Trier'})}
                                             className='d-inline-flex w-100'>
                                                <span className={`my-auto ${getSortClass(column)} text-truncate`}>
                                                    {column.render('Header')}
                                                </span>
                                        </div>
                                        {column.canResize
                                            ? <div
                                                className={`d-inline-block position-absolute end-0 top-0 resizer w-10px h-100 border-end border-2 ${column.isResizing ? 'border-info' : ''}`}
                                                {...column.getResizerProps()}
                                            />
                                            : <div
                                                className='d-inline-block position-absolute end-0 top-0 w-10px h-100 border-end border-2 cursor-not-allowed'
                                            />
                                        }
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    {isSuccess &&
                        <tbody {...getTableBodyProps()}>
                        {rowVirtualizer.virtualItems.map((virtualRow) => {
                            const isLoaderRow = virtualRow.index > rows.length - 1;
                            if (isLoaderRow)
                                return <tr key='loadingNextPage'/>;
                            const row = rows[virtualRow.index];
                            prepareRow(row);
                            return <tr {...getTrProps(row)}
                                       {...row.getRowProps()}
                            >
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}
                                               style={{whiteSpace: cell.column.noWrap ? "normal" : ""}}
                                               className={!cell.column.noWrap ? 'text-truncate' : ''}>
                                        {cell.column.displayFlex ? <div
                                            className="d-flex align-items-center">{cell.render('Cell')}</div> : cell.render('Cell')}
                                    </td>;
                                })}
                            </tr>;
                        })}
                        </tbody>
                    }
                </table>
                {isSuccess && rows.length < 1 && <div className='text-center w-100 py-10' colSpan={1000}>
                    Aucun résultat
                </div>}
                {isError && <Alert className='mt-10 mx-10' variant='danger'>{error?.message}</Alert>}
                <div className='h-10px'>
                    {isFetchingNextPage &&
                        <div className='progress-bar progress-bar-striped progress-bar-animated h-100 bg-secondary'/>}
                </div>
            </div>
            {flex && <div className='mt-3 text-gray-500 fs-7 fw-bold text-start'>Affichage de {totalRows ?? '?'} résultats</div>}
        </BlockUI>
        <div>
            {showFiltres && !filterForSpeed && footerGroups.map(group => (
                <div {...group.getFooterGroupProps()} className='bg-dark-custom fw-bold fs-6 text-muted border-bottom border-gray-200 text-center'>
                    <Button
                    className="mt-2 mb-2"
                    variant={"secondary"}
                    onClick={() => {
                            setOnClickFilter(!onClickFilter);
                    }}
                    >{onClickFilter ? "Cacher filtres" : "Voir filtres"}</Button>
                    <div className={`d-flex justify-content-between align-items-center ${!onClickFilter ? 'd-none' : ''}`}>
                        <span className={`position-sticky ps-3 user-select-none py-0 pt-3 pb-3`}>Filtres :</span>
                        {group.headers.map((column, index) => {
                            return (
                                <span
                                    className={`position-sticky  user-select-none py-0 pt-3 pb-3  `} {...column.getFooterProps()}>{column.render('Footer')}</span>
                            )
                        })}
                    </div>
                </div>
        ))}
            {showFiltres && filterForSpeed && footerGroups.map(group => (
                <div {...group.getFooterGroupProps()} className='bg-dark-custom fw-bold fs-6 text-muted border-bottom border-gray-200 text-center'>
                    <div className={`d-flex justify-content-between align-items-center`}>
                        {group.headers.map((column, index) => {
                            return (
                                <span
                                    style={{
                                        width: column.totalWidth - 10
                                    }}
                                    className={`position-sticky  user-select-none py-0 px-2 pt-3 pb-3  `} {...column.getFooterProps()}>{column.render('Footer')}</span>
                            )
                        })}
                    </div>
                </div>
            ))}
        </div>
        {!flex && <div className='mt-3 text-gray-500 fs-7 fw-bold text-start'>Affichage de {totalRows ?? '?'} résultats</div>}
    </>;
});

function exportCustomForSuiviInvitation(col, row, value) {
    if (col.accessor == "tabEtatsInvitation") {
        let objectData = row.tabEtatsInvitation.filter(column => column?.actif);
        value = objectData[0].libelle;
    }
    if (col.accessor == "tabPaiements") {
        let objectData = row.tabPaiements.filter(column => column?.actif);
        value = objectData[0].libelle;
    }
    if (col.accessor == "tabEtatsPresence") {
        let objectData = row.tabEtatsPresence.filter(column => column?.actif);
        value = objectData[0].libelle;
    }
    if (col.accessor == "optionParticipation") {
        if (row.optionParticipation.id) {
            value = "Désignation : " + row.optionParticipation.designation + " Montant : " + row.optionParticipation.montant + "€";
        } else {
            value = "Aucun choix sélectionné"
        }
    }
    if (col.accessor == "tabAccompagnants") {
        if (row.tabAccompagnants) {
            value = [];
            Object.values(row.tabAccompagnants).forEach((item) => {
                value.push(item.destinataire.personne.prenom + ' ' + item.destinataire.personne.nom + ' ' + item.destinataire.personne.email)
                value.push("\n")
            })
            value = '"' + value + '"';
        }
    }
    if (col.accessor == "etatSuivi") {
        let result = ["En attente d'envoi"];
        if (row.destinataire?.personne?.email == null)
            result = "Adresse mail inconnue";
        if (row.destinataire?.personne?.mailIsDesinscrit)
            result = "Ne souhaite plus recevoir de mail";
        if (row.destinataire?.personne?.mailIsErrone)
            result = "Adresse mail erronée";
        if (row.dateEnvoiInvitation !== null)
            result = ["Email envoyé le" + row.dateEnvoiInvitation];
        if (row.dateEnvoiRelance !== null) {
            result.push("\n");
            result.push("Email relancé le " + row.dateEnvoiRelance)
        }
        if (row.dateEnvoiRappel !== null) {
            result.push("\n");
            result.push("Rappel email le " + row.dateEnvoiRappel)
        }
        value = "\"" + result + "\""
    }
    return value;
}

export default MetaTable;