import FooterSelectionDropDown from "./FooterSelectionDropdown";

function FooterSelection({ onChange }) {
    return <FooterSelectionDropDown
        className='btn-secondary btn-sm hide-after'
        onChange={onChange}
    >
        Choisir le pied de page
    </FooterSelectionDropDown>;
}

export default FooterSelection;