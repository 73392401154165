import { useMutation } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";

export default function useGetGenererParTable() {

    return useMutation(genererParTable, {
        onError: (err) => {
            toast.error(err.message);
        },
    });
}

function genererParTable(obj) {
    if (obj.id != null)
        return api.get(`rencontreSpeedBusiness/${obj.id}/GenererParTable?utiliserParticipant=`+obj.utiliserParticipant).json();
}