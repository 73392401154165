function BlockUI({ loading, children, className }) {
    return <div className={`blockui overflow-hidden ${className ?? ''}`}>
        {children}
        {loading &&
            <div className="blockui-overlay " style={{ zIndex: 1000, backgroundColor:"darkgray", opacity: 0.85 }}>
                <div className='blockui-message'>
                    <span className='spinner-border text-primary' />
                    Chargement...
                </div>
            </div>
        }
    </div>;
}

export default BlockUI;