import { useMutation } from "react-query";
import baseApi from "../../../services/apiPublic";

export default function useChangeCorrespondantTransfere(props) {
    return useMutation(changeCorrespondantTransfere, props);
}

export async function changeCorrespondantTransfere(correspondant) {
    const id = correspondant.id;

    if (id != null)
        return baseApi.post(`correspondantInvitation/${id}/transferer`, { json: correspondant }).json();

}
