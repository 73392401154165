import { Card, Form} from "react-bootstrap";
import {BtSwal} from "../../../../../utils/alerts/sweetAlert";

function PartenaireSettings({ control, register, watch, className, errors, setValue, getValues }) {
    const visibleTrombi = watch('isVisibleInTrombinoscope');
    return <Card className={`bg-light-info card-flush ${className ?? ''}`}>
        <Card.Body>
            <h3 className='text-center'>Confidentialité de ses coordonnées dans l'annuaire de l'application mobile</h3>
            <div className='mt-7 text-center'>
                <Form.Check
                    inline
                    type='switch'
                    label="Afficher dans l'annuaire"
                    id='isVisibleInTrombinoscope'
                    {...register('isVisibleInTrombinoscope')}
                />
                {visibleTrombi && <div className='mt-5 text-center'>
                    <Form.Check
                        inline
                        label='Tel 1 visible'
                        id='tel1IsVisible'
                        onClick={() => {
                            if (!getValues('tel1')) {
                                setValue('tel1IsVisible', false)
                                BtSwal.fire("Téléphone 1 non renseigné", "", "warning")
                            }else {
                                setValue('tel2IsVisible', false)
                            }
                        }}
                        {...register('tel1IsVisible')}
                    />
                    <Form.Check
                        inline
                        label='Tel 2 visible'
                        id='tel2IsVisible'
                        onClick={() => {
                            if (!getValues('tel2')) {
                                setValue('tel2IsVisible', false)
                                BtSwal.fire("Téléphone 2 non renseigné", "", "warning")
                            }else {
                                setValue('tel1IsVisible', false)
                            }
                        }}
                        {...register('tel2IsVisible')}
                    />
                    <Form.Check
                        inline
                        label='Email visible'
                        id='emailIsVisible'
                        {...register('emailIsVisible')}
                    />
                </div>}
            </div>
            {/* <Row className='mt-10'>
                <Col sm={6}>
                    <Form.Group className='mb-10'>
                        <Form.Label>Niveau de sponsor</Form.Label>
                        <FixedValuesSlider values={['Bronze', 'Or', 'Platine', 'Diamant']} />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Niveau de décision</Form.Label>
                        <Form.Range />
                    </Form.Group>
                </Col>
            </Row> */}
        </Card.Body>
    </Card>;
}

export default PartenaireSettings;