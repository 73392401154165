import useAuthReceptionniste from "../../../requests/communication/speedBusiness/useAuthReceptionniste";
import LoadingView from "../../../views/LoadingView/LoadingView";
import {Alert, Button, Form} from "react-bootstrap";
import {getAccessToken, getRefreshToken, setTokens} from "../../../services/token";
import React, {useEffect, useMemo, useRef, useState} from "react";
import annuaireProxy from "../../../proxies/annuaire";
import { ReactComponent as Art005 } from "@assets/icons/art005.svg";
import Select2 from "../../../components/Select2/Select2";
import {useSnapshot} from "valtio";
import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";
import {useQueryClient} from "react-query";
import auth from "../../../services/auth";
import useValidPresence from "../../../requests/communication/speedBusiness/useValidPresence";
import SearchBar from "../../../components/SearchBar/SearchBar";
import MetaTable from "../../../components/MetaTable/MetaTable";
import useWebSocketPresence from "../../../views/SpeedBusinessView/PresencesReceptionnisteView/WebsocketPresences";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import ContactModal from "../../../views/AnnuaireView/ContactModal/ContactModal";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import toast from "react-hot-toast";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import useInscrireParticipant from "../../../requests/communication/speedBusiness/useInscrireParticipant";

export default function AtterissagePageForConnexionReceptionniste(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get('ref') ? urlParams.get('ref').replace(/ /g, "+") : "";
    const receptionniste = useAuthReceptionniste(ref);
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const [debouncedSearch] = useDebounce(search, 300);
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const [idEtatPresence, stateIdEtatPresence] = useState("");
    const [prenom, setPrenom] = useState("");
    const [prenomNom, setPrenomNom] = useState("");
    const [nom, setNom] = useState("");
    const validerPresence= useValidPresence();
    const websocket = useWebSocketPresence();
    const annuaireSnap = useSnapshot(annuaireProxy);
    const inscrireDestinataires = useInscrireParticipant({
        baseRoute: "rencontreSpeedBusiness",
        id: receptionniste?.data?.receptionniste?.idRencontreSpeedBusiness,
        idInvitation: false,
        filters: [],
        onSuccess: (data) => {
            if (data?.code == 266){
                BtSwal.fire("", data.message, "warning");
            }
            annuaireProxy.addAutoDestinataire = false;
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
    useEffect(() => {
        stateIdEtatPresence("")
        setPrenom("")
        setPrenomNom("")
        setNom("")
    }, []);
    const colSearch = useMemo(() => {
        return {
            'etatPresence': idEtatPresence,
            'participant.personne.prenom': prenom,
            'participant.personne.nom': nom,
            'participant.personne.prenomNom': prenomNom,
        };
    }, [idEtatPresence, prenom, nom, prenomNom]);


    const tableRef = useRef();
    const columns = useMemo(() => {

        let tmp =  [
            {
                Header: 'Id',
                accessor: 'participant.personne.id',
                minWidth: 80,
                maxWidth: 80
            },
            {
                Header: 'etatInvitation',
                accessor: 'etatInvitation',
                hidden: true
            },
            {
                Header: 'etatPresence',
                accessor: 'etatPresence',
                hidden: true
            },
            {
                Header: 'prenomNom',
                accessor: 'participant.personne.prenomNom',
                hidden: true
            },
            {
                Header: 'nomPrenom',
                accessor: 'participant.personne.nomPrenom',
                hidden: true
            },
            {
                Header: 'prenom',
                accessor: 'participant.personne.prenom',
                hidden: true,
            },
            {
                Header: 'Participant',
                accessor: 'participant.personne.nom',
                minWidth: 300,
                Cell: ({row}) => {
                    let result = [];
                    result.push(<div class="me-7">
                        <div class="symbol symbol-fixed d-flex align-items-center position-relative">
                            <img src={row.original.participant.personne?.upload?.urlMiniature} alt="image personne"/>
                            <div className="ms-3 d-flex">
                                <p className="me-2">{row.original.participant.personne.prenom + " " + row.original.participant.personne.nom}</p>
                                <a
                                    onClick={() => annuaireProxy.showPersonne(row.original.participant.personne, false, "personne", true)}
                                    className='svg-icon svg-icon-1 cursor-pointer'><Art005/></a></div>
                        </div>
                    </div>);
                    result.push()
                    // result.push((row.original.destinataire.tel1 ? row.original.destinataire.tel1 + " " : "") + row.original.destinataire.tel2 ? row.original.destinataire.tel2 : "")
                    return result;
                },
                Footer: info => {
                    return <Form.Control
                        onKeyUp={(e) => {
                            setPrenomNom(e.target.value)
                        }}
                        className={`form-control`}
                        placeholder='Rechercher prénom nom'
                    />
                },
            },
            {
                Header: 'Téléphones',
                accessor: 'participant.personne.tel1',
                minWidth: 200,
                Cell: ({row}) => {
                    let result = [];
                    result.push(row.original.participant.personne.tel1 ? row.original.participant.personne.tel1 + " " : "")
                    result.push(<br/>)
                    result.push(row.original.participant.personne.tel2 ? row.original.participant.personne.tel2 : "")
                    return result;
                },
            },
            {
                Header: 'Email',
                accessor: 'participant.personne.email',
                minWidth: 250,
            },
            {
                Header: 'Présence rencontre',
                id: 'presenceEvenement',
                accessor: 'tabEtatsPresence',
                minWidth: 240,
                maxWidth: 240,
                Cell: ({row}) => {
                    const etatsPresence = (row.original.tabEtatsPresence).map((value, index) =>
                        <label
                            key={index}
                            className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${value.actif ? `active` : ``}`}
                            data-kt-button="true">
                            <input
                                onClick={(err) => {
                                    validerPresence.mutate({
                                        id: row.original.id,
                                        idEtatPresence: value.id
                                    })
                                }}
                                className="btn-check" type="radio" name="method" value={value.id}/>
                            <span style={{fontSize: '14px'}}>{value.libelle}</span></label>
                    );

                    const etatsPresenceDisabled = (row.original.tabEtatsPresence).map((value, index) =>
                        <label
                            key={index}
                            className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success disabled ${value.actif ? `active` : ``}`}
                            data-kt-button="true">
                            <input
                                className="btn-check" type="radio" name="method" value={value.id}/>
                            <span style={{fontSize: '10px'}}>{value.libelle}</span></label>
                    );

                    return <div className="btn-group" data-kt-buttons="true"
                                data-kt-buttons-target="[data-kt-button]">
                        {true ? etatsPresence : etatsPresenceDisabled}
                    </div>
                },
                Footer: info => {
                    let tabEtatsPresenceSelect = [
                        {id: 0, text: "Présent"},
                        {id: 1, text: "Absent"},
                        {id: 2, text: "En retard"},
                    ]
                    return <Select2
                        data={tabEtatsPresenceSelect}
                        closeOnSelect={true}
                        allowClear={true}
                        placeholder='Filtre présence'
                        onChange={(selected) => {
                            selected = selected?.at(0);
                            stateIdEtatPresence(selected)
                        }}/>
                },
            },
        ]
        return tmp;
    }, [receptionniste?.data])

    useEffect(() => {
        if (receptionniste?.data){
            speedBusinessProxy.addAutoParticipant = true;
            speedBusinessProxy.speedBusiness = {}
            speedBusinessProxy.speedBusiness.id = receptionniste?.data?.receptionniste?.idRencontreSpeedBusiness;
            auth.agence = {};
            auth.agence.id = receptionniste?.data?.receptionniste?.idAgence;
            auth.utilisateur = receptionniste?.data?.receptionniste?.personne;
        }
    }, [receptionniste?.data]);

    useEffect(() => {
        if (annuaireSnap?.addAutoDestinataire){
            inscrireDestinataires.mutate({
                idPersonnes: [annuaireSnap.personne?.id],
                idProfil: 1,
                isInclusif: true,
            });
            toast.success("Le contact a été ajouté")
        }
    }, [annuaireSnap.addAutoDestinataire]);



    if (receptionniste?.isLoading){
        return <LoadingView/>
    }

    if (receptionniste?.isError){
        return <Alert variant='danger'>{receptionniste.error.message}</Alert>
    }

    if (receptionniste?.data) {
        if (receptionniste?.data?.token && !getRefreshToken()) {
            setTokens(receptionniste?.data?.token, "")
        }
        if (!websocket){
            return <LoadingView/>
        }

        return <div style={{
            width: 1100
        }} className="m-auto">
            <div className="d-flex justify-content-between">
                <h3>Liste des participants</h3>
                <Button
                    variant='primary'
                    className='me-2'
                    onClick={() => {
                        annuaireProxy.showPersonne(null, false, "personne", true)
                    }}
                    // onClick={addToDestinataires}
                >
                    <FontAwesomeIcon icon={faPlus} className='me-2' />
                    Ajouter un contact
                </Button>
            </div>
            <SearchBar
                solid
                {...register('search')}
                onClear={search?.length > 0 ? () => {
                    setValue('search', '');
                } : null}
            />
            <MetaTable
                ref={tableRef}
                className='mt-5'
                height={500}
                url={`rencontreSpeedBusiness/${receptionniste?.data?.receptionniste?.idRencontreSpeedBusiness}/participants`}
                keys={['rencontreSpeedBusiness', receptionniste?.data?.receptionniste?.idRencontreSpeedBusiness, 'participants']}
                columns={columns}
                search={debouncedSearch}
                colSearch={colSearch}
                showFiltres={true}/>
            <ContactModal/>
        </div>
    } else {
        return <LoadingView/>
    }
}