export function formatDetailsEmail(value, type) {
    switch(type){
        case "Envoi retardé":
            value.details=`Echec ${value.status} (${value.details} - ${value.output})`;
            value.icon = 'fa fa-clock';
            break;
        case "Echec de l'envoi du message":
            if(value?.status && value?.details ){
                value.details = `Echec ${value.status} (${value.details})`;
            }
            value.icon = 'fa fa-times-circle text-danger';
            value.error = true;
            break;
        case "Message en attente d'envoi":
            if(!value?.details){
                value.details = "";
            }
            value.icon = "fa fa-hourglass-half";
            break;
        case "Lien cliqué":
            if(value?.url && value?.ip_address && value?.user_agent){
                value.details=`Cliqué sur ${value.url} par ${value.ip_address} (${value.user_agent})`;
            }
            value.icon = 'fa fa-link';
            break;
        case "Message consulté":
            if(value?.ip_address && value?.user_agent){
                value.details =`Ouvert par ${value.ip_address} (${value.user_agent})`;
            }
            value.icon = 'fa fa-eye';
            break;
        case "Message envoyé":
            if(value.details && value.output){
                value.details= value.details.replace("Message for", "Message pour");
                value.details= value.details.replace("accepted by", "accepté par")+` ${value.output}`;
            }
            value.icon = 'fa fa-paper-plane';
            break;
    }
    return value;
}