import { proxy } from "valtio";

const facebookProxy = proxy({
    compteFacebook:null,
    pageFacebook: null,
    arrayIdPageFacebookForPublication: [],
    postFacebookInCreaction:null,
    previewPostFacebookModal: {
        show: false
    },
    gestionPagesModal: {
        show: false,
    },
    addFacebookPagesModal: {
        show: false,
    },
    editPage: {
        show: false,
    },
    showPreviewPostFacebook(postFacebookInCreaction){
        facebookProxy.postFacebookInCreaction = postFacebookInCreaction;
        facebookProxy.previewPostFacebookModal = {
            show: true,
        }
    },
    showGestionPages: (compte) => {
        facebookProxy.compteFacebook = compte
        facebookProxy.gestionPagesModal = {
            show: true,
        };
    },
    showAddFacebookPagesModal: () => {
        facebookProxy.addFacebookPagesModal = {
            show: true,
        };
    },
    showEditPage: (page) => {
        facebookProxy.pageFacebook = page
        facebookProxy.gestionPagesModal.show = false
        facebookProxy.editPage = {
            show: true,
        }
    }
})

export default facebookProxy;