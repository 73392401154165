import {Modal} from "react-bootstrap";
import {useSnapshot} from "valtio";
import publicationMobileProxy from "../../../../proxies/publicationMobile";
import image1 from "../../../../assets/templateBluePrint/1.png";
import {template1} from "./Templates/1";
import image2 from "../../../../assets/templateBluePrint/2.png";
import image3 from "../../../../assets/templateBluePrint/3.png";
import image4 from "../../../../assets/templateBluePrint/4.png";
import image5 from "../../../../assets/templateBluePrint/5.png";
import {template2} from "./Templates/2";
import {template3} from "./Templates/3";
import {template4} from "./Templates/4";
import {template5} from "./Templates/5";

function SelectTemplateModal() {

    const publicationMobileSnap = useSnapshot(publicationMobileProxy);

    return <Modal
        fullscreen
        show={publicationMobileSnap.selectTemplateGrapesModal.show}
        onHide={() => {
            publicationMobileProxy.selectTemplateGrapesModal.show = false;
        }}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Selection du template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="d-flex flex-wrap justify-content-around">
                <div className="w-250px">
                    <div className="card  overlay overflow-hidden">
                        <div className="card-body p-0">
                            <div className="overlay-wrapper">
                                <img src={image1} alt="" style={{width:250, height:525, objectFit:"cover"}} className="rounded"/>
                            </div>
                            <div className="overlay-layer bg-dark bg-opacity-25">
                                <a onClick={() => {
                                    publicationMobileProxy.templateSelected = template1;
                                    publicationMobileProxy.selectTemplateGrapesModal.show = false;
                                    publicationMobileProxy.showEditGrapesForPublication()
                                }} href="#" className="btn btn-primary btn-shadow">Choisir</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-250px">
                    <div className="card  overlay overflow-hidden">
                        <div className="card-body p-0">
                            <div className="overlay-wrapper">
                                <img src={image2} style={{width:250, height:525, objectFit:"cover"}} alt="" className="w-100 rounded"/>
                            </div>
                            <div className="overlay-layer bg-dark bg-opacity-25 align-items-end justify-content-center">
                                <div className="overlay-layer bg-dark bg-opacity-25">
                                    <a onClick={() => {
                                        publicationMobileProxy.templateSelected = template2;
                                        publicationMobileProxy.selectTemplateGrapesModal.show = false;
                                        publicationMobileProxy.showEditGrapesForPublication()
                                    }} href="#" className="btn btn-primary btn-shadow">Choisir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-250px">
                    <div className="card  overlay overflow-hidden">
                        <div className="card-body p-0">
                            <div className="overlay-wrapper">
                                <img src={image3} style={{width:250, height:525, objectFit:"cover"}} alt="" className="w-100 rounded"/>
                            </div>
                            <div className="overlay-layer bg-dark bg-opacity-25 align-items-start justify-content-center">
                                <div className="overlay-layer bg-dark bg-opacity-25">
                                    <a onClick={() => {
                                        publicationMobileProxy.templateSelected = template3;
                                        publicationMobileProxy.selectTemplateGrapesModal.show = false;
                                        publicationMobileProxy.showEditGrapesForPublication()
                                    }} href="#" className="btn btn-primary btn-shadow">Choisir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-250px">
                    <div className="card  overlay overflow-hidden">
                        <div className="card-body p-0">
                            <div className="overlay-wrapper">
                                <img src={image4} style={{width:250, height:525, objectFit:"cover"}} alt="" className="w-100 rounded"/>
                            </div>
                            <div className="overlay-layer bg-dark bg-opacity-25 align-items-start justify-content-center">
                                <div className="overlay-layer bg-dark bg-opacity-25">
                                    <a onClick={() => {
                                        publicationMobileProxy.templateSelected = template4;
                                        publicationMobileProxy.selectTemplateGrapesModal.show = false;
                                        publicationMobileProxy.showEditGrapesForPublication()
                                    }} href="#" className="btn btn-primary btn-shadow">Choisir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-250px">
                    <div className="card  overlay overflow-hidden">
                        <div className="card-body p-0">
                            <div className="overlay-wrapper">
                                <img src={image5} style={{width:250, height:525, objectFit:"cover"}} alt="" className="w-100 rounded"/>
                            </div>
                            <div className="overlay-layer bg-dark bg-opacity-25 align-items-start justify-content-center">
                                <div className="overlay-layer bg-dark bg-opacity-25">
                                    <a onClick={() => {
                                        publicationMobileProxy.templateSelected = template5;
                                        publicationMobileProxy.selectTemplateGrapesModal.show = false;
                                        publicationMobileProxy.showEditGrapesForPublication()
                                    }} href="#" className="btn btn-primary btn-shadow">Choisir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </Modal.Body>
    </Modal>;
}

export default SelectTemplateModal;