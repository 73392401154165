import React from 'react';
import HtmlToReact from 'html-to-react';
import { cssToReactStyle } from './cssToReactStyle';

const parser = new HtmlToReact.Parser();

export function renderSectionExport(template, content) {
    const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

    var processingInstructions = [];

    // RichTextEditor
    for (const id in content.richTexts) {
        processingInstructions.push({
            replaceChildren: true,
            shouldProcessNode: (node) => node.attribs && node.attribs['id'] === id,
            processNode: function (node, children, index) {
                // Convert dom attributes to react attributes
                var attribs = node.attribs ?? {};
                attribs.className = attribs.class;
                delete attribs.class;
                if (attribs.style != null)
                    attribs.style = cssToReactStyle(node.attribs["style"]);
                return parser.parse(content.richTexts[id]);
            },
        });
    }

    for (const id in content.blocButtonTransferes) {
        processingInstructions.push({
            replaceChildren: true,
            shouldProcessNode: (node) => node.attribs && node.attribs['id'] === id,
            processNode: function (node, children, index) {
                // Convert dom attributes to react attributes
                var attribs = node.attribs ?? {};
                attribs.className = attribs.class;
                delete attribs.class;
                if (attribs.style != null)
                    attribs.style = cssToReactStyle(node.attribs["style"]);
                return <p dangerouslySetInnerHTML={{ __html: content.blocButtonTransferes[id] }}></p>;
            },
        });
    }

    // ImageEditor
    for (const id in content.images) {
        processingInstructions.push({
            // replaceChildren: true,
            shouldProcessNode: (node) => node.attribs && node.attribs['id'] === id,
            processNode: function (node, children, index) {
                // Convert dom attributes to react attributes
                var attribs = node.attribs ?? {};
                attribs.className = attribs.class;
                delete attribs.class;
                if (attribs.style != null)
                    attribs.style = cssToReactStyle(node.attribs["style"]);

                if (content.url && Object.keys(content.images).length == 1){
                    return <a target="_blank" href={content.url}>
                        <img
                            {...node.attribs}
                            src={content.images[id]}
                            alt=''
                        />
                    </a>
                }else if (Object.keys(content.images).length > 1 && id == "leftImage" && content.leftUrl){
                    return <a target="_blank" href={content.leftUrl}>
                        <img
                            {...node.attribs}
                            src={content.images[id]}
                            alt=''
                        />
                    </a>
                }else if (Object.keys(content.images).length > 1 && id == "rightImage" && content.rightUrl){
                    return <a target="_blank" href={content.rightUrl}>
                        <img
                            {...node.attribs}
                            src={content.images[id]}
                            alt=''
                        />
                    </a>
                }
                else {
                    return <img
                        {...node.attribs}
                        src={content.images[id]}
                        alt=''
                    />
                }
            },
        });
    }

    // Remove blank text nodes
    processingInstructions.push({
        shouldProcessNode: (node) => node.type === 'text' && node.data.match(/^\s+$/),
        processNode: () => null,
    });

    // All other nodes
    processingInstructions.push({
        shouldProcessNode: () => true,
        processNode: processNodeDefinitions.processDefaultNode,
    });

    return parser.parseWithInstructions(template, () => true, processingInstructions);
}