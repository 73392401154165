import {Button, Card} from "react-bootstrap";
import ForumItem from "../ForumItem/ForumItem";
import useGetSujetByCat from "../../../../requests/forum/useGetSujetByCat";
import BlockUI from "../../../../components/BlockUI/BlockUI";
import {useEffect, useState} from "react";
import {count} from "react-table/src/aggregations";
import $ from 'jquery';

function ForumListSujetByCategories({libelle, id, search, urlImageOrginal, nbSujetsPublie, filterEtat, filterSujetAgence}) {

    const [searchObjet, setSearchObjet] = useState(search);
    const useGetSujetByCategorie = useGetSujetByCat(libelle, search, filterEtat, filterSujetAgence);
    const [voirMoins, setVoirMoins] = useState(false);

    useEffect(() => {
        if (searchObjet !== search){
            setSearchObjet(search)
            useGetSujetByCategorie.refetch()
        }
        })

    useEffect(() => {
        useGetSujetByCategorie.refetch()
    }, [filterEtat])

    useEffect(() => {
        useGetSujetByCategorie.refetch()
    }, [filterSujetAgence])


    if (useGetSujetByCategorie.isSuccess){
        let isHidden = false;
        if (useGetSujetByCategorie.data.nbLignesTotales === 0){
            isHidden = true;
        }
        if (search === ""){
            isHidden = false;
        }
        const postCategories = (useGetSujetByCategorie.data.data).map((value, index) =>
            <ForumItem
                showMore={index>2}
                key={index}
                auteur={value.auteur}
                datePublication={value.datePublication}
                dernierMessage={value.dernierMessage}
                title={value.objet}
                content={value.corps}
                idSujet={value.id}
                dateCreation={value.dateCreation}
                nbMessages={value.nbMessages}
                nbVues={value.nbVues}
                isPublie={value.tabEtats.find(e => e.actif).id == 0}
                libelleCat={libelle}
                useGetSujetByCategorie={useGetSujetByCategorie}
                sujet={value}
            >

            </ForumItem>
        );

        return <Card hidden={isHidden} className='p-8'>
            <Card.Header>
                <Card.Title>
                    <div className='symbol symbol-60px pe-5'>
                        <img src={urlImageOrginal} alt='' />
                    </div>
                    <h2>{libelle} <span className={`ms-3 badge badge-primary`}>{nbSujetsPublie} sujets</span></h2>
                </Card.Title>
            </Card.Header>
            {count(postCategories) !== 0 ? postCategories : <div className='d-flex justify-content-center align-items-center mt-2'><img className='me-2' width="100" src='/icon/noResults.png'/><b>Aucun résultat</b></div>}
            {count(postCategories) > 2 && !voirMoins &&
                <Button onClick={() => {
                    $(".collapse.multi-collapse."+libelle).removeClass(["collapse", "multi-collapse"])
                    setVoirMoins(true)
                }} className="mt-2" style={{width:"fit-content", margin:"auto"}}>Voir plus</Button>
            }
            {count(postCategories) > 2 && voirMoins &&
                <Button onClick={() => {
                    $("."+libelle).addClass(["collapse", "multi-collapse"])
                    setVoirMoins(false)
                }} className="mt-2" style={{width:"fit-content", margin:"auto"}}>Voir moins</Button>
            }
        </Card>;
    }else {
        return <BlockUI loading={true}>
            <div className="vh-100 vw-100">
            </div>
        </BlockUI>
    }


}

export default ForumListSujetByCategories;