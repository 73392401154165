import { ReactComponent as Logo } from "@assets/logo.svg";
import SendIdentifiantForm from "./SendIdentifiantForm";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";

function SendIdentifiantView() {
    const snapAuth = useSnapshot(auth);
    return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
            <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                    <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Demande d'envoi de votre identifiant</h1>
                </div>
            </div>
        </div>
        <div className='d-flex flex-column flex-lg-row-fluid py-10'>
            <div className='d-flex flex-center flex-column flex-column-fluid'>
                <div className='w-lg-500px p-10 p-lg-15 mx-auto'>
                    <SendIdentifiantForm />
                </div>
            </div>
        </div>
    </div>;
}

export default SendIdentifiantView;
