import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";

export default function useDeletePersonne() {

    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    return useMutation(deletePersonne, {
        onSuccess: () => {
            queryClient.invalidateQueries('annuaire');
            snapAuth.websocket.send("annuaire")

        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

}

function deletePersonne(id) {
    if (id != null)
        return api.delete(`personne/${id}`).json();

}