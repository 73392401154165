import { Modal, Button } from "react-bootstrap";
import { BtSwal } from "@utils/alerts/sweetAlert";
import {useRef} from "react";
import { useSnapshot } from "valtio";
import accesProxy from "@proxies/acces";
import ReglagesStep from "./ReglagesStep/ReglagesStep";
import BlockUI from '@components/BlockUI/BlockUI';
import DestinatairesStep from "../../DestinatairesStep/DestinatairesStep";
import toast from "react-hot-toast";
import useSendMail from "../../../../requests/communication/mails/useSendMail";
import moment from "moment/moment";
import {useQueryClient} from "react-query";

function EditModal() {
    const stepRef = useRef();
    const acessSnapshot = useSnapshot(accesProxy);
    const dateEnvoi = moment().format("DD/MM/YYYY HH:mm");
    const queryClient = useQueryClient();

    const send = useSendMail({
        id: acessSnapshot.mail?.id,
        date: dateEnvoi,
        onSuccess: () => {
            queryClient.invalidateQueries(['email', acessSnapshot.mail?.id, 'correspondants']);
            toast.success('Le mail a été envoyé !');
        },
    });

    const steps = [
        {
            name: 'Réglages',
            renderComponent: (ref) => <ReglagesStep ref={ref} />,
        },
        {
            name: 'Destinataires',
            renderComponent: (ref) => <DestinatairesStep baseRoute='email' id={acessSnapshot.mail?.id} onlyPartenaireProfil={true} />
        },
    ];

    async function hideValidation() {
            const result = await BtSwal.fire({
                title: 'Voulez-vous quitter ?',
                text: "Si vous quittez vous perdrez ce que vous n'avez pas enregistré",
                icon: 'question',
                showDenyButton: true,
                denyButtonText: 'Quitter',
                confirmButtonText: 'Annuler',
                customClass: {
                    confirmButton: 'btn btn-secondary',
                    denyButton: 'btn btn-danger',
                },
            });
            if (result.isDenied)
                accesProxy.editModal.show = false;

    }

    async function goToStep(step) {
        if (stepRef.current?.save != null) {
            accesProxy.editModal.loading = true;
            if (await stepRef.current.save())
                accesProxy.editModal.step = step;
            accesProxy.editModal.loading = false;
        }
        else accesProxy.editModal.step = step;
    }

    return <Modal
        fullscreen
        show={acessSnapshot.editModal.show}
        onHide={hideValidation}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <div className='w-100 stepper stepper-links d-flex flex-column between'>
                <div className='stepper-nav'>
                    {steps.map((step, index) => <div
                        className={`stepper-item ${index === acessSnapshot.editModal.step ? 'current' : ''} ${index < acessSnapshot.editModal.step ? 'completed' : ''} hoverable`}
                        key={step.name}
                        onClick={() => {
                            goToStep(index);
                        }}
                    >
                        <h3 className='stepper-title'>{step.name}</h3>
                    </div>)}
                </div>
            </div>
        </Modal.Header>
        <BlockUI loading={acessSnapshot.editModal.loading} className='overflow-scroll modal-body'>
            {steps[acessSnapshot.editModal.step]?.renderComponent(stepRef)}
        </BlockUI>
        <Modal.Footer>
            {/* TODO: Make loading indicator buttons */}
            {acessSnapshot.editModal.step > 0 && <Button
                variant='danger'
                className='me-auto'
                onClick={() => accesProxy.editModal.step--}
            >Précédent</Button>}
            {acessSnapshot.editModal.step < steps.length - 1 && <Button
                text='Suivant'
                variant='secondary'
                className='ms-auto'
                onClick={async () => {
                    goToStep(acessSnapshot.editModal.step + 1);
                }}
            >Suivant</Button>}
            {acessSnapshot.editModal.step == steps.length - 1 && <Button
            text='Envoyer'
            variant='secondary'
            className='ms-auto'
            onClick={() => {
                send.mutate();
                accesProxy.editModal.show = false;
            }}
        >Envoyer</Button>}
        </Modal.Footer>
    </Modal>;
}

export default EditModal;