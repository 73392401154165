import {ProgressBar, Step} from "react-step-progress-bar/index.mjs";
import "react-step-progress-bar/styles.css";
import "./style.css";

/**
 * Composant utilisé dans la construction de la zigzagbar voir utilisatation dans la vue presentationGlobal
 * @param numberOfStep
 * @param progressPercent
 * @param rotate
 * @param left
 * @param width
 * @param zIndex
 * @returns {JSX.Element}
 * @constructor
 */
export default function CustomProgressBar({color = "--primary-color", numberOfStep, progressPercent, rotate, left, width, zIndex}) {

    return <div style={{
        zIndex:zIndex,
        rotate: rotate+"deg",
        top:0,
        left:left,
        position:"absolute"
    }}>
        <div >
            <div style={{
                width: width
            }}>
                <ProgressBar filledBackground={"var("+color+")"} percent={progressPercent}>
                    {numberOfStep.map((e, index) => {
                        if (e != 0){
                            return <Step>
                                {({accomplished, index}) => (
                                    <div
                                        style={{
                                            rotate: -rotate+"deg",
                                            backgroundColor:accomplished && "var("+color+")"
                                        }}
                                        className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                    >
                                        {e}
                                    </div>
                                )}
                            </Step>
                        }else {
                            return <Step>
                                {({accomplished, index}) => (
                                    <div></div>
                                )}
                            </Step>
                        }
                    })}
                </ProgressBar>
            </div>

        </div>
       </div>
}