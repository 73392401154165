import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useDeleteReceptionniste() {

    const queryClient = useQueryClient();

    return useMutation(deleteReceptionniste, {
        onSuccess: () => {
            queryClient.invalidateQueries('receptionniste');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de la suppression", err.message);
        },
    });

}

function deleteReceptionniste(id) {

    if (id != null)
        return api.delete(`receptionniste/${id}`).json();

}