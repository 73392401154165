import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";

export default function useDeleteValueChampPerso() {

    const queryClient = useQueryClient();

    return useMutation(deleteValueChampPerso, {
        onSuccess: () => {
            queryClient.invalidateQueries('champPersonnaliseAnnuaire');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

}

function deleteValueChampPerso(data) {
    let id = data.id;

    if (id != null)
        return api.delete(`champPersonnaliseAnnuaire/${id}/valeur`, { json: {
                valeur: data.valeur
            } }).json();

}