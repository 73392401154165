import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";

export default function useDeleteChampPerso() {

    const queryClient = useQueryClient();

    return useMutation(deleteChampPerso, {
        onSuccess: () => {
            queryClient.invalidateQueries('champPersonnaliseAnnuaire');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

}

function deleteChampPerso(id) {

    if (id != null)
        return api.delete(`champPersonnaliseAnnuaire/${id}`).json();

}