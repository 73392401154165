import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetDestinataireResponseInvitation(search) {

    if (!search){
        search = "";
    }
    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = {
        data: "email",
        searchable: true,
        orderable: true,
        search: {
            value: search,
            regex: false,
        }
    }

    filters.columns[1] = {
        data: "id",
        searchable: true,
        orderable: true,
        search: {
            value: "",
            regex: false,
        }
    }

    filters.order[0] = {
        column:1,
        dir: "asc"
    }


    return useQuery(
        ['destinataireReponseInvitation', search],
        () => api.get('destinataireReponseInvitation', {
            searchParams: Qs.stringify({
                start: 0,
                length: 100,
                ...filters,
            },{ arrayFormat: 'brackets' })}).json(),
    );
}