import { proxy } from "valtio";

const publicationReseauxSociauxProxy = proxy({
    arrayPagesFacebookSelected: [],
    arrayPublications:[],
    arrayPagesEnregistrer:[],
    showCardPostPublicationModal: false,
    dataShowCardPostPublicationModal: null,
    currentPostCardPostPublicationModal: null,
    editPublicationReseauSocial:{
        show : false
    },
    addFacebookPagesModal: {
        show: false,
    },
    calendarReseauSocial: {
        show: false
    },
    showAddFacebookPagesModal: () => {
        publicationReseauxSociauxProxy.calendarReseauSocial = false;
        publicationReseauxSociauxProxy.addFacebookPagesModal = {
            show: true,
        };
    },
    showEditPublicationReseauSocial: () => {
        publicationReseauxSociauxProxy.calendarReseauSocial = false;
        publicationReseauxSociauxProxy.editPublicationReseauSocial = {
            show: true,
        }
    },
    showCalendarReseauSocialModal: (publications, pagesEnregistrer) => {
        publicationReseauxSociauxProxy.addFacebookPagesModal = false;
        publicationReseauxSociauxProxy.arrayPublications = publications;
        publicationReseauxSociauxProxy.arrayPagesEnregistrer = pagesEnregistrer;
        publicationReseauxSociauxProxy.calendarReseauSocial = {
            show: true
        }
    }
})

export default publicationReseauxSociauxProxy;