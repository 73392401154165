import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeOpen} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Gra012 } from "@assets/icons/gra012.svg";
import { ReactComponent as SuiviEmail } from "@assets/icons/suiviEmailV2White.svg";
import mailsProxy from "@proxies/mails";
import invitationsProxy from "@proxies/invitations";


function LastMailsSendView({email, type}) {

    return <tr>
        <td>
            <div className="symbol symbol-50px">
                <span className="symbol-label bg-gray-100">
                    <FontAwesomeIcon size={"lg"}
                                     icon={faEnvelopeOpen}/>
                </span>
            </div>
        </td>
        <td>
            <span
               className="text-gray-800 fw-bolder mb-1 fs-6">{email.sujet}</span>
            {email.dateEnvoi ?
                <span className="text-gray-800 fw-bold d-block fs-7">Date d'envoi : <span className="text-info"> {email.dateEnvoi}</span></span> :
                ""
            }
            {email.dateEvenementFormatee ?
                <span className="text-gray-800 fw-bold d-block fs-7">Date de l'évènement : <span className="text-info"> {email.dateEvenementFormatee}</span></span> :
                ""
            }

        </td>
        <td className="text-end">
            {email.dateEnvoi ?
                <span className="badge badge-light-success fs-7 fw-bolder">Envoyé</span> :
                <span className="badge badge-light-info fs-7 fw-bolder">En attente d'envoi</span>
            }

        </td>
        <td>
            {type === 'mail' ?
                <span title="Satistiques email"
                    onClick={() => mailsProxy.showStatMail(email)}
                    className="btn btn-quaternaire p-2 pe-0"><span className='svg-icon svg-icon-1 me-2'><Gra012 /></span></span> :
                <span title="Suivi invitation"
                    onClick={() => invitationsProxy.showSuiviInvitation(email)}
                    className="btn btn-quaternaire p-2"><span color={"white"} className='svg-icon svg-icon-1 me-2'><SuiviEmail /></span></span> }
        </td>
    </tr>

}

export default LastMailsSendView;
