import image from './icons/twoImages.svg';

export const twoImagesModule = {
    image: image,
    defaultContent: {
        images: {
            leftImage: 'https://placehold.co/520x520/EEE/31343C',
            rightImage: 'https://placehold.co/520x520/EEE/31343C',
        },
    },
    template:
        `<tr id="colorPicker">
            <td valign="top" align="center">
                <table width="100%" cellspacing="0" cellpadding="0" border="0">
                    <tbody><tr>
                        <td valign="top" align="center">
                            <table class="flexibleContainer" width="600" cellspacing="0" cellpadding="0" border="0">
                                <tbody><tr>
                                    <td class="flexibleContainerCell" id="paddingTemplate"  width="600" valign="top" align="center">
                                        <table class="flexibleContainer" width="260" cellspacing="0" cellpadding="0" border="0" align="Left">
                                            <tbody><tr>
                                                <td class="imageContent" valign="top" align="Left">
                                                    <img id="leftImage" src="http://placekitten.com/g/520/520" class="flexibleImage" style="max-width:260px;" width="260">
                                                </td>
                                            </tr>
                                        </tbody></table>
                                        <table class="flexibleContainer" width="260" cellspacing="0" cellpadding="0" border="0" align="Right">
                                            <tbody><tr>
                                                <td class="imageContentLast" valign="top" align="Left">
                                                    <img id="rightImage" src="http://placekitten.com/g/520/520" class="flexibleImage" style="max-width:260px;" width="260">
                                                </td>
                                            </tr>
                                        </tbody></table>
                                    </td>
                                </tr>
                            </tbody></table>
                        </td>
                    </tr>
                </tbody></table>
            </td>
        </tr>`,
} 