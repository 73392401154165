import { useMutation } from "react-query";
import api from "@services/api";

export default function useSendMailAsTest({ id }, props) {
    return useMutation(() => {
        return api.post(`email/${id}/envoyerEnTest`, {
            retry: {
                limit : 0
            }
        }).json();
    }, props);
}