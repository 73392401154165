import { Card, Form } from "react-bootstrap";
import ProfilSelection from "../../../ProfilSelection/ProfilSelection";

function PartenaireSettings({ className, register, control, setValue, watch }) {
    const visibleTrombi = watch('isVisibleInAnnuaire');

    return <>
        <Form.Group className='mt-5 mb-5'>
            <Form.Label>Profils partenaire</Form.Label>
            <ProfilSelection
                control={control}
                name='tabProfils'
                isEntreprise={true}
            />
        </Form.Group>
        <Card className={`bg-light-info card-flush ${className ?? ''}`}>
        <Card.Body>
            <h3 className="text-center">Confidentialité de ses coordonnées dans l'annuaire de l'application mobile</h3>
            <div className="d-flex justify-content-center">
                <Form.Check
                    className='mt-5'
                    type='switch'
                    label="Afficher dans l'annuaire"
                    id={'entrepriseTrombi'}
                    {...register('isVisibleInAnnuaire')}
                />
            </div>
            {visibleTrombi && <div className='mt-5 text-center'>
                <Form.Check
                    inline
                    label='Tel 1 visible'
                    id='tel1IsVisible'
                    onClick={() => {
                        setValue('tel2IsVisible', false)
                    }}
                    {...register('tel1IsVisible')}
                />
                <Form.Check
                    inline
                    label='Tel 2 visible'
                    id='tel2IsVisible'
                    onClick={() => {
                        setValue('tel1IsVisible', false)
                    }}
                    {...register('tel2IsVisible')}
                />
            </div>}
        </Card.Body>
    </Card></>;
}

export default PartenaireSettings;