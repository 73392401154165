import { proxy } from "valtio";

const motsClesProxy = proxy({
    motsCles:[
        {
            type: 'menuitem',
            text: 'prenom',
            insert : '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
        },
        {
            type: 'menuitem',
            text: 'nom',
            insert : '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
        },
        {
            type: 'menuitem',
            text: 'email',
            insert : '<span contenteditable="false" class="mceNonEditable">{email}</span>'
        },
        {
            type: 'menuitem',
            text: 'civilite',
            insert : '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
        },
        {
            type: 'menuitem',
            text: 'te/vous',
            insert : '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
        },
        {
            type: 'menuitem',
            text: 'toi/vous',
            insert : '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
        },
        {
            type: 'menuitem',
            text: 'ta/votre',
            insert : '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
        },
        {
            type: 'menuitem',
            text: 'ton/votre',
            insert : '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
        },
        {
            type: 'menuitem',
            text: 'tes/vos',
            insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
        },

    ]
})

export default motsClesProxy;