import {Modal} from "react-bootstrap";
import {useSnapshot} from "valtio";
import facturationProxy from "../../../proxies/facturation";
import React, {useState} from "react";
import useFilterIntoTwoDates from "../../../requests/facturation/useFilterIntoTwoDates";
import toast from "react-hot-toast";
import Flatpickr from "react-flatpickr";
import moment from "moment/moment";
import {French} from "flatpickr/dist/l10n/fr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import {BtSwal} from "../../../utils/alerts/sweetAlert";

function TelechargementFactureModal() {

    const facturationSnap = useSnapshot(facturationProxy);
    const [tabDates, setTabDates] = useState(null);
    const downloadURI = (uri, name) => {
        const link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const dlFactureIntoTwoDate = useFilterIntoTwoDates({
        onSuccess: (res) => {
            toast.success('Téléchargement réussi', {
                duration:5000
            });
            downloadURI(res.url, 'factures.zip')
        },
        onError: (err) => {
            toast.error(err.message, {
                duration:5000
            });
        },
    });


    return <Modal
        show={facturationSnap.showTelechargementFacturationModal}
        onHide={() => {
            facturationProxy.showTelechargementFacturationModal = false;
        }}
        contentClassName="w-800px"
    >
        <Modal.Header
            closeButton
        >
            <h2>Téléchargement des factures</h2>
        </Modal.Header>
        <Modal.Body className=""
                    style={{width:800}}

        >
            <div className="d-flex justify-content-center">
                <p className="alert alert-primary m-0 me-2">Choisir une période</p>
                <Flatpickr
                    className={"form-control w-300px me-2"}
                    // defaultValue={dateEnvoi}
                    onChange={date => {
                        let tmpTabDates = [];
                        console.log(date)
                        if (date.length == 2){
                            tmpTabDates.push(moment(date[0]).format("MM/YYYY"))
                            tmpTabDates.push(moment(date[1]).format("MM/YYYY"))
                            setTabDates(tmpTabDates)
                        }else if (date.length == 1) {
                            tmpTabDates.push(moment(date[0]).format("MM/YYYY"))
                            tmpTabDates.push(moment(date[0]).format("MM/YYYY"))
                            setTabDates(tmpTabDates)
                        }

                    }}
                    options={{
                        mode: "range",
                        locale: French,
                        plugins: [
                            new monthSelectPlugin({
                                shorthand: true, //defaults to false
                                dateFormat: "m/Y", //defaults to "F Y"
                                // altFormat: "F Y", //defaults to "F Y"
                                // theme: "dark" // defaults to "light"
                            })
                        ],
                        // dateFormat: 'm/Y',
                        // enableTime: false,
                    }}
                />
                <button onClick={() => {
                    if (tabDates) {
                        dlFactureIntoTwoDate.mutate(tabDates);
                    }else {
                        BtSwal.fire("Veuillez sélectionner une plage de mois pour l'export des factures.", "", "info")
                    }
                }} className="btn btn-secondary fa fa-download">

                </button>
            </div>

        </Modal.Body>
    </Modal>;
}

export default TelechargementFactureModal;