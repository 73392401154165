import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useSetParametresAgence() {
    const queryClient = useQueryClient();
    return useMutation(parametres => {
        return api.post('parametreAgence', {
            json: parametres,
        }).json();
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('parametreAgence');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}