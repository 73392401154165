import { Button, Modal } from "react-bootstrap";
import useChangeAgence from '@requests/utilisateur/useChangeAgence';
import BlockUI from '@components/BlockUI/BlockUI';
import { useSnapshot } from 'valtio';
import auth from '@services/auth';
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {useEffect} from "react";

function ChangeAgenceModal({ show, onHide }) {
    const snap = useSnapshot(auth);
    const changeAgence = useChangeAgence();
    const {agence} = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        if (changeAgence.isSuccess){
            navigate("/"+snap?.agence?.dossierUpload+"/accueil");
            if (agence != snap?.agence?.dossierUpload){
                window.location.reload();
            }
        }
    })

    return <Modal
        show={show}
        onHide={onHide}
    >
        <Modal.Header closeButton>
            <Modal.Title>Changer d'agence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <BlockUI loading={changeAgence.isLoading}>
                <div className='d-flex flex-column gap-3'>
                    {Object.values(snap.utilisateur?.tabAgences).map(value => <Button
                        key={value.id}
                        variant={value.id === snap.agence.id ? 'primary' : 'light'}
                        disabled={value.id === snap.agence.id}
                        onClick={() => {
                            changeAgence.mutate(value);
                        }}
                    >
                        {value.nom}
                    </Button>)}
                </div>
            </BlockUI>
        </Modal.Body>
    </Modal>
}

export default ChangeAgenceModal;