import { useController } from "react-hook-form";
import Select2 from "@components/Select2/Select2";

function CiviliteSelection({ control, name, rules, isSolid = true }) {
    const { field } = useController({ control, name, rules });

    return <Select2 solid={isSolid}
                    data={["Monsieur", "Madame"]}
                    selected={field.value != null ? field.value : null}
                    minimumResultsForSearch={3}
                    placeholder='Choisir la civilité'
                    onChange={(selected) => {
                        field.onChange(selected.toString());
                    }}
    />
}

export default CiviliteSelection;