import useGetAgenceAfterAuth from "../../../requests/communication/reseauxSociaux/useGetAgenceAfterAuth";
import {Spinner} from "react-bootstrap";
import React from "react";
import LoadingView from "../../LoadingView/LoadingView";

export default function OauthRedirect(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code') ? urlParams.get('code').replace(/ /g, "+") : "";
    const state = urlParams.get('state') ? urlParams.get('state').replace(/ /g, "+") : "";
    const agence = useGetAgenceAfterAuth(state);

    if (agence.isLoading){
        return <div className='text-center'><Spinner animation='border' /></div>;
    }else {
        let base_url = window.location.origin;
        document.location.href = base_url+"/"+agence?.data?.agence+'/compteLinkedin/oauth?code='+code+'&state='+state;
    }

    return <LoadingView/>

}