import useGetLinkPreview from "../../requests/communication/reseauxSociaux/useGetLinkPreview";
import {Alert, Spinner} from "react-bootstrap";

function FacebookLinkPreview({lien}){

    const dataLinkPreview = useGetLinkPreview(lien);

    if (dataLinkPreview.isLoading || dataLinkPreview.isError){
        if (dataLinkPreview?.error?.code == 418){
            return <Alert variant='danger' className='m-2'>
                Affichage de l'aperçu bloqué par le site.
            </Alert>
        }
        return <div className='text-center'><Spinner animation='border' /></div>;
    }

    if(dataLinkPreview?.data?.imageOg){

        return <div style={{width:"95%", border: "solid 1px gray", borderRadius: 10}} className="m-auto">
            <div style={{backgroundImage:"url("+(dataLinkPreview?.data?.imageOg ? dataLinkPreview?.data?.imageOg : "")+")", backgroundSize:"contain", height:261, borderRadius: "10px 10px 0 0", width:"100%"}}></div>
            <div className="" style={{margin:0,padding:10, height:78, width: "100%", backgroundColor:"#f0f2f5", overflow: "hidden"}}>
                <p className="m-0">{(dataLinkPreview?.data?.url).split("/")[2].replace("www.", "").toUpperCase()}</p>
                <h2 className="m-0 p-0">{dataLinkPreview?.data?.titre}</h2>
                <p style={{textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden"}} className="m-0">{dataLinkPreview?.data?.description}</p>
            </div>
        </div>

    }else {

        return <div style={{width:"95%", border: "solid 1px gray", borderRadius: 10}} className="m-auto">
            <div className="" style={{margin:0,padding:10, height:78, width: "100%", backgroundColor:"#f0f2f5"}}>
                <p className="m-0">{(dataLinkPreview?.data?.url).split("/")[2].replace("www.", "").toUpperCase()}</p>
                <h2 className="m-0 p-0">{dataLinkPreview?.data?.titre}</h2>
                <p style={{textOverflow:"ellipsis", whiteSpace:"nowrap", overflow:"hidden"}} className="m-0">{dataLinkPreview?.data?.description}</p>
            </div>
        </div>
    }


}

export default FacebookLinkPreview;