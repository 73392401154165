import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useInscrireParticipant({ baseRoute, id, filters, idInvitation = false, ...props }) {
    const queryClient = useQueryClient();
    if (idInvitation){
        return useMutation(
            ({ idPersonnes, idProfil, isInclusif }) => {
                return api.post(
                    `${baseRoute}/${id}/inscrireParticipants`,
                    {
                        json: {
                            tabIdsPersonne: idPersonnes,
                            idProfil: idProfil ?? 1,
                            isInclusif,
                            tabFiltres: filters,
                            idInvitation:idInvitation
                        },
                    },
                ).json();
            },
            {
                ...props,
                onSuccess: (data) => {
                    queryClient.invalidateQueries([baseRoute, id, 'participants']);
                    if (props?.onSuccess)
                        props.onSuccess(data);
                },
                onError: (err) => {
                    showErrorAlert("Erreur lors de l'enregistrement", err.message);
                },
            }
        );
    }else {
        return useMutation(
            ({ idPersonnes, idProfil, isInclusif }) => {
                return api.post(
                    `${baseRoute}/${id}/inscrireParticipants`,
                    {
                        json: {
                            tabIdsPersonne: idPersonnes,
                            idProfil: idProfil ?? 1,
                            isInclusif,
                            tabFiltres: filters,
                        },
                    },
                ).json();
            },
            {
                ...props,
                onSuccess: (data) => {
                    queryClient.invalidateQueries([baseRoute, id, 'participants']);
                    if (props?.onSuccess)
                        props.onSuccess(data);
                },
                onError: (err) => {
                    showErrorAlert("Erreur lors de l'enregistrement", err.message);
                },
            }
        );
    }
}