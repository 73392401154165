import image from './icons/choixObligatoire.svg';

export const choixObligatoire = {
    image: image,
    defaultContent: ``,
    template:
        `<tr id="colorPicker">
                <td valign="top" align="center">
                    <table width="100%" cellspacing="0" cellpadding="0" border="0">
                        <tbody><tr>
                            <td valign="top" align="center">
                                <table class="flexibleContainer" width="600" cellspacing="0" cellpadding="0" border="0">
                                    <tbody><tr>
                                        <td class="flexibleContainerCell" id="paddingTemplate" width="600" valign="top" align="center">
                                            <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                                <tbody><tr>
                                                    <td id="blocReponseInvitation" class="textContent" valign="top">
                                                           {blocReponseInvitation}
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                        </td>
                                    </tr>
                                </tbody></table>
                            </td>
                        </tr>
                    </tbody></table>
                </td>
            </tr>
        `,
}