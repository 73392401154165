import { useMutation } from "react-query";
import api from "@services/api";

export default function useChangeMailAccesPartenaire(props) {
    return useMutation(changeAcces, props);
}

export async function changeAcces(mail) {
    return api.post('emailEnvoiIdentifiant', { json: mail }).json();
}
