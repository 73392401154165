import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useDeletePublicationReseauSocial() {

    const queryClient = useQueryClient();

    return useMutation(deletePublication, {
        onSuccess: () => {
            queryClient.invalidateQueries('publicationReseauSocial');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

}

function deletePublication(id) {

    if (id != null)
        return api.delete(`publicationReseauSocial/${id}`).json();

}