import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetChampPerso(search) {

    if (!search){
        search = "";
    }
    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = {
        data: "libelle",
        searchable: true,
        orderable: true,
        search: {
            value: "",
            regex: false,
        }
    }

    filters.search = {
        value:search
    }


    filters.order[0] = {
        column:0,
        dir: "asc"
    }

    return useQuery(
        ['champPersonnaliseAnnuaire', search],
        () => api.get('champPersonnaliseAnnuaire', {
            searchParams: Qs.stringify({
                start: 0,
                length: 100,
                ...filters,
            },{ arrayFormat: 'brackets' })}).json(),
    );
}