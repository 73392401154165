import { Modal, Button } from "react-bootstrap";
import { BtSwal } from "@utils/alerts/sweetAlert";
import {useMemo, useRef, useState} from "react";
import { useSnapshot } from "valtio";
import BlockUI from '@components/BlockUI/BlockUI';
import speedBusinessProxy from "../../../proxies/speedBusiness";
import ContactModal from "../../../views/AnnuaireView/ContactModal/ContactModal";
import EmailLienMaitreDuTemps
    from "../../../views/SpeedBusinessView/CheckMaitreDuTempsTutoAndSendMailModal/Steps/EmailLienMaitreDuTemps";
import TutorielEtPresencesMaitreDuTemps
    from "../../../views/SpeedBusinessView/CheckMaitreDuTempsTutoAndSendMailModal/Steps/TutorielEtPresencesMaitreDuTemps";

function CheckMaitreDuTempsTutoAndSendMailModal() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const stepRef = useRef();
    const speedSnap = useSnapshot(speedBusinessProxy);
    const steps = useMemo(() => {
        let tmpSteps = [
            {
                name: 'Présences',
                renderComponent: (ref) =>  <TutorielEtPresencesMaitreDuTemps ref={ref}/>
            },
            {
                name: 'Envoi lien maitre du temps',
                renderComponent: (ref) =>  <EmailLienMaitreDuTemps baseRoute='rencontreSpeedBusiness' id={speedSnap.speedBusiness?.id}  ref={ref}/>
            },

        ]
        return tmpSteps;
    }, [speedSnap?.speedBusiness])

    async function hideValidation() {
        const result = await BtSwal.fire({
            title: 'Voulez-vous quitter ?',
            text: "Si vous quittez vous perdrez ce que vous n'avez pas enregistré",
            icon: 'question',
            showDenyButton: true,
            denyButtonText: 'Quitter',
            confirmButtonText: 'Annuler',
            customClass: {
                confirmButton: 'btn btn-secondary',
                denyButton: 'btn btn-danger',
            },
        });
        if (result.isDenied){
            speedBusinessProxy.showMaitreDuTempsTutoAndSendMailModal = false;
            speedBusinessProxy.showLancementSpeedBusinessModal = true;
        }
        // queryClient.invalidateQueries('emails');
    }

    async function goToStep(step) {
        setCurrentIndex(step);
        if (stepRef.current?.save != null) {
            speedBusinessProxy.loading = true;
            if (await stepRef.current.save()) {
                speedBusinessProxy.step = step;
            }
            speedBusinessProxy.loading = false;
        }else {
            speedBusinessProxy.step = step;
        }
    }

    return <Modal
        fullscreen
        show={speedSnap.showMaitreDuTempsTutoAndSendMailModal}
        onHide={hideValidation}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <div className='w-100 stepper stepper-links d-flex flex-column between'>
                <div className='stepper-nav'>
                    {steps.map((step, index) => <div
                        className={`stepper-item ${index === speedSnap.step ? 'current' : ''} ${index < speedSnap.step ? 'completed' : ''} hoverable`}
                        key={step.name}
                        // onClick={() => {
                        //     goToStep(index);
                        // }}
                    >
                        <h3 className='stepper-title'>{step.name}</h3>
                    </div>)}
                </div>
            </div>
        </Modal.Header>
        <BlockUI loading={speedSnap.loading} className='overflow-scroll modal-body'>
            {steps[speedSnap.step]?.renderComponent(stepRef)}
        </BlockUI>
        <Modal.Footer>
            {/* TODO: Make loading indicator buttons */}
            {speedSnap.step > 0 && <Button
                variant='danger'
                className='me-auto'
                onClick={() => speedBusinessProxy.step = speedSnap.step -1 }
            >Précédent</Button>}
            {speedSnap.step < steps.length - 1 && <Button
                text='Suivant'
                variant='secondary'
                className='ms-auto'
                onClick={async () => {
                    goToStep(speedSnap.step + 1);
                }}
            >Suivant</Button>}
            {speedSnap.step == steps.length - 1 && <Button
                text='Suivant'
                variant='secondary'
                className='ms-auto'
                onClick={async () => {
                    speedBusinessProxy.showMaitreDuTempsTutoAndSendMailModal = false;
                    speedBusinessProxy.showLancementSpeedBusinessModal = true;
                }}
            >Terminer</Button>}
        </Modal.Footer>
        <ContactModal/>
    </Modal>;
}

export default CheckMaitreDuTempsTutoAndSendMailModal;