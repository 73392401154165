import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useDeleteInvitation() {

    const queryClient = useQueryClient();

    return useMutation(deleteInvitation, {
        onSuccess: () => {
            queryClient.invalidateQueries('invitations');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

}

function deleteInvitation(id) {

    if (id != null)
        return api.delete(`invitation/${id}`).json();

}