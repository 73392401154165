import { useMutation } from "react-query";
import api from "@services/api";
import auth from "@services/auth";

export default function useChangeHeader(props) {
    return useMutation(changeHeader, props);
}

function changeHeader(header) {
    const id = header?.id;
    const idAgence = auth.agence?.id;

    const params = { ...header, idAgence };

    if (id != null)
        return api.put(`headerEmail/${id}`, { json: params }).json();
    return api.post('headerEmail', { json: params }).json();
}