import React, {useMemo, useState} from "react";
import {Button, Card} from "react-bootstrap";
import { ReactComponent as Art005 } from "@assets/icons/art005.svg";
import MetaTable from "../../../components/MetaTable/MetaTable";
import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";
import SearchBar from "../../../components/SearchBar/SearchBar";
import annuaireProxy from "../../../proxies/annuaire";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import {ReactComponent as Gen016} from "@assets/icons/gen016.svg";
import useResendMailRappelForOneCorrespondant
    from "../../../requests/communication/speedBusiness/useResendMailRappelForOneCorrespondant";
import useSendMailsRencontre from "../../../requests/communication/speedBusiness/useSendMailsRencontre";
import moment from "moment/moment";
import Flatpickr from "react-flatpickr";
import {French} from "flatpickr/dist/l10n/fr";

export default function MailRappelStep({baseRoute, id}){
    const { register, watch, setValue } = useForm();
    const searchDestinataire = watch('searchDestinataire');
    const [debouncedSearchDestinataire] = useDebounce(searchDestinataire, 300);
    const sendEmailRappelOneCorrespondant = useResendMailRappelForOneCorrespondant();
    const sendMailRappel = useSendMailsRencontre();
    const [dateEnvoi, setDateEnvoi] = useState(moment().format("DD/MM/YYYY à HH:mm"));
    const destinatairesColumns = useMemo(() => [
        {
            Header: () => "",
            id: 'wrench',
            Cell: ({ row }) => <div className="d-flex justify-content-center">
                <a
                    onClick={() => annuaireProxy.showPersonne(row.original.participant.personne, false, "personne", true)}
                    className='svg-icon svg-icon-1 cursor-pointer'><Art005 /></a></div>,

            width: 60,
        },
        {
            Header: 'Id',
            accessor: 'participant.personne.id',
            width: 80,
        },
        {
            Header: 'Prenom',
            accessor: 'participant.personne.prenom',
        },
        {
            Header: 'Nom',
            accessor: 'participant.personne.nom',
        },
        {
            Header: 'Email',
            accessor: 'participant.personne.email',
            width: 250,
        },
        {
            Header: 'Téléphones',
            accessor: 'participant.personne.tel1',
            width: 250,
            Cell: ({row}) => {
                let result = [];
                result.push(row.original.participant.personne.tel1 ? row.original.participant.personne.tel1 + " " : "")
                result.push(<br/>)
                result.push(row.original.participant.personne.tel2 ? row.original.participant.personne.tel2 : "")
                return result;
            }
        },
        {
            Header: 'Envoyer email rappel',
            id: 'rappelMail',
            minWidth: 190,
            Cell: ({row}) => {
                return <>
                    <div style={{
                        border: "solid 1px black",
                        padding: 2,
                        width: 190
                    }} title={"envoyer mail de rappel"}
                         onClick={() => {
                             BtSwal.fire({
                                 title: `<b>Êtes-vous sûr de vouloir envoyer le mail de rappel à ${row.original.participant.personne.prenom} ${row.original.participant.personne.nom} ?</b>`,
                                 showDenyButton: true,
                                 confirmButtonText: `Oui`,
                                 denyButtonText: `Annuler`,
                                 icon: 'info',
                             }).then((result) => {
                                 if (result.isConfirmed) {
                                     sendEmailRappelOneCorrespondant.mutate(row.original.id)
                                 } else if (result.isDenied) {
                                     BtSwal.fire('Le mail n\'a pas été envoyé.', '', 'info')
                                 }
                             })
                         }}
                         className="cursor-pointer">
                        <span style={{
                            fill: "black",
                        }} className='svg-icon svg-icon-1 me-2'><Gen016/> Email de rappel</span></div>
                </>;
            },
        },
    ], []);
    return <Card className='card-flush'>
        <Card.Body>
            <h4 className='text-center'>Participants</h4>
            <div className='d-flex justify-content-between'>
                <div style={{
                    height: "fit-content"
                }}>
                    <SearchBar
                        solid
                        {...register('searchDestinataire')}
                        onClear={searchDestinataire?.length > 0 ? () => {
                            setValue('searchDestinataire', '');
                        } : null}
                    />
                </div>
                <Button style={{
                    height: "fit-content"
                }} onClick={() => {
                    BtSwal.fire({
                        title: `<b>Êtes-vous sûr de vouloir envoyer le mail de rappel à tous les participants ?</b>`,
                        showDenyButton: true,
                        confirmButtonText: `Oui`,
                        denyButtonText: `Annuler`,
                        icon: 'info',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            sendMailRappel.mutate({
                                id: id,
                                type: 'emailRappel',
                                dateProgrammationEnvoi: dateEnvoi,
                            })
                        } else if (result.isDenied) {
                            BtSwal.fire('Le mail n\'a pas été envoyé.', '', 'info')
                        }
                    })
                }}>Envoyer le mail de rappel à tous les participants</Button>
                <div style={{
                }}>
                    <span>Heure programmation mail</span><Flatpickr
                        className={"form-control mt-2"}
                        display={"hidden"}
                        defaultValue={dateEnvoi}
                        onChange={date => {
                            setDateEnvoi(moment(date[0]).format("DD/MM/YYYY à HH:mm"));
                        }}
                        options={{
                            locale: French,
                            dateFormat: 'd/m/Y à H:i',
                            enableTime: true,
                        }}
                    />
                </div>
            </div>
            <MetaTable
                className='mt-5'
                height={450}
                url={`${baseRoute}/${id}/participants`}
                keys={[baseRoute, id, 'participants']}
                columns={destinatairesColumns}
                search={debouncedSearchDestinataire}
            />
        </Card.Body>
    </Card>;
}