import {Alert, Button, Modal, Spinner, Tab, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useSnapshot} from "valtio";
import facebookProxy from "../../../proxies/facebook";
import useGetPagesEnregistrer from "../../../requests/communication/reseauxSociaux/facebook/useGetPagesEnregistrer";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
// todo bien vérifier la page !!!
function AddFacebookPagesModal() {
    const facebookSnap = useSnapshot(facebookProxy);
    const pagesEnregistrer = useGetPagesEnregistrer("");
    const [hoverPage, setHoverPage] = useState(null);
    const [arrayPageEnregisterClicked, setArrayPageEnregisterClicked] = useState(facebookSnap.arrayIdPageFacebookForPublication);

    useEffect(() => {

        setArrayPageEnregisterClicked(facebookSnap.arrayIdPageFacebookForPublication)

    }, [facebookSnap.arrayIdPageFacebookForPublication])

    if (pagesEnregistrer.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    if (pagesEnregistrer.isError)
        return <Modal
            show={facebookSnap.addFacebookPagesModal.show}
            onHide={() => facebookProxy.addFacebookPagesModal.show = false}
            size='lg'
            enforceFocus={false}
        ><Modal.Header closeButton>
            <Modal.Title>Sélection des pages facebook</Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <Alert variant='danger'>{pagesEnregistrer.error.message}</Alert>
            </Modal.Body>
        </Modal>

    return <Modal
        show={facebookSnap.addFacebookPagesModal.show}
        onHide={() => facebookProxy.addFacebookPagesModal.show = false}
        size='lg'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Sélection des pages facebook</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="d-flex justify-content-center flex-wrap align-items-center">
                    {pagesEnregistrer?.data?.data.map(page => {
                        return <div className={`card w-205px h-155px mb-5 border-3 me-5 z-index-1 cursor-pointer ${(page.ref === hoverPage && !arrayPageEnregisterClicked.find(e => e.ref === page.ref)) && "border-dark" } ${((page.ref === hoverPage && arrayPageEnregisterClicked.find(e => e.ref === page.ref)) || (page.ref !== hoverPage && arrayPageEnregisterClicked.find(e => e.ref === page.ref))) && "border-3 border border-dark" }`}
                                    onMouseEnter={(e) => {
                                        setHoverPage(page.ref)
                                    }}
                                    onMouseLeave={() => {
                                        setHoverPage(null)
                                    }}
                                    onClick={() => {
                                        if (arrayPageEnregisterClicked.find(e => e.ref === page.ref)) {
                                            setArrayPageEnregisterClicked(arrayPageEnregisterClicked.filter(e => e.ref !== page.ref))
                                        }else {
                                            setArrayPageEnregisterClicked(old => [...old, page])
                                        }
                                    }}
                        >
                            <div className="card-body w-200px h-150px bg-light p-5">
                                <div className="d-flex flex-column text-center mb-9">
                                    <div className="symbol symbol-40px symbol-lg-40px mb-4">
                                        <img src={page.icone} alt="image compte facebook"/>
                                    </div>
                                    <div className="text-center">
                                        <p className="text-gray-800 fw-bolder">{page.nom}</p>
                                    </div>
                                    <div>
                                        <p className="badge badge-success">
                                            Page enregistré
                                        </p> <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })  }
            </div>

                    <div className="d-flex justify-content-center">
                        <Button
                            onClick={() => {
                                BtSwal.fire({
                                    title: 'Êtes vous sur de vouloir ajouter les pages sélectionnées pour la publication ?',
                                    showDenyButton: true,
                                    confirmButtonText: `Oui`,
                                    denyButtonText: `Annuler`,
                                }).then((result) => {
                                    if (result.isConfirmed){
                                        facebookProxy.arrayIdPageFacebookForPublication = arrayPageEnregisterClicked;
                                        facebookProxy.addFacebookPagesModal.show = false;
                                    }
                                })
                            }}
                        >
                            Modifier les pages sélectionnées
                        </Button>
                    </div>
        </Modal.Body>
    </Modal>;


}

export default AddFacebookPagesModal;