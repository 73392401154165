import { useQuery } from "react-query";
import api from "@services/api";

export default function useGetDetailReversion(id) {
    return useQuery(
        ['reversionPaiement', id],
        async () => {
            return api.get(`reversionPaiement/${id}/detail`).json();
        },
        {
            enabled: id != null,
        },
    );
}