import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";

export default function useGetSujetByCat(libelle, searchByObjet, filter, filterSujetAgence) {

    const snapAuth = useSnapshot(auth);
    let entreprises = [];

    if (snapAuth?.utilisateur?.tabEntreprises && filterSujetAgence){
        Object.values(snapAuth?.utilisateur?.tabEntreprises).forEach(item => {
            entreprises.push(item.id);
        })
    }else {
        entreprises = "";
    }

    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = {
        data: "categorie.libelle",
        searchable: true,
        orderable: true,
        search: {
            value: libelle,
            regex: false,
        }
    }

    filters.columns[1] = {
        data: "objet",
        searchable: true,
        orderable: true,
        search: {
            value: searchByObjet,
            regex: false,
        }
    }

    filters.columns[2] = {
        data: "id",
        searchable: true,
        orderable: true,
        search: {
            value: "",
            regex: false,
        }
    }

    filters.columns[3] = {
        data: "tabEtats",
        searchable: true,
        orderable: true,
        search: {
            value: filter,
            regex: false,
        }
    }

    filters.columns[4] = {
        data: "auteur.tabEntreprises.id",
        searchable: true,
        orderable: true,
        searchOperator : "IN",
        search: {
            value: entreprises.toString(),
            regex: false,
        }
    }

    filters.order[0] = {
        column:2,
        dir: "desc"
    }

    return useQuery(
        ["sujetForumByCategories"+libelle],
        async () => {
            return api.get("sujetForum", {
                searchParams: Qs.stringify({
                    start: 0,
                    length: 100,
                    ...filters,
                }, { arrayFormat: 'brackets' }),
            }).json();
        }
    );

}