import {useMutation, useQueryClient} from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useDeleteExpediteur() {

    const queryClient = useQueryClient();

    return useMutation(deleteExpediteur, {
        onSuccess: () => {
            queryClient.invalidateQueries('expediteur');
            toast.success('L\'expéditeur à été supprimer', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function deleteExpediteur(id) {

    if (id != null)
        return api.delete(`expediteur/${id}`).json();

}