import { useEffect, useState } from "react";

export function useMetaCheckboxes() {
    const [selectAll, setSelectAll] = useState(false);
    const [selectList, setSelectList] = useState({});

    useEffect(() => {
        setSelectList({});
    }, [selectAll]);

    const reset = () => {
        setSelectAll(false);
        setSelectList({});
    };
    const toggleSelectAll = () => setSelectAll(!selectAll);
    const toggleItem = (id) => setSelectList({
        ...selectList,
        [id]: !selectList[id],
    });
    const isItemChecked = (id) => selectList[id] ? !selectAll : selectAll;
    const getIds = () => {
        let res = [];
        for (const id in selectList) {
            if (selectList[id])
                res.push(id);
        }
        return res;
    }

    return {
        toggleSelectAll,
        isSelectingAll: selectAll,
        selectList,
        toggleItem,
        isItemChecked,
        getIds,
        reset,
    };
}