import {Modal} from "react-bootstrap";
import {useSnapshot} from "valtio";
import publicationMobileProxy from "../../../../proxies/publicationMobile";
import GrapesJS from "../../../../components/GrapesJS/GrapesJS";

function GrapesJsForPublicationModal({getValues, onChangeForPublication}) {

    const publicationMobileSnap = useSnapshot(publicationMobileProxy);

    return <Modal
        fullscreen
        show={publicationMobileSnap.editGrapesModal.show}
        onHide={() => {
            publicationMobileProxy.editGrapesModal.show = false;
            publicationMobileProxy.templateSelected = null;
        }}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
        backdrop={"static"}
    >
        <Modal.Body>
            <GrapesJS
                getValues={getValues}
                onChangeForPublication={onChangeForPublication}
            />
        </Modal.Body>
    </Modal>;
}

export default GrapesJsForPublicationModal;