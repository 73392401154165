import {useCallback, useEffect, useMemo, useState} from "react";
import useGetSpeedBusinuess from "../../../requests/communication/speedBusiness/useGetSpeedBusinuess";
import LoadingView from "../../../views/LoadingView/LoadingView";
import {
    dureeMinuteToTimeMysqlWithoutHours,
    dureeTimeMysqlToMinute
} from "../../../views/SpeedBusinessView/ReglageStep/data";
import "./style.css";
import Groupe from "./Steps/Groupe";
import CountDownCircleTimer from "../../../components/CountDownCircleTimer/CountDownCircleTimer";
import PlacementChangementTable from "../../../views/SpeedBusinessView/PresentationView/Steps/PlacementChangementTable";
import useGetQueryParticipants from "../../../requests/communication/speedBusiness/useGetQueryParticipants";
import useGetQueryGenererParTable from "../../../requests/communication/speedBusiness/useGetQueryGenererParTable";
import useWebSocket from "./websocketSpeed";
import {useSnapshot} from "valtio";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import {
    getEtapeChangementPlace,
    getEtapeEntracte,
    getEtapeTimePersonne,
    getEtatPause,
    getEtatPlay, getEtatTermine,
    initDonneeParDefautSpeed
} from "./functions";
import PremierPlacement from "../../../views/SpeedBusinessView/PresentationView/Steps/PremierPlacement";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import moment from "moment";
import Entracte from "../../../views/SpeedBusinessView/PresentationView/Steps/Entracte";
import useGetSpeedFromRef from "../../../requests/communication/speedBusiness/useGetSpeedFromRef";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
export default function PresentationGlobal(){
    //pour init les données via le websocket
    const [valueChangeRemainingTime, setValueChangeRemainingTime] = useState(false);
    const [valueChangeRemainingTimeGroupeB, setValueChangeRemainingTimeGroupeB] = useState(false);
    const speedSnap = useSnapshot(speedBusinessProxy);
    const [currentPerson, setCurrentPerson] = useState(1);
    const [currentPersonGroupeB, setCurrentPersonGroupeB] = useState(1);
    const [isEntracte, setIsEntracte] = useState(false);
    const [isChangementTour, setIsChangementTour] = useState(false);
    const [isTimePersonne, setIsTimePersonne] = useState(true);
    const [tour, setTour] = useState(1);
    const [currentTime, setCurrentTime] = useState(0);
    const [currentTimeGroupeB, setCurrentTimeGroupeB] = useState(0);
    const [entracteIsDone, setEntracteIsDone] = useState(false);
    const [numberOfStep, setNumberOfStep] = useState([]);
    const [showDisposition, setShowDisposition] = useState(false);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const refSpeed = urlParams.get('ref') ? urlParams.get('ref').replace(/ /g, "+") : "";
    const [numberOfStepGroupeB, setNumberOfStepGroupeB] = useState([]);
    const speedBusiness = useGetSpeedFromRef(refSpeed);
    const websocket = useWebSocket(refSpeed);
    const [heureFin, setHeureFin] = useState(0);
    const screnn = useFullScreenHandle();
    const idSpeed = useMemo(()=> {
        return speedBusiness?.data?.id
    }, [speedBusiness?.data?.id])
    const [isFullRencontre, setIsFullRencontre] = useState(false);
    const participantsPresentEtEnRetardSansMaitre = useGetQueryParticipants(
        idSpeed,
        {
            data: "isMaitreDuTemps",
            searchable: true,
            orderable: true,
            searchOperator: "=",
            search: {
                //1==false 0==true
                value: 1,
                regex: false,
            },
        },
        {
            data: "isMaitreDuTempsSuppleant",
            searchable: true,
            orderable: true,
            searchOperator: "=",
            search: {
                //1==false 0==true
                value: 1,
                regex: false,
            },
        },
        "presentEtEnRetardSansMaitre",
        {
            data: "etatPresence",
            searchable: true,
            orderable: true,
            searchOperator: "IN",
            search: {
                value: "0,2",
                regex: false,
            },
        }
        );
    const participantsPresentEtEnRetardSansMaitreIfNotFullTauxRencontre = useGetQueryParticipants(
        idSpeed,
        {
            data: "isMaitreDuTemps",
            searchable: true,
            orderable: true,
            searchOperator: "=",
            search: {
                //1==false 0==true
                value: 1,
                regex: false,
            },
        },
        {
            data: "etatPresence",
            searchable: true,
            orderable: true,
            searchOperator: "IN",
            search: {
                value: "0,2",
                regex: false,
            },
        },
        "presentEtEnRetardSansMaitreIfNotFullTauxRencontre"

    );
    const participantsPresentEtEnRetard = useGetQueryParticipants(
        idSpeed,
        {
            data: "etatPresence",
            searchable: true,
            orderable: true,
            searchOperator: "IN",
            search: {
                value: "0,2",
                regex: false,
            },
        },
        {
            data: "isMaitreDuTemps",
            searchable: true,
            orderable: true,
            search: {
                //1==false 0==true
                value: "",
                regex: false,
            },
        },
        "presentEtEnRetard",
    );
    const participantsPresentEtEnRetardMaitre = useGetQueryParticipants(
        idSpeed,
        {
            data: "etatPresence",
            searchable: true,
            orderable: true,
            searchOperator: "IN",
            search: {
                value: "0,2",
                regex: false,
            },
        },
        {
            data: "isMaitreDuTemps",
            searchable: true,
            orderable: true,
            searchOperator: "=",
            search: {
                //1==false 0==true
                value: 0,
                regex: false,
            },
        },
        "presentEtEnRetardMaitre",
    );
    const participantsPresentEtEnRetardMaitreSuppleant = useGetQueryParticipants(
        idSpeed,
        {
            data: "etatPresence",
            searchable: true,
            orderable: true,
            searchOperator: "IN",
            search: {
                value: "0,2",
                regex: false,
            },
        },{
            data: "isMaitreDuTempsSuppleant",
            searchable: true,
            orderable: true,
            searchOperator: "=",
            search: {
                //1==false 0==true
                value: 0,
                regex: false,
            },
        },
        "presentEtEnRetardMaitreSuppleant",
    );
    const generationParTable = useGetQueryGenererParTable(idSpeed);
    const [zigZagBar, setZigZagBar] = useState({
        rotate: 0,
        width:0,
        left:0
    })
    const [duration, setDuration] = useState(0);
    const [durationGroupeB, setDurationGroupeB] = useState(0);
    const [zigZagBarGroupeB, setZigZagBarGroupeB] = useState({
        rotate: 0,
        width:0,
        left:0
    })
    const [isPlaying, setIsPlaying] = useState(false);
    const [hasGroups, setHasGroups] = useState(true);
    const [dataWebsocketIsInit, setDataWebsocketIsInit] = useState(false);
    const [startSpeed, setStartSpeed] = useState(false);
    const [initCurrentPerson, setInitCurrentPerson] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (speedSnap?.objectCurrentPresentationSpeed && speedBusiness?.data?.dureeParoleParPersonne && participantsPresentEtEnRetard?.data?.nbLignesTotales){
                setHeureFin(getHeureDeFin())
            }
        }, 60000);
        return () => clearTimeout(timeoutId);
    }, [heureFin, isPlaying]);

//initialisation par le websocket au reload + surveillance du play/pause
    useEffect(() => {
        if (speedBusiness?.data && speedSnap?.objectCurrentPresentationSpeed && !dataWebsocketIsInit){
            if (speedSnap?.objectCurrentPresentationSpeed.tabEtats.find(item => item.actif == true).id !== null){
                BtSwal.fire({
                    title: `La rencontre a déjà commencé`,
                    html: `Pour <b>suivre</b> la rencontre en cours, cliquez sur <b>Continuer.</b> <br>
                            Pour <b>redémarrer</b> la rencontre, cliquez <br> sur <b>Recommencer</b> (❗ cette action est irréversible). \n
                            Que souhaitez vous faire ?`,
                    showDenyButton: true,
                    confirmButtonText: `Continuer`,
                    denyButtonText: `Recommencer`,
                    icon: 'info',
                    allowEscapeKey: false,
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        initDataAfterReload();
                        setHeureFin(getHeureDeFin());
                    } else if (result.isDenied) {
                        BtSwal.fire('Les données ont été réinitialisées', '', 'info')
                        speedBusinessProxy.objectCurrentPresentationSpeed = initDonneeParDefautSpeed()
                        speedSnap.websocket.send({
                            ...speedSnap,
                            objectCurrentPresentationSpeed: {
                                ...initDonneeParDefautSpeed()
                            }
                        })
                    }
                })
                setDataWebsocketIsInit(true)
            }else {
                setDataWebsocketIsInit(true)
            }
        }
        if (speedSnap?.objectCurrentPresentationSpeed){
            switch (speedSnap?.objectCurrentPresentationSpeed?.tabEtats?.find(item => item.actif == true).id) {
                case null:
                    setIsPlaying(false);
                    break;
                case 0:
                    setIsPlaying(true);
                    break;
                case 1:
                    setIsPlaying(false);
                    break;
                case 2:
                    setIsPlaying(false);
                    break;
            }
        }

    }, [speedSnap?.objectCurrentPresentationSpeed,speedSnap?.objectCurrentPresentationSpeed?.tabEtats, speedBusiness?.data])


    useEffect(() => {
        //gestion de l'entracte a mis parcours
        if (tour == (Math.floor(speedBusiness?.data?.nombreTours/2) + 1) && !entracteIsDone && dureeTimeMysqlToMinute(speedBusiness?.data?.dureeEntracte) > 0){
            setIsEntracte(true)
            setEntracteIsDone(true)
            setIsChangementTour(false)
        }else if (!isTimePersonne && showDisposition){
            setShowDisposition(false);
        }
        //todo en test a retirer si ça plante
        if (speedBusiness?.data && speedSnap?.objectCurrentPresentationSpeed){
            if (speedSnap?.objectCurrentPresentationSpeed.tabEtats.find(item => item.actif == true).id !== null){
                initDataAfterReload()
            }
        }

        if (speedBusiness?.data && speedSnap?.objectCurrentPresentationSpeed && (tour > speedBusiness?.data?.nombreTours)){
                setSpeedTermine()
        }

        // -- a supprimer a la fin des tests
            // initialise les personnes a chaque début de tour
            // setCurrentPerson(1)
            // setCurrentPersonGroupeB(1)
    }, [tour]);
    useEffect(() => {
        if (initCurrentPerson){
            setCurrentPerson(1)
            setCurrentPersonGroupeB(1)
            setInitCurrentPerson(false)
            if (isTimePersonne){
                setValueChangeRemainingTime(duration)
                setValueChangeRemainingTimeGroupeB(durationGroupeB)
            }
        }
    }, [initCurrentPerson, isTimePersonne, isEntracte]);

    //surveillance de l'arrivée des participants
    useEffect(() => {
        speedSnap.websocket.sendParticipant({
            action: "participantsArrivesTour",
            numTour : tour,
        })
    }, [speedSnap?.updateParticipantsArrive, tour]);

    //init zigzagbar
    useEffect(() => {
        if (speedBusiness?.data && participantsPresentEtEnRetard?.data){
            //initialisation compteur
            // setCurrentTime(dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60);
            //init tab pour construire la zigzagbar
            let tmp = [];
            let tmp2 = [];
            let nbParticipantsGroupA = speedBusiness?.data?.nombrePersonnesParTableChoisies == 2 ? 2 : Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales/speedBusiness?.data?.nombreTablesChoisies)
            let nbParticipantsGroupB = Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales/speedBusiness?.data?.nombreTablesChoisies) - 1
            if (isFullRencontre && tour == (speedBusiness?.data?.nombreTablesChoisies + 1 )){
                nbParticipantsGroupA = speedBusiness?.data?.nombreTablesChoisies;
                nbParticipantsGroupB = participantsPresentEtEnRetard?.data?.nbLignesTotales - (Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales/speedBusiness?.data?.nombreTablesChoisies)*speedBusiness?.data?.nombreTablesChoisies)
            }
            for (let i = 0; i <= nbParticipantsGroupA; i++) {
                tmp.push(i+1)
            }
            for (let i = 0; i <= nbParticipantsGroupB; i++) {
                tmp2.push(i+1)
            }
            setNumberOfStep(tmp)
            setNumberOfStepGroupeB(tmp2)
            //
            //dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60
            setDuration(dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60)
            setDurationGroupeB(((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60)*Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies))/Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies))
            //Initialisation des données de la zigZagBar
            let largeurZigZag = 400;
            let hauteurZigZagBar = 50;
            let a = largeurZigZag/nbParticipantsGroupA
            let c = Math.sqrt((a*a)+(hauteurZigZagBar*hauteurZigZagBar))
            let alpha = Math.atan(hauteurZigZagBar/a)*(180/Math.PI)
            setZigZagBar({
                rotate: alpha,
                width: c,
                left: a
            })
            //Initialisation des données de la zigZagBarGroupeB
            let aGroupeB = largeurZigZag/nbParticipantsGroupB
            let cGroupeB = Math.sqrt((aGroupeB*aGroupeB)+(hauteurZigZagBar*hauteurZigZagBar))
            let alphaGroupeB = Math.atan(hauteurZigZagBar/aGroupeB)*(180/Math.PI)
            setZigZagBarGroupeB({
                rotate: alphaGroupeB,
                width: cGroupeB,
                left: aGroupeB
            })
            if (participantsPresentEtEnRetard?.data?.nbLignesTotales%speedBusiness?.data?.nombreTablesChoisies == 0){
                setHasGroups(false);
            }
            else if (speedBusiness?.data?.nombrePersonnesParTableChoisies == 2){
                setHasGroups(false);
            }
        }
    }, [speedBusiness?.data, participantsPresentEtEnRetard?.data, tour, isFullRencontre])

    //check nb maitre du temps et suppléant
    useEffect(() => {
        if (speedBusiness?.data && participantsPresentEtEnRetardMaitre?.data && participantsPresentEtEnRetardMaitreSuppleant?.data){
            //check maitre du temps
            if ((speedBusiness?.data?.nombreTablesChoisies > participantsPresentEtEnRetardMaitre?.data?.nbLignesTotales) && (speedBusiness?.data?.nombrePersonnesParTableChoisies != 2)){
                BtSwal.fire(
                    {
                        title: "Attention le nombre de maitre du temps requis ("+speedBusiness?.data?.nombreTablesChoisies+") n'a pas été atteint !",
                        text: "Merci de retourner dans les paramètres de la rencontre afin de rajouter des maitres du temps. Puis rafraichir la page.",
                        showDenyButton: false,
                        confirmButtonText: `Rafraichir la page`,
                        icon: 'warning',
                        allowOutsideClick:false,
                        allowEscapeKey: false,
                    }).then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                })

            }else if (speedBusiness?.data?.nombreTablesChoisies < participantsPresentEtEnRetardMaitre?.data?.nbLignesTotales){
                BtSwal.fire(
                    {
                        title: "Attention il y a ("+(participantsPresentEtEnRetardMaitre?.data?.nbLignesTotales - speedBusiness?.data?.nombreTablesChoisies)+") maitre du temps en trop !",
                        text: "Merci de retourner dans les paramètres de la rencontre afin de retirer des maitres du temps. Puis rafraichir la page.",
                        showDenyButton: false,
                        allowOutsideClick:false,
                        allowEscapeKey: false,
                        confirmButtonText: `Rafraichir la page`,
                        icon: 'warning',
                    }).then((result) => {
                    if (result.isConfirmed){
                        window.location.reload();
                    }
                })

            }
            //check maitre du temps suppléant s'il doit y en avoir
            if (((speedBusiness?.data?.nombreTablesChoisies + 1) == speedBusiness?.data?.nombreTours) && (speedBusiness?.data?.nombrePersonnesParTableChoisies != 2)){
                setIsFullRencontre(true);
                if ((Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales/speedBusiness?.data?.nombreTablesChoisies)-1) > participantsPresentEtEnRetardMaitreSuppleant?.data?.nbLignesTotales){
                    BtSwal.fire(
                        {
                            title: "Attention le nombre de maitre du temps suppléant requis ("+((Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales/speedBusiness?.data?.nombreTablesChoisies)-1))+") n'a pas été atteint !",
                            text: "Merci de retourner dans les paramètres de la rencontre afin de rajouter des maitres du temps suppléant. Puis rafraichir la page.",
                            showDenyButton: false,
                            confirmButtonText: `Rafraichir la page`,
                            allowOutsideClick:false,
                            allowEscapeKey: false,
                            icon: 'warning',
                        }).then((result) => {
                        if (result.isConfirmed){
                            window.location.reload();
                        }
                    })
                }else if ((Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales/speedBusiness?.data?.nombreTablesChoisies)-1) < participantsPresentEtEnRetardMaitreSuppleant?.data?.nbLignesTotales){
                    BtSwal.fire(
                        {
                            title: "Attention il y a ("+(participantsPresentEtEnRetardMaitreSuppleant?.data?.nbLignesTotales - ((Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales/speedBusiness?.data?.nombreTablesChoisies)-1)))+") maitre du temps suppléant en trop !",
                            text: "Merci de retourner dans les paramètres de la rencontre afin de retirer des maitres du temps suppléant. Puis rafraichir la page.",
                            showDenyButton: false,
                            confirmButtonText: `Rafraichir la page`,
                            allowOutsideClick:false,
                            allowEscapeKey: false,
                            icon: 'warning',
                        }).then((result) => {
                        if (result.isConfirmed){
                            window.location.reload();
                        }
                    })
                }
            }
        }
    }, [speedBusiness?.data, participantsPresentEtEnRetardMaitre?.data, participantsPresentEtEnRetardMaitreSuppleant?.data]);


    if (speedBusiness.isLoading || participantsPresentEtEnRetardSansMaitreIfNotFullTauxRencontre.isLoading || participantsPresentEtEnRetardSansMaitre.isLoading || generationParTable.isLoading || participantsPresentEtEnRetardMaitre.isLoading || participantsPresentEtEnRetard.isLoading || participantsPresentEtEnRetardMaitreSuppleant.isLoading){
        return <LoadingView/>
    }
    return  <FullScreen handle={screnn}><div style={{
        height: "100vh",
        display: "block",
        width: "100vw",
        backgroundColor: "#f7f7f9",
        backgroundImage: "url('/images/t.svg')",
        backgroundSize: "67px 56px",
        position: "relative"
    }} className="">
        {speedSnap?.objectCurrentPresentationSpeed ? <span>
            {((startSpeed && !isEntracte) && !(tour > speedBusiness?.data.nombreTours || typeof tour === "undefined")) && <>
            <div style={{
                    border: (isChangementTour || showDisposition) ? "" : "1px solid grey",
                    borderRadius: showDisposition ? 0 : "0 40px 40px 0",
                    height: isChangementTour ? 0 : 76,
                    width: 78,
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    left: 0,
                    zIndex:5,
                    top: isChangementTour ? "4%" : "35%"
                }}>
                {!showDisposition &&
                    <>{isPlaying ?
                        <i
                            onClick={() => {
                                if (websocket){
                                    pauseAndUpdateWebhookAndProxy();
                                    setHeureFin(getHeureDeFin())
                                }
                            }}
                            title={!websocket && "Connexion au serveur perdu"}
                            style={{
                                color: isChangementTour ? "white" : "black",
                                cursor: !websocket ? "not-allowed" : "pointer"
                            }} className="fa fa-4x fa-pause-circle"></i>
                        :
                        <i
                            onClick={() => {
                                if (websocket) {
                                    playAndUpdateWebhookAndProxy();
                                    setHeureFin(getHeureDeFin())
                                }
                            }}
                            title={!websocket && "Connexion au serveur perdu"}
                            style={{
                                color: isChangementTour ? "white" : "black",
                                cursor: !websocket ? "not-allowed" : "pointer"
                            }} className="fa fa-4x fa-play-circle"></i>
                    }</>
                }

                </div>
                {!isChangementTour && !isEntracte &&
                    <div style={{
                        border: showDisposition ? 0 : "1px solid grey",
                        borderRadius: showDisposition ? 0 : "0 40px 40px 0",
                        height: 76,
                        width: 78,
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        left: 0,
                        top: showDisposition ? 0 : "45%",
                        zIndex:5
                    }}>
                        {showDisposition ?
                            <i
                                onClick={() => {
                                    //todo afficher les placements
                                    setShowDisposition(!showDisposition)
                                }}
                                style={{
                                    color: "white", cursor: "pointer"
                                }} className="fa fa-4x fa-times"></i>
                            : <i
                                onClick={() => {
                                    //todo afficher les placements
                                    setShowDisposition(!showDisposition)
                                }}
                                style={{
                                    color: "black", cursor: "pointer"
                                }} className="fa fa-4x fa-users"></i>}
                            </div>
                        }
                    </>}


                {(!isEntracte && !(tour > speedBusiness?.data.nombreTours || typeof tour === "undefined")) && <div
                    style={{
                        backgroundColor: "var(--primary-color)",
                        display: "block"
                    }}
                    className="w-100 h-70px position-relative">
                    <div className="w-100 position-relative justify-content-center align-items-center d-flex">
                <p
                    style={{
                        fontWeight: "bold",
                        backgroundColor: "white",
                        right: 0,
                        padding: 5,
                        margin: "10px 0",
                        top: 8,
                        borderRadius: "20px 0 0 20px"
                    }}
                    className="position-absolute">
                    {isChangementTour ? "Debut du prochain tour dans : " + currentTime + " secondes" : (isEntracte ? "Fin de l'entracte dans : " + currentTime + " secondes" : (!startSpeed ? "" : "Heure de fin estimée : "+heureFin))}
                </p>
                {(startSpeed && isChangementTour) &&
                    <span style={{
                        fontSize: 22,
                        color: "white",
                        fontWeight: "bold"
                    }}>Tour {tour} / {speedBusiness?.data.nombreTours} </span>
                }
                {!startSpeed && <p className="ms-5" style={{
                    fontSize: 31,
                    margin: "10px 0",
                    color: "white",
                    fontWeight: "bold",
                }}>Recherchez votre table</p>}
                {(startSpeed && isTimePersonne) && <p className="ms-5" style={{
                    fontSize: 31,
                    margin: "10px 0",
                    color: "white",
                    fontWeight: "bold",
                }}>TEMPS DE PAROLE</p>}
                {(startSpeed && isEntracte) && <p className="ms-5" style={{
                    fontSize: 31,
                    margin: "10px 0",
                    color: "white",
                    fontWeight: "bold",
                }}>ENTRACTE</p>}
                {(startSpeed && isChangementTour) && <p className="ms-10" style={{
                    fontSize: 31,
                    margin: "10px 0",
                    color: "white",
                    fontWeight: "bold",
                }}>CHANGEMENT DE PLACE</p>}

                <span onClick={() => {
                    try {
                        if (screnn.active) {
                            screnn.exit();
                        }else {
                            screnn.enter();
                        }
                    }catch (e) {
                        showErrorAlert('Plein écran non pris en charge')
                    }
                }} className="fa fa-expand btn btn-secondary" style={{
                    marginLeft:40,
                    fontSize: 12,
                    padding:8,
                    color: "white",
                    fontWeight: "bold"
                }}>&nbsp;&nbsp;<span style={{
                    fontFamily: "poppins, Helvetica",
                    fontSize:12
                }}>Plein écran</span></span>
                {!startSpeed && <p
                    style={{
                        fontSize:16,
                        fontWeight: "bold",
                        backgroundColor: "white",
                        left: 0,
                        padding: 5,
                        margin: "10px 10px",
                        top: 8,
                        borderRadius: 20,
                        cursor: "pointer"
                    }}
                    onClick={() => {
                        if (websocket) {
                            playAndUpdateWebhookAndProxy();
                            setStartSpeed(true);
                            setHeureFin(getHeureDeFin())
                        }
                    }}
                    className="position-absolute">
                    Démarrer la rencontre
                </p>}
            </div>

        </div> }

        <div>
            {!startSpeed &&
                <div style={{
                    width: "100%"
                }} className="mt-5 d-flex justify-content-center align-items-center">
                    <PremierPlacement speed={speedBusiness?.data}
                                      nbTablesTotales={speedBusiness?.data.nombreTablesChoisies}
                                      arrayDataParticipantSansMaitre={isFullRencontre ? participantsPresentEtEnRetardSansMaitre?.data?.data : participantsPresentEtEnRetardSansMaitreIfNotFullTauxRencontre?.data?.data}
                                      arrayDataParticipantMaitreSuppleant={participantsPresentEtEnRetardMaitreSuppleant?.data?.data}
                                      arrayDataParticipantMaitre={participantsPresentEtEnRetardMaitre?.data?.data}
                                      tour={tour}/>
            </div>
            }
            {showDisposition && startSpeed &&
                <div style={{
                    width: "100%"
                }} className="mt-5 d-flex justify-content-center align-items-center">
                    <PremierPlacement speed={speedBusiness?.data}
                                      nbTablesTotales={speedBusiness?.data.nombreTablesChoisies}
                                      arrayDataParticipantSansMaitre={isFullRencontre ? participantsPresentEtEnRetardSansMaitre?.data?.data : participantsPresentEtEnRetardSansMaitreIfNotFullTauxRencontre?.data?.data}
                                      arrayDataParticipantMaitreSuppleant={participantsPresentEtEnRetardMaitreSuppleant?.data?.data}
                                      arrayDataParticipantMaitre={participantsPresentEtEnRetardMaitre?.data?.data}
                                      tour={tour}/>
                </div>
            }
            {startSpeed && (isTimePersonne && tour <= speedBusiness?.data.nombreTours) &&
                <div className="flex-wrap" style={{
                    justifyContent: "space-around",
                    display: showDisposition ? "none" : "flex"
                }}>
                    {hasGroups ?
                        <>
                            {/*Groupe A*/}
                            <Groupe
                                setInitCurrentPerson={setInitCurrentPerson}
                                valueChangeRemainingTime={valueChangeRemainingTime}
                                setValueChangeRemainingTime={setValueChangeRemainingTime}
                                hasGroup={hasGroups}
                                tour={tour}
                                speedBusiness={speedBusiness}
                                isTimePersonne={isTimePersonne}
                                isPlaying={isPlaying}
                                currentPerson={currentPerson}
                                setCurrentPerson={setCurrentPerson}
                                setTour={setTour}
                                setIsEntracte={setIsEntracte}
                                isEntracte={isEntracte}
                                setIsChangementTour={setIsChangementTour}
                                isChangementTour={isChangementTour}
                                setCurrentTime={setCurrentTime}
                                setIsTimePersonne={setIsTimePersonne}
                                currentTime={currentTime}
                                numberOfStep={numberOfStep}
                                zigZagBar={zigZagBar}
                                numIsPair={numIsPair}
                                duration={duration}
                                color={"--primary-color"}
                                nomDuGroupe={"A"}
                                personneParTable={(isFullRencontre && tour == speedBusiness?.data?.nombreTablesChoisies+1 && speedBusiness?.data?.nombrePersonnesParTableChoisies != 2) ? speedBusiness?.data?.nombreTablesChoisies : ((speedBusiness?.data?.nombrePersonnesParTableChoisies == 2) ? 2 : Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies))}
                            />
                            {/*Groupe B*/}
                            <Groupe
                                setInitCurrentPerson={setInitCurrentPerson}
                                valueChangeRemainingTime={valueChangeRemainingTimeGroupeB}
                                setValueChangeRemainingTime={setValueChangeRemainingTimeGroupeB}
                                hasGroup={hasGroups}
                                tour={tour}
                                speedBusiness={speedBusiness}
                                isTimePersonne={isTimePersonne}
                                isPlaying={isPlaying}
                                currentPerson={currentPersonGroupeB}
                                setCurrentPerson={setCurrentPersonGroupeB}
                                setTour={setTour}
                                setIsEntracte={setIsEntracte}
                                isEntracte={isEntracte}
                                setIsChangementTour={setIsChangementTour}
                                isChangementTour={isChangementTour}
                                setCurrentTime={setCurrentTimeGroupeB}
                                setIsTimePersonne={setIsTimePersonne}
                                currentTime={currentTimeGroupeB}
                                numberOfStep={numberOfStepGroupeB}
                                zigZagBar={zigZagBarGroupeB}
                                numIsPair={numIsPair}
                                duration={durationGroupeB}
                                color={"--secondary-color"}
                                nomDuGroupe={"B"}
                                personneParTable={(isFullRencontre && tour == speedBusiness?.data?.nombreTablesChoisies+1) ? participantsPresentEtEnRetard?.data?.nbLignesTotales - (Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales/speedBusiness?.data?.nombreTablesChoisies)*speedBusiness?.data?.nombreTablesChoisies) : Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies)}
                            />
                        </>
                        :
                        <Groupe
                            setInitCurrentPerson={setInitCurrentPerson}
                            valueChangeRemainingTime={valueChangeRemainingTime}
                            setValueChangeRemainingTime={setValueChangeRemainingTime}
                            hasGroup={hasGroups}
                            tour={tour}
                            speedBusiness={speedBusiness}
                            isTimePersonne={isTimePersonne}
                            isPlaying={isPlaying}
                            currentPerson={currentPerson}
                            setCurrentPerson={setCurrentPerson}
                            setTour={setTour}
                            setIsEntracte={setIsEntracte}
                            isEntracte={isEntracte}
                            setIsChangementTour={setIsChangementTour}
                            isChangementTour={isChangementTour}
                            setCurrentTime={setCurrentTime}
                            setIsTimePersonne={setIsTimePersonne}
                            currentTime={currentTime}
                            numberOfStep={numberOfStep}
                            zigZagBar={zigZagBar}
                            numIsPair={numIsPair}
                            duration={duration}
                            color={"--primary-color"}
                            nomDuGroupe={"A"}
                            personneParTable={(isFullRencontre && tour == speedBusiness?.data?.nombreTablesChoisies+1 && speedBusiness?.data?.nombrePersonnesParTableChoisies != 2) ? speedBusiness?.data?.nombreTablesChoisies : ((speedBusiness?.data?.nombrePersonnesParTableChoisies == 2) ? 2 : Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies))}
                        />
                    }

                </div>
            }
        </div>
        {/*Changement de tour*/}
        {(isChangementTour && tour <= speedBusiness?.data.nombreTours) &&
            <div hidden={showDisposition} style={{
                width: "100%"
            }} className="mt-5 d-flex justify-content-center align-items-center">
                {/*// mettre tour - 2 a la fin des test (car dans <TableChangementTable> je met indexTour + 1 ...) */}
                <PlacementChangementTable indexTour={tour-2} speed={speedBusiness?.data} nbTablesTotales={speedBusiness?.data.nombreTablesChoisies} arrayDataParticipantSansMaitre={isFullRencontre ? participantsPresentEtEnRetardSansMaitre?.data?.data : participantsPresentEtEnRetardSansMaitreIfNotFullTauxRencontre?.data?.data} arrayDataParticipantMaitreSuppleant={participantsPresentEtEnRetardMaitreSuppleant?.data?.data} arrayDataParticipantMaitre={participantsPresentEtEnRetardMaitre?.data?.data} />
                {/*Is hidden   */}
                <CountDownCircleTimer
                    setInitCurrentPerson={setInitCurrentPerson}
                    valueChangeRemainingTime={valueChangeRemainingTime}
                    setValueChangeRemainingTime={setValueChangeRemainingTime}
                    tour={tour}
                    speedBusiness={speedBusiness}
                    isTimePersonne={isTimePersonne}
                    isPlaying={isPlaying}
                    currentPerson={currentPerson}
                    setCurrentPerson={setCurrentPerson}
                    setTour={setTour}
                    setIsEntracte={setIsEntracte}
                    isEntracte={isEntracte}
                    setIsChangementTour={setIsChangementTour}
                    isChangementTour={isChangementTour}
                    setCurrentTime={setCurrentTime}
                    setIsTimePersonne={setIsTimePersonne}
                    currentTime={currentTime}
                    numberOfStep={numberOfStep}
                    zigZagBar={zigZagBar}
                    numIsPair={numIsPair}
                    duration={dureeTimeMysqlToMinute(speedBusiness?.data?.dureeChangementPlace) * 60}
                />
            </div>

        }


        {(isEntracte && tour <= speedBusiness?.data.nombreTours) &&
            <Entracte hidden={showDisposition} currentTime={currentTime} playing={isPlaying} onClick={() => {
                pauseAndUpdateWebhookAndProxy();
                setHeureFin(getHeureDeFin())
            }} changementTour={isChangementTour} onClick1={() => {
                playAndUpdateWebhookAndProxy();
                setHeureFin(getHeureDeFin())
            }} initCurrentPerson={setInitCurrentPerson} speedBusiness={speedBusiness}
                      valueChangeRemainingTime={valueChangeRemainingTime}
                      valueChangeRemainingTime1={setValueChangeRemainingTime} currentPerson={currentPerson}
                      currentPerson1={setCurrentPerson} participantsPresentEtEnRetard={participantsPresentEtEnRetard}
                      tour={tour} tour1={setTour} isEntracte={setIsEntracte} isChangementTour={setIsChangementTour}
                      timePersonne={isTimePersonne} entracte={isEntracte} isTimePersonne={setIsTimePersonne}
                      currentTime1={setCurrentTime} numberOfStep={numberOfStep} zigZagBar={zigZagBar}
                      numIsPair={numIsPair}/>
        }

        {(tour > speedBusiness?.data.nombreTours || typeof tour === "undefined") &&
            <div style={{
                display: "flex",
                justifyContent:"center",
                alignItems: "center",
                height:"100%",
                width:"100%",
            }}>
                <div style={{
                    display: "flex",
                    justifyContent:"center",
                    alignItems: "center",
                    height:500,
                    width:800,
                    backgroundColor: "var(--primary-color)",
                    borderRadius: 40,
                    boxShadow: "0px 10px 10px"
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 56,
                        fontWeight: "bold"
                    }}>Rencontre terminée</span>
                </div>
            </div>
        }
        </span> : <LoadingView/>}
    </div></FullScreen>;

    function numIsPair(n) {
        return (n & 1) ? false : true;
    }
    function pauseAndUpdateWebhookAndProxy() {

        let diffTimestampServeur = speedSnap?.diffServeurTimestamp;
        setIsPlaying(false)
        speedBusinessProxy.objectCurrentPresentationSpeed = {
            ...speedSnap.objectCurrentPresentationSpeed,
            heureDebDernierePause: moment().unix() + diffTimestampServeur,
            tabEtats: getEtatPause(),
            numPersonneGroupAEnCours: currentPerson,
            numPersonneGroupBEnCours: currentPersonGroupeB,
            tempsEtapeGroupeA: currentTime,
            tempsEtapeGroupeB: currentTimeGroupeB,
            numTour: tour,
            tabEtapes: (() => {
                if (isTimePersonne) {
                    return getEtapeTimePersonne();
                } else if (isEntracte) {
                    return getEtapeEntracte();
                } else if (isChangementTour) {
                    return getEtapeChangementPlace();
                }
            })()
        };
        // * 60 pour avoir le résultat en secondes ...
        speedSnap.websocket.send({
            ...speedSnap,
            objectCurrentPresentationSpeed: {
                ...speedSnap.objectCurrentPresentationSpeed,
                heureDebDernierePause: moment().unix() + diffTimestampServeur,
                tabEtats: getEtatPause(),
                numPersonneGroupAEnCours: currentPerson,
                numPersonneGroupBEnCours: currentPersonGroupeB,
                tempsEtapeGroupeA: currentTime,
                tempsEtapeGroupeB: currentTimeGroupeB,
                numTour: tour,
                tabEtapes: (() => {
                    if (isTimePersonne) {
                        return getEtapeTimePersonne();
                    } else if (isEntracte) {
                        return getEtapeEntracte();
                    } else if (isChangementTour) {
                        return getEtapeChangementPlace();
                    }
                })()
            }
        })
    }

    function playAndUpdateWebhookAndProxy() {
        let diffTimestampServeur = speedSnap?.diffServeurTimestamp;
        setIsPlaying(true)
        speedBusinessProxy.objectCurrentPresentationSpeed.tabEtats = getEtatPlay();
        speedBusinessProxy.objectCurrentPresentationSpeed.heureDepart = speedSnap?.objectCurrentPresentationSpeed?.heureDepart ? speedSnap?.objectCurrentPresentationSpeed?.heureDepart : moment().unix() + diffTimestampServeur;
        speedBusinessProxy.objectCurrentPresentationSpeed.heureFinDernierePause = speedSnap?.objectCurrentPresentationSpeed?.heureDepart ? moment().unix() + diffTimestampServeur : null;
        speedBusinessProxy.objectCurrentPresentationSpeed.dureePause = speedSnap?.objectCurrentPresentationSpeed?.heureDepart ? speedSnap?.objectCurrentPresentationSpeed.dureePause + (moment().unix() + diffTimestampServeur - speedSnap.objectCurrentPresentationSpeed.heureDebDernierePause) : null
        speedSnap.websocket.send({
            ...speedSnap,
            objectCurrentPresentationSpeed: {
                ...speedSnap.objectCurrentPresentationSpeed,
                tabEtats: getEtatPlay(),
                heureFinDernierePause: speedSnap?.objectCurrentPresentationSpeed?.heureDepart ? moment().unix() + diffTimestampServeur : null,
                heureDepart: speedSnap?.objectCurrentPresentationSpeed?.heureDepart ? speedSnap?.objectCurrentPresentationSpeed?.heureDepart : moment().unix() + diffTimestampServeur,
                dureePause: speedSnap?.objectCurrentPresentationSpeed?.heureDepart ? speedSnap?.objectCurrentPresentationSpeed.dureePause + (moment().unix() + diffTimestampServeur - speedSnap.objectCurrentPresentationSpeed.heureDebDernierePause) : null
            }
        })
    }

    function setSpeedTermine(){
        speedBusinessProxy.objectCurrentPresentationSpeed.tabEtats = getEtatTermine();
        speedSnap.websocket.send({
            ...speedSnap,
            objectCurrentPresentationSpeed: {
                ...speedSnap.objectCurrentPresentationSpeed,
                tabEtats: getEtatTermine()
            }
        })
    }

    function initDataAfterReload() {
        let diffTimestampServeur = speedSnap?.diffServeurTimestamp;
        //si l'état du speed était en pause
        if (speedSnap?.objectCurrentPresentationSpeed.tabEtats.find(item => item.actif == true).id == 1) {
            setIsPlaying(false);
            setStartSpeed(true);
            setCurrentPerson(speedSnap?.objectCurrentPresentationSpeed.numPersonneGroupAEnCours)
            setCurrentPersonGroupeB(speedSnap?.objectCurrentPresentationSpeed.numPersonneGroupBEnCours)
            setTour(speedSnap?.objectCurrentPresentationSpeed?.numTour)
            setCurrentTime(speedSnap?.objectCurrentPresentationSpeed?.tempsEtapeGroupeA)
            setValueChangeRemainingTime(speedSnap?.objectCurrentPresentationSpeed?.tempsEtapeGroupeA)
            setCurrentTimeGroupeB(speedSnap?.objectCurrentPresentationSpeed?.tempsEtapeGroupeB)
            setValueChangeRemainingTimeGroupeB(speedSnap?.objectCurrentPresentationSpeed?.tempsEtapeGroupeB)
            switch (speedSnap?.objectCurrentPresentationSpeed?.tabEtapes.find(item => item.actif == true).id) {
                case null :
                    setStartSpeed(false);
                    break;
                case 0 :
                    setIsTimePersonne(true)
                    setIsEntracte(false)
                    setIsChangementTour(false)
                    break;
                case 1 :
                    setIsTimePersonne(false)
                    setIsEntracte(true)
                    setIsChangementTour(false)
                    break;
                case 2 :
                    setIsTimePersonne(false)
                    setIsEntracte(false)
                    setIsChangementTour(true)
                    break;
            }
        } else {
            // calcul pour retrouver l'état actuel
            let objectDerouleSpeed = []
            let nbPersonneParTableGroupeA = Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies);
            let nbPersonneParTableGroupeB = Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies);
            let nbPersonneParTableGroupeAFullLastTour = 0
            let nbPersonneParTableGroupeBFullLastTour = 0
            let currentIsFullLastTour = (speedBusiness?.data?.nombreTablesChoisies +1) == speedBusiness?.data?.nombreTours
            if (currentIsFullLastTour){
                nbPersonneParTableGroupeAFullLastTour = speedBusiness?.data?.nombreTablesChoisies;
                nbPersonneParTableGroupeBFullLastTour = participantsPresentEtEnRetard?.data?.nbLignesTotales - (Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales/speedBusiness?.data?.nombreTablesChoisies)*speedBusiness?.data?.nombreTablesChoisies);
            }else if (speedBusiness?.data?.nombrePersonnesParTableChoisies == 2){
                nbPersonneParTableGroupeAFullLastTour = 2;
                nbPersonneParTableGroupeA = 2;
            }
            for (let i = 0; i < speedBusiness?.data?.nombreTours; i++) {
                if (i >= speedBusiness?.data?.nombreTours - 1) {
                    //dernier tour de parole
                    if (currentIsFullLastTour){
                        objectDerouleSpeed.push({
                            name: "parole",
                            duration: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * nbPersonneParTableGroupeAFullLastTour,
                            durationParPersonne: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60),
                            durationGoupB: ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies)) / Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies) * nbPersonneParTableGroupeBFullLastTour,
                            durationGoupBParPersonne: ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies)) / Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies),
                            nbPersonneParTableGroupeA:nbPersonneParTableGroupeAFullLastTour,
                            nbPersonneParTableGroupeB:nbPersonneParTableGroupeBFullLastTour,
                            tour: i + 1,
                        })
                    }else {
                        objectDerouleSpeed.push({
                            name: "parole",
                            duration: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * nbPersonneParTableGroupeA,
                            durationParPersonne: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60),
                            durationGoupB: ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies)) / Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies) * nbPersonneParTableGroupeB,
                            durationGoupBParPersonne: ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies)) / Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies),
                            nbPersonneParTableGroupeA,
                            nbPersonneParTableGroupeB,
                            tour: i + 1,
                        })
                    }
                } else {
                    objectDerouleSpeed.push({
                        name: "parole",
                        duration: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * nbPersonneParTableGroupeA,
                        durationParPersonne: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60),
                        durationGoupB: ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies)) / Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies) * nbPersonneParTableGroupeB,
                        durationGoupBParPersonne: ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies)) / Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies),
                        nbPersonneParTableGroupeA,
                        nbPersonneParTableGroupeB,
                        tour: i + 1,
                    })
                    //si on est pas au tour de l'entracte, alors il n'y a pas de changement de table -> attention a modi
                    if (!((i + 1) == (Math.floor(speedBusiness?.data?.nombreTours / 2)) && dureeTimeMysqlToMinute(speedBusiness?.data?.dureeEntracte) > 0)) {
                        objectDerouleSpeed.push({
                            name: "table",
                            duration: dureeTimeMysqlToMinute(speedBusiness?.data?.dureeChangementPlace) * 60,
                            nbPersonneParTableGroupeA,
                            nbPersonneParTableGroupeB,
                            tour: i + 2,
                        })
                    }
                }
                if ((i + 1) == (Math.floor(speedBusiness?.data?.nombreTours / 2)) && dureeTimeMysqlToMinute(speedBusiness?.data?.dureeEntracte) > 0) {
                    objectDerouleSpeed.push({
                        name: "entracte",
                        duration: dureeTimeMysqlToMinute(speedBusiness?.data?.dureeEntracte) * 60,
                        tour: i + 2,
                    })
                    objectDerouleSpeed.push({
                        name: "table",
                        duration: dureeTimeMysqlToMinute(speedBusiness?.data?.dureeChangementPlace) * 60,
                        nbPersonneParTableGroupeA,
                        nbPersonneParTableGroupeB,
                        tour: i + 2,
                    })
                }
            }
            let tempsTotalSpeedPlay = moment().unix() + diffTimestampServeur - speedSnap?.objectCurrentPresentationSpeed?.heureDepart - speedSnap?.objectCurrentPresentationSpeed.dureePause;
            let incrementTime = 0;
            let incrementTimeAfter = 0;
            let currentStepAfterFor = null;
            for (let item of objectDerouleSpeed) {
                incrementTime = incrementTime + item.duration;
                if (incrementTime > tempsTotalSpeedPlay) {
                    currentStepAfterFor = item;
                    break;
                }
                incrementTimeAfter = incrementTimeAfter + item.duration;
            }
            let tempsTotalSpeedPlayInEtape = (tempsTotalSpeedPlay - incrementTimeAfter);
            setIsPlaying(true);
            setStartSpeed(true);
            setTour(currentStepAfterFor?.tour)
            switch (currentStepAfterFor?.name) {
                case "parole" :
                    setCurrentPerson(Math.ceil(tempsTotalSpeedPlayInEtape / currentStepAfterFor?.durationParPersonne))
                    setCurrentPersonGroupeB(Math.ceil(tempsTotalSpeedPlayInEtape / currentStepAfterFor?.durationGoupBParPersonne))
                    setIsTimePersonne(true)
                    setIsEntracte(false)
                    setIsChangementTour(false)
                    setCurrentTime((Math.ceil(tempsTotalSpeedPlayInEtape / currentStepAfterFor?.durationParPersonne) * currentStepAfterFor?.durationParPersonne) - tempsTotalSpeedPlayInEtape)
                    setValueChangeRemainingTime((Math.ceil(tempsTotalSpeedPlayInEtape / currentStepAfterFor?.durationParPersonne) * currentStepAfterFor?.durationParPersonne) - tempsTotalSpeedPlayInEtape)
                    setCurrentTimeGroupeB((Math.ceil(tempsTotalSpeedPlayInEtape / currentStepAfterFor?.durationGoupBParPersonne) * currentStepAfterFor?.durationGoupBParPersonne) - tempsTotalSpeedPlayInEtape)
                    setValueChangeRemainingTimeGroupeB((Math.ceil(tempsTotalSpeedPlayInEtape / currentStepAfterFor?.durationGoupBParPersonne) * currentStepAfterFor?.durationGoupBParPersonne) - tempsTotalSpeedPlayInEtape)
                    break;
                case "entracte" :
                    setIsTimePersonne(false)
                    setIsEntracte(true)
                    setIsChangementTour(false)
                    setCurrentTime(currentStepAfterFor?.duration - tempsTotalSpeedPlayInEtape)
                    setValueChangeRemainingTime(currentStepAfterFor?.duration - tempsTotalSpeedPlayInEtape)
                    setCurrentTimeGroupeB(currentStepAfterFor?.duration - tempsTotalSpeedPlayInEtape)
                    setValueChangeRemainingTimeGroupeB(currentStepAfterFor?.duration - tempsTotalSpeedPlayInEtape)
                    break;
                case "table" :
                    setIsTimePersonne(false)
                    setIsEntracte(false)
                    setIsChangementTour(true)
                    setCurrentTime(currentStepAfterFor?.duration - tempsTotalSpeedPlayInEtape)
                    setValueChangeRemainingTime(currentStepAfterFor?.duration - tempsTotalSpeedPlayInEtape)
                    setCurrentTimeGroupeB(currentStepAfterFor?.duration - tempsTotalSpeedPlayInEtape)
                    setValueChangeRemainingTimeGroupeB(currentStepAfterFor?.duration - tempsTotalSpeedPlayInEtape)
                    break;
            }
        }
    }

    function getHeureDeFin(){
        let diffTimestampServeur = speedSnap?.diffServeurTimestamp;
        let objectDerouleSpeed = []
        let nbPersonneParTableGroupeA = Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies);
        let nbPersonneParTableGroupeB = Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies);
        let nbPersonneParTableGroupeAFullLastTour = 0
        let nbPersonneParTableGroupeBFullLastTour = 0
        let currentIsFullLastTour = (speedBusiness?.data?.nombreTablesChoisies +1) == speedBusiness?.data?.nombreTours
        if (currentIsFullLastTour){
            nbPersonneParTableGroupeAFullLastTour = speedBusiness?.data?.nombreTablesChoisies;
            nbPersonneParTableGroupeBFullLastTour = participantsPresentEtEnRetard?.data?.nbLignesTotales - (Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales/speedBusiness?.data?.nombreTablesChoisies)*speedBusiness?.data?.nombreTablesChoisies);
        }else if (speedBusiness?.data?.nombrePersonnesParTableChoisies == 2){
            nbPersonneParTableGroupeAFullLastTour = 2;
            nbPersonneParTableGroupeA = 2;
        }
        for (let i = 0; i < speedBusiness?.data?.nombreTours; i++) {
            if (i >= speedBusiness?.data?.nombreTours - 1) {
                //dernier tour de parole
                if (currentIsFullLastTour){
                    objectDerouleSpeed.push({
                        name: "parole",
                        duration: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * nbPersonneParTableGroupeAFullLastTour,
                        durationParPersonne: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60),
                        durationGoupB: ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies)) / Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies) * nbPersonneParTableGroupeBFullLastTour,
                        durationGoupBParPersonne: ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies)) / Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies),
                        nbPersonneParTableGroupeA:nbPersonneParTableGroupeAFullLastTour,
                        nbPersonneParTableGroupeB:nbPersonneParTableGroupeBFullLastTour,
                        tour: i + 1,
                    })
                }else {
                    objectDerouleSpeed.push({
                        name: "parole",
                        duration: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * nbPersonneParTableGroupeA,
                        durationParPersonne: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60),
                        durationGoupB: ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies)) / Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies) * nbPersonneParTableGroupeB,
                        durationGoupBParPersonne: ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies)) / Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies),
                        nbPersonneParTableGroupeA,
                        nbPersonneParTableGroupeB,
                        tour: i + 1,
                    })
                }
            } else {
                objectDerouleSpeed.push({
                    name: "parole",
                    duration: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * nbPersonneParTableGroupeA,
                    durationParPersonne: (dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60),
                    durationGoupB: ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies)) / Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies) * nbPersonneParTableGroupeB,
                    durationGoupBParPersonne: ((dureeTimeMysqlToMinute(speedBusiness?.data?.dureeParoleParPersonne) * 60) * Math.ceil(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies)) / Math.floor(participantsPresentEtEnRetard?.data?.nbLignesTotales / speedBusiness?.data?.nombreTablesChoisies),
                    nbPersonneParTableGroupeA,
                    nbPersonneParTableGroupeB,
                    tour: i + 1,
                })
                //si on est pas au tour de l'entracte, alors il n'y a pas de changement de table -> attention a modi
                if (!((i + 1) == (Math.floor(speedBusiness?.data?.nombreTours / 2)) && dureeTimeMysqlToMinute(speedBusiness?.data?.dureeEntracte) > 0)) {
                    objectDerouleSpeed.push({
                        name: "table",
                        duration: dureeTimeMysqlToMinute(speedBusiness?.data?.dureeChangementPlace) * 60,
                        nbPersonneParTableGroupeA,
                        nbPersonneParTableGroupeB,
                        tour: i + 2,
                    })
                }
            }
            if ((i + 1) == (Math.floor(speedBusiness?.data?.nombreTours / 2)) && dureeTimeMysqlToMinute(speedBusiness?.data?.dureeEntracte) > 0) {
                objectDerouleSpeed.push({
                    name: "entracte",
                    duration: dureeTimeMysqlToMinute(speedBusiness?.data?.dureeEntracte) * 60,
                    tour: i + 2,
                })
                objectDerouleSpeed.push({
                    name: "table",
                    duration: dureeTimeMysqlToMinute(speedBusiness?.data?.dureeChangementPlace) * 60,
                    nbPersonneParTableGroupeA,
                    nbPersonneParTableGroupeB,
                    tour: i + 2,
                })
            }
        }
        let tempsTotalSpeedPlay = 0;
        if (speedSnap?.objectCurrentPresentationSpeed?.heureDepart){
            tempsTotalSpeedPlay = moment().unix() + diffTimestampServeur - speedSnap?.objectCurrentPresentationSpeed?.heureDepart - speedSnap?.objectCurrentPresentationSpeed.dureePause;
        }

        let tempsTotalSpeed = 0;
        for (let item of objectDerouleSpeed) {
            tempsTotalSpeed = tempsTotalSpeed + item.duration;
        }
        if (!isPlaying && startSpeed && speedSnap?.objectCurrentPresentationSpeed?.heureDepart){
            tempsTotalSpeedPlay = tempsTotalSpeedPlay - (moment().unix() + diffTimestampServeur - speedSnap.objectCurrentPresentationSpeed.heureDebDernierePause)
        }
        let tempsRestant = tempsTotalSpeed - tempsTotalSpeedPlay;
        let heureDeFin = tempsRestant;
        return moment().add(heureDeFin, "seconds").format("HH:mm");
    }
}