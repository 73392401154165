import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import useUploadFile from '@requests/upload/useUploadFile';
import BlockUI from '@components/BlockUI/BlockUI';
import { showErrorAlert } from '@utils/alerts/showErrorAlert';

function EditButton({ onClick }) {
    return <div className={`sectionButton symbol symbol-40px`} onClick={onClick}>
        <div className='symbol-label fs-2 fw-bold bg-secondary hoverable text-inverse-primary p-2'>
            <FontAwesomeIcon icon={faPencilAlt} />
        </div>
    </div>;
}

function ImageEditor({ children, onChange }) {
    const inputFile = useRef();
    const uploadFile = useUploadFile({
        type: 'userfile',
        onError: (err) => {
            showErrorAlert("Erreur lors de l'upload", err.message);
        },
        onSuccess: (data) => {
            onChange(data.urlOriginale);
        }
    });

    function handleUploadInput(e) {
        const file = e.target.files[0];
        uploadFile.mutate(file);
    }

    return <BlockUI loading={uploadFile.isLoading}>
        <div className='overlay'>
            <div className='overlay-wrapper text-center'>
                {children}
            </div>
            <div className='overlay-layer bg-dark bg-opacity-25 align-items-start justify-content-end'>
                <EditButton onClick={() => inputFile.current?.click()} />
                <input
                    type='file'
                    id='file'
                    ref={inputFile}
                    style={{ display: 'none' }}
                    accept='.png,.jpg,.jpeg'
                    onChange={handleUploadInput}
                />
            </div>
        </div>
    </BlockUI>;
}

export default ImageEditor;