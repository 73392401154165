import { useMutation } from "react-query";
import api from "@services/api";

export default function useSendSms({ id, date, ...props }) {
    return useMutation(() => api.post(
        `sms/${id}/envoyer`, {
            json: {
                dateProgrammationEnvoi: date,
            }
        }).json(), props);
}