import {useMutation, useQueryClient} from "react-query";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import baseApi from "../../../services/apiPublic";

export default function useValidSmsByMail() {

    const queryClient = useQueryClient();
    return useMutation(validSms, {
        onSuccess: () => {
            queryClient.invalidateQueries('sms');
            // snapAuth.websocket.send("sms")
            toast.success('Le sms a été validé', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function validSms(ref) {

    if (ref != null)
        return baseApi.post(`sms/valider`, {json : {ref : ref}}).json();

}