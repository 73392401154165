import { Modal } from "react-bootstrap";
import AgenceSettings from "./AgenceSettings/AgenceSettings";
import MailSettings from "./MailSettings/MailSettings";

function SettingsModal({ show, onHide }) {
    return <Modal
        size='lg'
        show={show}
        onHide={onHide}
    >
        <Modal.Header closeButton>
            <Modal.Title>Paramètres</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h3>Paramètres de mails</h3>
            <MailSettings show={show} />
            <div className='my-10 separator' />
            <h3>Paramètres d'agence</h3>
            <AgenceSettings show={show} />
        </Modal.Body>
    </Modal>
}

export default SettingsModal;