import {useEffect, useRef, useState} from "react";
import {Card, Stack, Button, Modal, Form} from "react-bootstrap";
import { Editor } from '@tinymce/tinymce-react';
import Response from "./Response/Response";
import useGetSujet from "@/requests/forum/useGetSujet";
import 'tinymce/tinymce';
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/plugins/image'
import './style.css';
import useGetResponseByIdSujet from "../../../requests/forum/useGetResponseByIdSujet";
import useAddResponseAboutSujet from "../../../requests/forum/useAddResponseAboutSujet";
import toast from "react-hot-toast";
import sujetProxie from "@proxies/sujet";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";
import useUploadFile from "../../../requests/upload/useUploadFile";
import $ from "jquery";
// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';
import {iconLienPj} from "../../../assets/icons/svgTinymce";
import {linkUrlTinyMce} from "../../../functions/linkUrlTinyMce";
import fileManagerProxy from "../../../proxies/fileManager";
import {imageTinyMce} from "../../../functions/ImageTinyMce";
import PersonnePartenaireSelection from "../../personne/PersonneSelection/PersonnePartenaireSelection";
import 'tinymce/tinymce';
import 'tinymce/plugins/lists';
import LoadingView from "../../LoadingView/LoadingView";

function ForumPostViewModal() {

    const editorRef = useRef();
    const sujetSnapshot = useSnapshot(sujetProxie);
    const id = sujetSnapshot.idSujet;
    // const sujet = useGetSujet(id);
    const responseSujet = useGetResponseByIdSujet(id);
    const utilisateur = useSnapshot(auth);
    const [stateContentEditor, setContentEdior] = useState("");
    const [stateAutheurSujet, setAutheurSujet] = useState(utilisateur.utilisateur);
    const agenceId = utilisateur.agence?.id;
    let objet = null;
    if (sujetSnapshot.sujet){
        objet = sujetSnapshot.sujet.objet
    }
    const [stateShowListAuth, setStateShowListAuth] = useState(false);
    const addResponse = useAddResponseAboutSujet(id, agenceId, stateContentEditor, "Re : "+objet, stateAutheurSujet);
    const uploadFile = useUploadFile({ type: "userfile" });
    if (uploadFile.data != null) {
        $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled",false);
        $(".tox-textfield").first().val(uploadFile.data.urlOriginale)

        if ($(".tox-dialog__title").text() == "Insérer une image"  || $(".tox-dialog__title").text() == "Modifier une image"){
            $(".tox-textfield").eq(1).val("L'image n'a pas pu être chargée")
        }
        else if ($(".tox-textfield").eq(1).val() == ""){
            $(".tox-textfield").eq(1).val("Cliquer ici pour ouvrir la pièce jointe.")
        }
        $(".tox-dialog__footer-end button").eq(1).trigger('click')
    }

    function repondreSujet(){
        if (stateContentEditor === ""){
            toast.error("Vous devez rédiger un contenu à la réponse")
        }else if (stateAutheurSujet === ""){
            toast.error("Vous devez selectionner un auteur pour la réponse")
        }
        else {
            addResponse.mutate(null);
            toast.success("Réponse publié")
            setContentEdior("");
            editorRef.current.setContent("")
        }
    }
    function logChange(val) {

        setAutheurSujet(val)

        // partenaires.data.data.forEach(function (element){
        //     if (element.personne.id == val){
        //         setAutheurSujet(element.personne);
        //
        //     }else if (val == utilisateur.utilisateur.id){
        //         setAutheurSujet(utilisateur.utilisateur)
        //     }
        // });
    }

    //test d'un ajout d'une réponse à la volée
    if (sujetSnapshot.sujet && responseSujet.data && utilisateur){
        let data = responseSujet.data.data;
        let dataSujet = sujetSnapshot.sujet;
        const responses = data.map((response, index) => <div key={index}>
            {index !== 0 && <div className='separator my-10' />}
            <Response
                author={response.auteur.personne}
                content={response.corps}
                dateCreation={response.dateCreation}
                idResponse={response.id}
                idSujet={id}
            />
        </div>);

        return <Modal
            show={sujetSnapshot.showModal.show}
            onHide={() => sujetProxie.showModal.show = false}
            size='lg'
            enforceFocus={false}
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
        <div>
            <Card className='mw-900px mx-auto'>
                <Card.Header>
                    <Card.Title>
                        <div className='symbol symbol-50px pe-5'>
                            <img src={dataSujet.auteur?.personne?.upload?.urlMiniature} alt='' />
                        </div>
                        <Stack gap={1} className='align-middle my-auto'>
                            <div className='fs-2'>{dataSujet.objet}</div>
                            <div className='fs-6 text-muted'>
                                par <span className='text-info'>{dataSujet.auteur.personne.prenom} {dataSujet.auteur.personne.nom}</span>
                            </div>
                        </Stack>
                    </Card.Title>
                    <div className='card-toolbar'>
                        <span className='badge badge-light-warning fs-7'>{dataSujet.dateCreation}</span>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: dataSujet.corps
                        }}></div>
                    <div className='border-gray-300 border-start-dotted ps-8'>
                        { (dataSujet.tabEtats.find(e => e.id == 0 && e.actif) && !dataSujet.isLectureSeule) && <div className='pt-5'>
                            <div className=' rounded position-relative p-5' style={{ zIndex: 100 }}>
                                <Form.Group className="mb-3">
                                    <Form.Label className={"required"}>Êtes vous l'auteur ?</Form.Label><br/>
                                    <Form.Check
                                        inline
                                        type='switch'
                                        label=""
                                        id='utilisateurIsAuteur'
                                        defaultChecked={true}
                                        onClick={(val)  => {
                                            if (val.target.checked){
                                                setAutheurSujet(utilisateur.utilisateur);
                                                setStateShowListAuth(false);
                                            }else {
                                                setStateShowListAuth(true);
                                            }
                                        }}
                                    />
                                </Form.Group>
                                <div hidden={!stateShowListAuth}>
                                    <p className="text-center">Choisir l'auteur de la réponse : </p>
                                    <PersonnePartenaireSelection
                                        logChange={logChange}
                                        stateAutheurSujet={stateAutheurSujet}
                                        utilisateur={utilisateur.utilisateur}
                                    />
                                    {/*<Select2*/}
                                    {/*    solid*/}
                                    {/*    data={dataPartenaires}*/}
                                    {/*    minimumResultsForSearch={3}*/}
                                    {/*    onChange={logChange}*/}
                                    {/*/>*/}
                                </div>
                                <div className="mt-3 bg-light min-h-100px p-2">
                                    <Editor
                                        id={"tinyMCEARecup#forumResonse#"}
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        // inline={true}
                                        init={{
                                            content_style: [contentUiCss, contentCss, '.mce-content-body { height: 200px; }'].join('\n'),
                                            skin: false,
                                            language : 'fr_FR',
                                            language_url : '/lang/tinymce/fr_FR/langs/fr_FR.js',
                                            menubar: false,
                                            plugins: ["link autolink image code table textcolor lists"],
                                            toolbar: 'undo redo | ' +
                                                'bold italic underline | bullist ' +
                                                '| link unlink',
                                            setup: function (editor) {
                                                editor.ui.registry.addIcon('link', iconLienPj);
                                                editor.on('init',function(e) {
                                                    editor.windowManager.oldOpen = editor.windowManager.open;
                                                    editor.windowManager.open = function (t, r) {
                                                        var modal = editor.windowManager.oldOpen.apply(this, [t, r]);  // call original
                                                        if (this && t.title === "Insert/Edit Link"){
                                                            linkUrlTinyMce(t, isValidHttpUrl, null, uploadFile);
                                                        }
                                                        else if (t.title === "Insert/Edit Image"){
                                                            fileManagerProxy.baseId = "tinyMCEARecup#forumResonse#";
                                                            imageTinyMce(isValidHttpUrl, null, uploadFile);
                                                        }
                                                        return modal;

                                                    }
                                                });
                                            }
                                        }}
                                        onChange={() => setContentEdior(editorRef.current.getContent())}
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <Button onClick={repondreSujet} className='mt-3'>Répondre</Button>
                            </div>
                        </div> }
                        <h4 className='text-gray-400 py-3'>
                            {responses.length} réponses
                        </h4>
                        {responses}
                    </div>
                </Card.Body>
            </Card>
        </div>
            </Modal.Body>
        </Modal>;
    }else {
        return <Modal
            show={sujetSnapshot.showModal.show}
            onHide={() => sujetProxie.showModal.show = false}
            size='lg'
            enforceFocus={false}
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <LoadingView/>
            </Modal.Body>
        </Modal>
    }

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            let checkUrlMotCle = string.slice(0,4)
            return string && checkUrlMotCle == "{url";
        }

        return url.protocol === "https:";
    }

}

export default ForumPostViewModal;