import {count} from "sms-length"
import {Button, Form, OverlayTrigger, Tooltip} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useSnapshot} from "valtio";
import smsProxy from "../../proxies/sms";
import 'emoji-picker-element';
import Select2 from "../Select2/Select2";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {BtSwal} from "../../utils/alerts/sweetAlert";

function SmsEditor({setValueSmsMessage, getValuesSmsMessage, checkIsAvecReponse}) {
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues, control, watch, setError, clearErrors } = useForm();
    const smsContent = watch('message');
    const [hiddenEmoji, setHiddenEmoji] = useState(true);
    const [motsCle, setMotCle] = useState("Mots clés")
    const [smsObject, setSmsObject] = useState({
        encoding : "GSM_7BIT",
        length : 0,
        messages : 1,
        remaining : 160,
        characterPerMessage : 160
    });
    const smsSnapshot = useSnapshot(smsProxy);

    useEffect(() => {
        if (smsContent || smsContent === ""){

            // let reg = smsContent.match(wordsBetweenTwoCaracters());
            let reg = [];
            let searchStart = true;
            let indexStartFind = 0;
            let searchStop = false;
            let indexStopFind = 0;
            for (let i = 0; i < smsContent.length; i++) {
                if (smsContent[i] === '{' && searchStart){
                    searchStart = false;
                    searchStop = true;
                    indexStartFind = i;
                }else if (smsContent[i] === '}' && searchStop){
                    searchStart = true;
                    searchStop = false;
                    indexStopFind = i;
                    reg.push(smsContent.substring(indexStartFind, indexStopFind+1))
                }
            }

            if (reg){
                reg.forEach((item) => {
                    if (item != "{prenom}" && item != "{nom}" && item != "{civilite}" && item != "{stop36180}"){
                        setValueSmsMessage("message", getValues("message").replace(item, ""))
                        setValue("message", getValues("message").replace(item, ""))
                        showErrorAlert("Attention mot clé incorrect", "Le mot clé "+item+" est incorrect.")
                    }
                })
            }

            if (smsContent.indexOf("{stop36180}") > -1 && smsContent.indexOf("\n{stop36180}") === -1){
                setValueSmsMessage("message", getValues("message").replace("{stop36180}", "\n{stop36180}"))
                setValue("message", getValues("message").replace("{stop36180}", "\n{stop36180}"))
                showErrorAlert("Attention erreur mention stop", "La mention stop doit être après un retour à la ligne.")
            }

            let objectTmp = count(smsContent);
            setSmsObject(objectTmp)
            if (objectTmp.encoding == "UTF16" && objectTmp.length > 536){
                setValueSmsMessage("message", getValuesSmsMessage("message"))
                setValue("message", getValuesSmsMessage("message"))
                if (!getValuesSmsMessage("isUnicode")){
                    showErrorAlert("Nombre de caractères maximum atteint.", "Certains caractères que vous souhaiter ajouter change l'encodage du message et réduit le nombre de caractères maximum à 536.")
                }else {
                    showErrorAlert("Nombre de caractères maximum atteint, impossible de rajouter les caractères suivant : ", smsContent.slice(536))
                }
            }else if (objectTmp.length > 1224){
                setValueSmsMessage("message", getValuesSmsMessage("message"))
                setValue("message", getValuesSmsMessage("message"))
                showErrorAlert("Nombre de caractères maximum atteint, impossible de rajouter les caractères suivant : ", smsContent.slice(1224))
            }else {
                setValueSmsMessage("message", smsContent)
                let countPrenom = (smsContent.match(/{prenom}/g) || []).length;
                let nbCaractPrenom = objectTmp.encoding == "UTF16" ? 8 : 10;
                let countNom = (smsContent.match(/{nom}/g) || []).length;
                let nbCaractNom = objectTmp.encoding == "UTF16" ? 5 : 7;
                let countCivilite = (smsContent.match(/{civilite}/g) || []).length;
                let nbCaractCivilite = objectTmp.encoding == "UTF16" ? 10 : 12;
                let nbCaractereBrut = objectTmp.length;
                if (countPrenom){
                    nbCaractereBrut = nbCaractereBrut - (nbCaractPrenom*countPrenom);
                }if (countNom){
                    nbCaractereBrut = nbCaractereBrut - (nbCaractNom*countNom);
                }if (countCivilite){
                    nbCaractereBrut = nbCaractereBrut - (nbCaractCivilite*countCivilite);
                }
                setValueSmsMessage("nbCaracteres", nbCaractereBrut)
            }
            if (objectTmp.encoding == "UTF16"){
                setValueSmsMessage("isUnicode", true)
            }else {
                setValueSmsMessage("isUnicode", false)
            }
        }
    }, [smsContent])

    useEffect(() => {
        reset(smsSnapshot.sms ?? {
            id: null,
            message:"\n{stop36180}"
        });
    }, [reset, smsSnapshot.sms]);

    function listener(e) {
        var startPos = document.getElementById('messageSms').selectionStart;
        var endPos = document.getElementById('messageSms').selectionEnd;
        let result = document.getElementById('messageSms').value.substring(0, startPos)
            + e.detail.unicode
            + document.getElementById('messageSms').value.substring(endPos, document.getElementById('messageSms').value.length);
        setValue("message", result)
        setHiddenEmoji(true);
    }


    useEffect(() => {
        document.querySelector('emoji-picker').addEventListener('emoji-click', listener)
        return () => {
            if (document.querySelector('emoji-picker')){
                document.querySelector('emoji-picker').removeEventListener('emoji-click', listener)
            }
        }
    })

    useEffect(() => {
        document.getElementById("emojiPickerSmsEdit").onclick = (e) => {
            setHiddenEmoji(!hiddenEmoji);
        }
    })

    useEffect(() => {
        if (motsCle !== "Mots clés"){
            setMotCle("Mots clés");
        }
    }, [motsCle])

    return <div>
        <div className="d-flex">
            <Button
                className="me-2 w-35px h-35px"
                id="emojiPickerSmsEdit"
                style={{padding: 4}}
            >
                🙂
            </Button>
            <Select2
                data={["Mots clés", "{civilite}", "{nom}", "{prenom}"]}
                // large
                closeOnSelect={true}
                solid
                selected={motsCle}
                onChange={(selected) => {
                    setMotCle(selected[0])
                    if (selected[0] !== "Mots clés"){
                        var startPos = document.getElementById('messageSms').selectionStart;
                        var endPos = document.getElementById('messageSms').selectionEnd;
                        let result = document.getElementById('messageSms').value.substring(0, startPos)
                            + selected[0]
                            + document.getElementById('messageSms').value.substring(endPos, document.getElementById('messageSms').value.length);
                        setValue("message", result)
                    }
                }}
            />
            {getValues('message') && getValues('message').indexOf("\n{stop36180}") > -1 ?
                <div className="d-flex align-items-center ms-2">
                    <Button
                        onClick={() => {
                            if (getValues('message') && getValues('message').indexOf("\n{stop36180}") > -1) {
                                setValue("message", document.getElementById('messageSms').value.replace("\n{stop36180}", ""))
                                if (!getValuesSmsMessage('isAvecReponse')){
                                    BtSwal.fire('Attention', 'Sans cette mention, certains opérateurs affichent un numéro au lieu de l\'expéditeur personnalisé.', 'warning')
                                }
                            }
                        }}
                        className="fa fa-trash">
                    </Button>
                    <span className="ms-3">Supprimer la mention stop</span>
                </div>
                :
                <div className="d-flex align-items-center ms-2">
                    <Button
                        onClick={() => {
                            if (getValues('message').indexOf("\n{stop36180}") === -1) {
                                setValue("message", document.getElementById('messageSms').value+"\n{stop36180}");
                            }
                        }}
                        className="fa fa-plus">
                    </Button>
                    <span className="ms-3">Ajouter la mention stop</span>
                </div>
            }
        </div>
        <div id="emojiPickerSmsEditContainer" hidden={hiddenEmoji} className="position-absolute">
            <emoji-picker></emoji-picker>
        </div>
        <Form.Group className="mt-2">
            <Form.Control
                maxLength={1224}
                id="messageSms"
                as='textarea'
                rows={5}
                style={{borderRadius: ".95rem .95rem 0 0"}}
                {...register('message')}
            />
        </Form.Group>
        <div style={{color :"white", borderRadius: "0 0 .95rem .95rem"}} className="d-flex justify-content-around text-center bg-primary fw-bolder p-2">
            <span>Codage détecté <br/> {smsObject.encoding}</span>
            <span>Nombre de message <br/> {smsObject.messages}</span>
            <span>Caractères <br/> {smsObject.length}</span>
            <span>Restant <br/> {smsObject.remaining}/{smsObject.characterPerMessage}</span>
        </div>
        <small className="color-primary"><i className="fa fa-exclamation-triangle color-primary"/> L'ajout de mots clés fausse l'estimation du nombre de caractères. </small>
        <br/><small hidden={checkIsAvecReponse} className="color-primary"><i className="fa fa-info-circle color-primary"/> Pour des raisons légales, la personnalisation de l’émetteur pour les envois vers la France ne sera prise en compte que si la fin du message contient la mention STOP au 36180, précédée d’un espace ou d’un retour à la ligne. Sans cette mention dans le message, l’expéditeur sera un numéro court, ou l’opérateur peut décider de la rajouter par lui-même. Certains MVNO français ne prennent pas en charge le changement d’expéditeur.</small>
        <br/><small className="color-primary">
        <i className="fa fa-info-circle color-primary"/> Envoi à caractère commercial. Pour vos campagnes de SMS marketing, vous devez impérativement : <br/>
        1) Avoir obtenu le consentement de vos contacts lors de la collecte de leur numéro, <br/>
        2) Fournir la possiblité à vos contacts de se désinscrire (mention STOP obligatoire en cas de changement d'expéditeur). <br/>
        3) L'envoi de SMS à caractère commercial est interdit par la loi hors de la plage horaire 8h00-20h00 en semaine (10h00-19h00 le samedi), ainsi que le dimanche et les jours fériés.

    </small>

    </div>

}

export default SmsEditor;