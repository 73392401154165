import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";

export default function useSendAccesPartenaire() {


    return useMutation(sendAcces, {
        onSuccess: () => {
        },
        onError: (err) => {

        },
    });

}

function sendAcces(id) {

    if (id != null)
        return api.get(`personne/${id}/envoyerIdentifiant`, {
            retry: {
                limit : 0
            }
        });

}