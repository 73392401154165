import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetResponsablePlaceLimite(search, length) {

    if (!search){
        search = "";
    }
    if (!length){
        length = 100;
    }
    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = {
        data: "prenomNom",
        searchable: true,
        orderable: true,
        search: {
            value: '',
            regex: false,
        }
    }


    filters.columns[2] = {
        data: "email",
        searchable: true,
        orderable: true,
        search: {
            value: '',
            regex: false,
        }
    }

    filters.columns[1] = {
        data: "id",
        searchable: true,
        orderable: true,
        search: {
            value: '',
            regex: false,
        }
    }

    filters.search = {
        value:search
    }

    filters.order[0] = {
        column:1,
        dir: "asc"
    }

    return useQuery(
        ['responsablePlaceLimite', search],
        () => api.get('responsablePlaceLimite', {
            searchParams: Qs.stringify({
                start: 0,
                length: length,
                ...filters,
            },{ arrayFormat: 'brackets' })
        }).json(),
        {cacheTime:0}

    );
}