import HeaderSelectionDropDown from "./HeaderSelectionDropdown";

function HeaderSelection({ onChange }) {
    return <HeaderSelectionDropDown
        className='btn-secondary btn-sm hide-after'
        onChange={onChange}
    >
        Choisir une entête
    </HeaderSelectionDropDown>;
}

export default HeaderSelection;