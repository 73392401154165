import { useQuery } from "react-query";
import api from "../../../services/api";

export default function useGetLinkPreview(url) {
    return useQuery(
        [url],
        async () => {
            return api.get(`previewPageWeb?url=${url}`).json();
        },
        {
            enabled: url != null,
        }
    );
}

