import { Alert, Dropdown, Spinner } from "react-bootstrap";
import useGetHeaders from "@requests/communication/header/useGetHeaders";


function HeaderSelectionDropDown({ children, className, onChange }) {
    const headers = useGetHeaders();

    return <Dropdown>
        <Dropdown.Toggle className={`${className ?? ''} hide-after`}>
            {children}
        </Dropdown.Toggle>
        <Dropdown.Menu className='fs-6 w-400px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
            {headers.isLoading && <div className='text-center my-5'>
                <Spinner animation='border' />
            </div>}
            {headers.isError &&
                <Alert variant='danger' className='m-2'>
                    {headers.error?.message ?? 'Erreur'}
                </Alert>
            }
            {headers.isSuccess &&
                <div className='mh-300px scroll-y'>
                    {headers.data?.data?.map(header => <div className='menu-item px-3' key={header.id}>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => onChange(header)}
                        >
                            <img style={{ width: '100%' }} src={header.upload?.urlOriginale} />
                        </Dropdown.Item>
                    </div>)}
                </div>
            }
        </Dropdown.Menu>
    </Dropdown>;
}

export default HeaderSelectionDropDown;