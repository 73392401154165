import { useQuery } from "react-query";
import api from "@services/api";
import auth from "@services/auth";
import { sleep } from "@utils/sleep";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";

export default function useGetUtilisateur(fetch) {
    return useQuery(
        ['utilisateur'],
        async () => {
            return api.get('utilisateur').json();
        },
        {
            onSuccess: data => {
                auth.utilisateur = data?.utilisateur;
                auth.agence = data?.agence;
                auth.parametreFacturation = data?.parametreFacturation;
            },
            onError: (err) => {
                showErrorAlert("Erreur lors de l'authentification", err.message);
            },
            enabled: fetch,
        }
    );
}