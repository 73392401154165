import { useForm } from "react-hook-form";
import LoadingButton from "@components/LoadingButton/LoadingButton";
import useSendIdentifiant from "../../requests/utilisateur/useSendIdentifiant";
import {emailPattern} from "../../functions/patterns";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";

function SendIdentifiantFrom() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const {agence} = useParams();

    const sendIdentifiant = useSendIdentifiant({
        onSuccess: () => {
            navigate("/"+agence+"/success",
                {
                    state : {
                        titleText : "Votre identifiant a bien été envoyé par mail."
                    }
                }
            );
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    function onSubmit(data) {
        sendIdentifiant.mutate(data);
    }

    return <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' onSubmit={handleSubmit(onSubmit)} >
        <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Demande d'envoi</h1>
            <div className='text-gray-400 fw-bold fs-4'>de votre identifiant</div>
        </div>
        <div className='fv-row mb-10 fv-plugins-icon-container'>
            <label className='form-label fs-6 fw-bolder text-dark'>Nom</label>
            <input
                className='form-control form-control-lg form-control-solid'
                type='text'
                name='nom'
                autoComplete='off'
                {...register('nom', { required: true })}
            />
            {errors.nom &&
                <div className='fv-plugins-message-container invalid-feedback'>
                    Ce champ est requis
                </div>
            }
            <label className='form-label fs-6 fw-bolder text-dark'>Prénom</label>
            <input
                className='form-control form-control-lg form-control-solid'
                type='text'
                name='prenom'
                autoComplete='off'
                {...register('prenom', { required: true })}
            />
            {errors.prenom &&
                <div className='fv-plugins-message-container invalid-feedback'>
                    Ce champ est requis
                </div>
            }
            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
            <input
                className='form-control form-control-lg form-control-solid'
                type='email'
                name='email'
                autoComplete='off'
                {...register('email', { required: true, pattern: emailPattern() })}
            />
            {errors.email &&
                <div className='fv-plugins-message-container invalid-feedback'>
                    Ce champ est requis
                </div>
            }
        </div>
        <div className='text-center'>
            <LoadingButton
                loading={sendIdentifiant.isLoading}
                type='submit'
            >Recevoir vos identifiants</LoadingButton>
        </div>
    </form>;
}

export default SendIdentifiantFrom;