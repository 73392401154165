import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useDeleteHeader() {

    const queryClient = useQueryClient();

    return useMutation(deleteHeader, {
        onSuccess: () => {
            queryClient.invalidateQueries('headers');
            toast.success("Entête supprimé");
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

}

function deleteHeader(id) {

    if (id != null)
        return api.delete(`headerEmail/${id}`).json();

}