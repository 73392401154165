import { Controller } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import { FormControl, InputGroup } from "react-bootstrap";
import { French } from "flatpickr/dist/l10n/fr"
import { ReactComponent as Gen014 } from '@assets/icons/gen014.svg'
import moment from "moment";
import $ from "jquery";

function FormDatePicker({ control, name, solid, enableTime, idDate, rules, id, maxDate, getValues, setValue }) {
    let selectId = "";
    if (!idDate && id){
        selectId = id;
    }else if (idDate){
        selectId = idDate
    }
    return <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => <Flatpickr
            defaultValue={field.value}
            // maxDate={maxDate ? moment().subtract(maxDate, "years").format("DD/MM/YYYY") : ""}
            // value={$("#"+selectId).val() !== "" ? $("#"+selectId).val() : field.value}
            onChange={date => {
                if (enableTime)
                    field.onChange(moment(date[0]).format("DD/MM/YYYY à HH:mm"));
                else
                    field.onChange(moment(date[0]).format("DD/MM/YYYY"));

                if (idDate){
                    ($(".timeForDate"+idDate).val(""))
                    if (idDate === "idDate0"){
                        let dateReponseSouhaitee = moment(new Date($("#idDate0").val().split('/')[2]+"-"+$("#idDate0").val().split('/')[1]+"-"+$("#idDate0").val().split('/')[0]))
                        dateReponseSouhaitee = dateReponseSouhaitee.subtract(7, "days");
                        dateReponseSouhaitee = dateReponseSouhaitee.format("DD/MM/YYYY")

                        let dateRappel = moment(new Date($("#idDate0").val().split('/')[2]+"-"+$("#idDate0").val().split('/')[1]+"-"+$("#idDate0").val().split('/')[0]));
                        dateRappel = dateRappel.subtract(2, "days");
                        dateRappel = dateRappel.format("DD/MM/YYYY");

                        let dateRelance = moment(new Date($("#idDate0").val().split('/')[2]+"-"+$("#idDate0").val().split('/')[1]+"-"+$("#idDate0").val().split('/')[0]))
                        let dateAujourdhui = moment()
                        let diffDrDa = dateRelance.diff(dateAujourdhui, "days")
                        dateRelance = dateRelance.subtract(Math.round(diffDrDa/2), "days")
                        dateRelance = dateRelance.format("DD/MM/YYYY")
                        // setValue('dateReponse', dateReponseSouhaitee)
                        // setValue('dateRelance', dateRelance)
                        // setValue('dateRappel', dateRappel)
                        // $("#reponseSouhaiteAvantLe").val(dateReponseSouhaitee)
                        // $("#relanceLe").val(dateRelance)
                        // $("#dateRappel").val(dateRappel)
                    }
                }
            }}
            render={
                (props, ref) => {
                    return <InputGroup className={solid ? 'input-group-solid' : ''}>
                        <InputGroup.Text>
                            <span className='svg-icon svg-icon-fluid w-25px'>
                                <Gen014 />
                            </span>
                        </InputGroup.Text>
                        <FormControl id={selectId} {...props} ref={ref} className={solid ? 'form-control-solid' : ''} />
                    </InputGroup>
                }
            }
            options={{
                locale: French,
                dateFormat: enableTime ? 'd/m/Y à H:i' : 'd/m/Y',
                enableTime: enableTime,
                maxDate: maxDate ? moment().subtract(maxDate, "years").format("DD/MM/YYYY") : ""
            }}
        />}
    />;
}

export default FormDatePicker;