import React, { useEffect, useState } from 'react';
import {Alert, Button, Card, Col, Form, Modal, Row} from 'react-bootstrap';
import Canvas from './components/Canvas/Canvas';
import { exportMail } from './exportMail';
import ModulesList from './components/ModulesList/ModulesList';
import { DragDropContext } from 'react-beautiful-dnd';
import { reorder } from './utils/reorder';
import { mailTemplate } from './mailTemplate/mailTemplate';
import { mailInvitationTemplate } from './mailTemplate/mailInvitationTemplate';
import { v4 as uuidv4 } from 'uuid';
import './MailEditor.scss';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import HeaderSelection from './components/Header/HeaderSelection/HeaderSelection';
import HeaderEditor from './components/Header/HeaderEditor';
import FooterSelection from './components/Footer/FooterSelection/FooterSelection';
import FooterEditor from './components/Footer/FooterEditor';
import mailEditorProxy from "../../proxies/mailEditorProxy";
import {useSnapshot} from "valtio";
import $ from "jquery";
import {count} from "react-table/src/aggregations";
import AddJustOneUrlOnImageModal from "./Modals/AddJustOneUrlOnImageModal";
import AddTwoUrlOnImageModal from "./Modals/AddTwoUrlOnImageModal";
import AddCustomButtonTransfert from "./Modals/AddCustomButtonTransfert";
import {mailRencontreTemplate} from "./mailTemplate/mailRencontreTemplate";

const MailEditor = forwardRef(({ value, isInvitation, isRencontre = false, hiddenFooterHeader = false, hiddenVisualisezLeEnLigne = false }, ref) => {
    const [sections, setSections] = useState(value ?? { order: [] });
    const mailEditorSnap = useSnapshot(mailEditorProxy);
    const [paddingInputTop, setPaddingInputTop] = useState(0);
    const [paddingInputBot, setPaddingInputBot] = useState(0);
    const [paddingInputLeft, setPaddingInputLeft] = useState(0);
    const [paddingInputRight, setPaddingInputRight] = useState(0);
    let modulesOrder;
    let choixMailTemplate;
    if (isInvitation){
        modulesOrder = Object.keys(mailInvitationTemplate);
        choixMailTemplate = mailInvitationTemplate;
    }else if (isRencontre){
        modulesOrder = Object.keys(mailRencontreTemplate);
        choixMailTemplate = mailRencontreTemplate;
    }
    else {
        modulesOrder = Object.keys(mailTemplate);
        choixMailTemplate = mailTemplate;
    }

    useEffect(() => {
        setSections(value ?? { order: [] });
    }, [value]);

    useEffect(() => {
        if (mailEditorSnap.padding && typeof mailEditorSnap.padding === 'string' && count(mailEditorSnap.padding.split(" ")) == 4) {
            setPaddingInputTop(mailEditorSnap.padding.split(" ")[0].replace("px", ''))
            setPaddingInputRight(mailEditorSnap.padding.split(" ")[1].replace("px", ''))
            setPaddingInputBot(mailEditorSnap.padding.split(" ")[2].replace("px", ''))
            setPaddingInputLeft(mailEditorSnap.padding.split(" ")[3].replace("px", ''))
        }else {
            setPaddingInputTop(0)
            setPaddingInputRight(0)
            setPaddingInputBot(0)
            setPaddingInputLeft(0)
        }
    }, [mailEditorSnap.padding])

    useImperativeHandle(ref, () => ({
        save() {
            return {
                json: sections,
                html: exportMail(sections, isInvitation, hiddenVisualisezLeEnLigne),
            };
        }
    }));

    function handleOnDragEnd(result) {
        if (!result.destination ||
            (result.source.droppableId === result.destination.droppableId &&
                result.source.index === result.destination.index)) return;

        // Moving section inside canvas
        if (result.source.droppableId === result.destination.droppableId &&
            result.destination.droppableId === 'canvas') {
            const newOrder = reorder(
                sections.order,
                result.source.index,
                result.destination.index
            );

            setSections({
                ...sections,
                order: newOrder,
            });
        }

        // Adding new section
        else if (result.source.droppableId === 'modulesList' &&
            result.destination.droppableId === 'canvas') {
            const id = uuidv4();
            const type = modulesOrder[result.source.index];

            let newOrder = [...sections.order];
            newOrder.splice(result.destination.index, 0, id);

            setSections({
                ...sections,
                [id]: {
                    type: type,
                    content: choixMailTemplate[type].defaultContent,
                },
                order: newOrder,
            });
        }
    }

    return <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className='d-flex h-100'>
            <div>
                <Card className='p-10 flex-column-fluid h-100 bg-light'>
                    <div className='scroll-y'>
                        <ModulesList isInvitation={isInvitation} order={modulesOrder} />
                        {/* <Button className='ml-auto' onClick={() => console.log(exportMail(blocks))}>Enregistrer</Button> */}
                    </div>
                </Card>
            </div>
            <div className='ps-10 flex-grow-1 flex-center'>
                <Card className='bg-light mh-100'>
                    <div className='scroll-y'>
                        <div className='text-center my-2'>
                            {/*<ColorPicker color={sections?.backgroundColor ?? '#fff'} onChange={(color) => {*/}
                            {/*    setSections({*/}
                            {/*        ...sections,*/}
                            {/*        backgroundColor: color,*/}
                            {/*    });*/}
                            {/*}} />*/}
                        </div>
                        {sections?.header == null &&
                            <div className={`d-flex justify-content-center mt-10 ${hiddenFooterHeader ? 'd-none' : ''}`}>
                                <HeaderSelection onChange={(header) => {
                                    setSections({
                                        ...sections,
                                        header: header?.upload?.urlOriginale,
                                        headerFull : header
                                    });
                                }} />
                            </div>
                        }
                        <table className='mailEditor' width='100%' height='100%' cellSpacing='0' cellPadding='0' border='0'>
                            <tbody>
                                <tr>
                                    <td id='bodyCell' valign='top' align='center'>
                                        <table id='emailBody' width='600' cellSpacing='0' cellPadding='0' border='0' >
                                            <tbody>
                                                {sections?.header != null && <HeaderEditor
                                                    header={sections?.header}
                                                    onChange={(header) => {
                                                        setSections({
                                                            ...sections,
                                                            header: header?.upload?.urlOriginale,
                                                            headerFull : header
                                                        });
                                                    }}
                                                />}
                                                {/*{isInvitation && <Invitation />}*/}
                                                <Canvas
                                                    sections={sections}
                                                    onChange={setSections}
                                                    isInvitation={isInvitation}
                                                />
                                            </tbody>
                                        </table>
                                        {sections?.footer != null && <FooterEditor
                                            footer={sections?.footer}
                                            onChange={(footer) => {
                                                setSections({
                                                    ...sections,
                                                    footer : footer?.corps,
                                                    footerFull : footer
                                                });
                                            }}
                                        />}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {sections?.footer == null &&
                            <div className={`d-flex justify-content-center mb-10 ${hiddenFooterHeader ? 'd-none' : ''}`}>
                                <FooterSelection onChange={(footer) => {
                                    setSections({
                                        ...sections,
                                        footer : footer?.corps,
                                        footerFull : footer
                                    });
                                }} />
                            </div>
                        }
                    </div>
                </Card>
            </div>
        </div>
        <Modal
            show={mailEditorSnap.modalPadding}
            onHide={() => {
                mailEditorProxy.modalPadding = false
            }}
            size='lg'
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <div className='w-100 stepper stepper-links d-flex flex-column between'>
                    <div className='stepper-nav'>
                        <h3 className='stepper-title'>Réglage des marges du bloc</h3>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Alert hidden={false} id="infoMessage"  className='mt-2 mx-auto' key='info' variant='info'>
                    Merci d'indiquer les valeurs des marges (en pixel) de chaque côté du bloc. Valeur maximale 25 (pour les blocs comportant des images). <br/>
                    Exemple : Haut : 20 Bas : 20 Gauche : 20 Droite : 20
                </Alert>
                <Row className='mt-5'>
                    <Col sm={6} className='mt-5 mt-sm-0'>
                        <Form.Label>Haut</Form.Label>
                        <Form.Control value={paddingInputTop} onChange={(e) => {
                            setPaddingInputTop(e.target.value)
                        }} />
                    </Col>
                    <Col sm={6} className='mt-5 mt-sm-0'>
                        <Form.Label>Droite</Form.Label>
                        <Form.Control value={paddingInputRight} onChange={(e) => {
                            setPaddingInputRight(e.target.value)
                        }} />
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col sm={6} className='mt-5 mt-sm-0'>
                        <Form.Label>Bas</Form.Label>
                        <Form.Control value={paddingInputBot} onChange={(e) => {
                            setPaddingInputBot(e.target.value)
                        }} />
                    </Col>
                    <Col sm={6} className='mt-5 mt-sm-0'>
                        <Form.Label>Gauche</Form.Label>
                        <Form.Control value={paddingInputLeft} onChange={(e) => {
                            setPaddingInputLeft(e.target.value)
                        }} />
                    </Col>
                </Row>
                <Alert hidden={true} id="errorMessage"  className='mt-2 mx-auto' key='danger' variant='danger'>
                    Erreur format non correct. Attention à la valeur maximale de 25. Il doit également avoir une valeur pour chaque champ.
                </Alert>

                {/*{errors.txtLien && <Form.Text className='text-danger'>Texte pour l'url requis</Form.Text>}*/}
                <div className="d-flex justify-content-center mt-2">
                    <Button
                        variant='primary'
                        onClick={() => {
                            if (!isNaN(paddingInputTop) && !isNaN(paddingInputRight) && !isNaN(paddingInputBot) && !isNaN(paddingInputLeft)
                                && ((paddingInputTop <= 25 && paddingInputRight <= 25 && paddingInputBot <= 25 && paddingInputLeft <= 25) || mailEditorSnap?.block?.type == "singleText")
                                && paddingInputTop && paddingInputRight && paddingInputBot && paddingInputLeft) {
                                let paddingInput = paddingInputTop+"px "+paddingInputRight+"px "+paddingInputBot+"px "+paddingInputLeft+"px";
                                mailEditorSnap.setPaddingSection(paddingInput)
                                let result = {
                                    ...mailEditorSnap.block,
                                    padding:paddingInput
                                };
                                mailEditorSnap.onChange(result)
                                $("[data-rbd-draggable-id='"+mailEditorSnap.id+"'] .flexibleContainerCell").css('padding', paddingInput)
                                mailEditorProxy.modalPadding = false;
                            }else {
                                $("#errorMessage").attr("hidden",false);
                            }

                        }}
                    >Valider</Button>
                </div>
            </Modal.Body>
        </Modal>
        <AddJustOneUrlOnImageModal/>
        <AddTwoUrlOnImageModal/>
        <AddCustomButtonTransfert/>
    </DragDropContext >;
});

export default MailEditor;