import { Modal, Button } from "react-bootstrap";
import { BtSwal } from "@utils/alerts/sweetAlert";
import {useEffect, useRef, useState} from "react";
import { useSnapshot } from "valtio";
import mailsProxy from "@proxies/mails";
import ReglagesStep from "./ReglagesStep/ReglagesStep";
import FinalisationStep from "./FinalisationStep/FinalisationStep";
import BlockUI from '@components/BlockUI/BlockUI';
import RedactionStep from "./RedactionStep/RedactionStep";
import DestinatairesStep from "../../DestinatairesStep/DestinatairesStep";
import useInscrireDestinataireMetaTable from "../../../../requests/communication/useInscrireDestinataireMetaTable";
import {useQueryClient} from "react-query";
import motsClesProxy from "../../../../proxies/motsClesProxy";
import {showErrorAlert} from "../../../../utils/alerts/showErrorAlert";
import auth from "../../../../services/auth";

function EditModal() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const stepRef = useRef();
    const mailsSnapshot = useSnapshot(mailsProxy);
    let queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    const inscrireDestinataireMetaTable = useInscrireDestinataireMetaTable({
        onSuccess: () => {
            queryClient.invalidateQueries(["email", mailsSnapshot?.mail.id, 'correspondants']);
            snapAuth.websocket.send("email")
            snapAuth.websocket.send(mailsSnapshot.mail?.id)
            snapAuth.websocket.send("correspondants")
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    useEffect(() => {
        if (mailsSnapshot.destinataireIsChecked){
            console.log(mailsSnapshot.destinataireIsChecked)
        }
    }, [mailsSnapshot])

    const steps = [
        {
            name: 'Réglages',
            renderComponent: (ref) => <ReglagesStep ref={ref} />,
        },
        {
            name: 'Rédaction',
            renderComponent: (ref) => <RedactionStep ref={ref} />
        },
        {
            name: 'Destinataires',
            renderComponent: (ref) => <DestinatairesStep baseRoute='email' id={mailsSnapshot.mail?.id} />
        },
        {
            name: 'Finalisation',
            renderComponent: (ref) => <FinalisationStep />,
        },
    ];

    async function hideValidation() {
        if (mailsSnapshot?.mail?.id && snapAuth.tabIdMailVerrou.indexOf(mailsSnapshot?.mail?.id) !== -1){
            auth.tabIdMailVerrou = snapAuth.tabIdMailVerrou.filter(i => i !== mailsSnapshot?.mail?.id)
            // auth.tabIdMailVerrou.splice(snapAuth.tabIdMailVerrou.indexOf(mailsSnapshot?.mail?.id), 1);
        }
        if (currentIndex === 3){
            mailsProxy.editModal.show = false;
        }else {
            const result = await BtSwal.fire({
                title: 'Voulez-vous quitter ?',
                text: "Si vous quittez vous perdrez ce que vous n'avez pas enregistré",
                icon: 'question',
                showDenyButton: true,
                denyButtonText: 'Quitter',
                confirmButtonText: 'Annuler',
                customClass: {
                    confirmButton: 'btn btn-secondary',
                    denyButton: 'btn btn-danger',
                },
            });
            if (result.isDenied)
                mailsProxy.editModal.show = false;
        }
        queryClient.invalidateQueries('emails');
        snapAuth.websocket.send("emails")

    }

    async function goToStep(step) {
        setCurrentIndex(step);
        let btswal;
        let checkDestinataire = false;
        if (step === 3 && mailsSnapshot.destinataireIsChecked){
            let message = "Attention des destinataires sélectionnés n'ont pas été ajoutés. Souhaitez-vous tout de même continuer ?"
            if (!mailsSnapshot.destinataireAlertIsAdd){
                message = "Attention des destinataires sélectionnés n'ont pas été supprimés. Souhaitez-vous tout de même continuer ?"
            }
            await BtSwal.fire({
                title: message,
                showDenyButton: true,
                confirmButtonText: `Oui`,
                denyButtonText: `Non`,
                icon : 'info'
            }).then((result) => {
                if (!result.isConfirmed){
                    checkDestinataire = true;
                }

            })
        }
        if (checkDestinataire){
            return false;
        }
        if (step === 2 && mailsSnapshot.tabIdsDestinataires !== null){
            btswal = BtSwal.fire({
                title: 'Souhaitez-vous reprendre les destinataires du mail ?',
                showDenyButton: true,
                confirmButtonText: `Oui`,
                denyButtonText: `Non`,
            }).then((result) => {
                if (result.isConfirmed){
                    let data = {
                        baseRoute : "email",
                        id : mailsSnapshot.mail.id,
                        tabIds : mailsSnapshot.tabIdsDestinataires
                    }
                    inscrireDestinataireMetaTable.mutate(data);
                }

            })
        }
        if (step === 2 && mailsSnapshot.tabIdsDestinataires !== null && await btswal){
            if (stepRef.current?.save != null) {
                mailsProxy.editModal.loading = true;
                if (await stepRef.current.save())
                    mailsProxy.editModal.step = step;
                mailsProxy.editModal.loading = false;
            }
            else mailsProxy.editModal.step = step;
        }else {
            // Pour reset les mots clés
            if (step === 1){
                motsClesProxy.motsCles = [
                    {
                        type: 'menuitem',
                        text: 'prenom',
                        insert : '<span contenteditable="false" class="mceNonEditable">{prenom}</span>'
                    },
                    {
                        type: 'menuitem',
                        text: 'nom',
                        insert : '<span contenteditable="false" class="mceNonEditable">{nom}</span>'
                    },
                    {
                        type: 'menuitem',
                        text: 'email',
                        insert : '<span contenteditable="false" class="mceNonEditable">{email}</span>'
                    },
                    {
                        type: 'menuitem',
                        text: 'civilite',
                        insert : '<span contenteditable="false" class="mceNonEditable">{civilite}</span>'
                    },
                    {
                        type: 'menuitem',
                        text: 'te/vous',
                        insert : '<span contenteditable="false" class="mceNonEditable">{vouvoiement}</span>'
                    },
                    {
                        type: 'menuitem',
                        text: 'toi/vous',
                        insert : '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronoms}</span>'
                    },
                    {
                        type: 'menuitem',
                        text: 'ta/votre',
                        insert : '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsFeminin}</span>'
                    },
                    {
                        type: 'menuitem',
                        text: 'ton/votre',
                        insert : '<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsMasculin}</span>'
                    },
                    {
                        type: 'menuitem',
                        text: 'tes/vos',
                        insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementPronomsPluriel}</span>'
                    },
                    {
                        type: 'menuitem',
                        text: 't\'/vous',
                        insert :'<span contenteditable="false" class="mceNonEditable">{vouvoiementBis}</span>'
                    },
                    {
                        type: 'menuitem',
                        text: 'Nom agence',
                        insert :'<span contenteditable="false" class="mceNonEditable">{nomAgence}</span>'
                    },
                    {
                        type: 'menuitem',
                        text: 'Transfert',
                        insert: '<a style="padding: 8px 12px; border: 1px solid  #0060a5; margin: 40px 0 0 10px; background-color: #0060a5; cursor: pointer; border-radius: 2px; font-family: Helvetica, Arial, sans-serif; font-size: 14px; color: #ffffff; text-decoration: none; font-weight: bold; display: inline-block;" href="{urlTransfertEmail}" target="_blank" rel="noopener"> Transf&eacute;rer</a>'
                    }
                ]
            }
            if (stepRef.current?.save != null) {
                mailsProxy.editModal.loading = true;
                if (await stepRef.current.save())
                    mailsProxy.editModal.step = step;
                mailsProxy.editModal.loading = false;
            }
            else mailsProxy.editModal.step = step;
        }

    }

    return <Modal
        fullscreen
        show={mailsSnapshot.editModal.show}
        onHide={hideValidation}
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
        keyboard={false}
    >
        <Modal.Header closeButton>
            <div className='w-100 stepper stepper-links d-flex flex-column between'>
                <div className='stepper-nav'>
                    {steps.map((step, index) => <div
                        className={`stepper-item ${index === mailsSnapshot.editModal.step ? 'current' : ''} ${index < mailsSnapshot.editModal.step ? 'completed' : ''} hoverable`}
                        key={step.name}
                        onClick={() => {
                            goToStep(index);
                        }}
                    >
                        <h3 className='stepper-title'>{step.name}</h3>
                    </div>)}
                </div>
            </div>
        </Modal.Header>
        <BlockUI loading={mailsSnapshot.editModal.loading} className='overflow-scroll modal-body'>
            {steps[mailsSnapshot.editModal.step]?.renderComponent(stepRef)}
        </BlockUI>
        <Modal.Footer>
            {/* TODO: Make loading indicator buttons */}
            {mailsSnapshot.editModal.step > 0 && <Button
                variant='danger'
                className='me-auto'
                onClick={() => mailsProxy.editModal.step--}
            >Précédent</Button>}
            {mailsSnapshot.editModal.step < steps.length - 1 && <Button
                text='Suivant'
                variant='secondary'
                className='ms-auto'
                onClick={async () => {
                    goToStep(mailsSnapshot.editModal.step + 1);
                }}
            >Suivant</Button>}
        </Modal.Footer>
    </Modal>;
}

export default EditModal;