import { useMutation } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import Qs from "qs";

export default function useGetParticipants() {

    return useMutation(participants, {
        onError: (err) => {
            toast.error(err.message);
        },
    });
}

function participants(id) {
    if (id != null)
        return api.get(`rencontreSpeedBusiness/${id}/participants`, {
            searchParams: Qs.stringify({
                start: 0,
                length: 1000,
            }, { arrayFormat: 'brackets' }),
        }).json();
}