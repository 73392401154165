import {Modal} from "react-bootstrap";
import {useSnapshot} from "valtio";
import React, {useMemo, useState} from "react";
import invitationsProxy from "../../../proxies/invitations";
import MetaTable from "../../../components/MetaTable/MetaTable";
import {formatDetailsEmail} from "../../../functions/formatDetailsEmail";
import Select2 from "../../../components/Select2/Select2";


function DetailInvitationModal() {
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const [type, setType] = useState("");
    const colSearch = useMemo(() => {
        return {
            'type': type,
        };
    }, [type]);
    const tabFilter = useMemo(() => {
        return {
            'idSupport': invitationsSnapshot.invitation?.id,
            'idDestinataire': invitationsSnapshot.destinataire?.id
        };
    }, [invitationsSnapshot.invitation?.id, invitationsSnapshot.destinataire?.id]);

    const columns = useMemo(() => [
        // {
        //     Header: 'Id',
        //     accessor: 'id',
        //     width: 80,
        //     minWidth: 60,
        // },
        {
            Header: 'Date',
            accessor: 'date',
            minWidth: 150,
            maxWidth: 150,
            Footer: type => {
                let tabTypes = [
                    {id: "MessageDelayed", text:"Envoi retardé"},
                    {id: "MessageDeliveryFailed", text:"Echec de l'envoi du message"},
                    {id: "MessageHeld", text:"Message en attente d'envoi"},
                    {id: "MessageLinkClicked", text:"Lien cliqué"},
                    {id: "MessageLoaded", text:"Message consulté"},
                    {id: "MessageSent", text:"Message envoyé"},
                ]
                return <Select2
                    data={tabTypes}
                    closeOnSelect={true}
                    allowClear={true}
                    placeholder='Filtre détail'
                    onChange={(selected) => {
                        selected = selected?.at(0);
                        setType(selected)
                    }}/>
            },
        },
        {
            Header: 'Type',
            accessor: 'type',
            hidden:true
        },
        {
            Header: 'Détails',
            minWidth: 600,
            maxWidth: 600,
            Cell: ({ row }) => {
                let detailsFormate = formatDetailsEmail(JSON.parse(row.original.detail), row.original.type);
                return [<div><p  className={`${detailsFormate.error && "text-danger"}`} style={{fontWeight: "bold"}}><i className={`${detailsFormate.icon} text-black`}/> {row.original.type}</p></div>,<div>{detailsFormate.details}</div>]
            }
        }
    ], []);

    return <Modal
        show={invitationsSnapshot.detailInvitationModal.show}
        onHide={() => invitationsProxy.detailInvitationModal.show = false}
        fullscreen
        dialogClassName='p-9'
        contentClassName='modal-rounded'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Detail du suivi de l'invitation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <MetaTable
                className='mt-5'
                height={500}
                url={`activiteEmail`}
                keys={['activiteEmail', invitationsSnapshot.invitation?.id, 'correspondants']}
                columns={columns}
                tabFilter={tabFilter}
                colSearch={colSearch}
                showFiltres={true}
            />
        </Modal.Body>
    </Modal>;
}

export default DetailInvitationModal;