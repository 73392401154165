import { useMutation } from "react-query";
import api from "@services/api";

export default function useChangeMailRelancePaiement(props) {
    return useMutation(changeMailRelancePaiement, props);
}

export async function changeMailRelancePaiement(mail) {
    return api.post('emailRelancePaiement', { json: mail }).json();
}
