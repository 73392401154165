import { proxy } from "valtio";

const publicationMobileProxy = proxy({
    publicationMobile: null,
    initFinalisation:false,
    editModal: {
        show: false,
        step: 0,
        loading: false,
    },
    editCropperModal: {
      show: false
    },
    editGrapesModal: {
        show: false
    },
    selectTemplateGrapesModal: {
        show: false
    },
    templateSelected : null,
    showEditPublication: (publication) => {
        publicationMobileProxy.publicationMobile = publication;
        publicationMobileProxy.editCropperModal.show = false;
        publicationMobileProxy.editGrapesModal.show = false;
        publicationMobileProxy.selectTemplateGrapesModal.show = false;
        publicationMobileProxy.editModal = {
            show: true,
            step: 0,
            loading: false,
        };
    },
    showEditCropperForPublication: () => {
        publicationMobileProxy.editCropperModal.show = true;
    },
    showEditGrapesForPublication: () => {
        publicationMobileProxy.editGrapesModal.show = true;
    },
    showSelectTemplateGrapesForPublication: () => {
        publicationMobileProxy.selectTemplateGrapesModal.show = true;
    }
})

export default publicationMobileProxy;