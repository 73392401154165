import {Form, Modal} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSnapshot } from "valtio";
import accesProxy from "@proxies/acces";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import useChangeMail from "@requests/communication/mails/useChangeMail";
import ExpediteurSelection from "../../../ExpediteurSelection/ExpediteurSelection";
import useGetMailAccesPartenaire from "../../../../../requests/parametres/accesPartenaires/useGetMailAccesPartenaire";
import { ReactComponent as Cod001 } from "@assets/icons/cod001.svg";
import ExpediteurSettings from "../../../../ParametresView/ExpediteursSettings/ExpediteurSettings";

const ReglagesStep = forwardRef((_, ref) => {
    const [showModalSettings, setShowModalSettings] = useState(false);
    const [currentComponentSettings, setCurrentComponentSettings] = useState(null);
    const changeMail = useChangeMail();
    const accesSnapshot = useSnapshot(accesProxy);
    const getMailAcess = useGetMailAccesPartenaire();


    const {
        register, handleSubmit, reset, control,
        formState: { errors },
    } = useForm({
        defaultValues: accesProxy.mail,
    });

    useEffect(() => {
        reset(accesSnapshot.mail ?? {
            sujet: null,
        });
    }, [reset, accesSnapshot.mail]);

    useImperativeHandle(ref, () => ({
        save: async () => {
            let done = false;
            await handleSubmit(async (data) => {
                try {
                    data.isReadOnly= true;
                    data.isValidePourEnvoi =true;
                    data.corpsHtml = getMailAcess.data.corpsHtml;
                    data.corpsTemplate = getMailAcess.data.corpsTemplate;
                    const result = await changeMail.mutateAsync(data);
                    accesProxy.mail = result;
                    done = true;
                } catch {}
            })();
            return done;
        },
    }));

    return <div className='w-800px m-auto'>
        <Form.Group>
            <Form.Label className='required'>Sujet du mail</Form.Label>
            <Form.Control maxLength={200} {...register('sujet', { required: true })} className='form-control-solid' placeholder='Sujet du mail' />
            {errors.sujet && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
        </Form.Group>
        <Form.Group className='mt-5'>
            <Form.Label className='required'>Pré-header</Form.Label>
            <Form.Control maxLength={60} {...register('preHeader', { required: true })} className='form-control-solid' placeholder='Sujet du mail' />
            {errors.preHeader && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
        </Form.Group>
        <Form.Group className='mt-5'>
            <Form.Label className='required'>Expéditeur</Form.Label>
            <div className="d-flex align-items-center">
                <div className="w-100">
                    <ExpediteurSelection control={control} name='expediteur' rules={{ required: true }} />
                </div>
                <span
                    title={"Ajouter un expéditeur"}
                    style={{fill: "black"}}
                    onClick={() => {
                        setCurrentComponentSettings(<ExpediteurSettings/>)
                        setShowModalSettings(true)
                    }} className='ms-2 svg-icon svg-icon-1 cursor-pointer'><Cod001/></span>
            </div>
            {errors.expediteur && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
        </Form.Group>
        <Modal
            show={showModalSettings}
            onHide={() => setShowModalSettings(false)}
            size='lg'
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Paramètres mail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {currentComponentSettings}
            </Modal.Body>
        </Modal>
    </div>;
});

export default ReglagesStep;