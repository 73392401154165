import {Accordion, Alert, Button, Form, Modal} from "react-bootstrap";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useSnapshot} from "valtio";
import invitationsProxy from "../../../../../../proxies/invitations";
import Select2 from "../../../../../../components/Select2/Select2";
import auth from "../../../../../../services/auth";
import {Editor} from "@tinymce/tinymce-react";
import {iconLienPj} from "../../../../../../assets/icons/svgTinymce";
import {linkUrlTinyMce} from "../../../../../../functions/linkUrlTinyMce";
import useUploadFile from "../../../../../../requests/upload/useUploadFile";
import $ from "jquery";
import {BtSwal} from "../../../../../../utils/alerts/sweetAlert";

export default function PersonnalisationInvitationModal({invitationEdited}){

    const [invitation, setInvitation] = useState(invitationEdited);
    const convertTabOptionsParticipation = invitation?.tabOptionsParticipation ? Object.entries(invitation?.tabOptionsParticipation).map(exp => exp[1]) : [];
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const editorRef = useRef(null);
    const editorRef2 = useRef(null);
    const editorRef3 = useRef(null);
    const editorRef4 = useRef(null);
    const uploadFilePresentation = useUploadFile({ type: "userfile"});
    if (uploadFilePresentation.data != null) {
        $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled",false);
        $(".tox-textfield").first().val(uploadFilePresentation.data.urlOriginale)
        if ($(".tox-textfield").eq(1).val() == ""){
            $(".tox-textfield").eq(1).val("Cliquer ici pour ouvrir la pièce jointe.")
        }
        $(".tox-dialog__footer-end button").eq(1).trigger('click')
    }
    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            let checkUrlMotCle = string.slice(0,4)
            return string && checkUrlMotCle == "{url";
        }

        return url.protocol === "https:";
    }
    const [champsPerso, setChampsPerso] = useState({
        champs1: {
            html : null,
            state: {
                disable : true,
                edit: false,
                active: false
            }
        },
        champs2: {
            html : null,
            state: {
                disable : true,
                edit: false,
                active: false
            }
        },
        champs3: {
            html : null,
            state: {
                disable : true,
                edit: false,
                active: false
            }
        },
        champs4: {
            html : null,
            state: {
                disable : true,
                edit: false,
                active: false
            }
        }
    })
    const data = useMemo(() => {
        return convertTabOptionsParticipation.map(exp => ({
            id: exp.id,
            text: `${exp.designation} -- ${exp.montant} €TTC`,
        }));
    }, [invitation?.tabOptionsParticipation]);

    useEffect(() => {
        setInvitation(invitationEdited)
        if (invitationEdited?.blocsPersonnalisesPageReponse){
            setChampsPerso(invitationEdited.blocsPersonnalisesPageReponse)
        }
    }, [invitationEdited]);

    return  <Modal
        show={invitationsSnapshot.personnalisationInvitation.show}
        onHide={() => {
            if (((JSON.stringify(invitationsProxy?.invitation?.blocsPersonnalisesPageReponse)) != JSON.stringify(champsPerso))) {
                BtSwal.fire({
                    title: 'Souhaitez-vous enregistrer toutes les modifications',
                    showDenyButton: true,
                    icon: "question",
                    confirmButtonText: `Oui`,
                    denyButtonText: `Non`,
                }).then((result) => {
                    if (result.isConfirmed){
                        if (invitationsProxy.invitation){
                            invitationsProxy.invitation.blocsPersonnalisesPageReponse = champsPerso;
                            invitationsProxy.personnalisationInvitation.show = false;
                        }else {
                            invitationsProxy.invitation = {
                                blocsPersonnalisesPageReponse: champsPerso
                            }
                            invitationsProxy.personnalisationInvitation.show = false;
                        }
                    }else {
                        invitationsProxy.personnalisationInvitation.show = false;
                    }
                })
            }else {
                invitationsProxy.personnalisationInvitation.show = false;
            }
        }}
        fullscreen
        // dialogClassName='p-9'
        // contentClassName='modal-rounded'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Personnaliser la page de réponse du destinataire</Modal.Title>
        </Modal.Header>
        <Modal.Body
        style={{
            height: "3000px",
            margin:0,
            padding:0,
            backgroundColor: "#f7f7f9"
        }}
        >
                <div style={{
                    height:"3000px"
                }} className='d-flex flex-column flex-lg-row flex-column-fluid'>
                    <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
                        <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                            <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={auth.srcLogo} className='h-60px logo'/>
                        </div>
                    </span>
                                <><h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Nous sommes ravis de
                                    votre choix</h1>
                                    <p className="text-white">Monsieur Prénom NOM,
                                        vous validez <strong>votre
                                            présence</strong> {invitation?.type?.motDeLiaison} {invitation?.type?.libelle}
                                        <strong>{invitation?.dateEvenementFormatee && invitation?.dateEvenementFormatee.toLowerCase()}</strong>.
                                    </p></>

                                <div className=''>
                                    {renderSwitch("champs1", champsPerso)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column flex-lg-row-fluid py-10'>
                        <div className=''>
                            <div className=''>
                                <h1 className="mx-10 text-center alert-info-color">Nous sommes ravis de votre choix</h1>
                                <div className='mt-5 mx-10 bg-white rounded'>
                                    {renderSwitch("champs2", champsPerso)}
                                </div>
                                <form>
                                    <div className=''>
                                        {((invitation?.paiementEnLigneIsUtilise && invitation?.paiementSurPlaceIsAutorise) || invitation?.champInformationComplementaireReponseIsAffiche || (invitation?.tabOptionsParticipation && Object.keys(invitation?.tabOptionsParticipation).length > 0) || invitation?.nbParticipantsMax != 0) &&
                                            <div className='mt-5 mx-10 bg-white rounded p-10 pb-4'>
                                                {invitation?.nbParticipantsMax != 0 &&
                                                    <p style={{width: "fit-content"}}
                                                       className="text-info">{invitation?.nbParticipantsMax} places
                                                        restantes.</p>}
                                                {invitation?.tabOptionsParticipation && Object.keys(invitation?.tabOptionsParticipation).length > 0 && !(invitation?.tabOptionsParticipation.length === 1 && (invitation?.tabOptionsParticipation[0].designation === "" || invitation?.tabOptionsParticipation[0].designation == null)) &&
                                                    <><Form.Group>
                                                        <Form.Label>Option de participation</Form.Label>
                                                        <Select2
                                                            allowClear={true}
                                                            data={data}
                                                            onChange={(selected) => {
                                                                selected = selected?.at(0);
                                                                const res = convertTabOptionsParticipation.find(
                                                                    exp => exp.id.toString() === selected
                                                                );
                                                            }}
                                                            minimumResultsForSearch={3}
                                                            placeholder='Selectionner une option de participation'

                                                        />
                                                    </Form.Group>
                                                    <div className='mt-4'>
                                                        {renderSwitch("champs3", champsPerso)}
                                                    </div>
                                                    </>

                                                }
                                                {invitation?.champInformationComplementaireReponseIsAffiche &&
                                                    <Form.Group className='mt-5'>
                                                        <Form.Label>Information complémentaire sur votre réponse
                                                            (facultatif)
                                                            :</Form.Label>
                                                        <Form.Control
                                                            as='textarea'
                                                            rows={5}
                                                        />
                                                    </Form.Group>}

                                                {invitation?.paiementEnLigneIsUtilise && invitation?.paiementSurPlaceIsAutorise &&
                                                    <div className="d-flex justify-content-center mt-2">
                                                        <div className="btn-group" data-kt-buttons="true"
                                                             data-kt-buttons-target="[data-kt-button]">
                                                            <label
                                                                id='surPlace'
                                                                className={`${`disabled`} small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success`}
                                                                data-kt-button="true">
                                                                <input
                                                                    className="btn-check" type="radio" value={1}/>
                                                                <span>{"Régler sur place"}</span></label>
                                                            <label
                                                                id="cb"
                                                                className={`disabled small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success`}
                                                                data-kt-button="true">
                                                                <input
                                                                    className="btn-check" type="radio" value={2}/>
                                                                <span>{"Régler maintenant par carte bancaire"}</span></label>
                                                        </div>
                                                    </div>
                                                }
                                            </div>}
                                        {(invitation?.listeCombienEtesVousAVenirIsAffiche) &&
                                            <div className='mt-5 mx-10 bg-white rounded p-10'>
                                                <Accordion>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>
                                                            {invitation?.listeCombienEtesVousAVenirIsAffiche &&
                                                                <div
                                                                    className='mt-2 d-flex flex-wrap w-100 justify-content-between align-items-center'>
                                                                    <h2>Gestion des accompagnants <span
                                                                        className="badge badge-circle badge-primary">0</span>
                                                                    </h2>
                                                                    <Button
                                                                        variant='secondary'
                                                                        className='px-20 me-3'
                                                                    >
                                                                        Ajouter un accompagnant</Button>
                                                                </div>}
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="table-responsive mt-3">
                                                                <table className="table gy-7 gs-7">
                                                                    <tbody>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>}
                                        <div className='mt-5 mx-10 bg-white rounded'>
                                            {renderSwitch("champs4", champsPerso)}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
        </Modal.Body>
    </Modal>

    function renderSwitch(key, value){
        switch(key) {
            case 'champs1':
                return renderComponent(key, value, editorRef, "var(--primary-color)", "btn btn-custom-grey", "#fff")
            case 'champs2':
                return renderComponent(key, value, editorRef2, "#f7f7f9", "btn btn-primary", "#403d38")
            case 'champs3':
                return renderComponent(key, value, editorRef3, "#fff", "btn btn-primary", "#403d38")
            case 'champs4':
                return renderComponent(key, value, editorRef4, "#f7f7f9", "btn btn-primary", "#403d38")
        }
    }

    function renderComponent(key, value, editorRef, backgroundColor, btnClass, color){
        if (value[key].state.edit) {
            return <>{key === "champs1" && <Alert variant="warning" className="">
                Ce champs ne sera pas visible dans la page d'inscription libre
            </Alert>}
            <div className="d-flex align-items-center justify-content-between position-relative"><div className={"bg-gray-100"} style={{
                color: "#716d66",
                fontWeight: "500",
                borderRadius: "0.95rem",
                minHeight: "50px",
                padding: "10px",
                width: "100%"
            }}>
                <Editor
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={champsPerso[key].html}
                    // inline={true}
                    init={{
                        skin: false,
                        // height: 500,
                        content_style: '.mce-content-body { min-height: 50px; }',
                        selector: '#editableDiv',
                        language: 'fr_FR',
                        language_url: '/lang/tinymce/fr_FR/langs/fr_FR.js',
                        menubar: false,
                        plugins: ["link autolink noneditable"],
                        default_link_target: '_blank',
                        toolbar: 'undo redo | fontsizeselect | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help | link unlink ',
                        setup: function (editor) {
                            var toggleState = false;

                            editor.ui.registry.addIcon('link', iconLienPj);

                            editor.on('init', function (e) {
                                editor.windowManager.oldOpen = editor.windowManager.open;
                                editor.windowManager.open = function (t, r) {

                                    var modal = this.oldOpen.apply(this, [t, r]);  // call original

                                    linkUrlTinyMce(t, isValidHttpUrl, 2002, uploadFilePresentation)

                                    return modal;
                                }
                            });


                        },

                    }}
                />
            </div>
                <Button
                    variant={"default"}
                    style={{
                        zIndex: 10,
                        fontSize:17,
                        top:11,
                        right: 10,
                        color: "#222f3e",
                        // backgroundColor: "inherit",
                        fontWeight: 100,
                        borderLeft:"1px solid #ccc",
                        // borderRadius: 0
                    }}

                    onClick={() => {
                        if (editorRef.current.getContent()) {
                            setChampsPerso({
                                ...champsPerso,
                                [key]: {
                                    html: editorRef.current.getContent(),
                                    state: {
                                        disable: false,
                                        edit: false,
                                        active: true
                                    }
                                }
                            })
                        }else {
                            setChampsPerso({
                                ...champsPerso,
                                [key]: {
                                    html: editorRef.current.getContent(),
                                    state: {
                                        disable: true,
                                        edit: false,
                                        active: false
                                    }
                                }
                            })
                        }
                    }}
                    className="position-absolute btn-tinymce p-2"
                >
                    Enregistrer
                </Button>
            </div></>
        }else if (value[key].state.disable) {
            return <div style={{
                height: 50,
                width: "100%",
                backgroundColor: backgroundColor,
                borderRadius: 10,
                display: "flex",
                border: "dashed 1px #b5b0a1",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Button
                    variant={"default"}
                    className={btnClass}
                    onClick={() => {
                        setChampsPerso({
                            ...champsPerso,
                            [key]: {
                                ...champsPerso[key],
                                state: {
                                    disable: false,
                                    edit: true,
                                    active: false
                                }
                            }
                        })
                    }}
                >
                    Ajouter du texte
                </Button>
            </div>
        } else {
            return <>
                <div className={`position-relative ${key != "champs3" && "p-10"} pb-5`}><div style={{
                color: color
            }} dangerouslySetInnerHTML={{
                __html: champsPerso[key].html
            }}></div>
                <Button
                    onClick={() => {
                        setChampsPerso({
                            ...champsPerso,
                            [key]: {
                                ...champsPerso[key],
                                state: {
                                    disable: true,
                                    edit: false,
                                    active: false
                                }
                            }
                        })
                    }}
                    style={{right: 40, top: 0, width: 35, height: 35}}
                    className="fa fa-trash rounded-circle position-absolute p-0"
                    variant={"danger"}
                >

                </Button>
                <Button
                    onClick={() => {
                        setChampsPerso({
                            ...champsPerso,
                            [key]: {
                                ...champsPerso[key],
                                state: {
                                    disable: false,
                                    edit: true,
                                    active: false
                                }
                            }
                        })
                    }}
                    style={{right: 0, top: 0, width: 35, height: 35}}
                    className="fa fa-pen rounded-circle position-absolute p-0"
                    variant={"primary"}
                >

                </Button>
            </div></>
        }
    }

}