import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import useUploadMultiPortrait from "../../requests/upload/useUploadMultiPortrait";
import useUploadMultiLogo from "../../requests/upload/useUploadMultiLogo";
import auth from "../../services/auth";
import {useSnapshot} from "valtio";

export default function UploadView() {
    const snapAuth = useSnapshot(auth);
    const uploadFile = useUploadMultiPortrait({
        type: 'logo',
        onSuccess: (res) => {
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'upload de l'image", err.message);
        }
    });
    const uploadFile2 = useUploadMultiLogo({
        type: 'logo',
        onSuccess: (res) => {
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'upload de l'image", err.message);
        }
    });
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        acceptedFiles.forEach((file) => {
            uploadFile.mutate(file)
        })
    }, [])
    const onDrop2 = useCallback(acceptedFiles => {
        // Do something with the files
        acceptedFiles.forEach((file) => {
            console.log(file)
            uploadFile2.mutate(file)
        })
    }, [])
    const {acceptedFiles, getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    const {acceptedFiles : acceptedFiles2, getRootProps : getRootProps2, getInputProps : getInputProps2, isDragActive : isDragActive2} = useDropzone({onDrop : onDrop2})

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const files2 = acceptedFiles2.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    if (snapAuth.utilisateur.id == 1){
        return (
            <section className="container">
                <div {...getRootProps({className: 'dropzone', id: "zone1"})}>
                    <input name="drop1" {...getInputProps()} />
                    <p>Multi portait</p>
                </div>
                <aside>
                    <h4>Files</h4>
                    <ul>{files}</ul>
                </aside>

                <div {...getRootProps2({className: 'dropzone', id: "zone2"})}>
                    <input name="drop2" {...getInputProps2()} />
                    <p>Multi logo</p>
                </div>
                <aside>
                    <h4>Files</h4>
                    <ul>{files2}</ul>
                </aside>
            </section>
        );
    }else {
        return "";
    }
}