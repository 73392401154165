import { useMutation } from "react-query";
import api from "@services/api";

export default function useRelanceMail({ id, date,...props  }) {

    return useMutation(() => api.post(
        `email/${id}/envoyer`, {
            json: {
                dateProgrammationEnvoi: date,
                type:"relance",
                renvoyerAToutLeMonde:true
            }
        }).json(), props);

}