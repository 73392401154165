import { useQuery } from "react-query";
import baseApi from "../../services/apiPublic";

export default function useGetGestionNotificationPublic(ref) {
    return useQuery(
        ['parametreNotification'],
        async () => {
            return baseApi.get(`parametreNotification?ref=${ref}`).json();
        },
        {
            enabled: ref != null,
        },
    );
}