import image from './icons/textAndImage.svg';

export const textAndImage = {
    image: image,
    defaultContent: {
        richTexts: {
            richtext: '<h1>Titre</h1><p>Purus sit amet luctus venenatis lectus magna. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Ultricies mi quis hendrerit dolor magna eget est lorem. Orci sagittis eu volutpat odio. Justo donec enim diam vulputate ut pharetra sit amet.</p>',
        },
        images: {
            image: 'https://placehold.co/520x520/EEE/31343C',
        },
    },
    template:
        `<tr id="colorPicker">
            <td valign="top" align="center">
                <table width="100%" cellspacing="0" cellpadding="0" border="0">
                    <tbody><tr>
                        <td valign="top" align="center">
                            <table class="flexibleContainer" width="600" cellspacing="0" cellpadding="0" border="0">
                                <tbody><tr>
                                    <td class="flexibleContainerCell" id="paddingTemplate" width="600" valign="top">
                                        <table class="flexibleContainer" width="200" cellspacing="0" cellpadding="0" border="0" align="Right">
                                            <tbody><tr>
                                                <td class="imageContent" valign="top" align="Left">
                                                    <img id="image" src="http://placekitten.com/g/400/400" class="flexibleImage" style="max-width:200px;" width="200">
                                                </td>
                                            </tr>
                                        </tbody></table>
                                        <table class="flexibleContainer" width="340" cellspacing="0" cellpadding="0" border="0" align="Left">
                                            <tbody><tr>
                                                <td id="richtext" class="textContent" valign="top">
                                                    <h3>Left Column</h3>
                                                    <br>
                                                    A kitten or kitty is a juvenile domesticated cat. Kittens are highly social animals and spend most of their waking hours playing and interacting with available companions.
                                                </td>
                                            </tr>
                                        </tbody></table>
                                    </td>
                                </tr>
                            </tbody></table>
                        </td>
                    </tr>
                </tbody></table>
            </td>
        </tr>`
}