import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";

export default function useGetCorrespondants(props) {
    return useMutation(getCorrespondants, props);
}

function getCorrespondants(data) {

    return api.get(`${data.baseRoute}/${data.id}/correspondants`).json();
}
