import { useMutation } from "react-query";
import baseApi from "../../../services/apiPublic";

export default function useChangeCorrespondantTransfereMail(props) {
    return useMutation(changeCorrespondantTransfereMail, props);
}

export async function changeCorrespondantTransfereMail(correspondant) {
    const id = correspondant.id;

    if (id != null)
        return baseApi.post(`correspondantEmail/${id}/transferer`, { json: correspondant }).json();

}
