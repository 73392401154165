import {useEffect, useMemo, useState} from "react";
import {Button} from "react-bootstrap";

export default function ChangementTableMobile({color, dataCurrentTableParticipantPartantDeTable, tabTables, setShowCheck, currentTime, tour, nbTours, nomCurrentTable}) {

    const participants = useMemo(() => {
        return  dataCurrentTableParticipantPartantDeTable.map(item => {
            return {
                participant: item?.infoParticipant?.participant.personne.prenom + " " + item?.infoParticipant?.participant.personne.nom,
                prochaineTable: tabTables[item?.tableSuivante]
            }
        })
    }, [dataCurrentTableParticipantPartantDeTable, tabTables])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowCheck(true)
        }, dataCurrentTableParticipantPartantDeTable.length * 4000);
        return () => clearTimeout(timeoutId);
    }, []);

    return <div style={{
        backgroundColor: "white",
        height:"100%"
    }}>
        <div style={{
            height: 120,
            width: "100%",
            marginBottom: 50,
            backgroundColor: "var(--primary-color)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            flexWrap: "wrap",
        }}>
            <div><h2 style={{
                width: "100%",
                fontSize: 22,
                marginBottom:0,
                color: "white",
                textAlign:"center"
            }}>Changement de table
                des participants</h2>
                <span style={{
                    display:"block",
                    fontWeight:"bold",
                    fontSize: 18,
                    color: "white",
                    textAlign:"center"
                }}>Tour {tour}/{nbTours} - Table : {nomCurrentTable}</span>
            </div>
            <span style={{
                position: "absolute",
                height: "40px",
                width: 380,
                bottom: -20,
                backgroundColor: "#2e8bdb",
                left: "calc(50% - 190px)",
                borderRadius: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <span style={{
                    fontSize: 14,
                    color: "white",
                    fontWeight: "bold"
                }}>Temps changement de table : {currentTime} secondes</span>
            </span>
        </div>
        <p style={{
            fontSize: 16,
            width: "100%",
            padding: 10,
            textAlign: "center"
        }}>C’est le moment de dire aux participants de changer de table.
            C’est parti ! 🔥</p>
        <div style={{
            paddingBottom: 20,
            textAlign: "center"
        }}>
                        <span> <i className="fa fa-users fa-2x me-2" style={{
                            color: color
                        }}></i> <span style={{
                            fontSize: 18,
                            fontWeight: "bold"
                        }}>Participants</span></span>
        </div>
        {participants.map((item, index) => {
            return <div style={{
                display:"flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                    <span className={`mb-3 me-3`}
                             style={{
                                 padding: "5px 5px 5px 5px",
                                 fontSize: 14,
                                 fontWeight: "bold",
                                 color: "var(--primary-color)",
                                 width: 200,
                                 display: "inline-block",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 backgroundColor: "white",
                                 boxShadow: "1px 6px 7px 0px",
                                 textOverflow: "ellipsis",
                                 overflow: "hidden",
                                 textWrap:"nowrap",

                             }}>
                {item.participant}
                </span>
                <img src="/images/tripleFlecheBleu.png" height={20} width={30} alt=">>>"/>
                <span style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "var(--primary-color)",
                    justifyContent: "center",
                    backgroundColor: "white",
                    marginLeft:5,
                    width: 100,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textWrap:"nowrap",
                }}>
                    {item.prochaineTable}
                </span>
            </div>
        })}
        <h3 className="mt-2 text-center">Vous avez terminé ? </h3>
        <Button onClick={() => {
            setShowCheck(true)
        }} className="d-block m-auto" style={{
            width: "fit-content"
        }}>Passez au check des nouveaux arrivants</Button>
    </div>;


}