import { Alert, Button, Spinner } from 'react-bootstrap';
import useGetProfilPartenaire from "../../../../requests/profil/useGetProfilPartenaire";

function ProfilFilter({ selected, onChange, className }) {
    const profils = useGetProfilPartenaire();

    return <div className={className}>
        <h3>Quel profil afficher ?</h3>
        <div className='mt-5 filter'>
            {profils.isLoading && <Spinner animation='border' />}
            {profils.isError && <Alert className='mx-auto w-300px' variant='danger'>{profils.error?.message}</Alert>}
            {profils.isSuccess && <>
                <Button
                    className='m-1'
                    variant={selected == null ? 'primary' : 'light'}
                    onClick={() => {
                        if (onChange != null)
                            onChange(null);
                    }}
                >Tout les partenaires</Button>
                {profils.data.data?.map(profil => <Button
                    className='m-1'
                    key={profil.id}
                    variant={profil.id === selected ? 'primary' : 'light'}
                    onClick={() => {
                        if (onChange != null)
                            onChange(profil.id);
                    }}
                >{profil.libelle}</Button>)}
            </>}
        </div>
    </div>;
}

export default ProfilFilter;