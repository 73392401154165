import { useQuery } from "react-query";
import api from "@services/api";

export default function useGetColorAgence(id) {
    return useQuery(
        ['themeUi', id],
        async () => {
            return api.get(`themeUi/${id}`).json();
        },
        {
            enabled: id != null,
        },
    );
}