export const breadcrumbs = {
    '/:agence': {
        name: 'Accueil',
    },
    '/:agence/accueil': {
        name: 'Accueil',
    },
    '/:agence/annuaire': {
        name: 'Annuaire',
    },
    '/:agence/communication': {
        name: 'Communication',
        abstract: true,
    },
    '/:agence/communication/mails': {
        name: 'Mails',
    },
    '/:agence/communication/invitations': {
        name: 'Invitations',
    },
    '/:agence/communication/acces': {
        name: 'Accès partenaire',
    },
    '/:agence/communication/publicationsMobile': {
        name: 'Publications mobile',
    },
    '/:agence/communication/publicationsReseauxSociaux': {
        name: 'Publications réseaux sociaux',
    },
    '/:agence/communication/sms': {
        name: 'SMS',
    },
    '/:agence/forum': {
        name: 'Forum',
    },
    '/:agence/facturation/facture': {
        name: 'Facture',
    },
    '/:agence/facturation/reversion': {
        name: 'Réversion',
    },
    '/:agence/forum/post': {
        name: 'Post',
        back: '/:agence/forum',
    },
    '/:agence/parametres': {
        name: 'Paramètres',
    },
    '/:agence/parametres/stripe': {
        name: 'Paramètres de paiement',
    },
    '/:agence/parametres/forum': {
        name: 'Paramètres du forum',
    },
    '/:agence/speedBusiness': {
        name: 'Générateur de rencontre',
    },
};