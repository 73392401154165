import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../../../utils/alerts/showErrorAlert";

export default function useActiveCompte() {
    const queryClient = useQueryClient();

    return useMutation(activeCompte, {
        onSuccess: () => {
            queryClient.invalidateQueries('compteLinkedin');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function activeCompte(stateAndCode) {
    if (stateAndCode)
        return api.post(`compteLinkedin/activation`, { json: stateAndCode }).json();
}