import MailEditor from "@components/MailEditor/MailEditor";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { useSnapshot } from "valtio";
import mailsProxy from "@proxies/mails";
import useChangeMail from "@requests/communication/mails/useChangeMail";
import { BtSwal } from "@utils/alerts/sweetAlert";

const RedactionStep = forwardRef((_, ref) => {
    const editorRef = useRef();
    const changeMail = useChangeMail();
    const mailsSnapshot = useSnapshot(mailsProxy);

    useImperativeHandle(ref, () => ({
        save: async () => {
            const mailBody = editorRef.current?.save();

            if (!(mailBody.json?.order?.length > 0)) {
                BtSwal.fire({
                    title: 'Mail incomplet !',
                    text: 'Vous devez ajouter au minimum une section au mail',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
                return false;
            }

            const newMail = {
                ...mailsSnapshot.mail,
                corpsHtml: mailBody.html,
                corpsTemplate: mailBody.json,
                header: mailBody.json.headerFull,
                signature: mailBody.json.footerFull
            };

            try {
                const result = await changeMail.mutateAsync(newMail);
                mailsProxy.mail = result;
                return true;
            } catch {
                return false;
            }
        },
    }));

    return <MailEditor
        ref={editorRef}
        value={mailsSnapshot.mail.corpsTemplate}
    />;
});

export default RedactionStep;