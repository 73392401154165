import {useEffect, useState} from "react";
import Select from "react-select";

function ForumFilter({ selected, onChange, className }) {

    const [selectedValue, setSelectedValue] = useState(selected != null ? {id:selected.id,title:selected.libelle} : null);
    const handleChange = value => {
        setSelectedValue(value);
    }


    useEffect(() => {
        setSelectedValue(selected != null ? {id:selected.id,title:selected.title} : null)
    }, [selected])

    const debouncedLoadOptions = [
        {id : 2, title : "Brouillon"},
        {id : 1, title : "En attente de publication"},
        {id : 0, title : "Publié"},
        {id : 3, title : "Archivé"},
        {id : 4, title : "Censuré"},
    ]

    return <Select
        className={className}
        placeholder={"Choisir un état"}
        noOptionsMessage={() => "Aucun résultat"}
        loadingMessage={() => "Chargement..."}
        minLength={0}
        styles={{
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? 'white' : '#716d66',
                backgroundColor: state.isSelected ? '#4fc9da' : 'white',
                "&:hover":{
                    backgroundColor: !state.isSelected ? 'rgba(79,201,218,0.3)' : "#4fc9da",
                }
            }),
            input: (base) => ({
                ...base,
                width: "100px"
            }),
            control: (base) => ({
                ...base,
                backgroundColor : "#FFF",
                borderRadius : ".95rem",
                color: '#716d66',
            }),
        }}
        isClearable={true}
        cacheOptions
        defaultOptions={true}
        value={selectedValue}
        getOptionLabel={e => e.title}
        getOptionValue={e => e.id}
        options={debouncedLoadOptions}
        onChange={ (selected) => {
            handleChange(selected)
            onChange(selected)
        }}
    />
}

export default ForumFilter;