import {Card, Button} from "react-bootstrap";
import React, {useEffect, useMemo, useRef, useState} from "react";
import MetaTable from "@components/MetaTable/MetaTable";
import SearchBar from "@components/SearchBar/SearchBar";
import { useForm } from "react-hook-form";
import { useDebounce } from 'use-debounce';
import "flatpickr/dist/plugins/monthSelect/style.css"
import DetailReversionModal from "./DetailReversionModal";
import facturationProxy from "../../../proxies/facturation";
import {baseURL} from "../../../config";
import useGetExportReversion from "../../../requests/facturation/reversion/useGetExportReversion";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";


function ReversionView() {
    const tableRef = useRef();
    const { register, watch, setValue } = useForm();
    const search = watch('search')
    const [searchFilter] = useDebounce(search, 300);
    const urlExportReversion = useGetExportReversion({
        onSuccess: (data) => {
            window.open(data.url, '_blank');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });


    const columns = useMemo(() => [
        {
            Header: () => null,
            id: 'wrench',
            Cell: ({ row }) => {
                return <>
                    <span
                    style={{fill: "#211f1c"}}
                    title="Voir les détails de la réversion"
                    onClick={() => {
                        facturationProxy.showDetailReversion(row?.original);
                    }} className='svg-icon svg-icon-2x m-0 cursor-pointer'><i className="fa fa-2x fa-eye"></i></span>
                    <span
                        style={{fill: "#211f1c"}}
                        title="Télécharger les informations concernant la réversion"
                        onClick={() => {
                            urlExportReversion.mutate(row?.original.id)

                        }} className='svg-icon svg-icon-2x m-0 ms-2 cursor-pointer'><i className="fa fa-2x fa-download"></i></span>
                </>
            },
            minWidth: 100,
            maxWidth: 100,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            minWidth: 80,
            maxWidth: 80,
        },
        {
            Header: 'Montant',
            accessor: 'montant',
            Cell: ({ row }) => {
                return  row?.original?.montant.toString().replace(".", ",")+"€"
            },
            minWidth: 150,
            maxWidth: 150,
        },
        {
            Header: 'Date création',
            accessor: 'dateCreation',
            minWidth: 100,
            maxWidth: 100,
        },
        {
            Header: 'Date réception',
            accessor: 'dateArriveeSurCompte',
            minWidth: 100,
            maxWidth: 100,
        },
        {
            Header: 'Méthode',
            accessor: 'methode',
            minWidth: 100,
            maxWidth: 100,
        },

    ], []);

    return <>
        <Card>
            <Card.Body>
                <div className='d-flex flex-stack flex-wrap'>
                    <SearchBar
                        solid
                        {...register('search')}
                        onClear={search?.length > 0 ? () => {
                            setValue('search', '');
                        } : null}
                    />
                </div>

                <MetaTable
                    ref={tableRef}
                    className='mt-5'
                    height={500}
                    url='reversionPaiement'
                    keys={['reversionPaiement']}
                    columns={columns}
                    search={searchFilter}
                />
                {/*<div className='d-flex mt-3'>*/}
                {/*    <Button*/}
                {/*        variant='secondary'*/}
                {/*        className='ms-auto'*/}
                {/*        onClick={() => {*/}
                {/*            // facturation.showExportFacturation(urlExport?.data)*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <FontAwesomeIcon icon={faFileDownload} className='me-2' />*/}
                {/*        Exporter*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </Card.Body>
        </Card>
        <DetailReversionModal/>
    </>;
}

export default ReversionView;