import { useQuery } from "react-query";
import api from "@services/api";

export default function useGetEtatCompte() {
    return useQuery(
        ['etatCompte'],
        async () => {
            return api.get(`stripe/etatCompte`).json();
        }
    );
}