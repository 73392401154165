import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetQueryGenererParTable(id) {

        return useQuery(
            ['GenererParTable', id],
            async () => {
                return api.get(`rencontreSpeedBusiness/${id}/GenererParTable`, {
                    searchParams: Qs.stringify({
                        start: 0,
                        length: 1000,
                        utiliserParticipant:true
                    }, { arrayFormat: 'brackets' }),
                }).json();
            },
            {
                enabled: id != null,
            },
        );
}