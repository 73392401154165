import {
    dureeMinuteToTimeMysqlWithoutHours,
    dureeTimeMysqlToMinute
} from "../../../../views/SpeedBusinessView/ReglageStep/data";
import CountDownCircleTimer from "../../../../components/CountDownCircleTimer/CountDownCircleTimer";

export default function EntracteMobile({
                                           currentTime,
                                           setInitCurrentPerson,
                                           isPlaying,
                                           valueChangeRemainingTime,
                                           setValueChangeRemainingTime,
                                           currentPerson,
                                           setCurrentPerson,
                                           tour,
                                           setTour,
                                           setIsEntracte,
                                           setIsChangementTour,
                                           isChangementTour,
                                           isTimePersonne,
                                           isEntracte,
                                           setIsTimePersonne,
                                           setCurrentTime,
                                           numberOfStep,
                                           zigZagBar,
                                           numIsPair,
                                           personneParTable,
                                           speedBusiness
                                       }) {
    return <>
        <span>
                <div style={{
                    width: "100%",
                    height: "100%",
                    // backgroundColor: "var(--primary-color)"
                    backgroundColor: "var(--primary-color)",
                    position: "relative",
                }}>
                <div style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <div style={{
                        flex: 1,
                        height: "100%",
                        width: "100%",
                        // backgroundColor: "var(--secondary-color)",
                        backgroundColor: "var(--secondary-color)",
                    }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                            <span style={{
                                fontSize: 52,
                                fontWeight: "bold",
                                color: "white"
                            }}>ENTRACTE</span>
                        </div>
                        <div style={{
                            position: "absolute",
                            right: "calc(50% - 150px)",
                            top: "calc(50% - 150px)",
                            height: 300,
                            width: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 200,
                            // border: "5px solid var(--primary-color)"
                            border: "5px solid var(--primary-color)"
                        }} className="bg-white">
                            <div style={{
                                height: 280,
                                width: 280,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 200,
                                border: "5px solid var(--primary-color)"
                            }} className="bg-white">
                                <div>
                                    <div style={{
                                        marginTop: 30
                                    }}>
                                        <span
                                            style={{
                                                // borderColor: "var(--primary-color)"
                                                borderColor: "var(--primary-color)"
                                            }}
                                            className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(currentTime / 60)[0]}</span>
                                    <span style={{
                                        borderColor: "var(--primary-color)"
                                    }}
                                          className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(currentTime / 60)[1]}</span>
                                    <span style={{
                                        fontSize: 30,
                                        fontWeight: "bold",
                                        marginRight: 10
                                    }}>{dureeMinuteToTimeMysqlWithoutHours(currentTime / 60)[2]}</span>
                                    <span style={{
                                        borderColor: "var(--primary-color)"
                                    }}
                                          className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(currentTime / 60)[3]}</span>
                                    <span style={{
                                        borderColor: "var(--primary-color)",
                                        marginRight: 0
                                    }}
                                          className="compteurSplitPresentation">{dureeMinuteToTimeMysqlWithoutHours(currentTime / 60)[4]}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                      <div style={{
                          flex: 1,
                          height: "100%",
                          width: "100%"
                      }}>

                    </div>

                </div>
                </div>
                <CountDownCircleTimer
                    setInitCurrentPerson={setInitCurrentPerson}
                    isPlaying={isPlaying}
                    valueChangeRemainingTime={valueChangeRemainingTime}
                    setValueChangeRemainingTime={setValueChangeRemainingTime}
                    currentPerson={currentPerson}
                    setCurrentPerson={setCurrentPerson}
                    personneParTable={personneParTable}
                    tour={tour}
                    setTour={setTour}
                    setIsEntracte={setIsEntracte}
                    setIsChangementTour={setIsChangementTour}
                    isChangementTour={isChangementTour}
                    isTimePersonne={isTimePersonne}
                    isEntracte={isEntracte}
                    setIsTimePersonne={setIsTimePersonne}
                    currentTime={currentTime}
                    setCurrentTime={setCurrentTime}
                    numberOfStep={numberOfStep}
                    zigZagBar={zigZagBar}
                    numIsPair={numIsPair}
                    duration={dureeTimeMysqlToMinute(speedBusiness?.data?.dureeEntracte) * 60}
                    title="Entracte"
                />
            </span>;
    </>;
}