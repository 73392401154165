import React from 'react';
import HtmlToReact from 'html-to-react';
import { cssToReactStyle } from './cssToReactStyle';
import ImageEditor from '../components/ImageEditor/ImageEditor';
import RichTextEditor from '../components/RichTextEditor/RichTextEditor';

const parser = new HtmlToReact.Parser();

export function renderSectionEditor(template, content, onChange, indexBloc) {
    const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

    var processingInstructions = [];

    // RichTextEditor
    for (const id in content.richTexts) {
        processingInstructions.push({
            replaceChildren: true,
            shouldProcessNode: (node) => node.attribs && node.attribs['id'] === id,
            processNode: function (node, children, index) {
                // Convert dom attributes to react attributes
                var attribs = node.attribs ?? {};
                attribs.className = attribs.class;
                delete attribs.class;
                if (attribs.style != null)
                    attribs.style = cssToReactStyle(node.attribs["style"]);

                return <RichTextEditor
                    id={indexBloc}
                    value={content.richTexts[id]}
                    onChange={(value) => onChange('richTexts', id, value)}
                />
            },
        });
    }

    for (const id in content.blocButtonTransferes) {
        processingInstructions.push({
            replaceChildren: true,
            shouldProcessNode: (node) => node.attribs && node.attribs['id'] === id,
            processNode: function (node, children, index) {
                // Convert dom attributes to react attributes
                var attribs = node.attribs ?? {};
                attribs.className = attribs.class;
                delete attribs.class;
                if (attribs.style != null)
                    attribs.style = cssToReactStyle(node.attribs["style"]);

                return <p dangerouslySetInnerHTML={{ __html: content.blocButtonTransferes[id] }}></p>;
            },
        });
    }

    // ImageEditor
    for (const id in content.images) {
        processingInstructions.push({
            // replaceChildren: true,
            shouldProcessNode: (node) => node.attribs && node.attribs['id'] === id,
            processNode: function (node, children, index) {
                // Convert dom attributes to react attributes
                var attribs = node.attribs ?? {};
                attribs.className = attribs.class;
                delete attribs.class;
                if (attribs.style != null)
                    attribs.style = cssToReactStyle(node.attribs["style"]);

                return <ImageEditor
                    onChange={(value) => onChange('images', id, value)}
                >
                    <img
                        {...node.attribs}
                        src={content.images[id]}
                        alt=''
                    />
                </ImageEditor>
            },
        });
    }

    // Remove blank text nodes
    processingInstructions.push({
        shouldProcessNode: (node) => node.type === 'text' && node.data.match(/^\s+$/),
        processNode: () => null,
    });
    
    // All other nodes
    processingInstructions.push({
        shouldProcessNode: () => true,
        processNode: processNodeDefinitions.processDefaultNode,
    });

    return parser.parseWithInstructions(template, () => true, processingInstructions);
}