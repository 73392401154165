import { useMemo } from "react";
import { useController } from "react-hook-form";
import Select2 from "@components/Select2/Select2";
import { Spinner, Alert } from "react-bootstrap";
import useGetSupportDestinataireValidationMessage
    from "../../../requests/parametres/supportDestinataireValidationMessage/useGetSupportDestinataireValidationMessage";

function SupportDestinataireValidationMessageSelection({ control, name, rules }) {
    const expediteurs = useGetSupportDestinataireValidationMessage();
    const { field } = useController({ control, name, rules });

    const data = useMemo(() => {
        return expediteurs.data?.data?.map(exp => ({
            id: exp.id,
            text: `${exp.libelle+"("+exp.description+")"}`,
        }));
    }, [expediteurs.data]);

    if (expediteurs.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    if (expediteurs.isError)
        return <Alert variant='danger'>{expediteurs.error.message}</Alert>


    return <Select2
                    multiple={true}
                    data={data}
                    selected={field.value != null ? Object.keys(field.value) : null}
                    minimumResultsForSearch={3}
                    placeholder="Sélectionner les types de validation et notification"
                    onChange={(selected) => {
                        let res = {};
                        expediteurs.data?.data.forEach(item => {
                            if (selected.includes(item.id.toString()))
                                res[item.id] = item;
                        });
                        field.onChange(res);
                    }}
    />
}

export default SupportDestinataireValidationMessageSelection;