import {useMutation} from "react-query";
import toast from "react-hot-toast";
import api from "@services/api";

export default function useSendMailMissionMaitreDuTemps() {

    return useMutation(send, {
        onSuccess: () => {
            toast.success('Les mails ont été envoyés', {
                duration:5000
            });
        },
        onError: (err) => {
            toast.error(err.message, {
                duration:5000
            });
        },
    });
}

function send(body) {

    if (body.id != null)
        return api.post(`rencontreSpeedBusiness/${body.id}/envoyerMessage`, {
            json: {
                dateProgrammationEnvoi: body.dateProgrammationEnvoi,
                type:body.type
            }
        }).json();

}