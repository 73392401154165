import {Col, Form, Row} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSnapshot } from "valtio";
import {forwardRef, useEffect, useImperativeHandle} from "react";
import SmsEditor from "../../../../../components/SmsEditor/SmsEditor";
import smsProxy from "../../../../../proxies/sms";
import useChangeSms from "../../../../../requests/communication/sms/useChangeSms";
import {showErrorAlert} from "../../../../../utils/alerts/showErrorAlert";
import {alphaNumericAndSpaceAndStartWithLetter} from "../../../../../functions/patterns";

const ReglagesStep = forwardRef((_, ref) => {
    const changeSms = useChangeSms();
    const smsSnapshot = useSnapshot(smsProxy);
    const {
        register, handleSubmit, unregister, reset, control, setValue, getValues, watch,
        formState: { errors },
    } = useForm({
        defaultValues: smsProxy.sms,
    });
    const checkIsAvecReponse = watch("isAvecReponse");
    useEffect(() => {
        reset(smsSnapshot.sms ?? {
            id: null,
        });
    }, [reset, smsSnapshot.sms]);

    useImperativeHandle(ref, () => ({
        save: async () => {
            let done = false;
            await handleSubmit(async (data) => {
                if (data.message){
                    try {
                        const result = await changeSms.mutateAsync(data);
                        smsProxy.sms = result;
                        done = true;
                    } catch {}
                }else {
                    showErrorAlert("Message manquant", "Merci de renseigner un contenu au sms")
                }
            })();
            return done;
        },
    }));

    return <div className='mw-800px m-auto'>
        <Row className='mt-5'>
            <Col lg={6} md={6} sm={12}>
                <Form.Group>
                    <Form.Label className='required'>Nom</Form.Label>
                    <Form.Control maxLength={60} {...register('nom', { required: true })} className='form-control-solid' placeholder='Nom' />
                    {errors.nom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Form.Group>
            </Col>
            <Col>

            </Col>
            <Col lg={6} md={6} sm={12}>
                <Form.Check
                    className="mt-12 mb-8 required"
                    type='switch'
                    label="Permettre de répondre à ce SMS"
                    id='checkIsAvecReponse'
                    onClick={e => {
                        if (e.target.checked) {
                            unregister("expediteur")
                        }
                    }}
                    {...register('isAvecReponse')}
                />
            </Col>
            {!checkIsAvecReponse &&
                <Col>
                    <Form.Group hidden={checkIsAvecReponse}>
                        <Form.Label className='required'>Expéditeur</Form.Label>
                        <Form.Control maxLength={11} {...register('expediteur', { required: true, minLength: 3, maxLength:11, pattern: alphaNumericAndSpaceAndStartWithLetter() })} className='form-control-solid' placeholder='Expéditeur' />
                        {errors.expediteur && <Form.Text className='text-danger'>Ce champ est requis (3 caractères minimum et 11 maximum, caractères alphanumériques et espace seulement. Doit commencer par une lettre)</Form.Text>}
                    </Form.Group>
                </Col>
            }
        </Row>
        <Form.Group>
            <Form.Label className='required'>Message</Form.Label>
            <SmsEditor
                setValueSmsMessage={setValue}
                getValuesSmsMessage={getValues}
                checkIsAvecReponse={checkIsAvecReponse}
            />
            {errors.message && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
        </Form.Group>
    </div>;
});

export default ReglagesStep;