import { useMutation } from "react-query";
import apiPublic from "../../../services/apiPublic";

export default function useChangeFacture(props) {
    return useMutation(changeFacture, props);
}

function changeFacture(facture) {
    const id = facture.facture.id;

    if (id != null)
        return apiPublic.put(`facture/${id}`, { json: facture.facture }).json();
    return apiPublic.post(`facture`, { json: facture }).json();
}