import { useMutation } from "react-query";
import api from "@services/api";
import auth from "@services/auth";

export default function useChangeBlocResponse(props) {
    return useMutation(changeResponse, props);
}

function changeResponse(bloc) {
    const id = bloc?.id;
    const idAgence = auth.agence?.id;

    const params = { ...bloc, idAgence };

    if (id != null)
        return api.put(`blocReponseInvitation/${id}`, { json: params }).json();
    return api.post('blocReponseInvitation', { json: params }).json();
}