import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import {useNavigate} from "react-router";
import LoadingButton from "@components/LoadingButton/LoadingButton";
import useSendResetPassword from "../../requests/utilisateur/useSendResetPassword";
import toast from "react-hot-toast";
import {useParams} from "react-router-dom";

function SendResetPasswordFrom() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const {agence} = useParams();

    const sendResetPassword = useSendResetPassword({
        onSuccess: () => {
            navigate("/"+agence+"/success",
                {
                    state : {
                        titleText : "Le lien pour la réinitialisation de mot de passe a bien été envoyé par mail."
                    }
                }
                );
        },
        onError: (err) => {
            toast.error(err.message, {
                duration:5000
            });
        }
    });

    function onSubmit(data) {
        sendResetPassword.mutate(data);
    }

    return <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' onSubmit={handleSubmit(onSubmit)} >
        <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Demande de</h1>
            <div className='text-gray-400 fw-bold fs-4'>réinitialisation de mot de passe</div>
        </div>
        <div className='fv-row mb-10 fv-plugins-icon-container'>
            <label className='form-label fs-6 fw-bolder text-dark'>Identifiant</label>
            <input
                className='form-control form-control-lg form-control-solid'
                type='text'
                name='identifiant'
                autoComplete='off'
                {...register('identifiant', { required: true })}
            />
            {errors.identifiant &&
                <div className='fv-plugins-message-container invalid-feedback'>
                    Ce champ est requis
                </div>
            }
        </div>
        <div className='text-center'>
            <LoadingButton
                loading={sendResetPassword.isLoading}
                type='submit'
            >Envoyer la demande</LoadingButton>
            <Button
                onClick={() => navigate("/"+(agence ? agence : "plink")+"/sendIdentifiant")}
                variant='link' className='m-auto w-100'>Identifiant oublié ?</Button>
        </div>
    </form>;
}

export default SendResetPasswordFrom;