export default function VousNEtesPlusMaitreDuTemps() {


    return <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
    }}>
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            backgroundColor: "var(--primary-color)",
        }}>
                    <span style={{
                        color: "white",
                        fontSize: 56,
                        fontWeight: "bold",
                        textAlign: "center"
                    }}>Merci d'avoir jouer votre role en tant que maitre du temps jusqu'au bout. Votre mission est terminée.</span>
        </div>
    </div>
}