import {useSnapshot} from "valtio";
import auth from "../../services/auth";

function HomeView(){
    const snapAuth = useSnapshot(auth);

    return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
    <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
        <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
            <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Bienvenue sur Partenaire Link</h1>
                <p className='fw-bold fs-2 text-white'>
                    Gérez les partenaires de votre
                    <br />club sportif, et plus encore !
                </p>
            </div>
        </div>
    </div>
    <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid">
                    <div className="d-flex flex-column flex-xl-row">
                        <div className="card bg-body me-9 pb-lg-18 w-100">
                            <div className="card-body pb-lg-20">
                                <div className="mb-13">
                                    <div className="mb-9">
                                        <h3 className="fs-2qx fw-bolder text-dark">A propos de Partenaire Link</h3>
                                        <span className="fs-5 fw-bold text-gray-400">Gérez les partenaires de votre club sportif, et plus encore !</span>
                                    </div>

                                    <div
                                        className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-325px"
                                        style={{backgroundImage: "url('images/img-4.jpg')"}}></div>


                                    <div className="fs-5 fw-bold text-gray-600 mt-4">
                                        <p className="mb-8">Vous êtes un club sportif à la recherche d'un logiciel permettant de gérer efficacement vos partenaires ? <br/> Partenaire Link est la solution qui vous fera gagner du temps. On s'occupe de tout.</p>
                                    </div>
                                </div>
                                <h2> <li>Une application web pour les responsables de club</li></h2>
                                <div className="pb-xl-7 pt-5">
                                    <div className="d-flex flex-wrap align-items-center justify-content-center">
                                        <div className="col-xl-4 col-md-12 h-250px d-flex justify-content-center align-items-center">
                                            <img src="/images/MacBook_Pro.png"
                                                 className="position-absolute z-index-2 w-375px" alt=""/>
                                        </div>
                                        <div className="col-xl-8 col-md-12">
                                            <div className="ps-md-20 ps-lg-14">
                                                <div
                                                    className="d-flex align-items-top position-relative z-index-2 mb-7">
                                                    <div className="symbol symbol-50px me-5 me-lg-9 pt-1">
																		<span className="symbol-label bg-light">
                                                                             <span
                                                                                 className="svg-icon svg-icon-2x svg-icon-info fa fa-user fa-2x">
																			</span>
																		</span>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <a href="#"
                                                           className="text-dark text-hover-primary fs-3 fw-bolder mb-2">Gérer vos partenaires</a>
                                                        <span className="text-gray-700 fw-bold fs-4">Les informations sur vos partenaires centralisées au même endroit.</span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex align-items-top position-relative z-index-2 mb-7">
                                                    <div className="symbol symbol-50px me-5 me-lg-9 pt-1">
																		<span className="symbol-label bg-light">
                                                                            <span
                                                                                className="svg-icon svg-icon-2x svg-icon-info fa fa-envelope fa-2x">
																			</span>
																		</span>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <a href="#"
                                                           className="text-dark text-hover-primary fs-3 fw-bolder mb-2">Programmer facilement vos mails et invitations</a>
                                                        <span className="text-gray-700 fw-bold fs-4">Prévoyez à l'avance l'envoi de vos mails et invitations. Et récupérer vos paiements avant votre événement grâce à notre service de paiement en ligne.</span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex align-items-top position-relative z-index-2 mb-7">
                                                    <div className="symbol symbol-50px me-5 me-lg-9 pt-1">
																		<span className="symbol-label bg-light">
                                                                            <span
                                                                                className="svg-icon svg-icon-2x svg-icon-info fa fa-newspaper fa-2x">
																			</span>
																		</span>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <a href="#"
                                                           className="text-dark text-hover-primary fs-3 fw-bolder mb-2">Publications pour votre application mobile</a>
                                                        <span className="text-gray-700 fw-bold fs-4">Mettez en avant un sponsor grâce aux publications mobile mises en avant.</span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex align-items-top position-relative z-index-2 mb-7">
                                                    <div className="symbol symbol-50px me-5 me-lg-9 pt-1">
																		<span className="symbol-label bg-light">
                                                                            <span
                                                                                className="svg-icon svg-icon-2x svg-icon-info fa fa-sms fa-2x">
																			</span>
																		</span>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <a href="#"
                                                           className="text-dark text-hover-primary fs-3 fw-bolder mb-2">Envoyer des SMS</a>
                                                        <span className="text-gray-700 fw-bold fs-4">Programmer vos envois de SMS à vos contacts.</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-top position-relative z-index-2">
                                                    <div className="symbol symbol-50px me-5 me-lg-9 pt-1">
																		<span className="symbol-label bg-light">
                                                                              <span
                                                                                  className="svg-icon svg-icon-2x svg-icon-info fab fa-forumbee fa-2x">
																			</span>
																		</span>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <a href="#"
                                                           className="text-dark text-hover-primary fs-3 fw-bolder mb-2">Gérer le forum de discussion pour vos partenaire.</a>
                                                        <span className="text-gray-700 fw-bold fs-4">Ailler un œil sur les publications de vos partenaires et choisissez de les publier manuellement ou automatiquement.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h2> <li>Une application mobile pour vos partenaires</li></h2>
                                <div className="pb-xl-7 pt-5">
                                    <div className="d-flex flex-wrap align-items-center justify-content-center">
                                        <div className="col-xl-8 col-md-12">
                                            <div className="ps-md-20 ps-lg-14">
                                                <div
                                                    className="d-flex align-items-top position-relative z-index-2 mb-7">
                                                    <div className="symbol symbol-50px me-5 me-lg-9 pt-1">
																		<span className="symbol-label bg-light">
                                                                             <span
                                                                                 className="svg-icon svg-icon-2x svg-icon-info fa fa-user fa-2x">
																			</span>
																		</span>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <a href="#"
                                                           className="text-dark text-hover-primary fs-3 fw-bolder mb-2">Un annuaire de tous les partenaires</a>
                                                        <span className="text-gray-700 fw-bold fs-4">Retrouver les contacts des partenaires du club.</span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex align-items-top position-relative z-index-2 mb-7">
                                                    <div className="symbol symbol-50px me-5 me-lg-9 pt-1">
																		<span className="symbol-label bg-light">
                                                                            <span
                                                                                className="svg-icon svg-icon-2x svg-icon-info fa fa-bullhorn fa-2x">
																			</span>
																		</span>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <a href="#"
                                                           className="text-dark text-hover-primary fs-3 fw-bolder mb-2">Annonce entre partenaires</a>
                                                        <span className="text-gray-700 fw-bold fs-4">Publiez, réagissez et gérez vos publications sur l'application mobile.</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-top position-relative z-index-2">
                                                    <div className="symbol symbol-50px me-5 me-lg-9 pt-1">
																		<span className="symbol-label bg-light">
                                                                              <span
                                                                                  className="svg-icon svg-icon-2x svg-icon-info fa fa-envelope fa-2x">
																			</span>
																		</span>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <a href="#"
                                                           className="text-dark text-hover-primary fs-3 fw-bolder mb-2">Retrouver vos mails et invitations aux évènements</a>
                                                        <span className="text-gray-700 fw-bold fs-4">Retrouver vos emails et invitations futur ou passé, accepté ou refusé sur votre application partenaire.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-12 h-250px d-flex justify-content-center align-items-center">
                                            <img style={{right:50}} src="/images/iPhone_12_Mini.png"
                                                 className="h-400px pt-20 pb-20"
                                                 alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-0">
                                    <div className="mb-9">
                                        <h1 className="fw-bolder text-dark mb-3">Notre équipe</h1>
                                        <span className="fs-5 fw-bold text-muted">Économisez des milliers d'euros en utilisant un seul outil pour différentes tâches administratives.</span>
                                    </div>
                                    <div className="d-flex flex-wrap w-100 mw-xxl-800px justify-content-around">
                                        <div className="d-flex flex-column text-center mb-11 me-5 me-lg-15">
                                            <div className="symbol symbol-100px symbol-lg-150px mb-4">
                                                <img src="/images/300-5.jpg" className="" alt=""/>
                                            </div>
                                            <div className="text-center">
                                                <p
                                                   className="text-dark fw-bolder fs-4">Yannick Blanc</p>
                                                <span className="text-muted d-block fw-bold">Développeur</span>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column text-center mb-11 me-5 me-lg-15">
                                            <div className="symbol symbol-100px symbol-lg-150px mb-4">
                                                <img src="/images/300-11.jpg" className="" alt=""/>
                                            </div>
                                            <div className="text-center">
                                                <p
                                                    className="text-dark fw-bolder fs-4">Thibault Loeuillet</p>
                                                <span className="text-muted d-block fw-bold">Développeur</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

}
export default HomeView;