import useGetTypeInvitation from "@requests/communication/invitations/useGetTypeInvitation";
import { useMemo } from "react";
import { useController } from "react-hook-form";
import Select2 from "@components/Select2/Select2";
import { Spinner, Alert } from "react-bootstrap";

function TypeInvitationSelection({ control, name, rules }) {
    const typeInvitation = useGetTypeInvitation();
    const { field } = useController({ control, name, rules });

    const data = useMemo(() => {
        return typeInvitation.data?.data?.map(exp => ({
            id: exp.id,
            text: `${exp.libelle}`,
        }));
    }, [typeInvitation.data]);

    if (typeInvitation.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    if (typeInvitation.isError)
        return <Alert variant='danger'>{typeInvitation.error.message}</Alert>


    return <Select2 solid
                    data={data}
                    selected={field.value != null ? [field.value.id.toString()] : null}
                    minimumResultsForSearch={3}
                    placeholder='Sélectionner un type'
                    onChange={(selected) => {
                        selected = selected?.at(0);
                        const res = typeInvitation.data?.data?.find(
                            exp => exp.id.toString() === selected
                        );
                        field.onChange(res);
                    }}
    />
}

export default TypeInvitationSelection;