import {faPencilAlt, faUndo} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useEffect, useRef, useState} from "react";
import useUploadFile from "../../requests/upload/useUploadFile";
import BlockUI from "../BlockUI/BlockUI";

import './style.css';
import {useController} from "react-hook-form";
import useRotateUploadImage from "../../requests/upload/useRotateUploadImage";
import {useQueryClient} from "react-query";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";
import { ReactComponent as Pen } from "@assets/icons/pen.svg";

function ImagePicker({ type, setValue, getValues, id, url, onChange, circular, imageClassName, className }) {
    const inputFile = useRef();
    // TODO: handle error
    const uploadFile = useUploadFile({ type: type });
    const queryClient = useQueryClient();
    const [allowRotate, setAllowRotate] = useState(true);
    const snapAuth = useSnapshot(auth);
    const rotateImage = useRotateUploadImage({
        onSuccess: () => {
            queryClient.invalidateQueries(['personne', 'entreprises']);
            queryClient.invalidateQueries('annuaire');
            queryClient.invalidateQueries('utilisateur');
            queryClient.invalidateQueries('destinataire');
            snapAuth.websocket.send("personne")
            snapAuth.websocket.send("entreprises")
            setAllowRotate(true);
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de la rotation de l'image", err.message);
        },
    });
    const [rotate, setRotate] = useState(0)

    let urltest = null;
    if (uploadFile.data != null){
        urltest = uploadFile.data.urlMiniature ? uploadFile.data.urlMiniature : uploadFile.data.urlOriginale

        if (uploadFile.data.urlOriginale !== getValues().upload.urlOriginale){
            setValue("upload", {
                id: uploadFile.data.id,
                libelle: "Portrait",
                type: type,
                urlMiniature: uploadFile.data.urlMiniature,
                urlOriginale: uploadFile.data.urlOriginale
            })

        }

    }else{
        urltest = url
    }

    function handleUploadInput(e) {
        const file = e.target.files[0];
        let test = uploadFile.mutate(file);
    }



    return <BlockUI loading={uploadFile.loading} className='overflow-visible'>
        <div className={`image-input image-input-outline ${circular ? 'image-input-circle' : ''}`}>
            <div
                className={`image-input-wrapper ${imageClassName ?? ''}`}
                style={{
                    backgroundImage: `url("${urltest}")`,
                    backgroundPosition: 'center',
                    backgroundSize: imageClassName === "w-150px h-150px rounded-circle" ? 'cover' : 'contain',
                    transform:'rotate(-'+rotate+'deg)'
                }}
            />
            <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow image-picker-edit'
                onClick={() => inputFile.current?.click()}
            >
                <Pen className="w-15px"/>
                <input
                    type='file'
                    id='file'
                    ref={inputFile}
                    style={{ display: 'none' }}
                    accept='.png,.jpg,.jpeg'
                    onChange={handleUploadInput}
                />
            </label>
            <label
                className={`btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow image-picker-undo ${!allowRotate || id < 5 || (url && url.split(".").pop() === "png") ? "disabled" : ""}`}
                onClick={() => {
                    if (id >= 5){
                        let data = {
                            id: id,
                            rotate: {
                                angle: 90
                            }
                        }
                        setAllowRotate(false);
                        setRotate(rotate + 90)
                        rotateImage.mutate(data);
                    }
                }}
            >
                <FontAwesomeIcon icon={faUndo} className="w-15px" style={{opacity : "0.5"}} />
            </label>
        </div>
    </BlockUI>;
}

export default ImagePicker;