import { useQuery } from "react-query";
import api from "@services/api";

export default function useGetEntreprisePersonne(id, isUtilisateur) {
    if (isUtilisateur){
        isUtilisateur = "utilisateur";
    }else{
        isUtilisateur = "personne";
    }
    return useQuery(
        ['personne', 'entreprises'],
        async () => {
            return api.get(`${isUtilisateur}/${id}/entreprises`).json();
        },
        {
            enabled: id != null,
        }
    );
}