import { useMutation } from "react-query";
import api from "@services/api";

export default function useDeleteBlocModalite(props) {

    return useMutation(deleteModalite, props);

}

function deleteModalite(id) {

    if (id != null)
        return api.delete(`blocModaliteReponseInvitation/${id}`).json();

}