import { useMutation } from "react-query";
import api from "@services/api";
import auth from "@services/auth";

export default function useChangeResponsablePlaceLimite(props) {
    return useMutation(changeResponsablePlaceLimite, props);
}

function changeResponsablePlaceLimite(responsable) {
    const id = responsable?.id;

    if (id != null)
        return api.put(`responsablePlaceLimite/${id}`, { json: responsable }).json();
    return api.post('responsablePlaceLimite', {
        json: {
            ...responsable,
            idAgence: auth?.agence?.id,
        }
    }).json();
}