import ExpediteurSettings from "./ExpediteursSettings/ExpediteurSettings";
import HeaderSettings from "./HeaderSettings.js/HeaderSettings";

function MailSettings({ show }) {
    return <div>
        <h4>Entêtes des mails</h4>
        <HeaderSettings show={show} />
        <h4>Expéditeurs des mails</h4>
        <ExpediteurSettings show={show} />
    </div>;
}

export default MailSettings;