import { useQuery } from "react-query";
import api from "@services/api";

export default function useGetExportStatMail(id) {

    return useQuery(
        ["correspondantEmail/urlExport"+id],
        async () => {
            return api.get("correspondantEmail/urlExport?idEmail="+id).json();
        },
        {
            enabled: id != null,
        },
    );

}