import { useMutation } from "react-query";
import api from "@services/api";

export default function useGetExportReversion(props) {
    return useMutation(getExport, props);
}

export async function getExport(id) {

    if (id != null)
        return api.post(`reversionPaiement/${id}/export`).json();
}