import { Dropdown } from 'react-bootstrap';
import { useState } from 'react';
import ContactModal from '@views/AnnuaireView/ContactModal/ContactModal';
import ChangeAgenceModal from './ChangeAgenceModal/ChangeAgenceModal';
import { useSnapshot } from 'valtio';
import auth from '@services/auth';
import {useNavigate, useParams} from 'react-router-dom';
import { ReactComponent as Cod001 } from "@assets/icons/cod001.svg";
import { ReactComponent as Com013 } from "@assets/icons/com013.svg";
import { ReactComponent as Map009 } from "@assets/icons/map009.svg";
import { ReactComponent as Arr077 } from "@assets/icons/arr077.svg";
import SettingsModal from '../SettingsModal/SettingsModal';
import annuaireProxy from "@proxies/annuaire";
import ForumEditViewModal from "../../forum/ForumView/ForumEditView/ForumEditViewModal";
import StatModal from "../../communication/MailsView/StatistiqueMail/ModalStatistiqueMail";
import SuiviModal from "../../communication/InvitationsView/SuiviModal/SuiviModal";
import FileManagerView from "../../FileManagerView/FileManagerView";
import ForumPostViewModal from "../../forum/ForumPostView/ForumPostViewModal";

function UserProfil() {
    const [showMenu, setShowMenu] = useState(false);
    const [showChangeAgence, setShowChangeAgence] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const snap = useSnapshot(auth);
    const navigate = useNavigate();
    const {agence} = useParams();

    return <>
        <Dropdown
            show={showMenu}
            onMouseEnter={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
        >
            <Dropdown.Toggle variant='flush' className='hide-after text-start mt-5 w-100'>
                <div className='d-flex align-items-sm-center justify-content-center py-5'>
                    <div className='me-5'>
                        <div className='symbol symbol-40px cursor-pointer'>
                            <img src={snap.utilisateur?.upload?.urlMiniature} alt='' />
                        </div>
                    </div>
                    <div className='flex-row-fluid flex-wrap'>
                        <div className='d-flex align-items-center flex-stack'>
                            <div className='me-2 fw-bold'>
                                <span className='text-white fs-6 lh-0'>{snap.utilisateur?.prenom} {snap.utilisateur?.nom}</span>
                                <span className='text-gray-400 d-block fs-8'>Agence {snap.agence?.nom}</span>
                            </div>
                            <span className='svg-icon svg-icon-1'><Cod001 /></span>
                        </div>
                    </div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className='fs-6 menu menu-sub menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
                <div className='menu-item px-3'>
                    <Dropdown.Item
                        className='menu-link px-3'
                        onClick={() => annuaireProxy.showPersonne(snap.utilisateur, true, "personne", true)}
                    >
                        <span className='svg-icon svg-icon-1 me-2'><Com013 /></span>
                        Mon profil
                    </Dropdown.Item>
                    <Dropdown.Item
                        className='menu-link px-3'
                        onClick={() => navigate('/'+(agence ? agence : "plink")+'/parametres')}
                    >
                        <span className='svg-icon svg-icon-1 me-2'><Cod001 /></span>
                        Paramètres
                    </Dropdown.Item>
                    {Object.keys(snap.utilisateur?.tabAgences)?.length > 1 && <Dropdown.Item
                        className='menu-link px-3'
                        onClick={() => setShowChangeAgence(true)}
                    >
                        <span className='svg-icon svg-icon-1 me-2'><Map009 /></span>
                        Changer d'agence
                    </Dropdown.Item>}
                    <Dropdown.Item
                        className='menu-link px-3'
                        onClick={() => {
                            navigate('/'+(agence ? agence : "plink")+'/login');
                            auth.logout();
                        }}
                    >
                        <span className='svg-icon svg-icon-1 me-2'><Arr077 /></span>
                        Se déconnecter
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
        <ChangeAgenceModal
            show={showChangeAgence}
            onHide={() => setShowChangeAgence(false)}
        />
        <SettingsModal
            show={showSettings}
            onHide={() => setShowSettings(false)}
        />
        <ContactModal />
        <ForumEditViewModal/>
        <StatModal />
        <SuiviModal />
        <FileManagerView/>
        <ForumPostViewModal />

    </>;
}

export default UserProfil;