import BlockUI from "../../components/BlockUI/BlockUI";
import useGetUrl from "../../requests/stripe/useGetUrl";
import {Button, Col, Dropdown, Row} from "react-bootstrap";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";
import {useForm} from "react-hook-form";
import FormImagePickerStripe from "../../components/form/FormImagePicker/FormImagePickerStripe";
import React, {useEffect, useState} from "react";
import {BlockPicker} from "react-color";
import useGetParamsCompte from "../../requests/stripe/useGetParamsCompte";
import useChangeParamsStripe from "../../requests/stripe/useChangeParamsStripe";
import LoadingView from "../LoadingView/LoadingView";
import { ReactComponent as FillDrip } from "@assets/icons/fillDrip.svg";
import {useQueryClient} from "react-query";
import {BtSwal} from "../../utils/alerts/sweetAlert";
import PreviewAdaptationMarqueCheckoutAndPayment
    from "./PreviewAdaptationMarqueCheckoutAndPayment";
import PreviewAdaptationMarqueRecu from "./PreviewAdaptationMarqueRecu";


function ParametresAdaptationMarqueView() {
    const getUrl = useGetUrl();
    const queryClient = useQueryClient();
    const paramsCompte = useGetParamsCompte();
    const snap = useSnapshot(auth);
    const [showCheckoutAndPayment, setshowCheckoutAndPayment] = useState(true);
    const changeParamsStripe = useChangeParamsStripe({
        onSuccess: () => {
            queryClient.invalidateQueries(['useGetUrl']);
            snap.websocket.send("useGetUrl")

            BtSwal.fire('Les paramètres ont été modifés.', '', 'success')
        },
        onError: (err) => {
            queryClient.invalidateQueries(['useGetUrl']);
            snap.websocket.send("useGetUrl")

            BtSwal.fire(err.message, '', 'error')
        }
    });
    const { register, watch, handleSubmit, reset, setValue, getValues, control } = useForm();
    const [updateData, setUpdateData] = useState(0);

    console.log("update data ==> "+updateData);

    useEffect(() => {
        reset(paramsCompte.data);
    }, [paramsCompte.data]);

    if (snap?.parametreFacturation?.compteStripeIsConfigure && paramsCompte.isSuccess){
        return <BlockUI loading={getUrl.isLoading || changeParamsStripe.isLoading}>
            <div className="d-flex">
                <div style={{width: "fit-content"}} className='alert alert-info m-auto mb-3'>
                    <div className="mt-2 text-center">
                        <p>
                            Les paramètres ci-dessous vous permettrons d'adapter votre compte stripe à votre image de marque. <br/>
                            Par exemple, les reçus de paiement seront aux couleurs choisies avec l'icône et/ou le logo renseigné.
                        </p>
                    </div>
                </div>
                <Button
                    style={{width:"fit-content"}}
                    variant='secondary'
                    className='px-20 m-auto d-flex'
                    onClick={handleSubmit((data) => {
                        let newData = {
                            icon : data.icon,
                            logo : data.logo,
                            primary : data.primary_color ? data.primary_color : (data.parametresStripe.primary_color ? data.parametresStripe.primary_color : "#000000"),
                            secondary : data.secondary_color ? data.secondary_color : (data.parametresStripe.secondary_color ? data.parametresStripe.secondary_color : "#000000")
                        }
                        changeParamsStripe.mutate(newData)
                    })}
                >
                    Enregistrer les modifications
                </Button>
            </div>
            <Row className="" style={{minHeight:"200px"}}>
                <Col sm={2} className="justify-content-center d-flex me-3">
                    <div className="d-flex flex-column align-items-center">
                        <h1 className="mb-5">Icône :</h1>
                        <FormImagePickerStripe setValue={setValue} getValues={getValues} control={control} name='apercu.urlIcone' />
                    </div>
                </Col>
                <Col sm={3} className="justify-content-center d-flex">
                    <div className="d-flex flex-column align-items-center">
                        <h1 className="mb-5">Logo :</h1>
                        <FormImagePickerStripe setValue={setValue} getValues={getValues} control={control} name='apercu.urlLogo' />
                    </div>
                </Col>
                <Col sm={3} className="justify-content-center d-flex">
                    <div className="d-flex flex-column align-items-center">
                        <h1 className="mb-5">Couleur primaire :</h1>
                        <div className="d-flex align-items-center">
                            <div className="h-25px w-50px border-2 border-dark" style={{border: "solid", backgroundColor:getValues("parametresStripe.primary_color") }}>

                            </div>
                            <Dropdown title={'Choix de la couleur primaire'}>
                                <Dropdown.Toggle className='hide-after' variant='white'>
                                    <span className='svg-icon svg-icon-1 me-2'><FillDrip/></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='p-0'>
                                    <BlockPicker
                                        color={getValues("parametresStripe.primary_color") ? getValues("parametresStripe.primary_color") : "#000"}
                                        {...register('primary_color')}
                                        onChange={(color) => {
                                            setValue("parametresStripe.primary_color", color?.hex)
                                            setValue("primary_color", color?.hex)
                                            setUpdateData(updateData+1);
                                        }} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
                <Col sm={3} className="justify-content-center d-flex">
                    <div className="d-flex flex-column align-items-center">
                        <h1 className="mb-5">Couleur secondaire :</h1>
                        <div className="d-flex align-items-center">
                            <div className="h-25px w-50px border-2 border-dark" style={{border: "solid", backgroundColor:getValues("parametresStripe.secondary_color") }}>

                            </div>
                            <Dropdown title={'Choix de la couleur primaire'}>
                                <Dropdown.Toggle className='hide-after' variant='white'>
                                    <span className='svg-icon svg-icon-1 me-2'><FillDrip/></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='p-0'>
                                    <BlockPicker
                                        {...register('secondary_color')}
                                        color={getValues("parametresStripe.secondary_color") ? getValues("parametresStripe.secondary_color") : "#000"}
                                        onChange={(color) => {
                                            setValue("parametresStripe.secondary_color", color?.hex)
                                            setValue("secondary_color", color?.hex)
                                            setUpdateData(updateData+1);
                                        }} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
            </Row>
            <ul style={{width: "fit-content"}} className="nav nav-tabs nav-line-tabs m-auto mb-5 fs-6">
                <li className="nav-item cursor-pointer">
                    <a
                        onClick={() => {
                            setshowCheckoutAndPayment(true)
                        }}
                        className={`nav-link ${showCheckoutAndPayment && 'active'}`}
                    >
                        Checkout et Payments Links
                    </a>
                </li>
                <li className="nav-item cursor-pointer">
                    <a
                        onClick={() => {
                            setshowCheckoutAndPayment(false)
                        }}
                        className={`nav-link ${!showCheckoutAndPayment && 'active'}`}
                    >
                        Reçus envoyés par email
                    </a>
                </li>
            </ul>
            {
                showCheckoutAndPayment ?
                    <PreviewAdaptationMarqueCheckoutAndPayment colorSecondary={watch("parametresStripe.secondary_color") ? watch("parametresStripe.secondary_color") : "black"} logoOrIcon={watch("apercu.urlIcone") ? watch("apercu.urlIcone") : watch("apercu.urlLogo")} color={watch("parametresStripe.primary_color") ? watch("parametresStripe.primary_color") : "black"}/>
                    :
                    <PreviewAdaptationMarqueRecu logoOrIcon={watch("apercu.urlIcone") ? watch("apercu.urlIcone") : watch("apercu.urlLogo")} color={watch("parametresStripe.primary_color") ? watch("parametresStripe.primary_color") : "black"}/>
            }
        </BlockUI>;
    }else if (!paramsCompte.isLoading){
        return <BlockUI loading={getUrl.isLoading}>
            <div className='alert alert-info'>
                <div className="mt-2 text-center">
                    <p className="alert alert-warning">
                        Afin d'adapter votre compte stripe à votre marque, vous devez au préalable configurer votre compte.
                    </p>
                </div>
            </div>
        </BlockUI>;
    }else {
        return <LoadingView/>
    }


}

export default ParametresAdaptationMarqueView;