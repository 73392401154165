import {useForm} from "react-hook-form";
import {Navigate, useNavigate} from "react-router";
import LoadingButton from "@components/LoadingButton/LoadingButton";
import useResetPassword from "../../requests/utilisateur/useResetPassword";
import {passwordPattern} from "../../functions/patterns";
import {BtSwal} from "../../utils/alerts/sweetAlert";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {useParams} from "react-router-dom";
import {useState} from "react";
import {InputGroup} from "react-bootstrap";

function ResetPasswordFrom() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get('ref').replace(/ /g, "+");
    const {agence} = useParams();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const motDePasse = watch('motDePasse');
    const [objectVerifMDP, setObjectVerifMDP] = useState({
        hasSizeMinSix : false,
        hasNumber : false,
        hasLetter : false
    })

    const resetPassword = useResetPassword({
        onSuccess: () => {
            navigate("/"+agence+"/success",
                {
                    state : {
                        titleText : "Le mot de passe a bien été réinitialisé."
                    }
                }
            );
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    function onSubmit(data) {
        if (data.motDePasse !== data.motDePasseVerif){
            BtSwal.fire('Le nouveau mot de passe et la confirmation du mot de passe sont différents.', '', 'error')
        }else {
            delete data.motDePasseVerif
            resetPassword.mutate(data);
        }
    }

    function onKeyUpVerif(){
        let tmpObject = {
            hasSizeMinSix : false,
            hasNumber : false,
            hasLetter : false
        }
        if (motDePasse?.length >= 6){
            tmpObject = {
                ...tmpObject,
                hasSizeMinSix: true
            }
        }
        let regExp = /[a-zA-Z]/g;
        if (regExp.test(motDePasse)){
            tmpObject = {
                ...tmpObject,
                hasLetter: true
            }
        }
        if (/[0-9]/.test(motDePasse)){
            tmpObject = {
                ...tmpObject,
                hasNumber: true
            }
        }
        setObjectVerifMDP(tmpObject)
    }


    return <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' onSubmit={handleSubmit(onSubmit)} >
        <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Réinitialisation du</h1>
            <div className='text-gray-400 fw-bold fs-4'>mot de passe</div>
        </div>
        <div className='fv-row mb-10 fv-plugins-icon-container'>
            <label className='form-label fs-6 fw-bolder text-dark'>Nouveau mot de passe</label>
            <InputGroup style={{
                backgroundColor: "#f8f6f2"
            }} className="d-flex align-items-center">
                <input
                    className='form-control form-control-lg form-control-solid'
                    type={`${showPassword ? "text" : "password"}`}
                    name='motDePasse'
                    autoComplete='off'
                    onKeyUp={() => onKeyUpVerif()}
                    {...register('motDePasse', {required: true, pattern: passwordPattern()})}
                />
                <span
                    onMouseOver={(e) => {
                        e.target.style.color = "var(--primary-color)"
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.color = "black"
                    }}
                    onClick={(e) => {
                        setShowPassword(!showPassword)
                    }}
                    style={{
                        borderRadius: "0 10 10 0",
                        width: 30
                    }} className={`cursor-pointer ms-1 fa fa-2x ${showPassword ? "fa-eye-slash" : "fa-eye"}`}></span>
            </InputGroup>
            <div class="d-flex align-items-center mb-3 mt-3" data-kt-password-meter-control="highlight">
                <div
                    class={`flex-grow-1 ${Object.values(objectVerifMDP).filter(item => item === true).length >= 1 ? "bg-success bg-active-success" : "bg-gray-300"} rounded h-5px me-2`}></div>
                <div
                    class={`flex-grow-1 ${Object.values(objectVerifMDP).filter(item => item === true).length >= 2 ? "bg-success bg-active-success" : "bg-gray-300"} rounded h-5px me-2`}></div>
                <div class={`flex-grow-1 ${Object.values(objectVerifMDP).filter(item => item === true).length >= 3 ? "bg-success bg-active-success" : "bg-gray-300"} rounded h-5px`}></div>
            </div>
            {errors.motDePasse &&
                <div className='fv-plugins-message-container invalid-feedback'>
                    Ce champ est requis. Minimum de six caractères, au moins une lettre et un chiffre. Les espaces et émojis sont interdits.
                </div>
            }
            <label className='form-label fs-6 fw-bolder text-dark'>Confirmation du nouveau mot de passe</label>
            <InputGroup style={{
                backgroundColor: "#f8f6f2"
            }} className="d-flex align-items-center">
                <input
                    className='form-control form-control-lg form-control-solid'
                    type={`${showPasswordConfirm ? "text" : "password"}`}
                    name='motDePasseVerif'
                    autoComplete='off'
                    {...register('motDePasseVerif', {required: true, pattern: passwordPattern()})}
                />
                <span
                    onMouseOver={(e) => {
                        e.target.style.color = "var(--primary-color)"
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.color = "black"
                    }}
                    onClick={(e) => {
                        setShowPasswordConfirm(!showPasswordConfirm)
                    }}
                    style={{
                        borderRadius: "0 10 10 0",
                        width: 30
                    }} className={`cursor-pointer ms-1 fa fa-2x ${showPasswordConfirm ? "fa-eye-slash" : "fa-eye"}`}></span>
            </InputGroup>
            {errors.motDePasseVerif &&
                <div className='fv-plugins-message-container invalid-feedback'>
                    Ce champ est requis. Minimum de six caractères, au moins une lettre et un chiffre. Les espaces et
                    émojis sont interdits.
                </div>
            }
            <input
                className='form-control form-control-lg form-control-solid'
                type='hidden'
                name='ref'
                autoComplete='off'
                value={ref}
                {...register('ref')}
            />
        </div>
        <div className='text-center'>
            <LoadingButton
                loading={resetPassword.isLoading}
                type='submit'
            >Réinitialiser le mot de passe</LoadingButton>
            {/*{resetPassword.isSuccess && <Navigate to={"/"+(agence ? agence : "plink")+"/login"} replace={true} />}*/}
        </div>
    </form>;
}

export default ResetPasswordFrom;