import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useController } from 'react-hook-form';
import {forwardRef, useRef} from "react";

const FormTypeahead= forwardRef(({ control, name, rules, id, solid, isLoading, options, textKey, onSelected, autocompleteDisabled, maxLength, ...props }, ref) => {
    const { field } = useController({ control, name, rules });

    return <AsyncTypeahead
        defaultInputValue={field.value ?? ''}
        onInputChange={field.onChange}
        id={id}
        ref={ref}
        options={options}
        isLoading={!!isLoading}
        onSearch={() => { }}
        open={autocompleteDisabled || !(options?.length > 0) ? false : undefined}
        renderMenuItemChildren={textKey === null
            ? null
            : (option, props, index) => textKey(option)
        }
        inputProps={{ maxLength:maxLength, className: solid ? 'form-control-solid' : '', id : id}}
        onChange={(selected) => {
            if (onSelected)
                onSelected(selected[0]);
        }}
        {...props}
    />;
})

export default FormTypeahead;