import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {useSnapshot} from "valtio";
import auth from "../../services/auth";

export default function useDelierAnnuaire() {
    const queryClient = useQueryClient();
    const snapAuth = useSnapshot(auth);
    return useMutation(({ personne, entreprise }) => {
        return api.post('annuaire/delier', {
            json: {
                personne: personne,
                entreprise: entreprise,
            },
        }).json();
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('annuaire');
            queryClient.invalidateQueries('personne', 'entreprises');
            snapAuth.websocket.send("annuaire")
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}