import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetProfilPartenaire() {

    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = {
        data: "isPartenaire",
        searchable: true,
        orderable: true,
        search: {
            value: 1,
            regex: false,
        }
    }

    filters.order[0] = {
        column:0,
        dir: "desc"
    }

    return useQuery(
        ['profil'],
        async () => {
            return api.get('profil', {
                searchParams: Qs.stringify({
                    start: 0,
                    length: 500,
                    ...filters,
                }, { arrayFormat: 'brackets' }),
            }).json();
        },
    );
}