import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useDesinscrireParticipant({ baseRoute, id, filters, ...props }) {
    const queryClient = useQueryClient();
    return useMutation(
        ({ idPersonnes, isInclusif }) => {
            return api.post(
                `${baseRoute}/${id}/desinscrireParticipants`,
                {
                    json: {
                        tabIdsPersonne: idPersonnes,
                        isInclusif,
                        tabFiltres: filters,
                    },
                },
            ).json();
        },
        {
            ...props,
            onSuccess: () => {
                queryClient.invalidateQueries([baseRoute, id, 'participants']);
                if (props?.onSuccess)
                    props.onSuccess();
            },
            onError: (err) => {
                showErrorAlert("Erreur lors de l'enregistrement", err.message);
            },
        }
    );
}