import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion } from "react-bootstrap";
import EntrepriseForm from "./EntrepriseForm/EntrepriseForm";
import {useState} from "react";
import {useSnapshot} from "valtio";
import annuaireProxy from "../../../../proxies/annuaire";

function EntrepriseSelection({ isPartenaire, personne, entreprises, onSave, onRemove, setEntrepriseInEdit }) {
    const [accordionKey, setAccordionKey] = useState(null);
    const annuaireSnapshot = useSnapshot(annuaireProxy);

    function toggleAccordionKey(key) {
        if (key === accordionKey) {
            setAccordionKey(null);
            setEntrepriseInEdit(false);
        }
        else {
            setAccordionKey(key);
            setEntrepriseInEdit(true);
        }
    }

    let forms;

    if (annuaireSnapshot.isUtilisateur && entreprises && entreprises[0]){
        forms = <EntrepriseForm
            isPartenaire={isPartenaire}
            entreprise={entreprises[0]}
            personne={personne}
            onSave={async (entreprise) => {
                await onSave(entreprise);
                setAccordionKey(null);
                setEntrepriseInEdit(false);
            }}
            onDeny={() => {
                setAccordionKey(null);
                setEntrepriseInEdit(false);
            }}
            onRemove={onRemove}
        />
    }else {
        forms = entreprises?.map(entreprise => <Accordion.Item
            key={entreprise.id}
            eventKey={entreprise.id}
        >
            <Accordion.Header onClick={() => toggleAccordionKey(entreprise.id)}>
                <div className='fs-4 fw-bold ms-2'>{entreprise.nom}</div>
            </Accordion.Header>
            <Accordion.Body>
                <EntrepriseForm
                    isPartenaire={isPartenaire}
                    entreprise={entreprise}
                    personne={personne}
                    onSave={async (entreprise) => {
                        await onSave(entreprise);
                        setAccordionKey(null);
                        setEntrepriseInEdit(false);
                    }}
                    onDeny={() => {
                        setAccordionKey(null);
                        setEntrepriseInEdit(false);
                    }}
                    onRemove={onRemove}
                />
            </Accordion.Body>
        </Accordion.Item>);
    }

    return <div>
        <Accordion activeKey={accordionKey}>
            {forms}
            {!annuaireSnapshot.isUtilisateur &&
            <Accordion.Item eventKey='new'>
                <Accordion.Header onClick={() => toggleAccordionKey('new')}>
                    <div className='fs-4 fw-bolder ms-2 text-success'>
                        <FontAwesomeIcon icon={faPlus} className='me-2' />
                        Ajouter
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <EntrepriseForm
                        isPartenaire={isPartenaire}
                        personne={personne}
                        onSave={async (entreprise) => {
                            await onSave(entreprise);
                            setAccordionKey(null);
                            setEntrepriseInEdit(false);
                        }}
                        onDeny={() => {
                            setAccordionKey(null);
                            setEntrepriseInEdit(false);
                        }}
                    />
                </Accordion.Body>
            </Accordion.Item>}
        </Accordion>
    </div>;
}

export default EntrepriseSelection;