import { useMutation } from "react-query";
import api from "@services/api";

export default function useChangeChampsPerso(props) {
    return useMutation(changeChampPerso, props);
}

export async function changeChampPerso(champ) {
    const id = champ.id;

    if (id != null)
        return api.put(`champPersonnaliseAnnuaire/${id}`, { json: champ }).json();
    return api.post('champPersonnaliseAnnuaire', { json: champ }).json();
}
