import { proxy } from "valtio";

const mailsProxy = proxy({
    mail: null,
    tabIdsDestinataires:null,
    destinataireIsChecked:false,
    destinataireAlertIsAdd: false,
    destinataire: null,
    currentFilterUrl: null,
    detailEmailModal: {
        show : false,
    },
    editModal: {
        show: false,
        step: 0,
        loading: false,
    },
    sendModal: {
        show: false,
    },
    relanceModal: {
        show:false,
    },
    statModal: {
        show:false,
    },
    exportStatModal:{
        show: false
    },
    showEditMail: (mail) => {
        mailsProxy.mail = mail;
        mailsProxy.sendModal.show = false;
        mailsProxy.relanceModal.show = false;
        mailsProxy.tabIdsDestinataires = null;
        mailsProxy.destinataireIsChecked = false;
        mailsProxy.editModal = {
            show: true,
            step: 0,
            loading: false,
        };
    },
    showSendMail: (mail) => {
        mailsProxy.mail = mail;
        mailsProxy.editModal.show = false;
        mailsProxy.relanceModal.show = false;
        mailsProxy.sendModal = {
            show: true,
        };
    },
    showRelanceMail: (mail) => {
        mailsProxy.mail = mail;
        mailsProxy.editModal.show = false;
        mailsProxy.sendModal.show = false;
        mailsProxy.relanceModal = {
            show: true,
        };
    },
    showStatMail: (mail) => {
        mailsProxy.mail = mail;
        mailsProxy.sendModal.show = false;
        mailsProxy.statModal = {
            show: true,
        };
    },
    showDetailEmailModal: (destinataire) => {
        mailsProxy.destinataire = destinataire;
        mailsProxy.editModal.show = false;
        mailsProxy.relanceModal.show = false;
        mailsProxy.detailEmailModal = {
            show: true,
        };
    },
    showExportStatMail:(currentFilterUrl) => {
        mailsProxy.editModal.show = false;
        mailsProxy.relanceModal.show = false;
        mailsProxy.currentFilterUrl = currentFilterUrl;
        mailsProxy.exportStatModal = {
            show: true,
        };
    }
})

export default mailsProxy;