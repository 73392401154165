import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import useDeleteMessageSujet from "../../../../requests/forum/useDeleteMessageSujet";

function Response({ author, content, dateCreation, idResponse, idSujet }) {

    const deleteMessage = useDeleteMessageSujet(idSujet)

    return <div>
        <div className='d-flex align-items-center '>
            <div className='symbol symbol-35px pe-3'>
                <img src={author.upload?.urlMiniature} alt='' />
            </div>
            <span className='fs-5 fw-bold'>{author.prenom} {author.nom}</span>
            <div className='card-toolbar ms-auto'>
                <span className='badge badge-light-warning fs-7'>{dateCreation}</span>
                <Button
                    onClick={() => deleteMessage.mutate(idResponse)}
                    variant='icon'
                    className='btn-sm btn-light btn-active-danger w-25px h-25px'
                >
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            </div>
        </div>
        <p className='mt-3' dangerouslySetInnerHTML={{
            __html: content
        }}>

        </p>
    </div>;
}

export default Response;