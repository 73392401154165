import { useQuery } from "react-query";
import api from "../../../services/api";

export default function useGetInvitationById(id) {
    if (id){
        return useQuery(
            ['invitation', id],
            () => api.get('invitation/'+id).json(),
        );
    }
}