import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./CountDownCircleTimer.css";
import {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";

const renderTime = ({ remainingTime }) => {
    return (
        <div className="timer-CDCT">
            <div className="value-CDCT-light">{remainingTime}</div>
        </div>
    );
};

function CountDownCircleTimerLight({color = "--primary-color", isPlaying = true, duration = 10, setCurrentIndex, currentIndex = 0, currentPerson = 0, valueChangeRemainingTimeLight = false, setValueChangeRemainingTimeLight}) {
    const [key, setKey] = useState(0);
    const [localCurrentPerson, setLocalCurrentPerson] = useState(currentPerson);
    const [initialRemainingTime, setInitialRemainingTime] = useState(valueChangeRemainingTimeLight ? valueChangeRemainingTimeLight : duration);
    useEffect(() => {
        if (valueChangeRemainingTimeLight){
            setInitialRemainingTime(valueChangeRemainingTimeLight);
            setLocalCurrentPerson(currentPerson);
            setKey(uuidv4())
        }else {
            setInitialRemainingTime(duration)
            setKey(uuidv4())
        }
    }, [valueChangeRemainingTimeLight, duration]);
    return (
            <div className="d-flex justify-content-center align-items-center ms-3">
                <div className="timer-wrapper-CDCT position-relative">
                    <CountdownCircleTimer
                        key={key}
                        initialRemainingTime={initialRemainingTime ? initialRemainingTime : duration}
                        trailStrokeWidth={3}
                        strokeWidth={3}
                        size={30}
                        isPlaying={isPlaying}
                        duration={duration}
                        colors={["var("+color+")"]}
                        onComplete={() => {
                            if (currentPerson != localCurrentPerson) {
                                setCurrentIndex(0);
                                setLocalCurrentPerson(currentPerson);
                                setValueChangeRemainingTimeLight(false)
                            }else {
                                setCurrentIndex(currentIndex+1)
                                setValueChangeRemainingTimeLight(false)
                            }
                            setKey(uuidv4())
                            setValueChangeRemainingTimeLight(false)
                        }}
                        onUpdate={(newTime) => {

                        }}
                    >
                        {renderTime}
                    </CountdownCircleTimer>
                </div>
            </div>
    );
}

export default CountDownCircleTimerLight;
