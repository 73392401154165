import CropperComponent from "../../../components/CropperJs/CropperComponent";
import {Modal} from "react-bootstrap";
import {useState} from "react";


function ModalAddCategorieMail({stateModal, setStateModal}) {



    return <Modal
        show={stateModal}
        onHide={() => {
            setStateModal(false)
        }}
        size='lg'
        dialogClassName="modal-dialog modal-fullscreen-lg-down modal-lg"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Ajouter une catégorie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <CropperComponent
                setStateModal={setStateModal}
            />
        </Modal.Body>
    </Modal>;
}

export default ModalAddCategorieMail;