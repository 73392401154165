import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import {showErrorAlert} from "../../utils/alerts/showErrorAlert";
import {BtSwal} from "../../utils/alerts/sweetAlert";

export default function useSetParametresForum() {
    const queryClient = useQueryClient();
    return useMutation(parametres => {
        return api.post('parametreForum', {
            json: parametres,
        }).json();
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('parametreForum');
            BtSwal.fire("Paramètres modifiés" ,"", "success")
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}