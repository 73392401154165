import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetCreditAllMySms() {
    return useQuery(
        ["compteSmsCredit"],
        async () => {
            return api.get("compteSms/credits").json();
        }
    );
}