import useGetTypeInvitation from "@requests/communication/invitations/useGetTypeInvitation";
import { useMemo } from "react";
import { useController } from "react-hook-form";
import Select2 from "@components/Select2/Select2";
import { Spinner, Alert } from "react-bootstrap";

function TypeInvitationSelectionMultiple({ control, name, rules}) {
    const typeInvitation = useGetTypeInvitation();
    const { field } = useController({ control, name, rules });

    const data = useMemo(() => {
        return typeInvitation.data?.data?.map(exp => ({
            id: exp.id,
            text: `${exp.libelle}`,
        }));
    }, [typeInvitation.data]);

    if (typeInvitation.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    if (typeInvitation.isError)
        return <Alert variant='danger'>{typeInvitation.error.message}</Alert>


    return <Select2
                    multiple={true}
                    data={data}
                    selected={field.value != null ?  Object.keys(field.value) : null}
                    minimumResultsForSearch={3}
                    placeholder='Sélectionner un ou plusieurs type'
                    onChange={(selected) => {
                        let res = {};
                        typeInvitation.data?.data.forEach(item => {
                            if (selected.includes(item.id.toString()))
                                res[item.id] = item;
                        });
                        field.onChange(res);
                    }}
    />
}

export default TypeInvitationSelectionMultiple;