import {useEffect} from "react";
import {Button, Col, Form, FormGroup, Row, Stack} from "react-bootstrap";
import {useFieldArray} from "react-hook-form";
import {emailPattern} from "../../../../functions/patterns";
import CiviliteSelection from "../../../personne/CiviliteSelection/CiviliteSelection";

function TransfereTabForm({ control, name, register, errors }) {

    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
        keyName: '',
    });

    //tabDestinatairesTransfere
    useEffect(() => {
        if (fields.length < 1)
            append({
                idEmail : null,
                idPersonneSourceTransfert : null,
                destinataire : {
                    personne : {
                        civilite: '',
                        prenom: '',
                        nom: '',
                        email: '',
                        indicatifTel1:"33",
                        indicatifTel2:"33",
                        id:null,
                    },
                },
                id : null,
            });
    }, [fields, append]);

    return <div>
        {fields.map((item, index) => (
            <Stack key={item.id} className={index === 0 ? 'mt-5' : ''}>
                <Row>
                    <Form.Group className={'mb-3'}>
                        <Form.Label className='required'>Civilité</Form.Label>
                        <CiviliteSelection isSolid={false} control={control} name={`${name}.${index}.destinataire.personne.civilite`} rules={{ required: true }} />
                        {errors.tabAccompagnants && errors.tabAccompagnants[index]?.destinataire?.personne?.civilite && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                    </Form.Group>
                    <Col sm={6}>
                        <FormGroup as={Col}>
                            <Form.Label>Prenom</Form.Label>
                            <Form.Control maxLength={20} className='form-control' {...register(`${name}.${index}.destinataire.personne.prenom`, {required : true})} />
                            {errors.tabAccompagnants && errors.tabAccompagnants[index]?.destinataire?.personne?.prenom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup as={Col}>
                            <Form.Label>Nom</Form.Label>
                            <Form.Control maxLength={50} className='form-control' {...register(`${name}.${index}.destinataire.personne.nom`, {required : true})} />
                            {errors.tabAccompagnants && errors.tabAccompagnants[index]?.destinataire?.personne?.nom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup as={Col}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control maxLength={50} className='form-control' {...register(`${name}.${index}.destinataire.personne.email`, {required : true, pattern: emailPattern()})} />
                            {errors.tabAccompagnants && errors.tabAccompagnants[index]?.destinataire?.personne?.email && <Form.Text className='text-danger'>Ce champ est requis (merci d'indiquer un email valide)</Form.Text>}
                        </FormGroup>
                    </Col>
                </Row>
                <div className='d-flex justify-content-end'>
                    {index === fields.length - 1 && <Button
                        variant='link'
                        className='btn-color-info btn-active-color-primary'
                        onClick={() => append({
                            idEmail : null,
                            idPersonneSourceTransfert : null,
                            destinataire : {
                                personne : {
                                    civilite: '',
                                    prenom: '',
                                    nom: '',
                                    email: '',
                                    indicatifTel1:"33",
                                    indicatifTel2:"33",
                                    id:null,
                                },
                            },
                            id : null,
                        })}
                    >Ajouter un transfert</Button>}
                    {fields.length > 1 && <Button
                        variant='link'
                        className='btn-color-danger btn-active-color-primary ms-auto'
                        onClick={() => remove(index)}
                    >Enlever le tranfert</Button>}
                </div>
            </Stack>
        ))}
    </div>;
}

export default TransfereTabForm;