import image from './icons/singleImage.svg';

export const singleImageModule = {
    image: image,
    defaultContent: {
        images: {
            image: 'https://placehold.co/1120x800/EEE/31343C',
        }
    },
    template:
        `<tr id="colorPicker">
            <td valign="top" align="center">
                <table width="100%" cellspacing="0" cellpadding="0" border="0">
                    <tbody><tr>
                        <td valign="top" align="center">
                            <table class="flexibleContainer" width="600" cellspacing="0" cellpadding="0" border="0">
                                <tbody><tr>
                                    <td class="flexibleContainerCell" id="paddingTemplate" width="600" valign="top" align="center">
                                        <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                            <tbody><tr>
                                                <td class="imageContent" style="font-size: 0; max-width: 600px" valign="top">
                                                    <img id="image" src="http://placekitten.com/g/1120/800" width="560" class="flexibleImage" style="max-width: 560px">
                                                </td>
                                            </tr>
                                        </tbody></table>
                                    </td>
                                </tr>
                            </tbody></table>
                        </td>
                    </tr>
                </tbody></table>
            </td>
        </tr>`,
}