import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetDomaineExpediteur() {

    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = {
        data: "id",
        searchable: true,
        orderable: true,
        search: {
            value: '',
            regex: false,
        }
    }

    filters.order[0] = {
        column:0,
        dir: "asc"
    }

    return useQuery(
        ['domaineExpediteur'],
        () => api.get('domaineExpediteur', {
            searchParams: Qs.stringify({
                start: 0,
                length: 100,
                ...filters,
            },{ arrayFormat: 'brackets' })
        }).json(),
        {cacheTime:0}

    );
}