import {faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useGetFooters from "@requests/communication/footer/useGetFooters";
import {Editor} from "@tinymce/tinymce-react";
import React, {useEffect, useRef, useState} from "react";
import {Accordion, Alert, Button, Form, Spinner} from "react-bootstrap";
import {useQueryClient} from "react-query";
import useChangeFooter from "@requests/communication/footer/useChangeFooter";
import {useForm} from "react-hook-form";
import {iconLienPj} from "../../../assets/icons/svgTinymce";
import $ from "jquery";
import useUploadFile from "../../../requests/upload/useUploadFile";
import 'tinymce/plugins/code';
import 'tinymce/plugins/table';
import useDeleteFooter from "../../../requests/communication/footer/useDeleteFooter";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css'
import {linkUrlTinyMce} from "../../../functions/linkUrlTinyMce";
import {imageTinyMce} from "../../../functions/ImageTinyMce";
import fileManagerProxy from "../../../proxies/fileManager";
import {useSnapshot} from "valtio";
import motsClesProxy from "../../../proxies/motsClesProxy";
import {useDebounce} from "use-debounce";
import SearchBar from "../../../components/SearchBar/SearchBar";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import auth from "../../../services/auth";

function FooterSettings() {
    const queryClient = useQueryClient();
    const { register, watch, setValue } = useForm();
    const search = watch('search')
    const [debouncedFilter] = useDebounce(search, 500);
    const footers = useGetFooters(debouncedFilter);
    const [activeKey, setActiveKey] = useState();
    const [hiddenEditLines, setHiddenEditLines] = useState(false);
    const [hiddenAdd, setHiddenAdd] = useState(false);
    const snapAuth = useSnapshot(auth);
    const [update, setUpdate] = useState(0);
    const deleteFooter = useDeleteFooter({
        onSuccess: (res) => {
            queryClient.invalidateQueries('footers')
            snapAuth.websocket.send("footers")
            setHiddenAdd(false)

            setActiveKey(null);
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

    const changeFooter = useChangeFooter({
        onSuccess: (res) => {
            queryClient.invalidateQueries('footers')
            snapAuth.websocket.send("footers")
            setHiddenAdd(false)

            setActiveKey(null);
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });


    function onSave(data) {
        changeFooter.mutate(data);
    }

    function toggleAccordionKey(eventKey) {
        if (activeKey == eventKey) {
            setActiveKey(null);
            setHiddenAdd(false)
        }
        else {
            setActiveKey(eventKey);
            setHiddenAdd(true)
        }
    }

    useEffect(() => {
        document?.getElementById("searchBarId")?.focus();
    }, [footers.isSuccess])

    if (footers.isError)
        return <Alert variant='danger'>{footers.error?.message}</Alert>;

    if (footers.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    return <Accordion activeKey={activeKey} className='mt-7'>
        <div className='d-flex flex-stack flex-wrap'>
            <SearchBar
                solid
                {...register('search')}
                onClear={search?.length > 0 ? () => {
                    setValue('search', '');
                } : null}
            />
            <div className='d-flex'>
                <div hidden={hiddenAdd ? hiddenAdd : hiddenEditLines} className='mx-auto fw-bold'>
                    <Button
                        variant='secondary'
                        className='px-20'
                        onClick={() => {
                            toggleAccordionKey('new')
                            setHiddenEditLines(true);
                            setUpdate(update+1);
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} className='me-2' />
                        Ajouter une signature
                    </Button>
                </div>
            </div>
        </div>
        {footers.data?.data?.map(footer => {
            return <div hidden={hiddenEditLines} className='bg-light my-2 p-3 rounded' key={footer.id}>
                <div className='d-flex align-content-center align-items-center'>
                    <div className='me-auto fw-bold'>
                        {footer.nom}
                    </div>
                    <button
                        onClick={() => toggleAccordionKey(footer.id)}
                        className='btn-sm btn btn-quaternaire p-1 ps-2 pe-2'
                    >
                        <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button
                        onClick={() => BtSwal.fire({
                            title: 'Êtes vous sur de vouloir supprimer la signature '+footer.nom+' ?' ,
                            showDenyButton: true,
                            confirmButtonText: `Oui`,
                            denyButtonText: `Annuler`,
                        }).then((result) => {
                            if (result.isConfirmed){
                                deleteFooter.mutate(footer.id)
                                BtSwal.fire('La signature a été supprimé!', '', 'success')
                                setHiddenAdd(false)
                            } else if (result.isDenied) {
                                BtSwal.fire('La signature n\'a pas été supprimé', '', 'info')
                            }
                        })}
                        className='btn-sm btn btn-secondary p-1 ps-2 pe-2'
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
                <Accordion.Collapse eventKey={footer.id}>
                    <div className='mt-3 d-flex justify-content-center'>
                        <div className='separator' />
                        <FooterForm update={update} footer={footer} setHiddenAdd={setHiddenAdd} onSave={onSave} />
                    </div>

                </Accordion.Collapse>
            </div>;
        })}
        <div className='mt-10'>
            <Accordion.Collapse eventKey={'new'}>
                <div className='bg-light rounded mt-3 pt-3 mx-10 d-flex justify-content-center'>
                    <FooterForm update={update} onSave={onSave} setHiddenEditLines={setHiddenEditLines} toggleAccordionKey={toggleAccordionKey} />
                </div>
            </Accordion.Collapse>
        </div>
    </Accordion>;
}

function FooterForm({ footer, onSave, setHiddenEditLines, setHiddenAdd, toggleAccordionKey, update }) {
    const editorRef = useRef();
    const motsClesSnap = useSnapshot(motsClesProxy);

    const uploadFile = useUploadFile({ type: "userfile" });
    if (uploadFile.data != null) {
        $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled",false);
        $(".tox-textfield").first().val(uploadFile.data.urlOriginale)

        if ($(".tox-dialog__title").text() == "Insérer une image"  || $(".tox-dialog__title").text() == "Modifier une image"){
            $(".tox-textfield").eq(1).val("L'image n'a pas pu être chargée")
        }
        else if ($(".tox-textfield").eq(1).val() == ""){
            $(".tox-textfield").eq(1).val("Cliquer ici pour ouvrir la pièce jointe.")
        }
        $(".tox-dialog__footer-end button").eq(1).trigger('click')
    }

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            let checkUrlMotCle = string.slice(0,4)
            return string && checkUrlMotCle == "{url";
        }

        return url.protocol === "https:";
    }

    const {register, getValues, reset, handleSubmit, formState: { errors }} = useForm({
        defaultValues: footer ?? {
            nom : null,
            corps : null
        },
    });

    useEffect(() => {
        reset(footer)
        if (!footer?.corps){
            editorRef?.current?.setContent("");
        }
    }, [footer, reset, update])

    function handleSubmitSave() {
        if (setHiddenEditLines) {
            setHiddenEditLines(false)
        }
        if (setHiddenAdd){
            setHiddenAdd(false)
        }
        const corps = editorRef.current?.getContent();
        const nom = getValues('nom');

        onSave({
            ...footer,
            corps,
            nom,
        });
    }

    return <div className='mx-3 mt-5 w-600px'>
        <Form.Group className='mt-5'>
            <Form.Label>Nom</Form.Label>
            <Form.Control {...register('nom', {required: true})} />
            {errors.nom && <Form.Text className='text-danger'>Nom de la signature requise</Form.Text>}
        </Form.Group>

            <div className='w-600px'>
                <Alert variant='info' className='m-3'>
                    La largeur du pied de page doit être de 600px.
                </Alert>
                <div className='m-0'>
                <Editor
                    id={"tinyMCEARecup"+(footer?.id ? footer?.id : "" )}
                    initialValue={footer?.corps}
                    onInit={(evt, editor) => editorRef.current = editor}
                    init={{
                        content_style: [contentUiCss, contentCss, '#tinymce {margin:0; overflow-x: hidden;}'].join('\n'),
                        skin: false,
                        language : 'fr_FR',
                        language_url : '/lang/tinymce/fr_FR/langs/fr_FR.js',
                        menubar: false,
                        height: 500,
                        table_responsive_width: true,
                        table_sizing_mode: 'relative',
                        plugins: ["link autolink image code table textcolor"],
                        toolbar: 'fontsizeselect | ' +
                            'bold italic forecolor backcolor | mybutton | alignleft aligncenter alignright alignjustify' +
                            '| bullist numlist | image | link unlink code table',
                        setup: function (editor) {

                            editor.ui.registry.addIcon('link', iconLienPj);
                            editor.on('init',function(e) {
                                editor.windowManager.oldOpen = editor.windowManager.open;
                                editor.windowManager.open = function (t, r) {
                                    var modal = editor.windowManager.oldOpen.apply(this, [t, r]);  // call original
                                    if (this && t.title === "Insert/Edit Link"){
                                        linkUrlTinyMce(t, isValidHttpUrl, footer, uploadFile);
                                    }
                                    else if (t.title === "Insert/Edit Image"){
                                        fileManagerProxy.baseId = "tinyMCEARecup";
                                        imageTinyMce(isValidHttpUrl, footer, uploadFile);
                                    }
                                    return modal;

                                }
                            });
                            editor.ui.registry.addMenuButton('mybutton', {
                                text: 'Mots clés',
                                fetch: function (callback) {
                                    let data = [];
                                    motsClesSnap.motsCles.forEach((item) => {
                                        data.push({
                                            type: item.type,
                                            text: item.text,
                                            onAction: function () {
                                                editor.insertContent(item.insert);
                                            }
                                        })
                                    })
                                    callback(data);
                                }
                            });
                        }
                    }}
                />
                </div>
            </div>

        <div className='mt-2 d-flex justify-content-center'>
            <Button
                variant='secondary'
                className='btn-md mb-2'
                onClick={handleSubmit(handleSubmitSave)}
            >{footer?.id != null ? 'Enregistrer' : 'Ajouter'}</Button>
            <span className="m-2"></span>
            {footer?.id == null && <Button
                variant='danger'
                className='btn-md mb-2'
                onClick={() => {
                    setHiddenEditLines(false)
                    toggleAccordionKey('new')
                }}
            >Annuler</Button>
            }

        </div>
    </div>;
}

export default FooterSettings;