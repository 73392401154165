import image from './icons/transfert.svg';

export const blocButtonTransfere = {
    image: image,
    defaultContent: {
        blocButtonTransferes: {
            blocButtonTransfere: '<p style="text-align: center"><a class="customBtnTransfert" style="padding: 8px 12px; border: 1px solid  #0060a5; background-color: #0060a5; cursor: pointer; border-radius: 2px; font-family: Helvetica, Arial, sans-serif; font-size: 14px; color: #ffffff; text-decoration: none; font-weight: bold; display: inline-block;" href="{urlTransfertEmail}" target="_blank" rel="noopener"> Transf&eacute;rer</a></p>',
        },
    },
    template: `
            <tr id="colorPicker">
                <td valign="top" align="center">
                    <table width="100%" cellspacing="0" cellpadding="0" border="0">
                        <tbody><tr>
                            <td valign="top" align="center">
                                <table class="flexibleContainer" width="600" cellspacing="0" cellpadding="0" border="0">
                                    <tbody><tr>
                                        <td class="flexibleContainerCell" id="paddingTemplate" width="600" valign="top" align="center">
                                            <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                                <tbody><tr>
                                                    <td id="blocButtonTransfere" class="textContent" >
                                                          <p style="text-align: center"><a class="customBtnTransfert" style="padding: 8px 12px; border: 1px solid  #0060a5; background-color: #0060a5; cursor: pointer; border-radius: 2px; font-family: Helvetica, Arial, sans-serif; font-size: 14px; color: #ffffff; text-decoration: none; font-weight: bold; display: inline-block;" href="{urlTransfertEmail}" target="_blank" rel="noopener"> Transf&eacute;rer</a></p>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                        </td>
                                    </tr>
                                </tbody></table>
                            </td>
                        </tr>
                    </tbody></table>
                </td>
            </tr>
        `,
}
