import { useQuery } from "react-query";
import api from "@services/api";
import Qs from "qs";

export default function useGetCategoriesMail(search) {

    if (!search){
        search = "";
    }

    let filters = {
        columns: {},
        order: {}
    };

    filters.columns[0] = {
        data: "id",
        searchable: true,
        orderable: true,
        search: {
            value: '',
            regex: false,
        }
    }

    filters.columns[1] = {
        data: "libelle",
        searchable: true,
        orderable: true,
        search: {
            value: search,
            regex: false,
        }
    }

    filters.order[0] = {
        column:0,
        dir: "desc"
    }



    return useQuery(['categorieEmail', search], () => {
        return api.get('categorieEmail', {
            searchParams: Qs.stringify({
                start: 0,
                length: 100,
                ...filters,
            }, { arrayFormat: 'brackets' }),
        }).json();
    });
}