import { useMutation } from "react-query";
import api from "@services/api";
import auth from "@services/auth";

export default function useChangeTva(props) {
    return useMutation(changeTva, props);
}

function changeTva(tva) {
    const id = tva?.id;

    if (id != null)
        return api.put(`tauxTva/${id}`, { json: tva }).json();

    return api.post('tauxTva', {
        json: {
            ...tva,
            idAgence: auth?.agence?.id,
        }
    }).json();
}