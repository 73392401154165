import {useState} from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import useGetCategoriesMail from "../../../requests/parametres/categoriesMail/useGetCategoriesMail";
import ModalAddCategorieMail from "./ModalAddCategorieMail";
import useDeleteCategorieMail from "../../../requests/parametres/categoriesMail/useDeleteCategorieMail";
import SearchBar from "../../../components/SearchBar/SearchBar";
import {useForm} from "react-hook-form";
import {useDebounce} from "use-debounce";

function CategorieMailSettings() {
    const deleteCategorie = useDeleteCategorieMail();
    const [stateModal, setStateModal]= useState(false);
    const { register, watch, setValue } = useForm();
    const search = watch('search')
    const [debouncedFilter] = useDebounce(search, 500);
    const headers = useGetCategoriesMail(debouncedFilter);


    return <div className='mt-7'>
        <div className='d-flex flex-stack flex-wrap'>
            <SearchBar
                solid
                {...register('search')}
                onClear={search?.length > 0 ? () => {
                    setValue('search', '');
                } : null}
            />
            <div className='d-flex'>
                <div className='mx-auto fw-bold'>
                    <Button
                        variant='secondary'
                        className='px-20'
                        onClick={() => setStateModal(true)}
                    >
                        <FontAwesomeIcon icon={faPlus} className='me-2' />
                        Ajouter une catégorie
                    </Button>
                </div>
            </div>
        </div>
        {headers.data?.data?.map(
            h => <div key={h.id} className='py-3 text-center'>
                <h2>{h?.libelle}</h2>
                <img
                    width={281}
                    className='text-danger'
                    src={h.upload?.urlOriginale}
                    alt="Impossible de charger l'entête"
                />
                <button
                    onClick={() => BtSwal.fire({
                        title: 'Êtes vous sur de vouloir supprimer la catégorie ?',
                        showDenyButton: true,
                        confirmButtonText: `Oui`,
                        denyButtonText: `Annuler`,
                    }).then((result) => {
                        if (result.isConfirmed){
                            deleteCategorie.mutate(h.id)
                            BtSwal.fire('La catégorie a été supprimé!', '', 'success')
                        } else if (result.isDenied) {
                            BtSwal.fire('La catégorie n\'a pas été supprimé', '', 'info')
                        }
                    })}
                    className='ms-2 btn-sm btn btn-secondary p-2 ps-3 pe-3'
                >
                    <FontAwesomeIcon icon={faTrash} size={"2x"} />
                </button>
            </div>
        )}
        <ModalAddCategorieMail
            stateModal={stateModal}
            setStateModal={setStateModal}
        />
    </div>;
}

export default CategorieMailSettings;