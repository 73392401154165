import CropperForIllustrationInscriptionLibre from "../../../../components/CropperJs/CropperForIllustrationInscriptionLibre";
import {Modal} from "react-bootstrap";


function ModalAddIllustrationInscriptionLibre({stateModal, setStateModal}) {
    return <Modal
        show={stateModal}
        onHide={() => {
            setStateModal(false)
        }}
        size='lg'
        dialogClassName="modal-dialog modal-fullscreen-lg-down modal-lg"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Ajouter une illustration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <CropperForIllustrationInscriptionLibre
                setStateModal={setStateModal}
            />
        </Modal.Body>
    </Modal>;
}

export default ModalAddIllustrationInscriptionLibre;