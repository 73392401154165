import MailEditor from "@components/MailEditor/MailEditor";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { useSnapshot } from "valtio";
import { BtSwal } from "@utils/alerts/sweetAlert";
import useChangeInvitation from "@requests/communication/invitations/useChangeInvitation";
import invitationsProxy from "@proxies/invitations";
import blocsProxy from "../../../../../proxies/blocs";

const RedactionStep = forwardRef((_, ref) => {
    const editorRef = useRef();
    const changeInvitation = useChangeInvitation();
    const invitationsSnapshot = useSnapshot(invitationsProxy);
    const blocsSnap = useSnapshot(blocsProxy);

    useImperativeHandle(ref, () => ({
        save: async () => {
            const mailBody = editorRef.current?.save();
            let choixObligatoire = false;
            let countChoixObligatoire = 0;
            let countInformationObligatoire = 0;
            let blocInformationsObligatoire = false;

            if (!(mailBody.json?.order?.length > 0)) {
                BtSwal.fire({
                    title: 'Invitation incomplète !',
                    text: "Vous devez ajouter au minimum une section à l'invitation",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
                return false;
            }

            for (const [key, value] of Object.entries(mailBody.json)) {
                if (value != null && value.hasOwnProperty("type") && value.type === "choixObligatoire"){
                    choixObligatoire = true;
                    countChoixObligatoire = countChoixObligatoire + 1;
                }else if (value != null && value.hasOwnProperty("type") && value.type === "blocInformationsObligatoire"){
                    blocInformationsObligatoire = true;
                    countInformationObligatoire = countInformationObligatoire + 1;
                }
            }

            if (!choixObligatoire){
                BtSwal.fire({
                    title: 'Invitation incomplète !',
                    text: "Vous devez ajouter la section choix de participation",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
                return false;
            }else if (!blocInformationsObligatoire){
                BtSwal.fire({
                    title: 'Invitation incomplète !',
                    text: "Vous devez ajouter la section des informations de l'invitation",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
                return false;
            }else if (countChoixObligatoire != 1){
                BtSwal.fire({
                    title: 'Invitation incorrect !',
                    text: "Merci de mettre une seule section choix de participation.",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
                return false;
            }else if (countInformationObligatoire != 1){
                BtSwal.fire({
                    title: 'Invitation incorrect !',
                    text: "Merci de mettre une seule section information de participation.",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
                return false;
            }

            if (!mailBody.json.footerFull){
                BtSwal.fire({
                    title: 'Invitation incomplète !',
                    text: "Merci d'ajouter un pied de page.",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
                return false;
            }

            const newInvitation = {
                ...invitationsSnapshot.invitation,
                blocModaliteReponse: blocsSnap.blocModalite ? blocsSnap.blocModalite : invitationsSnapshot.invitation.blocModaliteReponse,
                blocReponse: blocsSnap.blocChoix ? blocsSnap.blocChoix : invitationsSnapshot.invitation.blocReponse,
                corpsEmailHtml: mailBody.html,
                corpsEmailTemplate: mailBody.json,
                header: mailBody.json.headerFull,
                signature: mailBody.json.footerFull
            };

            if (!newInvitation.blocModaliteReponse.id || !newInvitation.blocReponse.id){
                BtSwal.fire({
                    title: 'Invitation incomplète !',
                    text: "Merci de choisir de renseigner les blocs de modalité et réponse via les paramètres du bloc.",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
                return false;
            }

            try {
                const result = await changeInvitation.mutateAsync(newInvitation);
                invitationsProxy.invitation = result;
                return true;
            } catch {
                return false;
            }
        },
    }));

    return <MailEditor isInvitation
        ref={editorRef}
        value={invitationsSnapshot.invitation.corpsEmailTemplate}
    />;
});

export default RedactionStep;