import {useMutation, useQueryClient} from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useDeleteResponsablePlaceLimite() {

    const queryClient = useQueryClient();

    return useMutation(deleteResponsable, {
        onSuccess: () => {
            queryClient.invalidateQueries('responsablePlaceLimite');
            toast.success('Le responsable à été supprimé', {
                duration:5000
            });
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
}

function deleteResponsable(id) {

    if (id != null)
        return api.delete(`responsablePlaceLimite/${id}`).json();

}