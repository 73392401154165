import { useQuery } from "react-query";
import api from "@services/api";

export default function useGetVilleFromCodePostal({ code, ville }) {
    return useQuery(
        ['villeFromCodePostal', code, ville],
        () => {
            return api.post('geonames/villeFromCodePostal',
                { json: {codePostal : code, ville : ville} }).json();
        },
        {
            enabled: (code?.length >= 2 || ville?.length > 2),
        }
    );
}