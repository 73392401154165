import { useMutation } from "react-query";
import api from "@services/api";

export default function useChangeDestinataireValidationMessage(props) {
    return useMutation(changeDestinataire, props);
}

export async function changeDestinataire(personne) {
    const id = personne.id;

    if (id != null)
        return api.put(`destinataireValidationMessage/${id}`, { json: personne }).json();
    return api.post('destinataireValidationMessage', { json: personne }).json();
}
