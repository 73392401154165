import useGetLastMailsSend from "../../requests/communication/mails/useGetLastMailsSend";
import LoadingView from "../LoadingView/LoadingView";
import LastMailsSendView from "./LastMailsSendView";
import useGetLastInvitationsSend from "../../requests/communication/mails/useGetLastInvitationsSend";
import ForumItem from "../forum/ForumView/ForumItem/ForumItem";
import useGetLastSujets from "../../requests/forum/useGetLastSujets";
import EditModal from "../communication/MailsView/EditModal/EditModal";
import {default as EditModalInvitation} from "../communication/InvitationsView/EditModal/EditModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import mailsProxy from "../../proxies/mails";
import blocsProxy from "../../proxies/blocs";
import React from "react";
import invitationsProxy from "../../proxies/invitations";
import sujetProxie from "@proxies/sujet";
import ForumCreateViewModal from "../forum/ForumView/ForumCreate/ForumCreateViewModal";

function AccueilView() {

    const lastEmailsSend = useGetLastMailsSend();
    const lastInvitationsSend = useGetLastInvitationsSend();
    const lastSujetPublier = useGetLastSujets();

    if (lastEmailsSend.data && lastInvitationsSend.data && lastSujetPublier.data){
        const emails = (lastEmailsSend.data.data).map((value, index) =>
            <LastMailsSendView
                key={index}
                email={value}
                type={'mail'}
            />
        );
        const invitations = (lastInvitationsSend.data.data).map((value, index) =>
            <LastMailsSendView
                key={index}
                email={value}
                type={'invitation'}
            />
        );
        const lastSujet = (lastSujetPublier.data.data).map((value, index) =>
            <ForumItem
                key={index}
                auteur={value.auteur}
                datePublication={value.datePublication}
                dernierMessage={value.dernierMessage}
                title={value.objet}
                content={value.corps}
                idSujet={value.id}
                dateCreation={value.dateCreation}
                nbMessages={value.nbMessages}
                nbVues={value.nbVues}
                isPublie={value.tabEtats.find(e => e.actif).id == 0}
                useGetSujetByCategorie={lastSujetPublier}
                sujet={value}
            >

            </ForumItem>
        );
        return <div className=" row justify-content-center">
        <div className="row justify-content-center">
        <div className="card card-xl-stretch mb-5 mb-xl-8 col-lg-5 col-md-5 col-sm-12 me-5">
            <div className="card-header border-0 pt-4 align-items-center">
                <h4 className="fw-bolder text-gray-800">Derniers mails envoyés</h4>
                <Button variant='secondary' onClick={() => mailsProxy.showEditMail(null)}>
                    <FontAwesomeIcon icon={faPlus} className='me-2' />
                    Créer un mail
                </Button>
            </div>
            <div className="card-body pt-2 pb-4">
                <div className="tab-content">
                    <div className="tab-pane fade show active" id="kt_table_widget_1_tab_1">
                        <div className="table-responsive" style={{height: "30vh"}}>
                            <table className="table align-middle gs-0 gy-3">
                                <thead>
                                <tr>
                                    <th className="p-0 w-50px"></th>
                                    <th className="p-0 min-w-150px"></th>
                                    <th className="p-0 min-w-140px"></th>
                                    <th className="p-0 min-w-140px"></th>
                                    <th className="p-0 w-50px"></th>
                                </tr>
                                </thead>
                                <tbody>
                                    {emails}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <div className="card card-xl-stretch mb-5 mb-xl-8 col-lg-5 col-md-5 col-sm-12">
                <div className="card-header border-0 pt-4 align-items-center">
                    <h4 className="fw-bolder text-gray-800">Dernières invitations envoyées</h4>
                    <Button variant='secondary'
                            onClick={() => {
                                invitationsProxy.showEditInvitation(null);
                                blocsProxy.blocChoix = null;
                                blocsProxy.blocModalite = null;
                            }}>
                        <FontAwesomeIcon icon={faPlus} className='me-2' />
                        Créer une invitation
                    </Button>
                </div>
                <div className="card-body pt-2 pb-4">
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="kt_table_widget_1_tab_1">
                            <div className="table-responsive" style={{height: "30vh"}}>
                                <table className="table align-middle gs-0 gy-3">
                                    <thead>
                                    <tr>
                                        <th className="p-0 w-50px"></th>
                                        <th className="p-0 min-w-150px"></th>
                                        <th className="p-0 min-w-140px"></th>
                                        <th className="p-0 min-w-140px"></th>
                                        <th className="p-0 w-50px"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {invitations}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <div className="card card-xl-stretch mb-5 mb-xl-8 col-10">
                <div className="card-header border-0 pt-4 align-items-center">
                    <h4 className="fw-bolder text-gray-800">Derniers sujets publiés</h4>
                    <Button
                        onClick={() => sujetProxie.showCreateSujet(null)}
                        variant='secondary'
                    >
                        <FontAwesomeIcon icon={faPlus} className='me-2' />
                        Ajouter un sujet
                    </Button>
                </div>
                <div className="card-body pt-2 pb-4">
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="kt_table_widget_1_tab_1">
                            <div className="table-responsive" style={{height: "30vh"}}>
                                <table className="table align-middle gs-0 gy-3">
                                    <thead>
                                    <tr>
                                        <th className="p-0 w-50px"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                        {lastSujet}
                                        </td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EditModal />
            <EditModalInvitation />
            <ForumCreateViewModal/>
        </div>




    }else {
        return <LoadingView/>
    }
}

export default AccueilView;
