import {useMemo, useState} from "react";
import {useController} from "react-hook-form";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise"
import useGetCategoriesMail from "../../../../../requests/parametres/categoriesMail/useGetCategoriesMail";
import {getLoadOptionsCategorieMail} from "../../../../../requests/selectionRequests/categorieMail";

function CategorieSelection({ control, name, rules }) {
    const categories = useGetCategoriesMail();
    const { field } = useController({ control, name, rules });
    const data = useMemo(() => {
        return categories.data?.data?.map(exp => ({
            id: exp.id,
            title: `${exp.libelle}`,
            image: exp?.upload?.urlOriginale
        }));
    }, [categories.data]);
    const [selectedValue, setSelectedValue] = useState(field.value != null ? {id:field.value.id,title:field.value.libelle, image:field.value?.upload?.urlOriginale} : null);
    const handleChange = value => {
        setSelectedValue(value);
    }
    const loadOptions = getLoadOptionsCategorieMail();
    const debouncedLoadOptions = debounce(loadOptions, 500)

    return <AsyncSelect
        placeholder={"Choisir une catégorie"}
        noOptionsMessage={() => "Aucun résultat"}
        loadingMessage={() => "Chargement..."}
        styles={{
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? 'white' : '#716d66',
                backgroundColor: state.isSelected ? 'var(--primary-color)' : 'white',
                "&:hover":{
                    backgroundColor: !state.isSelected ? 'var(--primary-color-light)' : "var(--primary-color)",
                }
            }),
            control: (base) => ({
                ...base,
                backgroundColor : "#f8f6f2",
                borderRadius : ".95rem",
                color: '#716d66',
            }),
        }}
        formatOptionLabel={categorie => (
            <div className="categorie-option">
                <img width={34} src={categorie?.image} className="me-5" alt="categorie-image" />
                <span>{categorie?.title}</span>
            </div>
        )}
        cacheOptions
        defaultOptions={data}
        value={selectedValue}
        getOptionLabel={e => e.title}
        getOptionValue={e => e.id}
        loadOptions={debouncedLoadOptions}
        onChange={ (selected) => {
            handleChange(selected)
            field.onChange(selected)
        }}
    />
}

export default CategorieSelection;