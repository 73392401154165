import mailEditorProxy from "../../../proxies/mailEditorProxy";
import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import $ from "jquery";
import {useSnapshot} from "valtio";
import {useState} from "react";

function AddTwoUrlOnImageModal(){
    const mailEditorSnap = useSnapshot(mailEditorProxy);
    const [leftUrl, setLeftUrl] = useState(mailEditorSnap?.block?.content?.rightUrl ? mailEditorSnap?.block?.content?.rightUrl : "");
    const [rightUrl, setRightUrl] = useState(mailEditorSnap?.block?.content?.leftUrl ? mailEditorSnap?.block?.content?.leftUrl : "");

    return <Modal
        show={mailEditorSnap.modalAddTwoUrlOnImage}
        onHide={() => {
            mailEditorProxy.modalAddTwoUrlOnImage = false
        }}
        size='lg'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <div className='w-100 stepper stepper-links d-flex flex-column between'>
                <div className='stepper-nav'>
                    <h3 className='stepper-title'>Ajout de liens sur les images</h3>
                </div>
            </div>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col sm={6} className='mt-5 mt-sm-0'>
                    <Form.Label>Lien 1 (Image de gauche)</Form.Label>
                    <Form.Control value={leftUrl} onChange={(e) => {
                        setLeftUrl(e.target.value)
                    }} />
                </Col>
                <Col sm={6} className='mt-5 mt-sm-0'>
                    <Form.Label>Lien 2 (Image de droite)</Form.Label>
                    <Form.Control value={rightUrl} onChange={(e) => {
                        setRightUrl(e.target.value)
                    }} />
                </Col>
            </Row>

            <Alert hidden={true} id="errorMessageTwoUrl"  className='mt-2 mx-auto' key='danger' variant='danger'>
                Lien incorrect format attendu -> https://xxxxx.xx. Merci de renseigner les 2 champs.
            </Alert>

            {/*{errors.txtLien && <Form.Text className='text-danger'>Texte pour l'url requis</Form.Text>}*/}
            <div className="d-flex justify-content-center mt-2">
                <Button
                    variant='primary'
                    onClick={() => {
                        if (isValidHttpUrl(leftUrl) && isValidHttpUrl(rightUrl)) {
                            let result = {
                                ...mailEditorSnap.block,
                                content: {
                                    ...mailEditorSnap.block.content,
                                    rightUrl : rightUrl,
                                    leftUrl : leftUrl
                                }
                            };
                            mailEditorSnap.onChange(result)
                            mailEditorProxy.modalAddTwoUrlOnImage = false;
                        }else {
                            $("#errorMessageTwoUrl").attr("hidden",false);
                        }

                    }}
                >Valider</Button>
            </div>
        </Modal.Body>
    </Modal>;

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "https:";
    }
}

export default AddTwoUrlOnImageModal;