import {useSnapshot} from "valtio";
import auth from "../../../services/auth";
import {Card} from "react-bootstrap";
import {baseURL} from "../../../config";
import useGetInfosJustificatif from "../../../requests/communication/invitations/useGetInfosJustificatif";
import {useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import ConfirmInfosFacturationView
    from "../../../views/communication/InvitationsView/Response/PaiementView/ConfirmInfosFacturationView";
import useChangeFacture from "../../../requests/communication/invitations/useChangeFacture";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import {useQueryClient} from "react-query";

export default function ListFacturesPaiement(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ref = urlParams.get('ref') ? urlParams.get('ref').replace(/ /g, "+") : "";
    const snapAuth = useSnapshot(auth);
    const infoFactures = useGetInfosJustificatif(ref);
    const {agence} = useParams();
    const [showUrls, setShowUrls] = useState(true);
    const [currentEditFacture, setCurrentFactureEdit] = useState({
        facture: null
    })
    const queryClient = useQueryClient();
    const changeFacture = useChangeFacture({
        onSuccess: (res) => {
            queryClient.setQueryData('facture', res);
            queryClient.invalidateQueries('correspondantInvitation');
            toast.success("Information pour la facturation enregistré.");
            setShowUrls(true)
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de la génération de la facture", err.message);
        },
    });

    const tabInfos = useMemo(() => {
        if (infoFactures.data) {
            let dataCorrespondant = [];
            let dataAccompagnants = [];
            if (infoFactures.data?.correspondantInvitation?.tabPaiements[1].actif){
                dataCorrespondant = [<tr>
                    <td className="ps-5">{infoFactures?.data?.correspondantInvitation?.destinataire?.personne?.prenom} {infoFactures?.data?.correspondantInvitation?.destinataire?.personne?.nom}</td>
                    <td><i onClick={() => {
                        window.open(baseURL+"/stripe/recu?referenceCharge="+infoFactures?.data?.correspondantInvitation?.referenceCharge+"&agence="+agence, "_blank")
                    }} className="btn btn-primary fa fa-eye"></i></td>
                    <td><i onClick={() => {
                        window.open(baseURL+"/facture/afficher?referencePaiement="+infoFactures?.data?.correspondantInvitation?.referencePaiement+"&agence="+agence, "_blank")
                    }} className="btn btn-primary fa fa-eye"></i></td>
                    <td><i onClick={() => {
                        setCurrentFactureEdit({
                            facture: infoFactures?.data?.tabFactures[infoFactures?.data?.correspondantInvitation?.referencePaiement]
                        })
                        setShowUrls(false)
                    }} className="btn btn-primary fa fa-pen"></i></td>
                </tr>]
            }
            if (infoFactures?.data?.correspondantInvitation?.tabAccompagnants){
                dataAccompagnants = Object.values(infoFactures?.data?.correspondantInvitation?.tabAccompagnants).filter(item => !item.paieSaPart && item.tabPaiements[1].actif).map((value, index) => {
                    return  <tr>
                        <td className="ps-5">{value.destinataire?.personne?.prenom} {value.destinataire?.personne?.nom}</td>
                        <td><i onClick={() => {
                            window.open(baseURL+"/stripe/recu?referenceCharge="+value?.referenceCharge+"&agence="+agence, "_blank")
                        }} className="btn btn-primary fa fa-eye"></i></td>
                        <td><i onClick={() => {
                            window.open(baseURL+"/facture/afficher?referencePaiement="+value?.referencePaiement+"&agence="+agence, "_blank")
                        }} className="btn btn-primary fa fa-eye"></i></td>
                        <td><i onClick={() => {
                            setCurrentFactureEdit({
                                facture: infoFactures?.data?.tabFactures[value?.referencePaiement]
                            })
                            setShowUrls(false)
                        }} className="btn btn-primary fa fa-pen"></i></td>
                    </tr>;
                });
            }
            return [...dataAccompagnants, ...dataCorrespondant];
        } else {
            return [];
        }
    }, [infoFactures.data]);


    if (!showUrls) {
        return <ConfirmInfosFacturationView
            infosFacturation={currentEditFacture}
            changeFacture={changeFacture}
        />
    }else {
        return <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
            <div className='d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative'>
                <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
                    <div className='d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20'>
                    <span className='py-9 py-lg-20'>
                        <div className="containerLogoPublicPage">
                            <img src={snapAuth.srcLogo} className='h-60px logo' />
                        </div>
                    </span>
                        <h1 className='fw-bolder text-white fs-2qx pb-5 pb-md-10'>Reçu et facture</h1>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column flex-lg-row-fluid py-10 card'>
                <div className='d-flex flex-center flex-column flex-column-fluid card-body'>
                    <div className='p-10 p-lg-15 mx-auto w-lg-75 w-md-100'>
                        <Card className="p-5">
                            <table className="align-middle bg-white text-start container table table-striped">
                                <thead>
                                <tr>
                                    <th className="fw-bolder fs-4 ps-5" scope="col">Correspondant</th>
                                    <th className="fw-bolder fs-4" scope="col">Reçu</th>
                                    <th className="fw-bolder fs-4" scope="col">Facture</th>
                                    <th className="fw-bolder fs-4" scope="col">Corriger la facture</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tabInfos}
                                </tbody>
                            </table>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    }
}