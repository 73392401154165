import { useMutation } from "react-query";
import api from "../../../services/api";

export default function useChangeUrlMinimiser(props) {
    return useMutation(changeUrlMinimiser, props);
}

function changeUrlMinimiser(url) {
    return api.post(`minimiseurUrl`, { json: {
            url
        }}).json();
}