import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useDeleteValidationMessage() {

    const queryClient = useQueryClient();

    return useMutation(deleteDestinataire, {
        onSuccess: () => {
            queryClient.invalidateQueries('destinataireValidationMessage');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });

}

function deleteDestinataire(id) {

    if (id != null)
        return api.delete(`destinataireValidationMessage/${id}`).json();

}