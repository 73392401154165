import {Navigate, useLocation} from "react-router";
import useActiveCompte from "../../../requests/communication/reseauxSociaux/facebook/useActiveCompte";
import LoadingView from "../../LoadingView/LoadingView";
import {useSnapshot} from "valtio";
import auth from "../../../services/auth";

export default function OauthResponse(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code') ? urlParams.get('code').replace(/ /g, "+") : "";
    const state = urlParams.get('state') ? urlParams.get('state').replace(/ /g, "+") : "";
    const activeCompte = useActiveCompte();
    const location = useLocation();
    const snapAuth = useSnapshot(auth);

    if (!code || !state){
        return <Navigate to={"/"+snapAuth?.agence?.dossierUpload+'/parametres/facebook'} replace={true} state={{ from: location, isError:true }}/>;
    }else {
        activeCompte.mutate({
            state : state,
            code : code
        })
    }

    if (activeCompte.isFetching){
        return <LoadingView/>
    }else {
        return <Navigate to={"/"+snapAuth?.agence?.dossierUpload+'/parametres/facebook'} replace={true} state={{ from: location }}/>
    }

}