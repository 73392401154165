import { useMemo } from "react";
import { useController } from "react-hook-form";
import Select2 from "@components/Select2/Select2";
import { Spinner, Alert } from "react-bootstrap";
import useGetDomaineExpediteur from "../../../requests/communication/expediteur/useGetDomaineExpediteur";

function DomaineExpediteurSelection({ control, name, rules }) {
    const domaineExpediteur = useGetDomaineExpediteur();
    const { field } = useController({ control, name, rules });

    const data = useMemo(() => {
        return domaineExpediteur.data?.data?.map(exp => ({
            id: exp.libelle,
            text: `${exp.libelle}`,
        }));
    }, [domaineExpediteur.data]);

    if (domaineExpediteur.isLoading)
        return <div className='text-center'><Spinner animation='border' /></div>;

    if (domaineExpediteur.isError)
        return <Alert variant='danger'>{domaineExpediteur.error.message}</Alert>

    return <Select2 solid
                    data={data}
                    selected={field.value != null ? [field.value] : null}
                    minimumResultsForSearch={3}
                    placeholder='Sélectionner un domaine'
                    onChange={(selected) => {
                        selected = selected?.at(0);
                        const res = domaineExpediteur.data?.data?.find(
                            exp => exp.libelle === selected
                        );
                        field.onChange(res.libelle);
                    }}
    />
}

export default DomaineExpediteurSelection;