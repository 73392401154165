import mailEditorProxy from "../../../proxies/mailEditorProxy";
import {useSnapshot} from "valtio";
import {Modal} from "react-bootstrap";
import ButtonCreator from "../../ButtonCreator/ButtonCreator";

function AddJustOneUrlOnImageModal(){
    const mailEditorSnap = useSnapshot(mailEditorProxy);

    return <Modal
        show={mailEditorSnap.modalCustomButtonTranfert}
        onHide={() => {
            mailEditorProxy.modalCustomButtonTranfert = false
        }}
        size='lg'
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <div className='w-100 stepper stepper-links d-flex flex-column between'>
                <div className='stepper-nav'>
                    <h3 className='stepper-title'>Personnaliser bouton de transfert</h3>
                </div>
            </div>
        </Modal.Header>
        <Modal.Body>
            <ButtonCreator/>
        </Modal.Body>
    </Modal>;

}

export default AddJustOneUrlOnImageModal;