import { useMutation, useQueryClient } from "react-query";
import api from "@services/api";
import toast from "react-hot-toast";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";

export default function useDeleteAccompagnantInvitation() {

    const queryClient = useQueryClient();

    return useMutation(deleteAccompagnantInvitation, {
        onSuccess: () => {
            queryClient.invalidateQueries('invitations');
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de la suppression", err.message);
        },
    });

}

function deleteAccompagnantInvitation(data) {

    if (data.id != null)
        return api.delete(`correspondantInvitation/${data.id}`, {json: {
                ref: data.ref
            } }).json();

}